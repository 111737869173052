import React, { useEffect, useState } from "react";
import LeadReminderModal from "../../../Modals/LeadReminderModal";
import "react-datepicker/dist/react-datepicker.css";
import { _l } from "../../../../hooks/utilities";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import AdminServices from "../../../../services/admin-services";
import moment from "moment";
import { getLeadDetail } from "../../../../actions/leads-actions/leads-actions";
import { Trash,PencilSimpleLine } from "phosphor-react";
import { showConfirmation, toastCloseAction } from "../../../../actions/messages";
import { GET_SPECIFIC_LEAD_DETAIL } from "../../../../actions/leads-actions/leads-action-types";
import CommanPlaceholder from "../../../Placeholder/CommanPlaceholder";
import LeadCommanCard from "./LeadCommanCard";
const LeadAppoinment = ({ data, selectedEventDate }) => {
  const dispatch = useDispatch();
  const slug = "lead_appointments";
  const { lead_appointments } = useSelector((state) => state.leadsReducer.leadDetails);
  const [leadDataList, setLeadDataList] = useState([]);
  const [editReminder, seEditReminder] = useState("");
  const [openAccordion, setOpenAccordion] = useState('0');
  useEffect(() => {
    setLeadDataList(lead_appointments)
    const indexDate = lead_appointments && lead_appointments.findIndex((date) => moment(date.date).format("DD/MM/YYYY hh:mm a") === moment(selectedEventDate).format("DD/MM/YYYY hh:mm a"))
    setOpenAccordion(indexDate)
  }, [lead_appointments])
  useEffect(() => {
    dispatch(getLeadDetail(data.lead_id, slug));
  }, [data.lead_id])

  const deleteLeadReminder = (reminder_id) => {
    AdminServices.deleteReminder(reminder_id).then((data) => {
      if (data.status == 1) {
        // setLeadDataList(lead_appointments.filter(ld => ld.id != reminder_id))
        dispatch({
          type: GET_SPECIFIC_LEAD_DETAIL,
          payload: { data: lead_appointments.filter(ld => ld.id != reminder_id), id: data.lead_id, slug },
        });
        dispatch(toastCloseAction())
      }
    });
  }
  const modalCloseIconClick = (id) => {
    if (id) {
      dispatch(
        showConfirmation(
          _l("l_confirm"),
          _l("l_want_to_delete_reminder"),
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => {
            deleteLeadReminder(id);
          }
        )
      );
    }
  };

  const handleAccordionToggle = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <>
      <div className="d-flex flex-column flex-grow-1 p-10 py-0">
        <div className=" d-flex flex-column flex-grow-1">
          <div className="comman-content-scroll-wrapper flex-grow-1 d-flex flex-column ">
            <div className="comman-content-scroll flex-grow-1 d-flex flex-column ">
              <div className="custom-accordian-main comman-data-table small-data-table with-horizontal-scroll vertical-top d-flex flex-column flex-grow-1">
                {leadDataList && leadDataList.length > 0 ? 
                <>
                {
                  leadDataList.map((lead, index)=>{
                    return(<>
                    <LeadCommanCard 
                      data={lead}
                      type={"appoinment"}
                      handleDelete={ (id) => {
                        modalCloseIconClick(id)
                      }}
                      handleEdit={seEditReminder}
                      handleAccordionToggle={handleAccordionToggle} index={index} openAccordion={openAccordion}
                    /> </>)
                  })
                }
                </>
                :<>
                 <CommanPlaceholder imgType="appointme" mainWidth="m-auto" placeholderText = {_l("l_no_appointment")} />
                </>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        editReminder != "" ?
          <LeadReminderModal
            show={true}
            reminderData={{ ...editReminder, lead_id: data.lead_id }}
            handleClose={() => seEditReminder("")}
            isEdit={true}
            slug={slug}
          />
          :
          ""
      }

    </>
  )
}

export default LeadAppoinment