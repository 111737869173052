import React, { useEffect, useState } from 'react'
// import { PHOSPHUR_ICON_NAME_LIST as PhosphorIcons} from '../../constants/constants';   
import { CaretDoubleDown } from 'phosphor-react';
import { _l } from '../../hooks/utilities';
import { Spinner } from 'react-bootstrap';
const IconComponentWrapper = ({ searchVal = "", setIcon = () => {} }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [componentArr, setComponentArr] = useState([]);
  const [loder, setLoder] = useState(false)
  const [iconArrLength, seticonArrLength] = useState(0);
  const itemsPerPage = 54;
 
  useEffect(() => {
    setCurrentPage(1);
  }, [searchVal]);
  
  // useEffect(() => {
  //   try {
  //     let ComponentArray = []
  //     setLoder(true);
  //     const iconFilters = PhosphorIcons.filter((item) => item.toLowerCase().includes(searchVal.toLowerCase()))
  //     if (iconFilters.length > 0) {
  //       iconFilters.slice(0, (itemsPerPage * currentPage)).map((IconName) => {
  //         const importComponent = async () => {
  //           const module = await import(`phosphor-react/dist/icons/${IconName}.esm.js`);
  //           const Icon = module.default;
  //           const component = <Icon/>
  //           ComponentArray.push(component)
  //         };
  //         importComponent();
  //       })
  //     }else{

  //     }
  //     setComponentArr(ComponentArray)
  //     seticonArrLength(iconFilters.length);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoder(false)
  //   }
    
  // }, [currentPage, searchVal])
  
  return  (
    <>
    {componentArr && componentArr.length > 0 && componentArr.map((item, index) => {
      const Icon = item.type;
      return (
        <div className='col-lg-1 '>
          <a
              className={`h50w50 comman-round-box rounded-circle bg-white-05 d-flex justify-content-center align-items-center `} 
              onClick={() => {
                setIcon(Icon)
              }}
            >
              {/* <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${image.image_url}')`}}> </div> */}
              <Icon weight="light" className="c-icons" size={36}/>
              {/* {item} */}
          </a>
        </div>
      )
    })}
    {iconArrLength > (currentPage * itemsPerPage) ? 
    <div className='d-flex align-items-center justify-content-center'>
      <a href="#/"
        className="btn btn-white-03 btn-sm"
        onClick={() => {
          setCurrentPage(currentPage + 1)
        }}
      >
        <div className="align-item-center  justify-content-center">
          <span className=" title-fonts c-font f-12 ms-2">
            {_l("l_view_more_btn")} 
            {`(${iconArrLength - currentPage * itemsPerPage})`} 
          </span>
          <CaretDoubleDown size={12} className="c-icons mx-2" weight="light" />
          {loder ? 
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2`}
              aria-hidden="true"
            />
          :<></>}
        </div>
      </a>
      </div>
      : <></>}
    </>
    // <></>
  )
}

export default IconComponentWrapper;

