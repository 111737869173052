import React from "react";
import { Form } from "react-bootstrap";

const NameInput = ({type, value, onChange, placeholder}) => {
  return (
    <div className="row w-100 flex-grow-1">
      <div className="col-lg-2"></div>
      <div className="col-lg-7 m-auto">
        <Form.Group className={`c-input-box position-relative text-center `}>
          <div className="inputWrapper d-flex">
            <Form.Control
              placeholder={placeholder}
              className="regFormInput text-center"
              type={type}
              value={value}
              onChange={onChange}
            />
          </div>
        </Form.Group>
      </div>
      <div className="col-lg-2"></div>
    </div>
  );
};

export default NameInput;
