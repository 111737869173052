import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { _l } from "../../hooks/utilities";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import Form from "react-bootstrap/Form";


const TABLE_COUMN = [
  {
    title : "l_risk_zone",
    width : "20%"
  },
  {
    title : "l_risk_indicator",
    width : "20%"
  },
  {
    title : "l_rating",
    width : "3%"
  },
  {
    title : "l_note",
    width : "10%"
  },
  {
    title : "l_coefficient",
    width : "3%"
  }
]

const RiskDetailModal = ({
  show,
  handleClose,
  hideActionMenu = true,
  editData,
  riskDetailDataTitle,
  data
}) => {

  const TABLE_COLUMN = [
    { title: "l_risk_zone", width: "20%"},
    { title: "l_risk_indicator", width: "20%" },
    { title: "l_rating", width: "3%" },
    { title: "l_note", width: "10%" },
    { title: "l_coefficient", width: "3%"}
  ];


  const keyMapping = {
    l_coefficient: "coefficient",
    l_note: "notes",
    l_risk_indicator: "indicator",
    l_risk_zone: "title",
    l_rating: "rating",
  };

  const keyMappingReverse = Object.fromEntries(
    Object.entries(keyMapping).map(([key, value]) => [value, key])
  );

  const defaultRow = TABLE_COLUMN.reduce((acc, col) => {
    acc[col.title] = "";
    return acc;
  }, { id: "" });

  const [riskData, setriskData] = useState(editData);
  const [title, setTitle] = useState("")
  const [tblColumns, setTblColumns] = useState(TABLE_COUMN)

  
  useEffect(() => {
    if (editData) {
    let newCols = []
    let initialData = editData.map((item, index) => {
        let newItem = { ...defaultRow };
        Object.keys(item).forEach(key => {
          if (keyMappingReverse[key]) {
            newItem[keyMappingReverse[key]] = item[key];
          } else {
            if(index === 0 && key !== "id") {
              newCols.push({ title: key, width: "5%"})
            }
            newItem[key] = item[key];
          }
        });
        return newItem;
      });
      setTblColumns([...tblColumns, ...newCols]);
      setriskData(initialData)
    }
  }, [editData])


  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="xl"
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <div className="d-flex justify-content-between align-items-center w-100 pe-20pximp">
            <Modal.Title>
              <Form.Group className="col-xl-12 c-input-box position-relative w-3">
                <Form.Control
                  className="p-1"
                  placeholder={`${_l("l_template_title")}`}
                  type="text"
                  name="risk_zone"
                  value={riskDetailDataTitle}
                  onChange={(e) => {
                   
                  }}
                ></Form.Control>
              </Form.Group>
            </Modal.Title>
            <div className="d-flex">
              <span className="me-2 fw-semibold">{_l("l_last_updated_date")} :</span>
              <span>{data.created_at}</span>
            </div>
            </div>
          </Modal.Header>
          <Modal.Body className="with-overflow pt-0 minh60vh">
            <div className="form-wrapper-main border-bottom pb-2">
              <div className="comman_vertical_btn_h vertical_scroll120 comman-white-box-shadow light-theme-white-bg">
                <div className="comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1">
                  <table className="dataTable text-center tablewithborder">
                    <thead className="comman-white-box-shadow">
                      <tr>
                        {tblColumns && tblColumns.map((col, i)=>{
                          return(<>
                           <th style={{ width: `${col.width}` }} className="text-start" key={i}>
                          {_l(col.title)}
                        </th>
                          </>)
                        })}
                        {hideActionMenu ? (
                          <></>
                        ) : (
                          <th style={{ width: "2%" }} className="text-center">
                            {_l("l_action")}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody style={{ verticalAlign: "middle" }}>
                      {riskData && riskData.length > 0 ? (
                        <>
                          {
                            riskData.map((row, rowIndex) => (
                              <tr key={row.id}>
                                {
                                  tblColumns.map((col, colIndex) => (
                                    <td className="title-fonts text-truncate text-start max-width75 p-2 1">
                                      <div className="name-with-progress">
                                        <Form.Group className="col-xl-12 c-input-box position-relative">
                                          <Form.Control
                                            className="p-1"
                                            // placeholder={`${_l(col.title)}`}
                                            // type={col.intialVal !== "" && col.intialVal >= 0 ? "number" : "text"}
                                            name="risk_indicator"
                                            value={row[col.title]}
                                            disabled
                                          ></Form.Control>
                                        </Form.Group>
                                      </div>
                                  </td>
                                  ))
                                }
                              </tr>
                            ))
                          }
                        </>
                      ) : (
                        <>
                          {" "}
                          <tr className="">
                            <td className="border-0" colSpan={7}>
                              <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                                <div className="d-flex flex-column align-items-center">
                                  <CommanPlaceholder
                                    imgType="request"
                                    placeholderText={_l("l_no_data")}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <div className="d-flex me-20px">
            <span className="me-2 fw-semibold">{_l("l_risk_score")} :</span>
            <span>{data.score}</span>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default RiskDetailModal