import React from "react";
import { _l } from "../../../hooks/utilities";
import { useSelector } from "react-redux";

export function FloorListing({
  setFloor,
  buttonText,
  getSelectedItemsCountForFloor,
  getAvailableItemsCountForFloor,
}) {

  const userType = localStorage.getItem("user_type");
  const { floorDetails } = useSelector((state) => state.benchmarkReducer);

  let defaultTypeOfSpace = floorDetails.type_of_space.find((w) => w.is_default == 1);

  return (
    <div className="row m-0 gy-2 gx-0">
      {[_l("l_assign"), _l("l_update")].includes(buttonText) && getAvailableItemsCountForFloor && getAvailableItemsCountForFloor(-1) ? (
        <div className=" col-xl-12 c-box-shadow-box" onClick={() => setFloor({id: -1})}>
          <div className="bg-white-03 on-hover-bg-white-05 p-2 radius_5 w-100 cursor-pointer">
            <div class="align-items-center d-flex justify-content-between">
              <div class="c-font f-14 fw-semibold text-truncate title-fonts color-white-60 w100minus100per">
                {defaultTypeOfSpace ? defaultTypeOfSpace.title : ""}
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center w-100 ms-auto">
                  {getSelectedItemsCountForFloor &&
                  getAvailableItemsCountForFloor ? (
                    <div className="d-flex align-items-center overflow-auto mx-2">
                      <div className="d-flex ms-auto px-2">
                        <div className="left-box with_separator_10">
                          <div className="title-fonts fw-semibold text-center c-font f-13">
                            {getAvailableItemsCountForFloor
                              ? getAvailableItemsCountForFloor(-1)
                              : ""}
                          </div>
                          <div className="c-font f-11 opacity-50">
                            {_l("l_total")}
                          </div>
                        </div>
                        {
                          userType === "operator"
                          ?
                          <div className="left-box with_separator_10 pe-0">
                            <div className="title-fonts fw-semibold text-center c-font f-13">
                              {getSelectedItemsCountForFloor
                                ? getSelectedItemsCountForFloor(-1)
                                : ""}
                            </div>
                            <div className="c-font f-11 opacity-50">
                              {_l("l_selected")}
                            </div>
                          </div>
                          :
                          <React.Fragment></React.Fragment>
                        }
                      </div>
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <a href="#/"
                    className="btn btn-white-05 btn-sm border-0"
                    onClick={() => setFloor({id: -1})}
                  >
                    {buttonText || _l("l_setup_floor")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <div className="row m-0 gx-0">
      {floorDetails.floor.map((floor, index) => {
        if(getAvailableItemsCountForFloor && !getAvailableItemsCountForFloor(floor.id))
        {
          return <React.Fragment></React.Fragment>
        }
        return (
          <div
            key={index}
            className=" col-xl-12 m-0"
            onClick={() => setFloor(floor)}
          >
            <div className="task-list-card list-view p-10 cursor-pointer light-theme-border">
              <div class="align-items-center d-flex justify-content-between">
                <div class="c-font f-14 fw-semibold text-truncate title-fonts color-white-60 w100minus100per">
                  {floor.title}
                </div>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center w-100 ms-auto">
                    {getSelectedItemsCountForFloor &&
                    getAvailableItemsCountForFloor ? (
                      <div className="d-flex align-items-center overflow-auto mx-2">
                        <div className="d-flex ms-auto px-2">
                          <div className="left-box with_separator_10">
                            <div className="title-fonts fw-semibold text-center c-font f-13">
                              {getAvailableItemsCountForFloor
                                ? getAvailableItemsCountForFloor(floor.id)
                                : ""}
                            </div>
                            <div className="c-font f-11 opacity-50">
                              {_l("l_total")}
                            </div>
                          </div>
                          {
                            userType === "operator"
                            ?
                            <div className="left-box with_separator_10 pe-0">
                              <div className="title-fonts fw-semibold text-center c-font f-13">
                                {getSelectedItemsCountForFloor
                                  ? getSelectedItemsCountForFloor(floor.id)
                                  : ""}
                              </div>
                              <div className="c-font f-11 opacity-50">
                                {_l("l_selected")}
                              </div>
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                          }
                        </div>
                      </div>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <a href="#/"
                      className="btn btn-white-05 btn-sm border-0 h32wauto d-flex align-items-center"
                    >
                      {buttonText || _l("l_setup_floor")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      </div>
    </div>
  );
}
