import React from 'react'
import { _l } from '../../hooks/utilities'

const UnControlSubFiltersDropdown = ({ data, filterName, handleFilterLeads = () => {}, selectedleadfilers}) => {
    return (
            <ul className="dropdown-menu w-140 mL-158 overflowscroll" aria-labelledby="TaskListStatusDropdown">
                {filterName != "additional_filters" ? (
                    data && data.map((filter, i) => {
                        return (
                            <>
                            <li className="" key={i}>
                                <a href="#/"
                                    className={`custom-assignees custom-class-active dropdown-item with-tick ${selectedleadfilers.includes(filter.id || filter.staffid) ? 'active' : ''}`}
                                >
                                    <div
                                        className="d-flex align-items-center text-truncate"
                                    onClick={ () => {
                                        handleFilterLeads(filter.id, filterName);
                                    }}
                                    >
                                        <div className="d-flex align-items-center w100minus10 text_wrap">
                                            <div className="ps-2 text_wrap">
                                                <div className="c-list-detail text-truncate ">
                                                    {filter.name || filter.fullname}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className='dropdown-divider my-0'></li>
                            </>
                        )
                    })
                )
                : data && Object.keys(data).length ? (
                    Object.keys(data).map((filter, i) => { 
                        return(
                        <>   
                        <li className="" key={i}>
                            <a href="#/"
                                className={`custom-assignees custom-class-active dropdown-item with-tick ${selectedleadfilers.includes(filter) ? 'active' : ''}`}
                            >
                                <div
                                    className="d-flex align-items-center text-truncate"
                                    onClick={ () => {
                                        handleFilterLeads(filter, filterName);
                                    }}
                                >
                                    <div className="d-flex align-items-center w100minus10 text_wrap">
                                        <div className="ps-2 text_wrap">
                                            <div className="c-list-detail text-truncate ">
                                            { _l(data[filter])}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li className='dropdown-divider my-0'></li>
                        </>
                        )
                    })) 
                    : <></>}
            </ul>
    )
}

export default UnControlSubFiltersDropdown