import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Documents from "../Documents/Documentmain/Modalcomponent/Documents";
import Estimates from "../Documents/Estimatesmain/Modalcomponent/Estimates";
import Invoices from "../Documents/Invoicesmain/Modalcomponent/Invoices";
import Paytm from "../Documents/tabs/PaytmTabs/Paytm";
import CreditNote from "../Documents/Creditnotemain/Modalcomponent/CreditNote";
import Subscriptions from "../Documents/Subscriptionsmain/Modalcomponent/Subscriptions";
import LeadsTabs from "../MyLead/Leadsmain/LeadsTabs";
import WalletOffcanvas from "../Wallet/Modalcomponent/WalletOffcanvas";
import {  averageTaskChecklistRatings, getScheduleTitle, hideRightPanelNew, removeDuplicateObjects, removeDuplicates, removeOffCanvasBackdropClass, showError, showLeftPanelNew, TaskCommentModalToggle, toggleLeftPanelAndOffcanvasClasses } from "../../hooks/utilities";
import CommonHeaderModal from "../TaskModalComponents/CommonHeaderModal";
import CommonHeaderBody from "../TaskModalComponents/CommonHeaderBody";
import customerServices from "../../services/customer-services";
import CommanLoader from "../Loader/CommanLoader";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  dropDownActiveClass,
  _l,
  TaskCommentModalOpen,
  TaskModalClose,
  CloseCommentModal,
  tabindexFix,
} from "../../hooks/utilities";
import CommentSection from "../Comments/CommentSection";
import { useDispatch, useSelector } from "react-redux";
import {
  showMessage,
  showConfirmation,
  toastCloseAction,
  showFullScreenLoader,
} from "../../actions/messages";
import {
  updateTaskStatus,
  changePriority,
  changeTaskProject,
  setTaskData,
  updateTaskPin,
  getTaskCount,
  SetSelectedTask,
  getSelectedTask,
  getCustomerSpaces,
  getMentionList,
  setTaskAssigneeNew,
  updateBenchmarkProposalComments,
  setSpaceList,
  projectTaskCounts,
  setVirtualSpaceNotificationCount,
  setSpaceNotificationCount,
  updateedTaskChecklist,
  updateTaskAttechments,
  commanoffcanvasisOpen,
  updateTaskList,
  setBulkTaskRating,
  setNewEquipment,
  setEquipmentQuantityList,
  setEquipmentQuantity,
  getPOIsListUpdate,
  closeOutsideClick,
  updateHideLoader,
  commanoffcanvasisOpenfromCalendar,
  getCommentsForAudiocall,
  getTaskAttechments,
  filterFor3Dmodel,
  getEquimenntsList,
  getArLocalAnchors,
} from "../../actions/customer";
import {
  handleLeadPinUnpin,
  hotLeadSetUnset,
  leadStatusChange,
  addLeadComment,
  getLeadActivities,
  editLeadActivity,
  deleteLeadActivity,
} from "../../actions/leads-actions/leads-actions";
import TaskShareLinkModal from "../Modals/TaskShareLinkModal";
import TaskBodyCommonModal from "../TaskModalComponents/TaskBodyCommonModal";
import CommonFooter from "../TaskModalComponents/CommonFooter";
import RequestTaskBodyCommonModal from "../TaskModalComponents/RequestTaskBodyCommonModal";
import {
  addEditDocumentComment,
  getComments,
  removeDocumentComment,
} from "../../actions/documents-actions/documents-actions";
import DocumentsServices from "../../services/documents-services";
import documentsServices from "../../services/documents-services";
import {
  GET_ESTIMATE_DETAILS,
  GET_DOCUMENT_DETAILS,
  GET_INVOICE_DETAILS,
  GET_SUBSCRIPTION_DETAILS,
} from "../../actions/documents-actions/documents-action-types";
import EmailDetailView from "../MyEmail/EmailTabs/EmailDetailView";
import ConvertLeadToCustomer from "../Modals/ConvertLeadToCustomer";
import AdminServices from "../../services/admin-services";
import CustomTextEditor from "../CustomTextEditor/CustomTextEditor";
import { loginAsUser } from "../../actions/auth";
import { adminLoginAsStaff } from "../../actions/admin-actions";
import SpaceDocumentCanvas from "../Documents/SpaceDocument/SpaceDocumentCanvas";
import ImageDetailOffcanvas from "./ImageDetailOffcanvas";
import jquery from "jquery";
import AddEditProposalAmount from "../Modals/AddEditProposalAmount";
import Toasts from "../Toasts/Toasts";
import PilotOnboardingMain from "../Documents/PilotOnboarding/PilotOnboardingMain";
import LiteViewCanvas from "./LiteViewCanvas";
import moment from "moment";
import { addDays } from "@fullcalendar/react";
import  { setComments as setComment }   from "../../actions/documents-actions/documents-actions";
import { CUSTOM_ASSIGNEE_CHANGE } from "../../constants/constants";
import SpaceRoom from "../3DSpaces/SpaceRoom";
import SpaceEquipment from "../3DSpaces/SpaceEquipment";
import { getFloorDetails, selectGlobalDocType, updateFloorDetails } from "../../actions/benchmark-actions/benchmark-actions";
import equipmentsServices from "../../services/equipments-services";
import SpacePois from "../3DSpaces/SpacePois";
import { callAction } from "../../actions/chat";
import benchmarkServices from "../../services/benchmark-services";
import ProviderListOffcanvas from "./ProviderListOffcanvas";
let attchmentFiles = [];
let subscribeContactEmail = "";
const CommanOffCanvas = ({
  show,
  handleClose,
  docType,
  data,
  setData = () => {},
  setSelectedId = () => {},
  pinHandler,
  selectedTaskId,
  isExternal = false,
  handleParentComponentAction = ()=>{},
  handleBenchmarkModal = ()=>{},
  isEdit = false,
  isCreateBenchmark,
  IsKanbanView = false,
  readOnlyMode,
  setReadOnlyMode,
  commentList,
  pageName,
  isChat=false,
  meetingUrl,
  canvasFullHeight=false,
  onCloseShowLeftPanelDefault=false,
  canvasfullHeightwithHeaderFooter=false,
  contacts = [],
  isFullScreen=false,
  callUserList = [],
  isCommentsection = false,
  disableFullScreenToggle=false,
  setActiveTaskID = ()=>{},
  fromTopicSetting=false,
  isExternalWithNoLeftPanel=false,
  handleChangeCordinate = () => {},
  setPOIsList = () => {},
  cordinatesData,
  typeOfSpaceData,
  floorId,
  from,
  selectedRoom,
  setSelectedRoom = () => {},
  selectedEquipment = () => {},
  setRequestData = () => {},
  selectedPoi,
  iscallModal = false,
  isFromCalendar = false,
  selectedEventDate = ''
}) => {
  let totalRef = null;

  const [loaderForPOI, setLoaderForPOI] = useState(false);
  
  const [commentContent, setCommentContent] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [commentCount, setCommentCount] = useState(0);
  const [comments, setComments] = useState([]);
  const [selectedAttachment, setSelectedAttachment] = useState({});
  const [documentAddedFom, setDocumentAddedFom] = useState({
    name: "",
    profile_image: "",
  });
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [dataFetchId, setDataFetchId] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id: hash_id, page } = useParams();
  const selectedDate =
    localStorage.getItem("calendarData") &&
    localStorage.getItem("calendarData") != "undefined" &&
    JSON.parse(localStorage.getItem("calendarData")).length
      ? JSON.parse(localStorage.getItem("calendarData"))[3].date
      : new Date();
  const [showLink, setShowLink] = useState(false);
  const [showProposalAmountModal, setShowProposalAmountModal] = useState(false);
  const [proposalAmount, setProposalAmount] = useState(0);
  const [proposalDescription, setProposalDescription] = useState("");
  const [proposalId, setProposalId] = useState(0);
  const [externalPageLink, setExternalPageLink] = useState("");
  const [mentionList, setMentionList] = useState([]);
  const [myr_total, setMyr_total] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [commonHeaderData, setCommonHeaderData] = useState({
    creater_name: "",
    creater_profile: "",
    document_name: "",
    document_name_permission: "",
    document_id: "",
  });
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [showMyrTotal, setShowMyrTotal] = useState(false);
  const [commonHeaderBodyData, setCommonHeaderBodyData] = useState({
    requestAssignee: [],
  });
  const [providerType, setProviderType] = useState("");
  const [providerValue, setProviderValue] = useState("");
  const [providerDetail, setProviderDetail] = useState("");
  const [defaultComment, setdefaultComment] = useState([]);
  const [assigneeDropdownList, setAssigneeDropdownList] = useState([]);
  const [selectedContact, setSelectedContact] = useState()
  
  const [ShowCommentModal, setShowCommentModal] = useState(false);
  const [type, setType] = useState("");
  const [proposalDetail, setProposalDetail] = useState({});
  const [benchmark, setBenchmark] = useState({});
  const [checklistAssigneesList, setChecklistAssigneesList] = useState([]);
  const headerData = {
    date_read_only: false,
  };
  const defaultTopics = useSelector((state) => state.customer.defaultTopics);
  const task_attachments = useSelector((state) => state.customer.task_attachments);
  const quantityList = useSelector((state) => state.customer.set_equipments_quantity_list);
  const newEuipment = useSelector((state) => state.customer.set_new_equipment);
  const {taskList, getSelectedFloorId, get_equipments_list, setPOILatLng, getActivePOIList, callComments, filterfor3dPlan, isCallFullscreen} = useSelector((state) => state.customer);
  const {callStatus} = useSelector((state) => state.chat);
  const userType = localStorage.getItem("user_type");
  const dispatch = useDispatch();
  const contact_id = localStorage.getItem("contact_id");
  const staff_id = localStorage.getItem("staff_id");
  const client_id = localStorage.getItem("client_id");
  const projectId = localStorage.getItem("selectedOffice")
  const guestId = localStorage.getItem("guest_id");
  const contact_role = localStorage.getItem("contact_role");
  let RightSideBtnToggle = false;
  const statusClass = {
    Unpaid: "color-red",
    Paid: "color-green",
    Partially: "color-orange",
    Overdue: "color-red",
    Cancelled: "color-red",
    Draft: "color-orange",
  };
  const mentionsList = useSelector((state) => state.customer.mentionList);
  const documentData = useSelector((state) => state.documentsReducer);
  const checklistStateData = useSelector((state) => state.customer.taskChecklist);
  const proposalComment = useSelector(
    (state) => state.benchmarkReducer.proposalComments
  );
  const { globalView, benchmarkDetail, operatorListByCategory ,imageviewerisOpen } = useSelector((state) => state.customer);
  const virtualSpaces = localStorage.getItem("selectedVirtualSpace");
  const savedDate = localStorage.getItem("selectedDate");
  const [showConvertLeadModal, setShowConvertLeadModal] = useState(false);
  const [leadType, setLeadType] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("")
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [toastClass, setToastClass] = useState("unsucess");
  const [showToast, setShowToast] = useState(false);  
  const link = useSelector((state) => state.customer.get_link);
  const [visibleToCustomer, setVisibleToCustomer] = useState(false);
  const [visibleToProvider, setVisibleToProvider] = useState(false);
  const [loder, setLoder] = useState(false);
  const [guestcommentCount, setguestcommentCount] = useState(1)
  const [CommentModalWidth, setCommentModalWidth] = useState(false);
  const [clientidForSubscription, setClientidForSubscription] = useState(0);
  const datatypes = [
    'invoice', 'estimate', 'staff', 'customer', 'subscription', 'credit_note', 'premiumEstimate'
  ]
  const isCommentSectionOpen = [
    "estimate",
    "staff",
    "customer",
    "contact",
    "equipment",
    "invoice",
    "credit_note",
    "subscription",
    "schedule",
    "benchmark",
    "spaceDocument",
    "pilotonboarding",
    "type_of_space",
    "equipment_task",
    "poi",
    "premiumEstimate",
    "gmail",
    "provider_page"
  ];
  const [modalHeaderIconToggle, setModalHeaderIconToggle] = useState(false);
  const [showDefaultIcons, setShowDefaultIcons] = useState([]); 
  const [taskRating, setTaskRating] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const scheduleChecklistId = searchParams.get("update-schedule-checklist");
  // useEffect(() => {
  //     if(isCommentSectionOpen.indexOf(docType) > -1)
  //     {
  //       setShowCommentSection(true);
  //     }
  // }, [isCommentSectionOpen])
  useEffect(() => {
      if(isCommentSectionOpen.indexOf(docType) > -1)
      {
        setShowCommentModal(true);
        setShowCommentSection(true);
        TaskCommentModalOpen();
      }
      
      const handleWindowResizeUpdate = () => {
        if(window.innerWidth <= 767)
        {
          document.body.classList.remove("modal-open");
        }
        else {
          document.body.classList.add("modal-open");
        }
      };
  
      window.addEventListener('resize', handleWindowResizeUpdate);
  
      return () => {
        window.removeEventListener('resize', handleWindowResizeUpdate);
    
      }
  }, [JSON.stringify(isCommentSectionOpen),docType])
  useEffect(() => {
    if(link && link != [])
    {
      if(link.data.newTaskFlag != 1 && link.data.newRequestFlag != 1 && link.data.task_id && link.data.task_id > 0)
      {
        addNewCommentTask(link.data.link,"",0,1);
      }
    }
  }, [link])

  useEffect(() => {
    if (
      window.location.pathname == "/documents" || window.location.pathname == "/MyEmail" || docType == "provider_page"
    ) {
      setShowFullscreen(true);
    } else if (data && data.provider == "google_drive" && data.is_synced) {
      setShowFullscreen(true);
    }
    if (docType == "spaceDocument") {
      setShowFullscreen(false);
    }
  }, [data]);
  useEffect(() => {
    if(isFullScreen){
    setShowFullscreen(true);
  }
  }, [isFullScreen]);
  useEffect(() => {
    if (["imageview"].includes(docType) && isCommentsection == false) {
      // setShowFullscreen(true);
      fetchComments();
      setShowCommentModal(true);
      setShowCommentSection(true);
      if(pageName == "calendar"){
        jquery("body").addClass("right-penel-no-padding");
        hideRightPanelNew();
        setShowFullscreen(true);
        return () => {
          jquery("body").removeClass("right-penel-no-padding");
          hideRightPanelNew();
          setShowFullscreen(false);
        }
      }else{
        TaskCommentModalOpen();
      }
    }
    else if(["schedule", "benchmark", "proposal", "subscription"].includes(docType) && !isExternal) {
      setShowFullscreen(true);
    }else if(['spaceDocument'].includes(docType)){
      jquery("body").addClass("task-modal-offcanvas-open");
      setShowCommentModal(false);
      setShowCommentSection(false);
      CloseCommentModal();
    } 
  }, [docType]);
  useEffect(() => {
    if (globalView == "kanbanView") {
      setShowFullscreen(true);
    }
  }, [globalView]);
  useEffect(() => {
    if (isExternal) {
      setShowCommentModal(true);
    }
  }, [isExternal]);
  useEffect(() => {
    
    jquery(".office-list-icon,.office-box").on("mouseover", function () {
      setShowCommentSection(false);
      CloseCommentModal();
      setShowCommentModal(false);
    });

    jquery(".office-list-icon-search").on("click", function () {
      setShowCommentSection(false);
      CloseCommentModal();
      setShowCommentModal(false);
    });

    jquery(".office-list-icon-search").on("mouseover", function () {
      setShowCommentSection(false);
      CloseCommentModal();
      setShowCommentModal(false);
    });
  
    jquery(".team-box-open").on("click", function () {
      setShowCommentSection(false);
      CloseCommentModal();
      setShowCommentModal(false);
    });
  },[showCommentSection ,ShowCommentModal]);

  useEffect(() => {
    if (["equipment_task","type_of_space","poi"].includes(docType)) {
      dispatch(selectGlobalDocType(docType));
      jquery("#modal-overlay-toggle").removeClass("modal-overlay");
      jquery("body").addClass("task-modal-offcanvas-open");
    }
    if(selectedPoi && docType == "poi"){
      setCommonHeaderData({name:selectedPoi.name,x:selectedPoi.x,y:selectedPoi.y,z:selectedPoi.z,file:[],previewArray:selectedPoi.images,id:selectedPoi.id});
    }
    if (["equipment_task","type_of_space","poi"].includes(docType) && (selectedEquipment || selectedRoom || selectedPoi)) {
      let rel_id = 0;
      if (docType == "poi") {
        rel_id = selectedPoi.id
      } else if (docType == "type_of_space") {
        rel_id = selectedRoom.roomId
      } else if (docType == "equipment_task") {
        rel_id = selectedEquipment.id
      }

      dispatch(getComments(rel_id,docType,1));
    }
  },[docType,JSON.stringify(selectedPoi),JSON.stringify(selectedEquipment),JSON.stringify(selectedRoom)])
  
  useEffect(() => {
    jquery("#client_3d_optionfirst").css({ 'display': 'none' });
    jquery(".dropdown-menu").removeClass("show");
    dispatch(closeOutsideClick(false));
  }, [docType])

  useEffect(() => {
    setCommonHeaderBodyData({});
    if (docType != "poi") { 
    setCommonHeaderData({});
    }
    if (data) {
      let tempDocumentId =
      docType == "pilotonboarding"
      ? data.document_id 
        ? data.document_id
        : 0
      : docType == "request-task"
          ? data.id
            ? data.id
            : data.task_id
          : data.id
          ? data.id
          : docType == "gmail"
          ? data["conversation"]["email_id"]
          : 0
          ? data.id
            ? data.id
            : docType == "document"
            ? data.id
            : 0
          : 0 
          setSelectedId(tempDocumentId);
      tempDocumentId = docType == "imageview" ? data.taskId : tempDocumentId;
      let documentModalId = localStorage.getItem("modalTypeId");
      if (
        !isExternal &&
        (!documentModalId || documentModalId != tempDocumentId)
      ) {
        localStorage.setItem("modalTypeId", tempDocumentId);
        dispatch(
          getMentionList(
            tempDocumentId,
            docType == "request-task" || docType == "imageview" ? "task" : docType,
            isExternal ? 1 : 0
          )
        );
      }
      else if(userType == "operator")
      {
        if(data.proposal_id)
        {
          dispatch(getMentionList(data.proposal_id, 'proposal'));
        }
        // else {
        //   dispatch(getMentionList(data.id, 'schedule'));
        // }
      }
      else if (isExternal) {
        dispatch(
          getMentionList(
            tempDocumentId,
            docType == "request-task" || docType == "imageview" ? "task" : docType,
            isExternal ? 1 : 0,
            data.project_id,
            data.is_added_from_contact,
            data.addedfrom,
            data.user_type
          )
        );
      }

      let addFromDetails =
        data && (data.addedfrom_details || data.creator_details) ? true : false;

      let headerInfo = {};
      switch (docType) {
        case "invoice":
          setDocumentTitle(data.name);
          headerInfo = {
            name: `${addFromDetails ? data.addedfrom_details.firstname : ""} ${
              addFromDetails ? data.addedfrom_details.lastname : ""
            }`,
            profile_image: addFromDetails
              ? data.addedfrom_details.profile_image
              : "",
            doc_name: data.name ? data.name : "",
            url: data.invoice_link ? data.invoice_link : "",
          };
          setDocumentAddedFom(headerInfo);
          setExternalPageLink(
            `${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${data.hash}`
          );
          setCommentCount(data.comment_count ? data.comment_count : 0);
          break;
        case "estimate":
        case "premiumEstimate":
          headerInfo = {
            name: `${addFromDetails ? data.addedfrom_details.firstname : ""} ${
              addFromDetails ? data.addedfrom_details.lastname : ""
            }`,
            profile_image: addFromDetails
              ? data.addedfrom_details.profile_image
              : "",
            doc_name: data.name ? data.name : "",
            url: data.estimate_link ? data.estimate_link : "",
          };
          setDocumentTitle(data.name);
          setDocumentAddedFom(headerInfo);
          setExternalPageLink(
            `${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${data.hash}`
          );
          setCommentCount(data.comment_count ? data.comment_count : 0);
          break;
        case "spaceDocument":
          headerInfo = {
            name: `${addFromDetails ? data.addedfrom_details.firstname : ""} ${
              addFromDetails ? data.addedfrom_details.lastname : ""
            }`,
            profile_image: addFromDetails
              ? data.addedfrom_details.profile_image
              : "",
            doc_name: data.title ? data.title : "",
            // url: data.estimate_url ? data.estimate_url : "",
          };
          setDocumentTitle(data.name);
          setDocumentAddedFom(headerInfo);
          setExternalPageLink(
            `${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${data.hash}`
          );
          setCommentCount(data.comment_count ? data.comment_count : 0);
          break;
        case "subscription":
          headerData.date_read_only = data.status != "not_subscribed";
          headerInfo = {
            name: `${addFromDetails ? data.addedfrom_details.firstname : ""} ${
              addFromDetails ? data.addedfrom_details.lastname : ""
            }`,
            profile_image: addFromDetails
              ? data.addedfrom_details.profile_image
              : "",
            doc_name: data.name ? data.name : "",
          };
          setDocumentTitle(data.name);
          setDocumentAddedFom(headerInfo);
          setExternalPageLink(
            `${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${data.hash}`
          );
          setCommentCount(data.comment_count ? data.comment_count : 0);
          break;
        case "benchmark":
          
          headerInfo = {
            name: `${addFromDetails ? data.creator_details.full_name : ""}`,
            profile_image: addFromDetails
              ? data.creator_details.profile_image_url
              : "",
            doc_name: getScheduleTitle(data),
          };
          setDocumentTitle(data.name);
          setDocumentAddedFom(headerInfo);
         
          break;
        case "schedule":
          headerInfo = {
            name: `${addFromDetails ? data.creator_details.full_name : ""}`,
            profile_image: addFromDetails
              ? data.creator_details.profile_image_url
              : "",
              doc_name: getScheduleTitle(data),
          };
          setDocumentTitle(data.name);
          setDocumentAddedFom(headerInfo);
          break;
        case "lead":
          headerInfo = {
            doc_name: `${data.lead_profile.company} - ${data.lead_profile.name}`,
            profile_image: "",
            name: "",
          };
          setDocumentTitle(
            `${data.lead_profile.company} - ${data.lead_profile.name}`
          );
          setComments(data.lead_activities || []);
          break;
        case "gmail":
          headerInfo = {
            doc_name: `${
              data["conversation"]["messages"][0]["payload"]["headers"].filter(
                (header) => header["name"] == "Subject"
              )[0]["value"]
            }`,
            profile_image: `${data["thread"]["profile"]}`,
            name: `${extractEmails(
              data["conversation"]["messages"][0]["payload"]["headers"].filter(
                (header) => header["name"] == "From"
              )[0]["value"]
            )}`,
          };
          setDocumentTitle(
            `${
              data["conversation"]["messages"][0]["payload"]["headers"].filter(
                (header) => header["name"] == "Subject"
              )[0]["value"]
            }`
          );
          setCommentCount(data["thread"]["comments"].length);
          setComments(data["thread"]["comments"] || []);
          break;
        case "credit_note":
          headerInfo = {
            name: `${addFromDetails ? data.addedfrom_details.firstname : ""} ${
              addFromDetails ? data.addedfrom_details.lastname : ""
            }`,
            profile_image: addFromDetails
              ? data.addedfrom_details.profile_image
              : "",
            doc_name: data.credit_note_number ? data.credit_note_number : "",
          };
          setDocumentTitle(data.credit_note_number);
          setDocumentAddedFom(headerInfo);
          setExternalPageLink(
            `${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${data.hash}`
          );
          setCommentCount(data.comment_count ? data.comment_count : 0);
          break;
          setCommentCount(data["thread"]["comments"].length);
          setComments(data["thread"]["comments"] || []);
          break;
        case "document":
          headerInfo = {
            name: `${addFromDetails ? data.addedfrom_details.firstname : ""}`,
            profile_image: addFromDetails
              ? data.addedfrom_details.profile_image
              : "",
            doc_name: data.name ? data.name : "",
            url: data.document_url ? data.document_url : "",
          };
          setDocumentAddedFom(headerInfo);
          setDocumentTitle(`${data.name}`);
          setCommentCount(`${data.comments_count}`);
          setComments(`${[]}`);
          break;
        case "pilotonboarding":
          headerInfo = {
            name: `${addFromDetails ? data.addedfrom_details.full_name : ""}`,
            profile_image: addFromDetails
              ? data.addedfrom_details.profile_image
              : "",
          };
          setDocumentAddedFom(headerInfo);
          break;
        case "imageview":
          setSelectedAttachment(data.attachmentDetails || {});
          break;
        default:
          break;
      }

      if (docType == "task" || docType == "request-task") {
        dispatch(getFloorDetails(data.project_id));
        getTaskDetails(docType == "request-task" ? true : false);
      } else {
        var status_text = data.status_text ? data.status_text : "";
        if(docType == "benchmark" || docType == "schedule"){
          status_text = data.is_schedule == 0 ? data.is_opened == 1 ? _l('l_open') : _l('l_close')  : data.status_text;
        }
        setCommonHeaderData({
          creater_name: headerInfo ? headerInfo.name : "",
          creater_profile: headerInfo ? headerInfo.profile_image : "",
          document_name: headerInfo ? headerInfo.doc_name : "",
          document_name_permission: true,
          document_id:
            docType == "lead"
              ? data.lead_id
              : data.id
              ? data.id
              : docType == "document"
              ? data.id
              : data.id
              ? data.id
              : docType == "gmail"
              ? data["thread"]["id"]
              : 0,
          task_redirect_btn_permission: false,
          task_redirect_btn_text: "",
          document_type: docType,
          show_full_screen: showFullscreen,
          follow_btn_text: _l("l_follow"),
          show_task_myr_total: data.isRequestTask ? data.myr_total > 0 ? true : false :false,
          myr_total:
            docType == "benchmark" || docType == "schedule"
              ? "€" + data.max_budget
              : data.myr_total
              ? data.myr_total
              : data.total
              ? data.total
              : 0,
          myr_total_permission: false,
          status: data.status,
          status_text: status_text,
          selectedTab: "",
          isUnKnownBudget: data.budget_is_empty == "1" ? true : false,
          proposal_amount: data.operator_total || 0,
          proposal_description: data.proposal_description || "",
          proposal_id: data.proposal_id,
          proposal_status: data.proposal_status,
          is_opened: data.is_opened,
          assginee: data.assginee,
          is_assigneed: data.is_assigneed,
          start_date: data.startdate,
          duration: data.duration,
          surface: data.surface,
          rel_type: data.rel_type,
          attachment:data.map_attachment_url,
          schedule_id:data.schedule_id,
          selectedAssigneesNew: data.assignee_list_DEV,
          rating: data.rating,
          pills : pageName == "MyEmail" ? data['conversation']['messages'][0]['labelIds'][(data['conversation']['messages'][0]['labelIds'] || []).length - 1] : ""
        });
        // console.log(data.download_link , data.subscription_link, headerInfo && headerInfo.url,  data.image_url);
        // console.log(data, "data");
        setCommonHeaderBodyData({
          ...commonHeaderBodyData,
          date_tooltip:
            docType == "lead"
              ? _l("l_appointment_date")
              : docType == "gdrive"
              ? _l("created_date")
              : docType == "gmail"
              ? _l("l_mail_date")
              : _l("l_start_date"),
          date:
            docType == "lead"
              ? data.lead_profile.appointment_date
                ? new Date(data.lead_profile.appointment_date)
                : ""
              : data.date
              ? new Date(data.date)
              : docType == "gmail"
              ? new Date(
                  data["conversation"]["messages"][0]["payload"][
                    "headers"
                  ].filter((header) => header["name"] == "Date")[0]["value"]
                )
              : "",
          date_readonly: docType == "gmail" ? true : headerData.date_read_only,
          is_pinned:
            docType == "lead"
              ? data.lead_profile && data.lead_profile.pinned == 1
                ? 1
                : 0
              : data.pinned == 1
              ? 1
              : docType == "gmail"
              ? data["thread"]["is_important"]
              : 0,
          status_class:
          docType == "document" ? 
          data.status == 1
                ? "in-progress-white"
                : data.status == 4
                ? "in-progress-blue"
                : "done"
          :docType == "lead"
              ? data.lead_profile.status == 1
                ? "in-progress-white"
                : data.lead_profile.status == 30
                  ? "in-progress-blue"
                  : data.lead_profile.status == 16 
                    ? 'color-green'
                    : data.lead_profile.status == 28
                      ? 'color-yellow' 
                      : "color-orange"
              : docType == "gmail" && data["thread"]["status"] == 1
              ? "done"
              : "in-progress-white",

          status_permission: docType == "leads" ? true : true,
          payment_mode: data.payment_mode,
          priority:
            docType == "lead"
              ? data.lead_profile.hot_leads == 1
                ? 1
                : 0
              : docType == "gmail"
              ? data["thread"]["is_saver"]
              : 0,

          priority_permission: false,
          common_permission: true,
          project_rel_id: false,
          recurring_data: {},
          document_id:
            docType == "lead"
              ? data.lead_id
                ? data.lead_id
                : ""
              : data.id || data.task_id
              ? data.id || data.task_id
              : docType == "gmail"
              ? data["thread"]["id"]
              : 0,
          tag_id: 0,
          assignee_providers:
            docType == "lead" ? data.lead_profile.assigned_list : data.task_assignee_provider,
          assignee_equipments: [],
          visible_to_client: false,
          visible_to_provider:false,
          convertedTaskId: 0,
          assigneeList: [],
          requestAssignee: [],
          url: data.download_link ? data.download_link : data.subscription_link ? data.subscription_link : headerInfo ? headerInfo.url : "",
          estimate_id: data.estimate_id,
          status_name: docType == "lead" ? data.lead_profile.status == 16 ? _l("l_todo") : data.lead_profile.status == 19  ? _l("l_inprogress"): data.lead_profile.status == 1 ? _l("l_done") : data.status : data.status,
          documentAssignees:
            docType == "gmail" ? data["thread"]["assignees"] : data.assignees,
          comment_read_only: false,
          spaceProjectId:
            docType == "lead"
              ? data.spaceProjectId
                ? data.spaceProjectId
                : 0
              : 0,
          hash: hash_id,
          show_task_myr_total: data.isRequestTask ? data.myr_total > 0 ? true : false :false,
          duration: data.duration,
          surface: data.surface,
          rel_type: data.rel_type,
          attachment:data.map_attachment_url,
          schedule_id:data.schedule_id,
          selectedAssigneesNew: data.assignee_list_DEV,
          rating : data.rating,
          contacts: contacts && contacts.filter((item) => item.is_primary == 1)[0],
          type: data.type,
          is_upgrade_need: data.is_upgrade_need,
          is_payment_id_setup: data.is_payment_id_setup,
          email : data.lead_profile && data.lead_profile.email,
          estimate_status: data.estimate_status,
          user_payment_id:data.user_payment_id,
          pills : pageName == "MyEmail" ? data['conversation']['messages'][0]['labelIds'][(data['conversation']['messages'][0]['labelIds'] || []).length - 1] : ""
        });
      }
      if (isExternal && docType != "request-task" && docType != "task") {
        dispatch(
          getComments(
            docType == "gmail" ? data["thread"]["id"] : data.id,
            docType,
            1
          )
        );
        return;
      }
      setTaskRating(data && data.rating && data.rating ? data.rating : 0)
    }
    if (docType == "poi" && selectedPoi == "") {
      setCommonHeaderData({});
      setCommonHeaderData({name:"",file:[],previewArray:[],x:setPOILatLng.x,y:setPOILatLng.y,z:setPOILatLng.z});
    }
    setLoading(false);
    if(docType != "imageview"){
      dispatch(commanoffcanvasisOpen(true));
    }
    if (data && data.clientid) {
      setClientidForSubscription(data.clientid);    
    }
  }, [JSON.stringify(data), JSON.stringify(docType),JSON.stringify(selectedPoi)]);
  useEffect(() => {
    getMentionlist(mentionsList && mentionsList.length ? mentionsList : []);
  }, [mentionsList]);

  useEffect(() => {
    if (type !== "proposal") {
      if (comments && comments.length) {
        setCommentCount(comments.length);
      } else {
        setCommentCount(0);
      }
    }
  }, [comments]);
  useEffect(() => {
    if (
     
      documentData &&
      documentData[`${docType == "imageview" || docType == "request-task" ? "task" : docType}_comments`] &&
      documentData[`${docType == "imageview" || docType == "request-task" ? "task" : docType}_comments`].length
    ) {
      let rel_type = docType == "imageview" || docType == "request-task" ? "task" : ((userType == "operator" && data && data.proposal_id ? data.proposal_id : docType ) || type == "proposal") > 0 ? "proposal" : docType;
      let rel_id = 0
      if (docType == "poi") {
        rel_id = selectedPoi.id
      } else if (docType == "type_of_space") {
        rel_id = selectedRoom.roomId
      } else if (docType == "equipment_task") {
        rel_id = selectedEquipment.id 
      }else if(docType == "imageview" ){
        rel_id = data.taskId 
      } else if (docType == "gmail") {
        rel_id = data["thread"]["id"]
      } else if (userType == "operator" && data && data.proposal_id) {
        rel_id = data.proposal_id
      } else if (type == "proposal") {
        rel_id = localStorage.getItem("proposalId")
      } else {
        rel_id = data && data.id
      }
      const index = documentData[`${rel_type}_comments`].findIndex(
        (x) => x.id == rel_id
      );
      if (index !== -1) {
        let formattedComments = documentData[`${rel_type}_comments`][index].comments;
        formattedComments = formattedComments && formattedComments.map((item) => {
          item["description"] = datatypes.indexOf(docType) != -1  ? item["description"] :item["content"];
          item["profile_url"] = datatypes.indexOf(docType) != -1  ? item["profile_url"] :
            item["addedfrom_details"] &&
            item["addedfrom_details"]["profile_url"];
          item["attachments"] =
            item["attachments"] &&
            item["attachments"].map((file) => {
              file["file_name"] = file.file_url || file.image_url;
              return file;
            });
          return item;
        });
        setComments(formattedComments);
        setdefaultComment(formattedComments);
      } else if(window.location.pathname === '/documents' && data.provider == "one_drive")
      {
        let index = documentData[`task_comments`] && documentData[`task_comments`].length ? documentData[`task_comments`].length : null;
        let formattedComments = index && documentData[`task_comments`][index - 1].comments ? documentData[`task_comments`][index - 1].comments : [];
        if(formattedComments)
        {
          formattedComments = formattedComments && formattedComments.map((item) => {
            item["description"] = item["content"];
            item["profile_url"] =
              item["addedfrom_details"] &&
              item["addedfrom_details"]["profile_url"];
            item["attachments"] =
              item["attachments"] &&
              item["attachments"].map((file) => {
                file["file_name"] = file.file_url || file.image_url;
                return file;
              });
            return item;
          });
          setComments(formattedComments);
          setdefaultComment(formattedComments);
        } else {
          setComments([]);
          setdefaultComment([]);
        }
      }
    }
  }, [documentData,selectedPoi]);

  useEffect(() => {
    if (defaultTopics && defaultTopics.length) {
      if (isExternal) { 
        getTaskDetails(docType == "request-task" ? true : false);
      }
      const providerTopics = defaultTopics.filter(
        (x) =>
          x.main_key === (userType == "contact" ? "provider_task" : "proposals")
      );

      let requestAssigneeList = [];
      defaultTopics.forEach((topic) => {
        switch (topic.main_key) {
          case "my_task":
            topic.categories.forEach((my_task) => {
              if (!isNaN(parseInt(my_task.filter_key))) {
                requestAssigneeList.push({
                  main_key: topic.main_key,
                  type:
                    requestAssigneeList.findIndex(
                      (x) => x.type == topic.title
                    ) === -1
                      ? topic.title
                      : "",
                  name: my_task.title,
                  id: my_task.filter_key,
                  is_assigned: my_task.is_assigned || 0,
                  image: my_task.image || "",
                });
              }
            });
            break;
          case "provider_task":
            topic.categories.forEach((provider) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: provider.title,
                id: provider.filter_key,
                is_assigned: provider.is_assigned || 0,
                image: provider.image || "",
              });
            });
            break;

          case "my_people":
            topic.categories.forEach((provider) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: provider.title,
                id: provider.filter_key,
                is_assigned: provider.is_assigned || 0,
                image: provider.image || "",
              });
            });
            break;

          case "proposals":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;

          case "user_journey":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;

          case "myr_staff_task":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;
          case "task_topic":
            if (virtualSpaces && Object.keys(virtualSpaces).length) {
              topic.categories.forEach((proposal) => {
                requestAssigneeList.push({
                  main_key: topic.main_key,
                  type:
                    requestAssigneeList.findIndex(
                      (x) => x.type == topic.title
                    ) === -1
                      ? topic.title
                      : "",
                  name: proposal.title,
                  id: proposal.filter_key,
                  is_assigned: proposal.is_assigned || 0,
                  image: proposal.image || "",
                });
              });
            }
            break;

          default:
            break;
        }
      });

      setAssigneeDropdownList(requestAssigneeList);
    }
  }, [defaultTopics]);

  const downloadUrl = (url) => {
    window.open(url, "_blank");
  };
  const pinDocuments = (id) => {
    customerServices.pinDocuments(id, docType).then((res) => {
      if (res.status == 1) {
        pinHandler(id, docType, 0);
      }
    });
  };
  const addEditBenchmark = () => {
    customerServices.addEditBenchmarkStep1(
      projectId,
      data.startdate,
      data.links,
      data.need_to_hire_people,
      data.is_furnished,
      data.provider_id,
      [],
      data.id,
      data.basic_information,
      data.max_budget,
      data.start_date_is_empty,
      data.isUnKnownBudget,
      [],
      data.proposal_emails,
      [],
      data.contract_end_date,
      "clientId",
      data.operator_details ? data.operator_details : [],
      0
    );
  };
  const handleCommonAction = async(type, data) => {
    if (type == "modalClose") {
      if(scheduleChecklistId > 0)
      {
      searchParams.delete('update-schedule-checklist');
      searchParams.delete('task-day');
      searchParams.delete('operator');
      searchParams.delete('view');
      setSearchParams(searchParams);

        // await updateScheduleTasks();
      }
      setShowCommentSection(false);
      if (docType !="imageview") {
        dispatch(SetSelectedTask({}));
      }
      localStorage.removeItem("modalTypeId");
      let isRequestTask = docType == "request-task" ? true : false;
      if ((docType == "task" || docType == "request-task") && selectedTaskId > 0) {
        hideTaskModal(isRequestTask ? 1 : 0);
        if(scheduleChecklistId > 0)
        {
          toggleLeftPanelAndOffcanvasClasses(true);
        }
        else if(window.location.pathname === "/providerlist")
        {
          TaskCommentModalOpen()
          dispatch(getComments(localStorage.getItem("currentCompanyID"), "provider_page", isExternal, "", 1, 0)); 
        }
        else
        {
          TaskModalClose(onCloseShowLeftPanelDefault);
        }
        dispatch(updateedTaskChecklist([]));
      } else if(docType == "equipment_task"){
        clearData();
      } else if(docType === "provider_page"){
        clearData();
        TaskCommentModalOpen()
      } else {
        handleClose();
      }
    } else if(type == "poi_title"){
      setCommonHeaderData({...commonHeaderData,name:data});
    } else if(type == "poi_files"){
      handleFileUploadForPOIs(data);
    } else if(type == "poi_create"){
      create3dModelPois()
    } else if (type == "showFullscreen") {
      setShowFullscreen(data);
      if (!showCommentSection && data) {
        fetchComments();
      }
      setShowCommentSection(true);
      TaskCommentModalOpen();
      /*When open image viewer from comment section after open detail modal in full view*/
      jquery(".stand-alone-comments").removeClass("d-none");
    } else if (type == "editSchedule") {
      addEditBenchmark();
    } else if (type == "follow") {
      setShowLink(true);
    } else if (type == "nameUpdate") {
      if (docType == "task" || docType == "request-task") {
        updateTaskData(
          commonHeaderBodyData.dateType == "startdate" &&
            commonHeaderBodyData.date != ""
            ? commonHeaderBodyData.date
            : "",
          commonHeaderBodyData.dateType == "duedate" &&
            commonHeaderBodyData.date != ""
            ? commonHeaderBodyData.date
            : "",
          undefined,
          commonHeaderBodyData.visible_to_client
            ? commonHeaderBodyData.visible_to_client
            : false,
          commonHeaderBodyData.visible_to_provider
          ? commonHeaderBodyData.visible_to_provider
          : false,
          data
        );
      }
    } else if (type == "setMyr_total") {
      setMyr_total(data);
      setCommonHeaderBodyData({ ...commonHeaderBodyData, ["myr_total"]: data });
    } else if (type == "myrTotalUpdate") {
      if (
        commonHeaderBodyData &&
        commonHeaderBodyData.assignee_providers_data.length
      ) {
        updateTaskData("", "", data);
      } else {
        assignToProviderTask(providerType, providerValue, providerDetail, data);
      }
    } else if (type == "totalRef") {
      totalRef = data;
    } else if (type == "taskMoveBtn") {
      if (docType == "task") {
        dispatch(
          getSelectedTask(
            projectId,
            commonHeaderData && commonHeaderData.convertTaskId,
            1
          )
        );

        handleParentComponentAction("convertedTaskAction", {
          convertTaskId: commonHeaderData && commonHeaderData.convertTaskId,
          isShow: "show",
          isRequestTask: 1,
          task_id: commonHeaderData.document_id
            ? commonHeaderData.document_id
            : 0,
        });
      } else if (docType == "request-task") {
        dispatch(
          getSelectedTask(
            localStorage.getItem("selectedOffice"),
            commonHeaderData && commonHeaderData.convertTaskId
          )
        );
        handleParentComponentAction("convertedTaskAction", {
          convertTaskId: commonHeaderData && commonHeaderData.convertTaskId,
          isShow: "show",
          isRequestTask: null,
          task_id: commonHeaderData && commonHeaderData.document_id,
        });
      }
    }
    return;
  };

  const updateSubscriptionDate = async(newDate, currentData) => {
    const currentDate = moment();
    const minDate = moment(addDays(new Date(), 10));
    const dayOfMonth = moment(newDate).date();
  
    if (moment(newDate).isAfter(minDate) && (dayOfMonth < 29 || dayOfMonth > 31) && currentData.status_name === "not_subscribed") {
      try {
        const response = await DocumentsServices.updateSubscriptionDate(currentData.document_id, moment(newDate).format("YYYY-MM-DD"));
        if(!response.status)
        {
          showError(response.message);
        }
      }
      catch(e)
      {
        showError("l_something_went_wrong");
      }
    } 
    else {
      showMessage("l_invalid_date");
    }
  };

  const handleCommonHeaderBodyAction = (type, tempData, sub_categories, sub_category, extraId = "") => {
    switch (type) {
      case "dateUpdate":
        data.date = tempData;
        break;
      case "dateUpdate":
        data.date = tempData;
        break;
    }
    // Move below if else section to above switch statements
    if (type == "pin") {
      if (docType == "lead") {
        pinLead(tempData);
      } else if (docType == "task" || docType == "request-task") {
        handlePinTask();
      } else if (docType == "gmail") {
        changeMailPin(tempData);
      } else {
        pinDocuments(tempData);
      }
    } else if (type == "downloadUrl") {
      downloadUrl(tempData);
    } else if (type == "priority") {
      if (docType == "lead") {
        handleHotLead(tempData);
      } else if (docType == "task" || docType == "request-task") {
        changeTaskPriority();
      } else if (docType == "gmail") {
        changeMailPriority(tempData);
      }
    } else if (type == "status") {
      if (docType == "lead") {
        let tempStatus = tempData == 1 ? 1 : tempData == 3 ? 3 : tempData == 16 ? 16 : tempData == 28 ? 28 : 30;
        handleStatusChangeLead(data.lead_id, tempStatus);
      } else if (docType == "task" || docType == "request-task") {
        changeTaskStatus(tempData);
      } else if (docType == "gmail") {
        changeMailStatus(tempData);
      }
    } else if (type == "dateUpdate") {
      if (docType == "task" || docType == "request-task") {
        let index = -1 
        let tempdata = taskList

        index = tempdata.findIndex((task) => task.id == data.id)
        if(index > -1){
          let newtempData = moment(tempData).format("YYYY-MM-DD HH:mm:ss")  
          commonHeaderBodyData.dateType == "startdate" ?  tempdata[index].startdate = newtempData :
          tempdata[index].duedate = newtempData 
        }
      dispatch(updateTaskList(tempdata))

        updateTaskData(
          commonHeaderBodyData.dateType == "startdate" ? moment(tempData).format("YYYY-MM-DD HH:mm:ss") : "",
          commonHeaderBodyData.dateType == "duedate" ? moment(tempData).format("YYYY-MM-DD HH:mm:ss") : ""
        );
      }
      else {
        updateSubscriptionDate(tempData, commonHeaderBodyData)
      }
    } else if (type == "project") {
      if (docType == "task") {
        handleChangeTaskProject(tempData.id, tempData.name);
      }
    } else if (type == "recurring") {
      handleSetRecurringTask(tempData);
    } else if (type == "assignees") {
      if (docType == "task") {
        handleProviderTask(tempData,sub_categories,sub_category);
      } else if (docType == "request-task") {
        handleRequestAssignee(tempData,sub_categories,sub_category);
      } else if (
        docType === "estimate" ||
        docType === "subscription" ||
        docType === "invoice"
      ) {
        documentAssignee(tempData, docType, data.id);
      } else if (docType == "gmail") {
        handleParentComponentAction("shareEMail", {
          id: data.id,
          value: tempData,
          docType: "docType",
        });
      }
    } else if (type == "tag") {
    } else if (type == "handleTaskAssigneeData") {
      // handleTaskAssigneeData()
    } else if (type == "visible_to_client") {
      if (docType == "task" || docType == "request-task" ) {
        updateTaskData(undefined, undefined, undefined, tempData, visibleToProvider);
      }
    } else if (type == "visible_to_provider") {
      if (docType == "task" || docType == "request-task") {
        updateTaskData(undefined, undefined, undefined, visibleToCustomer, tempData);
      }
     } else if (type == "requestTaskConvert") {
      if (data && data.is_allowed_to_edit == 1) {
        if (data.request_task_id == 0) {
          if (data.checklist == 0) {
            dispatch(
              showMessage(
                "unsucess",
                _l("l_error"),
                _l("l_please_enter_valid_checklist")
              )
            );
            return;
          } else {
            dispatch(
              showConfirmation(
                _l("l_confirm"),
                _l("l_only_one_action_perform_at_a_time"),
                _l("l_convert"),
                _l("l_cancel"),
                () => {},
                () => {
                  handleConvertTask(tempData.id, tempData.type);
                }
              )
            );
          }
        }
      }
    } else if (type == "rightsideBtn") {
      RightSideBtnToggle = !RightSideBtnToggle;
    } else if (type == "subscribe") {
      subscribe(tempData);
    } else if (type == "documentEstimateReject") {
      dispatch(
        showConfirmation(
          _l("l_confirm"),
          _l("l_do_you_want_to_reject_estimate"),
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => {
            documentEstimateReject();
          }
        )
      );
    } else if (type == "documentEstimateAccept") {
      handleEstimateStatus(tempData);
    } else if (type == "convert_as_client") {
      setLeadType(tempData);
      setShowConvertLeadModal(true);
    } else if (type == "access_space") {
      let convertLeadDetails = localStorage.getItem("convertLeadDetails")
        ? JSON.parse(localStorage.getItem("convertLeadDetails"))
        : {};
      if (Object.keys(convertLeadDetails).length) {
        loginHandler(
          convertLeadDetails.email,
          convertLeadDetails.client_id,
          convertLeadDetails.project_id
        );
      }
    }
    return;
  };
  const pinLead = (id) => {
    dispatch(handleLeadPinUnpin(id));
  };

  const handleHotLead = (id) => {
    dispatch(hotLeadSetUnset(id));
  };

  const handleStatusChangeLead = (id, status) => {
    dispatch(leadStatusChange(id, status));
  };
  const updateTaskData = (
    startDateTemp = "",
    dueDateTemp = "",
    total = 0,
    visibility,
    visibleToProvider,
    taskName
  ) => {
    // return
    let existDueDate =
      commonHeaderBodyData.dateType == "duedate"
        ? commonHeaderBodyData.date
          ? commonHeaderBodyData.date
          : ""
        : "";
    let existStartDate =
      commonHeaderBodyData.dateType == "startdate"
        ? commonHeaderBodyData.date
          ? commonHeaderBodyData.date
          : ""
        : "";
    let existMyr_total = commonHeaderData.myr_total
      ? commonHeaderData.myr_total
      : 0;
    let existTaskName =
      taskName || commonHeaderData.document_name
        ? commonHeaderData.document_name
        : "";
    dispatch(
      setTaskData(
        projectId,
        commonHeaderData.document_id ? commonHeaderData.document_id : 0,
        taskName || existTaskName,
        startDateTemp || existStartDate,
        dueDateTemp || existDueDate,
        total || existMyr_total,
        0,
        0,
        0,
        "",
        "",
        visibility,
        visibleToProvider
      )
    );

    if (startDateTemp || dueDateTemp)
      dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate));
  };
  const hideTaskModal = (is_request_task = 0) => {
    // let validateTaskInfo = is_request_task && !CUSTOM_CLIENT_IDS.includes(client_id)
    let validateTaskInfo = is_request_task && userType == "contact" && CUSTOM_ASSIGNEE_CHANGE.includes(contact_role)
      ? getAssigneesRequestTask().length == 0 &&
        data.request_task_id == 0 &&
        data &&
        data.is_allowed_to_edit == 1
      : commonHeaderBodyData &&
        commonHeaderBodyData.common_permission &&
        commonHeaderData.document_name == "";
    if (validateTaskInfo) {
      dispatch(
        showConfirmation(
          _l("l_confirm"),
          _l("l_do_you_want_to_save_the_task_as_draft"),
          _l("l_save"),
          _l("l_delete"),
          () => {
            deleteTask(is_request_task == 1 ? 1 : 0);
          },
          () => {
            handleSaveTask(is_request_task == 1 ? 1 : 0);
          }
        )
      );
      return;
    }

    handleParentComponentAction("handleSelectedTask", {
      task_id: commonHeaderData.document_id ? commonHeaderData.document_id : 0,
      flag: false,
    });
    handleClose();
    dispatch(SetSelectedTask(""));
    
  };
  const handleSaveTask = (is_request_task = 0) => {
    let validateTaskInfo = is_request_task
      ? getAssigneesRequestTask.length == 0 &&
        data.request_task_id == 0 &&
        data &&
        data.is_allowed_to_edit == 1
      : commonHeaderBodyData &&
        commonHeaderBodyData.common_permission &&
        commonHeaderData.document_name == "";
    if (validateTaskInfo) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          is_request_task == 1
            ? _l("l_please_select_assignees")
            : _l("l_please_enter_task_name_tosave_the_task")
        )
      );
      return;
    }
    hideTaskModal(is_request_task);
  };
  const deleteTask = () => {
    setShowLoader(true);
    customerServices
      .deleteTask(
        commonHeaderBodyData && commonHeaderBodyData.document_id,
        projectId
      )
      .then((res) => {
        setShowLoader(false);
        if (res.status === 1) {
          dispatch(
            getTaskCount(client_id, contact_id, projectId, selectedDate)
          );
          handleClose();
          
        } else {
          dispatch(showMessage("unsucess", _l("l_error"), res.message));
        }
      });
  };
  const changeTaskStatus = (statusId) => {
    dispatch(
      updateTaskStatus(
        commonHeaderData.document_id ? commonHeaderData.document_id : 0,
        projectId,
        statusId
      )
    ).then((res) => {
      if (res) {
        let isAPICall = false;
        if (
          (statusId == "task_convert_to_request" && docType == "task") ||
          (statusId != "task_convert_to_request" && docType == "request-task")
        ) {
          isAPICall = true;
        }
        if (isAPICall) {
          dispatch(
            getSelectedTask(
              localStorage.getItem("selectedOffice"),
              commonHeaderData.document_id ? commonHeaderData.document_id : 0,
              docType == "task" ? 1 : null,
              false,
              0
            )
          );
        }
        if (globalView == "spaceView") {
          dispatch(getArLocalAnchors(getSelectedFloorId))
        }
      }
    });

    dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate));
  };
  const changeTaskPriority = () => {
    dispatch(
      changePriority(
        commonHeaderData.document_id ? commonHeaderData.document_id : 0,
        projectId,
        commonHeaderBodyData.priority == 1 ? 3 : 2,
        data.provider
      )
    ).then(() => {});
  };
  const handleChangeTaskProject = (id, name) => {
    
    dispatch(
      changeTaskProject(
        id,
        commonHeaderData.document_id ? commonHeaderData.document_id : 0
      )
    ).then(() => {
      
      dispatch(
        showMessage("sucess", _l("l_success"), `Task Moved to Project ${name}`)
      );
      if (localStorage.getItem("selectedOffice") != id) {
        hideTaskModal();
        dispatch(SetSelectedTask(""));
      }
    });
  };
  const handleSetRecurringTask = (recurringData) => {
    setCommonHeaderBodyData({
      ...commonHeaderBodyData,
      ["recurring_data"]: recurringData,
    });
    customerServices
      .setTaskRecurring(
        projectId,
        commonHeaderData.document_id ? commonHeaderData.document_id : 0,
        recurringData
      )
      .then(() => {});
  };
  const handleProviderTask = (details,sub_categories,sub_category) => {
    if (details.main_key == "provider_task") {
      if (commonHeaderData.myr_total && commonHeaderData.myr_total > 0) {
        handleRequestAssignee(details,sub_categories,sub_category);
        
      } else {
        setShowMyrTotal(true);
        totalRef.focus();
        setProviderType(details.main_key);
        setProviderValue(details.filter_key);
        setProviderDetail(details);
      }
    } else {
      handleRequestAssignee(details,sub_categories,sub_category);
    }
  };
  const assignToProviderTask = (type, value, details, data) => {
    updateTaskData(undefined, undefined, myr_total);
    let selectedAssignees = {
      main_key: details.main_key,
      filter_key: details.id || details.filter_key,
      image: details.image || "",
      name: details.name || "",
      is_assigned: 1,
    };
    customerServices
      .setRequestTaskAssignee(commonHeaderBodyData.document_id, [
        selectedAssignees,
      ])
      .then((res) => {
        if (res.status && res.data && res.data.schedule_assignees) {
         
          dispatch(
            setTaskAssigneeNew(
              {
                filter_key: details.id || details.filter_key,
                title: details.name,
                image: details.image || "",
              },
              details.main_key,
              details.id || details.filter_key,
              commonHeaderBodyData.document_id,
              [],
              0,
              myr_total,
              res.data.schedule_assignees,
            )
          );
          
        } 
      });
  };
  const handleBodyActionTask = (type, taskData) => {
    if (type == "attachmentCountUpdate") {
      handleParentComponentAction(type, taskData);
    }
  };
  const handleCommonFooter = (type, handlerData) => {
    if (type == "addComment") {
      let pattern = handlerData.content.match("<(\"[^\"]*\"|'[^']*'|[^'\">])*>");
      const rel_id = userType == "operator" && data.proposal_id ? data.proposal_id :  data.document_id;
      const rel_type = userType == "operator" && data.proposal_id ? "proposal" : docType;
      const master_record_id = ["benchmark", "schedule"].includes(docType) ? data.document_id : 0;

      if (pattern === null ) {
      
        if (docType == "task" || docType == "request-task") {
          addNewCommentTask();
        } else if (docType == "lead") {
          let content = (handlerData && handlerData.content) || commentContent;
          dispatch(addLeadComment(data.lead_id, content));
          setCommentContent("");
          setCommentCount(commentCount + 1);
        } else {
          let content = (handlerData && handlerData.content) || commentContent;
          let attachments = handlerData && handlerData.files;
          let editId = handlerData && handlerData.editCommentId;
          dispatch(
            addEditDocumentComment(
              rel_type,
              rel_id,
              content,
              isExternal,
              editId,
              attachments,
              setShowCommentModal,
              setType,
              master_record_id
            )
          );
        }
        setShowCommentSection(true);
        TaskCommentModalOpen();
      } else {
        setToastHeader(_l("l_error"));
        setToastMessage(_l("l_please_enter_valid_comment"));
        setConfirmButtonText("");
        setCancelButtonText("");
        setToastClass("unsucess");
        setShowToast(true);
      }
    } else if (type == "commentValueChange") {
      setCommentContent(handlerData);
    } else if (type == "removePreview") {
      attchmentFiles = Array.from(attchmentFiles).filter(
        (img) => img.name !== handlerData
      );
    } else if (type == "fileUpload") {
      if (handlerData.target.files.length > 5) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_maximum_5_files_are_allowed_at_a_time")
          )
        );
        return;
      }
      attchmentFiles = handlerData.target.files;
    } else if (type == "getComment") {
      let proposalFlag = localStorage.getItem("preventCommentSectionToggle");
      setShowCommentSection(!showCommentSection || Boolean(proposalFlag));
      if(Boolean(proposalFlag))
      {
        localStorage.removeItem("preventCommentSectionToggle");
      }
      fetchComments();
      if (!showCommentSection || Boolean(proposalFlag)) {
        TaskCommentModalOpen();
      } else {
        TaskCommentModalToggle();
      }
    }
  };
  let guest_commet = [
    {
        doc_type:docType,
        doc_id:data && data.id ? data.id : 0,
        comment_count:guestcommentCount
    }
];
  const handleAddComment = (value) => {
    let taskId = docType == "imageview" ? data.taskId : commonHeaderData.document_id
    ? commonHeaderData.document_id
    : 0;
    if (isExternal) {
      
      if (guestcommentCount < 8) {
        if (type == "proposal") {
          dispatch(
            addEditDocumentComment(
              'proposal',
              proposalDetail.proposal_id,
              value.content.trim(),
              isExternal,
              value.editCommentId,
              value.files,
              () => {},
              () => {},
              data.id
            )
          );
        } else {
          let content = (value && value.content) || commentContent;
          let attachments = value && value.files;
          let editId = value && value.editCommentId;
  
          if ((content && content.trim().length) || attachments.length) {
            switch (docType) {
              case "lead":
                if (editId > 0) {
                  dispatch(editLeadActivity(data.lead_id, editId, content.trim()));
                } else {
                  dispatch(addLeadComment(data.lead_id, content.trim()));
                  setCommentCount(commentCount + 1);
                  setguestcommentCount(guestcommentCount + 1)
                }
                setCommentContent("");
                break;
              case "task":
                addNewCommentTask(content, attachments, editId);
                break;
              case "request-task":
                addNewCommentTask(content, attachments, editId);
                break;
              case "imageview":
                addNewCommentTask(content, attachments, editId, hash_id);
                break;
              default:
                const rel_id = docType == "gmail" ? data["thread"]["id"] : userType == "operator" && data.proposal_id ? data.proposal_id :  data.id;
                const rel_type = userType == "operator" && data.proposal_id ? "proposal" : docType;
                const master_record_id = ["benchmark", "schedule"].includes(docType) ? data.document_id : 0;
                dispatch(
                  addEditDocumentComment(
                    rel_type,
                    rel_id,
                    // docType == "gdrive" ? data.id : data.id,
                    content,
                    isExternal,
                    editId,
                    attachments,
                    setShowCommentModal,
                    setType,
                    master_record_id
                  )
                );
                break;
            }
          } 
        }
        localStorage.setItem("guest_comment", JSON.stringify(guest_commet));
      }         
    } else {
    let pattern = null;
    if (pattern === null ) {
      if (type == "proposal") {
        dispatch(
          addEditDocumentComment(
            'proposal',
            proposalDetail.proposal_id,
            value.content.trim(),
            isExternal,
            value.editCommentId,
            value.files,
            () => {},
            () => {},
            data.id
          )
        );
      } else {
        let content = (value && value.content) || commentContent;
        let attachments = value && value.files;
        let editId = value && value.editCommentId;

        if ((content && content.trim().length) || attachments.length) {
          switch (docType) {
            case "lead":
              if (editId > 0) {
                dispatch(editLeadActivity(data.lead_id, editId, content.trim()));
              } else {
                dispatch(addLeadComment(data.lead_id, content.trim()));
                setCommentCount(commentCount + 1);
              }
              setCommentContent("");
              break;
            case "task":
              addNewCommentTask(content, attachments, editId);
              break;
            case "request-task":
              addNewCommentTask(content, attachments, editId);
              break;
            case "imageview":
              addNewCommentTask(content, attachments, editId, hash_id);
              break;
            default:
              let rel_id = 0;
              let rel_type = 0;
              if (docType == "poi") {
                rel_id = commonHeaderData.id
              } else if (docType == "type_of_space") {
                rel_id = selectedRoom.roomId
              } else if (docType == "equipment_task") {
                rel_id = selectedEquipment.id  
              } else if (docType == "gmail") {
                rel_id = data["thread"]["id"]
              } else if (userType == "operator" && data.proposal_id) {
                rel_id = data.proposal_id
              } else if (docType == "provider_page") {
                rel_id = localStorage.getItem("currentCompanyID")
              } else {
                rel_id = data.id
              } 

              if (userType == "operator" && data.proposal_id) {
                rel_type = "proposal"
              } else {
                rel_type = docType
              }
              const master_record_id = ["benchmark", "schedule"].includes(docType) ? data.document_id : 0;
              dispatch(
                addEditDocumentComment(
                  rel_type,
                  rel_id,
                  content,
                  isExternal,
                  editId,
                  attachments,
                  setShowCommentModal,
                  setType,
                  master_record_id
                )
              );
              break;
          }
        } 
      }
   }else {
    setToastHeader(_l("l_error"));
    setToastMessage(_l("l_please_enter_valid_comment"));
    setConfirmButtonText("");
    setCancelButtonText("");
    setToastClass("unsucess");
    setShowToast(true);
   }}
  };

  const updateScheduleTasks = async() => {
    try {
      setShowLoader(true);
      const scheduleChecklists = checklistStateData.filter((w) => w.update_schedule_id > 0);
      const response = await benchmarkServices.updateBenchmarkChecklist(benchmarkDetail.project_id, scheduleChecklists, benchmarkDetail.id);
      if(response.status)
      {
        showMessage("l_schedule_updated");
      }
    }
    catch(e)
    {
      console.error(e);
    }
    finally {
      setShowLoader(false);
    }
  }

  const handleCloseCommentSection = async() => {
    setShowCommentSection(false);
    CloseCommentModal();
    setShowCommentModal(false);
    setType("");
    localStorage.setItem("proposalId", "");
  };

  const fetchComments = (hash_id = 0) => {
    setLoading(true);
    switch (docType) {
      case "lead":
        if (dataFetchId > 0 && dataFetchId == data.lead_id) {
          setLoading(false);
          break;
        }
        setDataFetchId(data.lead_id);
        dispatch(getLeadActivities(data.lead_id));
        setLoading(false);
        break;
      
      case "gmail":
        dispatch(getComments(data["thread"]["id"], docType, isExternal));
        setLoading(false);
        break;
      case "gdrive":
        dispatch(getComments(data.provider === 'one_drive' && jquery.isNumeric(data.id) === false && data.task_id != undefined ? data.task_id: data.id, docType, isExternal));
        setLoading(false);
        break;
      case "imageview":
        dispatch(getComments(data.taskId, "task", isExternal, "", 1, 0));
        break;
      case "benchmark":
      case "schedule":
        const rel_id =  userType == "operator" && data.proposal_id ? data.proposal_id :  data.id;
        const rel_type = userType == "operator" && data.proposal_id ? "proposal" : docType;
        const master_record_id = ["benchmark", "schedule"].includes(docType) ? data.id : 0;
        if(rel_id && rel_type)
        {
          dispatch(getComments(rel_id, rel_type, isExternal, "", 1, master_record_id));
        }
        setLoading(false);
        if (localStorage.getItem('selectedVirtualSpace') && JSON.parse(localStorage.getItem('selectedVirtualSpace'))) {
          dispatch(setVirtualSpaceNotificationCount(projectId, data.unread_comment_count, -1));
        }
        else {
          dispatch(setSpaceNotificationCount(projectId, data.unread_comment_count, -1));
        }
        break;
      default:
        if(["task", "request-task", "benchmark", "schedule"].includes(docType) && data.unread_comment_count > 0)
        {
          if (localStorage.getItem('selectedVirtualSpace') && JSON.parse(localStorage.getItem('selectedVirtualSpace'))) {
            dispatch(setVirtualSpaceNotificationCount(projectId, data.unread_comment_count, -1));
          }
          else {
            dispatch(setSpaceNotificationCount(projectId, data.unread_comment_count, -1));
          }
        }
        setLoading(false);
        break;
    }
  };
  const addNewCommentTask = (
    content = "",
    attachments = "",
    editcommentId = 0,
    meeting_link_falg=0
  ) => {
    
    const rel_id = docType == "imageview" ? data.taskId : commonHeaderData.document_id
      ? commonHeaderData.document_id
      : 0;
    const rel_type = docType == "imageview" || docType == "request-task" || docType == "task" ? "task" : docType;
    if (
      (attchmentFiles && Array.from(attchmentFiles).length) ||
      (commentContent && commentContent.trim()) ||
      content != "" ||
      attachments != ""
    ) {
      if (editcommentId > 0) {
        customerServices
          .editLinkComment(
            rel_id,
            projectId,
            content != "" ? content : commentContent.trim(),
            undefined,
            editcommentId,
            undefined,
            docType == "imageview" ? selectedAttachment.task_comment_id : 0,
            attachments != "" ? attachments : attchmentFiles,
            0,
            "",
            hash_id,
            docType == "imageview" ? selectedAttachment.id : 0
          )
        
          .then((res) => {
            if (res.status) {
              let commentUpdate = comments.map((comment) => {
                if (comment.id == editcommentId) {
                  if (attchmentFiles.length) {
                    let tempImage = comment.attachments.length
                      ? comment.attachments
                      : [];
                    if (res.data.files_list && res.data.files_list.length) {
                      res.data.files_list.map((img) => {
                        tempImage.push({
                          id: img.file_id,
                          file_name: img.image_url,
                        });
                      });
                    }
                    comment.attachments = tempImage;
                  }
                  comment["content"] =
                    content != "" ? content : commentContent.trim();
                  comment["description"] =
                    content != "" ? content : commentContent.trim();
                  return comment;
                }
                return comment;
              });
              if (iscallModal ) {
                let commentUpdateForcall = callComments.map((comment) => {
                  if (comment.id == editcommentId) {
                    if (attchmentFiles.length) {
                      let tempImage = comment.attachments.length
                        ? comment.attachments
                        : [];
                      if (res.data.files_list && res.data.files_list.length) {
                        res.data.files_list.map((img) => {
                          tempImage.push({
                            id: img.file_id,
                            file_name: img.image_url,
                          });
                        });
                      }
                      comment.attachments = tempImage;
                    }
                    comment["content"] =
                      content != "" ? content : commentContent.trim();
                    comment["description"] =
                      content != "" ? content : commentContent.trim();
                    return comment;
                  }
                  return comment;
                });
                dispatch(callAction({
                  userId: callStatus.callerInfo._id,
                  receiverId: callStatus.receiverInfo._id,
                  callRole: callStatus.callRole,
                  actionKey: "newComment",
                  actionVal: res.data.comment_id,
                  extraData : {
                      projectId : localStorage.getItem("selectedOffice"),
                      checklist : removeDuplicateObjects(checklistStateData),
                      comments : commentUpdateForcall
                  }
              }));
              dispatch(getCommentsForAudiocall(commentUpdateForcall))
            }
              setComments(commentUpdate);
              setCommentContent("");

              attchmentFiles = [];
              setType("");
            }
          });
      } else {
        const comment = commentContent.trim();
        
        setCommentContent("");
        customerServices
          .addLinkComment(
         // data.provider === 'one_drive' && data.task_id != undefined ? data.task_id : docType == "imageview" && data.taskId != undefined ? data.taskId : data.id,
            rel_id,
            projectId,
            content != "" ? content : comment,
            undefined,
            undefined,
            docType == "imageview" || imageviewerisOpen == true ? selectedAttachment.task_comment_id : 0,
            undefined,
            attachments != "" ? attachments : attchmentFiles,
            0,
            "",
            hash_id,
            docType == "imageview" || imageviewerisOpen == true ? selectedAttachment.id : 0,
            data.provider == 'one_drive' ? data.name : "",
            data.provider || "",
            meeting_link_falg
          )
        
          .then((res) => {
            if (res.status) {
              setguestcommentCount(guestcommentCount + 1)
              let taskAttachments = task_attachments || []
              res.data.files_list.forEach((d) => {
                return taskAttachments.unshift({
                  attachment_key: "",
                  comment_file_id: res.data.comment_id,
                  contact_full_name: localStorage.getItem("full_name"),
                  contact_id: contact_id,
                  creator_image_url: localStorage.getItem("contact_image"),
                  dateadded:  new Date().toISOString(),
                  file_name: d.file_name,
                  file_type: d.file_type,
                  filetype: attachments[0].type,
                  id: d.file_id,
                  image_url: d.image_url,
                  is_allowed_to_edit: "1",
                  is_map: "1",
                  task_comment_id: res.data.comment_id,
                  task_id: rel_id,
                })
              })
              setShowCommentModal(true);
              let newArr = [
                ...comments,
                {
                  id: res.data.comment_id,
                  contact_id,
                  dateadded: new Date().toISOString(),
                  contact_full_name: localStorage.getItem("full_name"),
                  content: res.data.content ? res.data.content : content != "" ? content : comment,
                  description: res.data.content ? res.data.content : content != "" ? content : comment,
                  time: res.data.time,
                  addedfrom_details: {
                    profile_url: localStorage.getItem("contact_image"),
                  },
                  staffid: localStorage.getItem("staff_id"),
                  attachments: res.data.files_list.map((d) => {
                    return { ...d,id: d.file_id, file_name: d.image_url, file_url: d.image_url }
                  }),
                  is_allowed_to_edit: 1,
                  profile_url: localStorage.getItem("contact_image"),
                  parent_comment_details: res.data.parent_comment_details ? res.data.parent_comment_details : "",
                  parent_comment_id: res.data.parent_comment_id
                },
              ];
              setComments(newArr);

              dispatch(setComment(newArr,docType == "imageview" ? data.taskId : data.id, "task"))
              if (iscallModal ) {
                let newArr = [
                  {
                    id: res.data.comment_id,
                    contact_id,
                    dateadded: new Date().toISOString(),
                    contact_full_name: localStorage.getItem("full_name"),
                    content: res.data.content ? res.data.content : content != "" ? content : comment,
                    description: res.data.description ? res.data.description : content != "" ? content : comment,
                    time: res.data.time,
                    addedfrom_details: {
                      profile_url: localStorage.getItem("contact_image"),
                    },
                    staffid: localStorage.getItem("staff_id"),
                    attachments: res.data.files_list.map((d) => {
                      return { ...d,id: d.file_id, file_name: d.image_url, file_url: d.image_url }
                    }),
                    is_allowed_to_edit: 0,
                    profile_url: localStorage.getItem("contact_image"),
                    parent_comment_details: res.data.parent_comment_details ? res.data.parent_comment_details : "",
                    parent_comment_id: res.data.parent_comment_id
                  },
                ];
                dispatch(callAction({
                  userId: callStatus.callerInfo._id,
                  receiverId: callStatus.receiverInfo._id,
                  callRole: callStatus.callRole,
                  actionKey: "newComment",
                  actionVal: res.data.comment_id,
                  extraData : {
                      projectId : localStorage.getItem("selectedOffice"),
                      checklist : removeDuplicates(checklistStateData),
                      comments : callComments.concat(newArr)
                  }
              }));
                dispatch(getCommentsForAudiocall(removeDuplicates((callComments.concat(newArr)))))
                // dispatch(getCommentsForAudiocall([
                //   ...callComments,
                //   {
                //     id: res.data.comment_id,
                //     contact_id,
                //     dateadded: new Date().toISOString(),
                //     contact_full_name: localStorage.getItem("full_name"),
                //     content: res.data.content ? res.data.content : content != "" ? content : comment,
                //     description: res.data.content ? res.data.content : content != "" ? content : comment,
                //     time: res.data.time,
                //     addedfrom_details: {
                //       profile_url: localStorage.getItem("contact_image"),
                //     },
                //     staffid: localStorage.getItem("staff_id"),
                //     attachments: res.data.files_list.map((d) => {
                //       return { ...d,id: d.file_id, file_name: d.image_url, file_url: d.image_url }
                //     }),
                //     is_allowed_to_edit: 1,
                //     profile_url: localStorage.getItem("contact_image"),
                //     parent_comment_details: res.data.parent_comment_details ? res.data.parent_comment_details : "",
                //     parent_comment_id: res.data.parent_comment_id
                //   }
                // ]))
              }
              if (res.data.files_list && res.data.files_list.length) {
                handleParentComponentAction("attachmentCountUpdate", {
                  task_id: rel_id,
                  flag: res.data.files_list.length,
                });
              }
              
              attchmentFiles = [];
              setCommentCount(commentCount + 1);
              setType("");
            }
          });
      }
    } else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_comment")
        )
      );
    }
  };
  const getTaskComments = (pending = 0, hash_id = 0, all = 0) => {
    setLoading(true);
    let taskId = docType == "imageview" ? data.taskId : commonHeaderData.document_id
      ? commonHeaderData.document_id
      : 0;

    if(all)
    {
      let docType = "task";
      documentsServices.getComments(taskId, docType).then((res) => {
        setLoading(false);
        if (res.data) {
          let comments = res.data;
          comments = comments.map((item) => {
            item["description"] = item["content"];
            item["profile_url"] =
              item["addedfrom_details"] &&
              item["addedfrom_details"]["profile_url"];
            item["full_name"] =
              item["addedfrom_details"] &&
              item["addedfrom_details"]["full_name"];
            return item;
          });
          setComments(comments);
          setdefaultComment(comments);
        }

        if (localStorage.getItem("accessToken")) {
          const staffOrContactId =
            localStorage.getItem("user_type") == "contact"
              ? localStorage.getItem("contact_id")
              : localStorage.getItem("staff_id");
          const client_id = localStorage.getItem("client_id");
          
        }
      });
    }
    else {
      customerServices
        .getComments(
          taskId,
          projectId,
          undefined,
          pending,
          1,
          0,
          "",
          hash_id == 0 ? 0 : hash_id,
          1
        )
        .then((res) => {
          setLoading(false);
          if (res.data) {
            let comments = res.data;
            comments = comments.map((item) => {
              item["description"] = item["content"];
              item["profile_url"] =
                item["addedfrom_details"] &&
                item["addedfrom_details"]["profile_url"];
              item["full_name"] =
                item["addedfrom_details"] &&
                item["addedfrom_details"]["full_name"];
              return item;
            });
            setComments(comments);
            setdefaultComment(comments);
          }
  
          if (localStorage.getItem("accessToken")) {
            const staffOrContactId =
              localStorage.getItem("user_type") == "contact"
                ? localStorage.getItem("contact_id")
                : localStorage.getItem("staff_id");
            const client_id = localStorage.getItem("client_id");
            
          }
        });
    }
    
  };
  const handleSubscription = () => {
    setData({ ...data, status: "active" });
    setCommonHeaderBodyData({...commonHeaderBodyData, status_name: "active"})
  };
  const handleEstimateStatus = (status) => {
    setData({ ...data, status });
  };
  const deleteCommentTask = (id) => {
    customerServices.removeComment(projectId, id ,isExternal, localStorage.getItem("guest_id")).then((res) => {
      dispatch(toastCloseAction());
      if (res.status) {
        let newArr = comments.filter((comment) => comment.id !== id)
        setComments(newArr);
        setCommentContent("");
        dispatch(setComment(newArr,docType == "imageview" ? data.taskId : data.id, "task"))
        dispatch(updateTaskAttechments(task_attachments && task_attachments.filter((data)=>data.task_comment_id != id)))

        let tempData = taskList
        let index = tempData.findIndex((task)=> task.id == data.id)
        if(index > -1){
          tempData[index].comments = newArr
        }
        dispatch(updateTaskList(tempData.slice()))
        if (iscallModal) {
          dispatch(callAction({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
            actionKey: "newComment",
            actionVal: id,
            extraData: {
              projectId: localStorage.getItem("selectedOffice"),
              checklist: removeDuplicates(checklistStateData),
              comments: callComments.filter((comment) => comment.id !== id)
            }
          }))
        }

      }

      //Acknowledgement for deletion if required
    });
  };
  const deleteCommentProposal = (id, comment) => {
    customerServices
      .addNewProposalComment(
        proposalDetail.proposal_id,
        projectId,
        comment,
        id,
        1
      )
      .then((res) => {
        if (res.status) {
          dispatch(
            updateBenchmarkProposalComments(
              proposalDetail.proposal_id,
              { comment_id: id },
              -1
            )
          );
          setComments(comments.filter((x) => x.comment_id != id));
        }
      });
  };
  const handleDeleteComment = (id, comment) => {
    if (id > 0) {
      if (type == "proposal") {
        deleteCommentProposal(id, comment);
      } else {
        switch (docType) {
          case "task":
            deleteCommentTask(id);
            break;
          case "request-task":
            deleteCommentTask(id);
            break;
          case "imageview":
            deleteCommentTask(id);
            break;
          case "lead":
            dispatch(deleteLeadActivity(data.lead_profile.id, id));
            break;
          default:
            const rel_id = userType == "operator" && (docType == "benchmark" || docType == "schedule") && data.proposal_id > 0 ? data.proposal_id : docType == "gmail" ? data["thread"]["id"] : data.id;
            const rel_type = userType == "operator" && (docType == "benchmark" || docType == "schedule") && data.proposal_id > 0 ? 'proposal' : docType; 
            const master_record_id = ["benchmark", "schedule"].includes(docType) ? data.id : 0;

            dispatch(
              removeDocumentComment(
                rel_id,
                rel_type,
                id,
                isExternal, 
                master_record_id
              )
            );
            dispatch(toastCloseAction());
            break;
        }
      }
    } else {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_comment_id_is_required"))
      );
    }
  };
  const confirmAlert = (type, data, comment) => {
    switch (type) {
      case "delete":
        dispatch(
          showConfirmation(
            _l("l_confirm"),
            _l("l_you_want_to_delete_this_comment"),
            _l("yes"),
            _l("no"),
            () => {},
            () => {
              handleDeleteComment(data, comment);
              dispatch(toastCloseAction());
            }
          )
        );
        break;
    }
  };
  const handleSearchComment = (searchComment) => {
    if (searchComment != "") {
      setComments((comments) =>
        defaultComment.filter(
          (comment) =>
            comment.description &&
            comment.description
              .toLowerCase()
              .includes(searchComment.toLowerCase())
        )
      );
    } else {
      setComments(defaultComment);
    }
  };
  const handlePinTask = () => {
    let pin =
      data.is_requested == 1
        ? data.is_pinned == 1
          ? 1
          : 0
        : data.pinned == 1
        ? 1
        : 0;
    dispatch(updateTaskPin(data.id || data.task_id, projectId, pin, data.provider));
  };
  const getTaskDetails = (isRequestTask = false) => {
    if (data && Object.keys(data).length) {
      let id = isRequestTask
        ? data.id || data.task_id
          ? data.id || data.task_id
          : 0
        : data.id
        ? data.id
        : docType == "gmail"
        ? data["thread"]["id"]
        : 0;

      // setSelectedId(id);

      setCommonHeaderData({
        creater_name: isRequestTask
          ? data
            ? data.task_creator_name
            : ""
          : data
          ? data.creator_name
          : data.task_creator_name,
        creater_profile: isRequestTask
          ? data.creator_profile_image
            ? data.creator_profile_image
            : data.task_creator_image
          : data.creator_profile_image
          ? data.creator_profile_image
          :  data.task_creator_image,
        document_name: data.name ? data.name : "",
        document_name_permission: isRequestTask && data.id > 0
          ? true
          : data
          ? parseInt(data.is_allowed_to_edit) != 1
          : false,
        document_id: docType == "gmail" ? data["thread"]["id"] : id,
        task_redirect_btn_permission: data && data.request_task_id > 0,
        task_redirect_btn_text: isRequestTask
          ? _l("l_view_task")
          : _l("l_view_request_task"),
        document_type: isRequestTask
          ? _l("l_request")
          : (data.provider == "google_drive" || data.provider == "one_drive")
          ? data.is_synced
            ? _l("l_document_task")
            : _l("l_document")
          : _l("l_task"),
        show_full_screen: "showFullscreen",
        follow_btn_text: _l("l_follow"),
        show_task_myr_total: isRequestTask
          ? data.myr_total > 0 ? true : false
          : data.myr_total
          ? data.myr_total > 0
          : false,
        myr_total: isRequestTask ? 0 : data.myr_total ? data.myr_total : 0,
        myr_total_permission: isRequestTask
          ? false
          : data
          ? parseInt(data.is_allowed_to_edit) < 1
          : false,
        hash: data.hash ? data.hash : "",
        convertTaskId: data && data.request_task_id,
        duration: data.duration,
        surface: data.surface,
        rel_type: data.rel_type,
        attachment:data.map_attachment_url,
        schedule_id:data.schedule_id,
        selectedAssigneesNew: data.assignee_list_DEV
      });
      let isStartDate =
        (data && data.schedule_id > 0 && data.addedfrom == 0) ||
        data.is_requested == 1
          ? true
          : false;
      let requestTaskReadOnly =
        docType == "gmail"
          ? true
          : isRequestTask && data && data.request_task_id > 0;

      let task_assignee = [];
      let task_checklist_assignee = [];
      let task_assignee_ids = [];
      let task_assignee_pills = [];
      let task_assignee_team = [];
      let task_assignee_provider = [];
      
          if (
            !!data.assignee_list_DEV &&
            data.assignee_list_DEV.constructor === Array
          ) {
            data.assignee_list_DEV.map((ts) => {
              ts.categories && ts.categories.map((taskAssign) => {
                defaultTopics &&
                defaultTopics.forEach((topic) => {
                  if (topic.main_key ==  ts.main_key) {
                    topic.categories.forEach((provider) => {
                      if (taskAssign.filter_key == provider.filter_key) {
                        if (ts.main_key == "equipments" || (data.is_requested == 1 && ts.main_key == "provider_task")) {
                          task_assignee_ids.push({
                            name: ts.main_key,
                          });
                        }else{
                          task_assignee_ids.push({
                            id: taskAssign.filter_key,
                            name: ts.main_key,
                          });
                        }
                      }
                    })
                  }})
                // if (data.assignee_list.main_key == 'myr_staff_task' || data.assignee_list.main_key == 'my_people') {
                // taskAssign.categories.map((taskAssignFilterKey) => {
                defaultTopics && defaultTopics.forEach((topic) => {
                  if (ts.main_key == topic.main_key) {
                    topic.categories.forEach((provider) => {
                      if (taskAssign.filter_key == provider.filter_key) {
                        if (
                          ts.main_key == "myr_staff_task" ||
                          ts.main_key == "my_people" ||
                          ts.main_key == "task_topic" ||
                          ts.main_key == "my_contact" ||
                          ts.main_key == "my_operator" ||
                          ts.main_key == "my_staff"
                        ) {
                          // if (provider.main_sub_key == "people") {
                            task_checklist_assignee.push({
                              name: provider.title,
                              profile_image: provider.image,
                              id: provider.filter_key,
                              main_sub_key: provider.main_sub_key
                            })
                          // }
                         
                          } else {
                          let subCategory = []
                          if (topic.main_key == "provider_task") {
                            // dispatch(getOperatorsByCategory(projectId, taskAssign.filter_key, 1, 1));
                            taskAssign.sub_category  && taskAssign.sub_category.length > 0 && taskAssign.sub_category.forEach((dd) => {
                              // if (taskAssign.sub_category.includes(dd.staffid)) { 
                                subCategory.push({...dd,id:dd.staffid});
                              // }
                            })
                          }else{
                            provider.sub_category && provider.sub_category.forEach((sc) => {
                              taskAssign.sub_category && taskAssign.sub_category.length > 0 && taskAssign.sub_category.forEach((dd) => {
                                if (sc.id == dd) {
                                  subCategory.push(dd);
                                  task_assignee_pills.push({
                                    name: sc.name,
                                    id: sc.id,
                                    main_key:topic.main_key
                                  });
                                }
                              })
                            });
                        }
                          task_assignee_ids.push({
                            id: provider.filter_key,
                            name: provider.title,
                            sub_category: subCategory
                          })
                          if (topic.main_key == "provider_task") {
                            task_assignee_provider.push({
                              name: provider.title,
                              id: provider.filter_key,
                              main_key: topic.main_key
                            });
                          }
                          if (topic.main_key != "equipments" || (data.is_requested == 1 && topic.main_key != "provider_task")) { 
                          task_assignee_pills.push({
                            name: provider.title,
                            id: provider.filter_key,
                            main_key: topic.main_key
                          });
                          }
                        }
                      }
                    });
                  }
                });
                if ((ts.main_key == "provider_task" || ts.main_key == "proposals") && userType == "staff") {
                  ts.categories.map((data) => {
                    task_assignee_pills.push({
                      name: "Myr.ai Support",
                      id: data.filter_key,
                      main_key: data.filter_key
                    });
                  })
                }
              });
            })
          }
        // }
      // }
     
      setChecklistAssigneesList(task_checklist_assignee.concat(task_assignee_team));
      setCommonHeaderBodyData({
        date_tooltip: data.schedule_id > 0 || data.rel_type == "equipment" || data.is_requested == 1 ? _l("l_start_date") : _l("l_duedate"),
        date: isStartDate
          ? data.startdate && data.startdate != ""
            ? new Date(data.startdate)
            : ""
          : data.duedate && data.duedate != ""
          ? new Date(data.duedate)
          : "",
        dateType: isStartDate ? "startdate" : "duedate",
        date_readonly:
          isExternal || requestTaskReadOnly
            ? true
            : data
            ? parseInt(data.is_allowed_to_edit) != 1
            : false,
        is_pinned: isRequestTask
          ? data.is_pinned == 1
            ? 1
            : 0
          : data.pinned == 1
          ? 1
          : 0,
        status_class:
          data.is_requested == 1
            ? "bg-warning"
            : data.status == 1
            ? "in-progress-white"
            : data.status == 4
            ? "in-progress-blue"
            : "done",
          status_name:  data.is_requested == 1
          ? "l_request"
          : data.status == 1
          ? "l_not_started"
          : data.status == 4
          ? "l_inprogress"
          : "l_done",  
        status_permission:
          docType != "gmail" && requestTaskReadOnly
            ? false
            : docType == "gmail"
            ? true
            : data && parseInt(data.is_allowed_to_edit) > 0,
        priority: data.priority == "3" ? 1 : 0,
        priority_permission: requestTaskReadOnly
          ? true
          // : data
          // ? parseInt(data.is_allowed_to_edit) < 1
          : false,
        common_permission: requestTaskReadOnly
          ? false
          : data.schedule_id > 0 && userType == "operator" && contact_role != "3" 
          ? true
          : data && parseInt(data.is_allowed_to_edit) > 0,
        project_rel_id: isRequestTask ? 0 : data && data.rel_id,
        recurring_data: {
          recurring_type: data.recurring_type ? data.recurring_type : "",
          custom_recurring: data.custom_recurring ? data.custom_recurring : "",
          recurring: data.recurring ? data.recurring : "",
          repeat_every: data.repeat_every ? data.repeat_every : "",
        },
        document_id: id,
        tag_id: data.tags && data.tags.length ? data.tags[0].id : 0,
        assignee_providers: task_assignee.concat(task_assignee_team),
        assignee_equipments:
          data && data.task_assignee && data.task_assignee.equipments,
        visible_to_client: data && data.visible_to_client == 1 ? true : false,
        visible_to_provider: data && data.visible_to_provider == 1 ? true : false,
        convertTaskId: data && data.request_task_id,
        unread_comment_count: data.unread_comment_count,
        comment_count: data.comment_count ? data.comment_count : 0,
        assigneeList: assigneeDropdownList,
        requestAssignee: isRequestTask ? getAssigneesRequestTask() : [],
        checklist: isRequestTask && data.checklist ? data.checklist.length : 0,
        convertedTaskId: isRequestTask ? data && data.request_task_id : null,
        comment_read_only: requestTaskReadOnly ? true : false,
        task_assignee_ids: task_assignee_ids,
        task_assignee_pills: task_assignee_pills,
        assignee_providers_data: task_assignee_provider,
        task_assignee_type: isRequestTask
          ? ""
          : data.assignee_list && data.assignee_list.main_key,
        myr_total: data.myr_total ? data.myr_total : 0,
        show_task_myr_total: isRequestTask
          ? data.myr_total > 0 ? true : false
          : data.myr_total
          ? data.myr_total > 0
          : false,
        duration: data.duration,
        surface: data.surface,
        rel_type: data.rel_type,
        attachment:data.map_attachment_url,
        schedule_id:data.schedule_id,
        selectedAssigneesNew: data.assignee_list_DEV,
        rating:data.rating
      });
      setCommentCount(
        isRequestTask
          ? data && data.comments && data.comments.length
          : data && data.comment_count
      );
      setExternalPageLink(
        `${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${data.hash}`
      );
    }
  };
  const getAssigneesRequestTask = () => {
    let assigneedRequest = [];
    if (
      data &&
      data.assignees_type &&
      Object.keys(data.assignees_type).length > 0
    ) {
      let isAssigneesExist = assigneeDropdownList.filter(
        (taskAssignees) =>
          taskAssignees.id == data.assignees_type.assginee_id &&
          taskAssignees.main_key === data.assignees_type.assginee_type
      );
      if (isAssigneesExist.length) {
        let tempPush = [];
        tempPush = [
          {
            main_key: data.assignees_type.assginee_type,
            id: isAssigneesExist[0].id,
            image: isAssigneesExist[0].image || "",
            name: isAssigneesExist[0].name || "",
          },
        ];
        dropDownActiveClass(
          true,
          `${data.assignees_type.assginee_type}_${data.assignees_type.assginee_id}`
        );
        return tempPush;
      }
    }
    return data && data.assignee_list_DEV && data.assignee_list_DEV.constructor === Array ? data.assignee_list_DEV : [];
  };
  const handleConvertTask = (task_Id = "", flag = "") => {
    if (checklistStateData && checklistStateData.length > 0) {
      let task_id = task_Id;
      customerServices
        .convertRequestTask(flag, "", data.id, task_id)
        .then((res) => {
          dispatch(toastCloseAction());
          if (res.status && res.data) {
            if (flag == "new_task") {
              task_id = res.data.id;
              dispatch(
                getTaskCount(client_id, contact_id, projectId, savedDate)
              );
            }
            dispatch(getSelectedTask(projectId, task_id));

            handleParentComponentAction("convertedTaskAction", {
              convertTaskId: task_id,
              isShow: "show",
              isRequestTask: null,
              connvertTaskId: data.task_id,
            });
            dispatch(
              setTaskData(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                data.task_id,
                task_id
              )
            );
          }
        });
    } else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_checklist")
        )
      );
    }
  };
  const handleRequestAssignee = (assigneeDetail,sub_categories,sub_category) => {
    let selectedAssignees = {
      main_key: assigneeDetail.main_key,
      filter_key: assigneeDetail.id || assigneeDetail.filter_key,
      image: assigneeDetail.image || "",
      name: assigneeDetail.name || "",
      is_assigned: 1,
    };
    

    customerServices
      .setRequestTaskAssignee(data.task_id || data.id, [selectedAssignees],sub_categories,docType == "request-task" ? true : false)
      .then((res) => {
        if (res.status && res.data && res.data.schedule_assignees) {
          if (docType == "task" || docType == "request-task") {
            dispatch(
              setTaskAssigneeNew(
                {
                  filter_key: assigneeDetail.id || assigneeDetail.filter_key,
                  title: assigneeDetail.name,
                  image: assigneeDetail.image || "",
                },
                assigneeDetail.main_key,
                assigneeDetail.id || assigneeDetail.filter_key,
                data.id,
                sub_category,
                0,
                0,
                res.data.schedule_assignees,
                true
              )
            );
          }
        
        } 
      });
  };
  const updateRequestTitle = (assignee, checklist) => {
    let newTitle = ``;
    if (checklist) {
      newTitle = data.name.split(" - ");
      if (newTitle.length > 1) {
        newTitle[1] = checklist;
        newTitle = newTitle.join(" - ");
      }
    } else {
      newTitle = `${assignee}`;
     
    }
    if (newTitle.length) {
      dispatch(setTaskData(projectId, data.task_id, newTitle));
    }
  };
  const getMentionlist = (list) => {
    if (list) {
      setMentionList(
        list.map((name) => {
          let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
          return {
            display: name.name,
            id: id,
            image: name.profile_image || "",
            isteam: name.is_team ? 1 : 0,
            contact_role : name.contact_role,
          };
        })
      );
    }
  };

  const documentAssignee = (docs, moduleType, taskId) => {
    documentsServices
      .setDocumentAssignee(docs.type, docs.value, moduleType, taskId)
      .then((res) => {
        if (res.status == 1) {
          let assignee = data && data.assignees ? data.assignees : {};
          if (
            docs.type == "my_people" &&
            docs &&
            docs.details &&
            docs.details.id > 0
          ) {
            let newArr = [...assignee.my_people, docs.details];
            if (
              assignee.my_people.filter((x) => x.id == docs.details.id).length
            ) {
              newArr = assignee.my_people.filter(
                (c) => c.id != docs.details.id
              );
            }
            // assignee.my_people.push(docs.details)
            assignee.my_people = newArr;
          } else {
            let my_task = assignee.my_task.map((u, k) =>
              k != 0 ? u : docs && docs.details
            );
            assignee.my_task = my_task;
          }
          switch (moduleType) {
            case "estimate":
              dispatch({
                type: GET_ESTIMATE_DETAILS,
                payload: { ...data, assignees: assignee },
              });
              break;
            case "document":
              dispatch({
                type: GET_DOCUMENT_DETAILS,
                payload: { ...data, assignees: assignee },
              });
              break;
            case "subscription":
              dispatch({
                type: GET_SUBSCRIPTION_DETAILS,
                payload: { ...data, assignees: assignee },
              });
              break;
            case "invoice":
              dispatch({
                type: GET_INVOICE_DETAILS,
                payload: { ...data, assignees: assignee },
              });
              break;
            default:
              break;
          }
        }
      });
  };

  const handleCommonButtonToggle = (type, btnName = "") => {
    if (btnName != "") {
      if (type == "commonHeaderBodyData") {
        setCommonHeaderBodyData({
          ...commonHeaderBodyData,
          ["buttonShow"]: [btnName],
        });
      }
    } else {
      if (
        commonHeaderBodyData &&
        commonHeaderBodyData.buttonShow &&
        commonHeaderBodyData.buttonShow.length
      ) {
        setCommonHeaderBodyData({
          ...commonHeaderBodyData,
          ["buttonShow"]: [],
        });
      }
    }
  };
  const handleSubscriptionBody = (type, tempData) => {
    if (type == "ContactEmail") {
      subscribeContactEmail = tempData;
    }
  };

  const subscribeForProvider = (operatorData) => {
    DocumentsServices.subscribe(operatorData.hash, "", operatorData.email, 1, 0, 1, operatorData.client_id, undefined, undefined, data.payment_mode);
    navigate(`/explore-features?space=${data.project_id}`);
  };

  const subscribe = (date) => {
    setLoder(true);
    var subscribeEmail = subscribeContactEmail ? subscribeContactEmail.email : "";
    if(userType === "staff")
    {
      subscribeEmail = data.staff_email;
    }
    var dateNumber = date.getDate();
    if (subscribeEmail == "") {
      setLoder(false);
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_select_contact")));
    }else if ([29,30,31].includes(dateNumber)){
      setLoder(false);
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_subscribe_date_selection_error")));
    } else {
      DocumentsServices.subscribe(data.hash, date, subscribeEmail, isExternal, data.project_id,false,0,false,clientidForSubscription, data.payment_mode).then(
        (res) => {
          setLoder(false);
          if (res.status && res.status == 1) {
            if(window.location.pathname === "/create-benchmark" && res.extra.client_id)
            {
              let operatorData = res.extra;
              subscribeForProvider(operatorData);
            }
            else if(res.data && !res.data.site){
              dispatch(showMessage("success", _l("l_success"), _l(res.message)));
              navigate(`/explore-features?space=${data.project_id}`);
              handleSubscription();
            }
            else if(res.data && res.data.site != ""){
              window.open(res.data.site, '_blank', 'noreferrer');
            }  
          }
          else if(res.status == 2)
          {
            dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
            let formReference = document.getElementById("wallet_configuration_form");
            if(formReference)
            {
              formReference.click();
            }
          } 
          else {
            dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
          }
        }
      );
    }
  };
  const documentEstimateReject = () => {
    DocumentsServices.rejectEstimate(data.hash).then((res) => {
      dispatch(toastCloseAction());
      if (res.status == 1) {
        handleEstimateStatus("Declined");
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), res.message));
      }
    });
  };
  const extractEmails = (text) => {
    return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
  };
  const changeMailStatus = (mailStatus) => {
    if (data["thread"]["is_inbox"] == 0) {
      handleParentComponentAction("UpdateMessage", {
        id: data["thread"]["id"],
        value: mailStatus,
        column: "status",
      });
    }
  };
  const changeMailPriority = (mailStatus) => {
    var mailDate = new Date(
      data["conversation"]["messages"][0]["payload"]["headers"].filter(
        (header) => header["name"] == "Date"
      )[0]["value"]
    ).toLocaleString();
    if (data["thread"]["is_inbox"]) {
      handleParentComponentAction("saverFromInbox", {
        id: data["thread"]["id"],
        value: mailDate,
        column: "is_saver",
      });
    } else {
      handleParentComponentAction("UpdateMessage", {
        id: data["thread"]["id"],
        value: data["thread"]["is_saver"] ? 0 : 1,
        column: "is_saver",
      });
    }
  };
  const changeMailPin = (mailStatus) => {
    var mailDate = new Date(
      data["conversation"]["messages"][0]["payload"]["headers"].filter(
        (header) => header["name"] == "Date"
      )[0]["value"]
    ).toLocaleString();
    if (data["thread"]["is_inbox"]) {
      handleParentComponentAction("markImportantEmail", {
        id: data["thread"]["id"],
        value: mailDate,
        column: "is_saver",
      });
    } else {
      handleParentComponentAction("UpdateMessage", {
        id: data["thread"]["id"],
        value: mailDate,
        column: "un_important",
      });
    }
  };
  const convertLead = (customerDetail, space_details, is_add_operator) => {
    AdminServices.convertLead(
      customerDetail,
      "null",
      space_details,
      is_add_operator
    ).then((res) => {
      if (res.status) {
        dispatch(leadStatusChange(data.lead_profile.id, 1));
        let spaces = JSON.parse(localStorage.getItem("spaces"));
        if (spaces && spaces.length) {
          spaces.push(res.data.space_details);
          dispatch(setSpaceList(spaces));
          localStorage.setItem(
            "convertLeadDetails",
            JSON.stringify({
              project_id: res.data.project_id,
              email: res.data.email,
              client_id: res.data.client_id,
            })
          );
        }
      } else {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            res.message ? res.message : _l("l_something_went_wrong")
          )
        );
      }
      setShowConvertLeadModal(false);
      setLeadType("");
    });
  };
  const loginHandler = (email, clientId, project_id = 0) => {
    dispatch(showFullScreenLoader());
    dispatch(
      loginAsUser(email, clientId, project_id == 0 ? projectId : project_id)
    ).then(() => {
      if (!localStorage.getItem("accessToken")) {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_invalid_credentials"))
        );
      } else {
        localStorage.setItem("password", "12345");

        if (localStorage.getItem("membership_package_id") == 1) {
          // navigate("/premiumsubscription");
          navigate("/myprofile");
        } else {
          const client_id = localStorage.getItem("client_id");
          const contact_id = localStorage.getItem("contact_id");
          localStorage.setItem("selectedOffice", project_id == 0 ? projectId : project_id );
          dispatch(getCustomerSpaces(client_id, contact_id));
          dispatch(adminLoginAsStaff(true));
          dispatch(projectTaskCounts(projectId));
          navigate("/redirect", {
            state: {
              url: "/",
            },
          });
        }
      }
    });
  };

  const handleTaskRating = (star, customRating = -1)=>{
    if (customRating && customRating > -1) {
      setTaskRating(customRating)
      let taskListNew = taskList;
      let index = taskListNew.findIndex((d) => d.id == data.id);
      if (index > -1){
        taskListNew[index].rating = customRating;
        taskListNew[index].average_rating = customRating;
      }
      dispatch(setBulkTaskRating(taskListNew))
    } else {
    customerServices.addEditChecklistRating(
      projectId,
      0,
      data.id,
      star,
    ).then((res)=>{
      if (res.status) {

        let TotalRating = 0
        
        setTaskRating(star)
        let newCheklist = []
        checklistStateData && checklistStateData.map((item)=>{
          item.rating = star
          newCheklist.push(item)
          TotalRating = TotalRating + item.rating
        })
        dispatch(updateedTaskChecklist(newCheklist))
        let taskListNew = taskList;
          let index = taskListNew.findIndex((d) => d.id == data.id);
          if (index > -1){
            taskListNew[index].rating = star;
            taskListNew[index].average_rating = `${averageTaskChecklistRatings()}`;
          }
        dispatch(setBulkTaskRating(taskListNew))
        setTaskRating(star)    
      } else {
        dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)))
      }
    })
    }
  }

  useEffect((e) => {
    jquery(document).on("click", "#Loginuserdropdown", function () {
      jquery('#commonCanvasBody').addClass('toggle-zindex');
      // jquery(this).addClass('show');
    });
    jquery(document).mouseup(function (e) {
      var container = jquery(".dropdown-menu");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        jquery("#commonCanvasBody").removeClass("toggle-zindex");
      }
    });
  },);
  

  const openProposalAmountModal = (modalOpen, amount, description, proposalId) => {
    setShowProposalAmountModal(modalOpen);
    setProposalAmount(amount);
    setProposalDescription(description);
    setProposalId(proposalId);
  }
  
  useEffect(() => {
    if (data && data.contacts) {
      data.contacts.map((data) => parseInt(data.is_primary) === 1 ? setSelectedContact(data) : "");
    }
    if (data && data.clientid) {
      setClientidForSubscription(data.clientid);    
    }
  }, [data])

  const spaceFloorPlan = useSelector(
    (state) => state.benchmarkReducer.floorDetails
);
  const [newTypeOfSpaceData, setNewTypeOfSpaceData] = useState({
    title: "",
    quantity: 1,
    details: [],
});
const [equipmentData, setEquipmentData] = useState(newEuipment || {
  title: "",
  id: "",
  quantity: 1,
  priority: 0,
  file:[],
  defalutLifeTime: new Date(),
  previewArray:[],
  deleteFilesArray:[],
  commentsList:[],
  equipmentItems: [],
  provider_assignee: 0,
  contact_assignee: 0
});

const [file, setFile] = useState(newEuipment && newEuipment.file ? newEuipment.file : []);
const [previewArray, setPreviewArray] = useState(newEuipment && newEuipment.previewArray ? newEuipment.previewArray : []);
const [commentsList, setCommentsList] = useState(newEuipment && newEuipment.commentsList ? newEuipment.commentsList : []);
const [quantityData, setQuantityData] = useState(quantityList || [{
  id:1,
  name: "default quantity",
  attachments: [],
  preview: [],
  lifetime: new Date(),
  web_x: setPOILatLng && setPOILatLng.x,
  web_y: setPOILatLng && setPOILatLng.y,
  web_z: setPOILatLng && setPOILatLng.z,
  device_id:""
}]);
const [lastItemId, setLastItemId] = useState(0);

  useEffect(() => {
        if (selectedEquipment && get_equipments_list && get_equipments_list.length) {
          setEquipmentData({
            ...equipmentData,
            title:selectedEquipment.equipmentName
          });
          selectedEquipmentsQuantity(selectedEquipment.equipmentId);
        }
        },[selectedEquipment,get_equipments_list])
const getSpaceOptionList = () => {
  if (docType == "type_of_space") {
    let spaceOptions = spaceFloorPlan.type_of_space
      .map((space) => {
          return {
              label: space.title,
              value: space.id,
          };
      });
  spaceOptions = spaceOptions.filter(
      (obj, index, self) =>
          index === self.findIndex((o) => obj.label == o.label)
  );
  return spaceOptions;
  }else{
    let equipmentdOptions = get_equipments_list && get_equipments_list.length > 0 && get_equipments_list
          .map((space) => {
            return {
              label: space.equipment_name,
              value: space.id,
            };
          });
        equipmentdOptions = equipmentdOptions && equipmentdOptions.filter(
          (obj, index, self) =>
            index === self.findIndex((o) => obj.label == o.label)
        );
        return equipmentdOptions;
  }
};
const addNewTypeOfSpace = (title, quantity) => {
  setLoder(true)
  customerServices.addFloorItem(
      projectId,
      quantity,
      title,
      "type_of_space",
      floorId,
      0
  ).then((response) => {
      if (response.status == 1) {
          if (response.data) {
              dispatch(updateFloorDetails(response.data));
          }
      }
  });
};
const removeFloorItem = (slug, records, key) => {
  customerServices.deleteFloorItems(projectId, slug, [records], key).then((res) => {
      if (res.status == 1) {
          dispatch(updateFloorDetails(res.data));
          dispatch(showMessage('sucess', _l('l_success'), _l(res.message)));
      } else {
          showError(res.message);
      }
  })
}
const handleChange = (name, value) => {
  let qtyArr = quantityData
  if (qtyArr.length == 1 && name == "title") {
      qtyArr[0] = { ...qtyArr[0], name: value+ " "+1 }
  }
  if (qtyArr.length == 1 && name == "defalutLifeTime") {
      qtyArr[0] = { ...qtyArr[0], lifetime : qtyArr[0].lifetime != "" ? qtyArr[0].lifetime : value }
  }
  let Arr = equipmentData
  Arr = {
      ...Arr,
      [name]: value
  }
  setEquipmentData(Arr);
  setQuantityData(qtyArr.slice());
}

const handleFileUpload = (files) => {
  if (files) {
      let thumbnails = [];
      let newFiles = [];
      Array.from(files).map((img) => {
          thumbnails.push({ file_name: img.name, image_url: URL.createObjectURL(img) });
          newFiles.push(img);
      });

      if (file && Array.from(file).length) {
          /**Remove duplicate attachments from array */
          newFiles = Array.from(newFiles).filter(
              (x) => Array.from(file).findIndex((w) => w.name == x.name) === -1
          );
          thumbnails = thumbnails.filter(
              (x) => previewArray.findIndex((w) => w.file_name == x.file_name) === -1
          );

          setFile(Array.from(file).concat(newFiles));
          setPreviewArray(previewArray.concat(thumbnails));
      } else {
          setFile(Array.from(file).concat(newFiles));
          setPreviewArray(previewArray.concat(thumbnails));
      }
  }
};

const selectedEquipmentsQuantity = (equipmentId) =>{
  let quantityArr = []
  let equipmentsData = get_equipments_list
  let index = equipmentsData.findIndex((eq) => eq.id == equipmentId);
  if (index > -1) {
      // setEquipmentData({...equipmentData,quantity:equipmentsData[index].quantity})
      setEquipmentData({
          ...equipmentData,
          title: equipmentsData[index].equipment_name,
          id:equipmentsData[index].id,
          quantity:equipmentsData[index].quantity,
          priority: Number(equipmentsData[index].task_priority),
          provider_assignee: equipmentsData[index].provider_assignee,
          contact_assignee: equipmentsData[index].contact_assignee,
        });
      if(equipmentsData[index].quantity_details && equipmentsData[index].quantity_details.length > 0){
          equipmentsData[index].quantity_details.forEach((ss) => {
              quantityArr.push({...ss, preview:ss.attachments, name:ss.quantity_name, qty_id:ss.id, equipmentId: equipmentsData[index].id}) 
          })
      }else{
          quantityArr.push({
              id:1,
              name: equipmentsData[index].equipment_name + " "+1,
              attachments: [],
              preview: [],
              lifetime: new Date(),
              web_x: setPOILatLng && setPOILatLng.x,
              web_y: setPOILatLng && setPOILatLng.y,
              web_z: setPOILatLng && setPOILatLng.z,
              device_id:""
          })
      }
  }
  setQuantityData(quantityArr.slice());
}
const handleChangeQuantity = (quinatity, action) => {
  let qtyArr = quantityData
  if (action == "add") {
      qtyArr[0] = { ...qtyArr[0], name: equipmentData.title + " " + 1 }
      qtyArr.push({ name: equipmentData.title + " " + quinatity,id: quinatity, file: [], preview: [], lifetime: equipmentData.defalutLifeTime || new Date(), device_id:"",web_x:0,web_y:0,web_z:0 })
  } else {
      qtyArr = qtyArr.filter((dd, key) => key != qtyArr.length - 1)
  }
  setQuantityData(qtyArr.slice());
}
  const handleRemoveQuantityFile = (type, equipmentId = 0, fileId = 0, fileIndex, quantityId = 0, quantityIndex = -1) => {
    if (fileId > 0) {
      equipmentsServices.removeEquipmentQuantityImage(
        projectId,
        equipmentId,
        quantityId,
        fileId
      ).then((response) => {
        if (response.status == 1) {
          if (type == 'equipment') {
            setFile(file.filter((f, k) => k != fileIndex))
            setPreviewArray(previewArray.filter((f, k) => k != fileIndex))
          } else {
            let newData = quantityData
            if (newData[quantityIndex]) {
              newData[quantityIndex].attachments = newData[quantityIndex].attachments.filter((d, k) => k != fileIndex)
              newData[quantityIndex].preview = newData[quantityIndex].preview.filter((d, k) => k != fileIndex)
            }
            setQuantityData(newData.slice());
          }
        }
      });
    } else {
      if (type == 'equipment') {
        setFile(file.filter((f, k) => k != fileIndex))
        setPreviewArray(previewArray.filter((f, k) => k != fileIndex))
      } else {
        let newData = quantityData
        if (newData[quantityIndex]) {
          newData[quantityIndex].attachments = newData[quantityIndex].attachments.filter((d, k) => k != fileIndex)
          newData[quantityIndex].preview = newData[quantityIndex].preview.filter((d, k) => k != fileIndex)
        }
        setQuantityData(newData.slice());
      }
    }
  }
const handleAddEquipmentComment = (comment, fileUplods, commentId) => {
  let arrComment = commentsList
  let key = arrComment.findIndex((dd) => dd.id == commentId);
  if (key > -1) {
    arrComment[key].content = comment;
  }else{
    arrComment.push({
      contact_id: "3065",
      content: comment,
      date: new Date(),
      dateadded: new Date(),
      full_name: localStorage.getItem("full_name"),
      id: arrComment.length + 1,
      is_allowed_to_edit: "1",
      profile_url: "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg",
    })
  }
  setCommentsList(arrComment.slice())

}
const handleDeleteEquipmentComment = (comment,index) => {
  let arrComment = commentsList
  arrComment = arrComment.filter((com, key) => key != index);
  setCommentsList(arrComment.slice())
}

const addEquipment = () => {
  if (equipmentData.title == "") {
      showError("l_equipment_name_required");
  } else {
      setLoder(true);
      equipmentsServices.addNewEquipment(projectId, equipmentData.title, { ...equipmentData, file: file }, quantityData, commentsList, floorId).then((res) => {
          setLoder(false);
          if (res.status == 1) {
              dispatch(filterFor3Dmodel([...filterfor3dPlan, (res.data.equipments_id).toString()]));
              // dispatch(filterFor3Dmodel([...filterfor3dPlan, res.data.equipments_id]));
              dispatch(getEquimenntsList(projectId, getSelectedFloorId, 1));
              dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
              clearData("save")
          } else {
              showError(res.message);
          }
      })
  }
}
const clearData = (action) => {
  dispatch(setNewEquipment(undefined));
  dispatch(setEquipmentQuantityList(action == "save" ? undefined : [{
  id:1,
  name: "default quantity",
  file: [],
  preview: [],
  lifetime: 0,
  web_x: 0,
  web_y: 0,
  web_z: 0,
  device_id:""
}]));
  dispatch(setEquipmentQuantity(undefined));
  dispatch(updateHideLoader(false));
  dispatch(commanoffcanvasisOpen(false))
  dispatch(commanoffcanvasisOpenfromCalendar(false))
  if (onCloseShowLeftPanelDefault) {
    TaskModalClose(onCloseShowLeftPanelDefault);
  } else {
    TaskModalClose();
}
  TaskModalClose(onCloseShowLeftPanelDefault);
  handleClose();
  // handleCommonAction("modalClose");
}
let operatorData = defaultTopics
        ? defaultTopics.find((w) => w.main_key === "my_operator")
        : [];
    operatorData = operatorData && operatorData.categories
        ? operatorData.categories.filter((w) => w.main_sub_key !== "team").map((w) => {
            return { label: w.title, value: w.filter_key,profile_image:w.image };
        })
        : [];

    let contactData = defaultTopics
        ? defaultTopics.find((w) => w.main_key === "my_contact")
        : [];
    contactData = contactData && contactData.categories
        ? contactData.categories.filter((w) => w.main_sub_key !== "team").map((w) => {
            return { label: w.title, value: w.filter_key ,profile_image:w.image};
        })
        : [];

  const handleFileUploadForPOIs = (files) => {
    if (files) {
      let thumbnails = [];
      let newFiles = [];
      Array.from(files).map((img) => {
        thumbnails.push({ file_name: img.name, image_url: URL.createObjectURL(img) });
        newFiles.push(img);
      });
      if (commonHeaderData.file && commonHeaderData.file.length) {
        /**Remove duplicate attachments from array */
        newFiles = Array.from(newFiles).filter(
          (x) => Array.from(commonHeaderData.file).findIndex((w) => w.name == x.name) === -1
        );
        thumbnails = thumbnails.filter(
          (x) => commonHeaderData.previewArray.findIndex((w) => w.file_name == x.file_name) === -1
        );

        setCommonHeaderData({...commonHeaderData,file: commonHeaderData.file.concat(newFiles),previewArray: previewArray.concat(thumbnails)});
      } else {
        setCommonHeaderData({...commonHeaderData,file: commonHeaderData.file.concat(newFiles),previewArray: previewArray.concat(thumbnails)});
      }
    }
  };
  const create3dModelPois = () => {
    setLoaderForPOI(true);
    customerServices.createArPOIs(floorId, commonHeaderData.x, commonHeaderData.y, commonHeaderData.z,  commonHeaderData.name, commonHeaderData.file, commonHeaderData.id).then((res) => {
      setLoaderForPOI(false);
      if (res.status == 1) {
        let Data = getActivePOIList || []
        let index = -1 
        if (commonHeaderData.id > 0) { 
          index = Data.findIndex((d) => d.id == commonHeaderData.id)
        }
        if (index > -1) { 
          Data[index].title = commonHeaderData.name
        }else{
          Data.push({
            title: commonHeaderData.name,
            web_x: setPOILatLng.x.toString(),
            web_y: setPOILatLng.y.toString(),
            web_z: setPOILatLng.z.toString(),
            images:commonHeaderData.previewArray.map((p) => p.url),
            id: res.data.poi_id ? res.data.poi_id : 31,
            floor_id: floorId,
            project_id: projectId
          })
        }
        dispatch(updateHideLoader(false));
        dispatch(commanoffcanvasisOpen(false))
        dispatch(commanoffcanvasisOpenfromCalendar(false))
        if (onCloseShowLeftPanelDefault) {
          TaskModalClose(onCloseShowLeftPanelDefault);
        } else {
          TaskModalClose();
        }
        handleClose()
        handleCommonAction("modalClose");
        setPOIsList([]);
        dispatch(getPOIsListUpdate(Data, 1));
        showLeftPanelNew();
        hideRightPanelNew();
        dispatch(showMessage('sucess', _l('l_success'), _l(res.message)));
      } else {
        dispatch(showMessage('unsucess', _l('l_error'), _l(res.message)));
      }
    })
  }

  useEffect(() => {
    if (callComments) {
      setComments(callComments)
    }
  }, [callComments])
  

  useEffect(() => {
    if (!jquery.isEmptyObject(callStatus)) {
      
    }
  }, [callStatus])

  useEffect(() => {
    removeOffCanvasBackdropClass();
  })
  

  return (
    <>
     {
          showToast ? (
            <Toasts
              delay={2000}
              handler={() => {setShowToast(false);}}
              header={toastHeader}
              message={toastMessage}
              toastClass={toastClass}
              cancelButtonText={cancelButtonText}
              cancelButtonHandler={() => {setShowToast(false);}}
              confirmButtonText={confirmButtonText}
              confirmButtonHandler={() => {setShowToast(false);}}
            />
          ) : (<></>)
    }
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        onShow={() => {
          tabindexFix();
        }}
        onEntered={() => {
          removeOffCanvasBackdropClass();
        }}
        
        keyboard={false}
        className={`${isCallFullscreen ? "modal-full" : ''} taskDetailModal custom-offcanvas-modal radius_3 custom-modal-style
         ${showFullscreen /*|| isFullScreen */ ? "modal-full" : ""}
         ${docType === "dummy" ||
            docType === "estimate" || docType == "premiumEstimate" || 
            docType === "subscription" ||
            docType === "invoice" ||
            docType === "credit_note"||
            docType ==="spaceDocument" ||
            docType ==="lead" ||
            docType ==="pilotonboarding" 
            ? ""/*canvas-height-modified*/
            : ""
        }
        ${fromTopicSetting ? "with-comment-section" : ""}
        ${canvasFullHeight ? "no-footer" : ""}
        ${canvasfullHeightwithHeaderFooter ? "canvas-height-modified with-header" : ""}
        ${docType === "imageview" ? "modal-full-left-panel" : ""} 
        ${isExternal ? docType == "spaceDocument" ? "stand-alone z-index-3" : "stand-alone  z-index-3" : ""}
        ${isExternalWithNoLeftPanel ? "with-no-left-panel":""}
        ${pageName === "editScheduleChecklist" ? "z-index-22 modal-full" : ""}
        ${pageName === "MyEmail" ? "comment300px" : ""}
        `}
          
        backdrop={false}
        id={`${docType === "imageview" ? `commanCanvasImageBody` : "commonCanvasBody"}`}
      >
        <Offcanvas.Body className="p-0">
          {(data == undefined || Object.keys(data).length == 0) && !["equipment_task","type_of_space","poi"].includes(docType) && !iscallModal? (
            <CommanLoader className="comman-main-loader-wrapper" />
            // <></>
          ) : docType == "imageview" ? (
            <ImageDetailOffcanvas docType={docType} data={data} handleAction={handleCommonAction} CloseCommentModal={CloseCommentModal}  selectedAttachment={selectedAttachment} setSelectedAttachment={setSelectedAttachment} isChat={isChat} />
          ) : (
            <div className={`modal-body p-0 res-overflow-auto`}>
              <div className="d-flex justify-content-between flex-wrap h-100">
                <div className="task-detail-left-main h-100 res-width-100 d-flex flex-column position-relative p-0">
                  <div className={`offcanvas-CommonHeader-main z-index-15 border-bottom position-relative ${docType == "lead" ? "h60px" : "h54px"}`}>
                  <CommonHeaderModal
                    readOnlyMode={readOnlyMode}
                    isExternal={isExternal}
                    data={commonHeaderData}
                    type={docType}
                    handleAction={handleCommonAction}
                    showFullscreen={showFullscreen}
                    setShowFullscreen={setShowFullscreen}
                    showTotal={docType == "task" ? showMyrTotal : false}
                    setShowCommentModal={setShowCommentModal}
                    ShowCommentModal={ShowCommentModal}
                    IsKanbanView={IsKanbanView}
                    openProposalAmountModal={openProposalAmountModal}
                    onCloseShowLeftPanelDefault={onCloseShowLeftPanelDefault}
                    allData={data}
                    setCommentModalWidth={setCommentModalWidth}
                    setActiveTaskID={setActiveTaskID}
                    disableFullScreenToggle={disableFullScreenToggle}
                    modalHeaderIconToggle={modalHeaderIconToggle}
                    setModalHeaderIconToggle={setModalHeaderIconToggle}
                    setShowDefaultIcons={setShowDefaultIcons}
                    showDefaultIcons={showDefaultIcons}
                    newTypeOfSpaceData={newTypeOfSpaceData}
                    setNewTypeOfSpaceData={setNewTypeOfSpaceData}
                    getSpaceOptionList={getSpaceOptionList}
                    equipmentData={equipmentData}
                    handleChange={handleChange}
                    selectedEquipmentsQuantity={selectedEquipmentsQuantity}
                    requestData={data}
                    setRequestData={setRequestData}
                    loaderForPOI={loaderForPOI}

                  />
                  { ["poi", "benchmark" ,"schedule","pilotonboarding"].includes(docType) ? (
                    <></>
                  ) : (
                    <CommonHeaderBody
                      data={commonHeaderBodyData}
                      setdata={setCommonHeaderBodyData}
                      type={docType}
                      handleAction={handleCommonHeaderBodyAction}
                      checkListCounts={0}
                      allData={data}
                      isExternal={isExternal}
                      readOnlyMode={readOnlyMode}
                      contacts={contacts}
                      handleSubscriptionBody={handleSubscriptionBody}
                      selectedContact={selectedContact}
                      setSelectedContact={setSelectedContact}
                      visibleToCustomer={visibleToCustomer}
                      setVisibleToCustomer={setVisibleToCustomer}
                      visibleToProvider={visibleToProvider}
                      setVisibleToProvider={setVisibleToProvider}
                      loder={loder}
                      withAbsPosition={true}
                      modalHeaderIconToggle={modalHeaderIconToggle}
                      showDefaultIcons={showDefaultIcons}
                      setShowDefaultIcons={setShowDefaultIcons}
                      handleChange={handleChange}
                      equipmentData={equipmentData}
                      previewArray={previewArray}
                      handleRemoveQuantityFile={handleRemoveQuantityFile}
                      handleFileUpload={handleFileUpload}
                      handleAddComment={handleAddEquipmentComment}
                      handleDeleteEquipmentComment= {handleDeleteEquipmentComment}
                      commentsList={commentsList}
                      setCommentsList={setCommentsList}
                      addEquipment={addEquipment}
                      setModalHeaderIconToggle={setModalHeaderIconToggle}
                      contactData={contactData}
                      operatorData={operatorData}
                      handleChangeQuantity={handleChangeQuantity}
                      addNewTypeOfSpace={addNewTypeOfSpace}
                      newTypeOfSpaceData={newTypeOfSpaceData}
                      removeFloorItem={removeFloorItem}
                      lastItemId={lastItemId}
                      handleClose={ () => {
                        dispatch(commanoffcanvasisOpen(false))
                        handleCommonAction("modalClose");
                        handleClose()
                      }}
                    />
                  )}
                  </div>
                  {
                  docType == "poi" ?
                  <SpacePois 
                  data={commonHeaderData}
                  type={docType}
                  handleAction={handleCommonAction}
                  isExternal={isExternal}/>
                  : docType == "type_of_space" ?
                  <SpaceRoom 
                  handleChangeCordinate={handleChangeCordinate}
                  setPOIsList={setPOIsList}
                  cordinatesData = {cordinatesData}
                  typeOfSpaceData = {typeOfSpaceData}
                  floorId={floorId}
                  from={from}
                  selectedRoom={selectedRoom}
                  setSelectedRoom={setSelectedRoom}
                  setLastItem = {setLastItemId}
                  newTypeOfSpaceData={newTypeOfSpaceData} 
                  setNewTypeOfSpaceData={setNewTypeOfSpaceData} />
                  : docType == "equipment_task" ?
                  <SpaceEquipment 
                   selectedEquipment={selectedEquipment} 
                   equipmentData={equipmentData}
                   setEquipmentData={setEquipmentData}
                   quantityData={quantityData}
                   setQuantityData ={setQuantityData}
                   selectedEquipmentsQuantity={selectedEquipmentsQuantity}
                   handleRemoveQuantityFile ={handleRemoveQuantityFile}
                   commentsList={commentsList}
                   previewArray={previewArray}
                   file={file}
                   />
                  :
                  docType === "document" ? (
                    <Documents data={data} />
                  ) : docType === "estimate" || docType == "premiumEstimate"? (
                    <Estimates
                      data={data}
                      handleEstimateStatus={handleEstimateStatus}
                    />
                  ) : docType === "subscription" ? (
                    <Subscriptions
                      data={data}
                      handleSubscription={handleSubscription}
                      handleCommonButtonToggle={handleCommonButtonToggle}
                      handleSubscriptionBody={handleSubscriptionBody}
                    />
                  ) : docType === "invoice" || docType == "staff" || docType == "customer" || docType == "equipment" || docType == "contact"? (
                    <Invoices data={data} setData={setData}/>
                  ) : docType === "credit_note" ? (
                    <CreditNote data={data} />
                  ) : docType === "lead" ? (
                    <LeadsTabs
                      data={data}
                      handleCommonButtonToggle={handleCommonButtonToggle}
                      selectedEventDate ={selectedEventDate}
                    />
                  ) : docType === "task" ? (
                    <>
                    <TaskBodyCommonModal
                      data={data ? data : {}}
                      handleBodyAction={handleBodyActionTask}
                      isExternal={isExternal}
                      checklistAssigneesList={checklistAssigneesList}
                      showFullscreen={showFullscreen}
                      pageName={pageName}
                      iscallModal={iscallModal}
                    />
                    </>
                  ) : docType === "paytm" ? (
                    <Paytm />
                  ) : docType == "request-task" ? (
                    <RequestTaskBodyCommonModal data={data ? data : {}} isExternal={isExternal} iscallModal={iscallModal}/>
                  ) : docType == "gmail" ? (
                    <EmailDetailView
                      mailToView={data}
                      id={data["thread"]["id"]}
                      setShowLoader={setShowLoader}
                    />
                  ) : docType == "wallet" ? (
                    <WalletOffcanvas data={data ? data : {}} />
                  ) : docType == "texteditor" ? (
                    <CustomTextEditor data={data ? data : {}} />
                  ) : docType === "spaceDocument" || docType === "documents" ? (
                    <SpaceDocumentCanvas data={data} />
                  ) : docType == "imageview" ? (
                    <ImageDetailOffcanvas data={data} showCommentSection={showCommentSection} ShowCommentModal={ShowCommentModal} />
                  ) : docType == "pilotonboarding" ? (
                    <PilotOnboardingMain data={data} projectId={projectId} />
                   ) : docType == "LiteviewModal" ? (
                      <LiteViewCanvas data={data ? data : {}} />
                   ) : docType == "provider_page" ? (
                      <ProviderListOffcanvas data={data ? data : {}} />
                  ) : (
                    <></>
                  )}
               
                {
                 ["equipment_task","type_of_space","poi"].includes(docType) ?
                 <></>
                 :
                  <CommonFooter
                    data={{
                      comment: commentContent,
                      unreadCommentCount:
                        commonHeaderBodyData &&
                        commonHeaderBodyData.unread_comment_count,
                    }}
                    mentionList={mentionList}
                    count={commentCount}
                    type={docType}
                    handleAction={handleCommonFooter}
                    isAllowToEdit={
                      commonHeaderBodyData &&
                      commonHeaderBodyData.comment_read_only
                        ? 0
                        : 1
                    }
                    docId={
            docType == "provider_page" ?
            localStorage.getItem("currentCompanyID")
            :
                      docType == "gmail"
                        ? data["thread"]["id"]
                        :  data.provider === 'one_drive' ?  data.task_id
                        : data.id || data.task_id || data.lead_id
                    }
                    setShowCommentModal={setShowCommentModal}
                    ShowCommentModal={ShowCommentModal}
                    setShowCommentSection={setShowCommentSection}
                    setType={setType}
                    isExternal={isExternal}
                    spaceData={data}
                    showFullscreen={showFullscreen}
                  />
                  }
                  {/* )} */}

                  
                </div>
              </div>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      {showCommentSection || isExternal || isFromCalendar ? (
        <CommentSection
          loading={loading}
          commentList={comments.length > 0 ? comments : []}
          comment={commentList}
          handleAddOrEditComment={handleAddComment}
          handleClose={handleCloseCommentSection}
          mentionList={mentionList}
          show={showCommentSection || isExternal || isFromCalendar}
          handleDeleteComment={(id, comment) => {
            confirmAlert("delete", id, comment);
          }}
          handleSearchComment={handleSearchComment}
          external={isExternal}
          isReadOnly={
            commonHeaderBodyData && commonHeaderBodyData.comment_read_only
              ? true
              : false
          }
          docType={type || docType}
          data={data ? data : {}}
          meetingUrl={meetingUrl}
          contacts={contacts}
          ImageViewerComments={docType == "imageview" ? true : false}
          commanCommentSection={docType == "imageview" ? true : false}
          guestcommentCount={guestcommentCount}
          callUserList={callUserList}
          showFullscreen={showFullscreen}
          setSelectedAttachment={setSelectedAttachment}
          selectedAttachment={selectedAttachment}
          IsKanbanView={IsKanbanView}
          disableFullScreenToggle={disableFullScreenToggle}
          setShowFullscreen={setShowFullscreen}
          handleAction={handleCommonAction}
          setShowCommentModal={setShowCommentModal}
          setActiveTaskID={setActiveTaskID}
          onCloseShowLeftPanelDefault={onCloseShowLeftPanelDefault}
          handleTaskRating={handleTaskRating}
          taskRating={taskRating}
          getTaskComments={getTaskComments}
          iscallModal={iscallModal}
          pageName={pageName}
        />
      ) : (
        <></>
      )}
      {showLoader ? <CommanLoader /> : <></>}
      {showProposalAmountModal ? (
        <AddEditProposalAmount
          show={showProposalAmountModal}
          handleClose={() => {
            setShowProposalAmountModal(false);
          }}
          data = {{amount: proposalAmount,description: proposalDescription, proposal_id : proposalId}}
        />
      ) : (
        <></>
      )}
      {showLink ? (
        <TaskShareLinkModal
          show={showLink}
          handleClose={() => {
            setShowLink(false);
          }}
          title={docType}
          link={externalPageLink}
        />
      ) : (
        <></>
      )}
      {showConvertLeadModal ? (
        <ConvertLeadToCustomer
          handleClose={() => {
            setShowConvertLeadModal(false);
            setLeadType("");
          }}
          handleSave={(data, spaceData, is_add_operator) => {
            convertLead(data, spaceData, is_add_operator);
          }}
          show={showConvertLeadModal}
          lead={
            data.lead_profile && Object.keys(data.lead_profile).length
              ? data.lead_profile
              : null
          }
          isModal={1}
          type={leadType}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

export default CommanOffCanvas;
