import React, { useEffect, useMemo, useState } from "react";
import jquery from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../actions/messages";
import {
    Info,
    X,
    MagnifyingGlass,
    Funnel,
    CaretDoubleDown,
  } from "phosphor-react";
  import { isLoadingData } from "../hooks/loader_helper";
import CommanLoader from "./Loader/CommanLoader";
import { CardView } from "./Documents/CardView";
import { _l } from "../hooks/utilities";
import InvoicesplaceholderIcon from "../assets/icons/placeholder/InvoicesplaceholderIcon";
import { getEstimateDetail, getInvoiceDetail } from "../actions/documents-actions/documents-actions";
import DocumentsServices from "../services/documents-services";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import { CLIENT_TAB_DATA, ESTIMATE_COUNTER, ESTIMATE_TOPICS, INVOICE_COUNTER } from "../actions/action-type";
import moment from "moment";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import { clientTabData, leftPanelFilters } from "../actions/customer";
const InvoiceList = ({pageName, clientid}) => {
    const [invoiceList, setInvoiceList] = useState([]);
    const [paidInvoices, setPaidInvoices] = useState([]);

    const [sentEstimateList, setSentEstimateList] = useState([]);
    const [sentEstimateListPage, setSentEstimateListPage] = useState(1);
    const [draftEstimateList, setDraftEstimateList] = useState([]);
    const [draftEstimateListPage, setDraftEstimateListPage] = useState(1);
    const [acceptEstimateList, setAcceptEstimateList] = useState([]);
    const [acceptEstimateListPage, setAcceptEstimateListPage] = useState(1);
    const [expiredEstimateList, setExpiredEstimateList] = useState([]);
    const [expiredEstimateListPage, setExpiredEstimateListPage] = useState(1);
    const [declinedEstimateList, setDeclinedEstimateList] = useState([]);
    const [declinedEstimateListPage, setDeclinedEstimateListPage] = useState(1);

  const [docType, setDocType] = useState("");
  const [documentfilter, setdocumentfilter] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [estimateList, setEstimateList] = useState([]);
  const [spaceDocumentList, setSpaceDocumentList] = useState([]);
  const [showDocumentCanvas, setShowDocumentCanvas] = useState(localStorage.getItem('clientTab') == 1 ? true : false);
  const [selectDocument, setSelectDocument] = useState("");
  const [documentCardDetails, setDocumentCardDetails] = useState();
  const [documentDetail, setDocumentDetail] = useState();
  const [estimateListPage, setEstimateListPage] = useState(1);
  const [spaceDocumentListPage, setspaceDocumentListPage] = useState(1);
  const [documentListPage, setDocumentListPage] = useState(1);
  const [subscriptionListPage, setSubscriptionListPage] = useState(1);
  const [creditNoteListPage, setCreditNoteListPage] = useState(1);
  const [selectedId, setSelectedId] = useState(0);
  const [lastFilterRemove, setLastFilterRemove] = useState(false);
  const [invoice, setInvoice] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [estimate, setEstimate] = useState([]);
  const [spaceDocument, setSpaceDocument] = useState([]);
  const [searchString, setSearchString] = useState("");
  const savedDate = localStorage.getItem("selectedDate");
  const estimate_details = useSelector((state) => state.documentsReducer.estimate_details);
  const spaceClient = useSelector((state) => state.customer.spaceClient);
  const google_auth_status = useSelector(
    (state) => state.google_oauth_reducer.google_oauth_status
  );
  const { selectedProject: projectId } = useSelector((state) => state.customer);
  const {
    taskFilters,
    isAppliedUnreadComment,
    activeTaskStatusFilters,
    taskUnreadCmtCntAll, filterBenchmarkCategory,globalSearchString
  } = useSelector((state) => state.customer);
  const pathname = window.location.pathname;

  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(()=>{
    if(spaceClient && spaceClient.id && pathname == '/estimate')
    {
      estimateCardHandler(spaceClient);
      let data = [];
      dispatch(clientTabData(data));
    }
    if(estimate_details)
    {
      setDocumentDetail(estimate_details);
    }
  }, [spaceClient, estimate_details, pathname])
  setTimeout(() => {
    localStorage.setItem('clientTab', 0);
  }, 15000)
  useEffect(() => {
    if(pageName == 'estimate')
    {
      DocumentsServices.getEstimateTopics(pageName, 0).then((res) => {
        if(res.status == 1)
        {
          let data = res.data;
          dispatch({
            type: ESTIMATE_TOPICS,
            payload: {data}
          })
        }
      })
    }
  }, [pageName])

  useEffect(() => {
    if (globalSearchString == undefined || globalSearchString == "") {
      setSentEstimateList(
        estimate && estimate.filter(
          (inv) => (inv.status != null && inv.status === "Sent" ? inv.status : "")
          .toLowerCase()
        )
      );

      setDraftEstimateList(
        estimate && estimate.filter(
          (inv) => (inv.status != null && inv.status === "Draft" ? inv.status : "")
          .toLowerCase()
        )
      );

      setExpiredEstimateList(
        estimate && estimate.filter(
          (inv) => (inv.status != null && inv.status === "Expired" ? inv.status : "")
          .toLowerCase()
        )
      );

      setAcceptEstimateList(
        estimate && estimate.filter(
          (inv) => (inv.status != null && inv.status === "Accepted" ? inv.status : "")
          .toLowerCase()
        )
      );

      setDeclinedEstimateList(
        estimate && estimate.filter(
          (inv) => (inv.status != null && inv.status === "Declined" ? inv.status : "")
          .toLowerCase()
        )
      );
    } else {
        setSentEstimateList(
            estimate &&
              estimate.filter(
                (est) =>
                  (est.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                  (est.date != null ? est.date : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase()) ||
                  (est.id != null ? est.id : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase())) &&
                  (est.status != null && est.status == "Sent" ? est.status : "")
                    .toLowerCase()
              )
          );

          setAcceptEstimateList(
            estimate &&
              estimate.filter(
                (est) =>
                  (est.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                  (est.date != null ? est.date : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase()) ||
                  (est.id != null ? est.id : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase())) &&
                  (est.status != null && est.status == "Accepted" ? est.status : "")
                    .toLowerCase()
              )
          );

          setExpiredEstimateList(
            estimate &&
              estimate.filter(
                (est) =>
                  (est.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                  (est.date != null ? est.date : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase()) ||
                  (est.id != null ? est.id : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase())) &&
                  (est.status != null && est.status == "Expired" ? est.status : "")
                    .toLowerCase()
              )
          );

          setDeclinedEstimateList(
            estimate &&
              estimate.filter(
                (est) =>
                  (est.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                  (est.date != null ? est.date : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase()) ||
                  (est.id != null ? est.id : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase())) &&
                  (est.status != null && est.status == "Declined" ? est.status : "")
                    .toLowerCase()
              )
          );

          setDraftEstimateList(
            estimate &&
              estimate.filter(
                (est) =>
                  (est.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                  (est.date != null ? est.date : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase()) ||
                  (est.id != null ? est.id : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase())) &&
                  (est.status != null && est.status == "Draft" ? est.status : "")
                    .toLowerCase()
              )
          );
    }
  }, [estimate, globalSearchString]);
  
  useEffect(() => {
        getEstimateList();
  }, [projectId, taskFilters, savedDate]);

  const getEstimateList = () => {
    let selectedDate =  moment(savedDate).format("YYYY-MM-DD");
    DocumentsServices.getDocumentEstimates(projectId, taskFilters, selectedDate, clientid).then(
      (res) => {
        if (res.status == 1) {
          setEstimate(res.data);
          let data = res.extra;
          dispatch({
            type: ESTIMATE_COUNTER,
            payload: { data },
          });
        }
      }
    );
  };  
    const handleViewMore = (section) => {
        switch (section) {
          case "sent":
            setSentEstimateListPage(sentEstimateListPage + 1);
            break;
            case "accept":
              setAcceptEstimateListPage(acceptEstimateListPage + 1);
              break;
              case "draft":
                setDraftEstimateListPage(draftEstimateListPage + 1);
                break;
                case "declined":
                  setDeclinedEstimateListPage(declinedEstimateListPage + 1);
                  break;
                  case "expired":
                    setExpiredEstimateListPage(expiredEstimateListPage + 1);
                    break;
                    case "client_estimate":
                    setEstimateListPage(estimateListPage + 1);
                    break;
          default:
            break;
        }
      };
      const details = useSelector((state) => {
        switch (selectDocument) {
          case "invoice":
            return state.documentsReducer.invoice_details;
          case "subscription":
            return state.documentsReducer.subscription_details;
          case "estimate":
            return state.documentsReducer.estimate_details;
          case "credit_note":
            return state.documentsReducer.credit_note_details;
          case "document":
            return state.documentsReducer.document_details;
          case "spaceDocument":
            return state.customer.getFloorDocument;
          default:
            break;
        }
      });
    
      useEffect(() => {
        if (details) {
          setDocumentDetail(details);
        }
      }, [documentDetail, details]);
    const handlePinUnPin = (id, listName, flag = 1) => {
        let itemPosition = -1;
        let updateInvoice = [];
        switch (listName) {
          case "sent":
            itemPosition = sentEstimateList.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...sentEstimateList,
              [itemPosition]: {
                ...sentEstimateList[itemPosition],
                pinned: sentEstimateList[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setSentEstimateList(updateInvoice);
            estimateCardHandler(updateInvoice[itemPosition], flag);
            break;

          case "draft":
            itemPosition = draftEstimateList.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...draftEstimateList,
              [itemPosition]: {
                ...draftEstimateList[itemPosition],
                pinned: draftEstimateList[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setDraftEstimateList(updateInvoice);
            estimateCardHandler(updateInvoice[itemPosition], flag);
            break;

          case "accept":
            itemPosition = acceptEstimateList.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...acceptEstimateList,
              [itemPosition]: {
                ...acceptEstimateList[itemPosition],
                pinned: acceptEstimateList[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setAcceptEstimateList(updateInvoice);
            estimateCardHandler(updateInvoice[itemPosition], flag);
            break;

          case "expired":
            itemPosition = expiredEstimateList.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...expiredEstimateList,
              [itemPosition]: {
                ...expiredEstimateList[itemPosition],
                pinned: expiredEstimateList[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setExpiredEstimateList(updateInvoice);
            estimateCardHandler(updateInvoice[itemPosition], flag);
            break;

            case "client_estimate":
            itemPosition = declinedEstimateList.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...declinedEstimateList,
              [itemPosition]: {
                ...declinedEstimateList[itemPosition],
                pinned: declinedEstimateList[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setDeclinedEstimateList(updateInvoice);
            estimateCardHandler(updateInvoice[itemPosition], flag);
            break;

            case "client_estimate":
              itemPosition = estimate.findIndex((x) => x.id == id);
                updateInvoice = Object.values({
                ...estimate,
                [itemPosition]: {
                  ...estimate[itemPosition],
                  pinned: estimate[itemPosition].pinned == 1 ? 0 : 1,
                },
              });
              setEstimate(updateInvoice);
              estimateCardHandler(updateInvoice[itemPosition], flag);
              break;
        
        }
        if (itemPosition > -1) {
          let tempDocumentDetail = documentDetail;
          if (tempDocumentDetail && Object.keys(tempDocumentDetail).length) {
            tempDocumentDetail["pinned"] =
              tempDocumentDetail["pinned"] == 1 ? 0 : 1;
            setDocumentDetail(tempDocumentDetail);
          }
        }
      };
      const estimateCardHandler = async (estimate, flag = 1) => {
        setShowTaskModal(false);
        if (
          documentCardDetails &&
          documentCardDetails.type === "estimate" &&
          documentCardDetails.id == estimate.id
        ) {
          if (flag) {
            setShowDocumentCanvas(false);
            setDocumentCardDetails();
          }
        } else {
          try {
            // const details = await fetchDocumentDetails("estimate", estimate.id);
            // setDocumentDetail(details.data);
            dispatch(getEstimateDetail(projectId, estimate.id));
            setDocumentCardDetails({ ...estimate, type: "estimate" });
            setShowDocumentCanvas(true);
          } catch (e) {
            dispatch(
              showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
            );
            console.error(e);
          }
        }
      };
    return (
        <>
          {
          isLoadingData("get_all_estimates") ? (
            <div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian">
            <CommanLoader className="position-relative start-0"/>
          </div>
          ) : 
          (
            <>
              <div
                id="list_view"
                className={`comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian ${
                  showTaskModal || showReqestedTaskModal || showDocumentCanvas
                    ? "list-view-toggle"
                    : ""
                }`}
              >
                <Accordion
                  defaultActiveKey={["0","01","02","03","04"]}
                  alwaysOpen
                  className="task-list-accordian-main"
                >
                  <div className="pill-wrapper">
                    <div id="" className="">
                      <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                        <div className="badge  c-font f-12 color-white-80 bg-white-05  radius_3 me-2 d-none">
                          <div className="d-flex align-items-center">
                            <span className="color-white"></span>
                            <a href="#/" className="ps-1">
                              <X size={14} weight="light" className="c-icons" />
                            </a>
                          </div>
                        </div>
                        {taskFilters.length ? (
                      taskFilters.map((value, index) => {
                        if (value.main_key == "date_range_filter") {
                          jquery("body #active_week_date").removeClass("c-gradinet-active-border");
                          let displayFilterKey = value.title;
                          return (
                            <div
                              key={index}
                              className="badge c-font f-12 w-auto color-white-80 bg-white-05  radius_3 ms-2 comman-round-box active py-1"
                            >
                              <div className="d-flex align-items-center">
                                {/* <span className="color-white">{`${filter_key[0].toUpperCase()}${
                                filter_key.length > 1
                                  ? filter_key.slice(1).split("_").join(" ")
                                  : ""
                              }`}</span> */}

                                <span className="color-white">
                                  {displayFilterKey}
                                </span>
                                <a href="#/"
                                  className="ps-1"
                                  onClick={() => {
                                    dispatch(
                                      leftPanelFilters({
                                        main_key: value.main_key,
                                        filter_key: value.filter_key,
                                        title: value.title,
                                        main_sub_key: value.main_sub_key,
                                      })
                                    );
                                    // dispatch(
                                    //   projectTaskCounts(projectId, taskFilters, {
                                    //     main_key: value.main_key,
                                    //     filter_key: value.filter_key,
                                    //     title: value.title,
                                    //   })
                                    // );
                                    // dispatch(
                                    //   projectTaskCounts(
                                    //     projectId,
                                    //     taskFilters,
                                    //     '')
                                    // );
                                    // jquery(
                                    //   ".fixed-left-panel.provider-list"
                                    // ).addClass("expanded");
                                    // jquery("body").addClass("body-toggle");
                                    // jquery(".after-login-header").addClass(
                                    //   "body-toggle"
                                    // );
                                    // jquery("footer").addClass("body-toggle");
                                  }}
                                >
                                  <X size={14} className="c-icons" weight="light" />
                                </a>
                              </div>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <></>
                    )}
                        <div className={`d-flex radius_3 justify-content-between commn-search ${pageName == 'customer' ? 'd-none' : ""}`}>
                          <div className="comment-input-box">
                            <input
                              type="text"
                              className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                              placeholder={`${_l("l_search_documents")}`}
                              onChange={(e) => {
                                setSearchString(e.target.value);
                              }}
                              value={searchString}
                            />
                          </div>
                          <a href="#/" className="comman_action_icon">
                            <div className="d-flex align-items-center ">
                              <div className="action_icon h25w25 d-none">
                                <X
                                  size={14}
                                  weight="light"
                                  className="c-icons m-auto filter-img"
                                />
                              </div>
                              <div className="action_icon h25w25">
                                <MagnifyingGlass
                                  size={16}
                                  weight="light"
                                  className="c-icons MagnifyingGlassIcon"
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {pageName == 'customer' ? 
                  <Accordion.Item eventKey="04" className="with-pb-15 ">
                  <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                  <p className="mb-0"><br></br></p>
                  </div>
                  <Accordion.Body className="taskCountToggle">
                  {estimate && estimate.length ? (
                      estimate
                      .slice(
                          0,
                          estimateListPage > 1
                          ? (estimateListPage - 1) * 10 + 11
                          : estimateListPage * 11
                      )
                      .map((estimate, index) => {
                          return (
                          <CardView
                              hideShow={() => {
                              estimateCardHandler(estimate);
                              setSelectedId(estimate.id);
                              setSelectDocument("estimate");
                              }}
                              data={{
                              ...estimate,
                              downloadUrl: estimate.invoie_url,
                              }}
                              docType={"estimate"}
                              // docType={"pilotonboarding"}
                              setDocType={setDocType}
                              selectedId={selectedId}
                              setSelectedId={setSelectedId}
                              key={index}
                              pinHandler={(id) => {
                              handlePinUnPin(id, "client_estimate");
                              }}
                          />
                          );
                      })
                  ) : (
                      <></>
                  )}
                  {estimate.length &&
                  estimate.length >= (estimateListPage - 1) * 10 + 11 &&
                  estimate.length != (estimateListPage - 1) * 10 + 11 ? (
                      <div className="d-flex justify-content-center">
                      <a href="#/"
                          className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                          onClick={() => {
                          handleViewMore("client_estimate");
                          }}
                      >
                          <span className="title-fonts d-flex justify-content-center align-items-center">
                          <div className="d-flex align-item-center justify-content-center">
                              {_l("l_view_more_btn")}(
                              {estimateListPage > 1
                              ? estimate.length -
                                  ((estimateListPage - 1) * 10 + 11)
                              : estimate.length - 11}
                              )
                              <CaretDoubleDown
                              size={12}
                              weight="light"
                              className="c-icons mx-2"
                              />
                              <span
                              role="status"
                              aria-hidden="true"
                              className="ms-2 opacity-0 spinner-border spinner-border-sm"
                              ></span>
                          </div>
                          </span>
                      </a>
                      </div>
                  ) : (
                      <></>
                  )}
                  {estimate.length == 0 ? (
                      <CommanPlaceholder imgType="no-documents" isAbsolute={true} placeholderText = {_l("l_no_estimates")} />
                  ) : (
                      <></>
                  )}
                  </Accordion.Body>
                </Accordion.Item>
                :
                <>
                <Accordion.Item eventKey="0" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active in-progress accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 in-progress-blue rounded-circle me-2"></span>
                            <span className="task-title me-2">
                            {_l("l_sent")}
                            </span>
                            <span className="">
                            [{sentEstimateList ? sentEstimateList.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {sentEstimateList && sentEstimateList.length ? (
                        sentEstimateList
                        .slice(
                            0,
                            sentEstimateListPage > 1
                            ? (sentEstimateListPage - 1) * 10 + 3
                            : sentEstimateListPage * 3
                        )
                        .map((estimate, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                estimateCardHandler(estimate);
                                setSelectedId(estimate.id);
                                setSelectDocument("estimate");
                                }}
                                data={{
                                ...estimate,
                                downloadUrl: estimate.invoie_url,
                                }}
                                docType={"estimate"}
                                // docType={"pilotonboarding"}
                                setDocType={setDocType}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                key={index}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "sent");
                                }}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {sentEstimateList.length &&
                    sentEstimateList.length >= (sentEstimateListPage - 1) * 10 + 3 &&
                    sentEstimateList.length != (sentEstimateListPage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("sent");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {sentEstimateListPage > 1
                                ? sentEstimateList.length -
                                    ((sentEstimateListPage - 1) * 10 + 3)
                                : sentEstimateList.length - 3}
                                )
                                <CaretDoubleDown
                                size={12}
                                weight="light"
                                className="c-icons mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {sentEstimateList.length == 0 ? (
                        <CommanPlaceholder imgType="no-documents" placeholderText = {_l("l_no_estimates")} />
                    ) : (
                        <></>
                    )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="01" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active to-do accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 in-progress-white rounded-circle me-2"></span>
                            <span className="task-title me-2">
                            {_l("l_draft")}
                            </span>
                            <span className="">
                            [{draftEstimateList ? draftEstimateList.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {draftEstimateList && draftEstimateList.length ? (
                        draftEstimateList
                        .slice(
                            0,
                            draftEstimateListPage > 1
                            ? (draftEstimateListPage - 1) * 10 + 3
                            : draftEstimateListPage * 3
                        )
                        .map((estimate, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                estimateCardHandler(estimate);
                                setSelectedId(estimate.id);
                                setSelectDocument("estimate");
                                }}
                                data={{
                                ...estimate,
                                downloadUrl: estimate.invoie_url,
                                }}
                                docType={"estimate"}
                                // docType={"pilotonboarding"}
                                setDocType={setDocType}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                key={index}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "draft");
                                }}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {draftEstimateList.length &&
                    draftEstimateList.length >= (draftEstimateListPage - 1) * 10 + 3 &&
                    draftEstimateList.length != (draftEstimateListPage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("draft");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {draftEstimateListPage > 1
                                ? draftEstimateList.length -
                                    ((draftEstimateListPage - 1) * 10 + 3)
                                : draftEstimateList.length - 3}
                                )
                                <CaretDoubleDown
                                size={12}
                                weight="light"
                                className="c-icons mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {draftEstimateList.length == 0 ? (
                        <CommanPlaceholder imgType="no-documents" placeholderText = {_l("l_no_estimates")} />
                    ) : (
                        <></>
                    )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="02" className="with-pb-15 d-none">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active red accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 bg-danger rounded-circle me-2"></span>
                            <span className="task-title me-2">
                            {_l("l_expired")}
                            </span>
                            <span className="">
                            [{expiredEstimateList ? expiredEstimateList.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {expiredEstimateList && expiredEstimateList.length ? (
                        expiredEstimateList
                        .slice(
                            0,
                            expiredEstimateListPage > 1
                            ? (expiredEstimateListPage - 1) * 10 + 3
                            : expiredEstimateListPage * 3
                        )
                        .map((estimate, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                estimateCardHandler(estimate);
                                setSelectedId(estimate.id);
                                setSelectDocument("estimate");
                                }}
                                data={{
                                ...estimate,
                                downloadUrl: estimate.invoie_url,
                                }}
                                docType={"estimate"}
                                // docType={"pilotonboarding"}
                                setDocType={setDocType}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                key={index}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "expired");
                                }}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {expiredEstimateList.length &&
                    expiredEstimateList.length >= (expiredEstimateListPage - 1) * 10 + 3 &&
                    expiredEstimateList.length != (expiredEstimateListPage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("estimate");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {expiredEstimateListPage > 1
                                ? expiredEstimateList.length -
                                    ((expiredEstimateListPage - 1) * 10 + 3)
                                : expiredEstimateList.length - 3}
                                )
                                <CaretDoubleDown
                                size={12}
                                weight="light"
                                className="c-icons mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {expiredEstimateList.length == 0 ? (
                        <CommanPlaceholder imgType="no-documents" placeholderText = {_l("l_no_estimates")} />
                    ) : (
                        <></>
                    )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="03" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active done accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 done rounded-circle me-2"></span>
                            <span className="task-title me-2">
                            {_l("l_accepted")}
                            </span>
                            <span className="">
                            [{acceptEstimateList ? acceptEstimateList.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {acceptEstimateList && acceptEstimateList.length ? (
                        acceptEstimateList
                        .slice(
                            0,
                            acceptEstimateListPage > 1
                            ? (acceptEstimateListPage - 1) * 10 + 3
                            : acceptEstimateListPage * 3
                        )
                        .map((estimate, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                estimateCardHandler(estimate);
                                setSelectedId(estimate.id);
                                setSelectDocument("estimate");
                                }}
                                data={{
                                ...estimate,
                                downloadUrl: estimate.invoie_url,
                                }}
                                docType={"estimate"}
                                // docType={"pilotonboarding"}
                                setDocType={setDocType}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                key={index}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "accept");
                                }}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {acceptEstimateList.length &&
                    acceptEstimateList.length >= (acceptEstimateListPage - 1) * 10 + 3 &&
                    acceptEstimateList.length != (acceptEstimateListPage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("estimate");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {acceptEstimateListPage > 1
                                ? acceptEstimateList.length -
                                    ((acceptEstimateListPage - 1) * 10 + 3)
                                : acceptEstimateList.length - 3}
                                )
                                <CaretDoubleDown
                                size={12}
                                weight="light"
                                className="c-icons mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {acceptEstimateList.length == 0 ? (
                        <CommanPlaceholder imgType="no-documents" placeholderText = {_l("l_no_estimates")} />
                    ) : (
                        <></>
                    )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="04" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active red accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 bg-danger rounded-circle me-2"></span>
                            <span className="task-title me-2">
                            {_l("l_declined")}
                            </span>
                            <span className="">
                            [{declinedEstimateList ? declinedEstimateList.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {declinedEstimateList && declinedEstimateList.length ? (
                        declinedEstimateList
                        .slice(
                            0,
                            declinedEstimateListPage > 1
                            ? (declinedEstimateListPage - 1) * 10 + 3
                            : declinedEstimateListPage * 3
                        )
                        .map((estimate, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                estimateCardHandler(estimate);
                                setSelectedId(estimate.id);
                                setSelectDocument("estimate");
                                }}
                                data={{
                                ...estimate,
                                downloadUrl: estimate.invoie_url,
                                }}
                                docType={"estimate"}
                                // docType={"pilotonboarding"}
                                setDocType={setDocType}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                key={index}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "declined");
                                }}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {declinedEstimateList.length &&
                    declinedEstimateList.length >= (declinedEstimateListPage - 1) * 10 + 3 &&
                    declinedEstimateList.length != (declinedEstimateListPage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("declined");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {declinedEstimateListPage > 1
                                ? declinedEstimateList.length -
                                    ((declinedEstimateListPage - 1) * 10 + 3)
                                : declinedEstimateList.length - 3}
                                )
                                <CaretDoubleDown
                                size={12}
                                weight="light"
                                className="c-icons mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {declinedEstimateList.length == 0 ? (
                        <CommanPlaceholder imgType="no-documents" placeholderText = {_l("l_no_estimates")} />
                    ) : (
                        <></>
                    )}
                    </Accordion.Body>
                  </Accordion.Item>
                </>}
                </Accordion>
                {showDocumentCanvas ? (
            <CommanOffCanvas
              show={showDocumentCanvas}
              handleClose={() => {
                setShowDocumentCanvas(false);
                setDocumentCardDetails();
                setDocumentDetail();
                setSelectedId(0);
              }}
              pinHandler={(id, type, flag) => {
                handlePinUnPin(id, type, flag);
              }}
              data={documentDetail}
              setData={setDocumentDetail}
              docType={'estimate'}
              setSelectedId={setSelectedId}
            />
          ) : (
            <></>
          )}
              </div>
          </>
          )}
          </>
          
    )
                      
}
export default InvoiceList;