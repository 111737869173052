import moment from "moment";
import {
  FETCH_GROUPS,
  FETCH_GROUPS_EXTERNAL,
  LAST_MESSAGE_CONTACTDIR,
  CONTACT_DIR_COUNT,
  FETCH_MESSAGES,
  FETCH_UNREAD_MSG_COUNT,
  NEW_MESSAGE,
  UPLOAD_CHAT_MEDIA,
  UPDATE_UPLOADED_FILENAME,
  USER_ONLINE,
  USER_OFFLINE,
  UPDATE_USER_ONLINE,
  UPDATE_USER_OFFLINE,
  UPDATE_NEW_MESSAGE,
  UPDATE_UNREAD_MSG_COUNT,
  USER_READ_MSG,
  UPDATE_USER_READ_MSG,
  FETCH_VIEW_GROUPS,
  NEW_TASK_NOTI,
  ALL_PROJ_UNREAD_MSG_CNT,
  NEW_MSG_FOR_AM,
  UPDATE_NEW_MSG_FOR_AM,
  NEW_GROUP,
  EDIT_GROUP,
  ATTACH_BASE_URL,
  MULTI_USR_PLACEHOLDER,
  EDIT_MESSAGE,
  DELETE_MESSAGE,
  UPDATE_GENERAL_MSG_COUNT,
  OPEN_GENERAL_CHAT_GROUP,
  SET_CALL_STATUS,
  MUTE_CALL,
  UNMUTE_CALL,
  ACCEPT_CALL,
  CALL_NOT_RESPOND,
  SET_MY_STREAM,
  SET_REMOTE_STREAM,
  SET_UNREAD_MSG_COUNT,
  GET_GROUP_DETAILS,
  CALL_ACTION,
  CLEAR_MESSAGE_LIST,
  CALL_TASK_LIST,
  STOP_CHAT_SCROLL,
  SET_TYPING_MEMBERS,
  BURGER_MENU_TOPIC_TYPING,
  DISABLE_INPUT_CHAT,
  BURGER_MENU_TOPIC_ANALYSIS,
  CALL_IS_RECIVE
} from "../actions/chat-action-type";
import { ChatModel } from "../interfaces/chatModels";
import $ from "jquery";

const initialState = new ChatModel();

export default function (state = initialState, action) {
  const { type, payload } = action;
  let messageList = state.messageList;
  let unreadMsgCount = state.unreadMsgCount;
  let groupList = state.groupList;
  let groupListExtrnal = state.groupListExternal;
  let generalMsgCount = state.generalMsgCount;
  let callStatus = state.callStatus;
  let typingMembers = state.typingMembers;
  switch (type) {
    case FETCH_GROUPS:
      return {
        ...state,
        groupList: (payload.data.result) ? payload.data.result : [],
      };
    case FETCH_GROUPS_EXTERNAL:
      let groupListExternalFilterd = (payload.data.result) ? payload.data.result : [];
      groupListExternalFilterd = groupListExternalFilterd && groupListExternalFilterd.length > 0 && groupListExternalFilterd.filter((group) => { 
        return (
          group.extraData && group.extraData.mainKey && group.extraData.mainKey == "contact_directory"
        )})          
      return {
        ...state,
        groupListExternal: groupListExternalFilterd,
      };
    case LAST_MESSAGE_CONTACTDIR:
      var groupListExternal_ = state.groupListExternal ? state.groupListExternal : [];
      var unreadCountTotal = state.contactDirMsgCount ? state.contactDirMsgCount : 0;
      groupListExternal_ = groupListExternal_.map((group) => {
          const { messages } = payload
          let activeGroupId = localStorage.getItem("activeGroupId");
            if(group){
            if (group.groupId == messages.group) {
              group.lastMessageDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
              group.lastMessage = messages.message;
              group.lastMsgUserName = messages.senderName;
              group.lastMsgUserId = messages.user
              if (messages.group != activeGroupId) {
                group.unreadCount += 1;
                unreadCountTotal += 1;
                // if (group.unreadCount == 1) { unread-message line develoment is left
                //   group.firstUnreadMsgId = messages._id
                // }
              }
            }}
        return group;
      })
      return {
        ...state,
        groupListExternal : groupListExternal_,
        contactDirMsgCount : unreadCountTotal
      }
    case CONTACT_DIR_COUNT:
    var groupListExternal_ = state.groupListExternal ? state.groupListExternal : [];
    var unreadCountTotal = state.contactDirMsgCount ? state.contactDirMsgCount : 0;
    const groupId = payload 
    groupListExternal_ = groupListExternal_.map((group) => {
      if (groupId == ""){
        unreadCountTotal += group.unreadCount
      } else if (groupId == group.groupId) {
        unreadCountTotal -= group.unreadCount
        group.unreadCount = 0;
      }  
      return group;
    })
    return {
      ...state,
      groupListExternal : groupListExternal_,
      contactDirMsgCount : unreadCountTotal
    }
    case FETCH_MESSAGES:
      return {
        ...state,
        messageList: (payload.data.success) ? payload.data.result : [],
        otherUserStatus: (payload.data.success) ? payload.data.extra.otherUserStatus : 0,
        firstMsgDate : (payload.data.success) ? payload.data.extra.firstMsgDate : 0
      };
    case NEW_MESSAGE:
      var activeGroupId = localStorage.getItem("activeGroupId");
      var newState = {
        ...state,
        newMessage: payload.data,
      };
      if (activeGroupId == payload.data.group) {
        messageList.push(payload.data);
        newState.messageList = messageList.slice();
      }
      return newState;
    case UPLOAD_CHAT_MEDIA:
      return {
        ...state,
        uploadFileName: payload.data.data ? payload.data.data.filename : "",
      };
    case UPDATE_UPLOADED_FILENAME:
      return {
        ...state,
        uploadFileName: "",
      };
    case USER_ONLINE:
      let groupDetail = state.groupDetails ? state.groupDetails : {};
      if (Object.keys(groupDetail).length > 0) {
        let members = groupDetail.members
        members && members.length > 0 && members.map((user, i) => {
          if (user._id == payload.data.userId) {
            user.isOnline = "1";
          }
          return user;
        });
        groupDetail.members = members && members.length > 0 && members.slice() 
      }
      return {
        ...state,
        userOnline: payload.data,
        groupDetails : groupDetail,
      };
    case USER_OFFLINE:
      let groupDetailss = state.groupDetails ? state.groupDetails : {};
      if (Object.keys(groupDetailss).length > 0) {
        let members = groupDetailss.members
        members && members.length > 0 && members.map((user, i) => {
          if (user._id == payload.data.userId) {
            user.isOnline = "0";
          }
          return user;
        });
        groupDetailss.members = members && members.length > 0 && members.slice() 
      }
      return {
        ...state,
        userOffline: payload.data,
        groupDetails: groupDetailss,
      };
    case UPDATE_USER_ONLINE:
      return {
        ...state,
        userOnline: payload,
      };
    case UPDATE_USER_OFFLINE:
      return {
        ...state,
        userOffline: payload,
      };
    case UPDATE_NEW_MESSAGE:
      return {
        ...state,
        newMessage: payload,
      };
    case FETCH_UNREAD_MSG_COUNT:
      return {
        ...state,
        // unreadMsgCount: (payload.data.success) ? payload.data.result.unreadCount : 0,
        generalMsgCount: (payload.data.success) ? payload.data.result.generalGroupCount : 0,
      };
    case SET_UNREAD_MSG_COUNT:
      return {
        ...state,
        unreadMsgCount: payload,
      };
    case UPDATE_UNREAD_MSG_COUNT:
      unreadMsgCount = unreadMsgCount + parseInt(payload);
      return {
        ...state,
        unreadMsgCount: unreadMsgCount,
      };
    case USER_READ_MSG:
      return {
        ...state,
        readAllGrpMsgBy: payload.data,
      };
    case UPDATE_USER_READ_MSG:
      return {
        ...state,
        readAllGrpMsgBy: payload,
      };
    case FETCH_VIEW_GROUPS:
      return {
        ...state,
        viewGroupList: payload.data.result,
      };
    case NEW_TASK_NOTI:
      return {
        ...state,
        newTaskNoti: payload,
      };
    case ALL_PROJ_UNREAD_MSG_CNT:
      return {
        ...state,
        allProjUnreadMsgCnt: payload.data.result,
      };
    case NEW_MSG_FOR_AM:
      return {
        ...state,
        newMsgForAM: payload.data,
      };
    case UPDATE_NEW_MSG_FOR_AM:
      return {
        ...state,
        newMsgForAM: payload,
      };
    case NEW_GROUP:
      groupList.unshift(payload.data);
      return {
        ...state,
        groupList: groupList,
      };
    case EDIT_GROUP:
      var chatGroups = groupList;
      var groupData = payload.data;
      var index = groupList.findIndex(item => item.groupId == groupData._id);
      if(index > -1){
        chatGroups[index].groupName = groupData.groupName;
        chatGroups[index].displayPicture = groupData.displayPicture;
        var activeGroupId = localStorage.getItem("activeGroupId");
        var groupIcon = groupData.displayPicture != "" ? ATTACH_BASE_URL+groupData.displayPicture : MULTI_USR_PLACEHOLDER;
        if(activeGroupId == groupData._id){
          $("#group_name").text(groupData.groupName);
          $("#group_img").css('background-image', 'url(' + groupIcon + ')');
        }
      }
      return {
        ...state,
        groupList: chatGroups,
      };
    case EDIT_MESSAGE:
      var chatMessageList = messageList;
      var messageData = payload.data;
      var activeGroupId = localStorage.getItem("activeGroupId");
      if(activeGroupId == messageData.group){
        var index = messageList.findIndex(item => item._id == messageData._id);
        if(index > -1){
          chatMessageList[index].message = messageData.message;
          chatMessageList[index].isEdited = 1;
          if (!$.isEmptyObject(messageData.extraData)) {
            chatMessageList[index].extraData = messageData.extraData;
          }
        }
      }
      return {
        ...state,
        messageList: chatMessageList,
      };
    case DELETE_MESSAGE:
      var chatMessageList = messageList;
      var messageData = payload.data;
      var activeGroupId = localStorage.getItem("activeGroupId");
      if(window.location.pathname == "/aichat" || localStorage.getItem("currentView") == "AichatView"){
        chatMessageList = payload.data
      }else
      if(activeGroupId == messageData.group){
        var index = messageList.findIndex(item => item._id == messageData._id);
        if(index > -1){
          chatMessageList.splice(index, 1);
        }
      }
      return {
        ...state,
        messageList: chatMessageList,
      };
    case UPDATE_GENERAL_MSG_COUNT:
      generalMsgCount = generalMsgCount + parseInt(payload);
      return {
        ...state,
        generalMsgCount: generalMsgCount,
      };
    case OPEN_GENERAL_CHAT_GROUP:
      return {
        ...state,
        openGeneralChatGroup: payload,
      };
    case SET_CALL_STATUS:
      return {
        ...state,
        callStatus: payload,
      };
    case MUTE_CALL:
      var currentCallStatus = callStatus;
      if (!$.isEmptyObject(callStatus)) {
        if(callStatus.callerInfo && callStatus.callerInfo._id == payload.muteUserId){
          currentCallStatus.callerInfo.isMute = 1;
        }else if(callStatus.receiverInfo && callStatus.receiverInfo._id == payload.muteUserId){
          currentCallStatus.receiverInfo.isMute = 1;
        }
      }
      return {
        ...state,
        callStatus: currentCallStatus,
      };
    case UNMUTE_CALL:
      var currentCallStatus = callStatus;
      if (!$.isEmptyObject(callStatus)) {
        if(callStatus.callerInfo && callStatus.callerInfo._id == payload.muteUserId){
          currentCallStatus.callerInfo.isMute = 0;
        }else if(callStatus.receiverInfo && callStatus.receiverInfo._id == payload.muteUserId){
          currentCallStatus.receiverInfo.isMute = 0;
        }
      }
      return {
        ...state,
        callStatus: currentCallStatus,
      };
    case ACCEPT_CALL:
      var currentCallStatus = callStatus;
      if (!$.isEmptyObject(callStatus)) {
        if(payload.userId != "" && callStatus.callerInfo._id == payload.userId){
          currentCallStatus.status = "inProgress";
        }else if(payload.receiverId != "" && callStatus.receiverInfo._id == payload.receiverId){
          currentCallStatus.status = "inProgress";
        }
        currentCallStatus.ans = payload.ans;
        currentCallStatus.extraData.isReceiverCamFound = payload.isReceiverCamFound;
      }
      return {
        ...state,
        callStatus: currentCallStatus,
      };
    case CALL_NOT_RESPOND:
      var currentCallStatus = callStatus;
      if (!$.isEmptyObject(callStatus)) {
        if(currentCallStatus.randomCallId == payload.randomCallId && currentCallStatus.status != "inProgress"){
          currentCallStatus.status = "notResponding"
        }
      }
      return {
        ...state,
        callStatus: currentCallStatus,
      };
    case SET_MY_STREAM:
      return {
        ...state,
        myStream: payload,
      };
    case SET_REMOTE_STREAM:
      return {
        ...state,
        remoteStream: payload,
      };
    case GET_GROUP_DETAILS:
      return {
        ...state,
        groupDetails: payload,
      }
    case CALL_ACTION:
      var currentCallStatus = callStatus;
      var actionKey = payload.actionKey;
      let task_id = "";
      if (!$.isEmptyObject(callStatus)) {
        if(callStatus.callerInfo && callStatus.callerInfo._id == payload.actionUserId){
          if(actionKey == "record"){
            currentCallStatus.callerInfo.isRecording = payload.actionVal == "start" ? 1 : 0;
          }else if(actionKey == "turnCamera"){
            currentCallStatus.callerInfo.isCameraOff = payload.actionVal == "off" ? 1 : 0;
          }else if(actionKey == "screenShare"){
            currentCallStatus.callerInfo.isScreenShare = payload.actionVal == "on" ? 1 : 0;
          }
        }else if(callStatus.receiverInfo && callStatus.receiverInfo._id == payload.actionUserId){
          if(actionKey == "record"){
            currentCallStatus.receiverInfo.isRecording = payload.actionVal == "start" ? 1 : 0;
          }else if(actionKey == "turnCamera"){
            currentCallStatus.receiverInfo.isCameraOff = payload.actionVal == "off" ? 1 : 0;
          }else if(actionKey == "screenShare"){
            currentCallStatus.receiverInfo.isScreenShare = payload.actionVal == "on" ? 1 : 0;
          }
        }

        if (actionKey == "NewTask") {
           task_id = payload.actionVal
           currentCallStatus = {...currentCallStatus, task_id: task_id}    
        }
        if (actionKey == "newChecklist") {
          let checklist = payload.extraData && payload.extraData.checklist ? payload.extraData.checklist : []
          currentCallStatus ={...currentCallStatus, checklistItems: checklist}
        }
        if (actionKey == "newComment") {
          let comments = payload.extraData && payload.extraData.comments ? payload.extraData.comments : []
          currentCallStatus ={...currentCallStatus, commentsList: comments}
        }
        if (actionKey == "newAttachments") {
          let attachments = payload.extraData && payload.extraData.attachments ? payload.extraData.attachments : []
          currentCallStatus ={...currentCallStatus, attachmentsList: attachments}
        }
      }
      return {
        ...state,
        callStatus: currentCallStatus,
      };
      case CLEAR_MESSAGE_LIST:
        return{
          ...state,
          messageList: []
        }
      case CALL_TASK_LIST:
        return{
          ...state,
          callTaskAPI: payload
        }
      case STOP_CHAT_SCROLL:
        return{
          ...state,
          stopScrolling: payload
        }
      case SET_TYPING_MEMBERS:
        if(payload){
          typingMembers = typingMembers ? typingMembers : [];
          var uIndex = typingMembers.findIndex((item) => item.userId == payload.userId);
          if(payload.action == "add" && uIndex == -1){ // For add member in the typing list and if it not already in the list
            typingMembers.push({
              userId: payload.userId,
              fname: payload.fname,
              lname: payload.lname
            });
          }else if(payload.action == "remove" && uIndex > -1){ // For remove member from the typing list
            typingMembers = typingMembers.filter((item) => item.userId != payload.userId);
          }
        }
        return{
          ...state,
          typingMembers: typingMembers.slice()
        }
      case BURGER_MENU_TOPIC_TYPING :
        let setMessageTyping = state.burgerMenuTopicTyping ? state.burgerMenuTopicTyping : [];
        if (payload) {
          let {groupId, action} = payload
          if (action == "add") {
            setMessageTyping.push(groupId)
          }else if (action == "remove") {
            setMessageTyping = setMessageTyping.filter((item) => item != groupId)
          }
        }
        return{
          ...state,
          burgerMenuTopicTyping : setMessageTyping.slice()
        }
      case DISABLE_INPUT_CHAT:
        return{
          ...state,
          disableInputChat: payload
        }
      case CALL_IS_RECIVE:
        return{
          ...state,
          isCallRecived: payload
        }
      case BURGER_MENU_TOPIC_ANALYSIS :
        let bergerMTAnalysis = state.bergerMenuTopicAnalysis ? state.bergerMenuTopicAnalysis : [] ;
        if (payload) {
          let {groupId, disableInput} = payload
          if (disableInput == 0) {
            bergerMTAnalysis = bergerMTAnalysis.filter((item) => item != groupId)
          }else if (disableInput == 1) {
            bergerMTAnalysis.push(groupId)
          }
        }
        return{
          ...state,
          bergerMenuTopicAnalysis : bergerMTAnalysis.slice()
        }
    default:
      return state;
  }
}
