import React, { useEffect, useState, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import MyrAiIcon from "../../../assets/icons/MyrAiIcon";
import EmailDetailViewListing from "../../TaskModalComponents/EmailDetailViewListing";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ReactTooltip from "react-tooltip";
import { _l, getDatePickerLocale, getDatePickerTimeCaption } from "../../../hooks/utilities";
import ActionDropdown from "../../Dropdowns/ActionDropdown";
import CustomerServices from "../../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../../actions/messages";
import {
  X,
  DotsThreeVertical,
  Image,
  Paperclip,
  PencilSimpleLine,
  LinkSimple,
  Smiley,
  Trash,
  Eye,
  ArrowBendDoubleUpLeft,
  CaretUp,
  CaretDown,
  Hash,
  CalendarBlank,
} from "phosphor-react";
import DatePicker from "react-datepicker";
import { decode } from "url-safe-base64";

import { setMailCcAssignee, getDefaultTopics, setMailReply } from "../../../actions/customer";
import AddScheduleEmailModal from "../../Modals/AddScheduleEmailModal";
import moment from "moment";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import EmailAccordionDropDown from "../../Dropdowns/EmailAccordionDropDown";
import customerServices from "../../../services/customer-services";
import { Spinner } from "react-bootstrap";
import CommanLoader from "../../Loader/CommanLoader";
import AiReply from "../../../assets/icons/AiReply";

const EmailDetailViewAccordians = ({ mailToView, setShowLoader, id }) => {
  const { isMailReplay } = useSelector((state) => state.customer)
  const [ReplySection, setReplySection] = useState(false);
  const [emailReply, setEmailReply] = useState("");
  const dispatch = useDispatch();
  const [imageBuffer, setImageBuffer] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const fileUpload = useRef(null);
  const [mailDetails, setMailDetails] = useState(mailToView);
  const threadId = mailToView["thread"]["id"];
  const forceUpdate = React.useReducer((bool) => !bool, true)[1];
  const [messagesExpanded, setMessagesExpanded] = useState(false);
  const [topMessage, setTopMessage] = useState(false);
  const [bottomMessage, setBottomMessage] = useState(false);
  const [mailCc, setMailCc] = useState('');
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState("");
  const [selectedId, setSelectedId] = useState([]);
  const projectId = localStorage.getItem("selectedOffice");
  const [showScheduleMailModule, setShowScheduleMailModule] = useState(false);
  const [emails, setEmails] = useState([]);
  const [trackEmail, settrackEmail] = useState({})
  const [startDate, setStartDate] = useState('');
  const [loader, setLoader] = useState(false);
  const [emailReplyLoader, setEmailReplyLoader] = useState(false);
  const [initialalContent, setInitialalContent] = useState('')
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    dispatch(getDefaultTopics(projectId, "", "", "MyEmail"));
    setShowLoader(false);
    collapseMessages();
    let email = extractEmails(mailToView.conversation.messages[0]["payload"]["headers"].filter((header) => header["name"] == "From")[0]["value"])
    let content = mailToView.conversation.messages[0].snippet
    setEmails(email);
    setMailCc(email);
    setInitialalContent(content);
  }, []);

  var attachmentIds = [];

  useEffect(() => {
    mailDetails["conversation"]["messages"].map((message) => {
      (message["payload"]["parts"] || []).map((part) => {
        if (
          part["body"]["attachmentId"] != null &&
          part["body"]["data"] == null
        ) {
          attachmentIds.push(part["body"]["attachmentId"]);
        }
      });
      return message;
    });
    if (attachmentIds.length > 0) {
      CustomerServices.getMailAttachments(
        threadId,
        JSON.stringify(attachmentIds)
      ).then((res) => {
        if (res.status) {
          var updatedMail = mailDetails;
          updatedMail["conversation"]["messages"] = updatedMail["conversation"][
            "messages"
          ].map((message) => {
            (message["payload"]["parts"] || []).map((part) => {
              if (part["body"]["attachmentId"] != null) {
                part["body"]["data"] =
                  res.data[part["body"]["attachmentId"]]["data"];
                part["body"]["updated"] = 1;
                return part;
              }
            });
            return message;
          });

          setMailDetails(updatedMail);
          collapseMessages();
          forceUpdate();
          attachmentIds = [];
        }
      });
    }
  }, []);
  const extractEmails = (text) => {
    return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
  };
  const htmlDecode = (content) => {
    let e = document.createElement("div");
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };
  const handleMailReply = (messageId) => {
    if (!emailReply && imagePreviews.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_topic"))
      );
    } else {
      setShowLoader(true);
      setLoader(true);
      CustomerServices.sendMailReply(
        emailReply,
        messageId,
        imageBuffer,
        emails,
        selectedId
      ).then((res) => {
        if (res.status == 1) {
          if (res.data) {
            res.data.forEach((element, i) => {
              mailDetails["conversation"]["messages"].push(element);  
            });
          }
          setMailDetails(mailDetails);
          setReplySection(false);
          dispatch(setMailReply(false));
          setEmailReply("");
          setShowLoader(false);
          setLoader(false);
          setImagePreviews([]);
          setImageBuffer([]);
          collapseMessages();
          setMailCc("");
        }
      });
    }
  };
  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      previrewArray.push({ name: img.name, url: URL.createObjectURL(img) });
    });
    setImagePreviews(imagePreviews.concat(previrewArray));
  };
  const removeImageSelection = (name) => {
    const updatedImagePreviews = Array.from(imagePreviews).filter(
      (img) => img.name !== name
    );
    const updatedImageBuffer = imageBuffer.filter((file) => file.name !== name);
    setImageBuffer(updatedImageBuffer);
    setImagePreviews(updatedImagePreviews)
  };
  const toggleMessages = () => {
    setMessagesExpanded(!messagesExpanded);
    setTopMessage([]);
    setBottomMessage(mailDetails["conversation"]["messages"]);
  };
  const collapseMessages = () => {
    setMessagesExpanded(mailDetails["conversation"]["messages"].length > 2);
    if (mailDetails["conversation"]["messages"].length > 2) {
      setTopMessage([mailDetails["conversation"]["messages"][0]]);
      setBottomMessage([
        mailDetails["conversation"]["messages"][
          mailDetails["conversation"]["messages"].length - 1
        ],
      ]);
    } else {
      setTopMessage([]);
      setBottomMessage(mailDetails["conversation"]["messages"]);
    }
  };
  const handleAction = (action, data) => {
    var action_type = "set";
    if (selectedId.indexOf(data.details.id) > -1) {
      action_type = "remove";
    } else {
      var temp = selectedId;
      temp.push(data.details.id);
      setSelectedId(temp);
    }
    dispatch(setMailCcAssignee(data, action_type));
    if (action_type == "remove") {
      var sid = selectedId.filter((id) => {
        if (id != data.details.id) {
          return id;
        }
      });
      setSelectedId(sid);
    }
  };
  var isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
  const handleScheduleMailReply = (messageId) => {
    if (!emailReply && imagePreviews.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_topic"))
      );
    } else {
      setShowLoader(true);
      CustomerServices.sendMailReply(
        emailReply,
        messageId,
        imageBuffer,
        mailCc,
        selectedId
      ).then((res) => {
        if (res.status == 1) {
          mailDetails["conversation"]["messages"].push(res.data);
          setMailDetails(mailDetails);
          setReplySection(false);
          dispatch(setMailReply(false));
          setEmailReply("");
          setShowLoader(false);
          setImagePreviews([]);
          setImageBuffer([]);
          collapseMessages();
          setMailCc("");
        }
      });
    }
  };
  const scheduleMailOld = (date) => {
    // handleMailReply
    if (!emailReply && imagePreviews.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_topic"))
      );
    } else {
      setShowLoader(true);
      CustomerServices.scheduleMail(
        emailReply,
        threadId,
        imageBuffer,
        mailCc,
        selectedId,
        date
      ).then((res) => {
        setShowScheduleMailModule(false);

        if (res.status == 1) {
          // mailDetails['conversation']['messages'].push(res.data);
          // setMailDetails(mailDetails);
          setReplySection(false);
          dispatch(setMailReply(true));
          setEmailReply("");
          setShowLoader(false);
          setImagePreviews([]);
          setImageBuffer([]);
          collapseMessages();
          setMailCc("");
        } else {
          dispatch(showMessage("unsucess", _l("l_error"), res.message));
        }
      });
    }
  };

  const validationEmailsend = () => {
    let flag = true;
    if (emails.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_to_email_is_required"))
      );
      flag = false;
    }else if (emailReply == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_content_required"))
      );
      flag = false;
    }
    return flag;
  }

  const scheduleMail = (date) => {
    if (!validationEmailsend()){
      return false;
    }
    try {
      setLoader(true);
      customerServices.sendCreateScheduleEmail('', emailReply, emails, imageBuffer, date, threadId, true).then(((res) => {
        if (res.status) {
          dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
          setReplySection(false);
          dispatch(setMailReply(false));
          setEmailReply("");
          setShowLoader(false);
          setLoader(false);
          setImagePreviews([]);
          setImageBuffer([]);
          collapseMessages();
          setMailCc("");
          setStartDate('');
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
        }
      }))
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeMailID = (mail) => {
    setEmails(emails.filter((w)=> w !== mail))
  }

  const handleAddEmail = () => {
    if (mailCc.trim() !== '') {
      if (!emailRegex.test(mailCc.trim())) {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_email"))
        );
      }else{
        setEmails([...emails, mailCc.trim()]);
        setMailCc(''); 
      }
    }else{
      // setTagEmail('');
    }
  };
  
  const getEmailTracks = (email) => {
      customerServices.getEmailTrackingDetail(
          email.id
        ).then((res) => {
          if (res.status == 1) {
            settrackEmail(res.data)
          }
        });
  }

  const getMaxTime = () => {
    let formattedTimeStamp = moment();
    formattedTimeStamp.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 59,
    });
    return new Date(formattedTimeStamp);
  };

  const addOneHour = (date) => {
    try {
      let formattedTimeStamp = moment(date);
      formattedTimeStamp = formattedTimeStamp.add(75, "minutes");
      return new Date(formattedTimeStamp);
    } catch (error) {
      console.log("error", error);
    }
  };

  const minSelectableTime = addOneHour(new Date());

  const minTimeHour = (date) => {
    try {
      const newDate = new Date(date);
      newDate.setHours(newDate.getHours() - newDate.getHours());
      newDate.setMinutes(newDate.setMinutes() - newDate.setMinutes());
      return newDate;
    } catch (error) {
      console.log("error", error);
    }
  };

  const minSelectableTimeHour = minTimeHour(new Date());
  
  const changeTimeAndDate = (date) => {
    let setdateTime = moment(new Date());
    setdateTime = setdateTime.add(75, "minutes");
    if (new Date(setdateTime) > new Date(date)) {
      setStartDate(new Date(setdateTime));
    }else{
      setStartDate(date);
    }
  }
  const email_auto_reply = (mail, content) => {
    try {
      setEmailReplyLoader(true);
        customerServices.emailAutoReplyChat(mail, content).then((res) => {
          if (res.status) {
            setMailCc('')
            setEmailReply(res.data);
          }
        }).finally(() => {
            setEmailReplyLoader(false);
        });
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      <div className="custom-accordian-main overflow-hiiden-auto flex-grow-1 with-left-header-arrow emaildetailviewaccordians d-flex flex-column px-3 bg-white-03">
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          {topMessage.length > 0 &&
            topMessage.map((message) => {
              return (
                <Accordion.Item
                  eventKey={message["id"]}
                  className="comman-list p-0"
                >
                  <div className="d-flex justify-content-between align-items-center w-100 email-tab-main">
                    <div className="d-flex align-items-center maxw100minus180 ">
                      <Accordion.Header className="p-0 flex-grow-1 text-truncate w-auto">
                        <div className="d-flex align-items-center email-tab-left w-100">
                          <div className="comman-image-box h32w32 rounded-circle">
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                              style={{
                                backgroundImage: `url('${mailDetails["thread"]["profile"]}')`,
                              }}
                            ></div>
                          </div>
                          <div
                            className="c-font f-14 title-fonts px-2 w100minus50 flex-grow-1"
                            data-tip={extractEmails(
                              message["payload"]["headers"].filter(
                                (header) => header["name"] == "From"
                              )[0]["value"]
                            )}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <div className="text-truncate w-100">
                              <div className="text-truncate">
                                {htmlDecode(
                                  message["payload"]["headers"].filter(
                                    (header) => header["name"] == "From"
                                  )[0]["value"]
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <span className="c-font f-13">To -</span> */}
                        </div>
                      </Accordion.Header>
                    </div>
                    <div className="d-flex align-items-center email-tab-right text-nowrap">
                      <span className=" c-font f-12 color-white-60 pe-2">
                        {
                          message["payload"]["headers"].filter(
                            (header) => header["name"] == "Date"
                          )[0]["value"]
                        }
                      </span>
                      <div className="dropdown">
                        <a href="#/"
                          className="h20w20 d-flex align-items-center mx-1"
                          type="button"
                          id="EmailDetailViewListing"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <Eye size={16} className="c-icons" weight="light" />
                        </a>
                        <EmailDetailViewListing />
                      </div>
                      <UncontrolledDropdown className={`p-10  res py-0 pe-0 comman_action_icon  `}>
                        <div className="d-flex align-items-center m-auto light-theme-img shadow-none comman_action_icon justify-content-center">
                          <DropdownToggle tag={'div'} className="d-flex dropdown dropdown-center team-list-dropdown ">
                            <a href="#/"
                              className={`d-flex on-hover-active-toggle-img h32w32 with_bg action_icon me-10px 'activeicon'`}
                              type="button"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              aria-expanded="false"
                              data-tip={_l("l_email_tracking")}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              onClick={() => {getEmailTracks(message)}}
                              >
                              <div className="m-auto ">
                              <Eye size={18} weight="light" className="c-icons" />
                            </div>
                              {trackEmail && trackEmail.track_details && trackEmail.track_details.length.length > 0 ?
                                <span className="badge-custom email-orange d-flex rounded-pill p-0">{trackEmail.track_details.length.length || 0}</span>
                              : <></>}                                  
                            </a>
                            <ReactTooltip />
                          </DropdownToggle>
                          <div toggle={false} className="px-0"> 
                            <EmailAccordionDropDown data = {trackEmail}/>
                          </div>
                        </div>
                      </UncontrolledDropdown>
                      <div className="dropdown comman_action_icon">
                        <a href="#/"
                          className="action_icon with_bg h323w32 d-flex align-items-center mx-1"
                          type="button"
                          id="GeneralListing"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          <DotsThreeVertical
                            size={16}
                            className="c-icons"
                            weight="light"
                          />
                        </a>
                        <ActionDropdown
                          actions={[
                            {
                              actionName: _l("l_edit"),
                              actionHandler: () => {},
                              actionIcon: "PencilSimpleLineIcon",
                            },
                            {
                              actionName: _l("l_reply"),
                              actionHandler: () => {},
                              actionIcon: "ArrowBendDoubleLeftIcon",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <Accordion.Body className="c-font f-14 color-white-60 pb-2 CustomEdit">
                    <div
                      className= {
                        isHTML(
                          (message["payload"]["body"]["data"] ||
                            (message["payload"]["parts"].filter(
                              (part) => part["mimeType"] == "text/html"
                            ).length > 0
                              ? message["payload"]["parts"].filter(
                                  (part) => part["mimeType"] == "text/html"
                                )
                              : message["payload"]["parts"][0]["parts"])[0][
                              "body"
                            ]["data"] ||
                            "",
                          "base64").toString("utf8")
                        )
                          ? "email-body-content"
                          : ""
                      }
                      dangerouslySetInnerHTML={{
                        __html: Buffer.from(
                          message["payload"]["body"]["data"] ||
                            (message["payload"]["parts"].filter(
                              (part) => part["mimeType"] == "text/html"
                            ).length > 0
                              ? message["payload"]["parts"].filter(
                                  (part) => part["mimeType"] == "text/html"
                                )
                              : message["payload"]["parts"][0]["parts"])[0][
                              "body"
                            ]["data"] ||
                            "",
                          "base64"
                        ).toString("utf8"),
                      }}
                    ></div>
                    {(message["payload"]["parts"] || []).filter(
                      (part) =>
                        part["body"]["attachmentId"] != null &&
                        part["body"]["data"] != null
                    ).length > 0 ? (
                      <div className="mt-4 pt-2 px-3 radius_3">
                        <div className="row flex-wrap">
                          {message["payload"]["parts"]
                            .filter(
                              (part) =>
                                part["body"]["attachmentId"] != null &&
                                part["body"]["data"] != null
                            )
                            .map((part) => {
                              return (
                                <>
                                  <div className="col-xl-2 radius_3 px-1 pb-2">
                                    <div className="c-list-icon w-100">
                                      <div className="h130w130 w-100 comman-round-box with-bg radius_3 with-react-fancybox">
                                        <div
                                          className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                          style={{
                                            backgroundImage: `url(data:image/png;base64,${decode(
                                              part["body"]["data"]
                                            )})`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          <a href="#/" className="list-view-toggle w-100 d-none">
            <div className=" d-flex justify-content-between list-view p-1 px-2 task-list-card">
              <span className="c-font f-10 title-fonts color-white-80 d-flex align-items-center">
                <span className="me-1 fw-bold">10+</span>
                <span className="">View More</span>
              </span>
              <div className="d-flex flex-column justify-content-between opacity-60">
                <span className="line-height-0">
                  <CaretUp size={10} className="c-icons" weight="fill" />
                </span>
                <span className="line-height-0">
                  <CaretDown size={10} className="c-icons" weight="fill" />
                </span>
              </div>
            </div>
          </a>
          {messagesExpanded ? (
            <div
              className="chat-main"
              onClick={() => {
                toggleMessages();
              }}
            >
              <div class="chat-date-seperator px-0">
                <span class="seperator-line pt-1 border-start-0 border-end-0"></span>
                <a href="#/" class="date-text color-white-80 c-font f-10 title-fonts text-nowrap px-0">
                  <div className="border d-flex h30w30 rounded-circle box-shadow-3">
                    <span className="m-auto letter_spacing05">
                      {mailDetails["conversation"]["messages"].length - 2}
                    </span>
                  </div>
                </a>
                <span class="seperator-line pt-1 border-start-0 border-end-0"></span>
              </div>
            </div>
          ) : (
            <></>
          )}
          {bottomMessage.length > 0 &&
            bottomMessage.map((message, i) => {
              return (
                <Accordion.Item
                  eventKey={
                    i == bottomMessage.length - 1 || bottomMessage.length == 1
                      ? "0"
                      : message["id"]
                  }
                  className="comman-list p-0"
                >
                  <div className="d-flex justify-content-between align-items-center w-100 email-tab-main">
                    <div className="d-flex align-items-center maxw100minus180 ">
                      <Accordion.Header className="p-0 flex-grow-1 text-truncate w-auto">
                        <div className="d-flex align-items-center email-tab-left w-100">
                          <div className="comman-image-box h32w32 rounded-circle">
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                              style={{
                                backgroundImage: `url('${mailDetails["thread"]["profile"]}')`,
                              }}
                            ></div>
                          </div>
                          <div
                            className="c-font f-13 title-fonts px-2 w100minus50 flex-grow-1"
                            data-tip={extractEmails(
                              message["payload"]["headers"].filter(
                                (header) => header["name"] == "From"
                              )[0]["value"]
                            )}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <div className="text-truncate w-100">
                              <div className="text-truncate">
                                {htmlDecode(
                                  message["payload"]["headers"].filter(
                                    (header) => header["name"] == "From"
                                  )[0]["value"]
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <span className="c-font f-13">To -</span> */}
                        </div>
                      </Accordion.Header>
                    </div>
                    <div className="d-flex align-items-center email-tab-right text-nowrap">
                      <span className=" c-font f-12 color-white-60 pe-2">
                        {
                         moment( message["payload"]["headers"].filter(
                            (header) => header["name"] == "Date"
                          )[0]["value"]).format("DD-MM-YYYY hh:mm a")
                        }
                        (
                          {moment( message["payload"]["headers"].filter(
                            (header) => header["name"] == "Date"
                          )[0]["value"]).fromNow()}
                        )
                      </span>
                      <UncontrolledDropdown className={`p-10  res py-0 pe-0 comman_action_icon  `}>
                        <div className="d-flex align-items-center m-auto light-theme-img shadow-none comman_action_icon justify-content-center">
                          <DropdownToggle tag={'div'} className="d-flex dropdown dropdown-center team-list-dropdown ">
                            <a href="#/"
                              className={`d-flex on-hover-active-toggle-img h32w32 with_bg action_icon me-10px 'activeicon'`}
                              type="button"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              aria-expanded="false"
                              data-tip={_l("l_email_tracking")}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              onClick={() => {getEmailTracks(message)}}
                              >
                              <div className="m-auto ">
                              <Eye size={18} weight="light" className="c-icons" />
                            </div>                                
                            </a>
                            <ReactTooltip />
                          </DropdownToggle>
                          <div toggle={false} className="px-0"> 
                            <EmailAccordionDropDown data = {trackEmail}/>
                          </div>
                        </div>
                      </UncontrolledDropdown>
                      {/* <div className="dropdown comman_action_icon">
                        <a href="#/"
                          className="action_icon with_bg h32w32 d-flex align-items-center mx-1"
                          type="button"
                          id="GeneralListing"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          <DotsThreeVertical
                            size={16}
                            className="c-icons"
                            weight="light"
                          />
                        </a>
                        <ActionDropdown
                          actions={[
                            {
                              actionName: _l("l_edit"),
                              actionHandler: () => {},
                              actionIcon: "PencilSimpleLineIcon",
                            },
                            {
                              actionName: _l("l_reply"),
                              actionHandler: () => {},
                              actionIcon: "ArrowBendDoubleLeftIcon",
                            },
                          ]}
                        />
                      </div> */}
                    </div>
                  </div>
                  <Accordion.Body className="c-font f-14 color-white-60 pb-2 CustomEdit">
                    <div
                      className={
                        isHTML(
                          (message["payload"]["body"]["data"] ||
                            (message["payload"]["parts"].filter(
                              (part) => part["mimeType"] == "text/html"
                            ).length > 0
                              ? message["payload"]["parts"].filter(
                                  (part) => part["mimeType"] == "text/html"
                                )
                              : message["payload"]["parts"][0]["parts"])[0][
                              "body"
                            ]["data"] ||
                            "",
                          "base64").toString("utf8")
                        )
                          ? "email-body-content"
                          : ""
                      }
                      dangerouslySetInnerHTML={{
                        __html: Buffer.from(
                          message["payload"]["body"]["data"] ||
                            (message["payload"]["parts"].filter(
                              (part) => part["mimeType"] == "text/html"
                            ).length > 0
                              ? message["payload"]["parts"].filter(
                                  (part) => part["mimeType"] == "text/html"
                                )
                              : message["payload"]["parts"][0]["parts"])[0][
                              "body"
                            ]["data"] ||
                            "",
                          "base64"
                        ).toString("utf8"),
                      }}
                    ></div>
                    {(message["payload"]["parts"] || []).filter(
                      (part) =>
                        part["body"]["attachmentId"] != null &&
                        part["body"]["data"] != null
                    ).length > 0 ? (
                      <div className="mt-4 pt-2 px-3 radius_3">
                        <div className="row flex-wrap">
                          {message["payload"]["parts"]
                            .filter(
                              (part) =>
                                part["body"]["attachmentId"] != null &&
                                part["body"]["data"] != null
                            )
                            .map((part) => {
                              return (
                                <>
                                  <div className="col-xl-2 radius_3 px-1 pb-2">
                                    <div className="c-list-icon w-100">
                                      <div className="h130w130 w-100 comman-round-box with-bg radius_3 with-react-fancybox">
                                        <div
                                          className="comman-bg-img h-100 w-100 bg-style-cover radius_3"
                                          style={{
                                            backgroundImage: `url(data:image/png;base64,${decode(
                                              part["body"]["data"]
                                            )})`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
      </div>
      {/* <div className="file-list-wrapper">
        <div className="d-flex flex-wrap">
          <div className="file-image-list pb-4">
            <div className="c-list-icon w-100 position-relative with-overlay">
              <div className="h130w130 comman-round-box with-bg radius_3 with-react-fancybox">
                <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3"style={{ backgroundImage: `url('${User_2}')` }}></div>
              </div>
              <a href="#/" className="line-height-0 file-action-option"
                type="button"
                id="GeneralListing"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false">
                <DotsThreeVertical size={18} className="c-icons" weight="light" />
              </a>
              <ActionDropdown
                actions={[
                  {
                    actionName: _l("l_delete"),
                    actionHandler: () => {
                    },
                    actionIcon: "TrashIcon",
                  }
                ]}
              />
            </div>
            <div class="max-w-130px pt-2">
              <div class="c-font text-truncate f-12 color-white-60 ">aiony-haust-3TLl_97HNJo-unsplash.jpg</div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className={`replying-box-main d-flex flex-column  ${
          ReplySection || isMailReplay
            ? "bg-white-03 flex-grow-1 custom-accordian-Header-sticky with-bg-color-white with-min-height w90per"
            : "mt-auto ms-auto"
        } `}
      >
        {ReplySection || isMailReplay? (
          <div className="reply-box d-flex flex-column flex-grow-1">
            <div className="d-flex justify-content-between pb-3">
              <div className="reply-left d-flex flex-grow-1">
                <a href="#/" className="h20w20 d-flex align-items-center mx-1">
                  <ArrowBendDoubleUpLeft
                    size={16}
                    className="c-icons"
                    weight="light"
                  />
                </a>
                <div className="position-relative px-2 d-flex align-items-center comman_action_icon flex-grow-1">
                  <Form.Group className="align-items-center d-flex justify-content-center">
                      {
                        emails && emails.length > 0 && emails.map((mail, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className='badge c-font f-12 p-2 rounded-pill me-1 mt-2'
                              >
                                {mail}
                                <a href="#/" className='ps-1'
                                  onClick={() => removeMailID(mail)}
                                >
                                  <X size={10} weight="light" className="c-icons" />
                                </a>
                              </div>
                            </>
                          )
                        })
                      }
                   
                    <Form.Control
                      // className="bg-transparent mx-1 p-2 h-100"
                      className="bg-transparent border-0 border-bottom f-12 h-100 me-1 mt-2 p-2"
                      value={mailCc}
                      onChange={(e) => {
                        setMailCc(e.target.value);
                      }}
                      placeholder={_l('l_please_enter_email')}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleAddEmail();
                        }
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex align-items-center email-tab-right text-nowrap reply-right">
                <span className=" c-font f-12 color-white-60 pe-2">
                  {Date()}
                </span>
                <a href="#/"
                  className="h20w20 d-flex align-items-center mx-1"
                  onClick={() => {
                    setReplySection(false);
                    dispatch(setMailReply(false));
                    setStartDate('');
                    collapseMessages();
                  }}
                >
                  <Trash size={16} className="c-icons" weight="light" />
                </a>
              </div>
            </div>
            
            <div className="d-flex position-relative flex-grow-1">
              <div className="h30w30 comman-image-box rounded-circle">
                <div
                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                  style={{
                    backgroundImage: `url('${mailDetails["thread"]["profile"]}')`,
                  }}
                ></div>
              </div>
              <Form.Group className="w100minus30 d-flex flex-column flex-grow-1">

              <Form.Label className="input-label no-asterisk ml-10">
                  {_l('l_content')} :
                  </Form.Label> 
                  { emailReplyLoader
                    ? <CommanLoader message={_l("l_fetching_AI_reply")} className={`comman-main-loader-wrapper `} />
                    : <Form.Control
                      className="bg-transparent border-0 h-100 pt-0 flex-grow-1"
                      as="textarea"
                      value={emailReply}
                      onChange={(e) => setEmailReply(e.target.value)}
                      placeholder={_l('l_write_description_placeholder')}
                      rows={3}
                    />

                  }
              </Form.Group>
              <React.Fragment>
                {imagePreviews.length ? ( 
                  <div className="upload-image-preview ">
                    <div className="d-flex align-items-center justify-content-start">
                      {imagePreviews.map((preview, index) => {
                        return (
                          <div
                            key={index}
                            className="comman-image-box h25w25 radius_3 upload-image with-margin"
                          >
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                              style={{
                                backgroundImage: `url(
                                  '${preview.url}'
                                )`,
                              }}
                            ></div>
                            <a href="#/"
                              className="upload-close"
                              onClick={() => {
                                removeImageSelection(preview.name);
                              }}
                            >
                              <X size={10} className="c-icons" weight="light" />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </React.Fragment>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`${
            ReplySection
              ? "d-flex align-items-center justify-content-between flex-wrap pt-15"
              : ""
          }`}
        >
          {ReplySection ? (
            <div className="d-flex align-items-center flex-wrap comman_action_icon">
              <a href="#/" className="me-2 action_icon with_bg h32w32" onClick={() => fileUpload.current.click()}>
                <input
                  type="file"
                  multiple
                  onChange={(event) => {
                    let commentsfiles = [];
                    Array.from(event.target.files).map((file) => {
                      let tempImg = file;
                      commentsfiles.push(tempImg);
                    });
                    setImageBuffer(imageBuffer.concat(commentsfiles));
                    preparePreview(event.target.files);
                  }}
                  ref={fileUpload}
                  onClick={(e) => {
                    e.target.value = "";
                  }}
                  className="d-none"
                  id="reply_files"
                />
                <Paperclip size={18} className="c-icons" weight="light" />
              </a>
              <a href="#/" className="me-2 action_icon h32w32 me-2 with_bg">
                <LinkSimple size={16} className="c-icons" weight="light" />
              </a>
              <a href="#/" className="me-2 action_icon h32w32 me-2 with_bg">
                <Smiley size={16} className="c-icons" weight="light" />
              </a>
              <a href="#/" className="me-2 action_icon h32w32 me-2 with_bg">
                <Image size={16} className="c-icons" weight="light" />
              </a>
              <a href="#/" className="me-2 action_icon h32w32 me-2 with_bg">
                <PencilSimpleLine
                  size={18}
                  className="c-icons"
                  weight="light"
                />
              </a>
              <a href="#/" className="me-2 action_icon with_bg h32w32">
                <DotsThreeVertical
                  size={18}
                  className="c-icons"
                  weight="light"
                />
              </a>
              <a href="#/" className="me-2 action_icon with_bg h32w32 justify-content-center align-items-center">
                <MyrAiIcon className="HW18" />
              </a>
            </div>
          ) : (
            <></>
          )}
          <div className={`Reply-button d-flex ${ReplySection ? "" : "text-end"}`}>
            {ReplySection ? 
              <React.Fragment>
                <div className="comman_action_icon me-20px">
                  <a href="#/" data-tip={_l("l_AI_reply")} data-effect="solid" data-delay-show='1000' data-class="tooltip-main" className="action_icon align-items-center h32w32 justify-content-center with_bg" 
                    onClick={() => {email_auto_reply(mailCc, initialalContent)}}
                  >
                    <AiReply/>
                  </a>
                <ReactTooltip />
                </div>
              </React.Fragment> 
            : <React.Fragment></React.Fragment>}
            {ReplySection && 
            <div
              className={`${
                startDate
                  ? "h32wauto"
                  : "h32w32"
              } comman_action_icon custom-datepicker  me-10px on-hover-bg-white-05 withinputabsolute`}
              data-tip={_l("l_schedule_replay")}
              data-effect="solid"
              data-delay-show="1000"
              data-class="tooltip-main"
            >
              <div

                className={`action_icon align-items-center d-flex h-100 justify-content-center light-theme-img on-hover-active-toggle-img on-hover-color-green position-relative text-truncate w-100 with_bg  ${
                  startDate
                    ? "active px-2"
                    : ""
                }`}
              >
                <CalendarBlank
                  size={18}
                  weight="light"
                  className="c-icons m-0"
                />
                {startDate?
                <>
                {/* <span class="with_separator2_10"></span>  */}
                  <span className="title-fonts">{`${moment(startDate).format(
                  "DD/MM/yyyy hh:mm a"
                )}`}</span>
                </>
                :
                <></>
                // <span className="ms-10px">{`${_l("l_schedule_replay")}`}</span>
                }
                <DatePicker
                  portalId="custom_datepicker"
                  calendarClassName="custom-datepicker"
                  timeCaption={getDatePickerTimeCaption()}
                  locale={getDatePickerLocale()}
                  className="close-toast"
                  onChange={(date) => {
                    changeTimeAndDate(date);
                  }}
                  showTimeSelect
                  selected={startDate}
                  selectsStart
                  dateFormat="dd/MM/yyyy hh:mm a"
                  calendarStartDay={1}
                  minDate = {new Date()}
                  minTime={moment(new Date()).format("YYYY-MM-DD") == moment(startDate || new Date()).format("YYYY-MM-DD") ? minSelectableTime : minSelectableTimeHour}
                  maxTime ={getMaxTime()}
                  >
                  <div className="datepicker-label">
                    {_l("l_select_date_and_time")}
                  </div>
                </DatePicker>
              </div>
              <ReactTooltip />
            </div>
            // <Button
            //   variant="secondary"
            //   size="sm"
            //   className="ms-auto mx-3"
            //   disabled
            //   onClick={() => {
            //     if (ReplySection) {
            //       setShowScheduleMailModule(true);
            //     }
            //   }}
            // >
            //   {_l("l_schedule_replay")}
            // </Button>
            }
            <Button
              variant="primary"
              size="sm"
              className=""
              disabled={loader}
              onClick={() => {
                setReplySection(true);
                dispatch(setMailReply(true));
                collapseMessages();
                if (ReplySection) {
                  if (startDate) {
                    scheduleMail(startDate)
                  }else{
                    handleMailReply(mailDetails["thread"]["id"]);
                  }
                }
                // jqury(".taskDetailModal .accordion-collapse").removeClass("show");
              }}
            >
              {loader 
                  ? <>
                    {_l("l_please_wait")} <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                    />
                  </>
                  :ReplySection 
                    ? _l("l_send") 
                    : _l("l_replay")}
            </Button>
          </div>
        </div>
      </div>

      <ReactTooltip />
      <AddScheduleEmailModal
        show={showScheduleMailModule}
        handleClose={() => {
          setShowScheduleMailModule(false);
        }}
        handleSubmit={scheduleMail}
      />
    </>
  );
};

export default EmailDetailViewAccordians;
{
}
