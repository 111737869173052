import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";
import customerServices from "../../services/customer-services";
import { showMessage } from "../../actions/messages";
import { useDispatch, useSelector } from "react-redux";
import { updateSpaceContent } from "../../actions/customer";
import Spinner from "react-bootstrap/Spinner";

const PdfSignatureTittle = ({ show, handleClose,handleSignatureChange }) => {
  const [loader, setLoader] = useState(false)


  return (
    <>
      <div className="">
        <Modal
          show={show}
          
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_signature")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className=" pt-0">
            <div className="form-wrapper-main border-bottom pb-2">
              <div className="row">
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative d-flex">
                  <Form.Check
                  className="me-20px"
                  type="radio"
                    rows={5}
                    name=""
                    value="company"
                    onChange={(e)=>{handleSignatureChange(e)}}
                    label={"Company Signature"}
                  ></Form.Check>
                  <Form.Check
                  type="radio"
                    rows={5}
                    name=""
                    value="agent"
                    onChange={(e)=>{handleSignatureChange(e)}}
                    label={"Agent Signature"}
                  ></Form.Check>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button variant="primary" size="sm" onClick={() => {
              if (!loader) {
              }
            }}
              disabled={loader}
            >
              {loader
                ? _l('l_please_wait')
                : _l('l_save')}
              {loader ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
                : <></>
              }
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </>
  );
};

export default PdfSignatureTittle