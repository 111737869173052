import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { _l, showError, showSuccess, tabindexFix } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import CommanLoader from "../Loader/CommanLoader";
import { getBenchmarkDetail, setApiLoaderShow, setEditScheduleJsonData, setListOfSelectedOperator } from "../../actions/customer";
import CreateScheduleNew from "../Offcanvas/NewSchedule/CreateScheduleNew";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import { CalendarBlank } from "phosphor-react";
import DatePicker from "react-datepicker";
import benchmarkServices from "../../services/benchmark-services";
import { Spinner } from "react-bootstrap";
import moment from "moment";


const EditScheduleChecklist = ({ benchmarkData, handleClose }) => {

  const [step, setStep] = useState("assignment-screen");
  const [taskData, setTaskData] = useState({});
  const [selectedDate, setSelectedDate] = useState();
  const [loader, setLoader] = useState(false);


  const dispatch = useDispatch();

  const { isLoadingBenchmarkWeekSchedule, isLoadingBenchmarkInfo , benchmarkDetail, editedScheduleData } =
    useSelector((state) => state.customer);

    const handleSave = async() =>{
      setLoader(true);
      dispatch(setApiLoaderShow(true));
      try {
        let response = await benchmarkServices.updateScheduleBydate(
          benchmarkDetail.project_id, 
          benchmarkDetail.id,
          editedScheduleData,
          selectedDate
        )
        if (response && response.status) {
          dispatch(setApiLoaderShow(false));
          showSuccess("l_update_schedule")
        }else{
        dispatch(setApiLoaderShow(false));
        showError(response.message ? response.message : "l_something_went_wrong")
        }
      } catch (error) {
        console.log(error);
        
      } finally {
        dispatch(setApiLoaderShow(false));
        setLoader(false);
        dispatch(setEditScheduleJsonData([]));
      }
  
    }


  useEffect(() => {
    dispatch(getBenchmarkDetail(benchmarkData.id));
  }, []);


  return (
    <>
      {taskData && taskData.id > 0 ? (
        <CommanOffCanvas
          show={true}
          handleClose={() => {
            setTaskData({});
          }}
          pinHandler={(id, type, flag) => {
            // handlePinUnPin(id, type, flag);
          }}
          data={taskData}
          docType={"task"}
          setSelectedId={() => {}}
          selectedTaskId={taskData.id}
          IsKanbanView={false}
          meetingUrl={""}
          handleParentComponentAction={(type, taskData) => {}}
          pageName={"editScheduleChecklist"}
          isFullScreen={true}
          contacts={[]}
          callUserList={[]}
          isExternalWithNoLeftPanel={true}
        />
      ) : (
        <Modal
          show={true}
          onHide={handleClose}
          className="custom-modal-style provider-screen-modal c-modal scheduleEditChecklist"
          fullscreen
          centered
          backdrop={false}
          keyboard={false}
          onShow={() => {
            tabindexFix();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              <div className="d-flex align-items-center justify-content-center">
                <span className="text-center">
                  {_l("l_edit_checklist")} #{benchmarkData.id}
                </span>
              </div>
            </Modal.Title>
            
                <div className="d-flex justify-content-end ms-20px ms-auto" >
                  <div className="comman_action_icon custom-datepicker me-20px" data-bs-toggle="tooltip"
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main">
                    <div className={`action_icon h-100 light-theme-img on-hover-active-toggle-img position-relative w_180px with_bg ps-10px`}>
                      <CalendarBlank
                        size={22}
                        weight="light"
                        className="c-icons"
                      />
                      <DatePicker
                        portalId="custom_datepicker"
                        calendarClassName="custom-datepicker"
                        calendarStartDay={1}
                        className="close-toast"
                        placeholderText={_l("l_date")}
                        selected= {selectedDate }
                        onChange={(date) => {
                          setSelectedDate(date)
                        }}
                        showTimeSelect
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date(moment(new Date()).add(1,"days"))}
                      >
                        <div className="datepicker-label">{_l("l_select_date_and_time")}</div>
                      </DatePicker>
                    </div>
                  </div>

                  <Button className={`me-20px ${loader ? "for-disabled" : ""} `}variant="primary" size="lg" onClick={() => {
                    handleSave()
                  }}>
                    { loader ? _l("l_please_wait") : _l("l_save")}
                  {loader ?
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 `}
                      aria-hidden="true"
                    />
                    : <></>}
                    </Button>
                </div>
         
            
          </Modal.Header>
          <Modal.Body className="mt-15px pt-0">
            {isLoadingBenchmarkInfo || isLoadingBenchmarkWeekSchedule ? (
              <CommanLoader />
            ) : (
              <CreateScheduleNew
                hideFooter={true}
                handleClose={() => {}}
                handleStep={() => {}}
                readOnlyMode={false}
                selectedTab={""}
                step={step}
                setTaskData={setTaskData}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default EditScheduleChecklist;
