import React, { useEffect, useState } from "react";
import { _l, showError } from "../../hooks/utilities";
import RiskTable from "../Providertables/RiskTable";
import SpaceTable from "../Providertables/SpaceTable";
import DocumentTable from "../Providertables/DocumentTable";
import ContactTable from "../Providertables/ContactTable";
import TodoTable from "../Providertables/TodoTable";
import { Button } from "react-bootstrap";
import RiskListModal from "../Modals/RiskListModal";
import ProviderProfileTable from "../Providertables/ProviderProfileTable";
import CreateTodoModal from "../Modals/CreateTodoModal";
import customerServices from "../../services/customer-services";
import { getListOfRiskData, getProviderListTableData, updateProviderListLoader } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import UploadDocument from "../Modals/UploadDocument";
import CommanLoader from "../Loader/CommanLoader";
import ScheduleTable from "../Providertables/ScheduleTable";
import EquipmentTable from "../Providertables/EquipmentTable";

const TABS = [
  {
    title: "l_provider_profile",
    color: "black",
    name: "Provider-Profile",
  },
  {
    title: "l_contact",
    color: "black",
    name: "Contact",
  },
  {
    title: "l_space",
    color: "black",
    name: "Space",
  },
  {
    title: "l_document",
    color: "black",
    name: "Document",
  },
  {
    title: "l_risk",
    color: "black",
    name: "Risk",
  },
  {
    title: "l_todo_kpi_name",
    color: "black",
    name: "Todo",
  },
  {
    title: "l_schedule",
    color: "black",
    name: "Schedule",
  },
  {
    title: "l_equipment",
    color: "black",
    name: "Equipment",
  },
];
const ProviderListOffcanvas = ({ data }) => {

  const { providerlistTableData , providerListDataLoading} = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState("Provider-Profile");
  const [tabCounts, setTabCounts] = useState({
    Risk: 0,
    Space: 0,
    Document: 0,
    Contact: 0,
    Todo: 0,
  });
  const [showRiskModal, setShowRiskModal] = useState(false);
  const [showTodoModal, setShowTodoModal] = useState(false);
  const [showDocumnentsModal, setShowDocumnentsModal] = useState(false);

  let providerID = localStorage.getItem("currentCompanyID")

  const fetchData = (tab_name) => {
    dispatch(updateProviderListLoader(true));
    try {
      customerServices.getSelectedProviderDetails(providerID, tab_name).then((res) => {
        if (res && res.status) {
          switch (tab_name) {
            case "company_profile":
              dispatch(getProviderListTableData({ ...providerlistTableData, "company_profile": res.data.company_profile }));

              break;
            case "contact":
              dispatch(getProviderListTableData({ ...providerlistTableData, "contact": res.data.contact }));

              break;
            case "spaces":
              dispatch(getProviderListTableData({ ...providerlistTableData, "spaces": res.data.spaces }));

              break;
            case "document":
              dispatch(getProviderListTableData({ ...providerlistTableData, "document": res.data.document }));

              break;
            case "risk":
              dispatch(getProviderListTableData({ ...providerlistTableData, "risk": res.data.risk }));
              dispatch(getListOfRiskData(res.data.risk))

              break;
            case "task":
              dispatch(getProviderListTableData({ ...providerlistTableData, "task": res.data.task }));

              break;
            case "schedule":
              dispatch(getProviderListTableData({ ...providerlistTableData, "schedule": res.data.schedule }));

              break;
            case "equipment":
              dispatch(getProviderListTableData({ ...providerlistTableData, "equipment": res.data.equipment }));

            break;
            default:
              break;
          }
          dispatch(updateProviderListLoader(false));

        } else {
          showError(res.message);
          dispatch(updateProviderListLoader(false));

        }
      })
    } catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    try {
      customerServices.getProviderCompanyTabsCount(providerID).then((res) => {
        setTabCounts({
          Risk: res.data.risk ? res.data.risk : 0,
          Space: res.data.spaces ? res.data.spaces : 0,
          Document: res.data.document ? res.data.document : 0,
          Contact: res.data.contact ? res.data.contact : 0,
          Todo: res.data.task ? res.data.task : 0,
          Schedule: res.data.schedule? res.data.schedule : 0,
          Equipment: res.data.equipment? res.data.equipment : 0,
        });
      })
    } catch (error) {
    }
  }, []);

  useEffect(() => {
    switch (selectedTab) {
      case "Provider-Profile":
        fetchData("company_profile")
        break;
      case "Contact":
        fetchData("contact")
        break;
      case "Space":
        fetchData("spaces")
        break;
      case "Document":
        fetchData("document")
        break;
      case "Risk":
        fetchData("risk")
        break;
      case "Todo":
        fetchData("task")
        break;
      case "Schedule":
        fetchData("schedule")
        break;
      case "Equipment":
        fetchData("equipment")
        break;
      default:
        fetchData("company_profile")
        break;
    }

  }, [selectedTab])


  return (
    <>
   
      <div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian position-relative px20py20">
        <div className="comman-tab-wrapper  pb-20px">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {TABS.map((t) => {
                return (
                  <>
                    <div
                      className={`tab-wrapper d-flex align-items-center ${selectedTab === t.name ? "active" : ""
                        }`}
                    >
                      <a
                        href="#/"
                        className="tab-name d-flex align-items-center bg-white-03"
                        onClick={() => {
                          setSelectedTab(t.name);
                        }}
                      >
                        {/* <span class="c-dots  c-7 done rounded-circle me-10px"></span> */}
                        <span>{_l(t.title)}</span>
                        {!["l_provider_profile"].includes(t.title) && <span className="ms-1">{`[ ${t.name == "Risk"
                            ? tabCounts.Risk
                            : t.name == "Space"
                              ? tabCounts.Space
                              : t.name == "Document"
                                ? tabCounts.Document
                                : t.name == "Contact"
                                  ? tabCounts.Contact
                                  : t.name == "Todo"
                                    ? tabCounts.Todo
                                    : t.name == "Schedule"
                                     ? tabCounts.Schedule
                                      : t.name == "Equipment"
                                        ? tabCounts.Equipment
                                        : 0
                          } ]`}</span>}
                      </a>
                    </div>
                  </>
                );
              })}
            </div>
            {["Risk", "Todo", "Document", "Contact"].includes(selectedTab) ? <Button variant={"primary"} className="" size="sm"
              onClick={() => {
                switch (selectedTab) {
                  case "Risk":
                    setShowRiskModal(true);

                    break;
                  case "Todo":
                    setShowTodoModal(true);

                    break;
                  case "Document":
                    setShowDocumnentsModal(true);
                    break;
                  case "Contact":
                    window.open(`/connect-provider?space=${localStorage.getItem("selectedOffice")}&provider-type=invite_provider`, "_blank");
                    break;

                  default:
                    break;
                }
              }}>
              {selectedTab == "Risk" ? _l("l_add_risk") :
                selectedTab == "Todo" ? _l("l_create_todo") :
                  selectedTab == "Document" ? _l("l_upload_file") :
                    selectedTab == "Contact" ? _l("l_invite_provider")
                      : ""
              }
            </Button> : <></>}
          </div>
        </div>
        {providerListDataLoading ? 
      <> 
        <CommanLoader className="position-relative start-0 main-loader-wrapper full-screen" />  
      </> : <div className="comman_vertical_btn_h  comman-white-box-shadow light-theme-white-bg">
          <div className="comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1">
            {selectedTab == "Risk" ? (
              <RiskTable data={data.risk} />
            ) : selectedTab == "Space" ? (
              <SpaceTable data={data.spaces} />
            ) : selectedTab == "Document" ? (
              <DocumentTable data={data.document} />
            ) : selectedTab == "Contact" ? (
              <ContactTable data={data.contact} />
            ) : selectedTab == "Todo" ? (
              <TodoTable data={data.task} />
            ) : selectedTab == "Schedule" ? (
              <ScheduleTable data={data.schedule}/>
            ) : selectedTab == "Equipment" ? (
              <EquipmentTable data={data.equipment}/>
            ) :
             selectedTab == "Provider-Profile" ? (
              <ProviderProfileTable data={data.company_profile} />
            ) : (
              <></>
            )}
          </div>
        </div> }
      </div> 
      {
        showRiskModal ?
          <RiskListModal
            show={showRiskModal}
            handleClose={() => {
              setShowRiskModal(false);
            }}
          />
          : <></>
      }
      {
        showTodoModal ?
          <CreateTodoModal
            show={showTodoModal}
            handleClose={() => {
              setShowTodoModal(false);
            }}
          />
          : <></>
      }
      {
        showDocumnentsModal ?
          <>
            <UploadDocument
              show={showDocumnentsModal}
              handleClose={() => {
                setShowDocumnentsModal(false);
              }}
              data={{}}
              selectedDocument={{}}
            />
          </> :
          <></>
      }
    </>
  );
};

export default ProviderListOffcanvas;
