import { ChatCircle, Image } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SetSelectedTask, commanoffcanvasisOpenfromCalendar, dayGridWeek, getMentionList, getSelectedTask, getSelectedTaskCalendar, getSpaceFloorList, getTaskChecklist, getTaskList, setIndexForCalendarSelectedTask, setSpaceNotificationCount, setTaskUnreadCmtCntAll, setVirtualSpaceNotificationCount } from '../actions/customer';
import moment from 'moment';
import CustomerServices from "../services/customer-services";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import UncontrolledGeneralListing from './TaskModalComponents/UncontrolledGeneralListing';
import CommanOffCanvas from './Offcanvas/CommanOffCanvas';
import CheckedDropdown from './Dropdowns/CheckedDropdown';
import DocumentsServices from "../services/documents-services";
import CommentMinified from './Comments/CommentMinified';
import { _l, formateDate } from '../hooks/utilities';
import User_2 from "../assets/images/default-image-placeholder.png";
import ImageUploadDropdown from './Dropdowns/ImageUploadDropdown';
import { event } from 'jquery';
import $ from "jquery";
import ReactTooltip from 'react-tooltip';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import CalendarDropdown from './Dropdowns/CalendarDropdown';
import { GET_3RD_PARTY_CONTACTS, GET_GOOGLE_CONTACTS, SET_CONTACT_DATA, SET_NEW_MEETING } from '../actions/action-type';
import { checkIsAssigned, get_refresh_request_data } from '../actions/outlook_auth';
import outlookCalendar from '../services/outlook-calendar';
import { googlecalendar_assignees } from '../actions/googlecalendar_auth';
import googleCalendar from '../services/google-calendar';
import jquery, { isNumeric } from "jquery";
import CommanLoader from './Loader/CommanLoader';
import CreateTaskOffcanvas from './Offcanvas/CreateTaskOffcanvas';
import CommanPlaceholder from './Placeholder/CommanPlaceholder';
// import { find } from 'rsuite/esm/utils/ReactChildren';
import { getFloorDetails } from '../actions/benchmark-actions/benchmark-actions';

const Customcalendar = (
    { pageName,
        setselectedEventDate = () => { },
        selectStartDate,
        setDashboardStartDate=() => { },
        setDashboardEndDate = () => { },
    }) => {
    const {
        calendarData,
        taskList,
        selectedProject,
        activeTaskStatusFilters,
        taskFilters,
        defaultTopics,
        selectedTask,
        commanoffcanvasIsOpen,
        taskUnreadCmtCntAll,
        mentionList,
        isDayGridWeek,
        isDayGridMonth,
        isDayGridDay,
        globalSearchString,
        globalView,
        daterangeFilterApplied,
        appliedDaterangeFilter,
        pinDelayfilter,
        tasklistLoader,
        displayThreeDays,
        daterangeAppliedFilters
    } = useSelector((state) => state.customer);

    const outlook_calendar = useSelector(
        (state) => state.discord_auth.outlook_calendar_connectivity
    );
    const [isOutlookCalendarActive, setIsOutlookCalendarActive] = useState(outlook_calendar != undefined && outlook_calendar.status == 1 ? true : false);
    const projectId = localStorage.getItem("selectedOffice");
    const client_id = localStorage.getItem("client_id");
    const contact_id = localStorage.getItem("contact_id");
    const [calendarWeekData, setCalendarWeekData] = useState([]);
    const [calendarMonthData, setCalendarMonthData] = useState([]);
    const savedDate = localStorage.getItem("selectedDate");
    const [events, setEvents] = useState([]);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [commonModalData, setCommonModalData] = useState();
    const [task_type, setTask_type] = useState('');
    const [taskId, setTaskId] = useState(0);
    const [meetingUrl, setMeetingUrl] = useState("");
    const [convertTaskId, setConvertTaskId] = useState(0);
    const [activeTaskID, setActiveTaskID] = useState(0);
    const [openCheckList, setOpenCheckList] = useState(false);
    const [commentsLoading, setCommentsLoading] = useState(false);
    const [commentUnreadCount, setCommentUnreadCount] = useState(0);
    const [commentsList, setCommentsList] = useState([]);
    const [tempTaskId, setTempTaskId] = useState(0);
    const [commentCount, setCommentCount] = useState(0);
    const [showComments, setShowComments] = useState(false);
    const [openAttachmentsDropdown, setOpenAttachmentsDropdown] = useState(false);
    const [attchamentCount, setAttchamentCount] = useState(0);
    const [heightWidth, setHeightWidth] = useState({});
    const [show, setShow] = useState(false);
    const [dropdownToggle, setDropdownToggle] = useState(false);
    const [calendarEventInfo, setEventInfo] = useState({});
    const [showCalendarDropDown, setShowCalendarDropDown] = useState(false);
    const [providers, setProviders] = useState("");
    const [shareContacts, setShareContacts] = useState([]);
    const [dayClick, setDayClick] = useState("");
    const [selectCalIds, setSelectCalIds] = useState([]);
    const [showTaskInfo, setShowTaskInfo] = useState(false);
    const dispatch = useDispatch();
    const [showRequestTaskInfo, setShowRequestTaskInfo] = useState(false);
    const [isGoogleCalendarActive, setGoogleCalendarActive] = useState(0);
    const [tempCalendarId, setTempCalendarId] = useState('');
    const googlecalendar_connectivity = useSelector(
        (state) => state.google_calendar.googlecalendar_connectivity
    );
    const [tempHoverTask, setTempHoverTask] = useState({});
    const [dayofWek, setdayofWek] = useState(0);
    const [tempTaskList, setTempTaskList] = useState([]);
    const [taskcreateId, setTaskcreateId] = useState(0);
    const [showCreateTaskOffcanvas, setShowCreateTaskOffcanvas] = useState(false);
    const [createtaskDate, setcreatetaskDate] = useState(new Date())
    const [selectedDateIndex, setSelectedDateIndex] = useState(0)
    const [calendarCardClickEvent, setCalendarCardClickEvent] = useState(true)
    let selectedDate = localStorage.getItem("selectedDate")
    
    const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    useEffect(() => {
        if (calendarData && isDayGridMonth == false) {
            setCalendarWeekData(calendarData);
            setstartDate(calendarData[0].date);
            setEndDate(calendarData[6].date);
        } else if(daterangeFilterApplied == false){
            const firstDayOfMonth = moment( todayDate).startOf('month');
            const lastDayOfMonth = moment(selectStartDate ? selectStartDate : todayDate ).endOf('month');
            const todayDate = new Date();
            // setstartDate(firstDayOfMonth);
            // setEndDate(lastDayOfMonth);
            // localStorage.setItem("fromDate", firstDayOfMonth);
            // localStorage.setItem("toDate", lastDayOfMonth);
        }
    }, [calendarData, selectedDate])
   
     useEffect(() => {
        if(taskFilters && taskFilters.length > 0) {
        // dispatch(getTaskList(
        // // CustomerServices.getTaskList(
        //     client_id,
        //     contact_id,
        //     localStorage.getItem("selectedOffice"),
        //     moment(savedDate).format("YYYY-MM-DD"),
        //     false,
        //     activeTaskStatusFilters,
        //     taskFilters,
        //     "",
        //     0,
        //     0,
        //     isDayGridDay ? moment(localStorage.getItem("selectedDate")).subtract(1,"days").format("YYYY-MM-DD") : moment(startDate).format("YYYY-MM-DD"),
        //     isDayGridDay ? moment(localStorage.getItem("selectedDate")).add(1,"days").format("YYYY-MM-DD") : moment(endDate).format("YYYY-MM-DD"),
        //     globalView
        // ))
        // .then((res) => {
        //     if (res.status) {
        //         setEvents([])
        //         if (res.data.length > 0) {
        //             let temptaskList = res.data.sort((a, b) => {
        //                 let event_dateA = "";
        //                 if (a.startdate != null) {
        //                     event_dateA = moment(a.startdate).format("hh:mm A");
        //                 } else if (a.duedate != null) {
        //                     event_dateA = moment(a.duedate).format("hh:mm A");
        //                 } else {
        //                     event_dateA = moment(a.dateadded).format("hh:mm A");
        //                 }
        //                 let event_dateB = "";
        //                 if (b.startdate != null) {
        //                     event_dateB = moment(b.startdate).format("hh:mm A");
        //                 } else if (b.duedate != null) {
        //                     event_dateB = moment(b.duedate).format("hh:mm A");
        //                 } else {
        //                     event_dateB = moment(b.dateadded).format("hh:mm A");
        //                 }
        //                 return moment(event_dateA, 'hh:mm A').diff(moment(event_dateB, 'hh:mm A'));
        //             })
        //             var validDateData = [];
        //             temptaskList.map((eItem) => {
        //                 if(eItem.startdate == null && eItem.duedate == null){

        //                 }else{
        //                     validDateData.push(eItem);
        //                 }
        //             });
        //             setEvents(validDateData);
        //             setTempTaskList(validDateData);
        //         }
        //     }
        // })
        }
    }, [JSON.stringify(taskFilters),startDate, projectId])

    useEffect(() => {
        dispatch(getSpaceFloorList());
        dispatch(getFloorDetails(projectId));
    }, [projectId])

    useEffect(() => {
        if (taskList) {
           
            var temptaskList = [];
            taskList.map((eItem) => {
                if (eItem.startdate == null && eItem.duedate == null) {
                    temptaskList.push({ ...eItem, startdate: savedDate });
                } else {
                    temptaskList.push(eItem);
                }
            });
            
            let validDateData = temptaskList.sort((a, b) => {
                let event_dateA = "";
                if (a.startdate != null) {
                    event_dateA = moment(a.startdate).format("hh:mm A");
                } else if (a.duedate != null) {
                    event_dateA = moment(a.duedate).format("hh:mm A");
                } else {
                    event_dateA = moment(a.dateadded).format("hh:mm A");
                }
                let event_dateB = "";
                if (b.startdate != null) {
                    event_dateB = moment(b.startdate).format("hh:mm A");
                } else if (b.duedate != null) {
                    event_dateB = moment(b.duedate).format("hh:mm A");
                } else {
                    event_dateB = moment(b.dateadded).format("hh:mm A");
                }
                // return moment(event_dateA, 'hh:mm A').diff(moment(event_dateB, 'hh:mm A'));
                return event_dateA.localeCompare(event_dateB) ;

            })
            setEvents(validDateData);
            setTempTaskList(validDateData);
        }
      
    }, [JSON.stringify(taskList),isDayGridWeek, isDayGridMonth,isDayGridDay])
    
// useEffect(() => {
//     if(pinDelayfilter.includes("pinned") && pinDelayfilter.includes("overdue") && (taskFilters.length == 0 || taskFilters.some((key) => key.main_key == "date_range_filter"))){
//         setEvents(tempTaskList && tempTaskList.filter((task) => task.pinned == 1 || task.unread_delay_comment_count > 0))
//     }else if(pinDelayfilter.includes("pinned") && (taskFilters.length == 0 || taskFilters.some((key) => key.main_key == "date_range_filter"))){
//         setEvents(tempTaskList && tempTaskList.filter((task) => task.pinned == 1))
//     }else if(pinDelayfilter.includes("overdue") && (taskFilters.length == 0 || taskFilters.some((key) => key.main_key == "date_range_filter"))){
//         setEvents(tempTaskList && tempTaskList.filter((task) => task.unread_delay_comment_count > 0))
//     }else {
//         setEvents(tempTaskList)
//     }
  
// }, [pinDelayfilter])

    useEffect(() => {
        setTask_type(selectedTask && selectedTask.is_requested && selectedTask.is_requested == 1 ? 'request-task' : 'task');
        setCommonModalData(selectedTask);
    }, [JSON.stringify(selectedTask)])
    useEffect(() => {
        if (isDayGridMonth) {
            const currentDates = moment();

            // Get the previous month by subtracting 1 from the month
            {
                // ----------------------- add or subtract month --------------------
                // const previousMonth = currentDates.clone().add(1, 'months');
                // const todayDate = previousMonth

                // const firstDayOfMonth = moment( todayDate).startOf('month');
                // const lastDayOfMonth = moment( todayDate ).endOf('month'); 
            }
            const todayDate = new Date();
            const firstDayOfMonth = moment(selectStartDate ? selectStartDate : todayDate).startOf('month');
            const lastDayOfMonth = moment(selectStartDate ?selectStartDate : todayDate ).endOf('month');
            const monthDatesArray = [];

            let currentDate = firstDayOfMonth;
            while (currentDate.isSameOrBefore(lastDayOfMonth)) {
                monthDatesArray.push({ date: currentDate.format('YYYY-MM-DD') });
                currentDate.add(1, 'day');
            }
            setCalendarMonthData(monthDatesArray)
            const date = moment(monthDatesArray[0].date); // Thursday Feb 2015
            const dow = date.day();
            // setdayofWek( dow - 1)
            setdayofWek(dow == 0 ? dow + 6 : dow - 1)
            // if(daterangeFilterApplied == false){
            //     localStorage.setItem("fromDate", monthDatesArray[0].date)
            //     localStorage.setItem("toDate", monthDatesArray[monthDatesArray.length - 1].date)
            // }
            if(appliedDaterangeFilter != "all"){
            setstartDate(monthDatesArray[0].date);
            setEndDate(monthDatesArray[monthDatesArray.length - 1].date);
            }
        }

    }, [isDayGridMonth, selectStartDate])
    useEffect(() => {
        if($('.popoveraroowposition').hasClass('show'))
        {
          setDropdownToggle(true);
        } else {
          setDropdownToggle(false);
        }        
      }, [show])
    useEffect(() => {
        //   update attachments counts realtime
        if (events && events.length > 0) {   
            let index = events && events.findIndex((task) => task.id == tempTaskId)
            let temptaskList = events[index]
            temptaskList.attachments_count = attchamentCount
            temptaskList.comments_count = (commentsList.length + 1)
            setEvents(
                Object.values({ ...events, [index]: temptaskList })
            )
        }
    }, [attchamentCount])

    useEffect(() => {
        if (events && events.length > 0) {   
            let index = events && events.findIndex((task) => task.id == tempTaskId)
            let temptaskList = events[index]
            temptaskList.unread_comment_count = 0
            setCommentUnreadCount(0)
            setEvents(
                Object.values({ ...events, [index]: temptaskList })
            )
        }
      
    }, [showComments])
    
    useEffect(() => {
      jquery(".intro-office-list").on("mouseenter" ,()=>{
        setDropdownToggle(false);
        jquery("popoveraroowposition").removeClass("show");
      })
      jquery(".intro-office-list").on("focus" ,()=>{
        setDropdownToggle(false);
        jquery("popoveraroowposition").removeClass("show");
      })
      jquery(".popoveraroowposition").on("mouseleave" ,()=>{
        setDropdownToggle(false);
        jquery("popoveraroowposition").removeClass("show");
      })
    })
    
    useEffect(() => {
        setDashboardStartDate(startDate);
        setDashboardEndDate(endDate);
    }, [startDate, endDate])
    
    

    // useEffect(() => {
    //  if(isDayGridMonth || isDayGridDay){
    //     dispatch(dayGridWeek(true));
    //  }     
    // }, [])

    // useEffect(() => {
    //     if (dropdownToggle) {
    //         setTimeout(function() {
    //         jquery(".WeekDetailbox").addClass("CalendarOverlay")
    //         jquery(".daylist").addClass("CalendarOverlay")
    //         jquery(".MonthEventbox").addClass("CalendarOverlay")
    //     }, 500);
    //     }else {
    //         jquery(".WeekDetailbox").removeClass('CalendarOverlay')
    //         jquery(".daylist").removeClass("CalendarOverlay")
    //         jquery(".MonthEventbox").removeClass("CalendarOverlay")
    //     }
    // }, [dropdownToggle])
    
    
    const handleSelectedTask = (
        taskId = 0,
        show = "",
        isRequestedTask = null,
        convertRequestTaskId = 0
    ) => {
        setShowTaskModal(false);
        setTaskId(taskId);
        setConvertTaskId(convertRequestTaskId);
        localStorage.setItem("SelectedTask", taskId);
        localStorage.setItem(
            "TaskType",
            isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
        );
        if (isRequestedTask || taskId) {
            setShowTaskModal(show);
            dispatch(commanoffcanvasisOpenfromCalendar(true))
            if (show == true) {
                // jquery(
                //   "#commonLoader_full_calendar"
                // ).removeClass("d-none");
                // jquery("#full_calendar_loader").addClass(
                //   "d-none"
                // );
            }
        }
    };

    const getTaskComments = async (taskId) => {
        setTempTaskId(taskId)
        setCommentsLoading(true);
        setShowComments(true)
        try {
            const comments = await DocumentsServices.getComments(taskId, 'task', undefined, undefined, 1 );
            if (comments.data) {
                setCommentsList(comments.data.map((comment) => {
                    return {
                        id: comment.id,
                        content: comment.content,
                        contact_id: comment.contact_id,
                        staffid: comment.staffid,
                        full_name: comment.contact_full_name,
                        profile_url: comment.addedfrom_details.profile_url,
                        attachments: comment.attachments,
                        date: comment.time,
                        is_allowed_to_edit: comment.is_allowed_to_edit
                    }
                }));
            }
            if (commentUnreadCount > 0) {
                dispatch(setTaskUnreadCmtCntAll(Number(taskUnreadCmtCntAll) - Number(commentUnreadCount)))
                if (localStorage.getItem('selectedVirtualSpace') && JSON.parse(localStorage.getItem('selectedVirtualSpace'))) {
                    dispatch(setVirtualSpaceNotificationCount(projectId, commentUnreadCount, -1));
                }
                else {
                    dispatch(setSpaceNotificationCount(projectId, commentUnreadCount, -1));
                }
                setCommentUnreadCount(0);
                // let taskListNew = taskList;
                // let index = taskListNew.findIndex((d) => d.id == taskId);
                // if (index > -1){
                //   taskListNew[index].unread_comment_count = 0;
                // }
                // dispatch(updateUnreadChatCountTasklist(taskListNew));
            }
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setCommentsLoading(false);
        }
    }
    const handleAddEditComment = async (content, files, id) => {
        try {
            const response = await DocumentsServices.addDocumentComment('task', tempTaskId, content, 0, id, files);
            if (id > 0) {
                const commentIndex = commentsList.findIndex(w => w.id == id);
                setCommentsList(Object.values({ ...commentsList, [commentIndex]: { ...commentsList[commentIndex], content } }));
            }
            else {
                const staffid = localStorage.getItem("staff_id");
                const profile_url = localStorage.getItem("contact_image");
                const full_name = localStorage.getItem("full_name");
                let attachments = [];

                if (files) {
                    Array.from(files).map((file) => {
                        attachments.push({
                            file_url: URL.createObjectURL(file),
                            file_name: file.name
                        })
                    });
                }

                setCommentsList(commentsList.concat([
                    {
                        id: response.data.comment_id,
                        content: content,
                        contact_id: contact_id,
                        staffid: staffid,
                        full_name: full_name,
                        profile_url: profile_url,
                        attachments: attachments,
                        date: response.data.time,
                        dateadded:new Date()
                    }
                ]));
            }
              let index = events.findIndex((task)=>task.id == tempTaskId)
              let temptaskList = events[index]
             temptaskList.comments_count = (commentsList.length + 1)
             setEvents(
              Object.values({...events, [index] : temptaskList})
             )
        }
        catch (e) {
            console.error(e);
        }
    };
    const handleDeleteComment = (comment) => {
        try {
            const response = DocumentsServices.removeComment(taskId, 'task', comment.id);
            setCommentsList(commentsList.filter(x => x.id != comment.id));
            setCommentCount(commentsList.length - 1);
        }
        catch (e) {
            console.error(e);
        }
    }
    const [screenYPos,setScreenYPos] = useState({});
    const [popoverPosition,setPopoverPosition] = useState("");
    const handleOnMouseEnter = (e,id) => {
        // setActiveTaskID(id);
        setTimeout(() => {
            setCalendarCardClickEvent(false)
            let Ypos = e.pageY;
            let PopOverHeight = jquery(".calednar-dropdown-modal").height();
            setScreenYPos(Ypos);
            let intialPos = 0;
            if(intialPos === 0){
                setPopoverPosition("left");
            }
            intialPos = 1;
            if (screenYPos <= 300) {
                setPopoverPosition("bottom")
            } else if (screenYPos > 301 && screenYPos < 600) {
                if( PopOverHeight == "undefined" || PopOverHeight > 400){
                    setPopoverPosition("auto");
                }else{
                    setPopoverPosition("bottom");
                }
            } else if(screenYPos >= 601) {
                setPopoverPosition("top");
            }
            setShow(true);
        }, 2000);
      }
      const handleOnMouseLeave = () => {
        setCalendarCardClickEvent(true)
        setShow(false);
        setTaskcreateId(0);

        // dispatch(SetSelectedTask(""))
    }
    const getMeetingLink = (TaskId, calendar_id) => {
 
        outlookCalendar.getMeetingLink(TaskId, selectedProject, calendar_id).then((res) => {
          if (res.status == 1) {
            setMeetingUrl(res.data.meetingUrl);
            setProviders(res.data.providers);
            let data = res.data.providers.length > 0 ? res.data.providers : [];
            dispatch({
              type: GET_3RD_PARTY_CONTACTS,
              payload: { data },
            });
          } else {
            setMeetingUrl("");
            setProviders([]);
          }
        });
      if (isGoogleCalendarActive === 1) {
        if(!calendar_id){
          dispatch(googlecalendar_assignees(TaskId));
        }
        googleCalendar.getMeetingLink(TaskId, calendar_id).then((res) => {
          if (res.status == 1) {
         
            if(res.data && res.data.contactsGoogleCalendar) {
              // setProviders(res.data.contactsGoogleCalendar);
              let contacts = res.data.contactsGoogleCalendar;
              dispatch({
                type : GET_GOOGLE_CONTACTS,
                payload : contacts
              })
            }
            if (res.data.meetingUrl == null) {
              setMeetingUrl("");
              setProviders([]);
            } else {
              setMeetingUrl(res.data.meetingUrl);
              setProviders(res.data.providers);
            }
  
            // setProviders(res.data.providers);
            // window.dispatchEvent(new Event('update_eeting_url'));
          } else {
            setMeetingUrl("");
          }
        });
      }
    };
    const getspacedata = () => {
        if ((Object.keys(tempHoverTask).length > 0 && tempHoverTask.id && commanoffcanvasIsOpen == false)  && (showCalendarDropDown == false && dropdownToggle == true)) {
          setShowCalendarDropDown(true);
          let data= {
            link: "",
            newTaskFlag: 0,
            newRequestFlag: 0,
            task_id: 0,
          };
          dispatch({
            type: SET_NEW_MEETING,
            payload: { data },
          });
          setDayClick("");
          setShowTaskInfo(false);
          setMeetingUrl("");
          setShowRequestTaskInfo(false);
          dispatch(checkIsAssigned(tempHoverTask.id));
          setSelectCalIds(tempHoverTask.selected_cal_ids ? tempHoverTask.selected_cal_ids : []);
          localStorage.setItem("assign_contact", JSON.stringify([]));
          data = {
            status: 0,
            data: []
          }
          dispatch({
            type: SET_CONTACT_DATA,
            payload: { data },
          });
          setProviders([]);
          // setTempTaskId('');
          // setTimeout(() => {
          if (tempHoverTask.is_requested == 1) {
            // setShowTaskInfo(true);
            localStorage.setItem(
              "calendarDropdown",
              "CalendarRequestTaskDropdownClose"
            );
            // let width = calendarEventInfo.jsEvent.pageX;
            // let height = calendarEventInfo.jsEvent.pageY;
            // setHeightWidth({ width: width, height: height });
            dispatch(
              getSelectedTask(
                selectedProject,
                tempHoverTask.id,
                tempHoverTask.is_requested == 1
                  ? 1
                  : null,
              )
            );
          } else if (
            tempHoverTask.is_requested == 0
          ) {
            // setShowTaskInfo(true);
            localStorage.setItem(
              "calendarDropdown",
              "CalendarDropdownClose"
            );
            // let width = calendarEventInfo.jsEvent.pageX;
            // let height = calendarEventInfo.jsEvent.pageY;
            // setHeightWidth({ width: width, height: height });
           
            dispatch(
              getSelectedTask(
                selectedProject,
                tempHoverTask.id,
                tempHoverTask.is_requested == 1 ? 1 : null,
                1,
                0
              )
            );
    
            getMeetingLink(tempHoverTask.id, '');
          } else if (
            tempHoverTask.calendar_id != ""
          ) {
            if (isOutlookCalendarActive === true) {
              
              dispatch(get_refresh_request_data(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), [], selectedProject,  'outlook_calendar', 0));
              localStorage.setItem(
                "outlookCalendarDropdown",
                "OutlookCalendarDropdownClose"
              );
              if (
                jquery.isNumeric(tempHoverTask.id) ==
                true
              ) {
                // setShowTaskInfo(true);
                localStorage.setItem(
                  "calendarDropdown",
                  "CalendarDropdownClose"
                );
                // let width = calendarEventInfo.jsEvent.pageX;
                // let height = calendarEventInfo.jsEvent.pageY;
                // setHeightWidth({ width: width, height: height });
                dispatch(
                  getSelectedTask(
                    selectedProject,
                    tempHoverTask.id,
                    tempHoverTask.is_requested ==
                      1
                      ? 1
                      : null,
                    1
                  )
                );
    
                getMeetingLink(tempHoverTask.id, '');
              } else {
                // setShowTaskInfo(true);
                // setTempTaskId(tempHoverTask.event._def.publicId);
                setTempCalendarId(tempHoverTask.calendar_id);
                getMeetingLink(tempHoverTask.id, tempHoverTask.calendar_id);
                localStorage.setItem(
                  "outlookCalendarDropdown",
                  "OutlookCalendarDropdownClose"
                );
                // let width = calendarEventInfo.jsEvent.pageX;
                // let height = calendarEventInfo.jsEvent.pageY;
                // setHeightWidth({ width: width, height: height });
                dispatch(
                  getSelectedTaskCalendar(
                    tempHoverTask.calendar_id,
                    tempHoverTask.id,
                    isOutlookCalendarActive,
                    selectedProject
                  )
                );
              }
            } 
            if (isGoogleCalendarActive === 1 && googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2) {
              
            dispatch(get_refresh_request_data(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), [], selectedProject,  'google_calendar', 0));
              
              if (isNumeric(tempHoverTask.id) == true) {
                // setShowTaskInfo(true);
                localStorage.setItem(
                  "calendarDropdown",
                  "CalendarDropdownClose"
                );
                
                // let width = calendarEventInfo.jsEvent.pageX;
                // let height = calendarEventInfo.jsEvent.pageY;
                // setHeightWidth({ width: width, height: height });
                dispatch(
                  getSelectedTask(
                    selectedProject,
                    tempHoverTask.id,
                    tempHoverTask.is_requested ==
                      1
                      ? 1
                      : null,
                    1,
                    0,
                    "google_calendar",
                  )
                );
                getMeetingLink(tempHoverTask.id, '');
              } else {
                // setShowTaskInfo(true);
                // let width = calendarEventInfo.jsEvent.pageX;
                // let height = calendarEventInfo.jsEvent.pageY;
                // setHeightWidth({ width: width, height: height });
                localStorage.setItem(
                  "googleCalendarDropdown",
                  "GoogleCalendarDropdownClose"
                );
               
                dispatch(
                  getSelectedTaskCalendar(
                    tempHoverTask.calendar_id,
                    tempHoverTask.id,
                    isGoogleCalendarActive,
                    selectedProject
                  )
                );
                getMeetingLink(tempHoverTask.id, calendarEventInfo.calendar_id);
              }
              // window.dispatchEvent(new Event("join_meeting"));
              // editGeneral(calendarEventInfo.event._def);
            }
          }
        }
        
      }

      const getMentionlist = (id) => {
        dispatch(getMentionList(id, "task"));
      };
      
      const findIndexOfselectedTask = (date) => {
        let index = calendarWeekData.findIndex((week)=>week.date === date)
        
        switch (index) {
            case 0:
                setSelectedDateIndex(1);
                dispatch(setIndexForCalendarSelectedTask(1));
                break;
            case 6:
                setSelectedDateIndex(5);
                dispatch(setIndexForCalendarSelectedTask(5))                
                break;
            default:
                setSelectedDateIndex(index);
                dispatch(setIndexForCalendarSelectedTask(index));
                break;
        }
      }

    return (
        <>
            {tasklistLoader == true ?
            <div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian">
            <CommanLoader className="position-relative start-0" />
            </div>
          : <div className='comman-contnet-wrapper h_calc77 mt-10 border-0 comman-main-box h-100 ps-10px m-0 pt-10px padding-bottom-100px' onMouseLeave={() =>{setDropdownToggle(false)}}>
                {/* week view start */}
                {isDayGridWeek  ? <div className='CustomFullCalendar Weekview d-flex flex-column' >
                    {<div className={`modal-overlay`}></div>}
                    {/* {<div className={`modal-overlay ${dropdownToggle && commanoffcanvasIsOpen == true ? "" : "box-shadow-none"}`}></div>} */}
                    <div className='d-flex flex-grow-1' onMouseLeave={()=>{setDropdownToggle(false)}}>
                        {calendarWeekData && calendarWeekData.length > 0 && calendarWeekData.filter((week,index)=> commanoffcanvasIsOpen ? index => (selectedDateIndex - 1) && index <= (selectedDateIndex + 1) : index > -1 && index < 7 ).map((week, index) => {
                            if(events.filter((event)=>{let event_date = "";
                            if (event.startdate != null) {
                                event_date = moment(event.startdate).format("YYYY-MM-DD HH:mm:ss");
                            } else if (event.duedate != null) {
                                event_date = moment(event.duedate).format("YYYY-MM-DD HH:mm:ss");
                            } else {
                                event_date = moment(event.dateadded).format("YYYY-MM-DD HH:mm:ss");
                            }
                            return(week.date == moment(event_date).format("YYYY-MM-DD"))
                                }).length == 0 || events.length == 0){
                            return(<>
                            { commanoffcanvasIsOpen ? index >= (selectedDateIndex - 1) && index <= (selectedDateIndex + 1) ? 
                             <CommanPlaceholder mainWidth={`calednarPlacehoder`} imgType="request" placeholderText={_l("l_no_data")} />  :
                             <></>
                             :<CommanPlaceholder mainWidth={`calednarPlacehoder ${displayThreeDays ? "" : "w7days"}`} imgType="request" placeholderText={_l("l_no_data")} /> 
                            }
                            </>)
                            }else{
                            return (
                                <>
                                <div className={`Weekbox pb-5 ${index} ${displayThreeDays ? "width33per" : ""} ${commanoffcanvasIsOpen ? index >= (selectedDateIndex - 1) && index <= (selectedDateIndex + 1) ? "width33per" : "d-none" :""} `} key={index} 
                                onClick={()=>{
                                    if(taskcreateId == 0 && commanoffcanvasIsOpen == false) {
                                        // setShowCreateTaskOffcanvas(true);
                                        // setcreatetaskDate(week.date)
                                    }
                                    }}>
                                    {events && events.length > 0 && events.filter((event, index)=>event.name.toLocaleLowerCase().includes( globalSearchString.toLocaleLowerCase()) ).map((event, index) => {
                                        let event_date = "";
                                        if (event.startdate != null) {
                                            event_date = moment(event.startdate).format("YYYY-MM-DD HH:mm:ss");
                                        } else if (event.duedate != null) {
                                            event_date = moment(event.duedate).format("YYYY-MM-DD HH:mm:ss");
                                        } else {
                                            event_date = moment(event.dateadded).format("YYYY-MM-DD HH:mm:ss");
                                        }
                                        let task_assignee = [];
                                        let taskData = event
                                        if (taskData.assignee_list_DEV && taskData.assignee_list_DEV.length > 0) {
                                            taskData.assignee_list_DEV.forEach((assignee) => {
                                                // if (taskData.schedule_assignees.length > 0) {
                                                //     task_assignee = [];
                                                //     task_assignee = taskData.schedule_assignees ? taskData.schedule_assignees : []
                                                // }

                                                if (assignee && assignee.categories && ["myr_staff_task", "my_staff" ,"my_operator","my_contact", "task_topic","my_task"].includes(assignee.main_key)) {
                                                    assignee && assignee.categories.map((taskAssign) => {
                                                        defaultTopics &&
                                                            defaultTopics.forEach((topic) => {
                                                                if (["myr_staff_task", "my_staff" ,"my_operator","my_contact", "task_topic","my_task"].includes(topic.main_key)) {
                                                                    if (topic.main_key == assignee.main_key) {
                                                                        topic.categories.forEach((provider) => {
                                                                            if (taskAssign.filter_key == provider.filter_key) {
                                                                                // if (taskData.schedule_assignees.length == 0) {
                                                                                    task_assignee.push({
                                                                                        name: provider.title,
                                                                                        profile_image: provider.image,
                                                                                        id: provider.filter_key,
                                                                                    });
                                                                                // }
                                                                            }
                                                                        });
                                                                    }
                                                                }
                                                            });
                                                    });
                                                }
                                            })
                                        }
                                        if (week.date == moment(event_date).format("YYYY-MM-DD")) {
                                            return (
                                                <>
                                                {[popoverPosition].map((placement, index) => ( 
                                                    <div className={`WeekDetailbox calendar-box-bg radius_3 ${event.id == activeTaskID || (selectedTask && selectedTask.id == event.id) ? "z-index-7 opacity-100" : ""} ${dropdownToggle ? "CalendarOverlay" : ""}`} key={index}
                                                        onMouseEnter={(e) => {
                                                            handleOnMouseEnter(e, event.id);
                                                            setTaskcreateId(event.id);
                                                            if (commanoffcanvasIsOpen == false) {
                                                                setTempHoverTask(event)
                                                                setEventInfo(e);
                                                                setShowCalendarDropDown(false);
                                                                if ($('.popoveraroowposition').hasClass('show')) {
                                                                    $('.popoveraroowposition').removeClass('show');
                                                                    setDropdownToggle(false);
                                                                    dispatch(SetSelectedTask(""))
                                                                }
                                                            }
                                                        }}
                                                        onMouseLeave={handleOnMouseLeave}
                                                        onClick={() => {
                                                            jquery("body").addClass("pb-0 right-penel-no-padding modal-open with-right-penel task-modal-offcanvas-open")
                                                            dispatch(commanoffcanvasisOpenfromCalendar(true))
                                                            setActiveTaskID(event.id);
                                                            setTaskcreateId(event.id);
                                                            findIndexOfselectedTask(week.date)
                                                            // if (commanoffcanvasIsOpen) {
                                                            // } else {
                                                            //     setShowTaskModal(true)
                                                            //     let width = calendarEventInfo.pageX;
                                                            //     let height = calendarEventInfo.pageY;
                                                            //     setHeightWidth({ width: width, height: height });
                                                            // }
                                                            if (commanoffcanvasIsOpen) {
                                                                // setselectedEventDate(week.date)
                                                                setShowTaskModal(true);
                                                                dispatch(
                                                                    getSelectedTask(
                                                                        event.rel_id,
                                                                        event.id,
                                                                        event.is_requested == 1
                                                                            ? 1
                                                                            : null,
                                                                    )
                                                                );
                                                            }
                                                        }}
                                                    >
                                                    <a href="#/" className={`WeekDetailHeader c-font f-12 d-block ${event.is_requested == 1 ? "bg-request" : event.status == 5 ? "bg-done" : event.status == 4 ? "bg-inprogress" : "bg-notstarted"}`}
                                                    onClick={()=>{
                                                        // setselectedEventDate(week.date)
                                                        setShowTaskModal(true);
                                                        setActiveTaskID(event.id);
                                                        dispatch(commanoffcanvasisOpenfromCalendar(true));
                                                        dispatch(getSelectedTask(event.rel_id,event.id,event.is_requested == 1 ? 1: null))
                                                        findIndexOfselectedTask(week.date);
                                                    }}>
                                                            <div className='d-flex align-items-center justify-content-center fw-semibold text-truncate'
                                                                data-tip={event.name}
                                                                data-effect="solid"
                                                                data-delay-show='1000'
                                                                data-class="tooltip-main"
                                                            >
                                                                <div className='me-2'>{moment(event_date).format("DD/MM hh:mm a")}</div>
                                                                <div className='text-truncate'>{event.name}</div>
                                                            </div>
                                                    </a>
                                                    <div className='WeekDetail p-15 radius_3'>
                                                        <a href="#/" className='CalendarProfile d-block'
                                                            onClick={(e) => {
                                                                if (!commanoffcanvasIsOpen && !$('.fade').hasClass('show') && !$('.dropdown-menu').hasClass('show')) {
                                                                    // setselectedEventDate(week.date)
                                                                    setShowTaskModal(true);
                                                                    setActiveTaskID(event.id);
                                                                    dispatch(commanoffcanvasisOpenfromCalendar(true));
                                                                    dispatch(
                                                                        getSelectedTask(
                                                                            event.rel_id,
                                                                            event.id,
                                                                            event.is_requested == 1
                                                                                ? 1
                                                                                : null,
                                                                        )
                                                                    );
                                                                    jquery("body").addClass("pb-0 right-penel-no-padding modal-open with-right-penel task-modal-offcanvas-open")
                                                                }
                                                            }}>
                                                            <OverlayTrigger
                                                                // trigger={['hover','focus']}
                                                                // "click",
                                                                delay={{ show: 1000 }}
                                                                key={index}
                                                                placement={placement}
                                                                onEnter={() => getspacedata()}
                                                                onToggle={(e) => { setDropdownToggle(e) }}
                                                                // show={SelectedTaskId == eventInfo.event.id }
                                                                show={(dropdownToggle == true) && tempHoverTask && tempHoverTask.id ? tempHoverTask.id == event.id : false}
                                                                // show={true}
                                                                onMouseLeave={()=>{setDropdownToggle(false)}}
                                                                onExited={()=>{setDropdownToggle(false)}}
                                                                overlay={
                                                                    <Popover id={`popover-positioned-${placement}`} className={` popoveraroowposition border-0 ${commanoffcanvasIsOpen == false ? selectedTask == undefined && selectedTask == "d-none" ? "w-400 " : "w-400 " : "d-none"}`}
                                                                        onMouseEnter={() => handleOnMouseEnter(event.id)}
                                                                        onMouseLeave={handleOnMouseLeave}>
                                                                        {commanoffcanvasIsOpen == false ? <>
                                                                            {selectedTask != undefined && selectedTask != "" ?
                                                                                <Popover.Body className="p-0"
                                                                                onMouseLeave={()=>{setDropdownToggle(false)}}>
                                                                                    <CalendarDropdown
                                                                                        projectId={selectedProject}
                                                                                        taskId={taskId}
                                                                                        providers={providers}
                                                                                        dayClick={dayClick}
                                                                                        meetingUrl={meetingUrl}
                                                                                        docType={"task"}
                                                                                        pageName={pageName}
                                                                                        data={{ contacts: shareContacts }}
                                                                                        isOutlookCalActive={isOutlookCalendarActive}
                                                                                        handler={handleSelectedTask}
                                                                                        selectCalIds={selectCalIds}
                                                                                        isFromPopover={true}
                                                                                        setShowTaskInfo={setShowTaskInfo}
                                                                                        taskNameOrStatusUpdate={(
                                                                                            task_id,
                                                                                            task_name_status,
                                                                                            flag = ""
                                                                                        ) => {
                                                                                            let eventUpdate = events.map((event) => {
                                                                                                if (task_id == event.id) {
                                                                                                    if (flag != "") {
                                                                                                        if (task_name_status == 1) {
                                                                                                            event["color"] = "#b3b3b3";
                                                                                                        } else if (task_name_status == 4) {
                                                                                                            event["color"] = "#03A9F4";
                                                                                                        } else if (task_name_status == 5) {
                                                                                                            event["color"] = "#00ff6a";
                                                                                                        }
                                                                                                    } else {
                                                                                                        event["title"] = task_name_status;
                                                                                                    }
                                                                                                    return event;
                                                                                                }
                                                                                                return event;
                                                                                            });
                                                                                            setEvents(eventUpdate);
                                                                                        }}
                                                                                        setActiveTaskID={setActiveTaskID}
                                                                                        isFromCalendar={true}
                                                                                        setSelectedTaskId={setEventInfo}
                                                                                        startdate={startDate}
                                                                                        endDate={endDate}
                                                                                        setDropdownToggle={setDropdownToggle}
                                                                                    />
                                                                                </Popover.Body>
                                                                                : <></>}
                                                                        </> : <></>}
                                                                    </Popover>
                                                                }
                                                            >
                                                                <div className='position-relative   assignee-list-wrapper'
                                                                 onMouseEnter={(e) => { handleOnMouseEnter(e, event.id) }} 
                                                                 onMouseLeave={handleOnMouseLeave}
                                                                 >
                                                                    <a href="#/" className='multi_inner_wrapper with-min-width d-flex with_minus justify-content-start align-items-center'>
                                                                        <div className='comman-image-box h50w50 rounded-circle one assignee-list-wrapper card-view-less-padding'>
                                                                            <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${task_assignee.length > 0 && task_assignee[0].profile_image ? task_assignee[0].profile_image : event.schedule_id > 0 ? User_2 : taskData.creator_profile_image ? taskData.creator_profile_image : ""})` }}></div>
                                                                        </div>
                                                                    </a>
                                                                    {task_assignee && task_assignee.length > 1 ?
                                                                        <div className={`multi_image_wrapper dropdown-center dropdown`}>
                                                                            <div className="multi_inner_wrapper d-flex position-relative">
                                                                               
                                                                                {task_assignee && task_assignee.length > 1 ?
                                                                                    <>
                                                                                        <a href="#/" className={`comman-image-box with_ml_minus_10px h25w25 with_overlay d-flex rounded-circle border-2 `}>
                                                                                            <UncontrolledDropdown>
                                                                                                <DropdownToggle tag="div" className="dropdown dropdown-center team-list-dropdown h25w25" >
                                                                                                    <div
                                                                                                        className="d-flex rounded-circle h-100"
                                                                                                        type="button"
                                                                                                        data-bs-toggle="dropdown"
                                                                                                        data-bs-auto-close="outside"
                                                                                                        aria-expanded="false"
                                                                                                    >
                                                                                                        <div className="unread_count m-auto">
                                                                                                            +{task_assignee ? task_assignee.length - 1 : " "}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <UncontrolledGeneralListing list={task_assignee ? task_assignee.map((data, index) => {
                                                                                                        return {
                                                                                                            name: data.name,
                                                                                                            image: data.profile_image ? data.profile_image : '',
                                                                                                            id: index,
                                                                                                        };
                                                                                                    }) : <></>} />
                                                                                                </DropdownToggle>
                                                                                            </UncontrolledDropdown>
                                                                                        </a>
                                                                                    </>
                                                                                    : <></>
                                                                                }

                                                                            </div>
                                                                            <ReactTooltip />
                                                                        </div> : <></>}
                                                                </div>
                                                            </OverlayTrigger>
                                                            <div className='color-white-60 c-font f-14 pt-2 text-center text-truncate'>{task_assignee.length > 0 && task_assignee[0].name ? task_assignee[0].name : taskData.creator_name ? taskData.creator_name : ""}</div>
                                                        </a>
                                                        <div className='progress-slider-with-action-icon flex-grow-1 pt-1'>
                                                            <div className='slider-progress flex-grow-1 pb-10px c-font f-14'>
                                                                <UncontrolledDropdown className='progress-slider w-100'>
                                                                    <DropdownToggle data-toggle="dropdown" tag="a" className="progress-slider w-100" onClick={() => {
                                                                        setOpenCheckList(true)
                                                                        dispatch(getTaskChecklist(event.id, taskData.rel_id));
                                                                    }}
                                                                        type="button"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                        data-bs-auto-close="outside">
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='progress'>
                                                                                <div className='progress-bar' style={{ width: `${event.checklist_progress}%` }}></div>
                                                                            </div>
                                                                            <div className='progress-text title-fonts color-white-80 text-center ps-2 c-font f-14'>{event.checklist_progress}%</div>
                                                                        </div>
                                                                    </DropdownToggle>
                                                                    <DropdownItem toggle={false} className="p-0">
                                                                        <CheckedDropdown
                                                                            unContolList={true}
                                                                            setOpenCheckList={setOpenCheckList}
                                                                            project_id={taskData.rel_id}
                                                                            openCheckList={openCheckList}
                                                                            task_id={event.id}
                                                                            taskDetails={taskData}
                                                                            task_status={taskData.status}
                                                                            isSystemTask={
                                                                                taskData.task && parseInt(taskData.is_allowed_to_edit) != 1
                                                                                    ? true
                                                                                    : null
                                                                            }
                                                                            isChecklistPermission={
                                                                                taskData.task &&
                                                                                    taskData.is_requested == 1 &&
                                                                                    taskData.request_task_id > 0
                                                                                    ? 1
                                                                                    : 0
                                                                            }
                                                                            pageName={"calendar"}
                                                                        />
                                                                    </DropdownItem>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                            <div className='d-flex align-items-center justify-content-center comman_action_icon'>
                                                                <div className='dropup  d-flex align-items-center action_icon with_bg h32wauto me-10px'>
                                                                    <UncontrolledDropdown className='progress-slider w-100'>
                                                                        <DropdownToggle tag={'a'} className='before-dnone before_dnone comman_action_icon d-flex radius_3 w-100 calender-action-icon'
                                                                            type="button"
                                                                            id="writeComMentDropdown"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                            data-bs-auto-close="outside"
                                                                            data-tip={_l('l_comments')}
                                                                            data-effect="solid"
                                                                            data-delay-show='1000'
                                                                            data-class="tooltip-main"
                                                                            onClick={() => {
                                                                                getTaskComments(event.id);
                                                                                getMentionlist(event.id);
                                                                                setTaskcreateId(event.id);
                                                                            }}>
                                                                            <div className='d-flex align-items-center w-100 on-hover-active-toggle-img text-on-hover-active-green'>
                                                                                <div className="position-relative">
                                                                                        <ChatCircle size={16} className="c-icons" weight="light" />
                                                                                        <span
                                                                                            className={`rounded-pill badge-custom p-0 ${parseInt(event.unread_comment_count) > 0
                                                                                                    ? ""
                                                                                                    : "d-none"
                                                                                                }`}
                                                                                            data-unread-cmt-id={event.id}
                                                                                        >
                                                                                            {event.unread_comment_count}
                                                                                        </span>
                                                                                </div>
                                                                                <span className="d-flex justify-content-center flex-grow-1">{event.comments_count}</span>
                                                                            </div>
                                                                        </DropdownToggle>
                                                                        <DropdownItem toggle={false} className="p-0">
                                                                            <CommentMinified
                                                                                comments={commentsList}
                                                                                disabled={
                                                                                    selectedTask &&
                                                                                    parseInt(selectedTask.request_task_id) > 0 &&
                                                                                    selectedTask.is_requested == 1}
                                                                                handleAddComment={handleAddEditComment}
                                                                                deleteComment={handleDeleteComment}
                                                                                id={event.id}
                                                                                loading={commentsLoading}
                                                                                mentionList={mentionList.map((name) => {
                                                                                    let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                                                                                    return {
                                                                                      display: name.name,
                                                                                      id: id,
                                                                                      image: name.profile_image || "",
                                                                                      isteam: name.is_team ? 1 : 0,
                                                                                    };
                                                                                  })}
                                                                                handleImagePreview={""}
                                                                                pageName={pageName}
                                                                                task={selectedTask}
                                                                                unContolList={true}
                                                                                showComments={showComments}
                                                                            />
                                                                        </DropdownItem>
                                                                    </UncontrolledDropdown>
                                                                </div>
                                                                <div className='dropup  d-flex align-items-center action_icon with_bg h32wauto'>
                                                                    <div className='w-100'>
                                                                        <UncontrolledDropdown className='comman_action_icon w-100 d-flex close-toast border-0'>
                                                                            <DropdownToggle tag={'a'} className='before-dnone before_dnone comman_action_icon d-flex radius_3 w-100 calender-action-icon'
                                                                                type="button"
                                                                                id="imageLstAndUploadDropdown"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                data-bs-auto-close="outside"
                                                                                data-tip={_l('l_attachments')}
                                                                                data-effect="solid"
                                                                                data-delay-show='1000'
                                                                                data-class="tooltip-main"
                                                                                onClick={(e) => {
                                                                                    setOpenAttachmentsDropdown(true);
                                                                                    setTempTaskId(event.id);
                                                                                    setTaskcreateId(event.id);
                                                                                }}>
                                                                                <div className='d-flex align-items-center w-100 on-hover-active-toggle-img text-on-hover-active-green'>
                                                                                    <div className="position-relative">
                                                                                        <Image size={16} className="c-icons" weight="light" />
                                                                                    </div>
                                                                                    <span className="d-flex justify-content-center flex-grow-1">{event.attachments_count}</span>
                                                                                </div>
                                                                            </DropdownToggle>
                                                                            <DropdownItem toggle={false} className="p-0">
                                                                                <ImageUploadDropdown
                                                                                    unContolList={true}
                                                                                    setOpenAttachmentsDropdown={
                                                                                        setOpenAttachmentsDropdown
                                                                                    }
                                                                                    openAttachmentsDropdown={openAttachmentsDropdown}
                                                                                    task_id={event.id}
                                                                                    project_id={projectId}
                                                                                    updateImageCount={setAttchamentCount}
                                                                                    Request_task_converted={
                                                                                        selectedTask &&
                                                                                            parseInt(selectedTask.request_task_id) > 0 &&
                                                                                            selectedTask.is_requested == 1
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    taskDetails={event}
                                                                                    pageName={pageName}
                                                                                />
                                                                            </DropdownItem>
                                                                        </UncontrolledDropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> ))}</>)
                                        }
                                    })}
                                </div> 
                                </>)}
                        })}
                    </div>
                </div> : <></>}
                {/* week end */}

                {/* month view start */}
                {isDayGridMonth ? <div className='CustomFullCalendar Monthview '>
                <div className='modal-overlay'></div>
                <div className='d-flex flex-wrap MonthHeader light-theme-grey-light-bg-imp' onMouseLeave={()=>setDropdownToggle(false)}>

                {daysOfWeek.map((weekDay, index) => {
                       return(<>
                        <div className='MonthHeaderbox title-fonts c-font f-14 fw-semibold'>{_l(`l_${weekDay}`)}</div>
                       </>)
                })}
                </div>
                    <div className='d-flex flex-wrap border-start border-end'>
                            {calendarMonthData.filter((date, index) => index < dayofWek).map((month, key) => {
                                for (let i = 0; i < dayofWek; i++) {
                                    return (<>
                                        <div className='Monthbox disabled' >
                                            <div className='text-start title-fonts c-font f-14 fw-semibold p-15'></div>
                                        </div>
                                    </>)
                                }
                            })}
                        {calendarMonthData && calendarMonthData.length > 0 && calendarMonthData.map((month, index) => {
                            return (<>
                                <div className='Monthbox' key={index}
                                 style={{backgroundColor: `${(formateDate(month.date) < formateDate(localStorage.getItem("fromDate")) || formateDate(month.date) > formateDate(localStorage.getItem("toDate")))? "var(--color-white-03)": "" }`}}>
                                    <div className='text-start title-fonts c-font f-14 fw-semibold p-15'
                                    onClick={()=>{
                                        if(taskcreateId == 0 &&commanoffcanvasIsOpen == false) {
                                            // setShowCreateTaskOffcanvas(true);
                                            // setcreatetaskDate(month.date)
                                        }
                                    }}>{moment(month.date).format("D")}</div>
                                    {events && events.length > 0 && events.filter((event)=>event.name.toLocaleLowerCase().includes( globalSearchString.toLocaleLowerCase())).map((event, index) => {
                                        let event_date = "";
                                        if (event.startdate != null) {
                                            event_date = moment(event.startdate).format("YYYY-MM-DD HH:mm:ss");
                                        } else if (event.duedate != null) {
                                            event_date = moment(event.duedate).format("YYYY-MM-DD HH:mm:ss");
                                        } 
                                        let task_assignee = [];
                                        let taskData = event
                                        if (taskData.assignee_list_DEV && taskData.assignee_list_DEV.length > 0) {
                                            taskData.assignee_list_DEV.forEach((assignee) => {
                                                // if (taskData.schedule_assignees.length > 0) {
                                                //     task_assignee = [];
                                                //     task_assignee = taskData.schedule_assignees ? taskData.schedule_assignees : []
                                                // }
                                                if (assignee && assignee.categories && ["myr_staff_task", "my_staff" ,"my_operator","my_contact", "task_topic","my_task"].includes(assignee.main_key)) {
                                                    assignee && assignee.categories.map((taskAssign) => {
                                                        defaultTopics &&
                                                            defaultTopics.forEach((topic) => {
                                                                if (["myr_staff_task", "my_people", "task_topic", "my_operator"].includes(topic.main_key)) {
                                                                    if (topic.main_key == assignee.main_key) {
                                                                        topic.categories.forEach((provider) => {
                                                                            if (taskAssign.filter_key == provider.filter_key) {
                                                                                // if (taskData.schedule_assignees.length == 0) {
                                                                                    task_assignee.push({
                                                                                        name: provider.title,
                                                                                        profile_image: provider.image,
                                                                                        id: provider.filter_key,
                                                                                    });
                                                                                // }
                                                                            }
                                                                        });
                                                                    }
                                                                }
                                                            });
                                                    });
                                                }
                                            })
                                        }
                                        if ( month.date == moment(event_date).format("YYYY-MM-DD")) {
                                            return (<>
                                             {[popoverPosition].map((placement, index) => (  
                                                <div className={`MonthEventbox p-5px d-flex align-items-center justify-content-between radius_3 m-1 cursor-pointer ${event.id == activeTaskID ? "z-index-7" : ""} ${event.is_requested == 1 ? "bg-request" : event.status == 5 ? "bg-done" : event.status == 4 ? "bg-inprogress" : "bg-notstarted"} ${dropdownToggle ? "CalendarOverlay" : ""}`}
                                                    onClick={() => {
                                                        if (!commanoffcanvasIsOpen) {
                                                            dispatch(commanoffcanvasisOpenfromCalendar(true))
                                                            setActiveTaskID(event.id)
                                                            setTaskcreateId(event.id);
                                                            dispatch(
                                                                getSelectedTask(
                                                                    event.rel_id,
                                                                    event.id,
                                                                    event.is_requested == 1
                                                                        ? 1
                                                                        : null,
                                                                )
                                                            );                                                       
                                                            setselectedEventDate(month.date)
                                                            setShowTaskModal(true);
                                                            jquery("body").addClass("pb-0 right-penel-no-padding modal-open with-right-penel task-modal-offcanvas-open")
                                                        }else{
                                                            setActiveTaskID(event.id)
                                                            setTaskcreateId(event.id);
                                                            dispatch(
                                                                getSelectedTask(
                                                                    event.rel_id,
                                                                    event.id,
                                                                    event.is_requested == 1
                                                                        ? 1
                                                                        : null,
                                                                )
                                                            );                                                       
                                                            setselectedEventDate(month.date)
                                                        }
                                                    }}
                                                >
                                                    <a href="#/" className='c-font f-12  f-black d-flex align-items-center w100minus50 text-truncate' 
                                                     data-tip={event.name}
                                                     data-effect="solid"
                                                     data-delay-show='1000'
                                                     data-class="tooltip-main tooltip-tetx-break"
                                                     data-place="bottom"
                                                     onMouseEnter={(e) => {                                                       
                                                        setTaskcreateId(event.id);
                                                        if (commanoffcanvasIsOpen == false) {
                                                            setTempHoverTask(event)
                                                            setEventInfo(e);
                                                            setShowCalendarDropDown(false);
                                                            if ($('.popoveraroowposition').hasClass('show')) {
                                                                $('.popoveraroowposition').removeClass('show');
                                                                setDropdownToggle(false);
                                                                dispatch(SetSelectedTask(""))
                                                            }
                                                        }
                                                    }}
                                                    onMouseLeave={handleOnMouseLeave}
                                                    >
                                                        <ReactTooltip />
                                                        <OverlayTrigger
                                                                // trigger={['hover','focus']}
                                                                // "click",
                                                                delay={{ show: 2000 }}
                                                                key={index}
                                                                placement={placement}
                                                                onEnter={() => getspacedata()}
                                                                onToggle={(e) => { setDropdownToggle(e) }}
                                                                // show={SelectedTaskId == eventInfo.event.id }
                                                                show={(dropdownToggle == true) && tempHoverTask && tempHoverTask.id ? tempHoverTask.id == event.id : false}
                                                                // show={true}
                                                                overlay={
                                                                    <Popover id={`popover-positioned-${placement}`} className={` popoveraroowposition border-0 ${commanoffcanvasIsOpen == false ? selectedTask == undefined && selectedTask == "d-none" ? "w-400 " : "w-400 " : "d-none"}`}
                                                                        onMouseEnter={() => {
                                                                            setCalendarCardClickEvent(false)
                                                                            handleOnMouseEnter(event.id)
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setCalendarCardClickEvent(true)
                                                                            handleOnMouseLeave()
                                                                        }}
                                                                        onClick={(e) => e.stopPropagation()}
                                                                        >
                                                                        {commanoffcanvasIsOpen == false ? <>
                                                                            {selectedTask != undefined && selectedTask != "" ?
                                                                                <Popover.Body className="p-0">
                                                                                    <CalendarDropdown
                                                                                        projectId={selectedProject}
                                                                                        taskId={taskId}
                                                                                        providers={providers}
                                                                                        dayClick={dayClick}
                                                                                        meetingUrl={meetingUrl}
                                                                                        docType={"task"}
                                                                                        pageName={pageName}
                                                                                        data={{ contacts: shareContacts }}
                                                                                        isOutlookCalActive={isOutlookCalendarActive}
                                                                                        handler={handleSelectedTask}
                                                                                        selectCalIds={selectCalIds}
                                                                                        setShowTaskInfo={setShowTaskInfo}
                                                                                        taskNameOrStatusUpdate={(
                                                                                            task_id,
                                                                                            task_name_status,
                                                                                            flag = ""
                                                                                        ) => {
                                                                                            let eventUpdate = events.map((event) => {
                                                                                                if (task_id == event.id) {
                                                                                                    if (flag != "") {
                                                                                                        if (task_name_status == 1) {
                                                                                                            event["color"] = "#b3b3b3";
                                                                                                        } else if (task_name_status == 4) {
                                                                                                            event["color"] = "#03A9F4";
                                                                                                        } else if (task_name_status == 5) {
                                                                                                            event["color"] = "#00ff6a";
                                                                                                        }
                                                                                                    } else {
                                                                                                        event["title"] = task_name_status;
                                                                                                    }
                                                                                                    return event;
                                                                                                }
                                                                                                return event;
                                                                                            });
                                                                                            setEvents(eventUpdate);
                                                                                        }}
                                                                                        setActiveTaskID={setActiveTaskID}
                                                                                        isFromCalendar={true}
                                                                                        setSelectedTaskId={setEventInfo}
                                                                                        startdate={startDate}
                                                                                        endDate={endDate}
                                                                                        setDropdownToggle={setDropdownToggle}
                                                                                    />
                                                                                </Popover.Body>
                                                                                : <></>}
                                                                        </> : <></>}
                                                                    </Popover>
                                                                }
                                                            >
                                                             <div className='d-flex text-truncate' onMouseEnter={(e) => { handleOnMouseEnter(e, event.id) }} onMouseLeave={handleOnMouseLeave}>
                                                                 <div className='me-1'>{moment(event_date).format("hh:mm a")}</div>
                                                                 <div className='text-truncate'>{event.name}</div>
                                                             </div>
                                                        </OverlayTrigger>
                                                    </a>
                                                    <div className='CalendarProfile '>
                                                        <div className='multi_image_wrapper dropdown-center dropdown'>
                                                            <div className='multi_inner_wrapper d-flex position-relative '>
                                                                <a href="#/" className='comman-image-box h20w20 rounded-circle multi-wrapper-border me-1 border-2'>
                                                                    <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{ backgroundImage: `url(${task_assignee.length > 0 && task_assignee[0].profile_image ? task_assignee[0].profile_image : event.schedule_id > 0 ? User_2 : taskData.creator_profile_image ? taskData.creator_profile_image : ""})` }}></div>
                                                                </a>
                                                                    {task_assignee && task_assignee.length > 1 ?
                                                                        <>
                                                                            <a href="#/" className={`comman-image-box h20w20 with_overlay d-flex rounded-circle border-2 `}>
                                                                                <UncontrolledDropdown className='w-100'>
                                                                                    <DropdownToggle tag="a" className="dropdown dropdown-center team-list-dropdown w-100" >
                                                                                        <div
                                                                                            className="d-flex rounded-circle"
                                                                                            type="button"
                                                                                            data-bs-toggle="dropdown"
                                                                                            data-bs-auto-close="outside"
                                                                                            aria-expanded="false"
                                                                                        >
                                                                                            <div className="c-font f-12 m-auto unread_count">
                                                                                                +{task_assignee ? task_assignee.length - 1 : " "}
                                                                                            </div>
                                                                                        </div>
                                                                                        <UncontrolledGeneralListing list={task_assignee ? task_assignee.map((data, index) => {
                                                                                            return {
                                                                                                name: data.name,
                                                                                                image: data.profile_image ? data.profile_image : '',
                                                                                                id: index,
                                                                                            };
                                                                                        }) : <></>} />
                                                                                    </DropdownToggle>
                                                                                </UncontrolledDropdown>
                                                                            </a>
                                                                        </>
                                                                        : <></>
                                                                    }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>))}
                                            </>)
                                        }
                                    })}
                                </div>
                            </>)
                        })}
                    </div>
                </div> : <></>}
                {/* month view end  */}

                {/* day view start */}
                {isDayGridDay ? 
                <>{calendarWeekData && calendarWeekData.length > 0 && calendarWeekData.filter((data, index) => index > 2 && index < 4).map((day, index) => {
                    if(events.filter((event)=>{let event_date = "";
                    if (event.startdate != null) {
                        event_date = moment(event.startdate).format("YYYY-MM-DD HH:mm:ss");
                    } else if (event.duedate != null) {
                        event_date = moment(event.duedate).format("YYYY-MM-DD HH:mm:ss");
                    } else {
                        event_date = moment(event.dateadded).format("YYYY-MM-DD HH:mm:ss");
                    }
                    return(day.date == moment(event_date).format("YYYY-MM-DD"))
                        }).length == 0 && day.date == formateDate(localStorage.getItem("selectedDate"))){
                    return(<>
                     <CommanPlaceholder mainWidth={` ${displayThreeDays ? "" : "w7days"}`} imgType="request" placeholderText={_l("l_no_data")} />
                    
                    </>)
                    }else{
                        return (<>
                <div className='CustomFullCalendar Dayview' onMouseLeave={()=>setDropdownToggle(false)} 
                onClick={()=>{
                    if(taskcreateId == 0 && commanoffcanvasIsOpen == false) {
                        // setShowCreateTaskOffcanvas(true);
                        // setcreatetaskDate(day.date)
                    }
                }}>
                    <div className='modal-overlay'></div>
                            {events && events.length > 0 && events.filter((event)=>event.name.toLocaleLowerCase().includes( globalSearchString.toLocaleLowerCase())).map((event, index) => {
                                let event_date = "";
                                if (event.startdate != null) {
                                    event_date = moment(event.startdate).format("YYYY-MM-DD HH:mm:ss");
                                } else if (event.duedate != null) {
                                    event_date = moment(event.duedate).format("YYYY-MM-DD HH:mm:ss");
                                } else {
                                    event_date = moment(event.dateadded).format("YYYY-MM-DD HH:mm:ss");
                                }
                                let task_assignee = [];
                                let taskData = event
                                if (taskData.assignee_list_DEV && taskData.assignee_list_DEV.length > 0) {
                                    taskData.assignee_list_DEV.forEach((assignee) => {
                                        // if (taskData.schedule_assignees.length > 0) {
                                        //     task_assignee = [];
                                        //     task_assignee = taskData.schedule_assignees ? taskData.schedule_assignees : []
                                        // }
                                        if (assignee && assignee.categories && ["myr_staff_task", "my_staff" ,"my_operator","my_contact", "task_topic","my_task"].includes(assignee.main_key)) {
                                            assignee && assignee.categories.map((taskAssign) => {
                                                defaultTopics &&
                                                    defaultTopics.forEach((topic) => {
                                                        if (["myr_staff_task", "my_staff" ,"my_operator","my_contact", "task_topic","my_task"].includes(topic.main_key)) {
                                                            if (topic.main_key == assignee.main_key) {
                                                                topic.categories.forEach((provider) => {
                                                                    if (taskAssign.filter_key == provider.filter_key) {
                                                                        // if (taskData.schedule_assignees.length == 0) {
                                                                            task_assignee.push({
                                                                                name: provider.title,
                                                                                profile_image: provider.image,
                                                                                id: provider.filter_key,
                                                                            });
                                                                        // }
                                                                    }
                                                                });
                                                            }
                                                        }
                                                    });
                                            });
                                        }
                                    })
                                }
                                if (day.date == moment(event_date).format("YYYY-MM-DD")) {
                                    return (<>
                                        {[popoverPosition].map((placement, index) => (
                                         <div className={`d-flex flex-column ${event.id == activeTaskID ? "z-index-7" : ""} `}
                                         onMouseEnter={(e) => {
                                            handleOnMouseEnter(e, event.id)
                                            setTaskcreateId(event.id);
                                            if (commanoffcanvasIsOpen == false) {
                                                setTempHoverTask(event)
                                                setEventInfo(e);
                                                setShowCalendarDropDown(false);
                                                if ($('.popoveraroowposition').hasClass('show')) {
                                                    $('.popoveraroowposition').removeClass('show');
                                                    setDropdownToggle(false);
                                                    dispatch(SetSelectedTask(""))
                                                }
                                            }
                                        }}
                                        onMouseLeave={handleOnMouseLeave}
                                        onClick={() => {
                                            dispatch(commanoffcanvasisOpenfromCalendar(true))
                                            setActiveTaskID(event.id)
                                            setTaskcreateId(event.id);
                                            if (commanoffcanvasIsOpen) {
                                            } else {
                                                // setShowTaskModal(true)
                                                let width = calendarEventInfo.pageX;
                                                let height = calendarEventInfo.pageY;
                                                setHeightWidth({ width: width, height: height });
                                            }
                                            if (commanoffcanvasIsOpen) {
                                                setselectedEventDate(day.date)
                                                setShowTaskModal(true);
                                                dispatch(
                                                    getSelectedTask(
                                                        event.rel_id,
                                                        event.id,
                                                        event.is_requested == 1
                                                            ? 1
                                                            : null,
                                                    )
                                                );
                                            }
                                        }}>
                                            <div className={`daylist m-2 ${dropdownToggle ? "CalendarOverlay" : ""}`} onClick={()=>{
                                                if(commanoffcanvasIsOpen){
                                                    setselectedEventDate(day.date)
                                                        setShowTaskModal(true);
                                                        setActiveTaskID(event.id);
                                                        dispatch(commanoffcanvasisOpenfromCalendar(true));
                                                        dispatch(
                                                            getSelectedTask(
                                                                event.rel_id,
                                                                event.id,
                                                                event.is_requested == 1
                                                                    ? 1
                                                                    : null,
                                                            )
                                                        );
                                                }
                                            }}>
                                                <a href="#/" className={`DayViewHeader c-font f-12 p-5px radiustop d-block ${event.is_requested == 1 ? "bg-request" : event.status == 5 ? "bg-done" : event.status == 4 ? "bg-inprogress" : "bg-notstarted"}`} 
                                                onClick={()=>{
                                                    setselectedEventDate(day.date)
                                                    setShowTaskModal(true);
                                                    setActiveTaskID(event.id);
                                                    dispatch(commanoffcanvasisOpenfromCalendar(true));
                                                    dispatch(getSelectedTask(event.rel_id,event.id,event.is_requested == 1 ? 1: null))
                                                    // ******************
                                                    jquery("body").addClass("pb-0 right-penel-no-padding modal-open with-right-penel task-modal-offcanvas-open")
                                                }}>
                                                    <div className='d-flex align-items-center fw-semibold f-black'>
                                                        <div className='me-2'>{moment(event_date).format("hh:mm a")}</div>
                                                        <div className='text-truncate'>{event.name}</div>
                                                    </div>
                                                </a>
                                                <div className='WeekDetail p-15 calendar-box-bg'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                    <OverlayTrigger
                                                                // trigger={['hover','focus']}
                                                                // "click",
                                                                delay={{ show: 2000 }}
                                                                key={index}
                                                                placement={placement}
                                                                onEnter={() => getspacedata()}
                                                                onToggle={(e) => { setDropdownToggle(e) }}
                                                                // show={SelectedTaskId == eventInfo.event.id }
                                                                show={(dropdownToggle == true) && tempHoverTask && tempHoverTask.id ? tempHoverTask.id == event.id : false}
                                                                // show={true}
                                                                overlay={
                                                                    <Popover id={`popover-positioned-${placement}`} className={` popoveraroowposition border-0 ${commanoffcanvasIsOpen == false ? selectedTask == undefined && selectedTask == "d-none" ? "w-400 " : "w-400 " : "d-none"}`}
                                                                        onMouseEnter={() => handleOnMouseEnter(event.id)}
                                                                        onMouseLeave={handleOnMouseLeave}>
                                                                        {commanoffcanvasIsOpen == false ? <>
                                                                            {selectedTask != undefined && selectedTask != "" ?
                                                                                <Popover.Body className="p-0">
                                                                                    <CalendarDropdown
                                                                                        projectId={selectedProject}
                                                                                        taskId={taskId}
                                                                                        providers={providers}
                                                                                        dayClick={dayClick}
                                                                                        meetingUrl={meetingUrl}
                                                                                        docType={"task"}
                                                                                        pageName={pageName}
                                                                                        data={{ contacts: shareContacts }}
                                                                                        isOutlookCalActive={isOutlookCalendarActive}
                                                                                        handler={handleSelectedTask}
                                                                                        selectCalIds={selectCalIds}
                                                                                        setShowTaskInfo={setShowTaskInfo}
                                                                                        taskNameOrStatusUpdate={(
                                                                                            task_id,
                                                                                            task_name_status,
                                                                                            flag = ""
                                                                                        ) => {
                                                                                            let eventUpdate = events.map((event) => {
                                                                                                if (task_id == event.id) {
                                                                                                    if (flag != "") {
                                                                                                        if (task_name_status == 1) {
                                                                                                            event["color"] = "#b3b3b3";
                                                                                                        } else if (task_name_status == 4) {
                                                                                                            event["color"] = "#03A9F4";
                                                                                                        } else if (task_name_status == 5) {
                                                                                                            event["color"] = "#00ff6a";
                                                                                                        }
                                                                                                    } else {
                                                                                                        event["title"] = task_name_status;
                                                                                                    }
                                                                                                    return event;
                                                                                                }
                                                                                                return event;
                                                                                            });
                                                                                            setEvents(eventUpdate);
                                                                                        }}
                                                                                        setActiveTaskID={setActiveTaskID}
                                                                                        isFromCalendar={true}
                                                                                        setSelectedTaskId={setEventInfo}
                                                                                        startdate={startDate}
                                                                                        endDate={endDate}
                                                                                        setDropdownToggle={setDropdownToggle}
                                                                                    />
                                                                                </Popover.Body>
                                                                                : <></>}
                                                                        </> : <></>}
                                                                    </Popover>
                                                                }
                                                            > 
                                                        <a href="#/" className='CalendarProfile flex-grow-1 w-75' 
                                                        onClick={(e) => {
                                                            if (!commanoffcanvasIsOpen && !$('.fade').hasClass('show') && !$('.dropdown-menu').hasClass('show')) {
                                                        setselectedEventDate(day.date)
                                                        setShowTaskModal(true);
                                                        setActiveTaskID(event.id);
                                                        dispatch(commanoffcanvasisOpenfromCalendar(true));
                                                        dispatch(getSelectedTask(event.rel_id,event.id,event.is_requested == 1 ? 1: null,));
                                                        // *************
                                                        jquery("body").addClass("pb-0 right-penel-no-padding modal-open with-right-penel task-modal-offcanvas-open")

                                                    }
                                                    }}
                                                    onMouseEnter={(e) => { handleOnMouseEnter(e, event.id) }} 
                                                    onMouseLeave={handleOnMouseLeave}
                                                    >
                                                            <div className='d-flex align-items-center'>
                                                                <div className='position-relative   assignee-list-wrapper'>
                                                                    <div className='multi_inner_wrapper with-min-width d-flex with_minus justify-content-start align-items-center'>
                                                                        <div className='comman-image-box h35w35 rounded-circle one assignee-list-wrapper card-view-less-padding'>
                                                                            <div className='comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle' style={{ backgroundImage: `url(${task_assignee.length > 0 && task_assignee[0].profile_image ? task_assignee[0].profile_image : event.schedule_id > 0 ? User_2 : taskData.creator_profile_image ? taskData.creator_profile_image : ""})` }}></div>
                                                                        </div>
                                                                        {task_assignee && task_assignee.length > 1 ?
                                                                        <div className={`multi_image_wrapper dropdown-center dropdown`}>
                                                                            <div className="multi_inner_wrapper d-flex position-relative pe-2">
                                                                               
                                                                                {task_assignee && task_assignee.length > 1 ?
                                                                                    <>
                                                                                        <div className={`comman-image-box h25w25 with_overlay d-flex rounded-circle border-2 `} 
                                                                                        onClick={(event)=>event.preventDefault()}>
                                                                                            <UncontrolledDropdown>
                                                                                                <DropdownToggle tag="div" className="dropdown dropdown-center team-list-dropdown h25w25" >
                                                                                                    <div
                                                                                                        className="d-flex rounded-circle h-100"
                                                                                                        type="button"
                                                                                                        data-bs-toggle="dropdown"
                                                                                                        data-bs-auto-close="outside"
                                                                                                        aria-expanded="false"
                                                                                                    >
                                                                                                        <div className="unread_count m-auto">
                                                                                                            +{task_assignee ? task_assignee.length - 1 : " "}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <UncontrolledGeneralListing list={task_assignee ? task_assignee.map((data, index) => {
                                                                                                        return {
                                                                                                            name: data.name,
                                                                                                            image: data.profile_image ? data.profile_image : '',
                                                                                                            id: index,
                                                                                                        };
                                                                                                    }) : <></>} />
                                                                                                </DropdownToggle>
                                                                                            </UncontrolledDropdown>
                                                                                        </div>
                                                                                    </>
                                                                                    : <></>
                                                                                }

                                                                            </div>
                                                                            <ReactTooltip />
                                                                        </div> : <></>}
                                                                    </div>
                                                                </div>
                                                                <div className='color-white-60 c-font f-14 ps-3'>{task_assignee.length > 0 && task_assignee[0].name ? task_assignee[0].name : taskData.creator_name ? taskData.creator_name : ""}</div>
                                                            </div>
                                                        </a>
                                                        </OverlayTrigger>
                                                        <div className='progress-slider-with-action-icon w-25'>
                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                <div className='slider-progress  flex-grow-1 p-15 py-0 c-font f-14 min-width200'>
                                                                    <UncontrolledDropdown className='progress-slider w-100'>
                                                                        <DropdownToggle data-toggle="dropdown" tag="a" className="progress-slider w-100" onClick={() => {
                                                                            setOpenCheckList(true)
                                                                            dispatch(getTaskChecklist(event.id, taskData.rel_id));
                                                                        }}
                                                                            type="button"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                            data-bs-auto-close="outside">
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='progress'>
                                                                                    <div className='progress-bar' style={{ width: `${event.checklist_progress}%` }}></div>
                                                                                </div>
                                                                                <div className='progress-text title-fonts color-white-80 text-center ps-2 c-font f-14'>{event.checklist_progress}%</div>
                                                                            </div>
                                                                        </DropdownToggle>
                                                                        <DropdownItem toggle={false} className="p-0">
                                                                            <CheckedDropdown
                                                                                unContolList={true}
                                                                                setOpenCheckList={setOpenCheckList}
                                                                                project_id={taskData.rel_id}
                                                                                openCheckList={openCheckList}
                                                                                task_id={event.id}
                                                                                taskDetails={taskData}
                                                                                task_status={taskData.status}
                                                                                isSystemTask={
                                                                                    taskData.task && parseInt(taskData.is_allowed_to_edit) != 1
                                                                                        ? true
                                                                                        : null
                                                                                }
                                                                                isChecklistPermission={
                                                                                    taskData.task &&
                                                                                        taskData.is_requested == 1 &&
                                                                                        taskData.request_task_id > 0
                                                                                        ? 1
                                                                                        : 0
                                                                                }
                                                                                pageName={"calendar"}
                                                                            />
                                                                        </DropdownItem>
                                                                    </UncontrolledDropdown>
                                                                </div>
                                                                <div className='d-flex align-items-center justify-content-center'>
                                                                    <div className='dropup  d-flex align-items-center pe-2'>
                                                                        <UncontrolledDropdown className='progress-slider w-100'>
                                                                            <DropdownToggle tag={'a'} className='before-dnone before_dnone bg-white-05 comman_action_icon d-flex radius_3 w-100 calender-action-icon'
                                                                                type="button"
                                                                                id="writeComMentDropdown"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                data-bs-auto-close="outside"
                                                                                data-tip={_l('l_comments')}
                                                                                data-effect="solid"
                                                                                data-delay-show='1000'
                                                                                data-class="tooltip-main"
                                                                                onClick={() => {
                                                                                    getTaskComments(event.id);
                                                                                    getMentionlist(event.id);
                                                                                }}>
                                                                                <div className='d-flex align-items-center w-100 on-hover-active-toggle-img text-on-hover-active-green'>
                                                                                        <div className="position-relative">
                                                                                            <ChatCircle size={16} className="c-icons" weight="light" />
                                                                                            <span
                                                                                                className={`rounded-pill badge-custom p-0 ${parseInt(event.unread_comment_count) > 0
                                                                                                    ? ""
                                                                                                    : "d-none"
                                                                                                    }`}
                                                                                                data-unread-cmt-id={event.id}
                                                                                            >
                                                                                                {event.unread_comment_count}
                                                                                            </span>
                                                                                        </div>
                                                                                </div>
                                                                            </DropdownToggle>
                                                                            <DropdownItem toggle={false} className="p-0">
                                                                                <CommentMinified
                                                                                    comments={commentsList}
                                                                                    disabled={
                                                                                        selectedTask &&
                                                                                        parseInt(selectedTask.request_task_id) > 0 &&
                                                                                        selectedTask.is_requested == 1}
                                                                                    handleAddComment={handleAddEditComment}
                                                                                    deleteComment={handleDeleteComment}
                                                                                    id={event.id}
                                                                                    loading={commentsLoading}
                                                                                    mentionList={mentionList.map((name) => {
                                                                                        let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                                                                                        return {
                                                                                          display: name.name,
                                                                                          id: id,
                                                                                          image: name.profile_image || "",
                                                                                          isteam: name.is_team ? 1 : 0,
                                                                                        };
                                                                                      })}
                                                                                    handleImagePreview={""}
                                                                                    pageName={pageName}
                                                                                    task={selectedTask}
                                                                                    unContolList={true}
                                                                                    showComments={showComments}
                                                                                />
                                                                            </DropdownItem>
                                                                        </UncontrolledDropdown>
                                                                    </div>
                                                                    <div className='dropup  d-flex align-items-center'>
                                                                        <div className='w-100'>
                                                                            <UncontrolledDropdown className='comman_action_icon w-100 d-flex close-toast border-0'>
                                                                                <DropdownToggle tag={'a'} className='me-0 before-dnone before_dnone bg-white-05 comman_action_icon d-flex radius_3 w-100 calender-action-icon'
                                                                                    type="button"
                                                                                    id="imageLstAndUploadDropdown"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"
                                                                                    data-bs-auto-close="outside"
                                                                                    data-tip={_l('l_attachments')}
                                                                                    data-effect="solid"
                                                                                    data-delay-show='1000'
                                                                                    data-class="tooltip-main"
                                                                                    onClick={(e) => {
                                                                                        setOpenAttachmentsDropdown(true);
                                                                                        setTempTaskId(event.id);
                                                                                    }}>
                                                                                    <div className='d-flex align-items-center w-100 on-hover-active-toggle-img text-on-hover-active-green'>
                                                                                        <div className="position-relative">
                                                                                            <Image size={16} className="c-icons" weight="light" />
                                                                                        </div>
                                                                                        <span className="d-flex justify-content-center flex-grow-1">{event.attachments_count}</span>
                                                                                    </div>
                                                                                </DropdownToggle>
                                                                                <DropdownItem toggle={false} className="p-0">
                                                                                    <ImageUploadDropdown
                                                                                        unContolList={true}
                                                                                        setOpenAttachmentsDropdown={
                                                                                            setOpenAttachmentsDropdown
                                                                                        }
                                                                                        openAttachmentsDropdown={openAttachmentsDropdown}
                                                                                        task_id={event.id}
                                                                                        project_id={projectId}
                                                                                        updateImageCount={setAttchamentCount}
                                                                                        Request_task_converted={
                                                                                            selectedTask &&
                                                                                                parseInt(selectedTask.request_task_id) > 0 &&
                                                                                                selectedTask.is_requested == 1
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        taskDetails={event}
                                                                                        pageName={pageName}
                                                                                    />
                                                                                </DropdownItem>
                                                                            </UncontrolledDropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}</>)
                                }
                            })}
                </div> </> )}
            })} </> : <></>}
                {/* day view end */}

                {/* createTask Offcanvas start */}
                {showCreateTaskOffcanvas ? 
                <>
                <CreateTaskOffcanvas
                show={showCreateTaskOffcanvas}
                handleClose={(taskId)=>{
                    setShowCreateTaskOffcanvas(false);
                    if (taskId) {
                        setActiveTaskID(taskId);
                        setselectedEventDate(createtaskDate)
                        dispatch(
                            getSelectedTask(
                                localStorage.getItem("selectedOffice"),
                                taskId
                            ))
                        setShowTaskModal(true);
                    }
                }
            } 
                is_fromCalendar ={true}
                isFromLiteVersion={true}
                createtaskDate ={new Date(createtaskDate)}
                />
                </> : <></>}
                {/* createTask Offcanvas end */}
            </div>}
            {
                showTaskModal ? <CommanOffCanvas
                    show={showTaskModal}
                    handleClose={() => {
                        setShowTaskModal(false);
                        // setIsRequestTask()
                        setCommonModalData();
                        setTaskId(0);
                        setTask_type('')
                    }}
                    pinHandler={() => {
                        // handlePinUnPin(id, type, flag);
                    }}
                    data={commonModalData}
                    pageName={pageName}
                    docType={task_type}
                    setSelectedId={setTaskId}
                    selectedTaskId={taskId}
                    IsKanbanView={false}
                    meetingUrl={meetingUrl}
                    canvasfullHeightwithHeaderFooter={true}
                    handleParentComponentAction={(type, taskData) => {
                        if (task_type == 'task') {
                            if (type == 'attachmentCountUpdate') {
                                // updateAttchmentChecklistCount(taskData.task_id, taskData.flag)
                                return;
                            } else if (type == 'handleSelectedTask') {
                                handleSelectedTask(taskData.task_id, taskData.flag)
                                return;
                            } else if (type == 'convertedTaskAction') {
                                handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                                return;
                            }
                        } else if (task_type == 'request-task') {

                            if (type == 'convertedTaskAction') {
                                handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                                return;
                            }
                        }

                    }}
                    setActiveTaskID={setActiveTaskID}
                    isFromCalendar = {true}
                /> : <></>
            }
        </>
    )
}

export default Customcalendar