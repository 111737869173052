import {
  CaretDown,
  ChatCircle,
  DotsThreeVertical,
  DownloadSimple,
  EnvelopeSimple,
  FileArrowUp,
  Phone,
  PhoneCall,
  Plus,
  PushPin,
  ShieldCheck,
  X
} from "phosphor-react";
import React from "react";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import { _l } from "../../hooks/utilities";
import ReactTooltip from "react-tooltip";
import Form from "react-bootstrap/Form";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

const GeneralCardView = ({
  actionItems,
  cardTitle,
  ownerDetails,
  pills = [],
  progress,
  category,
  showShieldIcon,
  people = [],
  onClick = () => {},
  ActionButton,
  ActionSelection,
  roleDetail,
  selectedTopic = "",
  teamName,
  managers = [],
  providerList = [],
  managerSelection,
  projects = [],
  peopleListTitle = "members",
  hideChatAndCall,
  hideProgressBarTooltip,
  aiChatAssistURL,
  lastLogin,
  noOfComments,
  taskScore,
  logDetcity,
  logDetavgtime,
  logDetstatus,
  logDettotalduration,
  logDetnoofsession,
  viewOwnerDetails = () => {},
  shoWdetails = false,
  details ={},
  uploadContract = () => {},
  imagePreviews=[],
  showDownloadIcon = false,
  showUploadIcon = false,
  downloadFile = ()=>{},
  createChatChannels = () => {},
  setCurrentProposal = () =>{ },
  proposal,
  setSelectedContract= () => {},
  isFromEmailSchedul = false,
  data
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={`task-list-card list-view cursor-pointer`} onClick={onClick} >
        <div className="task-list-wrapper">
          <div className="d-flex w-100 align-items-start">
            <div className="d-flex task-list-detail">
              <div className="task-left flex-grow-1 width100minus160">
                <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                  <div className="task-creator-img d-none">
                    <div className="h20w20 d-flex align-items-center me-2">
                      <Form.Check
                        type="checkbox"
                        className="m-auto"
                        checked=""
                        profile
                      />
                    </div>
                  </div>
                  <div className="task-top-part text-truncate flex-grow-1 d-flex main-calc-width-task">
                    <div className="d-flex align-items-center w-100 comman_action_icon" 
                    onClick={
                      shoWdetails ? ()=>{} : viewOwnerDetails}
                    >
                      {
                        ownerDetails && ownerDetails.profile_image
                        ?
                        <React.Fragment>
                          {typeof ownerDetails.profile_image === "string" ? (
                            <div className="task-creator-img">
                              <div className="comman-image-box h32w32 rounded-circle">
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${ownerDetails.profile_image}')`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          ) : (
                            <div className="action_icon with_bg h32w32 d-flex align-items-center justify-content-center rounded-circle">
                            <ownerDetails.profile_image />
                            </div>
                          )}
                        </React.Fragment>
                        :
                        <React.Fragment></React.Fragment>
                      }
                      <a
                        className="task-list-name text-truncate d-flex align-items-center"
                        // onClick={onClick}
                        onClick={shoWdetails ? viewOwnerDetails : ()=>{}}
                      >
                        <div className="d-flex flex-column">
                          <div className="title-fonts c-font text-truncate">
                            {cardTitle}
                          </div>
                          <div className="c-font color-white-60 f-10  location me-2 fw-normal mt-1">
                            {isFromEmailSchedul && <div className="d-flex align-items-center ">
                              <div className="d-flex align-items-center c-font f-12 mt-1 with_separator_10 ps-0">
                                <span className="ps-0">{_l("l_date")} :</span>
                                <span className="color-white">{moment(details.schedule_date).format("DD/MM/yy hh:mm a")}</span>
                              </div>
                              <div className="d-flex align-items-center c-font f-12 mt-1 with_separator_10">
                                <span className="ps-0">{_l("l_content")} :</span>
                                <span className="color-white text-truncate">{details.msg_body}</span>
                              </div>
                            </div>}
                            {shoWdetails && <div className="d-flex align-items-center ">
                              <div className="d-flex align-items-center c-font f-12 mt-1 with_separator_10 ps-0">
                                <span className="ps-0">Member since :</span>
                                <span className="color-white fw-semibold">{moment(details.datecreated).format("YYYY-MM-DD")}</span>
                              </div>
                              <div className="d-flex align-items-center c-font f-12 mt-1 with_separator_10">
                              <span className="ps-0">{_l("l_task_score")} :</span>
                                <span className="color-white fw-semibold">{details.score}%</span>
                              </div>
                              <div className="d-flex align-items-center c-font f-12 mt-1 with_separator_10">
                                <span className="ps-0">{_l("l_task")} :</span>
                                <span className="color-white fw-semibold">{details.total_task}</span>
                              </div>
                              <div className="d-flex align-items-center c-font f-12 mt-1 with_separator_10">
                                <span className="ps-0">{_l("l_address")} :</span>
                                <span className="color-white fw-semibold">{details.current_address}</span>
                              </div>
                            </div>}
                            {selectedTopic === "manage_user" && lastLogin ? (
                              <div className=" color-white-80 "
                                data-tip={`${_l("l_last_login")}`}
                                data-effect="solid"
                                data-delay-show='1000'
                              >
                                <span>{`${_l("l_last_login")}`} : </span>
                                <span> {lastLogin && lastLogin != 0 ? moment(lastLogin).format('DD/MM/YYYY hh:mm:s A') : 'No last login'} </span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div> 
                        <div className={`${!showShieldIcon ? "d-none" : ""}`}>
                          <ShieldCheck
                            size={18}
                            weight="light"
                            className="c-icons mx-2 color-green"
                          />
                        </div>    
                      </a>
                        {shoWdetails && 
                        <div className="d-flex justify-content-center align-content-center">
                          {showUploadIcon && 
                          <Button 
                          className="bg-white-10 btn btn-primary btn-sm light-theme-img me-10px position-relative"
                          // variant="secondary"
                          data-tip={`${_l("l_upload_contract")}`}
                          data-effect="solid"
                          data-delay-show='1000'
                          >
                            <input
                              className="z-index-3 hidden-input"
                              type="file"
                              accept=".pdf"
                              onInput={(e) => {
                                setCurrentProposal(proposal);
                                e.preventDefault();
                                uploadContract( e.target.files[0])
                                setSelectedContract(e.target.files)
                              }}
                            />
                            <div className="m-auto">
                              <FileArrowUp
                                size={18}
                                className="c-icons me-10px"
                                weight="light"
                              />
                              <span className="me-10px">
                                {_l("l_upload_contract")}
                                </span>
                            </div>
                        </Button>}
                          {showDownloadIcon && 
                          <a className="action_icon with_bg h32w32 position-relative light-theme-img me-10px"
                          onClick={()=>{
                            downloadFile();
                          }}
                          >
                            <DownloadSimple
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </a>}
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img me-10px">
                            <PhoneCall
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                          <a href="#/" onClick={()=>{
                            createChatChannels()
                          }}>
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img me-10px">
                            <ChatCircle
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                          </a>
                        </div>}                    
                    </div>
                  </div>
                 
                  {teamName && teamName.team_name && teamName.length > 0 ? (
                    teamName.map((tm) => {
                      return (
                        <div className="with_separator_10 c-font color-white-80 f-12">
                          {tm.team_name}
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {roleDetail && roleDetail ? 
                  <div className="badge rounded-pill text-nowrap list-toggle-view-none h32wauto c-font f-12 d-flex align-items-center me-10px"
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                    data-tip={_l("l_user_role")}>
                    <span className="">{roleDetail ? roleDetail.role_name : ""}</span>
                  </div>
                  :<></>}
                </div>
                 
                <div className="d-flex align-items-center task-bottom-part-full" onClick={(e) =>{e.stopPropagation()}}>
                  <div className="task-right-static-options">
                    <div className="d-flex align-items-center">
                      <div className="comman_action_icon with_separator_10 card-view-less-padding d-none">
                        <div className="dropdown-center white-border-box color-white-60 border-0">
                          <div
                            type="button"
                            id="StatusDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-tip={`${_l("l_task_status")}`}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <div className="d-flex align-items-center m-auto">
                              <span className={`c-dots c-10`}>
                                #{category ? category : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end ">
                      {selectedTopic == "manage_user" ? 
                        <>
                        {(logDetcity || logDetstatus || logDetavgtime || logDettotalduration || logDetnoofsession) ?
                              ["right"].map((placement) => (
                                <OverlayTrigger
                                  trigger={['hover', 'focus']}
                                  rootClose
                                  delay={{ show: 1500 }}
                                  key={placement}
                                  placement={placement}
                                  overlay={
                                    <Popover
                                      id={`popover-positioned-${placement}`}
                                      className="w-300"
                                    >
                                      {/* <Popover.Header as="h3">{`Popover ${placement}`}{`${_l("l_manage_user_label")} ${_l("l_detail")}`}</Popover.Header> */}
                                      <Popover.Body className="p-0">
                                        <div className="popover-detail-wrapper padding_10 c-font f-14">
                                         
                                              <div className="pb-10px">
                                                <span className='color-white-60'>{_l("l_number_of_session")} :</span>
                                                <span className="color-white fw-semibold ms-2">{logDetnoofsession && logDetnoofsession || '-'}</span>
                                              </div>
                                              <div className="pb-10px">
                                                <span className='color-white-60'>{_l("l_total_duration")} :</span>
                                                <span className="color-white fw-semibold ms-2">{logDettotalduration && logDettotalduration || '-'}</span>
                                              </div>
                                              <div className="pb-10px">
                                                <span className='color-white-60'>{_l("l_average_duration_time_by_session")} :</span>
                                                <span className="color-white fw-semibold ms-2">{logDetavgtime && logDetavgtime || '-'}</span>
                                              </div>
                                              <div className="pb-10px">
                                                <span className='color-white-60'>{_l("l_status")} :</span>
                                                <span className="color-white fw-semibold ms-2">{logDetstatus && logDetstatus || '-'}</span>
                                              </div>
                                              <div className="">
                                                <span className='color-white-60'>{_l("l_location")}</span>
                                                <span className="color-white fw-semibold ms-2">{logDetcity && logDetcity || '-'}</span>
                                              </div>
                                           
                                        </div>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <div className="me-10px">
                                    <div className="badge rounded-pill text-nowrap list-toggle-view-none h32wauto c-font f-12 d-flex align-items-center" 
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main">
                                      <span className="">{_l("l_login_details")}</span>
                                    </div>
                                  </div>
                                </OverlayTrigger>
                              ))
                              : <></>}
                        {noOfComments && noOfComments != 0 ? 
                        <div className="me-10px">
                          <div className="badge rounded-pill text-nowrap list-toggle-view-none h32wauto c-font f-12 d-flex align-items-center">
                            <span className="  d-block p-0 my-auto ">{_l("l_no_of_comments")}: {noOfComments}</span>
                          </div>
                        </div>
                        : <></>}
                        {taskScore && taskScore != 0 ? 
                        <div className="me-10px">
                          <div className="badge rounded-pill text-nowrap list-toggle-view-none h32wauto c-font f-12 d-flex align-items-center">
                            <span className="  d-block p-0 my-auto ">{_l("l_task_score")}: {taskScore}%</span>
                          </div>
                        </div>
                        : <></>}
                        </> : <></> }
                        {data && data.is_from_chat && data.is_from_chat == "1" ? 
                          <div className="pill-min-width pill-bottom-show  text-start">
                            <div className="d-flex align-items-center">
                              <a href="#/" className="badge h32wauto d-flex align-items-center c-font f-12 badge-white rounded-pill me-2  text-nowrap ">
                                <span
                                  className={`p-0`}
                                > 
                                  {_l("l_ai_document")}
                                </span>
                              </a>
                            </div>
                          </div> 
                          : <></>} 
                        <div className="position-relative d-flex assignee-list-wrapper card-view-less-padding ">
                          {pills.length > 0 &&
                            pills.map((pill, index) => {
                              if (index < 5) {
                                return (
                                  <div
                                    key={index}
                                    className="dropdown-center multi_inner_wrapper with-min-width d-flex me-10px with_minus  "
                                  >
                                    <div
                                      className="badge rounded-pill text-nowrap list-toggle-view-none h32wauto c-font f-12 d-flex align-items-cente"
                                      data-tip={pill.tooltip || pill.content}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                    >
                                      <span
                                        class={`text-truncate max-width75  d-block p-0 my-auto ${pill.color}`}
                                      >
                                        {pill.content}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                        </div>
                        {pills.length > 5 ? (
                          <React.Fragment>
                            <UncontrolledDropdown className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center">
                              <DropdownToggle
                                data-toggle="dropdown"
                                tag="div"
                                className=""
                              >
                                <a href="#/"
                                  className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                  id="TaskAssigneeDropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  data-tip={`${_l("l_view_all")}`}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                >
                                  <div className="unread_count m-auto c-font f-12">
                                    +{pills.length - 2}
                                  </div>
                                </a>
                                <GeneralListing
                                  unControlList={true}
                                  list={pills.map((w, index) => {
                                    return {
                                      id: index + 1,
                                      name: w.content,
                                    };
                                  })}
                                />
                              </DropdownToggle>
                            </UncontrolledDropdown>
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                        {roleDetail && roleDetail.role_id != 1 ? (
                          <>
                            <div className="position-relative d-flex assignee-list-wrapper card-view-less-padding ">
                              {projects.length > 0 &&
                                projects.map((pill, index) => {
                                  if (index < 1) {
                                    return (
                                      <div
                                        key={index}
                                        className="dropdown-center multi_inner_wrapper with-min-width d-flex me-10px with_minus  "
                                      >
                                        <div
                                          className="badge rounded-pill text-nowrap list-toggle-view-none h32wauto c-font f-12 d-flex align-items-cente"
                                          data-tip={pill.content}
                                          data-effect="solid"
                                          data-delay-show='1000'
                                          data-class="tooltip-main"
                                        >
                                          <span
                                            class={`text-truncate max-width75  d-block p-0 my-auto ${pill.color}`}
                                          >
                                            {pill.content}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                            {projects.length > 1 ? (
                              <React.Fragment>
                                <UncontrolledDropdown className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center me-10px">
                                  <DropdownToggle
                                    data-toggle="dropdown"
                                    tag="div"
                                    className=""
                                  >
                                    <a href="#/"
                                      className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                      id="TaskAssigneeDropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-tip={`${_l("l_view_all")}`}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                    >
                                      <div className="unread_count m-auto c-font f-12">
                                        +{projects.length - 1}
                                      </div>
                                    </a>
                                    <GeneralListing
                                      unControlList={true}
                                      list={projects.map((w, index) => {
                                        return {
                                          id: index + 1,
                                          name: w.content,
                                        };
                                      })}
                                    />
                                  </DropdownToggle>
                                </UncontrolledDropdown>
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </>
                        ) : (
                          <>
                            <div
                              className={`dropdown-center multi_inner_wrapper with-min-width d-flex me-10px with_minus ${
                                selectedTopic == "manage_user" ? "" : "d-none"
                              }`}
                            >
                              <div
                                className="badge rounded-pill text-nowrap list-toggle-view-none h32wauto c-font f-12 d-flex align-items-cente"
                                data-tip={_l("all_project_access")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                              >
                                <span
                                  class={`text-truncate max-width75  d-block p-0 my-auto`}
                                >
                                  {_l("all_project_access")}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {/* <div className="dropdown"> */}
                        {ActionSelection ? ActionSelection : <></>}
                      {/* </div> */}
                      <div className="comman_action_icon with_separator_10 card-view-less-padding d-none">
                        <div className="dropdown-center white-border-box color-white-60 border-0">
                          <div
                            className={`action_icon with_bg h32w32 dropdown close-toast`}
                            type="button"
                            id="StatusDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-tip={`${_l("l_task_status")}`}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <div className="d-flex align-items-center m-auto">
                              <span className={`c-dots c-10`}></span>

                              <CaretDown
                                size={14}
                                className="c-icons d-none"
                                weight="light"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`with_separator_10 before-dnone pe-0 align-items-center ser ${
                          managers.length > 0 ? "d-flex" : "d-none"
                        }`}
                      >
                        {managers.length ? (
                          <div className=" d-flex align-items-center">
                            <div className="c-font color-white-80 f-12 me-10px">
                              {_l("l_managers") + ":"}
                            </div>
                            <div
                              className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_minus before-dnone`}
                            >
                              <a href="#/"
                                data-bs-toggle="tooltip"
                                data-tip={`${managers[0].fullname}`}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                              >
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${managers[0].profile_image_url}')`,
                                  }}
                                ></div>
                                <ReactTooltip />
                              </a>
                              {managers.length > 1 ? (
                                <React.Fragment>
                                  <UncontrolledDropdown className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center">
                                    <DropdownToggle
                                      data-toggle="dropdown"
                                      tag="div"
                                      className=""
                                    >
                                      <a href="#/"
                                        className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                        id="TaskAssigneeDropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-tip={`${_l("l_view_all")}`}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        <div className="unread_count m-auto c-font f-12">
                                          +{managers.length - 1}
                                        </div>
                                      </a>
                                      <GeneralListing
                                        unControlList={true}
                                        list={managers.map((w, index) => {
                                          return {
                                            id: index + 1,
                                            name: w.fullname,
                                            image: w.profile_image_url,
                                          };
                                        })}
                                      />
                                    </DropdownToggle>
                                  </UncontrolledDropdown>
                                </React.Fragment>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </div>
                          </div>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                        {selectedTopic == "manage_company" ? (
                          <>{managerSelection}</>
                        ) : (
                          <></>
                        )}
                      </div>
                      {providerList.length ? (
                        <div className="with_separator_10  before-dnone d-flex align-items-center sd">
                          <div className="c-font color-white-80 f-12 me-10px">
                            {_l("l_providers") + ":"}
                          </div>
                          <div
                            className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_minus before-dnone`}
                          >
                            <a href="#/"
                              data-bs-toggle="tooltip"
                              data-tip={`${providerList[0].fullname}`}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                            >
                              <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                style={{
                                  backgroundImage: `url('${providerList[0].profile_image_url}')`,
                                }}
                              ></div>
                              <ReactTooltip />
                            </a>
                            {providerList.length > 1 ? (
                              <React.Fragment>
                                <UncontrolledDropdown className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center">
                                  <DropdownToggle
                                    data-toggle="dropdown"
                                    tag="div"
                                    className=""
                                  >
                                    <a href="#/"
                                      className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                      id="TaskAssigneeDropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-tip={`${_l("l_view_all")}`}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                    >
                                      <div className="unread_count m-auto c-font f-12">
                                        +{providerList.length - 1}
                                      </div>
                                    </a>
                                    <GeneralListing
                                      unControlList={true}
                                      list={providerList.map((w, index) => {
                                        return {
                                          id: index + 1,
                                          name: w.fullname,
                                          image: w.profile_image_url,
                                        };
                                      })}
                                    />
                                  </DropdownToggle>
                                </UncontrolledDropdown>
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </div>
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      {people.length > 0 ? (
                        <>
                          <div className="c-font color-white-80 f-12 pe-2">
                            {_l(peopleListTitle) + ":"}
                          </div>
                          <div
                            className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_minus before-dnone`}
                            onClick={() => { }}
                          >
                            <a href="#/"
                              data-bs-toggle="tooltip"
                              data-tip={`${
                                people[0].full_name ||
                                people[0].team_name ||
                                people[0].fullname
                              }`}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                            >
                              <div
                                className="comman-image-box-bg bg-style-cover bg-style-cover-90 h-100 w-100 rounded-circle"
                                style={{
                                  backgroundImage: `url('${
                                    people[0].profile_image ||
                                    people[0].profile_image_url
                                  }')`,
                                }}
                              ></div>
                              <ReactTooltip />
                            </a>
                            {people.length > 1 ? (
                              <React.Fragment>
                                <UncontrolledDropdown className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center">
                                  <DropdownToggle
                                    data-toggle="dropdown"
                                    tag="div"
                                    className=""
                                  >
                                    <a href="#/"
                                      className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                      id="TaskAssigneeDropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-tip={`${_l("l_view_all")}`}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                    >
                                      <div className="unread_count m-auto c-font f-12">
                                        +{people.length - 1}
                                      </div>
                                    </a>
                                    <GeneralListing
                                      unControlList={true}
                                      list={people.map((w, index) => {
                                        return {
                                          id: index + 1,
                                          name:
                                            w.full_name ||
                                            w.team_name ||
                                            w.fullname,
                                          image:
                                            w.profile_image ||
                                            w.profile_image_url,
                                        };
                                      })}
                                    />
                                  </DropdownToggle>
                                </UncontrolledDropdown>
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </div>
                        </>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      <div className="comman_action_icon with_separator_10 card-view-less-padding d-none">
                        <a href="#/"
                          className="priority-checkbox span-13 action_icon with_bg close-toast"
                          data-tip={_l("l_priority")}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <input
                            type="checkbox"
                            id="priority"
                            className="absolute-input"
                          />
                          <div className="check_box">
                            <HighPriorityActiveIcon className="HW18 check_span_img active" />
                            <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                          </div>
                        </a>
                      </div>
                      {progress !== undefined ? (
                        <div className=" list-toggle-view-none">
                          <div className="d-flex dropdown align-items-center  d-flex align-items-center before-block justify-content-end  card-view-less-padding  comman_action_icon close-toast">
                            <div className="">
                              <a href="#/" className="before-dnone comman_action_icon pe-0 with_separator_10 card-view-none d-none">
                                <div className="d-flex align-items-center">
                                  <div className="action_icon with_bg h32w32 rounded-circle on-hover-active-toggle-img light-theme-img">
                                    <Plus
                                      size={18}
                                      color="#ffffff"
                                      weight="light"
                                    />
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="dropdown progress-wrapper-minus d-flex">
                              <a href="#/"
                                className="progress-wrapper-link"
                                data-tip={hideProgressBarTooltip ? "" : _l("l_add_checklist")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                              >
                                <div className="progress-slider with_separator_10 before-dnone card-view-less-padding">
                                  <div className="d-flex align-items-center">
                                    <div className="progress w_100">
                                      <div
                                        className="progress-bar"
                                        style={{
                                          width: `${progress}%`,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="progress-text c-font f-12 title-fonts ps-2">
                                      {progress}%
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      {
                        aiChatAssistURL
                        ?
                        <div className="d-flex align-items-center justify-content-end table-list-attechment w_172 w-auto">
                          <div
                            className="with_separator_10 d-flex align-items-center card-view-less-padding before-dnone pe-0"
                            data-tip={_l("l_support")}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <a href="#/"
                              className="comman_action_icon w-100 d-flex"
                              type="button"
                              onClick={() => {
                                navigate(aiChatAssistURL)
                              }}
                            >
                              <div className="d-flex align-items-center w-100">
                                <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img">
                                  <ChatCircle
                                    size={18}
                                    className="c-icons"
                                    weight="light"
                                  />
                                </div>
                              </div>
                            </a>
                            <ReactTooltip />
                          </div>
                        </div>
                        :
                        <React.Fragment></React.Fragment>
                      }
                      {hideChatAndCall ? (
                        <></>
                      ) : (
                        <div className="d-flex align-items-center justify-content-end table-list-attechment w_172 w-auto">
                          <div
                            className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding d-none"
                            data-tip={_l("l_comments")}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <a href="#/"
                              className="comman_action_icon w-100 d-flex"
                              type="button"
                            >
                              <div className="d-flex align-items-center w-100">
                                <div className="action_icon with_bg  on-hover-active-toggle-img light-theme-img">
                                  <ChatCircle
                                    size={18}
                                    className="c-icons"
                                    weight="light"
                                  />
                                </div>
                                <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                  3
                                </span>
                              </div>
                            </a>
                            <ReactTooltip />
                          </div>
                          <div
                            className="with_separator_10 d-flex align-items-center card-view-less-padding before-dnone pe-0"
                            data-tip={_l("l_chat_modal")}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                          >
                            <a href="#/"
                              className="comman_action_icon w-100 d-flex"
                              type="button"
                            >
                              <div className="d-flex align-items-center w-100">
                                <div className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img">
                                  <ChatCircle
                                    size={18}
                                    className="c-icons"
                                    weight="light"
                                  />
                                </div>
                              </div>
                            </a>
                            <ReactTooltip />
                          </div>
                          <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding close-toast d-none">
                            <a href="#/"
                              className="comman_action_icon w-100"
                              onClick={() => {}}
                              data-tip={_l("l_add_email_templates")}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                            >
                              <div className="d-flex align-items-center w-100">
                                <div className="action_icon with_bg  on-hover-active-toggle-img light-theme-img">
                                  <EnvelopeSimple
                                    size={18}
                                    weight="light"
                                    className="c-icons"
                                  />
                                </div>
                                <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                  0
                                </span>
                              </div>
                            </a>
                          </div>
                          <div className="with_separator_10 pe-0 card-view-less-padding ">
                            <a href="#/"
                              className="comman_action_icon w-100 d-flex"
                              data-tip={_l("l_phone_label")}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                            >
                              <div className="action_icon with_bg position-relative h32w32 on-hover-active-toggle-img light-theme-img">
                                <Phone
                                  size={18}
                                  weight="light"
                                  className="c-icons"
                                />
                              </div>
                            </a>
                          </div>
                        </div>
                      )}
                      <div>
                      {ActionButton ? ActionButton : <></>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="task-assign-section">
                <div className="d-flex align-items-center justify-content-end">
                  <div className="before-dnone comman_action_icon with_separator_10 ps-0 pe-1 d-none">
                    <a href="#/"
                      className="action_icon with_bg"
                      onClick={(e) => {e.stopPropagation()}}
                      data-tip={_l("l_pinned")}
                      data-effect="solid"
                      data-delay-show='1000'
                      data-class="tooltip-main"
                    >
                      <PushPin
                        size={16}
                        color="#00ff6a"
                        weight="fill"
                        className="c-icons"
                      />
                    </a>
                  </div>
                  {actionItems && actionItems.length ? (
                        <div className="dropstart comman_action_icon ml-10" onClick={(e) => e.stopPropagation()} >
                      <a href="#/"
                        type="button"
                        className="action_icon h32w32 with_bg with-border-active line-height-0 "
                        data-tip={_l("l_options")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        id="deLeteDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <DotsThreeVertical
                          size={18}
                          className="c-icons"
                          weight="light"
                        />
                      </a>
                      <ActionDropdown actions={actionItems} />
                      {/* <DeleteDropdown /> */}
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </div>
              {imagePreviews && imagePreviews.length ? (
                <div className="upload-image-preview ms-10px">
                  <div className="d-flex align-items-center justify-content-start">
                    {imagePreviews.map((preview, index) => {
                      if (typeof preview.url === "string") {
                        return (
                          <div
                            key={index}
                            className="comman-image-box h35w35 radius_3"
                          >
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                              style={{
                                backgroundImage: `url(
                                  '${preview.url}'
                                )`,
                              }}
                            ></div>
                            {/* <a
                              href="#/"
                              className="upload-close"
                              onClick={() => {
                                // handleAction('removePreview', preview.name)
                                removeImageSelection(preview.name);
                                setSelectedContract();

                              }}
                            >
                              <X
                                size={10}
                                weight="light"
                                className="c-icons"
                              />
                            </a> */}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={index}
                            className="comman-image-box h35w35 radius_3 d-flex"
                          >
                            <preview.url
                              size={30}
                              weight="light"
                              className="c-icons"
                            />
                            {/* <a
                              href="#/"
                              className="upload-close"
                              onClick={() => {
                                // handleAction('removePreview', preview.name)
                                removeImageSelection(preview.name);
                                setSelectedContract();
                              }}
                            >
                              <X
                                size={10}
                                weight="light"
                                className="c-icons"
                              />
                            </a> */}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <ReactTooltip />
        </div>
      </div>
    </>
  );
};

export default GeneralCardView;
