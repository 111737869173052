import React, { useEffect, useMemo, useState } from "react";
import jquery from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../actions/messages";
import {
    Info,
    X,
    MagnifyingGlass,
    Funnel,
    CaretDoubleDown,
  } from "phosphor-react";
  import { isLoadingData } from "../hooks/loader_helper";
import CommanLoader from "./Loader/CommanLoader";
import { CardView } from "./Documents/CardView";
import { _l } from "../hooks/utilities";
import { getCreditNoteDetail, getInvoiceDetail } from "../actions/documents-actions/documents-actions";
import DocumentsServices from "../services/documents-services";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import { CLIENT_TAB_DATA, CREDITNOTE_COUNTER, INVOICE_COUNTER, SUBSCRIPTION_COUNTER } from "../actions/action-type";
import moment from "moment";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import { clientTabData, leftPanelFilters } from "../actions/customer";
const CreditNotesList = ({pageName, clientid}) => {
    const [creditNoteList, setCreditNoteList] = useState([]);
    const [openCreditNoteList, setOpenCreditNoteList] = useState([]);
    const [openCreditNoteListPage, setOpenCreditNoteListPage] = useState(1);
    const [closeCreditNoteList, setCloseCreditNoteList] = useState([]);
    const [closeCreditNoteListPage, setCloseCreditNoteListPage] = useState(1);
    const [voidCreditNoteList, setVoidCreditNoteList] = useState([]);
    const [voidCreditNoteListPage, setVoidCreditNoteListPage] = useState(1);
    const [paidInvoices, setPaidInvoices] = useState([]);
    const [unpaidInvoices, setUnpaidInvoices] = useState([]);
    const [draftInvoices, setDraftInvoices] = useState([]);
    const [cancelledInvoices, setCancelledInvoices] = useState([]);
    const [cancelledInvoicePage, setCancelledInvoicePage] = useState(1);
    const [creditNoteListPage, setCreditNoteListPage] = useState(1);
  const [docType, setDocType] = useState("");
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
  const [showDocumentCanvas, setShowDocumentCanvas] = useState(localStorage.getItem('clientTab') == 1 ? true : false);
  const [selectDocument, setSelectDocument] = useState("");
  const [documentCardDetails, setDocumentCardDetails] = useState();
  const [documentDetail, setDocumentDetail] = useState();
  const [selectedId, setSelectedId] = useState(0);
  const [invoice, setInvoice] = useState([]);
  const [searchString, setSearchString] = useState("");
  const savedDate = localStorage.getItem("selectedDate");
  const { selectedProject: projectId } = useSelector((state) => state.customer);
  const {
    taskFilters,globalSearchString
  } = useSelector((state) => state.customer);

  const dispatch = useDispatch(); 
   const spaceClient = useSelector((state) => state.customer.spaceClient);
   const credit_note_details = useSelector((state) => state.documentsReducer.credit_note_details);
   const pathname = window.location.pathname;
  useEffect(()=>{
    if(spaceClient && spaceClient.id && pathname == "/credit_notes")
    {
      creditNoteCardHandler(spaceClient);
      let data = [];
      dispatch(clientTabData(data));
    }
    if(credit_note_details)
    {
      setDocumentDetail(credit_note_details);
    }
  }, [spaceClient, credit_note_details, pathname])

  setTimeout(() => {
    localStorage.setItem('clientTab', 0);
  }, 10000)

useEffect(() => {
  if(globalSearchString)
  {
    setSearchString(globalSearchString);
  }
}, [globalSearchString])
  useEffect(() => {
    if (globalSearchString == undefined || globalSearchString == "") {
      setOpenCreditNoteList(
        creditNoteList && creditNoteList.filter(
          (inv) => (inv.status != null && inv.status === "1" ? inv.status : "")
          .toLowerCase()
        )
      );

      setCloseCreditNoteList(
        creditNoteList && creditNoteList.filter(
          (inv) => (inv.status != null && inv.status === "2" ? inv.status : "")
          .toLowerCase()
        )
      );

      setVoidCreditNoteList(
        creditNoteList && creditNoteList.filter(
          (inv) => (inv.status != null && inv.status === "3" ? inv.status : "")
          .toLowerCase()
        )
      );

      setCancelledInvoices(
        invoice && invoice.filter(
          (inv) => (inv.status != null && inv.status === "Cancelled" ? inv.status : "")
          .toLowerCase()
        )
      );
    } else {
      setOpenCreditNoteList(
        openCreditNoteList &&
          openCreditNoteList.filter(
            (inv) =>
              (inv.credit_note_number.toLowerCase().includes(globalSearchString.toLowerCase()) ||
              (inv.date != null ? inv.date : "")
                .toLowerCase()
                .includes(globalSearchString.toLowerCase()) ||
              (inv.id != null ? inv.id : "")
                .toLowerCase()
                .includes(globalSearchString.toLowerCase()))
          )
      );

      setCloseCreditNoteList(
        closeCreditNoteList &&
          closeCreditNoteList.filter(
            (inv) =>
              (inv.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
              (inv.date != null ? inv.date : "")
                .toLowerCase()
                .includes(globalSearchString.toLowerCase()) ||
              (inv.id != null ? inv.id : "")
                .toLowerCase()
                .includes(globalSearchString.toLowerCase()))
          )
      );

      setVoidCreditNoteList(
        voidCreditNoteList &&
          voidCreditNoteList.filter(
            (inv) =>
              (inv.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
              (inv.date != null ? inv.date : "")
                .toLowerCase()
                .includes(globalSearchString.toLowerCase()) ||
              (inv.id != null ? inv.id : "")
                .toLowerCase()
                .includes(globalSearchString.toLowerCase()))
          )
      );

      setCancelledInvoices(
        invoice &&
          invoice.filter(
            (inv) =>
              (inv.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
              (inv.date != null ? inv.date : "")
                .toLowerCase()
                .includes(globalSearchString.toLowerCase()) ||
              (inv.id != null ? inv.id : "")
                .toLowerCase()
                .includes(globalSearchString.toLowerCase())) &&
              (inv.status != null && inv.status === "Cancelled" ? inv.status : "")
                .toLowerCase()
          )
      );
    }
  }, [globalSearchString, creditNoteList]);
  
  useEffect(() => {
        getCreditNotesList();
        
  }, [projectId, taskFilters, savedDate]);
  const getCreditNotesList = () => {
    let selectedDate =  moment(savedDate).format("YYYY-MM-DD");
    
      DocumentsServices.getAllCreditNotes(projectId, taskFilters, selectedDate, clientid).then((res) => {
      if (res.status == 1) {
        setCreditNoteList(
          res.data.map((credit_note) => {
            credit_note.name = credit_note.credit_note_number;
            return credit_note;
          })
        );
        let data = res.extra;
          dispatch({
            type: SUBSCRIPTION_COUNTER,
            payload: { data }
          });
      } else {
        setCreditNoteList([]);
        let data = res.extra;
          dispatch({
            type: SUBSCRIPTION_COUNTER,
            payload: { data }
          });
      }
    });
  };
    const handleViewMore = (section) => {
        switch (section) {
          case "open":
            setOpenCreditNoteListPage(openCreditNoteListPage + 1);
            break;
            case "close":
              setCloseCreditNoteListPage(closeCreditNoteListPage + 1);
              break;
              case "void":
                setVoidCreditNoteListPage(voidCreditNoteListPage + 1);
                break;
                case "client_creditnote":
                  setCreditNoteListPage(creditNoteListPage + 1);
                  break;
          default:
            break;
        }
      };
      const invoiceCardHandler = async (invoice, flag = 1) => {
        setShowTaskModal(false);
        if (
          documentCardDetails &&
          documentCardDetails.type === "invoice" &&
          documentCardDetails.id == invoice.id
        ) {
          if (flag) {
            setShowDocumentCanvas(false);
            setDocumentCardDetails();
          }
        } else {
          try {
            // const details = await fetchDocumentDetails("invoice", invoice.id);
            // setDocumentDetail(details.data);
            dispatch(
              getInvoiceDetail(projectId, invoice.id, invoice.is_app_invoice)
            );
            setDocumentCardDetails({ ...invoice, type: "invoice" });
            setShowDocumentCanvas(true);
          } catch (e) {
            dispatch(
              showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
            );
            console.error(e);
          }
        }
      };
      const details = useSelector((state) => {
        switch (selectDocument) {
          case "invoice":
            return state.documentsReducer.invoice_details;
          case "subscription":
            return state.documentsReducer.subscription_details;
          case "estimate":
            return state.documentsReducer.estimate_details;
          case "credit_note":
            return state.documentsReducer.credit_note_details;
          case "document":
            return state.documentsReducer.document_details;
          case "spaceDocument":
            return state.customer.getFloorDocument;
          default:
            break;
        }
      });
    
      useEffect(() => {
        if (details) {
          setDocumentDetail(details);
        }
      }, [documentDetail, details]);
      const creditNoteCardHandler = (note, flag = 1) => {
        setShowTaskModal(false);
        if (
          documentCardDetails &&
          documentCardDetails.type === "credit_note" &&
          documentCardDetails.id == note.id
        ) {
          if (flag) {
            setShowDocumentCanvas(false);
            setDocumentCardDetails();
          }
        } else {
          try {
            dispatch(getCreditNoteDetail(projectId, note.id));
            setDocumentCardDetails({ ...note, type: "credit_note" });
            setShowDocumentCanvas(true);
          } catch (e) {
            dispatch(
              showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
            );
            console.error(e);
          }
        }
      };
    const handlePinUnPin = (id, listName, flag = 1) => {
        let itemPosition = -1;
        let updateInvoice = [];
        switch (listName) {
          case "client_creditnote":
            itemPosition = creditNoteList.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...creditNoteList,
              [itemPosition]: {
                ...creditNoteList[itemPosition],
                pinned: creditNoteList[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setCreditNoteList(updateInvoice);
            invoiceCardHandler(updateInvoice[itemPosition], flag);
            break;

          case "open":
            itemPosition = openCreditNoteList.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...openCreditNoteList,
              [itemPosition]: {
                ...openCreditNoteList[itemPosition],
                pinned: openCreditNoteList[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setOpenCreditNoteList(updateInvoice);
            invoiceCardHandler(updateInvoice[itemPosition], flag);
            break;

          case "close":
            itemPosition = closeCreditNoteList.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...closeCreditNoteList,
              [itemPosition]: {
                ...closeCreditNoteList[itemPosition],
                pinned: closeCreditNoteList[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setCloseCreditNoteList(updateInvoice);
            invoiceCardHandler(updateInvoice[itemPosition], flag);
            break;

          case "void":
            itemPosition = voidCreditNoteList.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...voidCreditNoteList,
              [itemPosition]: {
                ...voidCreditNoteList[itemPosition],
                pinned: voidCreditNoteList[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setVoidCreditNoteList(updateInvoice);
            invoiceCardHandler(updateInvoice[itemPosition], flag);
            break;
        
        }
        if (itemPosition > -1) {
          let tempDocumentDetail = documentDetail;
          if (tempDocumentDetail && Object.keys(tempDocumentDetail).length) {
            tempDocumentDetail["pinned"] =
              tempDocumentDetail["pinned"] == 1 ? 0 : 1;
            setDocumentDetail(tempDocumentDetail);
          }
        }
      };
    return (
        <>
          {isLoadingData("document_list") ? (
            <CommanLoader />
          ) : (
            <>
              <div
                id="list_view"
                className={`comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian ${
                  showTaskModal || showReqestedTaskModal || showDocumentCanvas
                    ? "list-view-toggle"
                    : ""
                }`}
              >
                <Accordion
                  defaultActiveKey={["0","01","02","03","04"]}
                  alwaysOpen
                  className="task-list-accordian-main"
                >
                  <div className="pill-wrapper">
                    <div id="" className="">
                      <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                        <div className="badge  c-font f-12 color-white-80 bg-white-05  radius_3 me-2 d-none">
                          <div className="d-flex align-items-center">
                            <span className="color-white"></span>
                            <a href="#/" className="ps-1">
                              <X size={14} weight="light" className="c-icons" />
                            </a>
                          </div>
                        </div>
                        {taskFilters.length ? (
                          taskFilters.map((value, index) => {
                            if (value.main_key == "date_range_filter") {
                              jquery("body #active_week_date").removeClass("c-gradinet-active-border");
                              let displayFilterKey = value.title;
                              return (
                                <div
                                  key={index}
                                  className="badge c-font f-12 w-auto color-white-80 bg-white-05  radius_3 ms-2 comman-round-box active py-1"
                                >
                                  <div className="d-flex align-items-center">
                                    {/* <span className="color-white">{`${filter_key[0].toUpperCase()}${
                                    filter_key.length > 1
                                      ? filter_key.slice(1).split("_").join(" ")
                                      : ""
                                  }`}</span> */}
                                    <span className="color-white">
                                      {displayFilterKey}
                                    </span>
                                    <a href="#/"
                                      className="ps-1"
                                      onClick={() => {
                                        dispatch(
                                          leftPanelFilters({
                                            main_key: value.main_key,
                                            filter_key: value.filter_key,
                                            title: value.title,
                                            main_sub_key: value.main_sub_key,
                                          })
                                        );
                                        // dispatch(
                                        //   projectTaskCounts(projectId, taskFilters, {
                                        //     main_key: value.main_key,
                                        //     filter_key: value.filter_key,
                                        //     title: value.title,
                                        //   })
                                        // );
                                        // dispatch(
                                        //   projectTaskCounts(
                                        //     projectId,
                                        //     taskFilters,
                                        //     '')
                                        // );
                                        // jquery(
                                        //   ".fixed-left-panel.provider-list"
                                        // ).addClass("expanded");
                                        // jquery("body").addClass("body-toggle");
                                        // jquery(".after-login-header").addClass(
                                        //   "body-toggle"
                                        // );
                                        // jquery("footer").addClass("body-toggle");
                                      }}
                                    >
                                      <X size={14} className="c-icons" weight="light" />
                                    </a>
                                  </div>
                                </div>
                              );
                            }
                          })
                        ) : (
                          <></>
                        )}
                        <div className={`d-flex radius_3 justify-content-between commn-search ${pageName == 'customer' ? 'd-none' : ""}`}>
                          <div className="comment-input-box">
                            <input
                              type="text"
                              className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                              placeholder={`${_l("l_search_documents")}`}
                              onChange={(e) => {
                                setSearchString(e.target.value);
                              }}
                              value={searchString}
                            />
                          </div>
                          <a href="#/" className="comman_action_icon">
                            <div className="d-flex align-items-center ">
                              <div className="action_icon h25w25 d-none">
                                <X
                                  size={14}
                                  weight="light"
                                  className="c-icons m-auto filter-img"
                                />
                              </div>
                              <div className="action_icon h25w25">
                                <MagnifyingGlass
                                  size={16}
                                  weight="light"
                                  className="c-icons MagnifyingGlassIcon"
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {pageName == 'customer' ?
                  <Accordion.Item eventKey="0" className="with-pb-15 ">
                  <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                  <p className="mb-0"><br></br></p>
                  </div>
                  <Accordion.Body className="taskCountToggle">
                  {creditNoteList && creditNoteList.length ? (
                      creditNoteList
                      .slice(
                          0,
                          creditNoteListPage > 1
                          ? (creditNoteListPage - 1) * 10 + 11
                          : creditNoteListPage * 11
                      )
                      .map((note, index) => {
                          return (
                          <CardView
                              hideShow={() => {
                              creditNoteCardHandler(note);
                              setSelectedId(note.id);
                              setSelectDocument("credit_note");
                              }}
                              docType={"credit_note"}
                              setDocType={setDocType}
                              pinHandler={(id) => {
                              handlePinUnPin(id, "client_creditnote");
                              }}
                              User_2={""}
                              data={note}
                              selectedId={selectedId}
                              key={index}
                          />
                          );
                      })
                  ) : (
                      <></>
                  )}
                  {creditNoteList.length >=
                      (creditNoteListPage - 1) * 10 + 11 &&
                  creditNoteList.length !=
                      (creditNoteListPage - 1) * 10 + 11 ? (
                      <div className="d-flex justify-content-center">
                      <a href="#/"
                          className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                          onClick={() => {
                          handleViewMore("client_creditnote");
                          }}
                      >
                          <span className="title-fonts d-flex justify-content-center align-items-center">
                          <div className="d-flex align-item-center justify-content-center">
                              {_l("l_view_more_btn")}(
                              {creditNoteListPage > 1
                              ? creditNoteList.length -
                                  ((creditNoteListPage - 1) * 10 + 11)
                              : creditNoteList.length - 11}
                              )
                              <CaretDoubleDown
                              size={12}
                              weight="light"
                              className="c-icons mx-2"
                              />
                              <span
                              role="status"
                              aria-hidden="true"
                              className="ms-2 opacity-0 spinner-border spinner-border-sm"
                              ></span>
                          </div>
                          </span>
                      </a>
                      </div>
                  ) : (
                      <></>
                  )}
                  {creditNoteList.length == 0 ? (
                  <CommanPlaceholder imgType="no-credit-notes" isAbsolute={true} placeholderText = {_l("l_no_credit_notes")} />
                  ) : (
                      <></>
                  )}
                  {/* <CommanOffCanvas show={showDocumentCanvas} handleClose={() => {setShowDocumentCanvas(false)}} docType={"dummy3"}/> */}
                  {/* <ViewMoreButton /> */}
                  </Accordion.Body>
                </Accordion.Item>
                :
                <>
                <Accordion.Item eventKey="0" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active done accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 done rounded-circle me-2"></span>
                            <span className="task-title me-2">
                              {_l("l_open")}
                            </span>
                            <span className="">
                            [{openCreditNoteList ? openCreditNoteList.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {openCreditNoteList && openCreditNoteList.length ? (
                        openCreditNoteList
                        .slice(
                            0,
                            openCreditNoteListPage > 1
                            ? (openCreditNoteListPage - 1) * 10 + 4
                            : openCreditNoteListPage * 4
                        )
                        .map((note, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                creditNoteCardHandler(note);
                                setSelectedId(note.id);
                                setSelectDocument("credit_note");
                                }}
                                docType={"credit_note"}
                                setDocType={setDocType}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "open");
                                }}
                                User_2={""}
                                data={note}
                                selectedId={selectedId}
                                key={index}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {openCreditNoteList.length >=
                        (openCreditNoteListPage - 1) * 10 + 4 &&
                    openCreditNoteList.length !=
                        (openCreditNoteListPage - 1) * 10 + 4 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("open");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {openCreditNoteListPage > 1
                                ? openCreditNoteList.length -
                                    ((openCreditNoteListPage - 1) * 10 + 4)
                                : openCreditNoteList.length - 4}
                                )
                                <CaretDoubleDown
                                size={12}
                                weight="light"
                                className="c-icons mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {openCreditNoteList.length == 0 ? (
                    <CommanPlaceholder imgType="no-credit-notes"  placeholderText = {_l("l_no_credit_notes")} />
                    ) : (
                        <></>
                    )}
                    {/* <CommanOffCanvas show={showDocumentCanvas} handleClose={() => {setShowDocumentCanvas(false)}} docType={"dummy3"}/> */}
                    {/* <ViewMoreButton /> */}
                    </Accordion.Body>
                  </Accordion.Item>
                    <Accordion.Item eventKey="01" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active red accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 bg-danger rounded-circle me-2"></span>
                            <span className="task-title me-2">
                            {_l("l_closed")}
                            </span>
                            <span className="">
                            [{closeCreditNoteList ? closeCreditNoteList.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {closeCreditNoteList && closeCreditNoteList.length ? (
                        closeCreditNoteList
                        .slice(
                            0,
                            closeCreditNoteListPage > 1
                            ? (closeCreditNoteListPage - 1) * 10 + 4
                            : closeCreditNoteListPage * 4
                        )
                        .map((note, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                creditNoteCardHandler(note);
                                setSelectedId(note.id);
                                setSelectDocument("credit_note");
                                }}
                                docType={"credit_note"}
                                setDocType={setDocType}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "close");
                                }}
                                User_2={""}
                                data={note}
                                selectedId={selectedId}
                                key={index}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {closeCreditNoteList.length >=
                        (closeCreditNoteListPage - 1) * 10 + 4 &&
                    closeCreditNoteList.length !=
                        (closeCreditNoteListPage - 1) * 10 + 4 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("close");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {closeCreditNoteListPage > 1
                                ? closeCreditNoteList.length -
                                    ((closeCreditNoteListPage - 1) * 10 + 4)
                                : closeCreditNoteList.length - 4}
                                )
                                <CaretDoubleDown
                                size={12}
                                weight="light"
                                className="c-icons mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {closeCreditNoteList.length == 0 ? (
                    <CommanPlaceholder imgType="no-credit-notes"  placeholderText = {_l("l_no_credit_notes")} />
                    ) : (
                        <></>
                    )}
                    {/* <CommanOffCanvas show={showDocumentCanvas} handleClose={() => {setShowDocumentCanvas(false)}} docType={"dummy3"}/> */}
                    {/* <ViewMoreButton /> */}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="02" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active to-do accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 in-progress-white rounded-circle me-2"></span>
                            <span className="task-title me-2">
                            {_l('l_void')}
                            </span>
                            <span className="">
                            [{voidCreditNoteList ? voidCreditNoteList.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {voidCreditNoteList && voidCreditNoteList.length ? (
                        voidCreditNoteList
                        .slice(
                            0,
                            voidCreditNoteListPage > 1
                            ? (voidCreditNoteListPage - 1) * 10 + 4
                            : voidCreditNoteListPage * 4
                        )
                        .map((note, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                creditNoteCardHandler(note);
                                setSelectedId(note.id);
                                setSelectDocument("credit_note");
                                }}
                                docType={"credit_note"}
                                setDocType={setDocType}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "void");
                                }}
                                User_2={""}
                                data={note}
                                selectedId={selectedId}
                                key={index}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {voidCreditNoteList.length >=
                        (voidCreditNoteListPage - 1) * 10 + 4 &&
                    voidCreditNoteList.length !=
                        (voidCreditNoteListPage - 1) * 10 + 4 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("void");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {voidCreditNoteListPage > 1
                                ? voidCreditNoteList.length -
                                    ((voidCreditNoteListPage - 1) * 10 + 4)
                                : voidCreditNoteList.length - 4}
                                )
                                <CaretDoubleDown
                                size={12}
                                weight="light"
                                className="c-icons mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {voidCreditNoteList.length == 0 ? (
                    <CommanPlaceholder imgType="no-credit-notes"  placeholderText = {_l("l_no_credit_notes")} />
                    ) : (
                        <></>
                    )}
                    {/* <CommanOffCanvas show={showDocumentCanvas} handleClose={() => {setShowDocumentCanvas(false)}} docType={"dummy3"}/> */}
                    {/* <ViewMoreButton /> */}
                    </Accordion.Body>
                  </Accordion.Item>
                </>}
                </Accordion>
                {showDocumentCanvas ? (
            <CommanOffCanvas
              show={showDocumentCanvas}
              handleClose={() => {
                setShowDocumentCanvas(false);
                setDocumentCardDetails();
                setDocumentDetail();
                setSelectedId(0);
              }}
              pinHandler={(id, type, flag) => {
                handlePinUnPin(id, type, flag);
              }}
              data={documentDetail}
              setData={setDocumentDetail}
              docType={'credit_note'}
              setSelectedId={setSelectedId}
            />
          ) : (
            <></>
          )}
              </div>
          </>
          )}
          </>
          
    )
                      
}
export default CreditNotesList;