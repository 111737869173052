import React from "react";
import Lightbox from "yet-another-react-lightbox";
import LogoGreenDark from "../../assets/icons/LogoGreenDark";
import LogoGreenLight from "../../assets/icons/LogoGreenLight";
import { CloudArrowDown, Funnel } from "phosphor-react";

const DocumentPreviewerHeader = () => {
  return (
    <div className="position-sticky top-0 w-100 ">
      <LogoGreenDark className="header-logo-img p-2 max-w-130px h-auto " />
    </div>
  );
};

const RenderCustomDocument = ({ slide }) => {
  if (slide.type === "video") {
    return (
      <video controls>
        <source src={slide.src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  } else if (slide.type === "pdf") {
    return (
      <iframe src={slide.src} width="100%" height="90%" title="PDF Preview" />
    );
  } else {
    return (
      // <div className="h-100 w-100" style={{maxHeight:"90%",maxWidth:"90%"}}>
      //   <div className="h-100 w-100 bg-style-cover" style={{backgroundImage: `url(${slide.src})`}}></div>
      // </div>
      <img className="h100minus80px bg-style-cover" src={slide.src} alt="" />
    );
  }
};

const DocumentPreview = ({ slides, open, setOpen }) => {

  const selectedTheme = localStorage.getItem("selectedTheme");

  return (
    <>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={slides}
        render={{
          slide: ({ slide }) => (
            <>
              <div
                className={`header-logo position-absolute top-0 start-0 z-index-2 w-100 ms-1`}
              >
                <div className="d-flex align-items-center justify-content-between position-relative">
                  {selectedTheme == "light" ? (
                    <LogoGreenLight className="header-logo-img p-2 max-w-130px h-auto " />
                  ) : (
                    <LogoGreenDark className="header-logo-img p-2 max-w-130px h-auto " />
                  )}
                  {
                    slide.download_url
                    ?
                    <a href="#/" className="downloadarrow position-absolute right50 h32w32" onClick={() => window.open(slide.download_url, "_blank")}>
                      <CloudArrowDown
                        size={28}
                        className="c-icons"
                        weight="light"
                      />
                    </a>
                    :
                    <React.Fragment></React.Fragment>
                  }
                </div>
              </div>
              <RenderCustomDocument slide={slide} />
            </>
          ),
          // slideHeader: <DocumentPreviewerHeader />
        }}
        carousel={{ preload: 1, padding: 0 }}
      />
    </>
  );
};

export default DocumentPreview;
