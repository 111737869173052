import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  getTaskCount,
  setTaskData,
  projectTaskCounts,
  setcreateTaskReqInputIcons,
  setCreateTaskModalIsopen
} from "../../actions/customer";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import {  X, UploadSimple } from "phosphor-react";
import { _l, showError, tabindexFix } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/esm/Spinner";
import MeetingProviderModal from "../Modals/MeetingProviderModal";
import BenchmarkServices from "../../services/benchmark-services";
import CreateChecklistTemplate from "../Modals/CreateChecklistTemplate";
import jquery from "jquery";
import CreateTaskOffCanvasNew from "./CreateTaskOffCanvasNew";

let spaceSelected = false;

const CreateTaskOffcanvas = ({ handleClose, provider = "", is_fromCalendar , createtaskDate , isFromLiteVersion, isRequestTask,  pageName}) => {
  const {
    selectedTask,
    selectedProject,
    taskFilters,
    defaultTopics,
    attachmentsForTask,
  } = useSelector((state) => state.customer);
  const {floorDetails} = useSelector((state) => state.benchmarkReducer);

  const dispatch = useDispatch();


  const userType = localStorage.getItem("user_type");
  const virtualSpaces = localStorage.getItem("selectedVirtualSpace");
  const [taskTitle, setTaskTitle] = useState(""); 
  const [newChecklistItemDescription, setNewChecklistItemDescription] =
    useState("");
  const [checklistItems, setChecklistItems] = useState([]);
  const [visibleToCustomer, setVisibleToCustomer] = useState(false);
  const [loder, setLoder] = useState(false);
  const [taskAssignee, setTaskAssignee] = useState([]);
  const [taskAssigneePills, setTaskAssigneePills] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState([]);
  const [selectedAssigneeIds, setSelectedAssigneeIds] = useState([]);
  const [showTaskAssignee, setShowTaskAssignee] = useState(0);
  const [meetingModal, setMeetingModal] = useState(false);
  const [meetingLink, setMeetingLink] = useState("");
  const [visibleToProvider, setVisibleToProvider] = useState(false);
  const link = useSelector((state) => state.customer.get_link);
  const [attachmentsArray, setAttachmentsArray] = useState([]);
  const [previewArray, setPreviewArray] = useState([]);
  const fileInputRef = useRef();
  const [isAttachmentTask, setIsAttachmentTask] = useState(false);
  const [attchmentId, setAttchmentId] = useState(0);
  const [attachmentTaskId, setAttachmentTaskId] = useState(0);
  const [checklistMode, setChecklistMode] = useState("classic");
  const [selectedFloor, setSelectedFloor] = useState({ id: -1, title: "" });
  const [selectedTypeOfSpace, setSelectedTypeOfSpace] = useState({id: -1});
  const [showFloorList, setShowFloorList] = useState(false);
  const [dropDownToggle,setDropDownToggle]=useState(false);
  const [isModalOpen,setIsModalOpen]=useState(true);
  const [MainKeYArray,seMainKeYArray]=useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  const [inputActionIconShow, setinputActionIconShow] = useState(true);
  const [showChecklistTemplateModal, setShowChecklistTemplateModal] =
    useState(false);

  useEffect(() => {
    if(link && link != [])
    {
      if(link.data.newTaskFlag == 1)
      {
        handleCreateTask(link.data.link);
      }
    }
  }, [link])


  
  useEffect(() => {
    if (selectedFloor.id > 0) {
      floorDetails.type_of_space.map((spaceItem) => {
        return spaceItem.details.length > 0 && spaceItem.details.filter((check) => check.floor_id == selectedFloor.id).map((details, index) => {
            if (index == 0) {
              setSelectedTypeOfSpace(details);
              jquery("#checklistCreate").focus();
            }
        })
      })
    }
  },[JSON.stringify(selectedFloor),floorDetails])

  useEffect(() => { }, [selectedTask]);

  useEffect(() => {
    if (attachmentsForTask !== undefined) {
      setPreviewArray([{ name: attachmentsForTask.image_url, url: attachmentsForTask.image_url }])
      setIsAttachmentTask(true)
      setAttchmentId(attachmentsForTask.id)
      setAttachmentTaskId(attachmentsForTask.task_id)
    }
  }, [attachmentsForTask])

  const handleFileUpload = (files) => {
    if (files) {
      let thumbnails = [];
      let newFiles = [];
      Array.from(files).map((img) => {
        thumbnails.push({ name: img.name, url: URL.createObjectURL(img) });
        newFiles.push(img);
      });

      if (attachmentsArray && Array.from(attachmentsArray).length) {
        /**Remove duplicate attachments from array */
        newFiles = Array.from(newFiles).filter(
          (x) =>
            Array.from(attachmentsArray).findIndex((w) => w.name == x.name) ===
            -1
        );
        thumbnails = thumbnails.filter(
          (x) => previewArray.findIndex((w) => w.name == x.name) === -1
        );

        setAttachmentsArray(Array.from(attachmentsArray).concat(newFiles));
        setPreviewArray(previewArray.concat(thumbnails));
      } else {
        setAttachmentsArray(files);
        setPreviewArray(thumbnails);
      }
    }
  };

  const removeAttachment = (ref) => {
    let currentFiles = attachmentsArray;
    currentFiles = Array.from(currentFiles).filter(
      (file) => file.name !== ref.name
    );
    setAttachmentsArray(currentFiles);
    setPreviewArray(previewArray.filter((x) => x.name !== ref.name));
    fileInputRef.current.value = null;
  };


  const handleAddChecklistItem = (e) => {
    let checklistArr = e.target.value ? e.target.value.split("\n") : [`${newChecklistItemDescription}`];
    if (e.type == "paste") {
      checklistArr = e.clipboardData.getData('Text').split("\n");
    }
    let newArr = checklistItems
    for (let i = 0; i < checklistArr.length; i++) {
      if (checklistArr[i].trim().length) {
        newArr.push({ description: checklistArr[i].trim(), finished: 0, floor_id: selectedFloor.id != - 1 ? selectedFloor.id : 0, type_of_space_item_id: selectedTypeOfSpace.id != -1 ? selectedTypeOfSpace.id : 0 });
      }
    }
    setChecklistItems(newArr);
    setTimeout(() => {
      setNewChecklistItemDescription("");
    }, 100);
  };
  const formValidations = () => {
    if (!taskTitle.trim().length) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_task_title_not_found"))
      );
      return false;
    }else if(checklistItems.length == 0) {
      let newArr = checklistItems
      newArr.push({ description: newChecklistItemDescription.trim(), finished: 0 });
      setChecklistItems(newArr);
      setTimeout(() => {
        setNewChecklistItemDescription("");
      }, 100);
    return true
    }
    return true;
  };

  const handleCreateTask = (link="") => {
    if (formValidations() && selectedProject) {
      setLoder(true);
      let taskStatus = 1;
      let checkedItems = checklistItems.filter((x) => x.finished);
      taskStatus =
        checkedItems.length > 0 && checkedItems.length === checklistItems.length
          ? 5
          : checkedItems.length > 0 &&
            checkedItems.length !== checklistItems.length
            ? 4
            : 1;
      let sub_categories = []
      let sub_category = selectedAssignee.filter((s) => s.main_key == "equipments")
      if (sub_category.length > 0) {
        sub_categories = sub_category.map((ss) => {
          return {
            main_key: ss.main_key,
            categories: ss.categories.map((sc) => {
                return {
                  filter_key: sc.filter_key,
                  categories: sc.sub_category.map((sc) => sc.id)
                }
            })
          }
        })
      }
      CustomerServices.createNewTask(selectedProject, 0, taskStatus, selectedAssignee.length ? selectedAssignee : [], link ,"" ,visibleToCustomer, visibleToProvider,sub_categories).then(
        (res) => {
          setLoder(false);
          if (res.status && res.data.task_id) {
            const taskId = res.data.task_id;
            // setTaskAssigneeData(selectedAssignee, taskId, selectedProject);
            dispatch(
              setTaskData(
                selectedProject,
                taskId,
                taskTitle,
                undefined,
                '',
                0,
                0,
                0,
                0,
                "",
                "",
                visibleToCustomer,
                visibleToProvider,
                isAttachmentTask,
                attchmentId,
                attachmentTaskId
              )
            ).then(() => {
              handleClose(taskId);
              dispatch(toastCloseAction());
            });
            if (provider && provider.filter_key) {
            }
            // checklistItems.forEach((item) => {
              CustomerServices.addChecklistItem(
                taskId,
                selectedProject,
                checklistItems,
              );
            // });
            dispatch(
              getTaskCount(
                "",
                "",
                selectedProject,
                moment().format("YYYY-MM-DD")
              )
            );
            dispatch(
              projectTaskCounts(
                localStorage.getItem("selectedOffice"),
                taskFilters ? taskFilters : [],
                "")
            );
            
          } else {
            dispatch(
              showMessage("unsucess", _l("l_error"), res.message)
            );
          }
        }
      );
    }
  };

  
  const modalCloseIconClick = () => {
    if (taskTitle.trim().length || checklistItems.length) {
      dispatch(
        showConfirmation(
          _l("l_confirm"),
          _l("l_want_to_save_task"),
          _l("l_yes"),
          _l("l_no"),
          handleClose,
          handleCreateTask,
          true
        )
      );
    } else {
      handleClose();
    }
    dispatch(setCreateTaskModalIsopen(false))

  };

  const handleRequestAssignee = (assigneeDetail) => {
    let taskAssign = selectedAssignee
    let taskAssignIds = selectedAssigneeIds
    setDropDownToggle(assigneeDetail.main_key);
      if (taskAssign && taskAssign.length > 0) {
        if (assigneeDetail.main_key == "equipments") {
          let Index = taskAssign.findIndex((e) => e.main_key == assigneeDetail.main_key);
          if (Index > -1) {
            let IndexT = taskAssign[Index]['categories'].findIndex((taskassign) => taskassign.filter_key == assigneeDetail.filter_key)
            if (IndexT > -1) {
              let IndexU = taskAssign[Index]['categories'][IndexT].sub_category.findIndex((taskassign) => taskassign.id == assigneeDetail.sub_category[0].id)
              if (IndexU > -1) {
                taskAssign[Index]['categories'][IndexT].sub_category = taskAssign[Index]['categories'][IndexT].sub_category.filter((taskassign) => taskassign.id != assigneeDetail.sub_category[0].id)
                // taskAssignIds = taskAssignIds.sub_category.filter(sa => sa.id != assigneeDetail.filter_key)
              }else{
                taskAssign[Index]['categories'][IndexT].sub_category.push(assigneeDetail.sub_category[0])
              }
            }else{
              taskAssign[Index]['categories'] = [{ filter_key: assigneeDetail.filter_key, sub_category: assigneeDetail.sub_category }]
            }
          }else{
              taskAssign.push({
                main_key: assigneeDetail.main_key,
                categories: [{ filter_key: assigneeDetail.filter_key, sub_category: assigneeDetail.sub_category }]
              })
          }
        } else {
        var index = taskAssign.findIndex((e) => e.main_key == assigneeDetail.main_key);
        if (index > -1) {
          let isExist = taskAssign[index] && taskAssign[index].categories.findIndex((taskassign) => taskassign.filter_key == assigneeDetail.filter_key)
          if (isExist > -1) {
            taskAssign[index]['categories'] = taskAssign[index]['categories'].filter((taskassign) => taskassign.filter_key != assigneeDetail.filter_key)
            taskAssignIds = taskAssignIds.filter(sa => sa.id != assigneeDetail.filter_key)
          } else {
            if (assigneeDetail.main_key == "provider_task") {
              const indexIds = taskAssignIds.findIndex(id=> id.name == "provider_task")
              const index = taskAssign.findIndex(id=> id.main_key == "provider_task")
              if (indexIds > -1) {
                taskAssignIds[indexIds] = {
                  name: assigneeDetail.main_key,
                  id: assigneeDetail.filter_key
                }
              }
              if (index > -1) {
                taskAssign[index].categories = [{ filter_key: assigneeDetail.filter_key }]
              }
            }else{
            taskAssign[index]['categories'].push({ filter_key: assigneeDetail.filter_key })
            taskAssignIds.push({
              name: assigneeDetail.main_key,
              id: assigneeDetail.filter_key
            })
          }
        }
        } else {
          taskAssign.push({
            main_key: assigneeDetail.main_key,
            categories: [{ filter_key: assigneeDetail.filter_key }]
          })
          taskAssignIds.push({
            name: assigneeDetail.main_key,
            id: assigneeDetail.filter_key
          })
        }
      }
      } else {
        if (assigneeDetail.main_key == "equipments") {
          taskAssign = [{
            main_key: assigneeDetail.main_key,
            categories: [{ filter_key: assigneeDetail.filter_key, sub_category: assigneeDetail.sub_category }]
          }]
          taskAssignIds = [{
            name: assigneeDetail.main_key,
            id: assigneeDetail.filter_key,
            sub_category: assigneeDetail.sub_category
          }]
        } else { 
          taskAssign = [{
            main_key: assigneeDetail.main_key,
            categories: [{ filter_key: assigneeDetail.filter_key }]
          }]
          taskAssignIds = [{
            name: assigneeDetail.main_key,
            id: assigneeDetail.filter_key
          }]
        }
      }
      setSelectedAssignee(taskAssign.slice())
      // setSelectedAssigneeIds(taskAssignIds)
  };

 
  useEffect(() => {
    let task_assignee = [];
    let task_assignee_pills = [];
    let task_assignee_ids = [];
      if (selectedAssignee.length > 0) {
          selectedAssignee.map((ta) => {
            ta.categories && ta.categories.map((taskAssign) => {
              task_assignee_ids.push({
                name: ta.main_key,
                id: taskAssign.filter_key,
              })
            if (
              ta.main_key == "myr_staff_task" ||
              ta.main_key == "my_people" ||
              ta.main_key == "task_topic"
            ) {
              defaultTopics &&
                defaultTopics.forEach((topic) => {
                  if (topic.main_key == ta.main_key) {
                    topic.categories.forEach((provider) => {
                      if (taskAssign.filter_key == provider.filter_key) {
                        task_assignee.push({
                          name: provider.title,
                          profile_image: provider.image,
                          id: provider.filter_key,
                        });
                      }
                    });
                  }
                });
            } else {
              defaultTopics &&
                defaultTopics.forEach((topic) => {
                  if (topic.main_key == ta.main_key) {
                    topic.categories.forEach((provider) => {
                      if (taskAssign.filter_key == provider.filter_key) {
                        if (topic.main_key != "equipments") {
                          task_assignee_pills.push({
                            name: provider.title,
                            id: provider.filter_key,
                            main_key:topic.main_key
                          });
                        }

                        let subCategory = []
                        provider.sub_category && provider.sub_category.forEach((sc) => {
                          taskAssign.sub_category && taskAssign.sub_category.forEach((dd) => {
                            if (sc.id == dd.id) {
                                let title = taskTitle
                                let tempTitle = title.split('-')
                                if (tempTitle[1]) {
                                  title = taskAssign.sub_category[taskAssign.sub_category.length - 1].name + " - " + tempTitle[1]
                                }else{
                                  if(!taskTitle.toLocaleLowerCase().includes(dd.name.toLocaleLowerCase())){
                                      title =  taskAssign.sub_category[taskAssign.sub_category.length - 1].name + `${taskTitle ? " - " : "" }` + taskTitle
                                    }
                                }
                                // if(taskAssign.sub_category.length == 1){
                                //   title =  dd.name + `${taskTitle ? "-" : "" }` + taskTitle
                                // } else if (taskAssign.sub_category.length > 1 && !taskTitle.toLocaleLowerCase().includes(provider.title.toLocaleLowerCase())) {
                                //   title = provider.title + " - " + taskTitle
                                // }
                                setTaskTitle(title)
                              subCategory.push(dd.id);
                              task_assignee_pills.push({
                                name: sc.name,
                                id: sc.id,
                                main_key:topic.main_key
                              });
                            }
                          })
                        });
                        if (subCategory.length == 0) {
                          task_assignee_ids = task_assignee_ids.filter((tas) => tas.name != "equipments")
                        }
                        task_assignee_ids.push({
                          id: provider.filter_key,
                          name: provider.title,
                          sub_category: subCategory
                        })
                      }
                    });
                  }
                });
            }
          });
        })
      }
    setTaskAssigneePills(task_assignee_pills);
    setTaskAssignee(task_assignee);
    setSelectedAssigneeIds(task_assignee_ids);
}, [JSON.stringify(selectedAssignee)]);
  

  useEffect(() => {
    let servicesArray = taskAssigneePills.filter((data)=>data.main_key == "provider_task");

    let changeTitle = false

    taskAssigneePills.map((ta) => {
      defaultTopics &&
        defaultTopics.forEach((topic) => {
          if (topic.main_key == ta.main_key) {
            topic.categories.forEach((provider) => {
              if (provider.title == taskTitle) {
                changeTitle = true;
              }
            });
          }  
        });
    })
    
    if (taskAssigneePills.length == 0 && taskTitle != "") {
      defaultTopics &&
        defaultTopics.forEach((topic) => {
          if (topic.main_key == "provider_task") {
            topic.categories.forEach((provider) => {
              if (provider.title == taskTitle) {
                changeTitle = true;
              }
            });
          }  
        })
    }
    if (taskTitle == "" || (servicesArray.length > 0 && changeTitle)  ) {
      servicesArray.map((data) => {
        setTaskTitle(data.name)
      })
    } else if(servicesArray.length == 0 && changeTitle)
    {
      setTaskTitle("")
    }
  }, [taskAssigneePills])



  const handleTaskAssigneeData = () => {
    setShowTaskAssignee(showTaskAssignee == 0 ? 1 : 0);
  };
  
  const cleanTaskAssingeesPills = () => {
    if (taskAssigneePills.length) {
      setTaskAssigneePills([])
    }
  }

  const cleanTaskAssingees = () => {
    if (taskAssignee.length) {
      setTaskAssignee([])
    }
  }

  const applyFloorbaseFilter = (floor) => {
    setSelectedFloor(floor);
    setShowFloorList(false);
  };

  const getTypeOfSpaceCount = (id) => {
    let spaceItemsArray = [];
    floorDetails && floorDetails.type_of_space.map((e) =>{
      return e.details && e.details.length > 0 && e.details.map((s) => {
        if (s.floor_id == id) {
          spaceItemsArray.push(s);
        }
      })
    })
    return spaceItemsArray.length;
  }

  const getFloorwiseChecklistCount = (id) => {
    return checklistItems.filter((w) => w.floor_id == id).length;
  }

  const getChecklistAttachmentCount = (id) => {
    //To be implemented
    return 0;
  }

  const clearFloorFilter = () => {
    setSelectedFloor({ id: -1, title: "" });
    setShowFloorList(true);
    setSelectedTypeOfSpace({ id: -1, title: "" });
    setChecklistItems(checklistItems);
  }

  const switchToClassicView = (e) => {
    setChecklistMode(e.target.value); 
    setShowFloorList(false); 
    setSelectedFloor({...selectedFloor, id: -1, title: ""})
  }

  const saveTemplate = async(title) => {
    try {
      setShowChecklistTemplateModal(false);
      if (templatesList.find((x) => x.title && x.title.trim() == title.trim())) {
        showError("l_template_already_exists");
      } else if (title && title.trim().length) {
        let templateChecklistItems = checklistItems.slice();
        let formattedChecklistArray = [];

        if (templateChecklistItems && templateChecklistItems.length) {
          templateChecklistItems.forEach((checklist) => {
            if (checklist.description) {
              formattedChecklistArray.push({
                id: checklist.id || 0,
                floor_id: checklist.floor_id,
                checklist: [
                  {
                    name: checklist.description,
                    photo: 0,
                    selected: 1,
                    addedFromTemplate: false,
                    unique_id: checklist.id,
                    is_default_checklist: false,
                    tasks_per_month: 1,
                  },
                ],
                type_of_space_item_id: checklist.type_of_space_item_id,
              });
            }
          });
        }
        if (formattedChecklistArray.length) {
          const response = await BenchmarkServices.createChecklistTemplate(
            selectedProject,
            0,
            title.trim(),
            formattedChecklistArray,
            1
          );
          if (response.status && response.data.inserted_template_id) {
            setTemplatesList(
              templatesList.concat([
                { title: title.trim(), id: response.data.inserted_template_id },
              ])
            );
          } else {
            showError("l_something_went_wrong");
          }
        }
      } else {
        showError("l_please_enter_template_title");
      }
    }
    catch(e)
    {

    }
  }

  const copyTemplateChecklist = async (template) => {
    try {
      const response = await BenchmarkServices.getTemplateChecklistItems(
        selectedProject,
        0,
        template.value,
        1
      );
      if (response.status && response.data.length) {
        let templateItems = response.data.slice();
        let currentChecklistItems = checklistItems.slice();
        templateItems.forEach((x) => {
          if (
            !currentChecklistItems.find(
              (w) =>
                w.description &&
                w.description.trim().toLowerCase() ==
                  x.description.trim().toLowerCase() &&
                x.type_of_space_item_id == w.type_of_space_item_id
            )
          ) {
            currentChecklistItems.push({
              ...x,
              type_of_space_item_id: x.type_of_space_item_id,
              floor_id: x.floor_id,
              id: 0,
            });
          }
        });
        

        currentChecklistItems = currentChecklistItems.filter(
          (obj, index, self) =>
            index ===
            self.findIndex(
              (o) =>
                (obj.room_id == o.room_id &&
                  obj.type_of_space_item_id == o.type_of_space_item_id &&
                  obj.description == o.description) ||
                (obj.description == "" && o.description == "")
            )
        );

        currentChecklistItems.sort((a, b) => {
          if (a.description === "" && b.description !== "") {
            return 1;
          }
          if (a.description !== "" && b.description === "") {
            return -1;
          }
          return 0;
        });
        setChecklistItems(currentChecklistItems);
      }
    } catch (e) {
      console.error(e);
    }
  };  
const [show ,setShow] = useState(false);
  const handleCloseTask = () => {
    if (show) {
      setShow(false)
    } else {
      modalCloseIconClick()
    }
  }
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={handleCloseTask}
        className="custom-modal-style overflow-hidden with create-task-absolute-mobile-liteversion"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onShow={() => {
          tabindexFix();
        }}
        backdrop="static"
        keyboard={false}
        enforceFocus={false}
        container={document.getElementById("root")}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{_l("l_create_task")}</Modal.Title>
          

        <div className="d-flex justify-content-end flex-grow-1">

          {meetingModal? 
            <MeetingProviderModal meetingProvider={meetingModal} HandleClose={() =>{setMeetingModal(false)}} newTaskFlag={1}/>
          : <></>}
          {userType == "staff" && !virtualSpaces ? (
          <>
            <div className="align-items-center before-dnone color-white-60 d-flex pe-0 with_separator_10">
              <span>{_l("l_visible_for")}</span> <span className="ps-2">:</span>
            </div>
            <div className={`with_separator_10 align-items-center d-flex
                  }`}>
              <Form.Check
                className="align-items-center d-flex "
                type="checkbox"
                id=""
                onChange={(e) => {
                  setVisibleToCustomer(e.target.checked);
                }}
                label="Customer"
              />
            </div>
            <div className={`with_separator_10 align-items-center d-flex 
                  }`}>
              <Form.Check
                className="align-items-center d-flex "
                type="checkbox"
                label={_l("l_provider")}
                onChange={(e) => {
                  setVisibleToProvider(e.target.checked);
                }}
              />
            </div>
          </>)
            :
            (<></>)}
        </div>
        </Modal.Header>
        <Modal.Body className="with-overflow pt-0 p-0 p-20px overflow-visible d-flex flex-column">
          <div className="d-flex flex-column flex-grow-1 ">
           <CreateTaskOffCanvasNew 
           taskTitle={taskTitle}
           setTaskTitle={setTaskTitle}
           visibleToCustomer={visibleToCustomer}
           visibleToProvider={visibleToProvider}
           handleClose={handleClose}
           isRequest={isRequestTask}
           is_liteversion={false}
           is_task={is_fromCalendar ? false : true}
           show={show} 
           setShow={setShow}
           fromModal = {true}
           is_fromCalendar={is_fromCalendar}
           createtaskDate={createtaskDate}
           isFromLiteVersion = {is_fromCalendar ?  isFromLiteVersion : false}
           pageName={pageName}
           />          
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-between d-none">
        <div className={`d-flex flex-wrap upload-image-preview ${previewArray.length > 0 ? "" : "d-none"}`}>
            {previewArray.map((thumb, index) => {
              return (
                <div
                  key={index}
                  className="comman-image-box h50w50 radius_3 upload-image m-2"
                >
                  <div
                    className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                    style={{
                      backgroundImage: `url('${thumb.url}')`,
                    }}
                  ></div>
                  <a href="#/"
                    className="upload-close"
                    onClick={() => {
                      removeAttachment(thumb);
                    }}
                  >
                    <X size={10} weight="light" className="c-icons" />
                  </a>
                </div>
              );
            })}
          </div>
          <div className="request-image-wrapper w-100">
            <div className="d-flex align-items-center justify-content-between">
              <div className="upload-image-wrapper-main">
                <div className="d-flex align-items-center">
                  <div
                    className="comman_action_icon d-flex position-relative"
                    data-tip={`${_l("l_upload_file")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                  >
                   <Form.Control
                      className="  hidden-input"
                      type="file"
                      ref={fileInputRef}
                      multiple
                      accept="image/*"
                      onChange={(e) => {
                        handleFileUpload(e.target.files);
                      }}
                    />
                    <div className="d-flex align-items-center w-100">
                      <div className="action_icon h35w35 with_bg">
                        <UploadSimple size={18} weight="light" className="c-icons" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                {
                  checklistItems.length
                  ?
                    <Button className="mx-1" variant="white-05 border-0"  onClick={() => {
                      setShowChecklistTemplateModal(true);
                    }}>
                      {_l("l_save_as_template")}
                    </Button>
                  :
                    <React.Fragment></React.Fragment>
                }
                <Button
                  variant="primary" className="ms-2"
                  onClick={() => {
                    if (!loder) {
                      handleCreateTask();
                    }
                  }}
                >
                  {loder
                    ? _l('l_please_wait')
                    : _l('l_create')
                  }
                  {
                    loder ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        className={`ms-2 `}
                        aria-hidden="true"
                      />
                    ) :
                      <></>
                  }
                </Button>
              </div>
            </div>
          </div>

        </Modal.Footer>
      </Modal>
      {showChecklistTemplateModal ? (
        <CreateChecklistTemplate
          handleClose={() => {
            setShowChecklistTemplateModal(false);
          }}
          handleSave={saveTemplate}
          show={showChecklistTemplateModal}
          scheduleDetail={{
            categoryTitle: "",
            benchmarkId: _l("l_task"),
          }}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

export default CreateTaskOffcanvas;
