export const GET_LEAD_LIST = "GET_LEAD_LIST";
export const GET_SPECIFIC_LEAD_DETAIL = "GET_SPECIFIC_LEAD_DETAIL";
export const HOT_LEAD_SET_UNSET = "HOT_LEAD_SET_UNSET";
export const CHANGE_LEAD_STATUS = "CHANGE_LEAD_STATUS";
export const PIN_UNPIN_LEAD = "PIN_UNPIN_LEAD";
export const ADD_LEAD_ATTACHMENTS = "ADD_LEAD_ATTACHMENTS";
export const REMOVE_LEAD_ATTACHMENTS = "REMOVE_LEAD_ATTACHMENTS";
export const ADD_LEAD_REMINDER = "ADD_LEAD_REMINDER";
export const GET_LEAD_ACTIVITIES = "GET_LEAD_ACTIVITIES";
export const ADD_LEAD_COMMENT = "ADD_LEAD_COMMENT";
export const GET_LEAD_NOTES_LIST = "GET_NOTELEAD_S_LIST";
export const ADD_EDIT_LEAD_NOTE = "ADD_LEAD_NOTE_EDIT";
export const DELETE_LEAD_NOTE = "DELETE_LEAD_NOTE";
export const LEAD_BULK_ACTION = "LEAD_BULK_ACTION";
export const SET_MYR_STAFF = "SET_MYR_STAFF";
export const SET_LEAD_SOURCES = "SET_LEAD_SOURCES";
export const EDIT_LEAD_ACTIVITY = "EDIT_LEAD_ACTIVITY";
export const DELETE_LEAD_ACTIVITY = "DELETE_LEAD_ACTIVITY";
export const LEAD_COMPANY_DATA = "LEAD_COMPANY_DATA";
export const SET_LEADS_PRIMARY_KEY = "SET_LEADS_PRIMARY_KEY";
export const LEAD_CONTACT_COMMENTS = "LEAD_CONTACT_COMMENTS";
export const CHECKED_BUIK_LEAD = "CHECKED_BUIK_LEAD";
export const LEAD_FILTERS = "LEAD_FILTERS";
export const LEAD_PAGE_LOADER = "LEAD_PAGE_LOADER";
