import React, { useEffect } from "react";
import CelebrationIcon from "../../../assets/icons/CelebrationIcon";
import { _l } from "../../../hooks/utilities";
import { useNavigate, useSearchParams } from "react-router-dom";

export function OnboardingFlowFinalStep({navigation, congratulationMessage, removeExtraMargin, destination}) {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let edit = searchParams.get("edit");
  
  useEffect(() => {
    if(navigation)
    {
      if(edit)
      {
        navigate(destination);
      }
      else {
        setTimeout(() => {
          navigate(destination);          
        }, 3000);
      }
    }
  }, [])
  

  return (
    <React.Fragment>
      {
        !edit
        ?
          <div className="align-item-center d-flex flex-column justify-content-center text-center h-100">
            <div className={`${removeExtraMargin ? ""  : "mx-auto"}`}>
              <div className="mx-auto">
                <CelebrationIcon className="fill-transparent HW22 pe-auto c-icons mx-auto" />
              </div>
              <div className="onboarding-header text-center p-20">
                <div className="c-font display-5 fw-bold py-2 title-fonts">
                  {_l("l_congratulation")}
                </div>
                <div className="c-font color-white-60 f-18 py-1 title-fonts">
                  {_l(congratulationMessage)}
                </div>
              </div>
            </div>
          </div>
        :
          <React.Fragment></React.Fragment>
      }
    </React.Fragment>
  );
}
