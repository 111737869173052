import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import { Trash, ArrowRight, UploadSimple, UserCircleGear, X, CalendarBlank, CaretRight, MapPin, ChatCircle, PushPin, DotsNine } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  getAssigneeTypesForRequest,
  _l,
  showError,
  tabindexFix,
} from "../../hooks/utilities";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../actions/messages";
import CustomerServices from "../../services/customer-services";
import { closeOutsideClick, getArLocalAnchors, getTaskCount, projectTaskCounts, setTaskData, updateLocalAnchor } from "../../actions/customer";
import moment from "moment";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import ReactTooltip from "react-tooltip";
import Spinner from "react-bootstrap/esm/Spinner";
import AddAssigneeMainDropdown from "../TaskModalComponents/AddAssigneeMainDropdown";
import MeetingProviderModal from "../Modals/MeetingProviderModal";
import customerServices from "../../services/customer-services";
import { CUSTOM_ASSIGNEE_CHANGE, CUSTOM_CLIENT_IDS, CUSTOM_ROLES } from "../../constants/constants";
import { getFloorDetails } from "../../actions/benchmark-actions/benchmark-actions";
import { FloorPlanSummary } from "../SpaceOnBoarding/SpaceDetails/FloorPlanSummary";
import Select from "react-select";
import BenchmarkServices from "../../services/benchmark-services";
import CreateChecklistTemplate from "../Modals/CreateChecklistTemplate";
// import { Button } from "reactstrap";
import jquery from "jquery";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import TaskListStatusDropdown from "../Dropdowns/TaskListStatusDropdown";
import CreateTaskOffCanvasNew from "./CreateTaskOffCanvasNew";
import $ from "jquery";

let spaceSelected = false;

const CreateTaskRequestOffcanvas = ({ display, handleClose, from = "" }) => {
  const {
    defaultTopics,
    selectedProject,
    taskFilters,
    activeTaskStatusFilters,
    getSelectedFloorId,
    get_Ar_Local_Anchors,
    setPOILatLng,
    getActivePOIList,
    attachmentsForTask
  } = useSelector((state) => state.customer);

  const {floorDetails} = useSelector((state) => state.benchmarkReducer);

  const dispatch = useDispatch();
  const fileInputRef = useRef();

  const userImage = localStorage.getItem("contact_image");
  const userType = localStorage.getItem("user_type");
  const taskCreatorName = localStorage.getItem("full_name");
  const virtualSpaces = localStorage.getItem("selectedVirtualSpace");
  const [meetingModal, setMeetingModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [providerCategorylist, setProviderCategorylist] = useState([]);
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
  const [requestAssignee, setRequestAssignee] = useState([]);
  const [requestTitle, setRequestTitle] = useState("");
  const [description, setDescription] = useState("");
  const [attachmentsArray, setAttachmentsArray] = useState([]);
  const [previewArray, setPreviewArray] = useState([]);
  const [providercategory, setProvidercategory] = useState([]);
  const [inputActionIconShow, setinputActionIconShow] = useState(true);
  const [calendarDateChanged, setcalendarDateChanged] = useState(false)
  const [newChecklistItemDescription, setNewChecklistItemDescription] =
    useState("");
  const [checklistItems, setChecklistItems] = useState([]);
  const [loder, setLoder] = useState(false);
  const [visibleToCustomer, setVisibleToCustomer] = useState(false);
  const [visibleToProvider, setVisibleToProvider] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState([]); 
  const [selectedAssigneeIds, setSelectedAssigneeIds] = useState([]);
  const [taskAssigneePills, setTaskAssigneePills] = useState([]);
  const [taskAssignee, setTaskAssignee] = useState([]);
  const [showTaskAssignee, setShowTaskAssignee] = useState(0);
  const [x, setX] = useState('0');
  const [y, setY] = useState('0');
  const [z, setZ] = useState('0');
  const [pointId, setPointId] = useState('0');
  const [isAttachmentTask, setIsAttachmentTask] = useState(false);
  const [attchmentId, setAttchmentId] = useState(0);
  const [attachmentTaskId, setAttachmentTaskId] = useState(0);
  const [checklistMode, setChecklistMode] = useState("classic");
  const [selectedFloor, setSelectedFloor] = useState({ id: -1, title: "" });
  const [selectedTypeOfSpace, setSelectedTypeOfSpace] = useState({id: -1});
  const [showFloorList, setShowFloorList] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const [showChecklistTemplateModal, setShowChecklistTemplateModal] =
    useState(false);

  useEffect(() => {
    if (setPOILatLng) {
      setX(setPOILatLng.x);
      setY(setPOILatLng.y);
      setZ(setPOILatLng.z);
    }
    if (getActivePOIList && getActivePOIList.length > 0) {
      let tempPOI = getActivePOIList.filter((PO) =>  parseInt(PO.web_x) != 0 || parseInt(PO.web_y) != 0 || parseInt(PO.web_z) != 0)[0]
      let temp_id = tempPOI ? tempPOI.id : 0

      setPointId(temp_id);
    }
  }, [setPOILatLng,getActivePOIList])
  const link = useSelector((state) => state.customer.get_link);

  useEffect(() => {
    if(link && link != [])
    {
      if(link.data.newRequestFlag == 1)
      {
        handleCreateRequest(link.data.link);
      }
    }
  }, [link])

  useEffect(() => {
    $("#client_3d_optionfirst").css({ 'display': 'none' });
    $(".dropdown-menu").removeClass("show");
    // if ($('.blank_anchor').length) {
    //   $('.blank_anchor').remove();
    // }
    dispatch(closeOutsideClick(false));
  }, [])

useEffect(() => {
    let element = document.body;
    jquery("#modal-overlay-toggle").removeClass("modal-overlay");
    if (display) {
      if (element) {
        element.classList.remove("modal-open");
      }
    }
    return () => {
      if (element) {
        element.classList.remove("modal-open");
      }
    };
  }, [display]);

  useEffect(() => {
    if (selectedFloor.id > 0) {
      floorDetails.type_of_space.map((spaceItem) => {
        return spaceItem.details.length > 0 && spaceItem.details.filter((check) => check.floor_id == selectedFloor.id).map((details, index) => {
            if (index == 0) {
              setSelectedTypeOfSpace(details);
              jquery("#checklistCreateRequest").focus();
            }
        })
      })
    }
  },[JSON.stringify(selectedFloor),floorDetails])

  useEffect(() => {
    if (defaultTopics && defaultTopics.length) {
      let requestAssigneeList = [];
      defaultTopics.forEach((topic) => {
        switch (topic.main_key) {
          case "provider_task":
            topic.categories.forEach((provider) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: provider.title,
                id: provider.filter_key,
                is_assigned: provider.is_assigned || 0,
                image: provider.image || "",
              });
            });
            break;

          case "my_people":
            topic.categories.forEach((provider) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: provider.title,
                id: provider.filter_key,
                is_assigned: provider.is_assigned || 0,
                image: provider.image || "",
              });
            });
            break;

          case "proposals":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;

          case "user_journey":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;

          case "myr_staff_task":
            topic.categories.forEach((proposal) => {
              requestAssigneeList.push({
                main_key: topic.main_key,
                type:
                  requestAssigneeList.findIndex(
                    (x) => x.type == topic.title
                  ) === -1
                    ? topic.title
                    : "",
                name: proposal.title,
                id: proposal.filter_key,
                is_assigned: proposal.is_assigned || 0,
                image: proposal.image || "",
              });
            });
            break;
          case "task_topic":
            if (virtualSpaces && Object.keys(virtualSpaces).length) {
              topic.categories.forEach((proposal) => {
                requestAssigneeList.push({
                  main_key: topic.main_key,
                  type:
                    requestAssigneeList.findIndex(
                      (x) => x.type == topic.title
                    ) === -1
                      ? topic.title
                      : "",
                  name: proposal.title,
                  id: proposal.filter_key,
                  is_assigned: proposal.is_assigned || 0,
                  image: proposal.image || "",
                });
              });
            }
            break;
          default:
            break;
        }
      });

      const assigneeTypes = getAssigneeTypesForRequest();
      setProviderCategorylist(
        requestAssigneeList.filter((x) => assigneeTypes.includes(x.main_key))
      );
    }

    return () => {
      previewArray.map((img) => {
        URL.revokeObjectURL(img.url);
      });
    };
  }, [defaultTopics]);

  // useEffect(() => {
  //   jquery(`.custom-assignees`).removeClass("active with-tick");
  //   jquery(`.custom-assigneesMain`).removeClass("active ");
  //   // jquery(`.w-200`).removeClass("show");
  //   if (requestAssignee.length) {
  //     requestAssignee.forEach((assigne, index) => {
  //       jquery(
  //         `.custom-assignees[data-assigneesId=${assigne.filter_key}]`
  //       ).addClass("active with-tick");
  //       if (index == 0) {
  //         jquery(
  //           `.custom-assigneesMain[data-assigneeMain=${assigne.main_key}]`
  //         ).addClass("active ");
  //       }
  //       // jquery(`data-assigneesId=[${assigne.filter_key}]`).addClass('active with-tick')
  //     });
  //   } else {
  //   }
  // }, [requestAssignee]);

  const handleFileUpload = (files) => {
    if (files) {
      let thumbnails = [];
      let newFiles = [];
      Array.from(files).map((img) => {
        thumbnails.push({ name: img.name, url: URL.createObjectURL(img) });
        newFiles.push(img);
      });

      if (attachmentsArray && Array.from(attachmentsArray).length) {
        /**Remove duplicate attachments from array */
        newFiles = Array.from(newFiles).filter(
          (x) =>
            Array.from(attachmentsArray).findIndex((w) => w.name == x.name) ===
            -1
        );
        thumbnails = thumbnails.filter(
          (x) => previewArray.findIndex((w) => w.name == x.name) === -1
        );

        setAttachmentsArray(Array.from(attachmentsArray).concat(newFiles));
        setPreviewArray(previewArray.concat(thumbnails));
      } else {
        setAttachmentsArray(files);
        setPreviewArray(thumbnails);
      }
    }
  };

  useEffect(() => {
    if (attachmentsForTask !== undefined) {
      setPreviewArray([{ name: attachmentsForTask.image_url, url: attachmentsForTask.image_url }])
      setIsAttachmentTask(true)
      setAttchmentId(attachmentsForTask.id)
      setAttachmentTaskId(attachmentsForTask.task_id)
    }
  }, [attachmentsForTask])
  

  const formValidations = () => {
    if (requestAssignee.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_select_assignees"))
      );
      return false;
    } else if (checklistItems.length == 0) {
      if (newChecklistItemDescription != "") {
        let newArr = checklistItems
        newArr.push({ description: newChecklistItemDescription.trim(), finished: 0 });
        setChecklistItems(newArr);
        setNewChecklistItemDescription("");
        return true;
      } else if (checklistItems.every((a) => a && a.description.trim() == "")) {
        dispatch(showMessage("unsucess", _l("l_error"), _l("l_add_checklist_items")));
        return false;
      } 
        // dispatch(
        //   showMessage("unsucess", _l("l_error"), _l("l_add_checklist_items"))
        // );
    }
    // else if(checklistItems.length == 0 || checklistItems.every((a) => a.description.trim() == "")) {
    //   dispatch(showMessage("unsucess", _l("l_error"), _l("l_add_checklist_items")));
    //   return false;
    // }
    // Description is commented
    // else if (!description.trim().length) {
    //   dispatch(
    //     showMessage("unsucess", _l("l_error"), _l("l_please_enter_description"))
    //   );
    //   return false;
    // }
    return true;
  };

  const handleAddChecklistItem = (e) => {
    let checklistArr = e.target.value ? e.target.value.split("\n") : [`${newChecklistItemDescription}`];
    if (e.type == "paste") {
      checklistArr = e.clipboardData.getData('Text').split("\n");
    }
    let newArr = checklistItems
    for (let i = 0; i < checklistArr.length; i++) {
      if (checklistArr[i].trim().length) {
        newArr.push({ description: checklistArr[i].trim(), finished: 0, floor_id: selectedFloor.id != - 1 ? selectedFloor.id : 0, type_of_space_item_id: selectedTypeOfSpace.id != -1 ? selectedTypeOfSpace.id : 0  });
      }
    }
    setChecklistItems(newArr);
    setTimeout(() => {
      setNewChecklistItemDescription("");
    }, 100);
  };

  const removeAttachment = (ref) => {
    let currentFiles = attachmentsArray;
    currentFiles = Array.from(currentFiles).filter(
      (file) => file.name !== ref.name
    );
    setAttachmentsArray(currentFiles);
    setPreviewArray(previewArray.filter((x) => x.name !== ref.name));
    fileInputRef.current.value = null;
  };

  const handleCreateRequestOld = () => {
    if (formValidations() && selectedProject) {
      CustomerServices.createNewTask(selectedProject, 1).then((res) => {
        if (res.status && res.data.task_id) {
          const taskId = res.data.task_id;
          CustomerServices.setTaskData(
            selectedProject,
            taskId,
            "Request by " + taskCreatorName + " - " + providercategory.label,
            moment().format("YYYY-MM-DD"),
            undefined,
            undefined,
            description
          );

          CustomerServices.addLinkComment(
            taskId,
            selectedProject,
            null,
            undefined,
            undefined,
            undefined,
            undefined,
            attachmentsArray
          );

          CustomerServices.setTaskAssignee(
            selectedProject,
            taskId,
            "myr_network",
            providercategory.value,
            moment().format("YYYY-MM-DD"),
            1
          );
          dispatch(
            getTaskCount("", "", selectedProject, moment().format("YYYY-MM-DD"))
          );
          handleClose();
          dispatch(
            showMessage("success", _l("l_success"), _l("l_request_created"))
          );
        }
      });
    }
  };

  const handleCreateRequest = (link="") => {
    if (formValidations()) {
      setLoder(true);
      let tempTaskName =
        (requestAssignee && requestAssignee[0] && requestAssignee[0].name
          ? requestAssignee[0].name
          : "") +
        `${checklistItems && checklistItems.length ? " - " : ""}` +
        (checklistItems && checklistItems.length
          ? checklistItems.filter((a) => a.description.trim() != "")[0]
            .description
          : "");
      // requestAssignee && requestAssignee[0] && requestAssignee[0].name
      //   ? requestAssignee[0].name
      //   : "";

      let taskStatus = 1;
      let sub_categories = []
      let sub_category = selectedAssignee.filter((s) => s.main_key == "equipments" || (s.main_key == "provider_task" && userType == "contact" && CUSTOM_ASSIGNEE_CHANGE.includes(contact_role)))
      if (sub_category && sub_category.length > 0) {
        sub_categories = sub_category.map((ss) => {
          return {
            main_key: ss.main_key,
            categories: ss.categories.map((sc) => {
                return {
                  filter_key: sc.filter_key,
                  categories: sc.sub_category.map((sc) => sc.id)
                }
            })
          }
        })
      }
      // let checkedItems = checklistItems.filter((x) => x.finished);
      // taskStatus =
      //   checkedItems.length > 0 && checkedItems.length === checklistItems.length
      //     ? 5
      //     : checkedItems.length > 0 &&
      //       checkedItems.length !== checklistItems.length
      //       ? 4
      //       : 1;
      CustomerServices.createRequestTask(
        // "Request by " + taskCreatorName + " - " + tempTaskName,
        tempTaskName,
        attachmentsArray,
        selectedAssignee,
        description,
        taskStatus,
        checklistItems.filter((a) => a.description.trim() != ""),
        visibleToCustomer,
        link,
        visibleToProvider,
        sub_categories
      ).then((res) => {
        setLoder(false);
        const taskId = res.data.id;
        // if (taskId) {
        //   checklistItems.forEach((item) => {
        //     CustomerServices.addChecklistItem(
        //       taskId,
        //       selectedProject,
        //       item.description,
        //       item.finished ? "1" : "0"
        //     );
        //   });
        // }
        if (res.status == 1) {
          dispatch(
            setTaskData(
              selectedProject,
              taskId,
              tempTaskName,
              undefined,
              '',
              0,
              0,
              0,
              0,
              "",
              "",
              visibleToCustomer,
              visibleToProvider,
              isAttachmentTask,
              attchmentId,
              attachmentTaskId
            )
          ).then(() => {
            handleClose(taskId);
            dispatch(toastCloseAction());
          });
          if (from == "3dPlan" && (parseInt(x) != "" || parseInt(y) != "" || parseInt(z) != "")) {
            customerServices.setArLocalAnchors(getSelectedFloorId, taskId, pointId, x, y, z).then((res) => {
              if (res.status == 1) { 
                let localArray = get_Ar_Local_Anchors
                localArray.push({
                  checklist_id: 0,
                  checklist_title: null,
                  poi: pointId,
                  task_id: taskId,
                  title: tempTaskName,
                  web_x: x,
                  web_y: y,
                  web_z: z
                })
                dispatch(getArLocalAnchors(getSelectedFloorId))
              }
            })
          }
          dispatch(
            getTaskCount("", "", selectedProject, moment().format("YYYY-MM-DD"))
          );
          dispatch(
            projectTaskCounts(
              localStorage.getItem("selectedOffice"),
              taskFilters ? taskFilters : [],
              "",

            )
          );
          handleClose(taskId);
          dispatch(
            showMessage("sucess", _l("l_success"), _l(`${res.message}`))
          );
          // dispatch(toastCloseAction());
        } else {
          dispatch(
            showMessage(
              "unsucess",
              _l("l_error"),
              res.message ? _l(`${res.message}`) : ""
            )
          );
        }
      });
    }
  };

  const modalCloseIconClick = () => {
    if (
      requestTitle.trim().length ||
      (attachmentsArray && Array.from(attachmentsArray).length) ||
      requestAssignee.length ||
      description.trim().length ||
      checklistItems.length
    ) {
      dispatch(
        showConfirmation(
          _l("l_confirm"),
          _l("l_want_to_save_task"),
          _l("l_yes"),
          _l("l_no"),
          handleClose,
          handleCreateRequest,
          true
        )
      );
    } else {
      handleClose();
    }
  };

  // const handleRequestAssignee = (assigneeDetail) => {
  //   if (
  //     requestAssignee.findIndex(
  //       (x) =>
  //         x.main_key == assigneeDetail.main_key &&
  //         x.filter_key == (assigneeDetail.id || assigneeDetail.filter_key)
  //     ) !== -1
  //   ) {
  //     setRequestAssignee([]);
  //     setSelectedAssignee([])
  //     // dropDownActiveClass(
  //     //   false,
  //     //   `${assigneeDetail.main_key}_${assigneeDetail.id}`
  //     // );
  //   } else {
  //     // dropDownActiveClass(
  //     //   true,
  //     //   `${assigneeDetail.main_key}_${assigneeDetail.id}`
  //     // );
  //     setSelectedAssignee([{ id: assigneeDetail.id || assigneeDetail.filter_key, name: assigneeDetail.main_key }])

  //     setRequestAssignee([
  //       {
  //         main_key: assigneeDetail.main_key,
  //         filter_key: assigneeDetail.id || assigneeDetail.filter_key,
  //         image: assigneeDetail.image || "",
  //         name: assigneeDetail.name || "",
  //         is_assigned: 1,
  //       },
  //     ]);
  //   }
  // };

  // const handleRequestAssignee = (assigneeDetail) => {
  //   let taskAssign = selectedAssignee
  //   let taskAssignIds = selectedAssigneeIds
  //   if (
  //     requestAssignee.findIndex(
  //       (x) =>
  //         x.main_key == assigneeDetail.main_key &&
  //         x.filter_key == (assigneeDetail.id || assigneeDetail.filter_key)
  //     ) !== -1
  //   ) {
  //     setRequestAssignee([]);
  //     setSelectedAssignee([])
  //     // dropDownActiveClass(
  //     //   false,
  //     //   `${assigneeDetail.main_key}_${assigneeDetail.id}`
  //     // );
  //   } else {
  //     // dropDownActiveClass(
  //     //   true,
  //     //   `${assigneeDetail.main_key}_${assigneeDetail.id}`
  //     // );
  //     if (taskAssign && taskAssign.length > 0) {
  //       var index = taskAssign.findIndex((e) => e.main_key == assigneeDetail.main_key);
  //       if (index > -1) {
  //         let isExist = taskAssign[index] && taskAssign[index].categories.findIndex((taskassign) => taskassign.filter_key == assigneeDetail.filter_key)
  //         if (isExist > -1) {
  //           taskAssign[index]['categories'] = taskAssign[index]['categories'].filter((taskassign) => taskassign.filter_key != assigneeDetail.filter_key)
  //           taskAssignIds = taskAssignIds.filter(sa => sa.id != assigneeDetail.filter_key)
  //         } else {
  //           if (assigneeDetail.main_key == "provider_task") {
  //             const indexIds = taskAssignIds.findIndex(id=> id.name == "provider_task")
  //             const index = taskAssign.findIndex(id=> id.main_key == "provider_task")
  //             if (indexIds > -1) {
  //               taskAssignIds[indexIds] = {
  //                 name: assigneeDetail.main_key,
  //                 id: assigneeDetail.filter_key
  //               }
  //             }
  //             if (index > -1) {
  //               taskAssign[index].categories = [{ filter_key: assigneeDetail.filter_key }]
  //             }
  //           }else{
  //           taskAssign[index]['categories'].push({ filter_key: assigneeDetail.filter_key })
  //           taskAssignIds.push({
  //             name: assigneeDetail.main_key,
  //             id: assigneeDetail.filter_key
  //           })
  //         }
  //       }
  //       } else {
  //         taskAssign.push({
  //           main_key: assigneeDetail.main_key,
  //           categories: [{ filter_key: assigneeDetail.filter_key }]
  //         })
  //         taskAssignIds.push({
  //           name: assigneeDetail.main_key,
  //           id: assigneeDetail.filter_key
  //         })
  //       }
  //     } else {
  //       taskAssign = [{
  //         main_key: assigneeDetail.main_key,
  //         categories: [{ filter_key: assigneeDetail.filter_key }]
  //       }]
  //       taskAssignIds = [{
  //         name: assigneeDetail.main_key,
  //         id: assigneeDetail.filter_key
  //       }]
  //     }
  //     setSelectedAssignee(taskAssign)
  //     setSelectedAssigneeIds(taskAssignIds)
  //     setRequestAssignee([
  //       {
  //         main_key: assigneeDetail.main_key,
  //         filter_key: assigneeDetail.id || assigneeDetail.filter_key,
  //         image: assigneeDetail.image || "",
  //         name: assigneeDetail.name || "",
  //         is_assigned: 1,
  //       },
  //     ]);
  //   }
  // };
  const client_id =localStorage.getItem("client_id");
  const contact_role =localStorage.getItem("contact_role");
  
  const handleRequestAssignee = (assigneeDetail) => {
    let taskAssign = selectedAssignee
    let taskAssignIds = selectedAssigneeIds
      if (taskAssign && taskAssign.length > 0) {
        // if (assigneeDetail.main_key == "equipments" || (assigneeDetail.main_key == "provider_task" && CUSTOM_CLIENT_IDS.includes(client_id) && contact_role != 3)) {
        if (assigneeDetail.main_key == "equipments" || (assigneeDetail.main_key == "provider_task" && userType == "contact" && CUSTOM_ASSIGNEE_CHANGE.includes(contact_role))) {
          let Index = taskAssign.findIndex((e) => e.main_key == assigneeDetail.main_key);
          if (Index > -1) {
            let IndexT = taskAssign[Index]['categories'].findIndex((taskassign) => taskassign.filter_key == assigneeDetail.filter_key)
            if (IndexT > -1) {
              let IndexU = taskAssign[Index]['categories'][IndexT].sub_category.findIndex((taskassign) => taskassign.id == assigneeDetail.sub_category[0].id)
              if (IndexU > -1) {
                taskAssign[Index]['categories'][IndexT].sub_category = taskAssign[Index]['categories'][IndexT].sub_category.filter((taskassign) => taskassign.id != assigneeDetail.sub_category[0].id)
                // taskAssignIds = taskAssignIds.sub_category.filter(sa => sa.id != assigneeDetail.filter_key)
              }else{
                taskAssign[Index]['categories'][IndexT].sub_category.push(assigneeDetail.sub_category[0])
              }
            }else{
            taskAssign[Index]['categories'] = [{ filter_key: assigneeDetail.filter_key, sub_category: assigneeDetail.sub_category }]
          }
          }else{
              taskAssign.push({
                main_key: assigneeDetail.main_key,
                categories: [{ filter_key: assigneeDetail.filter_key, sub_category: assigneeDetail.sub_category }]
              })
          }
        } else {
        var index = taskAssign.findIndex((e) => e.main_key == assigneeDetail.main_key);
        if (index > -1) {
          let isExist = taskAssign[index] && taskAssign[index].categories.findIndex((taskassign) => taskassign.filter_key == assigneeDetail.filter_key)
          if (isExist > -1) {
            taskAssign[index]['categories'] = taskAssign[index]['categories'].filter((taskassign) => taskassign.filter_key != assigneeDetail.filter_key)
            taskAssignIds = taskAssignIds.filter(sa => sa.id != assigneeDetail.filter_key)
          } else {
            if (assigneeDetail.main_key == "provider_task") {
              const indexIds = taskAssignIds.findIndex(id=> id.name == "provider_task")
              const index = taskAssign.findIndex(id=> id.main_key == "provider_task")
              if (indexIds > -1) {
                taskAssignIds[indexIds] = {
                  name: assigneeDetail.main_key,
                  id: assigneeDetail.filter_key
                }
              }
              if (index > -1) {
                taskAssign[index].categories = [{ filter_key: assigneeDetail.filter_key }]
              }
            }else{
            taskAssign[index]['categories'].push({ filter_key: assigneeDetail.filter_key })
            taskAssignIds.push({
              name: assigneeDetail.main_key,
              id: assigneeDetail.filter_key
            })
          }
        }
        } else {
          taskAssign.push({
            main_key: assigneeDetail.main_key,
            categories: [{ filter_key: assigneeDetail.filter_key }]
          })
          taskAssignIds.push({
            name: assigneeDetail.main_key,
            id: assigneeDetail.filter_key
          })
        }
      }
      } else {
        // if (assigneeDetail.main_key == "equipments" || (assigneeDetail.main_key == "provider_task" && CUSTOM_CLIENT_IDS.includes(client_id) && contact_role != 3)) {
          if (assigneeDetail.main_key == "equipments" || (assigneeDetail.main_key == "provider_task" && userType == "contact" && CUSTOM_ASSIGNEE_CHANGE.includes(contact_role))) {
          taskAssign = [{
            main_key: assigneeDetail.main_key,
            categories: [{ filter_key: assigneeDetail.filter_key, sub_category: assigneeDetail.sub_category }]
          }]
          taskAssignIds = [{
            name: assigneeDetail.main_key,
            id: assigneeDetail.filter_key,
            sub_category: assigneeDetail.sub_category
          }]
        } else { 
          taskAssign = [{
            main_key: assigneeDetail.main_key,
            categories: [{ filter_key: assigneeDetail.filter_key }]
          }]
          taskAssignIds = [{
            name: assigneeDetail.main_key,
            id: assigneeDetail.filter_key
          }]
        }
      }
      
      setSelectedAssignee(taskAssign.slice())
      // setSelectedAssigneeIds(taskAssignIds)
      setRequestAssignee([
        {
          main_key: assigneeDetail.main_key,
          filter_key: assigneeDetail.id || assigneeDetail.filter_key,
          image: assigneeDetail.image || "",
          name: assigneeDetail.name || "",
          is_assigned: 1,
        },
      ]);
  };

  useEffect(() => {
    let task_assignee = [];
    let task_assignee_pills = [];
    let task_assignee_ids = [];
      if (selectedAssignee.length > 0) {
          selectedAssignee.map((ta) => {
            ta.categories && ta.categories.map((taskAssign) => {
              task_assignee_ids.push({
                name: ta.main_key,
                id: taskAssign.filter_key,
              })
            if (
              ta.main_key == "myr_staff_task" ||
              ta.main_key == "my_people" ||
              ta.main_key == "task_topic"
            ) {
              defaultTopics &&
                defaultTopics.forEach((topic) => {
                  if (topic.main_key == ta.main_key) {
                    topic.categories.forEach((provider) => {
                      if (taskAssign.filter_key == provider.filter_key) {
                        task_assignee.push({
                          name: provider.title,
                          profile_image: provider.image,
                          id: provider.filter_key,
                        });
                      }
                    });
                  }
                });
            } else {
              defaultTopics &&
                defaultTopics.forEach((topic) => {
                  if (topic.main_key == ta.main_key) {
                    topic.categories.forEach((provider) => {
                      if (taskAssign.filter_key == provider.filter_key) {
                        // if (topic.main_key != "equipments" && !(topic.main_key == "provider_task" && CUSTOM_CLIENT_IDS.includes(client_id) && contact_role != 3)) {
                          if (topic.main_key != "equipments" && !(topic.main_key == "provider_task" && userType == "contact" && CUSTOM_ASSIGNEE_CHANGE.includes(contact_role))) {
                          task_assignee_pills.push({
                            name: provider.title,
                            id: provider.filter_key,
                            main_key:topic.main_key
                          });
                        }

                        let subCategory = []
                        if (topic.main_key == "provider_task") {
                          taskAssign.sub_category && taskAssign.sub_category.forEach((dd) => {
                            subCategory.push(dd);
                            task_assignee.push({
                              name: dd.fullname,
                              id: dd.id,
                              profile_image: dd.profile_image_url,
                              main_key:topic.main_key
                            });
                          })
                        }else{
                        provider.sub_category && provider.sub_category.forEach((sc) => {
                          taskAssign.sub_category && taskAssign.sub_category.forEach((dd) => {
                            subCategory.push(dd.id);
                            if (sc.id == dd.id) {
                              task_assignee_pills.push({
                                name: sc.name,
                                id: sc.id,
                                main_key:topic.main_key
                              });
                            }
                          })
                        });
                      }
                        if (subCategory.length == 0) {
                          task_assignee_ids = task_assignee_ids.filter((tas) => tas.name != "equipments")
                        }
                        task_assignee_ids.push({
                          id: provider.filter_key,
                          name: provider.title,
                          sub_category: subCategory
                        })
                      }
                    });
                  }
                });
            }
          });
        })
      }
    setTaskAssigneePills(task_assignee_pills);
    setTaskAssignee(task_assignee);
    setSelectedAssigneeIds(task_assignee_ids);
}, [JSON.stringify(selectedAssignee)]);
  const handleTaskAssigneeData = () => {
    setShowTaskAssignee(showTaskAssignee == 0 ? 1 : 0);
  };

  const applyFloorbaseFilter = (floor) => {
    setSelectedFloor(floor);
    setShowFloorList(false);
    // setChecklistItems(checklistStateData.filter((w) => w.floor_id == floor.id));
  };

  const getTypeOfSpaceCount = (id) => {
    let spaceItemsArray = [];
    floorDetails && floorDetails.type_of_space.map((e) =>{
      return e.details && e.details.length > 0 && e.details.map((s) => {
        if (s.floor_id == id) {
          spaceItemsArray.push(s);
        }
      })
    })
    return spaceItemsArray.length;
  }

  const getFloorwiseChecklistCount = (id) => {
    return checklistItems.filter((w) => w.floor_id == id).length;
  }

  const getChecklistAttachmentCount = (id) => {
    //To be implemented
    return 0;
  }

  const clearFloorFilter = () => {
    setSelectedFloor({ id: -1, title: "" });
    setShowFloorList(true);
    setSelectedTypeOfSpace({ id: -1, title: "" });
    setChecklistItems(checklistItems);
  }

  const switchToClassicView = (e) => {
    setChecklistMode(e.target.value); 
    setShowFloorList(false); 
    setSelectedFloor({...selectedFloor, id: -1, title: ""})
  }

  const saveTemplate = async(title) => {
    try {
      setShowChecklistTemplateModal(false);
      if (templatesList.find((x) => x.title && x.title.trim() == title.trim())) {
        showError("l_template_already_exists");
      } else if (title && title.trim().length) {
        let templateChecklistItems = checklistItems.slice();
        let formattedChecklistArray = [];

        if (templateChecklistItems && templateChecklistItems.length) {
          templateChecklistItems.forEach((checklist) => {
            if (checklist.description) {
              formattedChecklistArray.push({
                id: checklist.id || 0,
                floor_id: checklist.floor_id,
                checklist: [
                  {
                    name: checklist.description,
                    photo: 0,
                    selected: 1,
                    addedFromTemplate: false,
                    unique_id: checklist.id,
                    is_default_checklist: false,
                    tasks_per_month: 1,
                  },
                ],
                type_of_space_item_id: checklist.type_of_space_item_id,
              });
            }
          });
        }
        if (formattedChecklistArray.length) {
          const response = await BenchmarkServices.createChecklistTemplate(
            selectedProject,
            0,
            title.trim(),
            formattedChecklistArray,
            1
          );
          if (response.status && response.data.inserted_template_id) {
            setTemplatesList(
              templatesList.concat([
                { title: title.trim(), id: response.data.inserted_template_id },
              ])
            );
          } else {
            showError("l_something_went_wrong");
          }
        }
      } else {
        showError("l_please_enter_template_title");
      }
    }
    catch(e)
    {

    }
  }

  const copyTemplateChecklist = async (template) => {
    try {
      const response = await BenchmarkServices.getTemplateChecklistItems(
        selectedProject,
        0,
        template.value,
        1
      );
      if (response.status && response.data.length) {
        let templateItems = response.data.slice();
        let currentChecklistItems = checklistItems.slice();
        templateItems.forEach((x) => {
          if (
            !currentChecklistItems.find(
              (w) =>
                w.description &&
                w.description.trim().toLowerCase() ==
                  x.description.trim().toLowerCase() &&
                x.type_of_space_item_id == w.type_of_space_item_id
            )
          ) {
            currentChecklistItems.push({
              ...x,
              type_of_space_item_id: x.type_of_space_item_id,
              floor_id: x.floor_id,
              id: 0,
            });
          }
        });
        

        currentChecklistItems = currentChecklistItems.filter(
          (obj, index, self) =>
            index ===
            self.findIndex(
              (o) =>
                (obj.room_id == o.room_id &&
                  obj.type_of_space_item_id == o.type_of_space_item_id &&
                  obj.description == o.description) ||
                (obj.description == "" && o.description == "")
            )
        );

        currentChecklistItems.sort((a, b) => {
          if (a.description === "" && b.description !== "") {
            return 1;
          }
          if (a.description !== "" && b.description === "") {
            return -1;
          }
          return 0;
        });
        setChecklistItems(currentChecklistItems);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const [show ,setShow] = useState(false);
  const handleCloseRequest = () => {
    if (show) {
      setShow(false)
    } else {
      modalCloseIconClick()
    }
  }
  return (
    <>
      <Modal
        show={true}
        onHide={handleCloseRequest}
        className={`custom-modal-style  ${from == "3dPlan" ? "modal-style-modified w-40vw" : "overflow-hidden with create-task-absolute-mobile-liteversion"}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={from == "3dPlan" ? false : true}
        size="lg"
        onShow={() => {
          tabindexFix();
        }}
        // placement="bottom"
        // backdropClassName="offcanvas-backdrop"
        // className="bottom-offcanvas with-max-h-70vh"
        // backdrop="static"
      >
        <Modal.Header closeVariant="white">
          <Modal.Title>{_l('l_create_request')}</Modal.Title>
        <div className="d-flex justify-content-end flex-grow-1">
          {/* <a href="#/" className="action_icon on-hover-active-toggle-imgs with_separator_10"
              data-tip={_l('l_video_camera')}
              data-effect="solid"
              data-class="tooltip-main"
              onClick={() => {
                if(formValidations())
                {
                  setMeetingModal(true);
                }
              }}
          >
            <VideoCamera size={22} weight="light" className="c-icons" />
          </a> */}
          {meetingModal ? 
                          <MeetingProviderModal meetingProvider={meetingModal} HandleClose={() =>{setMeetingModal(false)}} newRequestFlag={1}/>
                        : <></>}
                      {userType == "staff" && !virtualSpaces ? (
              <>
              <div className={`align-items-center before-dnone color-white-60 d-flex pe-0 with_separator_10 
                }`}>
              <span>{_l("l_visible_for")}</span> <span className="ps-2">:</span>
              </div>
              <div className={`with_separator_10 align-items-center d-flex `}>
                <Form.Check
                  className="align-items-center d-flex "
                  type="checkbox"
                  id=""
                  onChange={(e) => {
                    setVisibleToCustomer(e.target.checked);
                  }}
                  label="Customer"
                />
              </div>
              <div className={`with_separator_10 align-items-center d-flex`}>
                <Form.Check
                  className="align-items-center d-flex "
                  type="checkbox"
                  label={_l("l_provider")}
                  onChange={(e) => {
                    setVisibleToProvider(e.target.checked);
                  }}
                />
              </div> 
          </>
            ) 
            : 
            (<></>)}
        </div>
        </Modal.Header>
        <Modal.Body className="with-overflow pt-0 overflow-visible min-h25vh d-flex flex-column">
          <div className="custom-modal-section-sticky mt-2 d-flex flex-column flex-grow-1 max-height-77vh">
          <CreateTaskOffCanvasNew 
           taskTitle={requestTitle}
           setTaskTitle={setRequestTitle}
           visibleToCustomer={visibleToCustomer}
           visibleToProvider={visibleToProvider}
           handleClose={handleClose}
           isRequest={true}
           from={from}
           show={show} 
           setShow={setShow}
           fromModal = {true}
           />
          </div>
          <ReactTooltip />
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-between d-none">
        <div className={`d-flex flex-wrap upload-image-preview ${previewArray.length > 0 ? "" : "d-none"}`}>
            {previewArray.map((thumb, index) => {
              return (
                <div
                  key={index}
                  className="comman-image-box h50w50 radius_3 upload-image m-2"
                >
                  <div
                    className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                    style={{
                      backgroundImage: `url('${thumb.url}')`,
                    }}
                  ></div>
                  <a href="#/"
                    className="upload-close"
                    onClick={() => {
                      removeAttachment(thumb);
                    }}
                  >
                    <X size={10} weight="light" className="c-icons" />
                  </a>
                </div>
              );
            })}
          </div>
          <div className="request-image-wrapper w-100">
            <div className="d-flex align-items-center justify-content-between">
              <div className="upload-image-wrapper-main">
                <div className="d-flex align-items-center">
                  <div
                    className="comman_action_icon d-flex position-relative"
                    data-tip={`${_l("l_upload_file")}`}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                  >
                    <Form.Control
                      className="  hidden-input"
                      type="file"
                      ref={fileInputRef}
                      multiple
                      accept="image/*"
                      onChange={(e) => {
                        handleFileUpload(e.target.files);
                      }}
                    />
                    <div className="d-flex align-items-center w-100">
                      <div className="action_icon h35w35 with_bg">
                        <UploadSimple size={18} weight="light" className="c-icons" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                {
                  checklistItems.length
                  ?
                    <Button className="mx-1" variant="white-05 border-0"  onClick={() => {
                      setShowChecklistTemplateModal(true);
                    }}>
                      {_l("l_save_as_template")}
                    </Button>
                  :
                    <React.Fragment></React.Fragment>
                }
                {/* <Button onClick={() =>{if(formValidations())
                {
                  setMeetingModal(true);
                  let data = [];
                  dispatch({
                    type: GET_MEETING_LINK,
                    payload: { data },
                  });
                }}} variant="secondary"> {_l('l_create_with_meet')}</Button> */}
              <Button
          variant="primary" className="ms-2"
                onClick={() => {
                  if (!loder) {
                    handleCreateRequest();
                  }
                }}
              >
                {loder
                  ? _l('l_please_wait')
                  : _l('l_create')
                }
                {
                  loder ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className={`ms-2 `}
                      aria-hidden="true"
                    />
                  ) :
                    <></>
                }
              </Button>
              </div>
              {/* <Button
                variant="primary"
                onClick={() => {
                  handleCreateRequest();
                }}
              >
                {_l("l_create")}{" "}
              </Button> */}
            </div>
          </div>
          <ReactTooltip />

        </Modal.Footer>
      </Modal>
      {showChecklistTemplateModal ? (
        <CreateChecklistTemplate
          handleClose={() => {
            setShowChecklistTemplateModal(false);
          }}
          handleSave={saveTemplate}
          show={showChecklistTemplateModal}
          scheduleDetail={{
            categoryTitle: "",
            benchmarkId: _l("l_request"),
          }}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

export default CreateTaskRequestOffcanvas;
