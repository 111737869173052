import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { _l } from "../../../hooks/utilities";
import $ from "jquery";
import { showMessage } from "../../../actions/messages";
import { useDispatch, useSelector } from "react-redux";
import { getStaffDeatil, updateStaffDate } from "../../../actions/documents-actions/documents-actions";
import { Checkbox, FormControlLabel } from "@mui/material";
import Form from "react-bootstrap/Form";
import documentsServices from "../../../services/documents-services";
const PermissionTab = ({data}) => {
const [allPermissions, setAllPermissions] = useState([]);
const [customerPermissions, setCustomerPermissions] = useState([]);
const [keys, setKeys] = useState([]);
const [staffid, setStaffid] = useState();
const [keyIndex, setKeyIndex] = useState([]);
const [checked, setChecked] = useState(false);
const dispatch = useDispatch();
useEffect(() => {
    if(data)
    {
        setAllPermissions(data && data.all_permissions ? data.all_permissions : []);
        setCustomerPermissions(data && data.permissions ? data.permissions : []);
        setKeyIndex(data.keyIndex ? data.keyIndex : []);
        setStaffid(data.id)
        let arr = [];
        let tempArr = [];
        for (const key in data.all_permissions)
        {
          arr.push(key);
        }
        setKeys(arr);
    }
}, [data])

const changeStatus = (permission, key, status, staffid) => {
  documentsServices.updateStaffPermission(staffid, permission, key, status).then((res) => {
    if(res.message == 'Permission added successfully' || res.message == 'Permission removed successfully')
    {
        dispatch(getStaffDeatil(staffid));
    }
  })
}
    return (
        <div>
                { (keys && keys.length ? keys : []).map((permission, index) => {
                    return (
                        <>
                        <div className="row" key={index}>
                            <div className="col-4">
                                <div className="bill-from d-flex  ">
                                        <div className="color-white-60  pb-2">
                                            <b>
                                                {allPermissions[permission].name}
                                            </b>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-6">
                            <ul>
                            {   
                                (allPermissions[permission].capabilities && allPermissions[permission].capabilities.length > 0 ? allPermissions[permission].capabilities : []).map((res, key) => {
                                    return (
                                        <>
                                            <li className='align-items-center d-flex justify-content-between p-10 px-0 pt-0' key={key}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <div className='d-flex flex-grow-1 w100minus100per align-items-center'>
                                                    <Form.Check
                                                        type="checkbox"
                                                        className="true"
                                                        checked={res.status == 1 ? true : false}
                                                        onChange={(e) => {
                                                            changeStatus(permission, res.key, e.target.checked, staffid)
                                                        }}
                                                    />
                                                    <div className='c-font color-white-80 f-12 flex-grow-1 ms-2 text-truncate w100minus100per pe-2'>{res.key}</div>
                                                </div>
                                            </li>
                                        </>
                                    )
                                })
                            }
                            </ul>
                        </div>
                        </>
                    );
                })}
        </div>
    )
}
export default PermissionTab;