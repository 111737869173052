import React, { useEffect, useState } from 'react'
import { _l } from '../../hooks/utilities'
import customerServices from '../../services/customer-services';
import { ChatCircle } from 'phosphor-react';
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import CommentMinified from '../Comments/CommentMinified';
import documentsServices from '../../services/documents-services';
import { getMentionList } from '../../actions/customer';
import { useDispatch, useSelector } from 'react-redux';
let showComments = false;
const ProviderProfileTable = ({
    data
}) => {
    const mentionsList = useSelector((state) => state.customer.mentionList);
    const [regionvalue, setRegionValue] = useState([]);
    const [categoryListing, setCategoriesList] = useState([]);
    const [selectedKeys, setselectedKeys] = useState('');
    const [commentsLoading, setCommentsLoading] = useState(false);
    const [commentsList, setCommentsList] = useState([]);
    const [fileUploadLoader, setFileUploadLoader] = useState(false);
    const [replyCommentData, setReplyCommentData] = useState({});


    const contact_id = localStorage.getItem("contact_id");
    const userType = localStorage.getItem("user_type");
    let providerID = localStorage.getItem("currentCompanyID");
    const dispatch = useDispatch();

  const getTaskComments = async(type_key) => {
    showComments = true;
    setCommentsLoading(true);
    try {
      const comments = await documentsServices.getComments(providerID, type_key, undefined, undefined, 0);
      if(comments.data)
      {
        setCommentsList(comments.data.map((comment) => {
          return {
            id: comment.id,
            content: comment.content,
            contact_id: comment.contact_id,
            staffid: comment.staffid,
            full_name: comment.contact_full_name || comment.staff_full_name ,
            profile_url: comment.addedfrom_details.profile_url,
            attachments: comment.attachments,
            date: comment.time,
            dateadded: comment.dateadded,
            is_allowed_to_edit:comment.is_allowed_to_edit,
            parent_comment_details : comment.parent_comment_details ? comment.parent_comment_details : '',
            parent_comment_id : comment.parent_comment_id? comment.parent_comment_id : 0
          }
        }));
      }
    }
    catch(e)
    {
      console.error(e);
    }
    finally{
      setCommentsLoading(false);
    }
  }

  const getMentionlist = (type_key) => {
    dispatch(getMentionList(providerID, type_key));
  };
  
  const handleAddEditComment = async(content, files, id) => {
    setFileUploadLoader(true)
    try {   
      const response = await documentsServices.addDocumentComment(selectedKeys, providerID, content, 0, 0, files, 0, 0, 0 );
      if(id > 0)
      {
        const commentIndex = commentsList.findIndex(w => w.id == id);
        setCommentsList(Object.values({...commentsList, [commentIndex]: {...commentsList[commentIndex], content}}));
      }
      else {
        const staffid = localStorage.getItem("staff_id");
        const profile_url = localStorage.getItem("contact_image");
        const full_name = localStorage.getItem("full_name");
        let attachments = [];

        if(files)
        {
          Array.from(files).map((file) => {
            attachments.push({
              file_url: URL.createObjectURL(file),
              file_name: file.name
            })
          });
        }

        var addComments = {
          id: response.data.comment_id,
          content: content,
          contact_id: contact_id,
          staffid: staffid,
          full_name: full_name,
          profile_url: profile_url,
          is_allowed_to_edit:"1",
          attachments: attachments,
          date: response.data.time,
          dateadded:new Date(),
        }
        if (replyCommentData && replyCommentData.content != '' && replyCommentData.id != '') {
          addComments.parent_comment_details = {id : replyCommentData.id, content : replyCommentData.content , file_id : "0"}
          addComments.parent_comment_id = replyCommentData.id
        }else if(replyCommentData.attachments && replyCommentData.attachments[0].file_url != ''){
          let replyCommentDatas = replyCommentData.attachments[0]
          addComments.parent_comment_details = {content : "[task_attachment]", file_id : replyCommentDatas.file_id, file_name : replyCommentDatas.file_name, file_url: replyCommentDatas.file_url}
          addComments.parent_comment_id = replyCommentData.id
        }
        setCommentsList(commentsList.concat([addComments]));
        // setUpdatedCommentCount(Number(commentsList.length + 1));
        setFileUploadLoader(false);
      }
    }
    catch(e)
    {
      setFileUploadLoader(false);
      console.error(e);
    }
  };
  const handleDeleteComment = (comment) => {
    try {
      const response = documentsServices.removeComment(providerID, selectedKeys, comment.id);
      setCommentsList(commentsList.filter(x => x.id != comment.id));
      // setUpdatedCommentCount(Number(commentsList.length - 1));
      }
    catch(e)
    {
      console.error(e);
    }
  }
  useEffect(() => {
    let regions = [];
    let categoryLists = [];
    customerServices.getRegionList().then((response) => {
      if(response.status)
      {
         regions = response.data.map((region) => {
          return {
            label: region.name,
            value: region.id,
          };
        })
        setRegionValue(regions ? regions.filter((item) => data.regions && data.regions.includes(item.value)).map((w) => {
            return{
              label : w.label,
              value : w.value,
              isFixed : true,
            }
          }) : []);
      }
    })  
    customerServices.getProviderServices().then((response) => {
      if (response.status) {
         categoryLists = response.data.map((w) => {
            return { 
              label: w.name, 
              value: w.id, 
            };
          })
        setCategoriesList(categoryLists ? categoryLists.filter((item) => data.categories && data.categories.includes(item.value)).map((w) => {
            return{
              label : w.label,
              value : w.value,
              isFixed : true,
            }
          })  : []);
      }
    })
  }, []);
  return (
    <div className="p-10">
      <div className="row">
        <div className="col-xl-12 ">
          <div className="bg-white-05 c-font color-white fw-semibold p-10 radius_3  light-theme-base-body-color h46px">
            <div className="d-flex align-items-center justify-content-between">
              <div>{_l('l_company_info')}</div>
            </div>
          </div>
          <ul className="p-10 px-0">
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-1 color-white-60 f-13  fw-semibold">
              {_l('l_company_name')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col d-flex align-items-center justify-content-between">
              <div className={`c-font f-13  color-white text-truncate p-10 border}`}>{data.company}</div>
              {/* comment icon start */}
              { userType == "contact" && <div className={`align-items-center before-block card-view-less-padding close-toast col-1 d-flex dropdown dropdown-center on-hover-active-toggle-img text-on-hover-active-green with-left-20 with_separator_10 h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        data-tip={_l("l_comments")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_company");
                          setselectedKeys("provider_company");
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircle
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                        comments={commentsList}
                        handleAddComment={handleAddEditComment}
                        deleteComment={handleDeleteComment}
                        id={providerID}
                        // task={task}
                        loading={commentsLoading}
                        mentionList={mentionsList.map((name) => {
                          let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                          return {
                            display: name.name,
                            id: id,
                            image: name.profile_image || "",
                            isteam: name.is_team ? 1 : 0,
                          };
                        })}
                        // handleImagePreview={handleImagePreview}
                        unContolList={true}
                        // totalUnreadCommentCount={task.unread_comment_count}
                        showComments={showComments}
                        setReplyCommentData={setReplyCommentData}
                        fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>

                  </UncontrolledDropdown>
                </div>}
              {/* comment icon end */}
              </div>
               
            </li>
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-1 color-white-60 f-13  fw-semibold">
              {_l('l_company_type')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col d-flex align-items-center justify-content-between">
              <div className={`c-font f-13  color-white text-truncate p-10 border}`}>
                 {
                    data.industry_type ==  "industry_type_1" ? _l("l_office") :
                    data.industry_type ==  "industry_type_2" ? _l("l_coworking_and_flex_office") : 
                    data.industry_type ==  "industry_type_3" ? _l("l_building") : 
                    data.industry_type ==  "industry_type_4" ? _l("l_multi_properties") : 
                    data.industry_type ==  "industry_type_5" ? _l("l_hotel_and_retail") : 
                    data.industry_type ==  "industry_type_6" ? _l("l_property_manager_and_facility_manager") : 
                    ""
                  
              }</div>
                {/* comment icon start */}
                {userType == "contact" && <div className={`align-items-center before-block card-view-less-padding close-toast col-1 d-flex dropdown dropdown-center on-hover-active-toggle-img text-on-hover-active-green with-left-20 with_separator_10 h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        data-tip={_l("l_comments")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_industry_type");
                          setselectedKeys("provider_industry_type");
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircle
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                        comments={commentsList}
                        handleAddComment={handleAddEditComment}
                        deleteComment={handleDeleteComment}
                        id={providerID}
                        // task={task}
                        loading={commentsLoading}
                        mentionList={mentionsList.map((name) => {
                          let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                          return {
                            display: name.name,
                            id: id,
                            image: name.profile_image || "",
                            isteam: name.is_team ? 1 : 0,
                          };
                        })}
                        // handleImagePreview={handleImagePreview}
                        unContolList={true}
                        // totalUnreadCommentCount={task.unread_comment_count}
                        showComments={showComments}
                        setReplyCommentData={setReplyCommentData}
                        fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>

                  </UncontrolledDropdown>
                </div>}
              {/* comment icon end */}
              </div>
            
            </li>
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-1 color-white-60 f-13  fw-semibold">
              {_l('l_company_id_number')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col d-flex align-items-center justify-content-between">
              <div className={`c-font f-13  color-white text-truncate p-10 border}`}>{data.company_id_number}</div>
                  {/* comment icon start */}
             {userType == "contact" && <div className={`align-items-center before-block card-view-less-padding close-toast col-1 d-flex dropdown dropdown-center on-hover-active-toggle-img text-on-hover-active-green with-left-20 with_separator_10 h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        data-tip={_l("l_comments")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_company_id_number");
                          setselectedKeys("provider_company_id_number");
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircle
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                        comments={commentsList}
                        handleAddComment={handleAddEditComment}
                        deleteComment={handleDeleteComment}
                        id={providerID}
                        // task={task}
                        loading={commentsLoading}
                        mentionList={mentionsList.map((name) => {
                          let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                          return {
                            display: name.name,
                            id: id,
                            image: name.profile_image || "",
                            isteam: name.is_team ? 1 : 0,
                          };
                        })}
                        // handleImagePreview={handleImagePreview}
                        unContolList={true}
                        // totalUnreadCommentCount={task.unread_comment_count}
                        showComments={showComments}
                        setReplyCommentData={setReplyCommentData}
                        fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>

                  </UncontrolledDropdown>
                </div>}
              {/* comment icon end */}
              </div>
          
            </li>
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-1 color-white-60 f-13  fw-semibold">
              {_l('l_tax_id')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col d-flex align-items-center justify-content-between">
              <div className={`c-font f-13  color-white text-truncate p-10 border}`}>{data.v_t_number}</div>
               {/* comment icon start */}
               {userType == "contact" && <div className={`align-items-center before-block card-view-less-padding close-toast col-1 d-flex dropdown dropdown-center on-hover-active-toggle-img text-on-hover-active-green with-left-20 with_separator_10 h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        data-tip={_l("l_comments")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_v_t_number");
                          setselectedKeys("provider_v_t_number");
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircle
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                        comments={commentsList}
                        handleAddComment={handleAddEditComment}
                        deleteComment={handleDeleteComment}
                        id={providerID}
                        // task={task}
                        loading={commentsLoading}
                        mentionList={mentionsList.map((name) => {
                          let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                          return {
                            display: name.name,
                            id: id,
                            image: name.profile_image || "",
                            isteam: name.is_team ? 1 : 0,
                          };
                        })}
                        // handleImagePreview={handleImagePreview}
                        unContolList={true}
                        // totalUnreadCommentCount={task.unread_comment_count}
                        showComments={showComments}
                        setReplyCommentData={setReplyCommentData}
                        fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>

                  </UncontrolledDropdown>
                </div>}
              {/* comment icon end */}
              </div>
             
            </li>
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-1 color-white-60 f-13  fw-semibold">
              {_l('l_region_label')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col d-flex align-items-center justify-content-between">
              <div className={`c-font f-13  color-white text-truncate p-10 border}`}>{
                regionvalue && regionvalue.map((r)=>{
                    return(<>
                    <span className='me-2'>{r.label + ","}</span>
                    </>)
                })
              }</div>
                  {/* comment icon start */}
                  {userType == "contact" && <div className={`align-items-center before-block card-view-less-padding close-toast col-1 d-flex dropdown dropdown-center on-hover-active-toggle-img text-on-hover-active-green with-left-20 with_separator_10 h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        data-tip={_l("l_comments")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_regions");
                          setselectedKeys("provider_regions");
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircle
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                        comments={commentsList}
                        handleAddComment={handleAddEditComment}
                        deleteComment={handleDeleteComment}
                        id={providerID}
                        // task={task}
                        loading={commentsLoading}
                        mentionList={mentionsList.map((name) => {
                          let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                          return {
                            display: name.name,
                            id: id,
                            image: name.profile_image || "",
                            isteam: name.is_team ? 1 : 0,
                          };
                        })}
                        // handleImagePreview={handleImagePreview}
                        unContolList={true}
                        // totalUnreadCommentCount={task.unread_comment_count}
                        showComments={showComments}
                        setReplyCommentData={setReplyCommentData}
                        fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>

                  </UncontrolledDropdown>
                </div>}
              {/* comment icon end */}
              </div>
          
            </li>
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-1 color-white-60 f-13  fw-semibold">
              {_l('l_category')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col d-flex align-items-center justify-content-between">
              <div className={`c-font f-13  color-white text-truncate p-10 border}`}>
              {
                categoryListing && categoryListing.map((r)=>{
                    return(<>
                    <span className='me-2'>{r.label + ","}</span>
                    </>)
                })
              }
                </div>
                        {/* comment icon start */}
              {userType == "contact" && <div className={`align-items-center before-block card-view-less-padding close-toast col-1 d-flex dropdown dropdown-center on-hover-active-toggle-img text-on-hover-active-green with-left-20 with_separator_10 h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        data-tip={_l("l_comments")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_categories");
                          setselectedKeys("provider_categories");
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircle
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                        comments={commentsList}
                        handleAddComment={handleAddEditComment}
                        deleteComment={handleDeleteComment}
                        id={providerID}
                        // task={task}
                        loading={commentsLoading}
                        mentionList={mentionsList.map((name) => {
                          let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                          return {
                            display: name.name,
                            id: id,
                            image: name.profile_image || "",
                            isteam: name.is_team ? 1 : 0,
                          };
                        })}
                        // handleImagePreview={handleImagePreview}
                        unContolList={true}
                        // totalUnreadCommentCount={task.unread_comment_count}
                        showComments={showComments}
                        setReplyCommentData={setReplyCommentData}
                        fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>

                  </UncontrolledDropdown>
                </div>}
              {/* comment icon end */}
              </div>
      
            </li>
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-1 color-white-60 f-13  fw-semibold">
              {_l('l_company_address')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col d-flex align-items-center justify-content-between">
              <div className={`c-font f-13  color-white text-truncate p-10 border}`}>{data.address}</div>
              {/* comment icon start */}
              {userType == "contact" && <div className={`align-items-center before-block card-view-less-padding close-toast col-1 d-flex dropdown dropdown-center on-hover-active-toggle-img text-on-hover-active-green with-left-20 with_separator_10 h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        data-tip={_l("l_comments")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_address");
                          setselectedKeys("provider_address");
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircle
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                        comments={commentsList}
                        handleAddComment={handleAddEditComment}
                        deleteComment={handleDeleteComment}
                        id={providerID}
                        // task={task}
                        loading={commentsLoading}
                        mentionList={mentionsList.map((name) => {
                          let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                          return {
                            display: name.name,
                            id: id,
                            image: name.profile_image || "",
                            isteam: name.is_team ? 1 : 0,
                          };
                        })}
                        // handleImagePreview={handleImagePreview}
                        unContolList={true}
                        // totalUnreadCommentCount={task.unread_comment_count}
                        showComments={showComments}
                        setReplyCommentData={setReplyCommentData}
                        fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>

                  </UncontrolledDropdown>
                </div>}
              {/* comment icon end */}
              </div>
              
            </li>
            <li className="align-items-center g-0 lead-items p-1 row">
              <div className="c-font col-lg-1 color-white-60 f-13  fw-semibold">
              {_l('l_additional_data')} 
              </div>
              <div class="col-1 text-center">:</div>
              <div className="col d-flex align-items-center justify-content-between">
              <div className={`c-font f-13  color-white text-truncate p-10 border}`}>{data.additional_data}</div>
                  {/* comment icon start */}
                  {userType == "contact" && <div className={`align-items-center before-block card-view-less-padding close-toast col-1 d-flex dropdown dropdown-center on-hover-active-toggle-img text-on-hover-active-green with-left-20 with_separator_10 h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        data-tip={_l("l_comments")}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_additional_data");
                          setselectedKeys("provider_additional_data");
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircle
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                        comments={commentsList}
                        handleAddComment={handleAddEditComment}
                        deleteComment={handleDeleteComment}
                        id={providerID}
                        // task={task}
                        loading={commentsLoading}
                        mentionList={mentionsList.map((name) => {
                          let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                          return {
                            display: name.name,
                            id: id,
                            image: name.profile_image || "",
                            isteam: name.is_team ? 1 : 0,
                          };
                        })}
                        // handleImagePreview={handleImagePreview}
                        unContolList={true}
                        // totalUnreadCommentCount={task.unread_comment_count}
                        showComments={showComments}
                        setReplyCommentData={setReplyCommentData}
                        fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>

                  </UncontrolledDropdown>
                </div>}
              {/* comment icon end */}
              </div>
          
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProviderProfileTable