import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import { _l , getDatePickerLocale, getDatePickerTimeCaption } from "../../hooks/utilities";

const AddScheduleEmailModal = ({
  show,
  handleClose,
  handleSubmit
}) => {

  const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style with-min-height"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_add_email_schedule")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form.Group className="col-12 c-input-box position-relative mb-3 border-bottom p-15">
            <Form.Label className="input-label no-asterisk ">
              {_l("l_date_to_be_notified")}
            </Form.Label>
            <div className="d-flex align-items-center">
              <div
                className="custom-datepicker"
                data-bs-toggle="tooltip"
                title="Aug, 8 2022"
              >
                <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                  <CalendarBlankIcon className="HW18 opacity-60" />
                  <DatePicker
                    portalId="custom_datepicker"
                    calendarClassName="custom-datepicker"
                    timeCaption={getDatePickerTimeCaption()}
                    className="close-toast"
                    showTimeSelect
                    selected={startDate}
                    locale={getDatePickerLocale()}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Start Date"
                    dateFormat="MMMM d, yyyy h:mmaa"
                    selectsStart
                    calendarStartDay={1}
                    minDate = {new Date()}
                  ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                </div>
              </div>
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" onClick={handleClose}>
            {_l("l_close")}
          </Button>
          <Button variant="primary" onClick={()=>{handleSubmit(startDate);}} >{_l("l_send")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddScheduleEmailModal;
