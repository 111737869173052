
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DropdownItem, DropdownMenu } from "reactstrap";
import Select from "react-select";
import { showMessage } from "../../actions/messages";
import { _l, countryCodedigitLength, showError, validateEmail } from "../../hooks/utilities";
import benchmarkServices from "../../services/benchmark-services";
import Spinner from "react-bootstrap/Spinner";
import PhoneInput from "react-phone-input-2";
import customerServices from "../../services/customer-services";

const InviteProvider = ({ item, setNewMyrStaffPast, taskId}) => {
    const dispach = useDispatch();
    const [email, setEmail] = useState("");
    const [loader, setLoader] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [phonenumber, setPhonenumber] = useState("");
    const [countryCode, setCountryCode] = useState("+33");
    const [countryCodes, setCountryCodes] = useState([]);
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [numberFormate, setNumberFormate] = useState('');
    const [numberlength, setNumberLength]= useState({max : 14, min : 9});
    const project_id = localStorage.getItem("selectedOffice");
    const {country_codes} = useSelector((state) => state.customer);
    const selectedOffice = localStorage.getItem("selectedOffice");
    const userType = localStorage.getItem("user_type");

    useEffect(() => {
        if (country_codes && country_codes.length > 0) {
            setCountryCodes(
                country_codes.map((code) => {
                    return {
                        value: code.code_value,
                        label: code.country_code,
                    };
                })
            );
        }
    }, [country_codes])
    const handleSubmit = () => {
        if (email == "") {
            dispach(showMessage("unsucess", _l("l_error"), _l("l_please_enter_email")));
        } else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            dispach(showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_email")));
        } else if (name == "") {
            dispach(showMessage("unsucess", _l("l_error"), _l("l_please_enter_name")));
        }else if (company == "") {
            dispach(showMessage("unsucess", _l("l_error"), _l("l_please_enter_company_name")));
        // } else if (countryCode == "") {
        //     dispach(showMessage("unsucess", _l("l_error"), _l("l_select_country_code")));
        } else if (phonenumber.trim() == "" || !phonenumber.length || (numberlength.max != phonenumber.length && numberlength.min != phonenumber.length)) {
            dispach(showMessage("unsucess", _l("l_error"), _l("l_please_enter_mobile")));
        } else {
            let providerArray = [{
                email: email,
                phonenumber: countryCode + " " + phonenumber.slice(countryCode.length - 1),
                name: name,
                company_name : company,
            }]
            const return_invitee_list = 1
            setLoader(true);
            benchmarkServices.addBulkProvidersToCategory(project_id, item.id, providerArray,return_invitee_list).then((res) => {
                setLoader(false);
                if (res.status == 1) {
                    setToggle(true);
                    setNewMyrStaffPast(res)
                    clearFormData()
                    dispach(showMessage("sucess", _l("l_sucess"), _l(res.message)));
                } else {
                    dispach(showMessage("unsucess", _l("l_error"), _l(res.message)));
                }

            })
        }
    }
    const clearFormData = () => {
        setName('');
        setPhonenumber("33");
        setEmail('');
        setCountryCode("");
        setCompany("")
    }

    const emailCheck = async(email) => {
        if (validateEmail(email)) {
          try {
            const response = await customerServices.emailCheckInvitees(
              email,
              item.id,
              selectedOffice,
              userType === "operator"
            );
            if (response.status) {
              let countryCode = '+33' ;
              let phoneNumbers =  '';
              if (response.data.phonenumber && response.data.phonenumber.includes("+")) {
                countryCode = response.data.phonenumber.match(/^\+(\d+)/)[0]
                phoneNumbers = response.data.phonenumber.match(/\d+/g).join('');
              }
              let maxLength = countryCodedigitLength(countryCode)
              setNumberLength(maxLength)
              setPhonenumber(phoneNumbers);
              setCountryCode(countryCode)
              setName(response.data.fullname);
              setCompany(response.data.company);
            }
          }
          catch(e)
          {
            console.error(e);
            showError("l_something_went_wrong");
          }
        }
      };
    useEffect(() => {
        if (numberFormate.dialCode) {
            setCountryCode(`+${numberFormate.dialCode}`)
            const dialcodeL = Number(numberFormate.dialCode.length);
            let digitlength = numberFormate.format.match(/\./g).slice(numberFormate.dialCode.length);
            if (["32","352", "49", "44"].includes(numberFormate.dialCode)) {
                if (["49", "44"].includes(numberFormate.dialCode)) {
                    setNumberLength({max : 11 + dialcodeL, min : 10 + dialcodeL})
                }else{
                    setNumberLength({max : 10 + dialcodeL, min : 9 + dialcodeL})
                }
            }else{
                setNumberLength({max : digitlength.length + dialcodeL, min : digitlength.length + dialcodeL})
            }
        }
    }, [numberFormate])
    const dropdownDemoForm =
        <>
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>{_l("l_provider_email")}</Form.Label>
                    <Form.Control
                        className="p-10"
                        type="email"
                        placeholder={_l("l_email_placeholder")}
                        value={email}
                        onChange={(e) => {
                            emailCheck(e.target.value);
                            setEmail(e.target.value);
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{_l("l_provider_name_placeholder")}</Form.Label>
                    <Form.Control
                    className="p-10"
                        type="text"
                        placeholder={_l("l_full_name_placeholder")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{_l("l_company_name")}</Form.Label>
                    <Form.Control
                    className="p-10"
                        type="text"
                        placeholder={_l("l_company_name")}
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3 position-relative select-half-separator">
                    <Form.Label>{_l("l_mobile_number")}</Form.Label>
                    {/* <div className="row">
                        <Form.Group className="col-lg-4 c-input-box position-relative pe-0">
                            <Select
                                className="custom-select-menu"
                                options={countryCodes}
                                value={countryCode}
                                onChange={(e) => { setCountryCode(e) }}
                                classNamePrefix="react-select"
                            />
                        </Form.Group>
                        <Form.Group className="col-lg-8">
                            <Form.Control
                                type="number"
                                placeholder={_l("l_mobile_number_placeholder")}
                                value={phonenumber}
                                onChange={(e) => setPhonenumber(e.target.value)}
                            />
                        </Form.Group>
                    </div> */}
                    <div className="align-items-center d-flex m-0 react-tel-input-small row select-half-separator-main">
                        <PhoneInput
                        className="p-10"
                            autoFormat={true}
                            enableLongNumbers = {true}
                            countryCodeEditable={false}
                            disableCountryGuess={true}
                            searchStyle={{"border-color" : "red"}}
                            disableSearchIcon={true}
                            enableSearch={true}
                            localization={""}
                            country='fr'
                            preferredCountries={['fr', 'de', 'be', 'es', 'ch','gb','pl','lu']}
                            name="mobile_number"
                            value={phonenumber}
                            onChange={(phone, formattedValue) => {
                                setNumberFormate(formattedValue);
                                setPhonenumber(phone);
                            }}
                            isValid={(value) => {
                                if (value.length > numberlength.max) {
                                    setPhonenumber(value.slice(0,numberlength.max));
                                } else {
                                    return true;
                                }
                            }}
                        />
                    </div>
                </Form.Group>
            </Form>
            <Button variant="primary" className={`${loader ? "for-disabled" : ""}`} size="sm" onClick={() => { handleSubmit() }}>
                {loader
                    ? _l('l_please_wait')
                    : _l("l_submit")

                }
                {loader ? <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                />
                    : <></>
                }
                    </Button>
        </>
        ;

    return (
        <>
            <DropdownMenu  className={`w-250 overflowscroll AssigneeDropdownCloseOutside${taskId}`} container="body">
                <DropdownItem toggle={false} className="no-hover">
                   
                    {dropdownDemoForm}
                </DropdownItem>
            </DropdownMenu>
        </>
    );
};

export default InviteProvider;
