import React, { useEffect, useState } from "react";
import CustomerServices from "../../services/customer-services";
import { _l, getPreviewFromURL, removeDuplicateObjects, isCamFound } from "../../hooks/utilities";
import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import Toasts from "../Toasts/Toasts";
import { useParams } from "react-router-dom";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import { useDispatch, useSelector } from "react-redux";
import { Paperclip,DotsThreeVertical, CloudArrowUp } from "phosphor-react";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { getCommentsForAudiocall, getTaskAttechments } from "../../actions/customer";
import { setComments } from "../../actions/documents-actions/documents-actions";
import FeedchatInTaskModal from "./FeedchatInTaskModal";
import FeedchatInTaskModalButton from "./FeedchatInTaskModalButton";
import { callAction } from "../../actions/chat";
import jquery from "jquery";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const FileUploadComponent = ({ task_id, projectId, attachmentCountUpdate, closeAllTost, toastClose, searchAttchment = '', isExternal, readOnly = false, isConvertedTask = false, isRequestTask = false, taskDetails, type= "" ,handleAction,data }) => {
  const dispatch = useDispatch();
  const documentData = useSelector((state) => state.documentsReducer.task_comments);
  const {isCallFullscreen, callComments } = useSelector((state) => state.customer);
  const {callStatus, isCallRecived} = useSelector((state) => state.chat);
  const [taskAttachments, setTaskAttachments] = useState([]);
  const [defaultTaskAttachments, setDefaultTaskAttachments] = useState([]);
  const [openGallery, setOpenGallery] = useState(false);
  const contact_name = localStorage.getItem("full_name");
  const [toastHeader, setToastHeader] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("unsucess");
  const [toastMessage, setToastMessage] = useState("");
  const [attachmentId, setAttachmentId] = useState(0);
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(0);
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const [isMute, setIsMute] = useState(false);
  const [isCameraOff, setIsCameraOff] = useState(false);
  const [isCameraFound, setIsCameraFound] = useState(true);
  const [isScreenShareOn, setIsScreenShareOn] = useState(false);

  const task_attachments = useSelector((state) => state.customer.task_attachments);
  const { id: hash_id, page } = useParams();
    const checklistStateData = useSelector(
    (state) => state.customer.taskChecklist
  );
  useEffect(() => {
    if (task_id) {
      dispatch(getTaskAttechments(task_id, projectId, '', (isExternal || hash_id) ? hash_id : false));
    }
    
  }, [task_id ,taskAttachments.length]);

  useEffect(() => {
    if (task_attachments) {
      setTaskAttachments(task_attachments);
      setDefaultTaskAttachments(task_attachments);
      // task_attachments.forEach((item) => {
      //   // setTaskAttachments((taskAttachments) => [...taskAttachments, item]);
      //   setDefaultTaskAttachments((defaultTaskAttachments) => [
      //     ...defaultTaskAttachments,
      //     item,
      //   ]);
      // });
    }
    if (type == "poi" && data && data.previewArray) {
      setTaskAttachments(data.previewArray);
      setDefaultTaskAttachments(data.previewArray);
    }
  }, [task_attachments,data,type])

  useEffect(() => {
    openToastClose()
  }, [toastClose])
  // const SliderImages  = [
  //   {
  //   original: {attachment.image_url},
  //   thumbnail: {attachment.image_url},
  //   },
  // ];

  const openToastClose = () => {
    setToastClass("unsucess");
    setToastHeader("");
    setConfirmButtonText("");
    setCancelButtonText("");
    setToastMessage(0);
    setAttachmentId(0);
    setShowToast(false);
  };
  const setAttchmentOnTop = (id) => {
    const targetAttchement = defaultTaskAttachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = defaultTaskAttachments.filter(
      (attachment) => attachment.id != id
    );
    setDefaultTaskAttachments(targetAttchement.concat(otherAttachments));
  };

  const deleteImage = (id, comment_id) => {
    CustomerServices.deleteTaskDocument(projectId, comment_id, 1, id).then((res) => {
      if (res && res.status == 1) {
        attachmentCountUpdate(task_id, -1);
        setTaskAttachments(
          taskAttachments.filter((attachment) => attachment.id !== id)
        );
        dispatch(toastCloseAction());
        let newArr = documentData
        newArr.forEach(com => {
          if (com.id == task_id) {
            com.comments = com.comments.filter((data) => data.id != comment_id)
          }
        });
        dispatch(setComments(newArr.filter((c) => c.id == task_id)[0].comments, task_id, "task"));
      } else {
        dispatch(
          showMessage("unsucess", _l("l_error"), res.message)
        )
      }
    });
    // dispatch(getComments(task_id, "task", isExternal, "", 1, 0));
  };
  useEffect(() => {

    if (searchAttchment != "") {
      let tempAttchments = defaultTaskAttachments.filter(
        (attachment) => {
          if (localStorage.getItem('user_type') == 'contact') {
            return attachment && attachment.contact_full_name &&
              attachment.contact_full_name.toLowerCase().includes(searchAttchment.toLowerCase())
          } else {
            return attachment && attachment.staff_full_name &&
              attachment.staff_full_name.toLowerCase().includes(searchAttchment.toLowerCase())
          }
        }
      )
      setTaskAttachments(tempAttchments);
    } else {
      setTaskAttachments(defaultTaskAttachments);
    }
  }, [searchAttchment]);

  const handleDeleteFile = (id, comment_id) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_you_want_to_delete_this_attachment"),
        _l("l_delete"),
        _l("l_cancel"),
        undefined,
        () => { deleteImage(id, comment_id) }
      )
    );
  }

  const handleDownloadFile =(url)=>{
    window.open(url, "_self");
  }
  const getFileDetails = (fileName) => {
    const lastIndex = fileName && fileName.lastIndexOf('.');
    
    if (lastIndex === -1) {
      // File has no extension
      return { type: 'Unknown', extension: 'None' };
    }
    const fileExtension = fileName && fileName.slice(lastIndex + 1);
  
    return fileExtension;
  };
useEffect(() => {
  if (callComments && callComments.length > 0) {
    let tempAttchments = []
    callComments.map((comment) => {
      if(comment.attachments){
        comment.attachments.map((attachment) => {
          let obj={ 
            altitude: comment.altitude ,
            contact_firstname: comment.contact_full_name,
            contact_full_name: comment.contact_full_name,
            contact_id: comment.contact_id,
            contact_image: null,
            contact_lastname:  comment.contact_full_name,
            creator_image_url: comment.profile_url,
            dateadded: comment.dateadded, 
            file_name: attachment.file_url,
            file_type: getFileDetails(attachment.file_url),
            id: comment.id,
            image_url: attachment.file_url,
            is_allowed_to_edit: 0,
            latitude: "0",
            longitude: "0",
            staff_firstname: null,
            staff_full_name: " ",
            staff_image: null,
            staff_lastname: null,
            staffid: "0",
            task_comment_id: "",
            task_id: callStatus.task_id,
          }
          tempAttchments.push(obj)
        })
      }
    })
    setTaskAttachments(tempAttchments)
//     if (isCallRecived && !jquery.isEmptyObject(callStatus)&& tempAttchments.length > 0) {
//     dispatch(callAction({
//       userId: callStatus.callerInfo._id,
//       receiverId: callStatus.receiverInfo._id,
//       callRole: callStatus.callRole,
//       actionKey: "newComment",
//       actionVal: "test",
//       extraData : {
//           projectId : localStorage.getItem("selectedOffice"),
//           checklist : removeDuplicateObjects(checklistStateData),
//           comments : tempAttchments
//       }
    
//   }));
// }
  }
}, [JSON.stringify(callComments)])

// attachments
useEffect(() => {
  if(callStatus && callStatus.attachmentsList)
  {
    setTaskAttachments(callStatus.attachmentsList)
  }
}, [JSON.stringify(callStatus)])

const checkIsCamFound = async () => {
  const camFound = await isCamFound();
  if(!camFound){
      setIsCameraOff(true);
      setIsCameraFound(false);
  }
};

useEffect(() => {
  checkIsCamFound();
}, []);

  return (
    <>
      {showToast ? (
        <Toasts
          delay={2000}
          handler={() => {
            setShowToast(false);
            setAttachmentId(0);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={() => {
            setShowToast(false);
            setAttachmentId(0);
          }}
          confirmButtonText={confirmButtonText}
          confirmButtonHandler={() => {
            deleteImage(attachmentId.id, attachmentId.comment_id);
            setAttachmentId(0);
            setShowToast(false);
          }}
        />
      ) : (
        <></>
      )}

      <div className={`file-parent-box border-top h106px position-sticky bottom-0  mt-auto p-10 bottom67px z-index-12  ${type == "lead" ? "px-0" :""} ${isCallFullscreen ? "" : ""}`}>
      <div className={`align-items-center bottom-0 d-flex  ${type == "lead" ? "py-0 p-10" :""}`}>
      {/* <div className="comment-input-box bg-white-03 with-sticky "> */}
        <div className={`file-image-list  h85w115 pe10px pt-0 pb-0 ${isRequestTask ? 'd-none' : ''} ${readOnly ? "for-disabled" : ''} ${isExternal ? 'd-none' : ""}`}> 
          <label className="c-list-icon w-100 position-relative">
            <div className="uploadboxhw comman-round-box with-bg radius_3 text-center d-flex w-100 flex-column bg-white-03 upload-box justify-content-center">
              <input
                type="file"
                className="absolute-input "
                multiple
                disabled={readOnly}
                onChange={(event) => {
                  if(type == "poi"){
                    handleAction("poi_files",event.target.files)
                  }else{
                  CustomerServices.addLinkComment(
                    task_id,
                    projectId,
                    "",
                    undefined,
                    undefined,
                    undefined,
                    1,
                    event.target.files
                  ).then((res) => {
                    let tempImage = [];
                    if (res.status && res.data) {
                      res.data.files_list.map((img) => {
                        tempImage.push({
                          id: img.file_id,
                          image_url: img.image_url,
                          contact_full_name: contact_name,
                          file_name: img.file_name,
                          is_allowed_to_edit: 1,
                          creator_image_url: localStorage.getItem("contact_image"),
                          comment_file_id: res.data.comment_id
                        });
                      });
                      setTaskAttachments(
                        tempImage.concat(taskAttachments)
                      );
                      attachmentCountUpdate(task_id, res.data.files_list.length);
                    }
                    if (isCallRecived) {
                      dispatch(callAction({
                        userId: callStatus.callerInfo._id,
                        receiverId: callStatus.receiverInfo._id,
                        callRole: callStatus.callRole,
                        actionKey: "newAttachments",
                        actionVal: res.data.comment_id,
                        extraData : {
                            projectId : localStorage.getItem("selectedOffice"),
                            checklist : removeDuplicateObjects(checklistStateData),
                            comments : callComments,
                            attachments : tempImage.concat(taskAttachments)
                        }
                    }));
                      
                    }

                  });
                }
                }}
              />
              <CloudArrowUp size={32}  weight="light" className="c-icons my-0 opacity70"  />
              <div className="c-font f-10 color-white-60 mt-10"> {_l('l_upload_file')}</div>
            </div>
            <div className="custom_file d-flex align-items-center justify-content-between w-100 d-none"> 
              <div className="btn btn-sm btn-white-10">
                <span className="c-font f-10 color-white-60"> {_l('l_upload_file')}</span>
              </div>
              <Paperclip size={16}  weight="light" className="c-upload_icon" />
            </div>
          </label>
        </div>
      {/* </div> */}
      <Lightbox
        open={openGallery}
        close={() => setOpenGallery(false)}
        slides={defaultTaskAttachments.map((img) => {
          if (type == "poi") {
            return { src: img.image_url };
          } else {
            return { src: getPreviewFromURL(img.image_url) };
          }
        })}
      />
      <div className="file-list-wrapper task-checklist-wrapper d-flex flex-column h-100 flex-grow-1 hhghrthtnrtntjyyt">
        <div className="d-flex flex-wrap">
          {taskAttachments && taskAttachments.length > 0 && taskAttachments.filter((file,key) => (isRequestTask || isExternal) ? key < 4 : key < 3).map((attachment, index) => {
            let preview = {};
            if (type == "poi") {
              preview.url = attachment.image_url;
            }else{
              preview.url = getPreviewFromURL(attachment.image_url);
            }
            return (
              <div
                key={index}
                className="file-image-list pt-0 pb-0 ps-0 h85px uploadimages flex-grow-1 maxw200px"
              >
                <div className="c-list-icon w-100 position-relative with-overlay dropend bg-white-05">
                  <div className={`uploadboxhw comman-round-box with-bg radius_3 w-100 with-react-fancybox`} 
                    onClick={() => {
                      setAttachmentDetails(attachment)
                      if (typeof preview.url !== "string" && type != "poi" ) {
                        const link = document.createElement("a");
                        link.href = attachment.image_url;
                        link.download = attachment.file_name;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      } else {
                        if(isExternal) {
                          setOpenGallery(true);
                          setAttchmentOnTop(attachment.id);
                        } else {
                          setShowTaskImage(true);
                          setAttachmentDetails(attachment);
                          // setOpenGallery(true);
                          // setAttchmentOnTop(attachment.id);
                        }
                      }
                    }}
                    type="button"
                  >
                    {
                      typeof preview.url === "string"
                      ?
                      <div
                        className={`comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex  ${(((isRequestTask || isExternal) && index == 3 && taskAttachments.length > 4) || ((!isRequestTask && !isExternal) && index == 2 && taskAttachments.length > 3)) ? "with-overlay-img":""}`}
                        type="button"
                        style={{
                          backgroundImage: `url('${preview.url}')`,
                        }}
                      >
                        {(((isRequestTask || isExternal) && index == 3 && taskAttachments.length > 4) || ((!isRequestTask && !isExternal) && index == 2 && taskAttachments.length > 3)) ?
                          <span className="m-auto position-relaive z-index-2 c-font fw-semibold title-fonts f-18 span-color">+{((isRequestTask || isExternal) && index == 3) ? taskAttachments.length - 3 : taskAttachments.length - 2}</span>
                          :
                          <></>
                      }
                      </div>
                      :["wav","mp3"].includes(getFileDetails(attachment.image_url || attachment.file_name)) ?
                      <>
                            <div className="chat-audio withf12 h-100 small pt-10px">
                              <AudioPlayer
                                style={{ width: '200px', margin: "0px" }}
                                layout="horizontal"
                                // autoPlay
                                className="audio_player p-1 bg-transparent box-shadow-none h-100"
                                showJumpControls={false}
                                showSkipControls={false}
                                customAdditionalControls={[]}
                                customVolumeControls={[]}
                                src={attachment.image_url || attachment.file_name}
                                autoPlay={false}
                                autoPlayAfterSrcChange={false}
                              />
                            </div>
                      </>
                      :
                      <div className="h-100 w-100 d-flex">
                        <preview.url size={72} weight="light" className="c-icons"/>
                      </div>
                    }
                    <div className="file-uploader-name d-none">
                      <div className="d-flex align-items-center">
                        <div className="h15w15 comman-round-box with-bg rounded-circle bg-white-05">
                          <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${attachment.creator_image_url}')` }}></div>
                        </div>
                        <div className="ps-2 text-truncate w100minus20">{localStorage.getItem('user_type') == 'contact' ? attachment.contact_full_name : attachment.staff_full_name}</div>
                      </div>

                    </div>
                    {/* <ImageGallery items={attachment.image_url} />; */}
                    {/* <ReactFancyBox
                      thumbnail={attachment.image_url}
                      image={attachment.image_url}
                      showCloseBtn

                      // style={{
                      //   backgroundImage: `url(${attachment.image_url})`,
                      // }}
                    /> */}
                  </div>

                  <a href="#/"
                    className={`line-height-0 file-action-option ${attachment.is_allowed_to_edit == 1 ? '' : 'for-disabled'} ${isExternal ? "d-none" :""}`} 
                    type="button"
                    id="DownloadDeleteDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-tip={_l("l_options")}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                  >
                    <DotsThreeVertical size={18}  weight="light" className="c-icons" />
                  </a>
                  {attachment.is_allowed_to_edit == 1 || isConvertedTask == false ?
                    <ActionDropdown
                      actions={[
                        {
                          actionName: "Delete",
                          actionHandler: () => {
                            handleDeleteFile(attachment.id, attachment.task_comment_id > 0 ? attachment.task_comment_id : attachment.id)
                            // setShowToast(false);
                            // setToastHeader(_l('l_are_you_sure'));
                            // setToastClass("toast-with-btn");
                            // setToastMessage(_l('l_you_want_to_delete_this_attachment'));
                            // setConfirmButtonText(_l('l_delete'));
                            // setCancelButtonText(_l('l_cancel'));
                            // setShowToast(true);
                            // setAttachmentId({ id: attachment.id, comment_id: attachment.comment_file_id });
                            // toastClose()
                          },
                          actionIcon: "TrashIcon",
                        },
                        {
                          actionName: "Download",
                          actionHandler: () => {
                            handleDownloadFile(attachment.image_url)
                          },
                          actionIcon:"DownloadSimpleIcon"
                        }
                      ]}
                    /> : <></>}

                </div>
                <div className="max-w-130px pt-2 d-none">
                  <div className="c-font text-truncate f-12 color-white-60 ">
                    {attachment.file_name}
                  </div>
                  <div className="c-font text-truncate f-12 color-white-60 d-none">
                    100KB
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {taskAttachments && taskAttachments.length == 0 ? (
          <CommanPlaceholder imgWidth="small-image"  imgType="no-images" mainSpacing="m-auto" placeholderText = {_l('l_no_attachments')} />
        ) : (
          <></>
        )}
      </div>
      </div>
      </div>

      {showTaskImage && Object.keys(attachmentDetails).length > 0 ? 
        <CommanOffCanvas
          show={showTaskImage}
          handleClose={() => {
            setShowTaskImage(false);
          }}
          data={{
            id: attachmentDetails.id,
            taskId: task_id,
            projectId: projectId,
            attachmentDetails: attachmentDetails,
            taskDetails: taskDetails,
            allAttachments: taskAttachments
          }}
          docType={"imageview"}
          setSelectedId={setSelectedAttachmentId}
          selectedTaskId={task_id}
          handleParentComponentAction={() => {
            
          }}
        /> : <></>
      }
    </>
  );
};

export default FileUploadComponent;
