import React from 'react'
import useScheduleSteps from '../../../hooks/useScheduleSteps';
import { _l } from '../../../hooks/utilities';

const ChecklistCountHeader = ({selectedTab, componentReender="",step=""}) => {
    const {
        calculationsCounts,
    } = useScheduleSteps();

    return (
        <div className='bg-base-header-color timesheet-head border-bottom-base-body-3px'>
        <div className="d-flex comman-data-table bg-base-header-color light-theme-grey-light-bg-imp" style={{height: "76px"}}>
            {
                componentReender == "leftpart" ?   
            <div className={`bg-transparent d-flex flex-column justify-content-center p-10 ps-3`}>
                <div className="title-fonts fw-semibold c-font f-18">
                    {Object.values(calculationsCounts && calculationsCounts.headerCounts).length
                        ? Object.values(calculationsCounts.headerCounts).reduce(
                            (a, b) => a + b
                        )
                        : 0}
                </div>
                <div className="c-font f-14 color-white-80 pt-2">
                    {_l("l_total_no_of_item")}
                </div>
            </div>
            : <></>
            }
            {
                componentReender == "rightpart" ?
            <div className={`d-flex flex-column res-overflow-auto p-0 w-100`}>
                <table className="dataTable h-100">
                    <thead className={`bg-base-header-color light-theme-grey-light-bg-imp table-th-px-26px ${selectedTab == "schedule-summary" ? " w-300" : "" }`} >
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts && calculationsCounts.headerCounts && calculationsCounts.headerCounts.mon}</div>
                            <div className="">{_l("l_week_single_letter_monday")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.tue}</div>
                            <div className="">{_l("l_week_single_letter_tuesday")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.wed}</div>
                            <div className="">{_l("l_week_single_letter_wednesday")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.thu}</div>
                            <div className="">{_l("l_week_single_letter_thrusday")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.fri}</div>
                            <div className="">{_l("l_week_single_letter_friday")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.sat}</div>
                            <div className="">{_l("l_week_single_letter_saturday")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.sun}</div>
                            <div className="">{_l("l_week_single_letter_sunday")}</div>
                        </th>
                        <th
                            style={{ width: "1%" }}
                            className="text-center user-select-none"
                        ></th>
                        {selectedTab == "assignment-schedule" ? ( <>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.monthly}</div>
                            <div className="">{_l("l_single_letter_monthly")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.quarterly}</div>
                            <div className="">{_l("l_single_letter_quaterly")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.half_yearly}</div>
                            <div className="">{_l("l_single_letter_semester")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.yearly}</div>
                            <div className="">{_l("l_single_letter_yearly")}</div>
                        </th>
                        </>
                        ) : ""}
                        {selectedTab == "schedule-summary" ? ( <>
                        <th
                            style={{ width: "8%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.monthly}</div>
                            <div className="">{_l("l_single_letter_monthly")}</div>
                        </th>
                        <th
                            style={{ width: "8%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.quarterly}</div>
                            <div className="">{_l("l_single_letter_quaterly")}</div>
                        </th>
                        <th
                            style={{ width: "8%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.half_yearly}</div>
                            <div className="">{_l("l_single_letter_semester")}</div>
                        </th>
                        <th
                            style={{ width: "8%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.yearly}</div>
                            <div className="">{_l("l_single_letter_yearly")}</div>
                        </th>
                        </>
                        ) : ""}
                        {selectedTab == "assignment-schedule" ? ( <>
                        <th
                            style={{ width: "10%" }}
                            className="text-center user-select-none letter_spacing05 opacity-0"
                        >
                            {_l("l_action")}
                        </th>
                        <th
                            style={{ width: "10%" }}
                            className="text-center user-select-none letter_spacing05 opacity-0"
                        >
                            {_l("l_select")}
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center user-select-none letter_spacing05"
                        ></th>
                        </>
                        ) : ""}
                        {step == "assignment-screen" ? (
                            <>
                            <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.monthly}</div>
                            <div className="">{_l("l_single_letter_monthly")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.quarterly}</div>
                            <div className="">{_l("l_single_letter_quaterly")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.half_yearly}</div>
                            <div className="">{_l("l_single_letter_semester")}</div>
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                        >
                            <div className="text-center fw-semibold count-absolute">{calculationsCounts.headerCounts.yearly}</div>
                            <div className="">{_l("l_single_letter_yearly")}</div>
                        </th>
                            <th
                            style={{ width: "5%" }}
                            className="text-center position-relative align-bottom"
                            >
                            <div className="text-center fw-semibold"></div>
                            <div className=""></div>
                            </th>
                            <th
                            style={{ width: "5%" }}
                            className="text-center"
                            >
                            <div className="text-center fw-semibold"></div>
                            <div className=""></div>
                            </th>
                            <th
                            style={{ width: "5%" }}
                            className="text-center"
                            >
                            <div className="text-center fw-semibold"></div>
                            <div className=""></div>
                            </th>
                            <th
                            style={{ width: "5%" }}
                            className="text-center"
                            >
                            <div className="text-center fw-semibold"></div>
                            <div className=""></div>
                            </th>
                            <th
                            style={{ width: "5%" }}
                            className="text-center"
                            >
                            <div className="text-center fw-semibold"></div>
                            <div className=""></div>
                            </th>
                            </>
                        ):(<></>)}
                        {step == "summary-screen" ? (
                            <>
                            <th
                            style={{ width: "8%" }}
                            className="text-center"
                        >
                            <div className="text-center fw-semibold">{calculationsCounts.headerCounts.monthly}</div>
                            <div className="">{_l("l_single_letter_monthly")}</div>
                        </th>
                        <th
                            style={{ width: "8%" }}
                            className="text-center"
                        >
                            <div className="text-center fw-semibold">{calculationsCounts.headerCounts.quarterly}</div>
                            <div className="">{_l("l_single_letter_quaterly")}</div>
                        </th>
                        <th
                            style={{ width: "8%" }}
                            className="text-center"
                        >
                            <div className="text-center fw-semibold">{calculationsCounts.headerCounts.half_yearly}</div>
                            <div className="">{_l("l_single_letter_semester")}</div>
                        </th>
                        <th
                            style={{ width: "8%" }}
                            className="text-center"
                        >
                            <div className="text-center fw-semibold">{calculationsCounts.headerCounts.yearly}</div>
                            <div className="">{_l("l_single_letter_yearly")}</div>
                        </th>
                            </>
                        ):(<></>)}
                    </thead>
                </table>
            </div>
            :<></>
            }
            <div className="d-flex flex-column res-overflow-auto flex-grow-1 p-0 d-none">
                <table className="dataTable">
                    <thead className="bg-transparent">
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        >
                            {calculationsCounts.headerCounts.mon}
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        >
                            {calculationsCounts.headerCounts.tue}
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        >
                            {calculationsCounts.headerCounts.wed}
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        >
                            {calculationsCounts.headerCounts.thu}
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        >
                            {calculationsCounts.headerCounts.fri}
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        >
                            {calculationsCounts.headerCounts.sat}
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        >
                            {calculationsCounts.headerCounts.sun}
                        </th>
                        <th
                            style={{ width: "1%" }}
                            className="text-center fw-semibold user-select-none"
                        ></th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        >
                            {calculationsCounts.headerCounts.monthly}
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        >
                            {calculationsCounts.headerCounts.quarterly}
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        >
                            {calculationsCounts.headerCounts.half_yearly}
                        </th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        >
                            {calculationsCounts.headerCounts.yearly}
                        </th>
                        <th style={{ width: "10%" }} className="text-center user-select-none"></th>
                        <th style={{ width: "10%" }} className="text-center user-select-none"></th>
                        <th style={{ width: "10%" }} className="text-center user-select-none"></th>
                        <th
                            style={{ width: "5%" }}
                            className="text-center fw-semibold"
                        ></th>
                    </thead>
                    <tbody className="c-font f-12 body-fonts fw-normal">
                        <tr className="bg-transparent">
                            <td
                                style={{ width: "5%" }}
                                className=" mt-2"
                            >
                                {_l("l_week_single_letter_monday")}
                            </td>
                            <td
                                style={{ width: "5%" }}
                                className=" mt-2"
                            >
                                {_l("l_week_single_letter_tuesday")}
                            </td>
                            <td
                                style={{ width: "5%" }}
                                className=" mt-2"
                            >
                                {_l("l_week_single_letter_wednesday")}
                            </td>
                            <td
                                style={{ width: "5%" }}
                                className=" mt-2"
                            >
                                {_l("l_week_single_letter_thrusday")}
                            </td>
                            <td
                                style={{ width: "5%" }}
                                className=" mt-2"
                            >
                                {_l("l_week_single_letter_friday")}
                            </td>
                            <td
                                style={{ width: "5%" }}
                                className=" mt-2"
                            >
                                {_l("l_week_single_letter_saturday")}
                            </td>
                            <td
                                style={{ width: "5%" }}
                                className=" mt-2"
                            >
                                {_l("l_week_single_letter_sunday")}
                            </td>
                            <td
                                style={{ width: "1%" }}
                                className=" mt-2 user-select-none"
                            ></td>
                            <td
                                style={{ width: "5%" }}
                                className=" mt-2"
                            >
                                {_l("l_single_letter_monthly")}
                            </td>
                            <td
                                style={{ width: "5%" }}
                                className=" mt-2"
                            >
                                {_l("l_single_letter_quaterly")}
                            </td>
                            <td
                                style={{ width: "5%" }}
                                className=" mt-2"
                            >
                                {_l("l_single_letter_semester")}
                            </td>
                            <td
                                style={{ width: "5%" }}
                                className=" mt-2"
                            >
                                {_l("l_single_letter_yearly")}
                            </td>
                            <td
                                style={{ width: "10%" }}
                                className=" mt-2 user-select-none"
                            ></td>
                            <td
                                style={{ width: "10%" }}
                                className=" mt-2 user-select-none"
                            ></td>
                            <td
                                style={{ width: "10%" }}
                                className="text-center fw-normal py-1 user-select-none"
                            ></td>
                            <td
                                style={{ width: "5%" }}
                                className="text-center fw-normal py-1 user-select-none"
                            ></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        {/* <div className='bg-base-header-color p-1'></div> */}
        </div>
    )
}

export default ChecklistCountHeader