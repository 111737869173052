import React from "react";
import { Button, Modal } from "react-bootstrap";
import { _l } from "../../hooks/utilities";
import AlertTraingle  from "../../assets/images/alerttriangle.svg";
import { useNavigate } from "react-router-dom";

const GoToLoginModal = () => {
  const navigate = useNavigate();
  return (
    <Modal
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
    >
      <Modal.Body className="">
        <div className="form-wrapper-main d-flex">
          <div className="text-center w-100">
            <img src={AlertTraingle} alt="" />
            <div className="mt-4">
                <div className="c-font f-18 fw-semibold title-fonts mt-2">
                    {_l("l_access_to_this_page_of_the_document_is_restricted")}
                </div>
                <div className="c-font f-14 title-fonts mt-2 color-white-70">
                    {_l("l_please_log_in_to_your_account_to_view_the_content")}
                </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center pt-0 pb-4">
            <Button variant="primary" onClick={() => navigate("/login")}>
              Login
            </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GoToLoginModal;