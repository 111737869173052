import {
  GET_BENCHMARK_SLOTS,
  ASSIGN_SLOT_TO_OPERATOR,
  GET_CHECKLIST_TEMPLATES,
  GET_CREATE_SCHEDULE_DETAILS,
  GET_SPACE_ROOMS,
  ADD_SPACE_ROOM,
  UPDATE_KPI_COUNTS,
  CREATE_SCHEDULE,
  GET_SPACE_ROOMS_ITEM,
  REMOVE_SPACE_ROOM,
  UPDATE_TYPE_OF_SPACE,
  GET_FLOOR_DETAILS,
  UPDATE_TYPE_OF_SPACE_LIST,
  SET_FLOOR_FILTER_ARRAY,
  SELECT_FLOOR_FOR_SCHEDULE,
  SET_FLOOR_PLANNING,
  UPDATE_FLOOR_SETTING,
  ADD_CHECKLIST_ITEM_TO_COPY,
  REMOVE_CHECKLIST_ITEM_FROM_ARRAY,
  CLEAR_CHECKLIST_CLIPBOARD,
  GET_GLOBAL_DOC_TYPE,
  UPDATE_SPACE_COEFFICIENT,
} from "./benchmark-action-type";

import CustomerServices from "../../services/customer-services";
import { updateProviderAssignee } from "../customer";
import BenchmarkServices from "../../services/benchmark-services";

export const getBenchmarkSlots = (project_id) => (dispatch) => {
  return CustomerServices.getBenchmarkSlots(project_id).then((data) => {
    dispatch({
      type: GET_BENCHMARK_SLOTS,
      payload: { data: data.data },
    });
  });
};

export const assignSlotToOperator =
  (schedule_id, templates, staff) => (dispatch) => {
    return CustomerServices.assignSlotToOperator(
      schedule_id,
      templates,
      staff.filter_key
    ).then((data) => {
      dispatch({
        type: ASSIGN_SLOT_TO_OPERATOR,
        payload: { schedule_id, templates, staff },
      });
      templates.forEach((template) => {
        dispatch(updateProviderAssignee(schedule_id, staff, template));
      });
    });
  };

export const getChecklistTemplates =
  (providerId, updateChecklist = []) =>
  (dispatch) => {
    if (updateChecklist.length > 0) {
      dispatch({
        type: GET_CHECKLIST_TEMPLATES,
        payload: { updateChecklist: updateChecklist },
      });
    } else {
      return CustomerServices.getChecklistTemplates(providerId).then((data) => {
        dispatch({
          type: GET_CHECKLIST_TEMPLATES,
          payload: { data: data.data, providerId, updateChecklist: [] },
        });
      });
    }
  };
export const createScheduleDetails =
  (data, changeDatatype = "", isEdit = 0, key = "") =>
  (dispatch) => {
    dispatch({
      type: GET_CREATE_SCHEDULE_DETAILS,
      payload: {
        data: data,
        changeDatatype: changeDatatype,
        isEdit: isEdit,
        key: key,
      },
    });
  };

export const getSpaceRooms =
  (rooms = [], scheduleId = 0, project_id) =>
  (dispatch) => {
    if (rooms.length) {
      dispatch({
        type: GET_SPACE_ROOMS,
        payload: { data: rooms },
      });
    } else {
      return BenchmarkServices.getSpaceRooms(scheduleId, project_id).then(
        (data) => {
          dispatch({
            type: GET_SPACE_ROOMS,
            payload: { data: data.data },
          });
        }
      );
    }
  };

export const addUpdateSpaceRooms = (rooms, schedule_id) => (dispatch) => {
  dispatch({
    type: ADD_SPACE_ROOM,
    payload: { data: rooms, schedule_id },
  });
};

export const updateRoomKPICounts = (kpiCounts) => (dispatch) => {
  dispatch({
    type: UPDATE_KPI_COUNTS,
    payload: { data: kpiCounts },
  });
};

export const createSchedule = (schedule) => (dispatch) => {
  dispatch({
    type: CREATE_SCHEDULE,
    payload: { data: schedule },
  });
};
export const getSpaceRoomsItem =
  (rooms = []) =>
  (dispatch) => {
    dispatch({
      type: GET_SPACE_ROOMS_ITEM,
      payload: { data: rooms },
    });
  };

export const deleteSpaceRoom = (room, schedule_id) => (dispatch) => {
  if (room.id) {
    return BenchmarkServices.deleteTypeOfSpace(room.id, schedule_id).then(
      (data) => {
        dispatch({
          type: REMOVE_SPACE_ROOM,
          payload: { data: room },
        });
      }
    );
  } else {
    dispatch({
      type: REMOVE_SPACE_ROOM,
      payload: { data: room },
    });
  }
};

export const updateTypeOfSpace =
  (schedule_id, title, quantity, id, item_id) => (dispatch) => {
    return BenchmarkServices.updateTypeOfSpace(
      schedule_id,
      title,
      quantity,
      id,
      item_id
    ).then((data) => {
      dispatch({
        type: UPDATE_TYPE_OF_SPACE,
        payload: { title, quantity, id, item_id },
      });
    });
  };

export const getFloorDetails = (project_id, all_type_of_space) => (dispatch) => {
  return BenchmarkServices.getFloorDetails(project_id, all_type_of_space).then((res) => {
    if (res.status && res.data.floor && res.data.type_of_space) {
      dispatch({
        type: GET_FLOOR_DETAILS,
        payload: { data: res.data },
      });
    }
  });
};
export const updateFloorDetails = (data) => (dispatch) => {
      dispatch({
        type: GET_FLOOR_DETAILS,
        payload: { data: data },
      });
};

export const updateTypeOfSpaceList = (spaceIds, checked) => (dispatch) => {
  dispatch({
    type: UPDATE_TYPE_OF_SPACE_LIST,
    payload: { spaceIds, checked },
  });
};

export const setFloorFilters = (keys) => (dispatch) => {
  dispatch({
    type: SET_FLOOR_FILTER_ARRAY,
    payload: { keys },
  });
};

export const selectFloorForSchedule = (keys) => (dispatch) => {
  dispatch({
    type: SELECT_FLOOR_FOR_SCHEDULE,
    payload: { keys },
  });
};

export const setFloorPlanning = (type_of_spaces, floors) => (dispatch) => {
  dispatch({
    type: SET_FLOOR_PLANNING,
    payload: { type_of_spaces, floors },
  });
};

export const updateFloorSetting = (floor, type_of_spaces) => (dispatch) => {
  dispatch({
    type: UPDATE_FLOOR_SETTING,
    payload: { floor, type_of_spaces },
  });
};

export const appendChecklistItemToClipBoard = (checklistItem) => (dispatch) => {
  dispatch({
    type: ADD_CHECKLIST_ITEM_TO_COPY,
    payload: { checklistItem },
  });
};

export const removeChecklistItemFromClipBoard =
  (checklistItem) => (dispatch) => {
    dispatch({
      type: REMOVE_CHECKLIST_ITEM_FROM_ARRAY,
      payload: { checklistItem },
    });
  };

export const clearChecklistClipboard = () => (dispatch) => {
  dispatch({
    type: CLEAR_CHECKLIST_CLIPBOARD,
  });
};

export const selectGlobalDocType = (type) => (dispatch) => {
  dispatch({
    type: GET_GLOBAL_DOC_TYPE,
    payload: type,
  });
};

export const updateSpaceCoefficient = (spaceId, coEfficient) => (dispatch) => {
  dispatch({
    type: UPDATE_SPACE_COEFFICIENT,
    payload: {spaceId, coEfficient},
  });
};
