import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExternalHeader from '../components/ExternalHeader';
import { isPremuimEstimateDisable, setSelectedGloablView, setSelectedLanguage } from "../actions/customer";
import { DEFAULT_PLACEHOLDER } from "../actions/action-type";
import { _l, countryCodeSetNumber, validateEmail } from "../hooks/utilities";
import { useNavigate } from "react-router-dom";
import { Button, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Buildings, CaretRight, Check, Envelope, Hash, LightbulbFilament, MagnifyingGlass, PhoneCall, UserCircle, UserCircleGear, Wrench, X } from "phosphor-react";
import CelebrationIcon from "../assets/icons/CelebrationIcon";
import PhoneInput from "react-phone-input-2";
import { showMessage } from "../actions/messages";
import customerServices from "../services/customer-services";
import moment from "moment";


const ConnectNew = () => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const [fullName, setFullName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [cheacked, setCheacked] = useState(false);
    const [fieldStep, setFieldStep] = useState("fullName")
    const [countryCode, setcountrycode] = useState('');
    const [numberFormate, setNumberFormate] = useState('');
    const [numberlength, setNumberLength]= useState({max : 10, min : 9});
    const [stepperProgress, setStepperProgress] = useState({step : 1, rate : 0});

    const navigate = useNavigate()

    const nameFieldRef = useRef();
    const companyNameFieldRef = useRef();
    const emailFieldRef = useRef();
    const cheackedFieldRef = useRef();

    const fieldsets = ["fullName", "companyName", "contactEmail", "phoneNumber", "Congratulations"];

    useEffect(() => {
        // if (userDataGlobal != undefined && userDataGlobal == user_id) { 
        if (localStorage.getItem("accessToken")) {
            if (localStorage.getItem("membership_package_id") == 1) {
                // navigate("/premiumsubscription");
                navigate("/myprofile");
                dispatch(isPremuimEstimateDisable());
            } else {
                navigate("/");
                dispatch(setSelectedGloablView("liteVersionView"));
            }
        }
        // }
    }, []);


    const languageSelect = [
        { value: "english", label: _l('l_english') },
        { value: "french", label: _l('l_french') },
    ];
    const [language, setlanguage] = useState(localStorage.getItem("language"))
    const setLanguage = (language) => {
        dispatch(setSelectedLanguage(language));
        localStorage.setItem("language", language)
        
    }

    useEffect(() => {
        document.body.classList.remove('with-left-penel');
        document.body.classList.add('ps-0');
        document.body.classList.add('pb-0');
        return () => {
            document.body.classList.add('with-left-penel');
            document.body.classList.remove('');
        }
    }, [])

    const fieldValidations = (step) => {
        switch (step) {
            case "fullName" :
                if (!nameFieldRef.current.value.trim()){
                    dispatch(showMessage("unsucess", _l("l_error"),_l('l_please_enter_name'))); 
                    nameFieldRef.current.focus();
                    return false;
                }else{
                    return true;
                }
            case "companyName" :
                if (!companyNameFieldRef.current.value.trim()){
                    dispatch(showMessage("unsucess", _l("l_error"),_l('l_please_enter_company_name')));
                    companyNameFieldRef.current.focus();
                    return false;
                }else {
                    return true;
                }
            case "contactEmail" :
                if (!emailFieldRef.current.value.trim() || !validateEmail(emailFieldRef.current.value)) {
                    dispatch(showMessage("unsucess", _l("l_error"),_l('l_please_enter_valid_email')));
                    emailFieldRef.current.focus();
                    return false;
                }else{
                    return true;
                }
            case "phoneNumber" :
                if (!phoneNumber.trim() || !phoneNumber.length || (numberlength.max != phoneNumber.length && numberlength.min != phoneNumber.length)){
                    dispatch(showMessage("unsucess", _l("l_error"), language == "english" ? `Phone number should contain a minimum of ${numberlength.min} digits and a maximum of ${numberlength.max} digits.` : `Le numéro de téléphone doit contenir au minimum ${numberlength.min} chiffres et au maximum ${numberlength.max} chiffres.`));
                    return false;
                }else if (!cheacked){
                    dispatch(showMessage("unsucess", _l("l_error"),_l('l_please_accept_terms_and_conditions')));
                    cheackedFieldRef.current.focus();
                    return false;
                }else{
                    return true;
                }
            default:
                break;
            }
        }; 

    const changeFields = (fields, back = 'ahead') => {
      const index = fieldsets.findIndex((fieldindex) => fieldindex == fields);
      if (fields == "Congratulations") {
            setFieldStep(fieldsets[0]);
            setStepperProgress({step : 1 , rate : Math.trunc(((1 - 1) / (fieldsets.length-1)) * 100)})
      }else{
        if (back == "back") {
                setFieldStep(fieldsets[index - 1]);
                setStepperProgress({step : (index), rate : Math.trunc(((index - 1) / (fieldsets.length - 1)) * 100)})
        }else{
            if (fieldValidations(fields)) {
                if (fields == "phoneNumber") {
                setLoader(true);
                // let date = moment(startDate).format("YYYY-MM-DD HH:mm:ss")
                let phoneNumbers = phoneNumber.slice(countryCode.length - 1)
                customerServices.contactForm( fullName, companyName, contactEmail, phoneNumbers, countryCode, '')
                    .then((res) => {
                        if(res.status == 1){
                            setFieldStep(fieldsets[index + 1]);
                            setStepperProgress({step : (index + 2), rate : Math.trunc(((index + 1) / (fieldsets.length - 1)) * 100)})
                            // dispatch(showMessage("sucess", _l("l_success"), _l("l_message_send")));
                            setLoader(false);
                            setCompanyName("");
                            setFullName("");
                            setContactEmail("");
                            setPhoneNumber("");
                            setNumberFormate("");
                            setCheacked(false);
                            // setFieldStep(fieldsets[0]);
                        }else{
                            dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
                            setLoader(false)
                        }
                })
                }else{
                    setFieldStep(fieldsets[index + 1]);
                    setStepperProgress({step : (index + 2), rate : Math.trunc(((index + 1) / (fieldsets.length - 1)) * 100)})
                }
                
            }
        }
      }
    }

    useEffect(() => {
        if (numberFormate.dialCode) {
            setcountrycode(`+${numberFormate.dialCode}`)
            const dialcodeL = Number(numberFormate.dialCode.length);
            let digitlength = numberFormate.format.match(/\./g).slice(numberFormate.dialCode.length);
            if (["32","352", "49", "44"].includes(numberFormate.dialCode)) {
                if (["49", "44"].includes(numberFormate.dialCode)) {
                    setNumberLength({max : 11 + dialcodeL, min : 10 + dialcodeL})
                }else{
                    setNumberLength({max : 10 + dialcodeL, min : 9 + dialcodeL})
                }
            }else{
                setNumberLength({max : digitlength.length + dialcodeL, min : digitlength.length + dialcodeL})
            }
        }
    }, [numberFormate])

    return (
        <>
            <ExternalHeader 
                profileImageURL={DEFAULT_PLACEHOLDER} 
                hideLeftPanel={true} 
                setlanguage={setlanguage} 
                language={language} 
                setLanguage={setLanguage} 
                languageSelect={languageSelect} 
                pageName={"registrationPage"}
                fieldStep = {fieldStep}
                changeFields= {changeFields} 
                stepperProgress = {stepperProgress}
            />
            
                
                    {fieldStep == "fullName" ? <>
                        {/* Full name start */}
                        <div class="d-flex flex-column h-100 RegNew">
                            <div class="d-flex flex-column  h-100 regNewcontent mt-0 pb-0">
                                <div className="col-7 mx-auto row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-7 m-auto">
                                        <div className="py-30px position-relative text-center">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="px-3">
                                                    <div class="c-font f-20 fw-semibold title-fonts">{_l('l_name_title')}</div>
                                                    <div class="c-font f-16 pt-15px sub-header text-center">
                                                        <span>{_l('l_name_subtitle')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                                <div className="col-7 flex-grow-1 mx-auto row mb-70">
                                    <div className="col-lg-2"></div>
                                    <div className="align-items-center col-lg-7 d-flex mx-auto">
                                        <Form.Group className={`c-input-box position-relative w-100`}>
                                            <label class="c-font f-11 fw-semibold text-uppercase title-fonts color-white-60">{_l("l_full_name")}</label>
                                            <div className="inputWrapper d-flex align-items-center">
                                                <div className="with_separator_10 pe-20pximp iconabsolute">
                                                    <UserCircle size={24} weight="light" className="c-icons mx-0" />
                                                </div>
                                                <Form.Control
                                                    placeholder={_l("l_full_name_placeholder")} 
                                                    className="regFormInput text-center px4pxpy55px  w-100"
                                                    autoFocus
                                                    name="fullName"
                                                    type="text"
                                                    ref={nameFieldRef}
                                                    value={fullName}
                                                    onChange={(e) => setFullName(e.target.value)}
                                                />
                                                <div className="comman_action_icon absolutebtn" onClick={() => changeFields("fullName")}>
                                                    <a className="action_icon d-flex h40w40 with_bg light-theme-white-bg shadow-none ">
                                                        <CaretRight size={18} weight="light" className="c-icons" />
                                                    </a>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                                <div>
                                    <div className="col-7 mt-auto mx-auto row mb-20">
                                        <div className="col-lg-2"></div>
                                            <div className="col-lg-7 m-auto">
                                                <div className="border  g-0 p-10 radius10 ">
                                                    <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                                                        {_l("l_name_footer_title")}
                                                    </div>
                                                    <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                                                        {_l("l_name_footer_subtitle")}
                                                    </div>
                                                </div>
                                            </div>
                                        <div className="col-lg-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Full name end  */}
                    </> : fieldStep == "companyName" ? <>
                        {/* Company name start */}
                        <div class="d-flex flex-column h-100 RegNew">
                            <div class="d-flex flex-column  h-100 regNewcontent mt-0 pb-0">
                                <div className="col-7 mx-auto row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-7 m-auto">
                                        <div className="py-30px position-relative text-center">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="px-3">
                                                    <div class="c-font f-20 fw-semibold title-fonts">{_l('l_company_name_enterpise')}</div>
                                                    <div class="c-font f-16 pt-15px sub-header text-center">
                                                        <span>{_l('l_company_name_subtitle')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                                <div className="col-7 flex-grow-1 mx-auto row mb-70">
                                    <div className="col-lg-2"></div>
                                    <div className="align-items-center col-lg-7 d-flex mx-auto">
                                        <Form.Group className={`c-input-box position-relative w-100`}>
                                            <label class="c-font f-11 fw-semibold text-uppercase title-fonts color-white-60">{_l("l_company_name")}</label>
                                            <div className="inputWrapper d-flex align-items-center">
                                                <div className="with_separator_10 pe-20pximp iconabsolute">
                                                    <Buildings size={24} weight="light" className="c-icons mx-0" />
                                                </div>
                                                <Form.Control
                                                    placeholder={_l("l_globex_corporation")}
                                                    className="regFormInput text-center px4pxpy55px  w-100"
                                                    autoFocus
                                                    name="companyName"
                                                    type="text"
                                                    ref={companyNameFieldRef}
                                                    value={companyName}
                                                    onChange={(e) => setCompanyName(e.target.value)}
                                                />
                                                <div className="comman_action_icon absolutebtn" onClick={() => changeFields("companyName")}>
                                                    <a className="action_icon d-flex h40w40 with_bg light-theme-white-bg shadow-none ">
                                                        <CaretRight size={18} weight="light" className="c-icons" />
                                                    </a>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                                <div>
                                    <div className="col-7 mt-auto mx-auto row mb-20">
                                        <div className="col-lg-2"></div>
                                            <div className="col-lg-7 m-auto">
                                                <div className="border  g-0 p-10 radius10 ">
                                                    <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                                                        {_l("l_l_company_name_footer_title")}
                                                    </div>
                                                    <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                                                        {_l("l_l_company_name_footer_subtitle")}
                                                    </div>
                                                </div>
                                            </div>
                                        <div className="col-lg-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Company name end */}
                    </> : fieldStep == "contactEmail" ? <>
                        {/* Email start */}
                        <div class="d-flex flex-column h-100 RegNew">
                            <div class="d-flex flex-column  h-100 regNewcontent mt-0 pb-0">
                                <div className="col-7 mx-auto row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-7 m-auto">
                                        <div className="py-30px position-relative text-center">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="px-3">
                                                    <div class="c-font f-20 fw-semibold title-fonts">{_l('l_email_title')}</div>
                                                    <div class="c-font f-16 pt-15px sub-header text-center">
                                                        <span>{_l('l_email_subtitle')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                                <div className="col-7 flex-grow-1 mx-auto row mb-70">
                                    <div className="col-lg-2"></div>
                                    <div className="align-items-center col-lg-7 d-flex mx-auto">
                                        <Form.Group className={`c-input-box position-relative w-100`}>
                                            <label class="c-font f-11 fw-semibold text-uppercase title-fonts color-white-60">{_l("l_email")}</label>
                                            <div className="inputWrapper d-flex align-items-center">
                                                <div className="with_separator_10 pe-20pximp iconabsolute">
                                                    <Envelope size={24} weight="light" className="c-icons mx-0" />
                                                </div>
                                                <Form.Control
                                                   placeholder={_l("l_email_placeholder")}
                                                    className="regFormInput text-center px4pxpy55px  w-100"
                                                    autoFocus
                                                    name="email"
                                                    type="email"
                                                    ref={emailFieldRef}
                                                    value={contactEmail}
                                                    onChange={(e) => setContactEmail(e.target.value)}
                                                />
                                                <div className="comman_action_icon absolutebtn" onClick={() => changeFields("contactEmail")}>
                                                    <a className="action_icon d-flex h40w40 with_bg light-theme-white-bg shadow-none ">
                                                        <CaretRight size={18} weight="light" className="c-icons" />
                                                    </a>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                                <div>
                                    <div className="col-7 mt-auto mx-auto row mb-20">
                                        <div className="col-lg-2"></div>
                                            <div className="col-lg-7 m-auto">
                                                <div className="border  g-0 p-10 radius10 ">
                                                    <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                                                        {_l("l_email_footer_title")}
                                                    </div>
                                                    <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                                                        {_l("l_email_footer_subtitle")}
                                                    </div>
                                                </div>
                                            </div>
                                        <div className="col-lg-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Email end */}
                        </> : fieldStep == "phoneNumber" ? <>                            
                        {/* Phone number start */}


                        <div class="d-flex flex-column h-100 RegNew">
                            <div class="d-flex flex-column  h-100 regNewcontent mt-0 pb-0">
                                <div className="col-7 mx-auto row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-7 m-auto">
                                        <div className="py-30px position-relative text-center">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="px-3">
                                                    <div class="c-font f-20 fw-semibold title-fonts">{_l('l_phone_number_title')}</div>
                                                    <div class="c-font f-16 pt-15px sub-header text-center">
                                                        <span>{_l('l_phone_number_subtitle')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                                <div className="col-7 flex-grow-1 mx-auto row mb-70">
                                    <div className="col-lg-2"></div>
                                    <div className="align-items-center col-lg-7 d-flex mx-auto">
                                        <div className="d-flex flex-column justify-content-center h-100 w-100">
                                        <Form.Group className="c-input-box position-relative w-100 Regnewphone">
                                            <label class="c-font f-11 fw-semibold text-uppercase title-fonts color-white-60">{_l("l_phone_number")}</label>
                                            <div className="inputWrapper d-flex align-items-center pb-15px">
                                                <div className="with_separator_10 pe-20pximp iconabsolute opacity-0">
                                                    <PhoneCall size={24} weight="light" className="c-icons mx-0" />
                                                </div>
                                                <div className="p-15 pb-0 pe-0 position-relative pt-0 flaginput w-100 position-relative ">
                                                    <PhoneInput
                                                        autoFormat={true}
                                                        enableLongNumbers = {true}
                                                        countryCodeEditable={false}
                                                        disableCountryGuess={true}
                                                        searchStyle={{"border-color" : "red"}}
                                                        disableSearchIcon={true}
                                                        enableSearch={true}
                                                        localization={""}
                                                        country='fr'
                                                        preferredCountries={['fr', 'de', 'be', 'es', 'ch','gb','pl','lu']}
                                                        name="mobile_number"
                                                        value={phoneNumber}
                                                        onChange={(phone, formattedValue) => {
                                                            setNumberFormate(formattedValue);
                                                            setPhoneNumber(phone);
                                                        }}
                                                        isValid={(value) => {
                                                            if (value.length > numberlength.max) {
                                                                    setPhoneNumber(value.slice(0,numberlength.max));
                                                            } else {
                                                              return true;
                                                            }
                                                        }}
                                                    />
                                                    <div className="comman_action_icon absolutebtn top-0" onClick={() => changeFields("phoneNumber")}>
                                                        <a className="action_icon d-flex h40w40 with_bg light-theme-white-bg shadow-none ">
                                                        {loader ?
                                                           <></> : <CaretRight size={18} weight="light" className="c-icons" />}
                                                        {
                                                            loader ? (
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm20"
                                                                role="status"
                                                                className={`m-auto `}
                                                                aria-hidden="true"
                                                            />
                                                            ) :
                                                            <></>
                                                        }
                                                        </a>
                                                    </div>
                                                </div>

                                            </div>
                                            
                                        </Form.Group>
                                        <div class="d-flex align-items-center pt-30px">
                                                <div class="pe-2">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox"
                                                        value=""
                                                        checked={cheacked}
                                                        onChange={() => setCheacked(!cheacked)}
                                                        ref={cheackedFieldRef}
                                                    />
                                                </div>
                                                {_l('l_i_accept_terms_and_conditions')}<a href="https://myr.ai/cgsc.html" class="color-green ms-1" target="_blank">CGSC</a>
                                                <a href="https://myr.ai/cgsp.html" class="color-green ms-1" target="_blank">CGSP</a>
                                                <span class="m-1">&amp;</span>
                                                <a href="https://myr.ai/privacy-policy.html" class="color-green ms-1" target="_blank">RGPD</a>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                                <div>
                                    <div className="col-7 mt-auto mx-auto row mb-20">
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-7 m-auto">
                                            <div className="border  g-0 p-10 radius10 ">
                                                <div className="mb-10 c-font f-14 fw-semibold line-clamp-2 title-fonts">
                                                    {_l("l_phone_number_footer_title")}
                                                </div>
                                                <div className="color-white-60 light-theme-color-white-70 line-clamp-3 title-fonts c-font f-12">
                                                    {_l("l_phone_number_footer_subtitle")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Phone number end */}
                        </> : <>
                        {/* all set start */}
                        <div className="container h-100">
                        <div className="d-flex flex-column justify-content-between flex-grow-1 h-100">
                            <div className="row d-none">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-7 m-auto">
                                    <div className="onboarding-header p-20px position-relative text-center">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div class=" px-3">
                                                <div class="c-font f-20 fw-semibold title-fonts">Sit tight and in just a moment,</div>
                                                <div class="c-font f-16 pt-15px sub-header text-center">
                                                    <span>You'll be ready to connect with customers and showcase the unique offerings of your business. We appreciate your patience!</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                            {/* <div className="d-flex flex-column justify-content-center align-items-center h-100 flex-grow-1"> */}
                                <div className="row w-100 my-auto">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-6 m-auto text-center title-fonts">
                                        <div className="text-center mb-10">
                                            <CelebrationIcon />
                                        </div>
                                        <div className="c-font color-white f-22 form-label fw-semibold mb-0 pb-20px ">{_l("l_congratulations")} {_l("l_your")} {_l("l_lead_added")}!</div>
                                        <div className="d-flex align-items-center justify-content-center mt-4" onClick={() => changeFields("Congratulations")}>
                                            <Button variant="primary" className="h45w45 w-auto px-4 ms-20px"  >{_l("l_back")}</Button>
                                        </div>
                                    </div>
                                   
                                    <div className="col-lg-2"></div>
                                </div>
                                <div></div>
                            {/* </div> */}
                        </div>
                        </div>
                        {/* all set end */}
                        </>}
        </>
    )
}

export default ConnectNew