import React, { useEffect, useState } from "react";
import { _l, getDatePickerLocale, getDatePickerTimeCaption } from "../../hooks/utilities";
import { CalendarBlank, CaretDoubleDown, DotsNine } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { UncontrolledDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import customerServices from "../../services/customer-services";
import { getProviderListTableData } from "../../actions/customer";

const SpaceTable = (data) => {
  const {  providerlistTableData } = useSelector((state) => state.customer);

  const [SpaceData, setSpaceData] = useState(
    data.data && data.data.length > 0 ? data.data : []
  );
  const [spacePage, setSpacePage] = useState(1);
  const [loder, setLoder] = useState({
    spaceLoader: false,
  });

  const dispatch = useDispatch();

  const scroll = (status) => {
    let page;
    let taskStaus = status;
    if (status == 1) {
      page = spacePage + 1;
      setSpacePage(page);
    }
    taskStaus = [status];
    if (status == 1) {
      setLoder({ ...loder, ["spaceLoader"]: false });
    }
  };

  const addSpaceTime = (date, space, typeDate) => {
    let CompanyID = localStorage.getItem("currentCompanyID");
    space.companyID = CompanyID
    customerServices.addSpaceTimeSpaceTable(date, space, typeDate).then((res) => {
      if (res.status) {
        let documentUpdates = {
          ...providerlistTableData,
          ["spaces"]: providerlistTableData["spaces"].map((w) => {
            if (w.id == space.id && w.project_id == space.project_id) {
              if (typeDate == "startDate") {
                w.schedule_start_date = date;
              }else{
                w.schedule_end_date = date;
              }
            }
            return w;
          }),
        };
        dispatch(getProviderListTableData(documentUpdates))
      }
    })
  }

  useEffect(() => {
    setSpaceData(providerlistTableData.spaces)
  }, [JSON.stringify(providerlistTableData)])
  return (
    <table className="dataTable text-center tablewithborder">
      <thead className="comman-white-box-shadow">
        <tr>
          <th style={{ width: "20%" }} className="text-start">
            {_l("l_space_name")}
          </th>
          <th style={{ width: "5%" }} className="text-start">
            {_l("l_space_address")}
          </th>
          <th style={{ width: "3%" }} className="text-start">
            {_l("l_startdate")}
          </th>
          <th style={{ width: "3%" }} className="text-start">
            {_l("l_end_date")}
          </th>
          <th style={{ width: "3%" }} className="text-start">
            {_l("l_status")}
          </th>
          <th style={{ width: "4%" }} className="text-center">
            {_l("l_space_score")}
          </th>
          <th style={{ width: "3%" }} className="text-center">
            {_l("l_action")}
          </th>
        </tr>
      </thead>
      <tbody style={{ verticalAlign: "middle" }}>
        {SpaceData && SpaceData.length ? (
          <>
            {SpaceData .slice(
                  0,
                  spacePage > 1 ? (spacePage - 1) * 10 + 10 : spacePage * 10
                ).map((space) => {
              return (
                <>
                  <tr>
                    <td>
                      <div className="name-with-progress max-w300">
                        <div className="d-flex align-items-center">
                          <a
                            href="#/"
                            className="h35w35 comman-round-box with-bg rounded-circle bg-white-05"
                          >
                            <div
                              className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                              style={{
                                backgroundImage: `url('${space.logo}')`,
                              }}
                            ></div>
                          </a>
                          <div className="ps-2 w100minus70 text-start flex-grow-1">
                            <div className="d-flex flex-column">
                              <a
                                href="#/"
                                className="title-fonts text-truncate text-start max-width-200 fw-semibold"
                              >
                                {space.office_title}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="title-fonts text-truncate text-start max-w150px">
                      {space.postal_address}
                    </td>
                    <td className="title-fonts text-truncate text-start">
                      <div
                        className="comman_action_icon custom-datepicker flex-grow-1 white-border-box withinputabsolute"
                        data-tip={space.schedule_start_date ?
                          moment(space.schedule_start_date).format("DD/MM/yyyy") : _l("l_schedule_start_date")}
                        data-effect="solid"
                        data-delay-show="1000"
                        data-class="tooltip-main"
                      >
                        <div
                          className={`action_icon align-items-center text-truncate d-flex h-100 w-100 justify-content-center light-theme-img on-hover-active-toggle-img position-relative on-hover-color-green bg-transparent with_bg `}
                        >
                          <CalendarBlank
                            size={18}
                            weight="light"
                            className="c-icons m-0"
                          />
                          {space.schedule_start_date ?
                          <>
                          <span class="with_separator2_10"></span> 
                          <span className="ms-10px title-fonts">{`${moment(space.schedule_start_date).format("DD/MM/yyyy")}`}</span>
                          </>
                          :
                          <span className="ms-10px">{`${_l("l_choose_when")}`}</span>
                          }
                          <DatePicker
                            portalId="custom_datepicker"
                            calendarClassName="custom-datepicker"
                            timeCaption={getDatePickerTimeCaption()}
                            locale={getDatePickerLocale()}
                            className="close-toast"
                            onChange={(date) => {
                                addSpaceTime(date, space, "startDate");
                            }}
                            selected={space.schedule_start_date ? new Date(moment(space.schedule_start_date)) : ''}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            calendarStartDay={1}
                          >
                            <div className="datepicker-label">
                              {_l("l_select_date_and_time")}
                            </div>
                          </DatePicker>
                        </div>
                      </div>
                    </td>
                    <td className="title-fonts text-truncate text-start">
                      <div
                        className="comman_action_icon custom-datepicker flex-grow-1 white-border-box withinputabsolute"
                        data-tip={space.schedule_end_date ?
                          moment(space.schedule_end_date).format("DD/MM/yyyy") : _l("l_schedule_end_date")}
                        data-effect="solid"
                        data-delay-show="1000"
                        data-class="tooltip-main"
                      >
                        <div
                          className={`action_icon align-items-center text-truncate d-flex h-100 w-100 justify-content-center light-theme-img on-hover-active-toggle-img position-relative on-hover-color-green bg-transparent with_bg `}
                        >
                          <CalendarBlank
                            size={18}
                            weight="light"
                            className="c-icons m-0"
                          />
                          {space.schedule_end_date ?
                          <>
                          <span class="with_separator2_10"></span> 
                          <span className="ms-10px title-fonts">{`${moment(space.schedule_end_date).format("DD/MM/yyyy")}`}</span>
                          </>
                          :
                          <span className="ms-10px">{`${_l("l_choose_when")}`}</span>
                          }
                          <DatePicker
                            portalId="custom_datepicker"
                            calendarClassName="custom-datepicker"
                            timeCaption={getDatePickerTimeCaption()}
                            locale={getDatePickerLocale()}
                            className="close-toast"
                            onChange={(date) => {
                                addSpaceTime(date, space, "endDate");
                            }}
                            selected={space.schedule_end_date ? new Date(moment(space.schedule_end_date)) : ''}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            calendarStartDay={1}
                          >
                            <div className="datepicker-label">
                              {_l("l_select_date_and_time")}
                            </div>
                          </DatePicker>
                        </div>
                      </div>
                    </td>
                    <td className="title-fonts test-start">
                      <div className="d-flex align-items-center max-width75">
                                <span class={`c-dots  c-7 rounded-circle me-10px ${space.space_status == "l_active" ? "done" : space.space_status == "l_inactive" ? "bg-warning" : space.space_status == "l_free_trial" ? "in-progress-blue" : "in-progress-white" }`}></span>
                                <span className="title-fonts">{_l(space.space_status)}</span>
                              </div>
                      
                    </td>
                    <td className="title-fonts text-center">{space.score}</td>
                    <td>
                      <div className="align-items-center comman_action_icon d-flex justify-content-center">
                        <UncontrolledDropdown setActiveFromChild={true}>
                          <DropdownToggle className="border-0 p-0">
                            <a
                              href="#/"
                              className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                            >
                              <DotsNine size={18} className="c-icons " weight="light" />
                            </a>
                          </DropdownToggle>
                          <ReactTooltip />
                        </UncontrolledDropdown>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
               <tr>
                {(SpaceData.length >= (spacePage - 1) * 10 + 10 &&
                  SpaceData.length != (spacePage - 1) * 10 + 10) ||
                loder.spaceLoader ? (
                  <>
                    <td className="border-0" colSpan={12}>
                      <a
                        href="#/"
                        className="btn btn-white-03 w-100 view-more-btn"
                        onClick={() => {
                          setLoder({ ...loder, ["spaceLoader"]: true });
                          scroll(1);
                        }}
                      >
                        <div className="d-flex align-item-center justify-content-center">
                          <span className=" title-fonts c-font f-12 ms-2">
                            {_l("l_view_more_btn")} (
                            {spacePage > 1
                              ? SpaceData.length - ((spacePage - 1) * 10 + 10)
                              : SpaceData.length - 10}
                            )
                          </span>
                          <CaretDoubleDown
                            size={12}
                            className="c-icons mx-2"
                            weight="light"
                          />
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 ${
                              loder.spaceLoader ? "opacity-1" : "opacity-0"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                      </a>
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
          </>
        ) : (
          <>
            {" "}
            <tr className="">
              <td className="border-0" colSpan={7}>
                <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                  <div className="d-flex flex-column align-items-center">
                    <CommanPlaceholder
                      imgType="request"
                      placeholderText={_l("l_no_data")}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default SpaceTable;
