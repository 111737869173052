import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TaskModalClose, _l, showLeftPanelNew } from "../../hooks/utilities";
import ShareScreen from "../../assets/icons/ShareScreen";
import peer from "../../actions/peer";

import {
    Microphone,
    MicrophoneSlash,
    PhoneSlash,
    Record,
    VideoCamera,
    VideoCameraSlash
} from "phosphor-react";

import {
    muteCall,
    unMuteCall,
    callAction,
    setMyStream,
    negoNeeded2
} from "../../actions/chat";

import {
    setCallDisconnect,
    setCallFullscreen,
    setCallRecording,
    setCallRecordingAction,
} from "../../actions/customer";

const FeedchatInTaskModalButton = ({ isMute, setIsMute, isCameraOff, setIsCameraOff, isCameraFound, isScreenShareOn, setIsScreenShareOn }) => {
    const dispatch = useDispatch();
    const { callStatus, myStream } = useSelector((state) => state.chat);
    const { callRecordingActive } = useSelector((state) => state.customer);

    const muteTheCall = async () => {
        myStream.getAudioTracks()[0].enabled = false;
        setIsMute(true);
        dispatch(
            muteCall({
                userId: callStatus.callerInfo._id,
                receiverId: callStatus.receiverInfo._id,
                callRole: callStatus.callRole,
            })
        );
    };

    const unMuteTheCall = () => {
        myStream.getAudioTracks()[0].enabled = true;
        setIsMute(false);
        dispatch(
            unMuteCall({
                userId: callStatus.callerInfo._id,
                receiverId: callStatus.receiverInfo._id,
                callRole: callStatus.callRole,
            })
        );
    };

    const turnCamera = async (val = "") => {
        if(val == "off"){
            setIsCameraOff(true);
            doCallAction("turnCamera","off");
            myStream.getVideoTracks()[0].enabled = false;
        }else{
            setIsCameraOff(false);
            doCallAction("turnCamera","on");
            myStream.getVideoTracks()[0].enabled = true;
        }
    };

    const disconnectCall = () => {
        dispatch(setCallDisconnect(true));
        dispatch(setCallRecording(false));
        TaskModalClose();
        dispatch(setCallFullscreen(false));
        showLeftPanelNew();
    };

    const doCallAction = (actionKey, actionVal) => {
        dispatch(callAction({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
            actionKey: actionKey,
            actionVal: actionVal
        }));
    };

    async function mergeStreams(screenSharingStream, cameraStream) {
        // Create a new MediaStream object to hold the merged streams
        const mergedStream = new MediaStream();
      
        // Add audio track from camera stream to merged stream
        const audioTrack = cameraStream.getAudioTracks()[0];
        if (audioTrack) {
            mergedStream.addTrack(audioTrack);
        }
      
        // Add video track from camera stream to merged stream
        const videoTrack = cameraStream.getVideoTracks()[0];
        if (videoTrack) {
            mergedStream.addTrack(videoTrack);
        }
      
        // Add video track from screen sharing stream to merged stream
        const screenSharingVideoTrack = screenSharingStream.getVideoTracks()[0];
        if (screenSharingVideoTrack) {
            mergedStream.addTrack(screenSharingVideoTrack);
        }
      
        return mergedStream;
    }

    const screenShare = async (val = "off") => {
        if(val == "off"){
            setIsScreenShareOn(false);
            doCallAction("screenShare","off");
            // Find the screen share track within the merged stream
            const screenShareTrack = myStream.getTracks().find(track => track.kind === 'video' && (track.label.includes('screen') || track.label.includes('desktop') || track.label.includes('share')));
            if(screenShareTrack){
                // Stop the screen share track
                screenShareTrack.stop();
                
                // Remove the screen share track from the stream
                // dispatch(setMyStream(myStream.removeTrack(screenShareTrack)));
            }
            
            // Remove existing tracks from the peer connection
            peer.peer.getSenders().forEach(sender => {
                peer.peer.removeTrack(sender);
            });
            // Add tracks from the updated local stream to the peer connection
            myStream.getTracks().forEach(track => {
                peer.peer.addTrack(track, myStream);
            });
            window.localStream=myStream;
            dispatch(setMyStream(myStream));
            dispatch(negoNeeded2({
                userId: callStatus.callerInfo._id,
                receiverId: callStatus.receiverInfo._id,
                callRole: callStatus.callRole
            }));
        }else{
            // Get screen sharing stream
            await navigator.mediaDevices.getDisplayMedia({ video: true })
                .then(async screenStream => {
                    setIsScreenShareOn(true);
                    // Get microphone stream
                    // const micStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
                    // Merge microphone stream with screen sharing stream
                    // micStream.getAudioTracks().forEach(track => {
                    //     screenStream.addTrack(track);
                    // });
                    const mergedStream = await mergeStreams(screenStream, myStream);
                    
                    // Remove existing tracks from the peer connection
                    peer.peer.getSenders().forEach(sender => {
                        peer.peer.removeTrack(sender);
                    });

                    // Add tracks from the updated local stream to the peer connection
                    mergedStream.getTracks().forEach(track => {
                        peer.peer.addTrack(track, mergedStream);
                    });
                    window.localStream=mergedStream;
                    dispatch(setMyStream(mergedStream));
                    dispatch(negoNeeded2({
                        userId: callStatus.callerInfo._id,
                        receiverId: callStatus.receiverInfo._id,
                        callRole: callStatus.callRole
                    }));
                    doCallAction("screenShare","on");
                })
                .catch(error => {
                    // User has denied or failed to grant screen sharing access
                });
        }
    };

    return (
        <div className="position-sticky bottom-0 start-0 file-parent-box">
            <div className="border-top"></div>
            <div className="p-10">
                <div className="d-flex align-items-center justify-content-center">
                    
                    {isMute ? 
                    <a className="btn btn-white-05 w_100px rounded-5" onClick={() => { unMuteTheCall() }}>
                        <MicrophoneSlash size={32} weight="light" className="c-icons" />
                    </a>
                    : <a className="btn btn-white-05 w_100px rounded-5" onClick={() => { muteTheCall() }}>
                        <Microphone size={32} weight="light" className="c-icons " />
                    </a>
                    }

                    <div className="p-15 py-0"></div>
                
                    {isCameraOff ? <a className={`btn ${isCameraFound ? "btn-white-05" : "btn-warning for-disabled"} w_100px rounded-5`} onClick={() => { if(isCameraFound){ turnCamera(); } }}>
                        <VideoCameraSlash size={32} weight="light" className={`c-icons ${isCameraFound ? "" : "black-l-white"}`} />
                    </a>
                    : <a className="btn btn-white-05 w_100px rounded-5" onClick={() => { turnCamera("off") }} >
                        <VideoCamera size={32} weight="light" className="c-icons " />
                    </a>
                    }

                    {/* <div className="p-15 py-0"></div>

                    <a className={`btn btn-white-05 w_100px rounded-5 ${isScreenShareOn ? "screen-sharing" : ""}`}  onClick={() => { screenShare(isScreenShareOn ? "off" : "on"); }}>
                        <ShareScreen className={`HW32 fill-white c-icons`} />
                    </a> */}

                    <div className="p-15 py-0"></div>
                
                    <a className="btn btn-white-05 w_100px rounded-5" onClick={() => { dispatch(setCallRecordingAction(callRecordingActive ? "stop" : "start")) }}>
                        <Record size={32} weight="fill" className={`c-icons ${ callRecordingActive ? "color-green" : ""}`}/>
                    </a>

                    <div className="p-15 py-0"></div>
                    
                    <a className="btn btn-warning w_100px rounded-5" onClick={() => { disconnectCall() }}>
                        <PhoneSlash size={32} weight="light" className="c-icons black-l-white" />
                    </a>

                </div>
            </div>
        </div>
    );
};

export default FeedchatInTaskModalButton;
