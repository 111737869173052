import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import { UserCircle, PhoneCall, User, Buildings, UsersThree, Package, Star, File, Timer, ListNumbers, Clock, CheckCircle, Check, Checks, Hash } from "phosphor-react";
import { DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector } from "react-redux";
import moment from "moment";

const UncontrolledGeneralListing = ({ 
  list,
  handler,
  isFromCalDropDown = null,
  selectedIds = [],
  zoomClass = null,
  fromChat = false,
  forCall = false,
  makeCall,
  sortList = true,
  fromAichat = false,
  ismatrix = false,
  providerManager = false,
  providerBoss = false,
  row1= null,
  row2= null,
  row3= null,
  row4= null,
  row5= null,
  fromReadMsg = false,
  formForMe = false,
  callFromGroup = ""
}) => {
  const [dropdownData, setDropdownData] = useState(list);
  const [selectedItem, setSelectedItem] = useState(selectedIds);
  const {metricsDetails} = useSelector((state) => state.customer);
  const localList = list
  useEffect(() => {
    let unOrderedData = list;
    if(sortList)
    {
      unOrderedData && unOrderedData.sort((a, b) => {
        if (a.name < b.name)
          return -1;
      });
    }
    setDropdownData(unOrderedData);
  }, [localList]);

  return (
    <>
   {ismatrix || providerManager || providerBoss ? <>
   <DropdownMenu>

    {/* <ul>
      <li className="border-bottom">
        <div className="dropdown-header">
          <div class="d-flex align-items-center justify-content-between">
            <div class="c-list-detail title-fonts text-truncate color-white c-font f-13">
            {ismatrix ? 
              _l("l_metrics") :
                providerManager ?  _l("l_provider_manager")  :
                providerBoss ? _l("l_provider_boss")  :
                <></>}</div>
          </div>
        </div>
      </li>
      <li className="hr_sepretr">
        <a href="#/" className="dropdown-item">
          <div className="d-flex align-items-center text-truncate">
            <div className="c-list-icon">
              <div className="h25w25 d-flex align-items-center">
                {ismatrix ? 
                <ChatCircle  size={18}  weight="light" className="c-icons" /> :
                providerManager ? <Package  size={18}  weight="light" className="c-icons" /> :
                providerBoss ? <Star  size={18}  weight="light" className="c-icons" /> :
                <></>}
              </div>
            </div>
            <div className="ps-2 w100minus30 text-truncate d-flex align-items-center justify-content-between">
              <div className="c-list-detail text-truncate">{_l(row1)}</div>
              {ismatrix ?<div className="d-flex align-items-center title-fonts ps-2">
                <span className="color-orange c-font f-13">{metricsDetails.avg_time_to_answer ? metricsDetails.avg_time_to_answer.hour : 0}</span>
                <span className="color-white-60 c-font f-10">{_l("l_hrs")}</span>
                <span className="color-orange c-font f-13">{metricsDetails.avg_time_to_answer ? metricsDetails.avg_time_to_answer.minute : 0}</span>
                <span className="color-white-60 c-font f-10">{_l("l_min")}</span>
              </div> :
               providerManager ? <div className="d-flex align-items-center title-fonts ps-2">
               <span className="color-orange c-font f-13">{_l("l_inprogress")}</span>
               <span className="color-blue c-font f-13 d-none">{_l("l_pending")}</span>
               <span className="color-green c-font f-13 d-none">{_l("l_done")}</span>

             </div> :
               providerBoss ? <div className="progress-slider w-50 ps-2">
               <div className="d-flex align-items-center">
                 <div className="progress">
                   <div className="progress-bar" style={{width:`70%`}}></div>
                 </div>
                 <div className="ms-2 title-fonts">70%</div>
               </div>
             </div> 
              : <></>}
            </div>
          </div>
        </a>
      </li>
      <li className="hr_sepretr">
        <a href="#/" className="dropdown-item">
          <div className="d-flex align-items-center text-truncate">
            <div className="c-list-icon">
              <div className="h25w25 d-flex align-items-center">
              {ismatrix ? 
                <Buildings  size={18}  weight="light" className="c-icons" /> :
                providerManager ? <File  size={18}  weight="light" className="c-icons" /> :
                providerBoss ? <Timer  size={18}  weight="light" className="c-icons" /> :
                <></>}
                
              </div>
            </div>
            <div className="ps-2 w100minus30 text-truncate d-flex align-items-center justify-content-between">
              <div className="c-list-detail text-truncate">{_l(row2)}</div>
              {ismatrix ? <div className="d-flex align-items-center title-fonts ps-2">
                <span className="color-blue c-font f-13">{metricsDetails.avg_time_to_fix ? metricsDetails.avg_time_to_fix.hour : 0}</span>
                <span className="color-white-60 c-font f-10">{_l("l_hrs")}</span>
                <span className="color-blue c-font f-13">{metricsDetails.avg_time_to_fix ? metricsDetails.avg_time_to_fix.minute : 0}</span>
                <span className="color-white-60 c-font f-10">{_l("l_min")}</span>
              </div>:
              providerManager ? <div className="d-flex align-items-center title-fonts ps-2">
              <span className="color-white-80 c-font f-13">8</span>
              
            </div> :
               providerBoss ? <div className="d-flex align-items-center title-fonts ps-2">
               <span className="color-orange c-font f-13">10</span>
               <span className="color-white-60 c-font f-10">{_l("l_hrs")}</span>
               <span className="color-orange c-font f-13">15</span>
               <span className="color-white-60 c-font f-10">{_l("l_min")}</span>
             </div> : <></>}
            </div>
          </div>
        </a>
      </li>
      <li className="hr_sepretr">
        <a href="#/" className="dropdown-item">
          <div className="d-flex align-items-center text-truncate">
            <div className="c-list-icon">
              <div className="h25w25 d-flex align-items-center">
              {ismatrix ? 
                <UsersThree  size={18}  weight="light" className="c-icons" /> :
                providerManager ? <Star  size={18}  weight="light" className="c-icons" /> :
                providerBoss ? <ListNumbers  size={18}  weight="light" className="c-icons" /> :
                <></>}
              </div>
            </div>
            <div className="ps-2 w100minus30 text-truncate d-flex align-items-center justify-content-between">
              <div className="c-list-detail text-truncate">{_l(row3)}</div>
              {ismatrix ? <div className="progress-slider w-50 ps-2">
                <div className="d-flex align-items-center">
                  <div className="progress">
                    <div className="progress-bar bg-white40" style={{width:`${metricsDetails.satisfaction ? metricsDetails.satisfaction : 0}%`}}></div>
                  </div>
                  <div className="ms-2 title-fonts">{metricsDetails.satisfaction ? metricsDetails.satisfaction : 0}%</div>
                </div>
              </div> :
              providerManager ? <div className="progress-slider w-50 ps-2">
              <div className="d-flex align-items-center">
                <div className="progress">
                  <div className="progress-bar" style={{width:`25%`}}></div>
                </div>
                <div className="ms-2 title-fonts">25%</div>
              </div>
            </div> :
              providerBoss ? <div className="d-flex align-items-center title-fonts ps-2">
              <span className="color-white-80 c-font f-13">10</span>
              
            </div>
              : <></>}
            </div>
          </div>
        </a>
      </li>
      <li className="hr_sepretr">
        <a href="#/" className="dropdown-item">
          <div className="d-flex align-items-center text-truncate">
            <div className="c-list-icon">
              <div className="h25w25 d-flex align-items-center">
              {ismatrix ? 
                <User size={18}  weight="light" className="c-icons" /> :
                providerManager ? <Clock  size={18}  weight="light" className="c-icons" /> :
                providerBoss ? <CheckCircle  size={18}  weight="light" className="c-icons" /> :
                <></>}
              </div>
            </div>
            <div className="ps-2 w100minus30 text-truncate d-flex align-items-center justify-content-between">
              <div className="c-list-detail text-truncate">{_l(row4)} </div>
              {ismatrix ? <div className="progress-slider w-50 ps-2">
                <div className="d-flex align-items-center">
                  <div className="progress">
                    <div className="progress-bar" style={{width:`${metricsDetails.task_score ? metricsDetails.task_score : 0 }%`}}></div>
                  </div>
                  <div className="ms-2 title-fonts">{metricsDetails.task_score ? metricsDetails.task_score : 0}%</div>
                </div>
              </div> :
              providerManager ? <div className="d-flex align-items-center title-fonts ps-2">
              <span className="color-orange c-font f-13">25</span>
              <span className="color-white-60 c-font f-10">{_l("l_hrs")}</span>
              <span className="color-orange c-font f-13">10</span>
              <span className="color-white-60 c-font f-10">{_l("l_min")}</span>
            </div> :
              providerBoss ? <div className="progress-slider w-50 ps-2">
              <div className="d-flex align-items-center">
                <div className="progress">
                  <div className="progress-bar" style={{width:`30%`}}></div>
                </div>
                <div className="ms-2 title-fonts">30%</div>
              </div>
            </div>
              : <></>}
            </div>
          </div>
        </a>
      </li>
     {row5 ?  <li className="hr_sepretr">
        <a href="#/" className="dropdown-item">
          <div className="d-flex align-items-center text-truncate">
            <div className="c-list-icon">
              <div className="h25w25 d-flex align-items-center">
              {ismatrix ? 
                <></> :
                providerManager ? <CheckCircle  size={18}  weight="light" className="c-icons" /> :
                providerBoss ? <></> :
                <></>}
              </div>
            </div>
            <div className="ps-2 w100minus30 text-truncate d-flex align-items-center justify-content-between">
              <div className="c-list-detail text-truncate">{_l(row5)} </div>
              {ismatrix ?<div className="progress-slider w-50 ps-2">
                <div className="d-flex align-items-center">
                  <div className="progress">
                    <div className="progress-bar" style={{width:`${metricsDetails.task_score ? metricsDetails.task_score : 0 }%`}}></div>
                  </div>
                  <div className="ms-2 title-fonts">{metricsDetails.task_score ? metricsDetails.task_score : 0}%</div>
                </div>
              </div> :
              providerManager ? <div className="progress-slider w-50 ps-2">
              <div className="d-flex align-items-center">
                <div className="progress">
                  <div className="progress-bar" style={{width:`30%`}}></div>
                </div>
                <div className="ms-2 title-fonts">30%</div>
              </div>
            </div> :
              providerBoss ? <div className="progress-slider w-50 ps-2">
              <div className="d-flex align-items-center">
                <div className="progress">
                  <div className="progress-bar" style={{width:`50%`}}></div>
                </div>
                <div className="ms-2 title-fonts">50%</div>
              </div>
            </div>
              : <></>}
            </div>
          </div>
        </a>
      </li> : <></>}
    </ul> */}
   </DropdownMenu>
   <DropdownMenu>
    <ul>
    {list && list.length > 0 && list.map((item, index)=>{
      return(<>
      <li className="hr_sepretr" key={index}>
        <a href="#/" className="dropdown-item">
          <div className="d-flex align-items-center text-truncate">
            <div className="c-list-icon">
              <div className="h25w25 d-flex align-items-center">
                  {
                  item.title == "l_satisfaction_level" ?  <><Star size={18} weight="light" className="c-icons" /></> :
                  item.title == "l_number_of_quote_requests" ?  <><ListNumbers size={18} weight="light" className="c-icons" /></> :
                  item.title == "l_delivery_of_consumables" ?  <><Package size={18} weight="light" className="c-icons" /></> :
                  item.title == "l_compliance_documents" ?  <><File size={18} weight="light" className="c-icons" /></> :
                  item.title == "l_on_site_work_duration" || item.title == "l_avg_time_to_answer" || item.title == "l_avg_time_to_fix" ?  <><Clock size={18} weight="light" className="c-icons" /></> :
                  item.title == "l_task_score" ?  <><User size={18}  weight="light" className="c-icons" /></> :
                  <></>}
              </div>
            </div>
            <div className="ps-2 w100minus30 text-truncate d-flex align-items-center justify-content-between">
              <div className="c-list-detail text-truncate">{_l(item.title)}</div>
                {item.title == "l_on_site_work_duration" ?
                  <div className="d-flex align-items-center title-fonts ps-2">
                    <span className="color-orange c-font f-13">{metricsDetails && metricsDetails.site_work_duration ? metricsDetails.site_work_duration.hour : 0}</span>
                    <span className="color-white-60 c-font f-10">{_l("l_hrs")}</span>
                    <span className="color-orange c-font f-13">{metricsDetails && metricsDetails.site_work_duration ? metricsDetails.site_work_duration.minute : 0}</span>
                    <span className="color-white-60 c-font f-10">{_l("l_min")}</span>
                  </div>
                  : item.title == "l_avg_time_to_answer" ?
                    <div className="d-flex align-items-center title-fonts ps-2">
                      <span className="color-orange c-font f-13">{metricsDetails && metricsDetails.avg_time_to_answer ? metricsDetails.avg_time_to_answer.hour : 0}</span>
                      <span className="color-white-60 c-font f-10">{_l("l_hrs")}</span>
                      <span className="color-orange c-font f-13">{metricsDetails && metricsDetails.avg_time_to_answer ? metricsDetails.avg_time_to_answer.minute : 0}</span>
                      <span className="color-white-60 c-font f-10">{_l("l_min")}</span>
                    </div>
                    : item.title == "l_avg_time_to_fix" ?
                      <div className="d-flex align-items-center title-fonts ps-2">
                        <span className="color-orange c-font f-13">{metricsDetails && metricsDetails.avg_time_to_fix ? metricsDetails.avg_time_to_fix.hour : 0}</span>
                        <span className="color-white-60 c-font f-10">{_l("l_hrs")}</span>
                        <span className="color-orange c-font f-13">{metricsDetails && metricsDetails.avg_time_to_fix ? metricsDetails.avg_time_to_fix.minute : 0}</span>
                        <span className="color-white-60 c-font f-10">{_l("l_min")}</span>
                      </div>
                      :
                      item.title == "l_satisfaction_level" ?
                        <div className="progress-slider w-50 ps-2">
                          <div className="d-flex align-items-center">
                            <div className="progress">
                              <div className="progress-bar" style={{ width: `${metricsDetails.satisfaction ? metricsDetails.satisfaction : 0}%` }}></div>
                            </div>
                            <div className="ms-2 title-fonts">{metricsDetails.satisfaction ? metricsDetails.satisfaction : 0}%</div>
                          </div>
                        </div>
                        : item.title == "l_task_score" ?
                          <div className="progress-slider w-50 ps-2">
                            <div className="d-flex align-items-center">
                              <div className="progress">
                                <div className="progress-bar" style={{ width: `${metricsDetails.task_score ? Math.ceil(metricsDetails.task_score) : 0}%` }}></div>
                              </div>
                              <div className="ms-2 title-fonts">{metricsDetails.task_score ? Math.ceil(metricsDetails.task_score) : 0}%</div>
                            </div>
                          </div> :
                          item.title == "l_compliance_documents" ?
                            <div className="d-flex align-items-center title-fonts ps-2">
                              <span className="color-white-80 c-font f-13">{metricsDetails.compliance_documents ? metricsDetails.compliance_documents : 0}</span>
                            </div>
                            : item.title == "l_delivery_of_consumables" ?
                              <div className="d-flex align-items-center title-fonts ps-2">
                                <span className="color-white-80 c-font f-13">{metricsDetails.delivery_of_consumables ? metricsDetails.delivery_of_consumables : 0}</span>
                              </div>
                              :
                              item.title == "l_number_of_quote_requests" ?
                                <div className="d-flex align-items-center title-fonts ps-2">
                                  <span className="color-white-80 c-font f-13">{metricsDetails.total_quote_request ? metricsDetails.total_quote_request : 0}</span>
                                </div> : <></>

                }
            </div>
          </div>
        </a>
      </li>
      </>)
    })}
    </ul>
   </DropdownMenu>
   </> : 
   formForMe ?
   <>
    <DropdownMenu container="body" className={`dropdown-menu w-170 overflowscroll ${zoomClass == "zoom-1" ? "demo" : ""}`}>
      {dropdownData && dropdownData.length ? 
      ( dropdownData.map((item, index) => {
        return(<>
        <li className="">
          <a href="#/" className="hr_sepretr dropend w-100">
            <div className="d-flex align-items-center text-truncate dropdown-item custom-assigneesMain ">
              <div className="c-list-icon">
                <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                  <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                  <Hash size={16} weight="light" className="c-icons" />
                  </div>
                </div>
              </div>
              <div className="ps-2 w100minus40 text_wrap">
                <div className="c-list-detail text-truncate ">{item.title}</div>
              </div>
              <div className="c-list-icon"><Hash size={16} weight="light" className="c-icons" /></div>
            </div>
          </a>
        </li>
        </>)

      })) : <></>}
    </DropdownMenu>
   </>
   :
    <DropdownMenu container="body" className={`dropdown-menu w-170 overflowscroll ${zoomClass == "zoom-1" ? "demo" : ""}`}>
      {dropdownData && dropdownData.length ? (
        dropdownData.sort((a, b) => {
          if (sortList) {
            if (a.name < b.name)
              return -1;
          }
        }).map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item && Object.keys(item).length > 0 ?
              <> <li>
                <a href="#/"
                  className={`dropdown-item ${item.id && selectedIds.includes(parseInt(item.id)) ? "active with-tick" : ""}`}
                  onClick={() => {
                    localStorage.setItem("callerInfo", JSON.stringify({
                      id: item.filter_key,
                      main_key:item.main_key,
                      main_sub_key: item.main_sub_key,
                      filter_key: item.filter_key,
                      title:item.name
                    }))
                    if(forCall && item.userId != localStorage.getItem("orgChatUserId")){
                      if(fromChat){
                        makeCall(item.userId);
                      }else{
                        makeCall(item.userId, item.email, item.userType);
                      }
                    }else if (fromAichat && item.userId != localStorage.getItem("orgChatUserId")){
                      makeCall(item.userId, item.name, callFromGroup);
                    }else if (isFromCalDropDown == null && handler) {
                      handler(item);
                    }

                  }}
                >
                  <div className="d-flex align-items-center text-truncate ">
                    {item.image || item.image_url ? (
                      <div className="c-list-icon">
                        <div className={`h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle position-relative ${item.userType == "client" ? "contact_user" : item.userType == "staff" && item.myrRole == 2 ? "operator_user" : "staff_user" }`}>
                          {fromChat || fromAichat && item.isOnline ?
                          <span class="d-flex  light-pill mt-1 position-absolute rounded-pill headmemberstatus right2px" >
                            <span className={`c-dots c-7 ${item.isOnline == 1 ? "done" : "bg-warning"} rounded-circle`}></span>
                          </span>
                          : <></>
                          }
                          <div
                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                            style={{
                              backgroundImage: `url('${item.image || item.image_url}')`,
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="h25w25 rounded-circle d-none">
                      <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle d-flex">
                        <User size={16}  weight="light" className="c-icons" /> 
                      </div>
                    </div>
                    <div className="ps-2 w100minus20 text_wrap ">
                      <div className="c-list-detail color-white text-truncate">
                        {item.name || item.full_name}
                      </div>
                      {fromReadMsg ? <div className="c-font color-white-60 f-10 lh-1 location me-2 mt-1">{moment(item.readTime).format("DD/MM/YYYY HH:mm:ss a")}</div> : <></>}
                    </div>
                    {(fromAichat && item.userId != localStorage.getItem("orgChatUserId")) || (forCall && fromChat && item.userId != localStorage.getItem("orgChatUserId")) ?
                    <a href="#/" className="comman_action_icon ms-2">
                      <PhoneCall size={18}  weight="light" className="c-icons" /> 
                    </a>
                    : <></>
                    }
                    {fromReadMsg ? 
                    <>
                        <Checks size={14} weight="light" className={`c-icons msg-status ms-2 msg-delivered-double ${item.isRead == 1 ? "color-green" : ""}`} />
                    </>
                    :<></>}
                  </div>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider my-0" />
              </li> </>: <></>}
            </React.Fragment>
          );
        })
      ) : (
        <li>
          <div className="comman-placeholder-wrapper w-100 py-0 d-flex h-100">
            <div className="d-flex flex-column align-items-center m-auto">
              <UserCircle size={16}  weight="light" className="c-icons small-image opacity-50" />
              <span className="color-white-80 py-2 title-fonts">
                {_l("l_no_assignee")}
              </span>
            </div>
          </div>
        </li>
      )}
    </DropdownMenu>}
    </>
  );
};

export default UncontrolledGeneralListing;
