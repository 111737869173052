import moment from "moment";
import apiService from "./api-service/api-service";

const API_URL = process.env.REACT_APP_API_URL;

const addBulkEquipments = (project_id, equipment_data) => {
  const endPoint = API_URL + "mod=equipments&act=add_equipment_bulk";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("equipment_data", JSON.stringify(equipment_data));
  return apiService(endPoint, "POST", true, form);
};

const addEquipmentQuantity = (project_id, equipment_id, quantity) => {
  const endPoint = API_URL + "mod=equipments&act=add_equipments_quantity";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("equipment_id", equipment_id);
  form.append("quantity", quantity);

  return apiService(endPoint, "POST", true, form);
};

const addNewEquipment = (project_id, name,equipmentData,quantityData,comments,floorId = 0, isConsumable = false) => {
  const endPoint = API_URL + "mod=equipments&act=add_equipments";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("name", name);
  if (isConsumable) {
    form.append("is_consumable", 1);
  }else{
    form.append("is_consumable", 0);
  }
  if (equipmentData) {
    if (floorId > 0) { 
      form.append("floor_id",floorId);
    }
    form.append("priority",equipmentData.priority);
    if(equipmentData.id){
      form.append("equipment_id",equipmentData.id);
    }
    form.append("contact_assignee",equipmentData.contact_assignee);
    form.append("provider_assignee",equipmentData.provider_assignee);
    if (equipmentData.file && equipmentData.file.length > 0) {
      equipmentData.file.forEach((file,index) => {
        if (file.file_id == undefined) { 
          form.append(`files[${index}]`,file);
        }
      }); 
    }
    if (comments && comments.length > 0) {
      comments.forEach((comment, index) => {
        form.append(`comments[${index}][content]`,comment.content);
      })      
    }
    if (quantityData && quantityData.length > 0) {
      quantityData.forEach((qty,key)=>{
        form.append(`quantity[${key}][name]`,qty.name);
        if (qty.qty_id > 0) { 
          form.append(`quantity[${key}][id]`,qty.id);
        }
        if (qty.local_anchors_id > 0) { 
          form.append(`quantity[${key}][local_anchor_id]`,qty.local_anchors_id);
        }
        form.append(`quantity[${key}][web_x]`,qty.web_x || 0);
        form.append(`quantity[${key}][web_y]`,qty.web_y || 0);
        form.append(`quantity[${key}][web_z]`,qty.web_z || 0);
        form.append(`quantity[${key}][lifetime]`,moment(qty.lifetime).format("YYYY-MM-DD"));
        qty.attachments && qty.attachments.length > 0 && qty.attachments.forEach((files,k)=> {
          if (files.file_id == undefined) { 
            form.append(`qty_files[${key}][${k}]`,files);
          }
        })
      })
    }
  }


  return apiService(endPoint, "POST", true, form);
};

const assignEquipmentToSpace = (equipment_id, quantity_id, project_id, space_id) => {
  const endPoint = API_URL + "mod=equipments&act=equipments_assign_to_type_of_space";
  let form = new FormData();
  form.append("equipment_id", equipment_id);
  form.append("equipment_item_id", quantity_id);
  form.append("project_id", project_id);
  form.append("type_of_space_item_id", space_id);
  return apiService(endPoint, "POST", true, form);
};

const createEquipmentSchedule = (equipment_id, project_id) => {
  const endPoint = API_URL + "mod=equipments&act=create_equipment_schedule";
  let form = new FormData();
  form.append("equipment_id", equipment_id);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const deleteEquipment = (equipment_id, project_id) => {
  const endPoint = API_URL + "mod=equipments&act=delete_equipment";
  let form = new FormData();
  form.append("id", equipment_id);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const deleteEquipmentQuantity = (equipment_id, quantity_id, project_id) => {
  const endPoint = API_URL + "mod=equipments&act=delete_equipment_quantity";
  let form = new FormData();
  form.append("equipment_id", equipment_id);
  form.append("quantity_id", quantity_id);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};

const getEquipmentDetails = (project_id, equipment_id) => {
  const endPoint = API_URL + "mod=equipments&act=get_equipment_details";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("equipment_id", equipment_id);

  return apiService(endPoint, "POST", true, form);
};

const getEquimenntsList = (project_id,floorId = 0, is_scan_3d = 0) => {
  const endPoint = API_URL + "mod=equipments&act=get_equipment_list";
  let form = new FormData();
  form.append("project_id", project_id);
  if (floorId > 0) { 
    form.append("floor_id", floorId);
  }

  if (is_scan_3d > 0) {
    form.append("is_scan_3d", is_scan_3d);
  }
  return apiService(endPoint, "POST", true, form);
};

const removeEquipmentQuantityImage = (project_id, equipment_id = 0, equipment_item_id = 0, file_id = 0) => {
  const endPoint = API_URL + "mod=equipments&act=remove_equipment_attachments";
  let form = new FormData();
  form.append("project_id", project_id);
  if (equipment_item_id > 0) {
    form.append("equipment_item_id", equipment_item_id);
  }
  if (equipment_id > 0) { 
    form.append("equipment_id", equipment_id);
  }
  form.append("file_id", file_id);

  return apiService(endPoint, "POST", true, form);
};

const updateEquipmentDetail = (project_id, equipment_id, key, value, isConsumable = false, minimum_quantity) => {
  const endPoint = API_URL + "mod=equipments&act=update_equipments_details";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("equipment_id", equipment_id);
  form.append(key, value);
  form.append("minimum_quantity", minimum_quantity);
  if (isConsumable) {
    form.append("is_consumable", 1);
  }else{
    form.append("is_consumable", 0);
  }

  return apiService(endPoint, "POST", true, form);
};

const updateEquipmentItem = (project_id, equipment_id, equipment_item_id, device_id, name, contact_assignee, provider_assignee) => {
  const endPoint = API_URL + "mod=equipments&act=update_equipment_item";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("equipment_id", equipment_id);
  form.append("equipment_item_id", equipment_item_id);
  form.append("device_id", device_id);
  form.append("contact_assignee", contact_assignee);
  form.append("provider_assignee", provider_assignee);
  form.append("name", name);

  return apiService(endPoint, "POST", true, form);
};

const uploadEquipmentImages = (project_id, equipment_id, equipment_item_id, files) => {
  const endPoint = API_URL + "mod=equipments&act=add_equipment_attachments";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("equipment_id", equipment_id);
  form.append("equipment_item_id", equipment_item_id);
  Array.from(files).forEach((file, index) => {
    form.append(`file[${index}]`, file);
  });
  return apiService(endPoint, "POST", true, form);
};

export default {
  addBulkEquipments,
  addEquipmentQuantity,
  addNewEquipment,
  assignEquipmentToSpace,
  createEquipmentSchedule,
  deleteEquipment,
  deleteEquipmentQuantity,
  getEquipmentDetails,
  getEquimenntsList,
  removeEquipmentQuantityImage,
  updateEquipmentDetail,
  updateEquipmentItem,
  uploadEquipmentImages
}