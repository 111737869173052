import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { _l } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_SECRET);
Geocode.setLanguage("fr");
Geocode.setRegion("fr");

const AddressAutocomplete = ({
  className,
  label,
  placeholder,
  name,
  value,
  data,
  setAdressComponents = () => {},
}) => {
  const addressOptions = {
    // componentRestrictions: { country: "fr" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };

  const [regionList, setRegionList] = useState([]);
  const [addressInputValue, setAddressInputValue] = useState("");

  const addressRef = useRef();
  const autoCompleteRef = useRef();

  const selectSpaceRegion = (addressComponent) => {
    let regionId = 0;
    let regionName = "";
    let fetchedRegion = "";
    let index = addressComponent.findIndex(
      (add) => add.types && add.types.includes("administrative_area_level_2")
    );
    if (index === -1) {
      index = addressComponent.findIndex(
        (add) => add.types && add.types.includes("administrative_area_level_1")
      );
    }
    if (index > -1) {
      fetchedRegion = addressComponent[index].long_name;
    }
    regionList.forEach((region) => {
      if (
        region.label.toLowerCase().trim() == fetchedRegion.toLowerCase().trim()
      ) {
        regionId = region.value;
        regionName = region.label;
      }
    });
    if (regionId == 0) {
      setRegionList(regionList.concat([{ value: 0, label: fetchedRegion }]));
      return { region_id: 0, region_name: fetchedRegion };
    } else {
      return { region_id: regionId, region_name: regionName };
    }
  };

  const handleGeoCoding = (addressValue) => {
    Geocode.fromAddress(addressValue).then(
      (response) => {
        let address = "";
        let city = "";
        let postal_code = "";
        let country = "";
        let country_code = "";

        const placeId = response.results[0].place_id;
        const { lat, lng } = response.results[0].geometry.location;
        const { address_components } = response.results[0];
        const { region_id, region_name } = selectSpaceRegion(
          response.results[0].address_components
        );
        address = response.results[0].formatted_address;

        address_components.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("postal_code")) {
            postal_code = component.long_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
            country_code = component.short_name;
          }
        });
        setAdressComponents({
          address,
          region_id,
          region_name,
          lat,
          lng,
          place_id: placeId,
          city,
          postal_code,
          country,
          country_code,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    if (addressInputValue && addressInputValue.trim().length && addressRef.current) {
      try {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          addressRef.current,
          addressOptions
        ).addListener("place_changed", () => {
          handleGeoCoding(addressRef.current.value);
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [addressInputValue]);

  useEffect(() => {
    const getRegionList = async () => {
      try {
        const response = await CustomerServices.getRegionList();
        if (response.status) {
          const regions = response.data.map((region) => {
            return {
              label: region.name,
              value: region.id,
            };
          });
          setRegionList(regions);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getRegionList();

    if(((data.address && data.address.trim().length) || value && value.trim().length) && !data.postalCode)
    {
      let addressString = data.address || value;
      handleGeoCoding(addressString.trim());
    }

  }, []);

  return (
    <Form.Group className={className}>
      <Form.Label className="input-label no-asterisk">{_l(label)}</Form.Label>
      <input
        placeholder={`${_l(placeholder)}`}
        type="text"
        className="form-control "
        name={name}
        ref={addressRef}
        onChange={(e) => setAddressInputValue(e.target.value)}
        defaultValue={value}
      />
    </Form.Group>
  );
};

export default AddressAutocomplete;
