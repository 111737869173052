import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LogoGreenDark from "../assets/icons/LogoGreenDark";
import LogoGreenLight from "../assets/icons/LogoGreenLight";
import myMsgAudio from "../assets/audio/my-msg-audio.mp3";
import myCallAudio from "../assets/audio/my-call-audio.mp3";
import myCallEndAudio from "../assets/audio/my-call-end-audio.mp3";
import myCallAcceptAudio from "../assets/audio/my-call-accept-audio.mp3";
import myCallRecordingAudio from "../assets/audio/my-call-recording-audio.mp3";
import HeaderUserDropdown from "./Dropdowns/HeaderUserDropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { _l, formateDate, getImagePreviewIcon, hideLeftPanelNew, removeDuplicateObjects, showError, showLeftPanelNew } from "../hooks/utilities";
import jquery from "jquery";
import moment from "moment";
import Button from "react-bootstrap/Button";
import {
  CalendarBlank,
  List,
  Chat,
  X,
  SlidersHorizontal,
  MagnifyingGlass,
  CaretLeft,
  HardDrives,
  SignOut,
  DotsThree,
  GearSix,
  DesktopTower,
  Phone,
  UsersThree,
  Funnel,
  User,
  Gauge,
  MagicWand,
  Clipboard,
  PhoneCall,
  FadersHorizontal,
  EnvelopeSimple,
} from "phosphor-react";
import {
  leftPanelFilters,
  getTaskCount,
  updateProjectTopics,
  setTaskUnreadCmtCntAll,
  setSpaceNotificationCount,
  projectTaskCounts,
  setVirtualSpaceNotificationCount,
  getTopicTasks,
  getDefaultTopics,
  userLogout,
  selectedChatUser,
  setGeneralUnreadCount,
  setAllChatUserList,
  setTaskTypeFilter,
  setSelectedGloablView,
  isPinnedDelayFilter,
  taskBulkAction,
  setAllCheckedTask,
  setSelectedDateView,
  setMetricsDetails,
  getDashboardFilterList,
  setFetchedTaskandMessages,
  updateTaskList,
  chatUnreadMessageCount,
  unreadTaskComment,
  lastMessageSpace,
  setCallRecording,
  setCallDisconnect,
  lastMessageContactDir
} from "../actions/customer";
import {
  updateUnreadMsgCount,
  updateNewMessage,
  updateNewTaskNoti,
  updateNewMsgForAM,
  playMsgAudio,
  updateGeneralMsgCount,
  setOpenGeneralChatGroup,
  userOffline,
  fetchGroups,
  initCall,
  getGroupDetails,
  groupIn,
  handleChatScrolltoStop,
  groupOut,
  fetchMessages,
  sendMessage,
  fetchGroupsExternal,
} from "../actions/chat";
import MailSetting from "./Modals/MailSetting";
import { useLocation } from "react-router-dom";
import customerServices from "../services/customer-services";
import benchmarkServices from "../services/benchmark-services";
import { showConfirmation, showMessage } from "../actions/messages";
import { SET_GLOBAL_SEARCH_STRING, USER_LOGOUT } from "../actions/action-type";
import AttechmentViewerMain from "./AttechmentViewer/AttechmentViewerMain";
import SetUserPassword from "./Modals/SetUserPassword";
import AudioCall from "./Chat/AudioCall";
import HeaderFilterDropdown from "./TaskModalComponents/HeaderFilterDropdown";
import StepIcon from "../assets/icons/StepIcon";
import FloorFilterDropdown from "./Dropdowns/FloorFilterDropdown";
import ToggleViewDropdown from "./Dropdowns/ToggleViewDropdown";
import Autocomplete from "./Dropdowns/Autocomplete";
import adminServices from "../services/admin-services";
import ActionDropdown from "./Dropdowns/ActionDropdown";
import { ATTACH_BASE_URL, USR_IMG_PLACEHOLDER } from "../actions/chat-action-type";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import UncontrolledGeneralListing from "./TaskModalComponents/UncontrolledGeneralListing";
import TopicSettingModal from "./Modals/TopicSettingModal";
import ChatServices from "../services/chat-services";
import ChatGroupDetailModal from "./Modals/ChatGroupDetailModal";

import EquipmentModalHTML from "./Modals/EquipmentModalHTML";
import chatServices from "../services/chat-services";
import FeedChatCallModal from "./Modals/FeedChatCallModal";
import FeedChatAudioCall from "./ChatAi/FeedChatAudioCall";
import BulkActionsModal from "./Modals/BulkActionsModal";
import UnControlMainFiltersDropDown from "./TaskModalComponents/LeadsMainFiltersDropDown";
import ListBulletsIcon from "../assets/icons/ListBulletsIcon";
import AddEmailTemplatesModalNew from "./Modals/AddEmailTemplatesModalNew";

const BACK_BUTTON_RESTRICTED = ["proposal", "space-onboarding"];

const Header = ({
  projectId,
  removeStatusFilter,
  hideDriveSyncBtn = true,
  setShowMailModal,
  showMailModal,
  setSynchronizedMail,
  pageName = "",
}) => {
  const {
    taskFilters,
    activeTaskStatusFilters,
    topicData,
    taskUnreadCmtCntAll,
    globalSearchString,
    isBackdrop,
    commanoffcanvasIsOpen,
    generalUnreadCount,
    selectedUserForChat,
    globalView,
    pinDelayfilter,
    getCheckedTask,
    selectedProject,
    dateView,
    groupList,
    lastChatSession,
    defaultTopics,
    dashBoradFilter,
    createTaskModalIsopen,
    virtualSpaceList,
    isContactDirChat
  } = useSelector((state) => state.customer);
  const isPremiumEstimateEdit = useSelector(
    (state) => state.customer.isPremiumEstimateAccess
  );
  const stepData = useSelector((state) => state.onboardingReducer.stepData);
  const [preEstiPageDisable, setPreEstiPageDisable] = useState(false);
  const {
    unreadMsgCount,
    newMessage,
    newTaskNoti,
    newMsgForAM,
    openGeneralChatGroup,
    callStatus,
    groupDetails
  } = useSelector((state) => state.chat);
  const {
    myrStaffList,
    myrLeadSources,
    checkedBulkLeads
  } = useSelector((state) => state.leadsReducer);
  const chatPlanTittle = useSelector((state) => state.customer.chatPlanTittle);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  let key = searchParams.get("feature")
  const displayUnreadMsgCount = ((globalView == "liteVersionView" && localStorage.getItem("isLiteVersion") == "true") && generalUnreadCount > 0 ) || (localStorage.getItem("isLiteVersion") != "true" && unreadMsgCount > 0) ? "" : "d-none";  
  const pathname = window.location.pathname;
  const isLeadPage = pageName == "leadPage" ? 1 : 0;
  const [currentUrl, setCurrentUrl] = React.useState(pathname);
  const [isLiteVersion, setisLiteVersion] = useState(false);
  const [taskType, setTaskType] = useState('2');
  const [selectedOffice, setSelectedOffice] = useState([]);
  const [taskSearchString, setTaskSearchString] = useState("");
  const navigate = useNavigate();
  const [CalendarPageActiveClass, setCalendarPageActiveClass] = useState(false);
  const [DocumentPageActiveClass, setDocumentPageActiveClass] = useState(false);
  const [leftPanelActiveFilters, setLeftActivePanelFilters] = useState([]);
  const [ChatPageActiveClass, setChatPageActiveClass] = useState(false);
  const [showFilterArea, setShowFilterArea] = useState(true);
  const ref = useRef(null);
  const contact_image = localStorage.getItem("contact_image");
  const emailAddress = localStorage.getItem("email");
  const userType = localStorage.getItem("user_type");
  const isAdmin = localStorage.getItem("admin");
  const packagePlan = localStorage.getItem("packagePlan") ? JSON.parse(localStorage.getItem("packagePlan")) : {}
  const [showDrviesModal, setShowDrviesModal] = useState(false);
  const handleClose = () => setShowDrviesModal(false);
  const handleShow = () => setShowDrviesModal(true);
  const [showSupportNavigator, setShowSupportNavigator] = useState(true);
  const [showDocumentsNavigator, setShowDocumentsNavigator] = useState(true);
  let searchMentionUsers = [];
  const spaceState = useSelector((state) => state.customer.spaces);
  const [searchString, setSearchString] = useState('');
  const [mentionStaffArray, setMentionStaffArray] = useState([]);
  const [selectedStaffMembersForSpaceFilter, setSelectedStaffMembersForSpaceFilter] = useState([]);
  const calendar_type = localStorage.getItem("calendar_type");
  const googlecalendar_connectivity = useSelector(
    (state) => state.google_calendar.googlecalendar_connectivity
  );
  const outlook_calendar = useSelector(
    (state) => state.discord_auth.outlook_calendar_connectivity
  );
  const [showGlobal, setShowGlobal] = useState(false);
  const [showGlobalSerachModal, setShowGlobalSerachModal] = useState(false);
  const mailModalHandleClose = () => setShowMailModal(false);
  const mailModalHandleShow = () => setShowMailModal(true);
  const location = useLocation();
  const [loginUserImage, setLoginUserImage] = useState(
    localStorage.getItem("contact_image")
  );
  const buttonInCalendar = location.pathname.includes("calendar");
  const [randomID, setRandomID] = useState(String(Math.random()));
  const [topicLogo, setTopicLogo] = useState({});
  const [selectedTeam, setSelectedTeam] = useState();
  const {themeColor} = useSelector((state) => state.customer)
  const [LightTheme , setLightTheme] = useState(localStorage.getItem('selectedTheme') == "light" ? true : false);
  const [show, setShow] = useState(false);
  const [viewGroupDetails, setViewGroupDetails] = useState(false);
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);
  const [groupMemberIds, setGroupMemberIds] = useState([]);
  const [providerOpearator, setProviderOpearator] = useState([]);
  let chatUserId = localStorage.getItem("chatUserId");
  let project_id = localStorage.getItem('selectedOffice')
  const [filterArr, setFilterArr] = useState([]);
  const [filterListForDisplay, setFilterListForDisplay] = useState([]);
  const [filterList, setFilterList] = useState([]);
  let virtualSpaceClientId = localStorage.getItem("virtualSpaceClientId")
  const rel_type = searchParams.get("rel_type");
  let isRegisterUser = localStorage.getItem("space_setup_pending");
  const [showCallModal, setShowCallModal] = useState(false);
  const [totalNotificationCount, settotalNotificationCount] = useState(0);
  const [missedcallCounts, setmissedcallCounts] = useState(0);
  const [selectedTopic, setselectedTopic] = useState();
  let selected_topic = JSON.parse(localStorage.getItem("selectedTopic"))
  let dateRangeFilters = taskFilters && taskFilters.length ? taskFilters.filter((dateRangeFilter) => dateRangeFilter.main_key == "date_range_filter") : []
  let checkedLeads = useRef([]);
  const [showBulkActionForLeads, setShowBulkActionForLeads] = useState(false);
  const [myrStaff, setMyrStaff] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [showPasswordSetModal, setShowPasswordSetModal] = useState(localStorage.getItem("is_registration_confirmed") == 0);
  const [isOnline, setIsOnline] = useState(0)
  const [tagesLeads, settagesLeads] = useState([]);
  let is_registered = localStorage.getItem("is_registered");
  let isContactDir = groupDetails && groupDetails.extraData && groupDetails.extraData.mainKey == "contact_directory";
  const dashboardBenchmarkId = !isNaN(Number(searchParams.get("dashboard-benchmark-id"))) && Number(searchParams.get("dashboard-benchmark-id")) ? Number(searchParams.get("dashboard-benchmark-id")) : 0;
  let ONBOARDING_PAGES = ["/hire-new-agent","/onboarding-proposal","/onboarding-employees","/view-onboarding-proposal","/job-proposal-onboarding" ,is_registered == 1 ? "/connect-provider" : ""];
   
  const [selectedleadfilers, setselectedleadfilers] = useState([]);
  const [bulkEmailSendShow, setBulkEmailSendShow] = useState(false);

  const modalhandleleadClose = () => setBulkEmailSendShow(false);
  const modalhandleleadShow = () => setBulkEmailSendShow(true);


  useEffect(() => {
    if(groupDetails && groupDetails.members){
      var chatUserId = localStorage.getItem("chatUserId");
  
      setGroupMemberIds(groupDetails.members.map((val) => val._id));
      if(taskFilters.some((filter)=>filter.main_key == "my_task")){
        setIsGroupAdmin(true)
      }else{

        setIsGroupAdmin(true);
        // setIsGroupAdmin(groupDetails.type == 2 && groupDetails.admin == chatUserId ? true : false);
      }
      if (selected_topic && selected_topic.title) {
        const online = groupDetails.members.filter((user) => user.lname && user.lname != '' ? `${user.fname} ${user.lname}` == selected_topic.title : user.fname == selected_topic.title)[0]
        if (online && online.isOnline && online.isOnline == 1) {
          setIsOnline(1);
        }else{
          setIsOnline(0);
        }
      }
    }
  }, [groupDetails]);


  function getImageDimensions(image) {
    return new Promise((resolve, reject) => {
      image.onload = function (e) {
        const width = this.width;
        const height = this.height;
        resolve({ height, width });
      };
    });
  }

  function compressImage(image, scale, initalWidth, initalHeight) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");

      canvas.width = scale * initalWidth;
      canvas.height = scale * initalHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      ctx.canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/jpg");
    });
  }

  async function readAndPreview(file) {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    var newName = timestamp + ".png";
    file.name = newName;
    var file = new File([file], file.name, { type: file.type });
    return file
  }

  async function previewImage(inputId, previewId = "", previewAttr = "src") {
    const imageInput = document.getElementById(inputId);
    const uploadedImage = imageInput.files[0];
    if (!uploadedImage) {
      return;
    }
    var name = uploadedImage.name;
    if (!/\.(jpe?g|png|jpg)$/.test(name)) {
      return dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_select_valid_file")));
    }
    const inputPreview = document.getElementById("input-preview");
    inputPreview.src = URL.createObjectURL(uploadedImage);

    const { height, width } = await getImageDimensions(inputPreview);

    const MAX_WIDTH = 500;
    const MAX_HEIGHT = 500;

    const widthRatioBlob = await compressImage(
      inputPreview,
      MAX_WIDTH / width,
      width,
      height
    );
    const heightRatioBlob = await compressImage(
      inputPreview,
      MAX_HEIGHT / height,
      width,
      height
    );
    const compressedBlob =
      widthRatioBlob.size > heightRatioBlob.size
        ? heightRatioBlob
        : widthRatioBlob;

    const outputPreview = document.getElementById("output-preview");
    outputPreview.src = URL.createObjectURL(compressedBlob);
    if(previewId != ""){
      const previewImage = document.getElementById(previewId);
      if(previewAttr == "style"){
        previewImage.style.backgroundImage = "url('"+URL.createObjectURL(compressedBlob)+"')";
      }else{
        previewImage.src = URL.createObjectURL(compressedBlob);
      }
    }

    const optimalBlob = compressedBlob.size < uploadedImage.size ? compressedBlob : uploadedImage;
    compressedBlob.name = name;
    URL.revokeObjectURL(inputPreview);
    URL.revokeObjectURL(outputPreview);
    return readAndPreview(compressedBlob);
  }


  const [equipementModalHTML, setEquipementModalHTML] = useState(false)
  const handleDropDownOpen = () => {
    jquery("#HeaderDocumentLinkDropdown").addClass("active");
  };
  const handleDropDownClose = () => {
    jquery("#HeaderDocumentLinkDropdown").removeClass("active");
  };
  const [showPills, setshowPills] = useState()
  const [showSettingModal, setshowSettingModal] = useState(false)
  useEffect(() => {
  setshowPills(window.innerWidth <= 1500 ? 1 : 2)
})

useEffect(() => {
  if (themeColor == "light") {
    jquery("html").addClass("light-theme")
    setLightTheme(true);
  }else{
    jquery("html").removeClass("light-theme")
    setLightTheme(false);
  }

  
}, [themeColor])

useEffect(() => {
  dispatch(getDashboardFilterList());
  if (Number(is_registered) == 1) {
    localStorage.removeItem("selectedOffice")
  }
}, [])


const selectedFilter = (item) => {
  let tempFilter = filterArr;
  let tempFilterData = filterListForDisplay;
  customerServices.changeDashboardFilter(item.id).then((res) => {
    if (res.status == 1) {
      if (tempFilter.includes(item.id)) {
        tempFilter = tempFilter.filter((filter) => filter != item.id);
        tempFilterData = tempFilterData.filter((filter) => filter.id != item.id);
      } else {
        tempFilter.push(item.id);
        // if (item.slug == "l_task_score") {
        //   // tempFilterData.push({ ...item, is_active: "1" ,value:totalTaskScore});
        // }else{
          tempFilterData.push({ ...item, is_active: "1" });
        // }
      }
      setFilterArr(tempFilter.slice());
      setFilterListForDisplay(removeDuplicateObjects(tempFilterData).slice());
    }
  })
}

const handleCreateChatGroup =(projectId,main_key,main_sub_key,filter_key)=>{
  localStorage.removeItem("firstDate");
  localStorage.removeItem("lastDate");
  dispatch(setFetchedTaskandMessages([]));
  var activeGroupIdPrev = localStorage.getItem("activeGroupId");
  if (chatUserId != "" && activeGroupIdPrev != "" && activeGroupIdPrev != undefined) {
    var socketDataOut = {
      groupId: activeGroupIdPrev,
      userId: chatUserId,
    };
    dispatch(groupOut(socketDataOut));
  }
    customerServices.createChatGroup(projectId,main_key,main_sub_key,filter_key).then((res)=>{
      if(res && res.status && res.data){
        let groupID = res.data._id
        localStorage.setItem("activeGroupId", groupID);
        chatServices.getGroupDetails( localStorage.getItem("chatUserId"), groupID).then((res) => {
          if (res.success) {
            var activeGroupId = localStorage.getItem("activeGroupId");
            if (chatUserId != "" && activeGroupId != "" && activeGroupId != undefined) {
              var socketDataIn = {
                groupId: activeGroupId,
                userId: chatUserId,
              };
              if (globalView  == "AichatView") {
                dispatch(groupIn(socketDataIn));
                dispatch(fetchMessages( 
                  localStorage.getItem("chatUserId"), 
                  groupID,
                  0,
                  ()=>{},
                  1,
              ));          
              }
            }
            localStorage.setItem("getGroupDetails", JSON.stringify(res.result))
            dispatch(getGroupDetails(res.result))       
          }else{
            dispatch(showMessage("unsucess", _l("l_error"),res.message));
          }
        });
        
      }else{
        localStorage.setItem("activeGroupId",0);
        dispatch(showMessage("unsucess", _l("l_error"),_l(res.message)));
      }
    })
  
  }

useEffect(() => {
  if (dashBoradFilter) {
    setFilterList(dashBoradFilter);
    let filter = []
    let tempFilterData = []
    dashBoradFilter.forEach((dd) => {
      if (dd.is_active == 1) {
        filter.push(dd.id);
        tempFilterData.push(dd);;
      }
    })
    setFilterArr(filter.slice());
    setFilterListForDisplay(tempFilterData.slice());
  }
}, [JSON.stringify(dashBoradFilter)])


  useEffect(() => {
    if (localStorage.getItem("selectedVirtualSpace")) {
      try {
        let virtualSPace = JSON.parse(
          localStorage.getItem("selectedVirtualSpace")
        );
        setSelectedTeam(
          virtualSPace && Object.keys(virtualSPace).length ? virtualSPace : {}
        );
        dispatch(
          getDefaultTopics(
            localStorage.getItem("selectedOffice"),
            "",
            "",
            pageName
          )
        );
      } catch (error) {
        console.log(error);
      }
      jquery(".link-center-box-toggle").addClass("d-none");
    } else {
      setSelectedTeam();
      jquery(".link-center-box-toggle").removeClass("d-none");
    }
  }, [localStorage.getItem("selectedVirtualSpace")]);

  const token = localStorage.getItem("accessToken");
  const adminData = localStorage.getItem("adminArchieve");
  const contact_role = localStorage.getItem("contact_role");

  if (!token && !adminData) {
    navigate("/login");
  }

  /* ---------------- login user not allowd to access below pages ---------------- */

// const ONBOARDING_PAGES = ["/hire-new-agent","/onboarding-proposal","/onboarding-employees","/view-onboarding-proposal"];
// if(ONBOARDING_PAGES.includes(location.pathname)){
//   if (!token && !adminData) {
//     navigate("/login");
//   }else{
//     navigate("/");
//   }
// }

  const [ProductTour, setProductTour] = useState(false);
  const [globalSearchCloseIcon, setglobalSearchCloseIcon] = useState(false);
  const GlobalModalSearchShow = () => {
    jquery(".after-login-header").addClass("comman-backdrop-search");
    jquery(".c-top-calendar").addClass("comman-backdrop-search");
    jquery(".create-task-box-main").addClass("comman-backdrop-search");
    jquery(".fixed-left-panel ").addClass("comman-backdrop-search");
    jquery("footer").addClass("comman-backdrop-search");
    jquery(".custom-class").addClass("comman-search-on-focus");
    jquery("#modal-overlay-toggle").removeClass("modal-overlay");
    setShowGlobalSerachModal(false);
  };
  const GlobalModalSearchHide = () => {
    // handleCreateTask();
    if(createTaskModalIsopen == false){
    jquery(".after-login-header").removeClass("comman-backdrop-search");
    jquery(".c-top-calendar").removeClass("comman-backdrop-search");
    jquery(".create-task-box-main").removeClass("comman-backdrop-search");
    jquery(".fixed-left-panel ").removeClass("comman-backdrop-search");
    jquery("footer").removeClass("comman-backdrop-search");
    jquery("body").removeClass("searchbackdrop");
    jquery("#modal-overlay-toggle").addClass("modal-overlay");
    }
  };
  useEffect(() => {
    if (searchString == "" && globalSearchString == "") { 
      GlobalModalSearchHide()
    }
  },[searchString ,globalSearchString])
  const [attechmentViewerHandle, setAttechmentViewerHandle] = useState(false);
  const NoHeaderIconPage = [
    "estimate",
    "staff",
    "client",
    "fullcalendar",
    "AdminTaskPage",
    "subscription",
    "credit_notes",
    "_equipments",
    "invoice",
    "credit_notes",
    "subscription",
    "schedule",
    "benchmark",
    "MyProfile",
    "PremiumSubscription",    
    "SpaceOnBoardingNew"
  ];
  const globalSearchPageArray = [
    "Dashboard",
    "documents",
    "leadPage",
    "MyEmail",
    "Wallet",
    "Proposals",
    "benchmarkList",
    "estimate",
    "staff",
    "client",
    "MyPeople",
    "calendar",
    "fullcalendar",
    "AdminTaskPage",
    "subscription",
    "credit_notes",
    "equipmentListReporting",
    "_equipments",
    "invoice",
    "schedule",
    "benchmark",
    "operatorListReporting",
    "MySpace",
    "LiteVersion",
    "ChatAi",
    "dashboardnew",
    "ProviderList"
  ];
  const pageNames = {
    CompanyProfile: _l("l_company_profile"),
    Wallet: _l("l_wallet"),
    PremiumSubscription: _l("l_premium_subscription"),
    MyProfile: _l("l_my_profile"),
  };

  const logout = () => {
    var chatUserId = localStorage.getItem("chatUserId");
    if (chatUserId != "" && chatUserId != undefined) {
      dispatch(userOffline({ userId: chatUserId }));
    }
    dispatch(userLogout());
    dispatch(setAllChatUserList(false));
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    dispatch({
      type: USER_LOGOUT
    });
    navigate("/login");
  };

  const handleFecthOperatorByCategory = () =>{
    if(groupDetails && groupDetails.extraData && groupDetails.extraData.mainKey && groupDetails.extraData.mainKey == "providers"){
      benchmarkServices.getOperatorsByCategory(projectId, groupDetails.extraData.filterKey ,0).then(
        (res) => {
          if (res.status && res.data) {
            setProviderOpearator(res.data)   
          }
        }
      );
    }
  }
  // useEffect(() => {
    jquery(document).mouseup(function (e) {
      var container = jquery(".global-search-main, .dropdown-menu");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        GlobalModalSearchHide();
        setglobalSearchCloseIcon(false);
      }
    });
  // }, []);
  useEffect(() => {
    if (["leadPage","dashboardnew"].includes(pageName)) {
      setShowSupportNavigator(false);
      setShowDocumentsNavigator(false);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state") != null ? urlParams.get("state") : "";
    if (state.includes("outlook_mail")) {
      customerServices
        .exchangeGoogleCode(code, "outlook_mail")
        .then((response) => {
          if (response.status) {
            window.opener.dispatchEvent(
              new CustomEvent("outlook_mail", { detail: response })
            );
            window.close();
          }
        });
    }

    window.addEventListener("outlook_mail", (event) => {
      let data = event.detail;
      setSynchronizedMail("outlook_mail");
      setShowMailModal(false);
      dispatch(getTopicTasks(selectedOffice.id, "MyEmail"));
      dispatch(showMessage("success", _l("l_success"), data.message));
    });
  }, []);

  useEffect(() => {
    // setLeftActivePanelFilters([]);
    const spaces = JSON.parse(localStorage.getItem("spaces"));
    if (spaces && spaces.length) {
      const project = spaces.filter((s) => s.project_id == projectId);
      if (project.length && !dashboardBenchmarkId) {
        setSelectedOffice(project[0]);
      } else {
        setSelectedOffice({})
        // setSelectedOffice(spaces[0]);
      }
    }
    if (window.location.pathname === "/calendar") {
      setCalendarPageActiveClass(true);
    }
    if (window.location.pathname === "/documents") {
      setDocumentPageActiveClass(true);
    }
    if (window.location.pathname === "/chat") {
      setChatPageActiveClass(true);
    }
    const element = ref.current;
    if (element != null) {
      element.addEventListener("shown.bs.dropdown", handleDropDownOpen);
      element.addEventListener("hide.bs.dropdown", handleDropDownClose);
    } else {
    }
    return () => {
      if (element != null) {
        element.addEventListener("shown.bs.dropdown", handleDropDownOpen);
        element.addEventListener("hide.bs.dropdown", handleDropDownClose);
      }
    };
  }, [projectId]);

  useEffect(() => {
    if (
      googlecalendar_connectivity &&
      googlecalendar_connectivity.data &&
      googlecalendar_connectivity.data.calendar_type != 0
    ) {
      setLeftActivePanelFilters([]);
    }
    if (taskFilters && pageName == "dashboard") {
      setLeftActivePanelFilters(taskFilters);
    }
  }, [googlecalendar_connectivity]);
  useEffect(() => {
    if (outlook_calendar != undefined && parseInt (outlook_calendar.status) != 0) {
      setLeftActivePanelFilters([]);
    }
  }, [outlook_calendar]);
  React.useEffect(() => {
    setCurrentUrl(pathname);
  }, [pathname]);

  useEffect(() => {
    if (currentUrl && currentUrl.includes("benchmarklisting")) {
      setShowFilterArea(false);
    }
  }, [currentUrl]);

  useEffect(() => {
    if(globalView == "liteVersionView"){
      setisLiteVersion(true);      
    }else{
      setisLiteVersion(false);
    }
  }, [globalView])

  if (!jquery.isEmptyObject(newMessage)) {
    localStorage.setItem("newMessageId", newMessage && newMessage._id)
    dispatch(handleChatScrolltoStop(true));
    let activeGroupId = localStorage.getItem("activeGroupId");
    let tempProjectId = localStorage.getItem('isLiteVersion') == "true" ? 0 : projectId
    // if (pageName == "Chat" && (pageName == "ChatAi" || globalView == "AichatView")) {
      if (newMessage.projectId == 0) {
        dispatch(lastMessageContactDir(newMessage.projectId, newMessage, "chat")) 
      } else {
        dispatch(lastMessageSpace(newMessage.projectId, newMessage, "chat")) 
      }

      if (parseInt(newMessage.projectId) == 0 && newMessage.group != activeGroupId) { 
        // dispatch(setGeneralUnreadCount(parseInt(generalUnreadCount) + 1));
      }else{
        if (newMessage.group != activeGroupId || globalView  != "AichatView" || newMessage.projectId != tempProjectId) {
          dispatch(playMsgAudio());
          dispatch(setSpaceNotificationCount(newMessage.projectId, 1, 1, "chat"));
          dispatch(setVirtualSpaceNotificationCount(newMessage.projectId, 1, 1, "chat"));
          dispatch(chatUnreadMessageCount(newMessage.group, 'add', "chatMSG")) /// burger menu 
        } 
      }
      if (newMessage.projectId == projectId) {
        dispatch(updateUnreadMsgCount(1));
        if (newMessage.groupType == 4) {
          dispatch(updateGeneralMsgCount(1));
        }
      }
    // }
    if ( localStorage.getItem("activeGroupId") != newMessage.group || newMessage.projectId != tempProjectId ) {
      // dispatch(playMsgAudio());
      // if (parseInt(newMessage.projectId) == 0) { 
      //   dispatch(setGeneralUnreadCount(parseInt(generalUnreadCount) + 1));
      // }else{
      //   // dispatch(setSpaceNotificationCount(newMessage.projectId, 1, 1, "chat"));
      //   dispatch(
      //     setVirtualSpaceNotificationCount(newMessage.projectId, 1, 1, "chat")
      //   );
      //   dispatch(chatUnreadMessageCount(newMessage))
      // }
    }else if (globalView == "AichatView" &&  localStorage.getItem("activeGroupId") == newMessage.group) {
      // jquery(".ai-chat-header").removeClass("mt-4")
      var msgListDiv = document.getElementById("scrollableDiv");
      if(msgListDiv){
        msgListDiv.scrollTop = msgListDiv.scrollHeight;
      }
    } 
    // setTimeout(() => {
      dispatch(updateNewMessage());
      dispatch(handleChatScrolltoStop(false));

      
    // }, 1000);
  }

  // if (!jquery.isEmptyObject(newMsgForAM)) {
  //   dispatch(updateNewMsgForAM());
  //   if (userType == "staff" && isAdmin && pageName != "ChatAi") {
  //     dispatch(setSpaceNotificationCount(newMsgForAM.projectId, 1, 1, "chat"));
  //     if (newMessage && newMessage.projectId == projectId) {
  //       dispatch(updateUnreadMsgCount(1));
  //     }
  //     if (pageName != "Chat" && pageName != "ChatAi") {
  //       dispatch(playMsgAudio());
  //       dispatch(setOpenGeneralChatGroup(false));
  //     }
  //   }
  // }

  if (!jquery.isEmptyObject(newTaskNoti)) {
    dispatch(playMsgAudio());
    if (newTaskNoti.taskId) {
      var taskUnreadCmtCntEl = jquery(
        "span[data-unread-cmt-id='" + newTaskNoti.taskId + "']"
      );
      var taskCmtCntEl = jquery(
        "span[data-cmt-cnt-id='" + newTaskNoti.taskId + "']"
      );
      taskCmtCntEl.text(parseInt(taskCmtCntEl.text()) + 1);
      taskUnreadCmtCntEl.text(parseInt(taskUnreadCmtCntEl.text()) + 1);
      taskUnreadCmtCntEl.removeClass("d-none");
    }
    if (newTaskNoti.projectId) {
      // var projUnreadCntEl = jquery("span[data-proj-unread-cnt-id='" + newTaskNoti.projectId + "']");
      // projUnreadCntEl.text(parseInt(projUnreadCntEl.text()) + 1);
      // projUnreadCntEl.removeClass('d-none');

      // var projDiv = jquery("li[data-space-div='" + newTaskNoti.projectId + "']");
      // projDiv.remove();
      // jquery("#left-office-list").prepend(projDiv);

      // var spaces = JSON.parse(localStorage.getItem("spaces"));
      // spaces.forEach(function (space, i) {
      //   if (space.project_id == newTaskNoti.projectId) {
      //     spaces[i]['notification_count'] = parseInt(space.notification_count) + 1
      //   }
      // });
      // localStorage.setItem("spaces", JSON.stringify(spaces));

      dispatch(setSpaceNotificationCount(newTaskNoti.projectId, 1, 1, "task"));
      dispatch(
        setVirtualSpaceNotificationCount(newTaskNoti.projectId, 1, 1, "task")
      );

      if (newTaskNoti.projectId == projectId && newTaskNoti.main_key != "") {
        dispatch(chatUnreadMessageCount(newTaskNoti, 'add', 'task'))
        dispatch(unreadTaskComment(newTaskNoti.main_key))
        // var topicUnreadCntEl = jquery(
        //   "div[data-main-key-div='" +
        //     newTaskNoti.main_key +
        //     "'] div[data-filter-key-cnt='" +
        //     newTaskNoti.filter_key +
        //     "']"
        // );
        // var spanEl = jquery(
        //   "div[data-main-key-div='" +
        //     newTaskNoti.main_key +
        //     "'] div[data-filter-key-cnt='" +
        //     newTaskNoti.filter_key +
        //     "'] span"
        // );
        // topicUnreadCntEl.removeClass("me-2 d-none");
        // topicUnreadCntEl.addClass("with-bg h18w18");
        // spanEl.text(parseInt(spanEl.text()) + 1);
        // if (newTaskNoti.title != "") {
        //   dispatch(
        //     updateProjectTopics(
        //       {
        //         filter_key: newTaskNoti.filter_key,
        //         title: newTaskNoti.title,
        //         custom_keys: [],
        //         total_noti_count: 1,
        //       },
        //       newTaskNoti.main_key
        //     )
        //   );
        // }
      }
      // if (newTaskNoti.projectId == projectId) {
      //   dispatch(setTaskUnreadCmtCntAll(parseInt(taskUnreadCmtCntAll) + 1));
      // }
    }
    dispatch(updateNewTaskNoti());
  }

  var iKey = 0;
  const onPagechangeToggle = () => {
    showLeftPanelNew();
  };
  useEffect(() => {
    setPreEstiPageDisable(false);
    if (isPremiumEstimateEdit == true) {
      setPreEstiPageDisable(true);
    }
  }, [JSON.stringify(isPremiumEstimateEdit)]);
  useEffect(() => {
    setLoginUserImage(localStorage.getItem("contact_image"));
  }, [localStorage.getItem("contact_image")]);

  // useEffect(() => {
  //   if(commanoffcanvasIsOpen == true)
  //   {
  //     let newArr = []
  //     if(leftPanelActiveFilters.length > 0 && activeTaskStatusFilters.length > 0)
  //     {
  //       newArr = leftPanelActiveFilters.concat(activeTaskStatusFilters)
  //     } else if (leftPanelActiveFilters.length > 0 && activeTaskStatusFilters.length == 0 ) {
  //       newArr = leftPanelActiveFilters
  //     }else if (leftPanelActiveFilters.length == 0 && activeTaskStatusFilters.length > 0 ) {
  //       newArr = leftPanelActiveFilters
  //     }
  //     setLeftActivePanelFilters(newArr)
  //   }

  // }, [commanoffcanvasIsOpen,])
  useEffect(() => {
    if (taskFilters || activeTaskStatusFilters) {
      setLeftActivePanelFilters(taskFilters.concat(activeTaskStatusFilters, pinDelayfilter));
    }
  }, [JSON.stringify(taskFilters), JSON.stringify(activeTaskStatusFilters), pinDelayfilter]);

  useEffect(() => {
    if(groupDetails && groupDetails._id && groupDetails.displayPicture != ""){
      setTopicLogo({url : ATTACH_BASE_URL.concat(groupDetails.displayPicture)})
    }else{
      setTopicLogo("")
    }
    handleFecthOperatorByCategory()
  }, [groupDetails])

  // useEffect(() => {
  //   if(lastChatSession.length == 0 && (taskFilters.length == 0 || taskFilters.filter((key) => key.filter_key != "date_range_filter").length == 0) && pageName != "ChatAi"){
  //     if((userType == "contact" || userType ==  "operator") && contact_role == 3){
  //       dispatch(
  //         leftPanelFilters({
  //           main_key: userType == "contact" ? "my_contact" : "my_operator",
  //           filter_key: localStorage.getItem("staff_id"),
  //           title: "l_to_me",
  //           image: "",
  //           main_sub_key: "people",
  //           sub_category: [],
  //         }));
  //     }else{ 
  //       let tempTopics = []
  //       let filter = []
  //       if (defaultTopics && lastChatSession && lastChatSession.length > 0) { 
  //         if (lastChatSession[0].main_key == "myr_support") {
  //           tempTopics = defaultTopics.filter((topic) => topic.main_key == "providers")              
  //         } else {
  //           tempTopics = defaultTopics.filter((topic) => topic.main_key == lastChatSession[0].main_key)
  //         }
  //       }
  //       if (tempTopics.length) {
  //         if (lastChatSession[0].filter_key ==  localStorage.getItem("staff_id")) {
  //           filter = tempTopics[0].categories.filter((category) => category.filter_key == "myrai_support")              
  //         } else {
  //           filter = tempTopics[0].categories.filter((category) => category.filter_key == lastChatSession[0].filter_key)
  //         }
  //       }
  //       if (filter.length > 0) {
  //         filter.map((category) => {
  //           dispatch(
  //             leftPanelFilters({
  //               main_key: category.filter_key == "myrai_support" ? "providers" : userType == "satff" ? tempTopics[0].main_key : tempTopics[0].main_key == " myr_support" ? "my_contact" : tempTopics[0].main_key ,
  //               filter_key: category.filter_key,
  //               title: category.title,
  //               image: category.image,
  //               main_sub_key: category.main_sub_key,
  //               sub_category: category.sub_category ? category.sub_category : [],
  //             })
  //           );
  //           localStorage.setItem("myrUserId",category.contact_id)
  //           localStorage.setItem("selectedTopic",JSON.stringify({id:category.filter_key, title: category.title, main_key:tempTopics[0].main_key, is_active: category.is_active, main_key_title: tempTopics[0].title,main_sub_key: category.main_sub_key}))
  //         })
        
  //       }else if(pageName != "dashboardnew"){
  //         dispatch(
  //           leftPanelFilters({
  //             main_key: "my_task",
  //             filter_key: "assigned_to_me",
  //             title: "l_to_me",
  //             image: "",
  //             main_sub_key: "my_task",
  //             sub_category: [],
  //           }))
  //       }
  //     }
  //   }else if( taskFilters.length > 0 && taskFilters.some((filter) => filter.filter_key == "date_range_filter")){
  //     let tempfilter = taskFilters.filter((filter) => filter.filter_key != "date_range_filter")
  //     if(tempfilter.length > 0){        
  //     // tempfilter.map((filter) =>{
  //     //   dispatch(
  //     //     leftPanelFilters({
  //     //       main_key: filter.main_key,
  //     //       filter_key: filter.filter_key,
  //     //       title: filter.title,
  //     //       image: "",
  //     //       main_sub_key: filter.main_sub_key,
  //     //       sub_category: filter.sub_category ? filter.sub_category : [] ,
  //     //     })
  //     //   );
  //     // })
  //   }
  //   }
    
  // }, [JSON.stringify(taskFilters), JSON.stringify(lastChatSession), lastChatSession.length , JSON.stringify(defaultTopics)])
//   useEffect(() => {      
//     if ((taskFilters.length == 0 || taskFilters.filter((fil)=> fil.filter_key != "unread_comments").length == 0 )&& projectId > 0 && (spaceState.length > 0 || virtualSpaceList.length > 0 )&& virtualSpaceClientId != "undefined") {
//       if (((userType == "contact" || userType == "operator") && contact_role == 3) || userType == "staff") {
//         applyDefaultfilter()
//       }else if(defaultTopics && defaultTopics.length > 0) {
//         let cleaningArr = defaultTopics && defaultTopics.filter((topic) => topic.main_key == "providers")
//         if (cleaningArr && cleaningArr.length > 0) {
//          let isCleaning = cleaningArr[0].categories && cleaningArr[0].categories.filter((category) => category.filter_key == 21  && category.is_active == 1)
//          if (isCleaning && isCleaning.length > 0) {
//           dispatch(leftPanelFilters("","","applyCleaningFilter"))
//           handleCreateChatGroup(projectId,"providers", "providers", 21)      
//          } else {
//           applyDefaultfilter()
//          }
//         }else{
//           applyDefaultfilter()
//         }
//       }
//     }
// }, [JSON.stringify(taskFilters), JSON.stringify(lastChatSession), JSON.stringify(defaultTopics),JSON.stringify(spaceState),projectId])
useEffect(() => {      
  if ((taskFilters.length == 0 || taskFilters.filter((fil)=> fil.filter_key != "unread_comments").length == 0 )&& projectId > 0 && (spaceState.length > 0 || virtualSpaceList.length > 0 )&& (virtualSpaceClientId != "undefined" || virtualSpaceClientId == '')) {
    applyDefaultfilter()
  }else if(((spaceState && spaceState.length == 0) && (virtualSpaceList && virtualSpaceList.length == 0) && (is_registered == 1) && (taskFilters.length == 0 || taskFilters.filter((fil)=> fil.filter_key != "unread_comments").length == 0 && isContactDirChat === false))){
    dispatch(leftPanelFilters({
      main_key: "providers",
      filter_key: "myrai_support",
      title: "Myr.ai Support",
      image: "",
      main_sub_key: "providers",
      sub_category: [],
    }))
  }
}, [JSON.stringify(taskFilters), JSON.stringify(lastChatSession), JSON.stringify(defaultTopics),projectId])

  useEffect(() => {
    if(taskFilters.length > 0 && taskFilters.some((filter) => filter.main_key == "providers") && dashBoradFilter.length > 0 && projectId > 0){
      let startdate = localStorage.getItem("selectedDate")
      let provider_id = taskFilters.filter((filter) => filter.main_key == "providers")[0].filter_key

      customerServices.getMetricsForProvider(projectId,provider_id,startdate,dashBoradFilter.filter((fi) => fi.is_active == 1)).then((res)=>{
        if(res.status && res.data){
          dispatch(setMetricsDetails(res.data))
        }
      })

    }
  }, [taskFilters, lastChatSession, JSON.stringify(dashBoradFilter), JSON.stringify(filterArr)])
  
useEffect(() => {
if(taskFilters.length == 1 && dateRangeFilters.length == 1 && !["operatorListReporting","equipmentListReporting"].includes(pageName)){
  if ((userType == "contact" || userType == "operator") && contact_role == 3) {
    applyDefaultfilter()
  }else{
    let cleaningArr = defaultTopics && defaultTopics.filter((topic) => topic.main_key == "providers")
    if (cleaningArr.length > 0) {
     let isCleaning = cleaningArr[0].categories && cleaningArr[0].categories.filter((category) => category.filter_key == 21  && category.is_active == 1)
     if (isCleaning && isCleaning.length > 0 && pageName != "dashboardnew" && projectId && projectId > 0) {
      dispatch(leftPanelFilters("","","applyCleaningFilter"))
      handleCreateChatGroup(projectId,"providers", "providers", 21)      
     } else {
      applyDefaultfilter()
     }
    }else{
      applyDefaultfilter()
    }

  }
}
   
  if (pageName !== "leadPage" ) {
    setselectedleadfilers([]);
  }
}, [pageName])

useEffect(() => {
  if((spaceState && spaceState.length == 0) && (virtualSpaceList && virtualSpaceList.length == 0) && (taskFilters.length > 0 && taskFilters.filter((w)=> w.main_key != "contact_directory").length > 0 )&& ( is_registered !== 1) && pageName !== "MyEmail" && !localStorage.getItem("activeGroupId")) {
    if (localStorage.getItem("activeGroupId")) {
      
    } else {
      handleCreateChatGroup(0,"myr_support",userType, localStorage.getItem("staff_id"));
    }
  }
  
}, [JSON.stringify(taskFilters)])

useEffect(() => {
  if ((spaceState && spaceState.length == 0) && (virtualSpaceList && virtualSpaceList.length == 0) && (userType != "staff") ) {
    localStorage.setItem("is_registered",1)
  }else{
    localStorage.setItem("is_registered",0)
  }
  
}, [spaceState,virtualSpaceList])

  
  // const makeCall = (myrUserId, email, myrUserType) => {
  //   dispatch(
  //     initCall({
  //       userId: localStorage.getItem("orgChatUserId"),
  //       myrUserId: myrUserId,
  //       email: email,
  //       myrUserType: myrUserType == "contact" ? "client" : "staff",
  //       extraData: {  },
  //     })
  //   );
  // };
  
  const applyDefaultfilter = () =>{
    if ((userType == "contact" || userType == "operator") && contact_role == 3) {
      hideLeftPanelNew();
      dispatch(
        leftPanelFilters({
          main_key: userType == "contact" ? "my_contact" : "my_operator",
          filter_key: localStorage.getItem("staff_id"),
          title: "l_to_me",
          image: "",
          main_sub_key: "people",
          sub_category: undefined,
        })
      );
      handleCreateChatGroup(projectId,userType == "contact" ? "my_contact" : "my_operator", "people", localStorage.getItem("staff_id"))
      localStorage.setItem("selectedTopic",JSON.stringify({
        id:userType == "contact" ? "my_contact" : "my_operator", 
        main_key:userType == "contact" ? "my_contact" : "my_operator", 
        is_active: 1, 
        main_key_title: "",
        main_sub_key:  "people",
        contact_role : "", 
        total_notification_count: {}, 
        thread_id : "",
        filter_key: localStorage.getItem("staff_id"),
      }))
    } else if(pageName != "dashboardnew"){
      if(!["SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "ConnectProvider", "MyEmail"].includes(pageName))
      {
        showLeftPanelNew();
      }
      let tempTopics = []
      let filter = []
      if (defaultTopics && lastChatSession && lastChatSession.length > 0) { 
        if (lastChatSession[0].main_key == "myr_support" && userType != "staff") {
          tempTopics = defaultTopics.filter((topic) => topic.main_key == "providers")              
        } else {
          tempTopics = defaultTopics.filter((topic) => topic.main_key == lastChatSession[0].main_key)
        }
      }
      if (tempTopics.length > 0) {
        if (lastChatSession[0].filter_key ==  localStorage.getItem("staff_id") && userType != "staff") {
          filter = tempTopics[0].categories.filter((category) => category.filter_key == "myrai_support")              
        } else {
          filter = tempTopics[0].categories.filter((category) => category.filter_key == lastChatSession[0].filter_key && category.is_active == 1)
        }
      }
      if (filter.length > 0 && !["operatorListReporting", "operatorListReporting", "equipmentListReporting"].includes(pageName)) {
        filter.map((category) => {
          let mainKey = category.filter_key == "myrai_support" ? "myr_support" : userType == "satff" ? tempTopics[0].main_key : tempTopics[0].main_key == " myr_support" ? "my_contact" : tempTopics[0].main_key
          let filterKey = category.filter_key == "myrai_support" ? localStorage.getItem("staff_id") : category.filter_key
          let mainSubKey =  category.filter_key == "myrai_support" ? userType : category.main_sub_key
          dispatch(
            leftPanelFilters({
              main_key: category.filter_key == "myrai_support" ? "providers" : userType == "satff" ? tempTopics[0].main_key : tempTopics[0].main_key == " myr_support" ? "my_contact" : tempTopics[0].main_key ,
              filter_key: category.filter_key,
              title: category.title,
              image: category.image,
              main_sub_key: category.main_sub_key,
              sub_category: category && category.sub_category  ? category.sub_category : [],
            })
          );
          localStorage.setItem("myrUserId",category.contact_id)
          localStorage.setItem("selectedTopic",JSON.stringify({id:category.filter_key, title: category.title, main_key:tempTopics[0].main_key, is_active: category.is_active, main_key_title: tempTopics[0].title,main_sub_key: category.main_sub_key, contact_role : category.contact_role, total_notification_count: category.total_notification_count, thread_id : category.thread_id,filter_key: category.filter_key ,image: category.image}))
          localStorage.setItem("callerInfo",JSON.stringify({id:category.filter_key, title: category.title, main_key:tempTopics[0].main_key, is_active: category.is_active, main_key_title: tempTopics[0].title,main_sub_key: category.main_sub_key, contact_role : category.contact_role, total_notification_count: category.total_notification_count, thread_id : category.thread_id,filter_key: category.filter_key ,image: category.image}))
          setselectedTopic({
            id:category.filter_key, 
            title: category.title, 
            main_key:tempTopics[0].main_key, 
            is_active: category.is_active, 
            main_key_title: tempTopics[0].title,main_sub_key: 
            category.main_sub_key, 
            contact_role : category.contact_role , 
            total_notification_count : category.total_notification_count
          })
          dispatch(unreadTaskComment(category.filter_key == "myrai_support" ? "providers" : userType == "satff" ? tempTopics[0].main_key : tempTopics[0].main_key == " myr_support" ? "my_contact" : tempTopics[0].main_key))
          settotalNotificationCount(category.total_notification_count && category.total_notification_count.service_count ?  category.total_notification_count.service_count : 0 )
          setmissedcallCounts(category.total_notification_count && category.total_notification_count.missed_call_count ? category.total_notification_count.missed_call_count : 0)
          handleCreateChatGroup(projectId,mainKey, mainSubKey, filterKey)
        })
      }else if(!["operatorListReporting", "operatorListReporting", "equipmentListReporting"].includes(pageName) && lastChatSession.length > 0 && defaultTopics && lastChatSession && lastChatSession[0].filter_key && (lastChatSession[0].filter_key == undefined || lastChatSession[0].filter_key == '')){
        dispatch(leftPanelFilters("","","applyCleaningFilter"))
        setTimeout(() => {
          dispatch(unreadTaskComment("my_task"))
        }, 800);
      }
    }
    
  }
  
  const makeCall = (receiverId = "" , full_name = "", groupId = "") => {
    dispatch(setCallDisconnect(false));
    dispatch(setCallRecording(false));
    dispatch(initCall({
      userId: localStorage.getItem("orgChatUserId"),
      receiverId: receiverId,
      extraData: {
        full_name: full_name,
        groupId: groupId
      }
    }));
  };

  const getMentionUsersForFilter = async() => {
    if(userType === "staff" && !mentionStaffArray.length)
    {
      try {
        const virtualResponse = await adminServices.getVirtualSpaceList();
        const response = await adminServices.getAllProjectMembers();
        let members = [];
        if(response.status && response.data)
        {
          response.data.forEach((project) => {
            project.members.forEach((member) => {
              let memberIndex = members.findIndex((w) => w.label && member.full_name && member.full_name.trim() == w.label.trim() && member.staffid == w.value);
              if(memberIndex === -1)
              {
                let showCrown = false;
                if(member.userType === "operator" && member.role == 2 && member.contact_role == 1)
                {
                  showCrown = true;
                }
                else if(member.userType === "contact" && member.contact_role == 1)
                {
                  showCrown = true;
                }
                members.push({label: member.full_name, value: member.staffid, profile_image_url: member.profile_image_url, pillContent: `#${member.userType}`, projects: [{project_id : project.project_id.toString(),title:project.office_title,space_name:project.postal_address}], showCrown,
                userType:member.userType,
                staffid:member.staffid,
                contactid:member.contactid,
                email:member.email,
                full_name: member.full_name
              });
              }
              else {
                members[memberIndex] = {...members[memberIndex], projects: members[memberIndex].projects.concat([{project_id : project.project_id.toString(),title:project.office_title,space_name:project.postal_address}])};
              }
            });
          });
        }
        let virtualMembers= [];
        if (virtualResponse.status && virtualResponse.data) {
          virtualResponse.data.forEach((projectV) => {
            projectV.members.forEach((member) => {
              let memberIndexV = virtualMembers.findIndex((w) => w.full_name && member.full_name && member.full_name.trim() == w.full_name.trim() && member.staffid == w.staffid);
              if (memberIndexV === -1) {
                let showCrown = false;
                virtualMembers.push({
                  label: member.full_name, 
                  value: member.staffid, 
                  profile_image_url: member.profile_image_url, 
                  pillContent: `#staff`, 
                  projects: [{ project_id: projectV.id.toString(), title: projectV.name, space_name: projectV.name }], 
                  showCrown,
                  userType: "staff",
                  staffid: member.staffid,
                  contactid: member.contactid || "",
                  email: member.email || "",
                  full_name: member.full_name
                });
              } else {
                virtualMembers[memberIndexV] = { ...virtualMembers[memberIndexV], projects: virtualMembers[memberIndexV].projects.concat([{ project_id: projectV.id.toString(), title: projectV.name, space_name: projectV.name }]) };
              }
            });
          });
        }
        let tempMember = members.concat(virtualMembers)
        tempMember.forEach((member) => {member.label = `@${member.label}`});
        tempMember.sort((a, b) => a.label.localeCompare(b.label));
        setMentionStaffArray(tempMember);
      }
      catch(e)
      {
        console.error(e);
        showError("l_something_went_wrong");
      }
    }
  }

  const applyStaffUserFilter = (member, isRemove) => {

    let projectIdArray = [];

    if(isRemove)
    {
      searchMentionUsers = selectedStaffMembersForSpaceFilter.filter((w) => w.value != member.value);
      if(searchMentionUsers.length)
      {
        projectIdArray = searchMentionUsers.reduce((commonProjects, obj) => 
        {
          const projects = obj.projects;
          return commonProjects.filter(project => projects.includes(project));
        }, searchMentionUsers[0].projects);
      }
      let updatedMemberList = mentionStaffArray.concat(member);
      updatedMemberList.sort((a, b) => a.label.localeCompare(b.label));
      setMentionStaffArray(updatedMemberList);
      setSelectedStaffMembersForSpaceFilter(searchMentionUsers);
    }
    else {
      searchMentionUsers = selectedStaffMembersForSpaceFilter.concat(member);
      if(searchMentionUsers.length == 1)
      {
        projectIdArray = searchMentionUsers.map((w) => w.projects);
        projectIdArray = projectIdArray[0];
      }
      else {
        projectIdArray = searchMentionUsers.reduce((commonProjects, obj) => 
        {
          const projects = obj.projects;
          return commonProjects.filter(project => projects.includes(project));
        }, searchMentionUsers[0].projects);
      }
      
      setSearchString("");
      setSelectedStaffMembersForSpaceFilter(searchMentionUsers);
      setMentionStaffArray(mentionStaffArray.filter((w) => w.value != member.value));
    }
  }
  const handleTaskBulkAction = (is_delete = 0, status, actionType, files) => {
    if (getCheckedTask && getCheckedTask.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_select_task"))
      );
      return false
    }else if(actionType == "set_assignee" || actionType == "comment") {
      customerServices.taskBulkAction(getCheckedTask, actionType, status, files).then((res) => {
        if (res.status) {
          dispatch(setAllCheckedTask([]));
          // setBulkActionAllTask({
          //   todo: false,
          //   inProgress: false,
          //   done: false,
          //   request: false
          // })
          dispatch(
            projectTaskCounts(
              projectId,
              taskFilters
                ? taskFilters
                : [],
              "",
            )
          );
          dispatch(
            getTaskCount(
              0,
              0,
              localStorage.getItem("selectedOffice"),
              moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
              taskFilters
                ? taskFilters
                : [],
              "",
              activeTaskStatusFilters ? activeTaskStatusFilters : []
            )
          );
        }
        dispatch(showMessage(res.status ? "sucess" : "unsucess", res.status ? _l("l_success") : _l("l_error"), res.message ? res.message : ''))
      })
    }else if(actionType == "set_due_date"){
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          _l("l_you_want_to_change_due_date_of_selected_tasks"),
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => {
            customerServices.taskBulkAction(getCheckedTask, actionType, status).then((res) => {
              if (res.status) {
                dispatch(
                  projectTaskCounts(
                    projectId,
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                  )
                );
                dispatch(
                  getTaskCount(
                    0,
                    0,
                    localStorage.getItem("selectedOffice"),
                    moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                    activeTaskStatusFilters ? activeTaskStatusFilters : []
                  )
                );
                dispatch(setAllCheckedTask([]));
                // setBulkActionAllTask({
                //   todo: false,
                //   inProgress: false,
                //   done: false,
                //   request: false
                // })
                dispatch(taskBulkAction(getCheckedTask, is_delete ? 'delete_task' : status == 'task_convert_to_request' ? 'task_convert_to_request' : 'status_change', is_delete ? '' : status == 'task_convert_to_request' ? 'task_convert_to_request' : status))
              }

              dispatch(
                showMessage(res.status ? "sucess" : "unsucess", res.status ? _l("l_success") : _l("l_error"), res.message ? res.message : '')
              );

            })

          }
        )
      );
    } else {
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          `${is_delete ? _l("l_you_want_tot_delete_this_task") : _l("l_you_want_to_change_task_status")}`,
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => {
            customerServices.taskBulkAction(getCheckedTask, is_delete ? 'delete_task' : status == 'task_convert_to_request' ? 'task_convert_to_request' : 'status_change', is_delete ? '' : status == 'task_convert_to_request' ? '' : status).then((res) => {
              if (res.status) {
                dispatch(
                  projectTaskCounts(
                    projectId,
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                  )
                );
                dispatch(
                  getTaskCount(
                    0,
                    0,
                    localStorage.getItem("selectedOffice"),
                    moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                    activeTaskStatusFilters ? activeTaskStatusFilters : []
                  )
                );
                dispatch(setAllCheckedTask([]));
                // setBulkActionAllTask({
                //   todo: false,
                //   inProgress: false,
                //   done: false,
                //   request: false
                // })
                dispatch(taskBulkAction(getCheckedTask, is_delete ? 'delete_task' : status == 'task_convert_to_request' ? 'task_convert_to_request' : 'status_change', is_delete ? '' : status == 'task_convert_to_request' ? 'task_convert_to_request' : status))
              }

              dispatch(
                showMessage(res.status ? "sucess" : "unsucess", res.status ? _l("l_success") : _l("l_error"), res.message ? res.message : '')
              );

            })

          }
        )
      );
    }
  }

  const backNavigationText = () => {
    const space_setup_pending = localStorage.getItem("space_setup_pending");

    if(dashboardBenchmarkId > 0)
    {
      return _l("l_back");
    }
    if(["SpaceOnBoardingNew"].includes(pageName) && !key && JSON.parse(space_setup_pending) == 1)
    {
      return "";
    }
    else if(["ConnectProvider", "SpaceOnBoarding"].includes(pageName) || key == "connect-provider")
    {
      return _l("l_back_to_space");
    }
    else if(["SpaceOnBoardingNew"].includes(pageName)) {
      return _l("l_back");
    }else if(["hireNewAgent"].includes(pageName)){
      return _l("l_back");
    }
    else {
      return _l("l_back_to_space_settings");
    }
  };

  const backButtonHandler = () => {
    //navigate(pageName == "ConnectProvider" || key == "connect-provider" ? `/explore-features?space=${projectId}` : "/")
    if(dashboardBenchmarkId)
    {
      navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${dashboardBenchmarkId}`);
    }
    else if(["ConnectProvider"].includes(pageName) || key == "connect-provider")
    {
      navigate(`/explore-features?space=${projectId}`);
    }
    else if(["SpaceOnBoardingNew"].includes(pageName)) {
      if(Number(projectId))
      {
        navigate(`/explore-features?space=${projectId}`);
      }
      else {
        navigate(`/premiumsubscription`);
      }
    }else if(localStorage.getItem("is_registered") == 1){
      navigate("/aichat");
      dispatch(setSelectedGloablView("AichatView"));

    }
    else {
      let FavView = localStorage.getItem("fav_dashboard")
      FavView == "AichatView" ?
      dispatch(setSelectedGloablView("AichatView")) :
        navigate("/") 
          FavView == "calendar" ?
            dispatch(setSelectedGloablView("calendarView"))
            : FavView == "my_spaces" ?
              dispatch(setSelectedGloablView("spaceView"))
              : FavView == "kanban_view" ?
                dispatch(setSelectedGloablView("kanbanView"))
                : FavView == "my_tasks" ? dispatch(setSelectedGloablView("listView"))
                  : dispatch(setSelectedGloablView("AichatView"))
    }
  }

  // useEffect(() => {
  //   if(pageName != "chatAi"){
  //     localStorage.removeItem("selectedTopic")
  //   }    
  // }, [pageName])  

  useEffect(() => {
  // var chatUserId = localStorage.getItem("chatUserId");
  if (chatUserId && project_id) {
    dispatch(fetchGroups(chatUserId, localStorage.getItem('selectedOffice'),0, 1));
  }
  
  }, [chatUserId, project_id])
  useEffect(() => {
    dispatch(fetchGroupsExternal(chatUserId, 0 , 1));
}, [])

  useEffect(() => {
    setselectedTopic(selected_topic);
    if(selected_topic && selected_topic.total_notification_count){
      setmissedcallCounts(selected_topic.total_notification_count.missed_call_count ? selected_topic.total_notification_count.missed_call_count : 0)
      settotalNotificationCount(selected_topic.total_notification_count.service_count ? selected_topic.total_notification_count.service_count : 0)
    }
    
  }, [JSON.stringify(selected_topic)])
  
  useEffect(() => {
    if (myrStaffList.length) {
      const formattedResponse = myrStaffList.map((staff) => {
        return {
          value: staff.staffid,
          label: staff.fullname,
          role: 1,
          profile_image: staff.profile_image_url,
        };
      });
      setMyrStaff(formattedResponse);
    }
      if (myrLeadSources.length || Object.keys(myrLeadSources).length > 0 ) {
      const formattedResponse =myrLeadSources && myrLeadSources.sources.map((source) => {
        return {
          value: source.id,
          label: source.name,
        };
      });
      const tagesLead =myrLeadSources && myrLeadSources.tags.map((source) => {
        return {
          value: source.id,
          label: source.name,
        };
      });
      setLeadSources(formattedResponse);
      settagesLeads(tagesLead);
    }
  }, [myrLeadSources, myrStaffList])

  const preparePreview = (files) => {
    var chatUserId = localStorage.getItem('chatUserId');
    var chatGroupId = localStorage.getItem('activeGroupId');

    var groupMembers = [];
    groupDetails.members.map((item) => {
      if(item != chatUserId){
        groupMembers.push({ userId: item._id });
      }
    });

    ChatServices.uploadChatMedia(files[0]).then((res) => {
      if (res.status && res.data.filename) {
        ChatServices.editChatGroup(chatUserId, chatGroupId, groupDetails.groupName, groupMembers, res.data.filename ,5).then((res) => {
          if (res.success) {

            // dispatch(fetchGroups(chatUserId, projectId,0, 1));
            dispatch(showMessage("sucess", _l("l_success"),res.message));
          }else{
            dispatch(showMessage("unsucess", _l("l_error"),res.message));
          }
        })
        
      }
    })
    // dispatch(uploadChatMedia(files[0]));
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray = ({ name: img.name, url: preview_url });
    });
    setTopicLogo(previrewArray);
  };
  // useEffect(() => {
  //   if (dateView) {
  //     switch (dateView) {
  //       case "dayView":
  //         dispatch(leftPanelFilters("", "", "all"));
  //         // localStorage.setItem("selectedDate", new Date());
  //         localStorage.setItem("tasklistView", "liteVersionView")
  //         dispatch(setSelectedGloablView("liteVersionView"))
  //         break;
  //       case "monthView":
  //         const todayDate = new Date();
  //         const firstDayOfMonth = moment(todayDate).startOf('month');
  //         const lastDayOfMonth = moment(todayDate).endOf('month');
  //         const monthDatesArray = [];

  //         let currentDate = firstDayOfMonth;
  //         while (currentDate.isSameOrBefore(lastDayOfMonth)) {
  //           monthDatesArray.push({ date: currentDate.format('YYYY-MM-DD') });
  //           currentDate.add(1, 'day');
  //         }
  //         const fromDateMonth = monthDatesArray[0].date;
  //         const toDateMonth = monthDatesArray[monthDatesArray.length - 1].date;
  //         var title = fromDateMonth + " - " + toDateMonth;
  //         localStorage.setItem("fromDate", fromDateMonth)
  //         localStorage.setItem("toDate", toDateMonth)
  //         dispatch(
  //           leftPanelFilters(
  //             {
  //               main_key: "date_range_filter",
  //               filter_key: "date_range_filter",
  //               title: title,
  //             },
  //             "add"
  //           )
  //         );
  //         break;
  //       case "weekView":
  //         var fromDate = moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1))).format("YYYY-MM-DD");
  //         var toDate = moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7))).format("YYYY-MM-DD");
  //         var selectedDate = moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 4))).format("YYYY-MM-DD");
  //         var title = fromDate + " - " + toDate;
  //         localStorage.setItem("selectedDate", selectedDate);
  //         localStorage.setItem("fromDate", fromDate);
  //         localStorage.setItem("toDate", toDate);
  //         dispatch(
  //           leftPanelFilters(
  //             {
  //               main_key: "date_range_filter",
  //               filter_key: "date_range_filter",
  //               title: title,
  //             },
  //             "add"
  //           )
  //         );
  //         break;

  //       default:

  //         break;
  //     }
  //   }
  // }, [dateView])
  return (
    <>
      {[
        "SpaceOnBoarding",
        "SpaceOnBoardingEquipments",
        "ConnectProvider",
        "SpaceOnBoardingNew",
        "Providers",
        "hireNewAgent"
      ].includes(pageName) && !BACK_BUTTON_RESTRICTED.find((w) => window.location.href.includes(w))? (
        <div className="after-login-header onboarding-header d-flex flex-column">
          <div className="row my-auto">
            <div className="col-lg-4">
              <div className="w-100 pe-3 py-2 text-truncate header-back-btn">
                <a href="#/" className="d-flex align-items-center"  onClick={backButtonHandler}>
                  {["ConnectProvider", "Providers","SpaceOnBoarding", "SpaceOnBoardingNew","hireNewAgent"].includes(pageName) ? (
                    <div
                      className="h40w40 d-flex"
                      // onClick={() =>navigate(pageName == "ConnectProvider" || key == "connect-provider" ? `/explore-features?space=${projectId}` : "/")}
                    >
                      <CaretLeft weight="light" className="c-icons" size={16} />
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <div className="w100minus40">
                  <div className="title-fonts text-truncate">{backNavigationText()}</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex h-100 justify-content-center text-truncate align-items-center">
                <div className="title-fonts text-center text-truncate">
                  {/* {_l("l_try_space_management_with_new_feature")}  position-absolute top-50 start-50 translate-middle */} 
                  <div className="fw-semibold title-fonts text-truncate">
                      {["Providers"].includes(pageName) ? (
                        <span id="header-page-title"></span>
                      ) : (
                        <span>
                          {selectedOffice.office_title
                            ? selectedOffice.office_title
                            : selectedOffice.postal_address}
                        </span>
                      )}
                    </div>
                    <div className="c-font f-12 opacity-50 text-truncate">
                      {["Providers"].includes(pageName) ? (
                        <span id="header-page-address"></span>
                      ) : (
                        <span>{selectedOffice.postal_address}</span>
                      )}
                    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex h-100 justify-content-center">
                <div className="d-flex align-items-center ms-auto">
                  {stepData.step == "space-onboarding" ? (
                    <React.Fragment>
                      {stepData.showChat ? (
                        <a href="#/"
                          data-tip={`${_l("l_support")}`}
                          data-for={randomID}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          className={`h-right-part-link on-hover-active-toggle-img res header-support d-none ${
                            ChatPageActiveClass ? "active" : ""
                          } ${
                            preEstiPageDisable == true ? "for-disabled" : ""
                          } ${showSupportNavigator ? "" : "d-none"} ${
                            [
                              "dashboard",
                              "PremiumSubscription"
                            ].includes(pageName)
                              ? "d-none"
                              : ""
                          }`}
                          onClick={() => {
                            dispatch(selectedChatUser(undefined));
                            if (globalView == "liteVersionView" ) {
                              dispatch(setAllChatUserList(false)); 
                              localStorage.setItem("activeGroupId","");
                              localStorage.setItem("generalChatForLiteVersion",true);
                              localStorage.removeItem("selectedOffice")
                              // localStorage.setItem("isLiteVersion", true)
                            }else{
                              // localStorage.setItem("generalChatForLiteVersion",false);
                            }
                            if (preEstiPageDisable == true) {
                              return;
                            }
                            if (localStorage.getItem("selectedOffice") > 0) {
                              localStorage.setItem("isLiteVersion", false)
                            }else{
                              localStorage.setItem("isLiteVersion", true)
                            }
                            navigate("/chat");
                            dispatch(setOpenGeneralChatGroup(false));
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative">
                            <div
                              className={`rounded-pill badge-custom ${displayUnreadMsgCount}`}
                            >
                              {globalView == "liteVersionView" && localStorage.getItem("isLiteVersion") == "true" ? generalUnreadCount : unreadMsgCount}
                            </div>
                            <Chat
                              size={24}
                              weight="light"
                              className="c-icons"
                            />
                            <span className="d-block c-font f-10 hover-span color-white-60 line-height-1 pt-1 res-d-none-imp">
                              {_l("l_support")}
                            </span>
                          </div>
                        </a>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                      <div className={`h-user-part no-after position-relative dropdown dropdown-center d-flex`}>
                        <a href="#/"
                          className={`h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle border-dashed p-5px m-auto ${
                            pageName == "MyProfile" ? "border-width2" : ""
                          } ${
                            userType == "staff"
                              ? "staff_user"
                              : userType == "operator"
                              ? "operator_user" 
                              : "contact_user"
                          }`}
                          type="button"
                          id="headerUserDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-tip={emailAddress}
                          data-for={randomID}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          data-bs-auto-close="outside"
                          data-place="left"
                        >
                          <div
                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                            style={{
                              backgroundImage: `url('${loginUserImage}')`,
                            }}
                          ></div>
                        </a>
                        <HeaderUserDropdown ProductTour pageName={pageName} />
                      </div>
                    </React.Fragment>
                  ) : (
                    <Button
                      variant="secondary"
                      className="ms-auto title-fonts d-flex align-items-center"
                      onClick={logout}
                    >
                      <SignOut size={16} className="c-icons" weight="light" />
                      <div className="ms-2">{_l("l_logout")}</div>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${
            showGlobalSerachModal ? "z-index-15 position-relative" : ""
          }`}
        >
          <a href="#/" className="left-panel-close" id="left-panel-close">
            <X size={18} weight="light" className="c-icons" />
          </a>

          <div
            className={`after-login-header ${globalView == "AichatView" ? "py-0" : ""} ${isBackdrop ? "comman-backdrop-search" : ""} ${
              globalView == "liteVersionView" ? "" : ""
            }`}
          >
            <div className="modal-overlay2 "></div>
            <div className={`header-wrapper position-relative h-100 header-back-btn ${["MyProfile", "PremiumSubscription","Proposals","dashboardnew","ConnectProvider" ,"staff", "SpaceOnBoardingNew" ].includes(pageName) || jquery
            ('body').hasClass("with-right-penel") || contact_role == 3 || localStorage.getItem("space_setup_pending") == 1 || localStorage.getItem("is_registered") == 1 || (spaceState && spaceState.length == 0) && (virtualSpaceList && virtualSpaceList.length == 0)? "" : "pl70px"}`}> 
              <div className="d-flex align-items-center justify-content-between h-100">
                <div
                  className={`left-part d-flex align-items-center  ${
                    globalView == "liteVersionView" ? "left-part" : "left-part"
                  }`}
                >
                  {showMailModal ? (
                    <MailSetting
                      show={showMailModal}
                      handleClose={mailModalHandleClose}
                      handleShow={mailModalHandleShow}
                      setSynchronizedMail={setSynchronizedMail}
                    />
                  ) : (
                    <></>
                  )}
                  {/* {hideDriveSyncBtn || isLeadPage ? (
                  <></>
                ) : (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setShowDrviesModal(true);
                    }}
                  >
                    {_l("l_symc_your_files")}
                  </Button>
                )}
                {showDrviesModal ? (
                  <DrivesFilesSetting
                    show={showDrviesModal}
                    handleClose={handleClose}
                    handleShow={handleShow}
                  />
                ) : (
                  <></>
                )} */}
                  <div className="hamburger-menu-link" id="hamburger-menu-link">
                    <List size={18} weight="light" className="c-icons" />
                  </div>
                  <div
                    className={`d-flex flex-column flex-grow-1 w-100  ${contact_role == 3 && projectId <= 0 && taskFilters.length == 0 ?  "" : "d-none"}`}
                    >
                    {  globalView == "liteVersionView" && pageName == "Dashboard"  ? (
                      <div className="title-fonts fw-semibold text-truncate f-12 c-font">
                        { (projectId == null || projectId == 0 || projectId == undefined || projectId == "null" || contact_role == 3)
                          ? _l("l_dashboard")
                          : selectedTeam
                          ? selectedTeam.name
                          : selectedOffice.office_title}
                      </div>
                    ) : (
                     pageName == "Dashboard" || pageName == "dashboardnew" ? <div className="title-fonts fw-semibold text-truncate f-12 c-font ">
                        {_l("l_dashboard")}
                      </div> :<></>
                    )}
                    {  globalView == "liteVersionView"  ? (
                      <div className="text-truncate f-12 c-font color-white-60 max-w-lg">
                        {(projectId == null || projectId == 0 || projectId == undefined || projectId == "null")
                          ? ""
                          : selectedTeam
                          ? ""
                          : selectedOffice.postal_address}
                      </div>
                    ) : (
                      <div className="text-truncate f-12 c-font d-flex align-items-center d-none">
                        <a href="#/"
                          className="pe-1"
                          type="button"
                          id="StatusScheduleDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <DesktopTower
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                          <span className="ps-2">{_l("l_on_desk")}</span>
                        </a>
                        
                        <div className="text-truncate f-12 c-font"></div>
                      </div>
                    )}
                  </div>
                  { isContactDir
                    ? <div className={`d-flex flex-column flex-grow-1 w-100 `}>
                           <div className="title-fonts fw-semibold text-truncate f-12 c-font">{groupDetails.groupName}</div>
                      </div>
                    : <></>
                  }
                  { pageName == "dashboardnew" ? 
                     <div
                     className={`d-flex flex-column flex-grow-1 w-100`}>
                     <div className="title-fonts fw-semibold text-truncate f-12 c-font ">
                        {_l("l_dashboard")}
                      </div>
                      </div>
                      :<></>
                      }
                  {!["MyProfile", "PremiumSubscription","Proposals","dashboardnew","invoice","ConnectProvider","SpaceOnBoardingNew","SpaceOnBoarding"].includes(pageName) && (taskFilters && taskFilters.filter((key)=> key.main_key !="date_range_filter").length == 1) ? (  
                    <>
                      {taskFilters && taskFilters.length > 0 && !isContactDir  ?
                      <div className={`d-flex align-items-center w-100 ${["operatorListReporting", "operatorListReporting", "equipmentListReporting"].includes(pageName) ? 'd-none' : ''}`}>
                        <div className="h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-03 position-relative comman-round-box-border-color border-width4 comman-image-box" onClick={()=>{
                          // if(groupDetails && groupDetails.extraData && groupDetails.extraData && groupDetails.extraData.mainKey && groupDetails.extraData.mainKey  == "providers" || groupDetails.extraData.mainKey  ==  "myr_support" ){
                          //   setShow(false);
                          //   setViewGroupDetails(false); 
                          // }else{
                          //   setViewGroupDetails(true); 
                          //   setShow(true)
                          // }
                          if(groupDetails && groupDetails.extraData && groupDetails.extraData.mainKey){
                            var mainKey = groupDetails.extraData.mainKey;
                            var filterKey = groupDetails.extraData.filterKey;
                            var mainSubKey = groupDetails.extraData.mainSubKey;
                            if((["my_contact","my_operator","my_staff"].includes(mainKey) && (mainSubKey == "team" || groupDetails.members.length > 2)) || ["providers","myr_support","equipments"].includes(mainKey)){
                              setShow(true);
                              if(userType == "staff" || contact_role == 1 || contact_role == 2){
                                setViewGroupDetails(true);
                              }else{
                                setViewGroupDetails(false); 
                              }
                            }else{
                              setShow(false);
                              setViewGroupDetails(false); 
                            }
                          }
                        }}
                          >
                          {taskFilters.some((taskFilter)=> taskFilter.filter_key == "myrai_support") || (userType != "staff" && contact_role == 3) ? 
                          <UsersThree size={16} weight="light" className={`c-icons`}/>
                          :topicLogo.url && topicLogo.url != "" && topicLogo.url != undefined ?
                           <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{
                            backgroundImage: `url('${topicLogo.url && topicLogo.url != "" && topicLogo.url != undefined ? topicLogo.url : ""}')`,
                          }}></div> 
                              : taskFilters && taskFilters.some((key) => key.main_key == "my_task")  ? 
                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{
                                  backgroundImage: `url('${localStorage.getItem("contact_image")}')`,
                                }}>
                          </div>
                           :
                           <>
                          {selected_topic && selected_topic.image ? <>
                            <span class="d-flex  light-pill mt-1 position-absolute rounded-pill headmemberstatus right2px" >
                              <span className={`c-dots c-7 ${isOnline == 1 ? "done" : "bg-warning"} rounded-circle`}></span>
                            </span>
                            <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{
                                  backgroundImage: `url('${selected_topic.image}')`,
                                }}></div>
                          </> :taskFilters && taskFilters.some((key) => key.main_sub_key == "people") ?
                          <User size={16}
                            weight="light"
                            className={`c-icons`} /> 
                            : 
                            <UsersThree size={16}
                            weight="light"
                            className={`c-icons`} /> 
                            }</>}
                          {/* <label className="upload_inputbox upload_relative border-0">
                          {taskFilters && taskFilters.some((key) => key.main_key == "providers") ? <input
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            className="absolute-input"
                            onChange={(event) => {
                              if (event.target.files.length == 1 && taskFilters && taskFilters.some((key) => key.main_key == "providers")) {
                                preparePreview(event.target.files);                                
                              }
                            }}
                            disabled ={taskFilters && taskFilters.some((key) => key.main_key != "providers")}
                          /> : <></>}
                          </label> */}
                        </div> 
                        <div className="c-font f-12 fw-semibold p-15 py-0 title-fonts w100minus25 text-truncate w100minus100per">
                          {/* <div className="mb-1">{groupDetails.groupName ? groupDetails.groupName : ""}</div> */}
                          <div className="mb-1 text-truncate" onClick={() => {
                            if(groupDetails && groupDetails.extraData && groupDetails.extraData.mainKey){
                              var mainKey = groupDetails.extraData.mainKey;
                              var filterKey = groupDetails.extraData.filterKey;
                              var mainSubKey = groupDetails.extraData.mainSubKey;
                              if((["my_contact","my_operator","my_staff"].includes(mainKey) && (mainSubKey == "team" || groupDetails.members.length > 2)) || ["providers","myr_support","equipments"].includes(mainKey)){
                                setShow(true);
                                if(userType == "staff" || contact_role == 1 || contact_role == 2){
                                  setViewGroupDetails(true);
                                }else{
                                  setViewGroupDetails(false); 
                                }
                              }else{
                                setShow(false);
                                setViewGroupDetails(false); 
                              }
                            }
                          }}>
                            {
                            // userType != "staff" && contact_role == 3 
                            //   ? localStorage.getItem("company_name") ? localStorage.getItem("company_name") : "Myr.ai Support" 
                            //   : groupDetails && groupDetails.groupName 
                            //     ? groupDetails.extraData && groupDetails.extraData.mainKey == "myr_support"
                            //      ? "Myr.ai Support" : groupDetails.groupName
                            //     : _l(taskFilters.filter((filter) => filter.main_key == "my_task")[0] 
                            //       ? localStorage.getItem('full_name') 
                            //         : taskFilters.filter((filter) => filter.filter_key != "date_range_filter" && filter.filter_key != "unread_comments")[0] 
                            //           ? taskFilters.filter((filter) => filter.filter_key != "date_range_filter" && filter.filter_key != "unread_comments")[0].title 
                            //           :"")
                            
                            userType != "staff" && contact_role == 3 
                              ? localStorage.getItem("company_name") ? localStorage.getItem("company_name") : "Myr.ai Support" 
                              :  _l(taskFilters.filter((filter) => filter.main_key == "my_task")[0] 
                                  ? localStorage.getItem('full_name') 
                                  : taskFilters.filter((filter) => filter.filter_key != "date_range_filter" && filter.filter_key != "unread_comments")[0] 
                                    ? taskFilters.filter((filter) => filter.filter_key != "date_range_filter" && filter.filter_key != "unread_comments")[0].title
                                    : groupDetails && groupDetails.groupName 
                                      ? groupDetails.extraData && groupDetails.extraData.mainKey == "myr_support" ? "Myr.ai Support" 
                                      : groupDetails.groupName :'')
                          }</div>
                          <div className="multi_image_wrapper dropdown dropdown-center me-auto">
                            <div className=" d-flex position-relative">
                          { groupDetails && groupDetails.members && groupDetails.extraData
                            && ((["my_contact","my_operator","my_staff"].includes(groupDetails.extraData.mainKey) && (groupDetails.extraData.mainSubKey == "team" || groupDetails.members.length > 2)) || ["providers","myr_support","equipments"].includes(groupDetails.extraData.mainKey))
                          && groupDetails.members.filter((m , index) => contact_role ==  3 ? index > -1  && index < 3 : index < 3).map((member) =>{
                            return(<>                            
                              <a href="#/" className={`h20w20 comman-image-box with-bg d-flex align-items-center rounded-circle bg-white-03 with-close me-2 ${member.myrUserType == "client" ? "contact_user" : member.myrUserType == "staff" && member.myrRole == 2 ? "operator_user" : "staff_user" }`}
                              >
                                <span class="d-flex light-pill headmemberstatus position-absolute rounded-pill " >
                                  <span className={`c-dots c-7 ${member.isOnline == 1 ? "done" : "bg-warning"} rounded-circle`}></span>
                                </span>
                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  data-tip={member.fname + " " + member.lname}
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                  data-place="right"
                                  style={{
                                    backgroundImage: `url('${member.displayPicture == "" ? USR_IMG_PLACEHOLDER : ATTACH_BASE_URL + member.displayPicture}')`,
                                  }}>
                                      <ReactTooltip />                                    
                                  </div>
                                
                              </a>
                            </>)
                          })}
                         {groupDetails && groupDetails.extraData && ((["my_contact","my_operator","my_staff"].includes(groupDetails.extraData.mainKey) && (groupDetails.extraData.mainSubKey == "team" || groupDetails.members.length > 2)) || ["providers","myr_support","equipments"].includes(groupDetails.extraData.mainKey)) ? 
                              <a href="#/" className="d-flex align-items-center rounded-circle with-close me-1">
                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex"
                                  data-tip="view more"
                                  data-effect="solid"
                                  data-delay-show='1000'
                                  data-class="tooltip-main"
                                  data-place="right"
                                  ><ReactTooltip />  
                                  </div>
                                  <UncontrolledDropdown className="d-flex rounded-circle dropdown dropdown-center team-list-dropdown ">
                                    <DropdownToggle tag="div" className="" >
                                      <div
                                        className="comman-image-box h20w20 with_overlay d-flex rounded-circle comman-round-box-border-color border-width3"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                        aria-expanded="false"
                                        data-tip={_l("l_view_all")}
                                        data-effect="solid"
                                        data-delay-show='1000'
                                        data-class="tooltip-main"
                                      >
                                        {/* <div className="unread_count m-auto c-font f-12">{groupDetails.members.length > 3 ? "+" + (groupDetails.members.length - 3) : ""}</div> */}
                                        <DotsThree size={16} className="c-icons"  weight="light" />
                                        
                                      </div>
                                      <UncontrolledGeneralListing
                                        list={groupDetails && groupDetails.members && groupDetails.members.length > 0 && groupDetails.members.sort((a,b)=>{
                                          {
                                            if (a.myrUserType2 == "Myr Support") {
                                              a = -1; b = 1;
                                            } else {
                                              a = 1; b = -1;
                                            }
                                            return a - b
                                          }
                                        }).map((item, index) => {
                                          let filter_key = ""
                                          let main_key = ""
                                          let main_sub_key = ""
                                          if (defaultTopics && defaultTopics.length > 0) {
                                            defaultTopics.forEach((topic) => {
                                              if (topic.categories) {
                                                topic.categories.forEach((category) => {
                                                  if (topic.main_key !== "myr_support" && category.contact_id && category.contact_id == item.myrUserId) {
                                                    filter_key = category.filter_key;
                                                    main_key = topic.main_key;
                                                    main_sub_key = category.main_sub_key;
                                                  } else if (topic.main_key !== "myr_support" && category.filter_key && category.filter_key == item.myrUserId) {
                                                    filter_key = category.filter_key;
                                                    main_key = topic.main_key;
                                                    main_sub_key = category.main_sub_key;
                                                  }
                                                })
                                              }
                                            })
                                          }
                                          return {
                                            name: item.fname + " " + item.lname,
                                            image: item.displayPicture == "" ? USR_IMG_PLACEHOLDER : ATTACH_BASE_URL + item.displayPicture,
                                            id: index,
                                            isOnline: item.isOnline,
                                            userId: item._id,
                                            userType : item.myrUserType,
                                            myrRole: item.myrRole,
                                            filter_key : filter_key,
                                            main_key : main_key,
                                            main_sub_key :main_sub_key
                                          };
                                        })}
                                        fromChat={false}
                                        forCall={false}
                                        makeCall={makeCall}
                                        fromAichat={true}
                                        sortList={false}
                                        callFromGroup={groupDetails && groupDetails._id ? groupDetails._id : ""}
                                      />
                                    </DropdownToggle>
                                    <ReactTooltip />
                                  </UncontrolledDropdown>
                                
                              </a> 
                              :<></>}
                           </div>
                          </div>
                        </div>
                      </div> 
                      : <></>}  
                    </>
                  ):<></>}
                  {pageName == "MyPeople" ? (
                    <>
                      <a href="#/"
                        style={{
                          marginRight: "15px",
                        }}
                        onClick={() => {
                          navigate("/");
                          onPagechangeToggle();
                          dispatch(setSelectedGloablView(globalView));

                        }}
                      >
                        <CaretLeft
                          size={18}
                          weight="light"
                          className="c-icons"
                        />
                      </a>
                      <div className="d-flex flex-column">
                        <div className="fw-semibold text-truncate">
                          <span>{_l("l_present_reporting")}</span>
                        </div>
                      </div>
                    </>
                  ) : ["Chat"].includes(
                      pageName
                    ) /* && openGeneralChatGroup */ ? (
                    localStorage.getItem("selectedVirtualSpace") &&
                    Object.keys(localStorage.getItem("selectedVirtualSpace"))
                      .length > 0 ? (
                      <>
                        <a href="#/"
                        className="no-space"
                          style={{
                            marginRight: "15px",
                          }}
                          onClick={() => {
                            navigate("/");
                            onPagechangeToggle();
                            dispatch(setOpenGeneralChatGroup(false));
                          dispatch(setSelectedGloablView(globalView));

                          }}
                        >
                          <CaretLeft
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                        </a>
                        <div className="d-flex flex-column w-50">
                          <div className="fw-semibold title-fonts text-truncate">
                            <span>
                              {
                                JSON.parse(
                                  localStorage.getItem("selectedVirtualSpace")
                                ).name
                              }
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <a href="#/"
                         className="with-space"
                          style={{
                            marginRight: "15px",
                          }}
                          onClick={() => {
                            navigate(-1);
                            // onPagechangeToggle();
                            dispatch(setOpenGeneralChatGroup(false));
                            dispatch(setSelectedGloablView(globalView));

                          }}
                        >
                          <CaretLeft
                            size={18}
                            weight="light"
                            className="c-icons"
                          />
                        </a>
                        {projectId > 0 ? 
                        <>
                        <div className="d-flex flex-column w-100 text-truncate">
                          {openGeneralChatGroup || localStorage.getItem("generalChatForLiteVersion") == "true" || selectedUserForChat && Object.keys(selectedUserForChat).length > 0 ? (
                            <div className="fw-semibold text-truncate">
                              <span></span>
                              {/* <span>{_l("l_general_discussion")}</span> */}
                            </div>
                          ) : (
                            <>
                              <div className="w-100 text-truncate">
                                <div className="fw-semibold title-fonts text-truncate">
                                  <span>
                                    {selectedOffice.office_title
                                      ? selectedOffice.office_title
                                      : selectedOffice.postal_address}
                                  </span> 
                                </div>
                                <div className="c-font f-12 opacity-50 text-truncate">
                                  <span>{selectedOffice.postal_address}</span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {/* Login as user btn start */}
                          {/* <Button variant="primary" className=" btn-sm-2">{_l("l_login_as_user")}</Button> */}
                        {/* Login as user btn over */}
                        </> : <></>}
                      </>
                    )
                  ) : (
                    <></> 
                  )}
                  {[
                    "Wallet",
                    "CompanyProfile",
                    "PremiumSubscription",
                    "MyProfile",
                  ].includes(pageName) ? (
                    <div className="f-14 title-fonts fw-semibold">
                      {pageNames[pageName]}
                    </div>
                  ) : (
                    <></>
                  )}
                  {["operatorListReporting", "operatorListReporting", "equipmentListReporting"].includes(pageName) || (taskFilters && taskFilters.filter((key)=> key.main_key !="date_range_filter").length > 1) ? <div
                    className={`office-detail text-truncate w-100`}
                    // data-tip={
                    //   selectedOffice.postal_address
                    //     ? selectedOffice.postal_address
                    //     : ""
                    // }
                    // data-effect="solid"
                    data-delay-show='1000'
                    // data-class="tooltip-main"
                  >
                    <div className="d-flex align-items-start">
                      <div className="bg-white-05 h40w40 rounded-circle title-fonts d-flex res-d-none-imp d-none">
                        <span className="m-auto c-font f-10 office-subtitle text-truncate p-1">
                          {selectedOffice.office_title
                            ? selectedOffice.office_title
                            : ""}
                        </span>
                      </div>
                      {projectId > 0 ? 
                      <div className="office-detail-name w100minus40 flex-grow-1">
                        {showFilterArea &&
                        (  pathname == "/" ||
                          pathname == "/tasks" ||
                          pathname == "/calendar" ||
                          pathname == "/MyEmail" ||
                          pageName == "operatorListReporting" ||
                          pageName == "equipmentListReporting" ||
                          pageName == "Proposals" ||
                          isLeadPage ||
                          pageName == "documents" ||
                          pageName == "invoice" ||
                          pageName == "credit_notes" ||
                          pageName == "subscription" ||
                          pageName == "client" ||
                          pageName == "estimate" ||
                          pageName == "schedule" ||
                          pageName == "benchmark" ||
                          pageName == "staff" ||
                          pageName == "dashboard" ||
                          pageName == "fullcalendar" ||
                          pageName == "_equipments") ? (
                          <React.Fragment>
                            <div className="fw-semibold c-font f-12 title-fonts text-truncate max-w-lg"> 
                              {(leftPanelActiveFilters.length == 1 &&
                                (leftPanelActiveFilters[0]["filter_key"] == "all" || leftPanelActiveFilters[0]["filter_key"] == "date_range_filter") ) ? (
                                <>
                                  {window.location.pathname === "/provider-reporting" ?
                                    <span>{ _l("l_services")}</span>
                                    : window.location.pathname === "/employee-reporting" ?
                                      <span>{ _l("l_people")}</span> 
                                      : window.location.pathname == "/equipment-reporting" ?
                                        <span>{ _l("l_equipment")}</span>
                                        : pageName == "dashboard" ?
                                          <span> {_l("l_dashboard")}</span>
                                          : <span>{_l("l_all_section")}</span>
                                  }
                                </>
                              ) : (
                                <></>
                              )}
                              {leftPanelActiveFilters.length !== 0 ? (
                                leftPanelActiveFilters.map((value, index) => {
                                  let displayMainKey = ""
                                  if(value == "overdue" || value == "pinned"){
                                    displayMainKey = _l("l_my_task");
                                  }
                                  if (
                                    value.main_key != "date_range_filter" &&
                                    value.main_key != ""
                                  ) {
                                    iKey++;
                                    if (iKey == 1) {
                                      let displayTitleKey = value.title;
                                      let displayFilterKey = value.filter_key;
                                      let displayMainKey = (value == "overdue" || value == "pinned") ?  _l("l_my_task") : value.main_key;
                                      if (displayMainKey == "my_task") {
                                        displayMainKey = _l("l_my_task");
                                      } else if (displayMainKey == "providers") {
                                        displayMainKey = _l("l_services");
                                      } else if (displayMainKey == "my_contact") {
                                        displayMainKey = _l("l_my_people");
                                      } else if (displayMainKey == "equipments") {
                                        displayMainKey = _l("l_equipments");
                                      } else if (displayMainKey == "requested_task") {
                                        displayMainKey = _l("l_request");
                                        localStorage.setItem(
                                          "value.main_key",
                                          displayMainKey
                                        );
                                      } else if (displayMainKey == "my_emails") {
                                        displayMainKey = _l("l_email");
                                      } else if (displayMainKey == "my_customs") {
                                        displayMainKey = _l("l_customs");
                                      } else if (displayMainKey == "my_customers") {
                                        displayMainKey = _l("l_my_customers");
                                      } else if (displayMainKey == "myr_staff_task") {
                                        displayMainKey = _l("l_myr_staff_task");
                                      } else if (displayMainKey == "user_journey") {
                                        displayMainKey = _l("l_user_journey");
                                      } else if (displayMainKey == "request_people") {
                                        displayMainKey = _l("l_request_people");
                                      }
                                      localStorage.setItem(
                                        "value.filter_key",
                                        displayFilterKey
                                      );
                                      window.dispatchEvent(
                                        new Event("value.filter_key")
                                      );
                                      return <span>{displayMainKey}</span>;
                                    }
                                  }
                                })
                              ) : (
                                <>
                                  {window.location.pathname === "/provider-reporting" ?
                                    <span>{ _l("l_services")}</span>
                                    : window.location.pathname === "/employee-reporting" ?
                                      <span>{ _l("l_people")}</span> 
                                      : window.location.pathname == "/equipment-reporting" ?
                                        <span>{ _l("l_equipment")}</span>
                                        : pageName == "dashboard" ?
                                          <span> {_l("l_dashboard")}</span>
                                          : <></>
                                  }
                                </>
                              )}
                              {/* {show ? (
                              <CalendarModal
                                show={show}
                                handleClose={calendarmodalhandleClose}
                                handleShow={calendarmodalhandleShow}
                              />
                            ) : (
                              <></>
                            )} */}
                            </div>
                            <div className="pill-wrapper mt-1 max-w-lg">
                              <div className="d-flex align-items-center">
                                {/* {buttonInCalendar && (
                                <div className="badge badge-white rounded-pill fw-light me-1 pill-task-active p-1">
                                  <div className="d-flex align-items-center">
                                    <a href="#/" className="ps-1">
                                      <div
                                        onClick={() => {
                                          setShow(1);
                                        }}
                                      >
                                        <span> {googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 || isOutlookCalendarActive === true ? isOutlookCalendarActive === true ? _l('l_outlook_calendar') : _l('l_google_calendar'): _l("l_select_calendar")}</span>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              )} */}
                                {leftPanelActiveFilters.length == 1 &&
                                activeTaskStatusFilters.length == 0 &&
                                (leftPanelActiveFilters[0]["filter_key"] =="all" || leftPanelActiveFilters[0]["filter_key"] == "date_range_filter") ? (
                                  <span className={`badge badge-white rounded-pill fw-light me-1 pill-task-active p-1 ${["operatorListReporting", "equipmentListReporting"].includes(pageName)? 'd-none' : ''}`}>
                                    {_l("l_all_topics")}
                                  </span>
                                ) : (
                                  <></>
                                )}
                                {leftPanelActiveFilters.length !== 0 ||
                                activeTaskStatusFilters.length !== 0 ? (
                                  leftPanelActiveFilters.findIndex(
                                    (x) =>
                                      (x.main_key === "my_operator" ||
                                        x.main_key === "my_staff" ||
                                        x.main_key === "my_contact" ||
                                        x.main_key === "myr_staff_task" ||
                                        x.main_key === "request_people" ||
                                        x.main_key === "equipments" ||
                                        (pageName == "operatorListReporting" || pageName == "equipmentListReporting" &&
                                          x.main_key != "date_range_filter")) &&
                                      x.filter_key > 0
                                  ) !== -1 ? (
                                    <div className="multi-images-with-close d-flex">
                                      {commanoffcanvasIsOpen == false &&
                                        leftPanelActiveFilters.map(
                                          (value, index) => {
                                            if (
                                              value.main_key !=
                                                "date_range_filter" &&
                                              value.main_key != ""
                                            ) {
                                              let displayFilterKey =
                                                value.title;
                                              localStorage.setItem(
                                                "value.filter_key",
                                                displayFilterKey
                                              );
                                              return (
                                                <div
                                                  className={` d-flex align-items-center ms-1 ${
                                                    index > 2 ? "d-none" : ""
                                                  }`}
                                                >
                                                  <div
                                                    data-bs-toggle="tooltip"
                                                    className="comman-image-box h20w20 rounded-circle with-close me-2"
                                                    data-tip={value.title}
                                                    data-for={randomID}
                                                    data-effect="solid"
                                                    data-delay-show='1000'
                                                    data-class="tooltip-main"
                                                    currentitem="false"
                                                    aria-describedby="ta80d5ced-c0a1-48da-8c8a-fea25169cae6"
                                                  >
                                                    <div
                                                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                      style={{
                                                        backgroundImage: `url('${value.image}')`,
                                                      }}
                                                    >{value.image == "" ? <UsersThree width="18" height="18" fill="currentColor" class="c-icons active-svg" />:<></>}</div>
                                                    {/* <a href="#/"
                                                      className="close-circle"
                                                      onClick={() => {
                                                        dispatch(
                                                          leftPanelFilters({
                                                            main_key:
                                                              value.main_key,
                                                            filter_key:
                                                              value.filter_key,
                                                            title: value.title,
                                                            main_sub_key: value.main_sub_key,
                                                          })
                                                        );

                                                        dispatch(
                                                          getTaskCount(
                                                            0,
                                                            0,
                                                            localStorage.getItem(
                                                              "selectedOffice"
                                                            ),
                                                            moment(
                                                              localStorage.getItem(
                                                                "selectedDate"
                                                              )
                                                            ).format(
                                                              "YYYY-MM-DD"
                                                            ),
                                                            taskFilters,
                                                            "",
                                                            activeTaskStatusFilters,
                                                            "remove",
                                                            value.filter_key,
                                                            isLeadPage,
                                                            pageName
                                                          )
                                                        );
                                                        dispatch(
                                                          projectTaskCounts(
                                                            projectId,
                                                            taskFilters,
                                                            {
                                                              main_key:
                                                                value.main_key,
                                                              filter_key:
                                                                value.filter_key,
                                                              title:
                                                                value.title,
                                                            },
                                                            isLeadPage,
                                                            pageName
                                                          )
                                                        );
                                                        window.dispatchEvent(
                                                          new Event(
                                                            "filter_key_close"
                                                          )
                                                        );
                                                      }}
                                                    >
                                                      <X
                                                        size={10}
                                                        weight="light"
                                                        className="c-icons lt-black"
                                                      />
                                                    </a> */}
                                                    <ReactTooltip/>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          }
                                        )}
                                    </div>
                                  ) : (
                                    leftPanelActiveFilters.map(
                                      (value, index) => {
                                        if (
                                          value.main_key !=
                                            "date_range_filter" &&
                                          value.main_key != ""
                                        ) {
                                          let displayFilterKey = value.title ? value.title : value == "pinned" ? "l_pinned" : value == "overdue" ? "l_delay": value;
                                          localStorage.setItem(
                                            "value.filter_key",
                                            displayFilterKey
                                          );
                                          let statusName = "";
                                          if (value == 1) {
                                            statusName = _l("l_todo_kpi");
                                          } else if (value == 4) {
                                            statusName = _l("l_inprogress_kpi");
                                          } else if (value == 5) {
                                            statusName = _l("l_done_kpi");
                                          } else if (value == 0) {
                                            statusName = _l("l_request");
                                          }
                                          return (
                                            <div
                                              className={`badge badge-white rounded-pill fw-light me-1 pill-task-active p-1 max-width75 text-truncate ${
                                                commanoffcanvasIsOpen
                                                  ? index > 0
                                                    ? "d-none"
                                                    : ""
                                                  : index > showPills
                                                  ? "d-none"
                                                  : ""
                                              }`}
                                              key={index}
                                            >
                                              <div className="d-flex align-items-center text-truncate">
                                                <span className="text-truncate">
                                                  {statusName != ""
                                                    ? statusName
                                                    : _l(displayFilterKey)}
                                                </span>
                                                <a href="#/" className="ps-1">
                                                  {/* <div
                                                    onClick={() => {
                                                      if(value == "pinned" || value == "overdue"){
                                                        dispatch(isPinnedDelayFilter(pinDelayfilter.filter((f) => f != value)));
                                                      }else if (
                                                        pageName ==
                                                          "Proposals" &&
                                                        value.main_key ==
                                                          "proposals"
                                                      ) {
                                                        dispatch(
                                                          leftPanelFilters({
                                                            main_key:
                                                              value.main_key,
                                                            filter_key:
                                                              value.filter_key,
                                                            title: value.title,
                                                            image: value.image,
                                                            main_sub_key: value.main_sub_key,
                                                          })
                                                        );
                                                      } else if (statusName != "") {
                                                        removeStatusFilter(
                                                          value
                                                        );
                                                      } else {
                                                        dispatch(
                                                          leftPanelFilters({
                                                            main_key:
                                                              value.main_key,
                                                            filter_key:
                                                              value.filter_key,
                                                            title: value.title,
                                                            main_sub_key: value.main_sub_key,
                                                          })
                                                        );
                                                        dispatch(
                                                          getTaskCount(
                                                            0,
                                                            0,
                                                            localStorage.getItem(
                                                              "selectedOffice"
                                                            ),
                                                            moment(
                                                              localStorage.getItem(
                                                                "selectedDate"
                                                              )
                                                            ).format(
                                                              "YYYY-MM-DD"
                                                            ),
                                                            taskFilters,
                                                            "",
                                                            activeTaskStatusFilters,
                                                            "remove",
                                                            value.filter_key,
                                                            isLeadPage,
                                                            pageName
                                                          )
                                                        );
                                                        dispatch(
                                                          projectTaskCounts(
                                                            projectId,
                                                            taskFilters,
                                                            {
                                                              main_key:
                                                                value.main_key,
                                                              filter_key:
                                                                value.filter_key,
                                                              title:
                                                                value.title,
                                                            },
                                                            isLeadPage,
                                                            pageName
                                                          )
                                                        );
                                                        window.dispatchEvent(
                                                          new Event(
                                                            "filter_key_close"
                                                          )
                                                        );
                                                      }
    
                                                    
                                                    }}
                                                  >
                                                    <X
                                                      size={10}
                                                      weight="light"
                                                      className="c-icons lt-black"
                                                    />
                                                  </div> */}
                                                </a>
                                              </div>
                                            </div>
                                          );
                                        } else if ( pageName == "operatorListReporting" &&  value.main_key == "date_range_filter") {
                                          return (
                                            <span
                                              className="badge badge-white rounded-pill fw-light me-1 pill-task-active p-1 d-none"
                                            >
                                              {pageName ==
                                              "operatorListReporting"
                                                ? _l("l_all_operators")
                                                : _l("l_all_topics")}
                                              {pageName ==
                                              "operatorListReporting" ? (
                                                <a href="#/" 
                                                   className="ms-1"
                                                   onClick={() => {
                                                    if (
                                                      pageName ==
                                                      "operatorListReporting"
                                                    ) {
                                                      navigate("/");
                                                    }
                                                   }}
                                                >
                                                <X
                                                  size={10}
                                                  weight="light"
                                                  className="c-icons lt-black"
                                                /> 2
                                                </a>
                                              ) : (
                                                <></>
                                              )}
                                            </span>
                                          );
                                        }
                                      }
                                    )
                                  )
                                ) : (
                                  <>
                                    <span
                                      className="badge badge-white rounded-pill fw-light me-1 pill-task-active p-1 d-none"
                                      
                                    >
                                      {pageName == "operatorListReporting"
                                        ? _l("l_all_operators")
                                        : pageName == "dashboard"
                                        ? ""
                                        : _l("l_all_topics")}
                                      {pageName == "operatorListReporting" ? (
                                        <a href="#/" 
                                           className="ms-1"
                                           onClick={() => {
                                            if (
                                              pageName == "operatorListReporting"
                                            ) {
                                              navigate("/");
                                            }
                                          }}
                                        >
                                        <X
                                          size={10}
                                          weight="light"
                                          className="c-icons lt-black"
                                        /> 1
                                        </a>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                  </>
                                )}
                                {/* {commanoffcanvasIsOpen == false  && (activeTaskStatusFilters.length !== 0 &&
                                NoHeaderIconPage.indexOf(pageName) == -1 )? (
                                  activeTaskStatusFilters.map(
                                    (value, index) => {
                                      let statusName = "";
                                      if (value == 1) {
                                        statusName = "Not started";
                                      } else if (value == 4) {
                                        statusName = "In progress";
                                      } else if (value == 5) {
                                        statusName = "Done";
                                      } else if (value == 0) {
                                        statusName = "Request";
                                      }

                                      return (
                                        <div
                                          className="badge badge-white rounded-pill fw-light me-1 pill-task-active p-1"
                                          key={index}
                                        >
                                          <div className="d-flex align-items-center">
                                            <span>{statusName}</span>
                                            <a href="#/" className="ps-1">
                                              <div
                                                onClick={() => {
                                                  removeStatusFilter(value);
                                                  // dispatch(getTaskCount(0, 0, localStorage.getItem('selectedOffice'), moment(localStorage.getItem('selectedDate')).format('YYYY-MM-DD'),
                                                  //   taskFilters, '', activeTaskStatusFilters, 'remove', value))
                                                }}
                                              >
                                                <X
                                                  size={10}
                                                  weight="light"
                                                  className="c-icons lt-black"
                                                />
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )} */}
                                {/* <div className="d-flex align-items-center color-white">{leftPanelActiveFilters.length > 0 ? leftPanelActiveFilters[0].main_key : ""}</div> */}
                                {leftPanelActiveFilters.length < (showPills + 2) &&
                                !commanoffcanvasIsOpen ? (
                                  <></>
                                ) : (
                                  <div
                                    className={`${
                                      leftPanelActiveFilters.length == 0
                                        ? "d-none"
                                        : ""
                                    }`}
                                  >
                                    <a href="#/"
                                      className={`badge badge-white rounded-pill fw-light me-1 pill-task-active p-0 h20w20 d-flex`}
                                      type="button"
                                      id="HeaderFilterDropdown"
                                      data-bs-toggle="dropdown"
                                      data-bs-auto-close="outside"
                                      aria-expanded="false"
                                    >
                                      <DotsThree
                                        size={16}
                                        className="c-icons"
                                        weight="light"
                                      />
                                    </a>
                                    <HeaderFilterDropdown
                                      list={leftPanelActiveFilters.map(
                                        (data, index) => {
                                          let statusName = "";
                                          if (data == 1) {
                                            statusName = _l("l_todo_kpi");
                                          } else if (data == 4) {
                                            statusName = _l("l_inprogress_kpi");
                                          } else if (data == 5) {
                                            statusName = _l("l_done_kpi");
                                          } else if (data == 0) {
                                            statusName = _l("l_request");
                                          } else if (data == "pinned") {
                                            statusName = _l('l_pinned');
                                          } else if (data == "overdue") {
                                            statusName = _l("l_delay");
                                          }
                                          return {
                                            filter_key: data.filter_key
                                              ? data.filter_key
                                              : "",
                                            main_key: data.main_key
                                              ? data.main_key
                                              : "",
                                            title: data.title
                                              ? _l(`${data.title}`)
                                              : statusName,
                                            image: data.image ? data.image : "",
                                            status: data,
                                          };
                                        }
                                      )}
                                      projectId={projectId}
                                      pageName={pageName}
                                      isLeadPage={isLeadPage}
                                      removeStatusFilter={removeStatusFilter}
                                    />
                                  </div>
                                )}
                                {
                      taskType == 0 || taskType == 1 ?
                        <div className="badge w-auto color-white-80 bg-white-05  radius_3 me-2">
                          <div className="d-flex align-items-center">

                            <span className="color-white">
                              {taskType ? _l('l_request_task') : _l('l_task')}
                            </span>
                            <a href="#/"
                              className="ps-1"
                              onClick={() => {
                                dispatch(setTaskTypeFilter(_l('l_all')))
                              }}
                            >
                              <X size={14} weight="light" className="c-icons" />
                            </a>
                          </div>
                        </div>
                        : <></>
                    }
{/* show date range filter */}
                     {/* {leftPanelActiveFilters.length && daterangeDayDifference < 7 && (daterangeAppliedFilters != "this_week" && daterangeAppliedFilters != "last_7_days") ? (
                      leftPanelActiveFilters.map((value, index) => {
                        if (value.main_key == "date_range_filter") {
                          jquery("body #active_week_date").removeClass("c-gradinet-active-border");
                          let displayFilterKey = value.title;
                          return (
                            <div
                              key={index}
                              className="badge c-font w-auto color-white-80 bg-white-05  radius_3 comman-round-box active py-1"
                            >
                              <div className="d-flex align-items-center">
                                {/* <span className="color-white">{`${filter_key[0].toUpperCase()}${
                                filter_key.length > 1
                                  ? filter_key.slice(1).split("_").join(" ")
                                  : ""
                              }`}</span> * /}

                                <span className="color-white">
                                  {displayFilterKey}
                                </span>
                                <a href="#/"
                                  className="ps-1"
                                  onClick={() => {
                                    dispatch(
                                      leftPanelFilters({
                                        main_key: value.main_key,
                                        filter_key: value.filter_key,
                                        title: value.title,
                                      })
                                    );
                                    dispatch(
                                      projectTaskCounts(projectId, taskFilters, {
                                        main_key: value.main_key,
                                        filter_key: value.filter_key,
                                        title: value.title,
                                      })
                                    );
                                    dispatch(
                                      projectTaskCounts(
                                        projectId,
                                        taskFilters,
                                        '')
                                    );
                                    dispatch(getTopicTasks(projectId, "", 0, localStorage.getItem('selectedDate'), taskFilters));
                                    dispatch(dayGridWeek(true));
                                    dispatch(dayGridMonth(false));
                                    dispatch(dayGridDay(false));

                                    // jquery(
                                    //   ".fixed-left-panel.provider-list"
                                    // ).addClass("expanded");
                                    // jquery("body").addClass("body-toggle");
                                    // jquery(".after-login-header").addClass(
                                    //   "body-toggle"
                                    // );
                                    // jquery("footer").addClass("body-toggle");
                                  }}
                                >
                                  <X size={14} className="c-icons" weight="light" />
                                </a>
                              </div>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <></>
                    )} */}
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                        <div className="d-none c-font f-10 fw-light color-white-80">
                          <span className="res-d-none-imp">
                            {selectedOffice.postal_address
                              ? selectedOffice.postal_address
                              : ""}
                          </span>
                        </div>
                      </div> : 
                      <>
                      <div className={`office-detail-name w100minus40 flex-grow-1 ${pageName == "Chat" || pageName == "Dashboard" || pageName == "dashboardnew" ? "" : "d-none" }`}>
                        <div className="title-fonts fw-semibold text-truncate f-12 c-font">
                        {_l("l_dashboard")}
                        </div>
                        </div>
                        </>}
                    </div>
                  </div> : <></>}
                </div>
                <div className="center-part flex-grow-1">
                  {/* Global Search HTML start */}
                    <div className={`${globalView ==="liteVersionView" ? "daily-schedule-main  m-0 daily-schedule-transform " : "daily-schedule-main m-0 daily-schedule-transform res-width"} ${globalView == "AichatView" ? "w340px" : ""}`}>
                      {/* <div className={`${globalView == "liteVersionView" ? "daily-schedule-main m-0 with-ms-4px" : "global-search-main"}`}> */}
                      {globalSearchPageArray.indexOf(pageName) > -1 ? (
                        <div
                          className={`commn-search d-flex justify-content-between radius_3 position-relative z-index-25 global-search-main align-items-center ps-10px radius_3 bg-base-header-color ${
                            showGlobalSerachModal ? "opacity-0" : ""
                          } ${isBackdrop ? "comman-search-on-focus" : ""}`}
                        >
                          <div className="m-auto ps-0">
                            <MagnifyingGlass
                              size={18}
                              className="c-icons"
                              weight="light"
                            />
                          </div>
                          <div className={`comment-input-box flex-grow-1 ${globalView == "AichatView" ? "" : "before-dnone with_separator_10 "}`}
                          onClick={() => {
                            jquery("body").addClass("searchbackdrop")
                            // jquery(".global-search-main").addClass("bg-base-header-color")
                            GlobalModalSearchShow();
                          }}
                          >
                            {/* <input
                              type="text"
                              className="form-control bg-transparent bg-transparent border-0 h-35 px-0"
                              placeholder={`${globalView == "liteVersionView" ? _l("l_hello_how_can_i_help_you") : _l("l_search") }`}
                              onClick={() => {
                                GlobalModalSearchShow();
                              }}
                              onChange={(e) => {
                                let search_string = e.target.value;
                                if (search_string == "") {
                                  setglobalSearchCloseIcon(false);
                                  GlobalModalSearchHide();
                                }
                                setglobalSearchCloseIcon(true);
                                dispatch({
                                  type: SET_GLOBAL_SEARCH_STRING,
                                  payload: { search_string },
                                });
                              }}
                              value={globalSearchString}
                            /> */}
                            <Autocomplete
                              mainWidth="w-100"
                              inputClass="form-control border-0 bg-transparent office-list-icon-search-input d-flex align-items-center h-100 w-100 shadow-none pe-0"
                              dropdownClass="w-100 max-height-60vh"
                              options={searchString.length && searchString.trim().charAt(0) === "@" ? mentionStaffArray : []}
                              value={searchString || globalSearchString}
                              onChange={(e) => {
                                let search_string = e.target.value;
                                if (search_string.split("").includes("@")) {
                                  setSearchString(search_string);
                                }else{
                                if (search_string == "") {
                                  setglobalSearchCloseIcon(false);
                                  GlobalModalSearchHide();
                                }
                                setglobalSearchCloseIcon(true);
                                GlobalModalSearchShow();
                                dispatch({
                                  type: SET_GLOBAL_SEARCH_STRING,
                                  payload: { search_string },
                                });
                                setSearchString("");
                              }
                              }}
                              onSelect={applyStaffUserFilter}
                              onFocus={() => {
                                getMentionUsersForFilter();
                              }}
                              filterFunctionForOptionString={(str) => str.replace("@", "")}
                              actualWidth={true}
                              isCallChatEnable={true}
                            />
                          </div>
                        { (searchString == undefined || searchString == "") && (globalSearchString == undefined || globalSearchString == "") ? <></> :
                        <>
                        <div className="with_separator_10 before-block p-0"></div>
                        <a href="#/"
                            className={`comman_action_icon px-1 d-flex `}
                            onClick={() => {
                              GlobalModalSearchHide();
                              setSearchString("");
                            }}
                          >
                            <div
                              className="d-flex align-items-center action_icon h25w25 shadow-none light-theme-img m-auto"
                              onClick={() => {
                                let search_string = "";
                                setglobalSearchCloseIcon(false);
                                dispatch({
                                  type: SET_GLOBAL_SEARCH_STRING,
                                  payload: { search_string },
                                });
                              }}
                            >
                              <X size={18} className="c-icons" weight="light" />
                            </div>
                          </a>
                        </>
                           }
                           {/* new modal for call */}
                           <a href="#/" className={`d-none comman_action_icon  px-0`} onClick={()=>{setShowCallModal(true)}}>
                            <div className="d-flex align-items-center  action_icon m-auto h32w32 light-theme-img shadow-none"
                              data-bs-toggle="dropdown"
                              data-tip={`${_l("l_bulk_actionsss")}`}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              data-bs-auto-close="outside"
                              aria-expanded="false">
                              <GearSix
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                            </div>
                            
                          </a>
                           {/* new modal for call */}
                          {["Dashboard", "leadPage"].includes(pageName) ? <div className={`with_separator_10 before-block ${globalSearchString == undefined || globalSearchString == "" ? "" : "p-0"}`}></div> : <></>}
                        {["Dashboard", "leadPage"].includes(pageName) && <a href="#/" className={`comman_action_icon  px-0  ${["Dashboard", "leadPage"].includes(pageName) ?  userType == 'staff' && pageName != "leadPage" ? 'd-none' : '' : "d-none"}`}>
                            <div className="d-flex align-items-center  action_icon m-auto h32w32 light-theme-img shadow-none"
                              data-bs-toggle="dropdown"
                              data-tip={`${_l("l_bulk_action")}`}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              data-bs-auto-close="outside"
                              aria-expanded="false"
                              onClick={()=>{
                                if (pageName == "leadPage") {
                                  setShowBulkActionForLeads(true);                                  
                                }
                              }}
                              >
                              <ListBulletsIcon 
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                            </div>
                          {pageName !="leadPage"  && <ActionDropdown widthClass="w-170"
                              actions={[
                                {
                                  actionName: _l("l_status"),
                                  actionIcon: "TagIcon",
                                  actionHandler: () => {
                                    // handleTaskBulkAction(0, 'task_convert_to_request')
                                  },
                                },
                                {
                                  actionName: _l("l_assign"),
                                  actionIcon: "UserGearCircleIcon",
                                  actionHandler: () => {
                                    // handleTaskBulkAction(0, 'task_convert_to_request')
                                  },
                                },
                                {
                                  actionName: _l("l_due_date"),
                                  actionIcon: "CalendarBlankIcon",
                                  actionHandler: () => {
                                    // handleTaskBulkAction(0, 'task_convert_to_request')
                                  },
                                },
                                {
                                  actionName: _l("l_comment"),
                                  actionIcon: "ChatCircleIcons",
                                  actionHandler: () => {
                                    // handleTaskBulkAction(0, 'task_convert_to_request')
                                  },
                                },
                                {
                                  actionName: _l("l_delete"),
                                  actionIcon: "TrashIcon",
                                  actionHandler: () => {
                                    // handleTaskBulkAction(1)
                                  },
                                },
                              ]}

                            actionStausChange = {handleTaskBulkAction}
                            />}
                          </a>}
                          {["leadPage"].includes(pageName) && 
                          <UncontrolledDropdown className={`p-10  res pe-0 py-0 comman_action_icon  `}>
                          <div className="d-flex align-items-center  action_icon m-auto light-theme-img shadow-none comman_action_icon justify-content-center">
                            <DropdownToggle tag={'div'} className="d-flex dropdown dropdown-center team-list-dropdown ">
                              <a href="#/"
                                className={`d-flex on-hover-active-toggle-img h32w32  ${selectedleadfilers.length > 0 ? 'activeicon' : ''}`}
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                                data-tip={_l("l_filters")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                >
                                <FadersHorizontal
                                size={18}
                                className="c-icons"
                                weight="light"
                                /> 
                                {selectedleadfilers.length > 0 ?
                                  <span className="badge-custom email-orange d-flex rounded-pill p-0">{selectedleadfilers.length}</span>
                                : <></>}                                  
                              </a>
                              <ReactTooltip />
                            </DropdownToggle>
                            <div toggle={false} className="px-0"> <UnControlMainFiltersDropDown 
                               myrLeadSources ={myrLeadSources}
                               setselectedleadfilers = {setselectedleadfilers}
                               selectedleadfilers ={selectedleadfilers}
                              /></div>
                          </div>
                        </UncontrolledDropdown>}
                        {["leadPage"].includes(pageName) && <div className="dropdown-center dropdown with_separator_10 before-dnone d-flex align-items-center card-view-less-padding close-toast">
                         <a href="#/" className="comman_action_icon w-100"
                           onClick={() => {
                               setBulkEmailSendShow(true);
                            }
                          }
                           data-tip={_l("l_bulk_email")}
                           data-effect="solid"
                           data-delay-show='1000'
                           data-class="tooltip-main"
                           data-for ={randomID}
                         >
                           <div className="d-flex align-items-center w-100">
                             <div className="action_icon h32w32 on-hover-active-toggle-img light-theme-img">
                               <EnvelopeSimple size={18} weight="light" className="c-icons" />
                             </div>
                           </div>
                         </a>
                       </div>
                        }
                          <a href="#/" className={`comman_action_icon with_separator_10 px-0  d-none ${pageName == "Dashboard" ? globalView == "calendarView" ? "d-none" : "" : "d-none"}`}>
                            <div className="d-flex align-items-center action_icon m-auto h32w32 light-theme-img shadow-none"
                              data-bs-toggle="dropdown"
                              data-tip={`${_l("")}`}
                              data-place="left"
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              data-bs-auto-close="outside"
                              aria-expanded="false">
                              <Funnel
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                            </div>
                            <ActionDropdown widthClass="w-170"
                              actions={[
                                {
                                  actionName: _l("l_day_view"),
                                  actionIcon: "DayViewIcon",
                                  active:dateView == "dayView",
                                  actionHandler: () => {
                                    dispatch(setSelectedDateView("dayView"));
                                    jquery(".dropdown-menu").removeClass("show");
                                  },
                                },
                                {
                                  actionName: _l("l_week_view"),
                                  actionIcon: "WeekViewIcon",
                                  active:dateView == "weekView",
                                  actionHandler: () => {
                                    dispatch(setSelectedDateView("weekView"));
                                    jquery(".dropdown-menu").removeClass("show");
                                  },
                                },
                                {
                                  actionName: _l("l_month_view"),
                                  actionIcon: "CalendarBlankIcon",
                                  active:dateView == "monthView",
                                  actionHandler: () => {
                                    dispatch(setSelectedDateView("monthView"));
                                    jquery(".dropdown-menu").removeClass("show");
                                    // var filter_key = "date_range_filter";
                                    // var title =
                                    //   moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() +1 ))).format("DD/MM/YYYY") +
                                    //   " - " +
                                    //   moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7))).format("DD/MM/YYYY");
                                    // dispatch(
                                    //   leftPanelFilters(
                                    //     {
                                    //       main_key: "date_range_filter",
                                    //       filter_key: filter_key,
                                    //       title: title,
                                    //     },
                                    //     "add"
                                    //   )
                                    // );
                                  },
                                }
                              ]}
                            />
                          </a>
                          {/* { (globalView != "spaceView" && globalView != "kanbanView") && (pageName == "Dashboard"  || (pageName == "ChatAi" || globalView == "AichatView")) ?  */}
                          {/* { contact_role != 3 && pageName == "dashboardnew"? 
                          <>
                          <div className="with_separator_10 before-block p-0"></div>
                          <div className="dropdown h-auto h35w35">
                            <a href="#/" className="comman_action_icon w-100 h-100 shadow-none"
                              type="button"
                              id="GeneralListing"
                              data-bs-toggle="dropdown"
                              // data-bs-auto-close="outside"
                              aria-expanded="false"
                              data-tip={`${ _l("l_view") + " "+_l('l_change')}`}
                              data-effect="solid"
                              data-delay-show='1000'
                              data-class="tooltip-main"
                              >
                              <div className="d-flex align-items-center h32w32 action_icon  light-theme-img shadow-none">
                                <SlidersHorizontal
                                  size={18}
                                  className="c-icons"
                                  weight="light"
                                />
                              </div>
                            </a>
                            <ToggleViewDropdown 
                            pageName={pageName}
                            />
                            {/* <GeneralListing
                              list={[1, 2, 3].map((index) => ({
                                name: `filter ${index}`,
                                image: "",
                                id: index,
                              }))}
                            /> */}
                          {/* </div>
                          </> : <></>}  */}
                          {/* : <></>} */}
                          {
                          // pageName == "Dashboard" || pageName =="MySpace" ? 
                          (pageName == "Dashboard" || globalView == "spaceView") && projectId > 0 ?
                              <>
                              <div className="with_separator_10 before-block p-0"></div>
                                <div className={`comman_action_icon with_separator_10 px-0 h35w35 d-flex`}>
                                  <a href="#/"
                                    className="d-flex align-items-center action_icon h-100 w-100 shadow-none light-theme-img m-auto "
                                    type="button"
                                    id="FloorFilterDropdown"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside"
                                    aria-expanded="false"
                                    data-tip={_l("l_floor_fliter")}
                                    data-effect="solid"
                                    data-delay-show='1000'
                                    // data-for={randomID}
                                    data-class="tooltip-main"
                                    data-place="top"
                                  >
                                    <StepIcon className="HW14 opacity-75 " />
                                  </a>
                                  <FloorFilterDropdown />
                                </div>
                              </>
                            : <></>}
                        </div>
                      ) : pageName === "Chat" || pageName === "MyProfile" ? (
                        <></>
                      ) : localStorage.getItem("is_registered") == 1 ? (
                        <>
                        <span className="c-font fw-semibold title-fonts py-2 f-20">{chatPlanTittle.package_name ?chatPlanTittle.package_name : packagePlan.package_name }</span>
                        </>
                      ) :(
                        <a href="#/"
                          className={`header-logo position-center-element  ${
                            preEstiPageDisable == true ? "with_overlay" : ""
                          }`}
                          onClick={() => {
                            if (preEstiPageDisable == true) {
                              return;
                            }
                            navigate("/");
                            onPagechangeToggle();
                          }}
                        >
                         {LightTheme ? < LogoGreenLight className="header-logo-img "/> :  <LogoGreenDark  className="header-logo-img"/>}
                        </a>
                      )}
                    {/* <div
                      className={`daily-schedule-main m-0 daily-schedule-transform ${isBackdrop ? "z-index-3imp" : ""}`}
                    >
                      <div
                        className={`commn-search d-flex justify-content-between radius_3 position-relative z-index-25 `}
                      >
                        <div className="m-auto ps-2">
                          <MagnifyingGlass
                            size={18}
                            className="c-icons"
                            weight="light"
                          />
                        </div>
                        <div className="comment-input-box with_separator_10 before-dnone flex-grow-1">
                          <input
                            type="text"
                            className="form-control bg-transparent bg-transparent border-0 h-35 px-0"
                            placeholder={`${_l("l_hello_how_can_i_help_you")}`}
                            onClick={() => {
                              GlobalModalSearchShow();
                            }}
                            onChange={(e) => {
                              let search_string = e.target.value;
                              if (search_string == "") {
                                setglobalSearchCloseIcon(false);
                                GlobalModalSearchHide();
                              }
                              setglobalSearchCloseIcon(true);
                              dispatch({
                                type: SET_GLOBAL_SEARCH_STRING,
                                payload: { search_string },
                              });
                            }}
                            value={globalSearchString}
                          />
                        </div>

                        <a href="#/" className="comman_action_icon with_separator_10 d-flex align-items-center ">
                          <div
                            className="d-flex align-items-center h-100 action_icon h25w25 shadow-none light-theme-img"
                            onClick={() => {
                              let search_string = "";
                              setglobalSearchCloseIcon(false);
                              dispatch({
                                type: SET_GLOBAL_SEARCH_STRING,
                                payload: { search_string },
                              });
                            }}
                          >
                            <X size={18} className="c-icons" weight="light" />
                          </div>
                        </a>
                        <a href="#/"
                          className={`comman_action_icon with_separator_10 px-1`}
                        >
                          <div className="d-flex align-items-center h-100 action_icon h25w25 light-theme-img">
                            <GearSix
                              size={18}
                              className="c-icons"
                              weight="light"
                            />
                          </div>
                        </a>
                        <div className="dropdown h-auto">
                          <a href="#/" className="comman_action_icon px-1 pe-0 h-100"
                            type="button"
                            id="GeneralListing"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            data-tip={`${_l('l_view_all')}`}
                            data-effect="solid"
                            data-delay-show='1000'
                            data-class="tooltip-main"
                            >
                            <div className="d-flex align-items-center h-100 action_icon h25w25 light-theme-img">
                              <SlidersHorizontal
                                size={18}
                                className="c-icons"
                                weight="light"
                              />
                            </div>
                          </a>
                          <GeneralListing list={[1, 2, 3].map((index) => {
                            return {
                              name: `Day ${index}`,
                              image:"",
                              id: index,
                            };
                          />
                        </div>
                      </div>
                      {showGlobalSerachModal ? (
                        <GlobalSearchModal
                          show={showGlobal}
                          showGlobalSerachModal={showGlobalSerachModal}
                          setShowGlobalSerachModal={setShowGlobalSerachModal}
                          handleClose={() => {
                            setShowGlobalSerachModal(false);
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div> */}
                    </div>
                  {/* Global Search HTML over */}
                </div>
                <div className="right-part">
                  <div className="d-flex align-items-center justify-content-end comman_action_icon">
                    <Button
                      variant="primary"
                      size="sm"
                      className="d-none"
                      onClick={() => {
                        // setEquipementModalHTML(true);
                        setAttechmentViewerHandle(true);
                      }}
                    >
                      asda
                    </Button>
                    <div
                      className={`link-part ${
                        NoHeaderIconPage.indexOf(pageName) > -1 ? "d-none" : ""
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <a href="#/"
                          data-tip={`${_l("l_calendar")}`}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-for={randomID}
                          data-class="tooltip-main"
                          id="HeaderCalendar"
                          className={`h-right-part-link on-hover-active-toggle-img res-d-none-imp header-calendar d-none ${
                            CalendarPageActiveClass ? "active" : ""
                          }  ${
                            preEstiPageDisable == true ? "for-disabled" : ""
                          }  ${
                            pageName === "dashboard" ||
                            pageName === "PremiumSubscription" ||
                            pageName == "ConnectProvider" ||
                            globalView == "liteVersionView"
                              ? "d-none"
                              : ""
                          }`}
                          onClick={() => {
                            if (preEstiPageDisable == true) {
                              return;
                            }
                            navigate("/calendar");
                            onPagechangeToggle();
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            {/* <CalendarWhiteIcon className="img-width-24" /> */}
                            {/* <CalendarBlankIcon className="white  HW24" /> */}
                            <CalendarBlank
                              size={24}
                              weight="light"
                              className="c-icons"
                            />
                            <span className="d-block c-font f-10 fw-light hover-span color-white-80 line-height-1 pt-1">
                              {_l("l_calendar")}
                            </span>
                          </div>
                        </a>
                        <a href="#/"
                          id="HeaderDocumentLinkDropdown"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          data-tip={`${_l("l_drive")}`}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-for={randomID}
                          data-class="tooltip-main"
                          aria-expanded="false"
                          ref={ref}
                          className={`h-right-part-link on-hover-active-toggle-img res-d-none-imp active-dropdown border-0 d-none ${
                            preEstiPageDisable == true ? "for-disabled" : ""
                          }   ${DocumentPageActiveClass ? "active" : ""} ${
                            showDocumentsNavigator && contact_role != 3
                              ? ""
                              : "d-none"
                          } ${
                            ["dashboard",  "PremiumSubscription", "ConnectProvider", "Providers"].includes(pageName)
                              ? "d-none"
                              : ""
                          } ${globalView == "liteVersionView" ? "d-none" : ""}`}
                          onClick={() => {
                            if (preEstiPageDisable == true) {
                              return;
                            }
                            navigate("/documents");
                            onPagechangeToggle();
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            {/* <FileText size={24} weight="light" className="c-icons" /> */}
                            <HardDrives
                              size={24}
                              weight="light"
                              className="c-icons"
                            />
                            <span className="d-block c-font f-10 fw-light hover-span color-white-80 line-height-1 pt-1">
                              {_l("l_drive")}
                            </span>
                          </div>
                        </a>
                        {/* <HeaderDocumentLinkDropdown /> */}
                        <a href="#/"
                          data-tip={`${_l("l_support")}`}
                          data-for={randomID}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          className={`h-right-part-link on-hover-active-toggle-img res header-support comman_action_icon ms-10px d-none`}>
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 with_bg">
                            <PhoneCall
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </a>
                        <a href="#/"
                          data-tip={`${_l("l_support")}`}
                          data-for={randomID}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          className={`h-right-part-link on-hover-active-toggle-img res header-support comman_action_icon ms-10px d-none`}>
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 with_bg">
                            <PhoneCall
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                          </div>
                        </a>
                        <a href="#/"
                          data-tip={`${_l("l_support")}`}
                          data-for={randomID}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          className={`p-10 on-hover-active-toggle-img res pe-0 py-0 d-none ${
                            ChatPageActiveClass ? "active" : ""
                          } ${
                            preEstiPageDisable == true ? "for-disabled" : ""
                          } ${showSupportNavigator ? "" : "d-none"} ${
                            [
                              "dashboard",
                              "PremiumSubscription",
                              "ChatAi"
                            ].includes(pageName)
                              ? "d-none"
                              : ""
                          }`}
                          onClick={() => {
                            dispatch(selectedChatUser(undefined));
                            if (globalView == "liteVersionView" ) { 
                              dispatch(setAllChatUserList(false));
                              localStorage.setItem("activeGroupId","")
                              // localStorage.removeItem("selectedOffice")
                              // localStorage.setItem("isLiteVersion", true)
                            }else{
                              // localStorage.setItem("generalChatForLiteVersion",false);
                            }
                            if (preEstiPageDisable == true) {
                              return;
                            }
                            if (localStorage.getItem("selectedOffice") > 0) {
                              localStorage.setItem("isLiteVersion", false)
                              localStorage.setItem("generalChatForLiteVersion",false);

                            }else{
                              localStorage.setItem("isLiteVersion", true)
                              localStorage.setItem("generalChatForLiteVersion",true);

                            }
                            navigate("/chat");
                            dispatch(setOpenGeneralChatGroup(false));
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon with_bg h30w30">
                          <div
                              className={`rounded-pill badge-custom ${displayUnreadMsgCount}`}
                            >
                              {globalView == "liteVersionView" && localStorage.getItem("isLiteVersion") == "true" ? generalUnreadCount : unreadMsgCount}
                            </div>
                            <Chat
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                            {/* <span className="d-block c-font f-10 hover-span color-white-60 line-height-1 pt-1 res-d-none-imp">
                              {_l("l_support")}
                            </span> * /}
                          </div> */}
                          </div>
                        </a>
                        {/* {pageName==="ChatAi" ? */}
                        <>
                        {/* ------------------------------------------- metrics dropdown start  ----------------------------------------------------------------------------------- */}
                        {/* {taskFilters && taskFilters.some((key) => key.main_key) ?  */}
                        <>
                       {!ONBOARDING_PAGES.includes(location.pathname) && userType == "operator" && taskFilters && taskFilters.some((key) => key.main_key == "providers") ?  
                       <UncontrolledDropdown className={`p-10  res pe-0 py-0`}>
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon with_bg h30w30">
                            <DropdownToggle tag={'div'} className="d-flex dropdown dropdown-center team-list-dropdown ">
                              <a href="#/"
                                className="d-flex on-hover-active-toggle-img"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                                data-tip={_l("l_metrics")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                >
                                <Gauge  size={18}  weight="light" className="c-icons" />                                    
                              </a>
                              <UncontrolledGeneralListing
                              list={dashBoradFilter.filter((item) => item.is_active == 1).map((data)=>{
                                return{
                                  title : data.slug,
                                  id : data.id
                                }})}
                                fromChat={false}
                                forCall={false}
                                fromAichat={false}
                                ismatrix={true}
                                row1={"l_avg_titme_to_answer"}
                                row2={"l_avg_titme_to_fix"}
                                row3={"l_satisfaction"}
                                row4={"l_task_score"}
                                row5={null}
                              /> 
                              <ReactTooltip />
                            </DropdownToggle>
                          </div>
                        </UncontrolledDropdown>  : <></>}
                        {/* ------------------------------------------- metrics dropdown end  ----------------------------------------------------------------------------------- */}

                        {/* ------------------------------------------- providerManager dropdown start  ------------------------------------------------------------------------------ */}
                        
                       {!ONBOARDING_PAGES.includes(location.pathname) && userType == "operators"  ? 
                       <UncontrolledDropdown className={`p-10  res pe-0 py-0`}>
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon with_bg h30w30">
                            <DropdownToggle tag={'div'} className="d-flex dropdown dropdown-center team-list-dropdown ">
                              <div
                                className="d-flex on-hover-active-toggle-img"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                                data-tip={_l("l_provider_manager")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                >
                                <MagicWand  size={18}  weight="light" className="c-icons" />                               
                              </div>
                              <UncontrolledGeneralListing
                                list={[]}
                                fromChat={false}
                                forCall={false}
                                fromAichat={false}
                                ismatrix={false}
                                providerManager={true}
                                row1={"l_delivery_of_consumables"}
                                row2={"l_compliance_documents"}
                                row3={"l_satisfaction_level"}
                                row4={"l_on_site_work_duration"}
                                row5={"l_task_completion_rate"}
                              /> 
                              <ReactTooltip />
                            </DropdownToggle>
                          </div>
                        </UncontrolledDropdown> : <></>}
                        {/* ------------------------------------------- providerManager dropdown end  ----------------------------------------------------------------------------------- */}

                        {/* ------------------------------------------- providerBoss dropdown start  ----------------------------------------------------------------------------------- */}
                        {!ONBOARDING_PAGES.includes(location.pathname) && userType == "operators" ?
                         <UncontrolledDropdown className={`p-10  res pe-0 py-0`}>
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon with_bg h30w30">
                            <DropdownToggle tag={'div'} className="d-flex dropdown dropdown-center team-list-dropdown ">
                              <div
                                className="d-flex on-hover-active-toggle-img"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                                data-tip={_l("l_provider_boss")}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                
                              >
                                <Clipboard  size={18}  weight="light" className="c-icons" />                                    
                              </div>
                              <UncontrolledGeneralListing
                                list={[]}
                                fromChat={false}
                                forCall={false}
                                fromAichat={false}
                                ismatrix={false}
                                providerManager={false}
                                providerBoss={true}
                                row1={"l_satisfaction_level"}
                                row2={"l_average_response_time"}
                                row3={"l_number_of_quote_requests"}
                                row4={"l_task_completion_rate"}
                                row5={null}
                              /> 
                              <ReactTooltip />
                            </DropdownToggle>
                          </div>
                        </UncontrolledDropdown> : <></> }
                        </>
                         {/* : <></>} */}
                        {/* ------------------------------------------- providerBoss dropdown end  ----------------------------------------------------------------------------------- */}
                              {!ONBOARDING_PAGES.includes(location.pathname) && pageName != "dashboardnew" && taskFilters && taskFilters.some((key) => key.main_key != "my_task") && taskFilters.filter((key) => key.main_key != "date_range_filter").length == 1  ? <UncontrolledDropdown
                                data-tip={`${_l("l_call")}`}
                                data-for={randomID}
                                data-effect="solid"
                                data-delay-show='1000'
                                data-class="tooltip-main"
                                className={`p-10 on-hover-active-toggle-img res pe-0 py-0`}>
                                  {missedcallCounts > 0 ? <div class="badge-custom bg-danger d-flex  rounded-pill"><span class="color-white ps-0">{missedcallCounts}</span></div> : <></>}
                                <DropdownToggle tag="div" className="d-flex flex-column justify-content-center align-items-center position-relative action_icon with_bg h30w30">
                                  <div className="d-flex rounded-circle dropdown dropdown-center team-list-dropdown ">
                                    <div
                                      className="d-flex"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      data-bs-auto-close="outside"
                                      aria-expanded="false"
                                      data-tip={_l("l_call")}
                                      data-effect="solid"
                                      data-delay-show='1000'
                                      data-class="tooltip-main"
                                      onClick={() => {
                                        if(contact_role != 3 && taskFilters.some((key) =>key.main_key == "my_contact" || key.main_sub_key == "contact")){
                                          let tempArr = groupDetails.members
                                          let templist = tempArr.filter((member) => member._id != localStorage.getItem("orgChatUserId"))
                                          // if (userType == "contact" || userType == "staff") {
                                          //   let contactid = defaultTopics.filter((topic) => topic.main_key == groupDetails.extraData.mainKey)[0].categories
                                          //   contactid = contactid.filter((cat) => cat.filter_key == groupDetails.extraData.filterKey)
                                          //   contactid = contactid[0].contact_id ? contactid[0].contact_id : 0
                                          //   templist = templist.filter((member) => member.myrUserId == contactid)
                                          // }
                                          makeCall(templist[0]._id, templist[0].fname + " " + templist[0].lname, groupDetails._id)

                                        }else if (contact_role != 3 && taskFilters.some((key) => key.main_sub_key == "people" || key.main_key == "my_contact" || key.main_sub_key == "contact") || (userType != "contact" && userType != "operator") && contact_role != 3) {
                                          let tempArr = groupDetails.members
                                          let templist = tempArr.filter((member) => member._id != localStorage.getItem("orgChatUserId"))
                                          // if (userType == "contact" || userType == "staff") {
                                          //   let contactid = defaultTopics.filter((topic) => topic.main_key == groupDetails.extraData.mainKey)[0].categories
                                          //   contactid = contactid.filter((cat) => cat.filter_key == groupDetails.extraData.filterKey)
                                          //   contactid = contactid[0].contact_id ? contactid[0].contact_id : 0
                                          //   templist = templist.filter((member) => member.myrUserId == contactid)
                                          // }
                                          makeCall(templist[0]._id, templist[0].fname + " " + templist[0].lname, groupDetails._id)
                                        }
                                      }}
                                    >
                                      <Phone
                                        size={18}
                                        weight="light"
                                        className="c-icons "
                                      />
                                    </div>
                                    {taskFilters.some((key) => key.main_sub_key != "people" ) || ((userType == "contact" || userType == "operator") && contact_role == 3) ?
                                      <UncontrolledGeneralListing
                                        list={groupDetails && groupDetails.members && groupDetails.members.length > 0 && groupDetails.members.map((item, index) => {
                                          let filter_key = ""
                                          let main_key = ""
                                          let main_sub_key = ""
                                          if (defaultTopics && defaultTopics.length > 0) {
                                            defaultTopics.forEach((topic) => {
                                              if (topic.categories) {
                                                topic.categories.forEach((category) => {
                                                  if (topic.main_key !== "myr_support" && category.contact_id && category.contact_id == item.myrUserId) {
                                                    filter_key = category.filter_key;
                                                    main_key = topic.main_key;
                                                    main_sub_key = category.main_sub_key;
                                                  } else if (topic.main_key !== "myr_support" && category.filter_key && category.filter_key == item.myrUserId) {
                                                    filter_key = category.filter_key;
                                                    main_key = topic.main_key;
                                                    main_sub_key = category.main_sub_key;
                                                  }
                                                })
                                              }
                                            })
                                          }
                                          return {
                                            name: item.fname + " " + item.lname,
                                            image: item.displayPicture == "" ? USR_IMG_PLACEHOLDER : ATTACH_BASE_URL + item.displayPicture,
                                            id: index,
                                            isOnline: item.isOnline,
                                            userId: item._id,
                                            userType : item.myrUserType,
                                            myrRole: item.myrRole,
                                            filter_key : filter_key,
                                            main_key : main_key,
                                            main_sub_key :main_sub_key
                                          };
                                        })}
                                        fromChat={false}
                                        forCall={false}
                                        makeCall={makeCall}
                                        fromAichat={true}
                                        callFromGroup={groupDetails && groupDetails._id ? groupDetails._id : ""}
                                      /> : <></>}
                                    <ReactTooltip />
                                  </div>
                                </DropdownToggle>
                                </UncontrolledDropdown> : <></>}
                        {(!ONBOARDING_PAGES.includes(location.pathname) && pageName != "dashboardnew" && (taskFilters && taskFilters.length  > 0 && taskFilters.filter((topic)=> ["created_by_me" , "assigned_to_me" , "pinned",  "overdue" , "myrai_support"].includes(topic.filter_key)).length == 0 ||selectedTopic && selectedTopic.main_key != "my_task" && selectedTopic.id != "myrai_support")) && taskFilters.filter((key) => key.main_key != "date_range_filter").length == 1 ? <a href="#/"
                          data-tip={`${_l("l_setting")}`}
                          data-for={randomID}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                          className={`p-10 on-hover-active-toggle-img res pe-0 py-0 position-relative`}
                          onClick={()=>{setshowSettingModal(true);}}
                          >
                            { totalNotificationCount > 0 ? <div class="badge-custom  d-flex rounded-pill"><span class="ps-0">{totalNotificationCount}</span></div> : <></>}
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon with_bg h30w30">
                            <GearSix
                              size={18}
                              weight="light"
                              className="c-icons"
                            />
                            {/* <span className="d-block c-font f-10 fw-light hover-span color-white-80 line-height-1 pt-1 res-d-none-imp">
                              {_l("l_setting")}
                            </span> */}
                          </div>
                        </a> : <></>}
                    
                        </> 
                        {/* : <></>         
                        } */}
                      </div>
                    </div>
                    {ONBOARDING_PAGES.includes(location.pathname) ? <></> :
                    <div className={`h-user-part position-relative dropdown dropdown-center d-flex ${pageName == "MyProfile" ?"no-after":""} ${ globalView == "AichatView" ? "ps-2" : ""}`}>
                      <a href="#/"
                        className={`h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle border-dashed p-5px m-auto ${
                          pageName == "MyProfile" ? "border-width2" : ""
                        } ${
                          userType == "staff"
                            ? "staff_user"
                            : userType == "operator"
                            ? "operator_user"
                            : "contact_user"
                        }`}
                        type="button"
                        id="headerUserDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-tip={emailAddress}
                        data-for={randomID}
                        data-effect="solid"
                        data-delay-show='1000'
                        data-class="tooltip-main"
                        data-bs-auto-close="outside"
                        data-place="left"
                      >
                        <div
                          id="userHeaderProfileImage"
                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                          style={{
                            backgroundImage: `url('${loginUserImage}')`,
                          }}
                        ></div>
                      </a>
                      <HeaderUserDropdown ProductTour pageName={pageName} />
                      <ReactTooltip id={randomID} />
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* leadBulk Action */}
          {showBulkActionForLeads ? 
          <BulkActionsModal
            show={showBulkActionForLeads}
            leads={checkedBulkLeads}
            staffList={myrStaff}
            leadSources={leadSources}
            handleClose={() => {setShowBulkActionForLeads(false)}}
            handleShow={() => {setShowBulkActionForLeads(true)}}
            tagesLeads={tagesLeads}
          />
          :<></>}
          {bulkEmailSendShow ? 
          <AddEmailTemplatesModalNew
             show={bulkEmailSendShow}
             handleClose={modalhandleleadClose}
             handleShow={modalhandleleadShow}
             checkedBulkLeads = {checkedBulkLeads}
             bulkEmail = {true}
          />
          : <></>}
          {/* leadBulk Action */}
          { !jquery.isEmptyObject(callStatus) 
              ? callStatus.status == "initCall"
                ? <FeedChatCallModal show={true} callStatus={callStatus} handleClose={()=>{}} />
                : <FeedChatAudioCall callStatus={callStatus} />
                // : <AudioCall callStatus={callStatus} />
              : <></>
          }
          {/* <FeedChatAudioCall /> */}
          <audio id="my-call-audio" className="d-none">
            <source src={myCallAudio} type="audio/mpeg" />
          </audio>
          <audio id="my-call-accept-audio" className="d-none">
            <source src={myCallAcceptAudio} type="audio/mpeg" />
          </audio>
          <audio id="my-call-end-audio" className="d-none">
            <source src={myCallEndAudio} type="audio/mpeg" />
          </audio>
          <audio id="my-call-recording-audio" className="d-none">
            <source src={myCallRecordingAudio} type="audio/mpeg" />
          </audio>
          {attechmentViewerHandle ? (
            <AttechmentViewerMain
              FileType="mp4"
              show={attechmentViewerHandle}
              handleClose={() => {
                setAttechmentViewerHandle(false);
              }}
            />
          ) : (
            <></>
          )}
          {equipementModalHTML ? (
            <EquipmentModalHTML
              show={equipementModalHTML}
              handleClose={() => {
                setEquipementModalHTML(false);
              }}
            />
          ) : (
            <></>
          )}
          {showPasswordSetModal ? (
            <SetUserPassword handleClose={() => {setShowPasswordSetModal(false)}} />
          ) : (
            <></>
          )}
        </div>
      )}

{
        showSettingModal && Math.abs(selectedTopic.id) > 0
        ?
        <TopicSettingModal 
        data={selectedTopic} 
        show={Math.abs(selectedTopic.id) > 0} 
        handleClose={() => {
          setshowSettingModal(false);
          // setJSON.parse(localStorage.getItem("selectedTopic"))({id: 0, title: "", main_key: "", is_active: "", main_key_title: "", main_sub_key: ""})
        }} 
          title={selectedTopic.main_key_title} 
          titlePrefix={"l_settings"} 
          />
        :
        <React.Fragment></React.Fragment>
      }

      <ChatGroupDetailModal     
        show={show}
        handleClose={()=>{
          setShow(false)
        }}
        handleShow={()=>{
          setShow(true)
        }}
        groupList={groupList}
        groupDetails={groupDetails}
        viewGroupDetails={viewGroupDetails}
        isGroupAdmin={isGroupAdmin}
        groupMemberIds={groupMemberIds}
        setGroupMemberIds={setGroupMemberIds}
        previewImage={previewImage}
        fromaichat={ globalView == "AichatView" ? true : false}
        providerOpearator={providerOpearator}
        preparePreview={preparePreview}
        topicLogo={topicLogo}
      />
      <audio id="my-msg-audio" className="d-none">
        <source src={myMsgAudio} type="audio/mpeg" />
      </audio>
    </>
  );
};
export default Header;
