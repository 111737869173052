import moment from "moment";
import { dataURItoBlob, getItemId } from "../hooks/utilities";
import store from "../store";
import apiService from "./api-service/api-service";

const API_URL = process.env.REACT_APP_API_URL;
const user_type = localStorage.getItem("user_type");
const selectedProject = store.getState().customer.selectedProject;

const getIBANlisting = () => {
    const endPoint = API_URL + "mod=User_payment&act=get_user_gocardless_account_list";
    let form = new FormData();
    // form.append("webX", webX);
    return apiService(endPoint, "POST", true, form);
}

const setNewIBANForWallet = (
    account_iban, 
    company_address, 
    city, 
    postal_code, 
    estimate_id = 0, 
    benchmark_id = 0,
    companyName = "",
    firstname = "",
    lastname="",
    email="",
    signature,
    proposal_id = 0,
    date,
    payment_id = 0

) => {
    const endPoint = API_URL + "mod=User_payment&act=setup_payment_account_for_user";
   
    let form = new FormData();
    form.append("account_iban", account_iban);
    form.append("company_address", company_address);
    form.append("city", city); 
    form.append("postal_code", postal_code); 
    form.append("estimate_id", estimate_id); 
    form.append("benchmark_id", benchmark_id); 
    form.append("company_name", companyName); 
    form.append("firstname", firstname);
    form.append("lastname", lastname);
    form.append("email", email);
    if (signature) {
        const file = dataURItoBlob(signature);
        form.append("signature", file, "signature.png");
    }
    if (proposal_id > 0) {
        form.append("proposal_id", proposal_id);
        form.append("date", moment(date).format("YYYY-MM-DD"));
    }
    if (payment_id > 0) {
        form.append("payment_id", payment_id);
    }

    return apiService(endPoint, "POST", true, form);
}
const updatePaymentMethod = (
    estimate_id = 0 , 
    benchmark_id = 0, 
    payment_method_id
) => {
    const endPoint = API_URL + "mod=User_payment&act=update_client_payment_method";

    let form = new FormData();
    form.append("payment_method_id", payment_method_id);
    form.append("estimate_id", estimate_id); 
    form.append("benchmark_id", benchmark_id); 

    return apiService(endPoint, "POST", true, form);
}

export default {
    getIBANlisting,
    setNewIBANForWallet,
    updatePaymentMethod

}