import { Check, User, Users, UsersThree } from "phosphor-react";
import React from "react";
import { _l } from "../../hooks/utilities";
import { Form } from "react-bootstrap";
import Select from "react-select";

const getPhospherIcon = {
  0: <User className={"c-icons stroke-width-5px h60w60"} size={26} />,
  1: <Users className={"c-icons stroke-width-5px h60w60"} size={26} />,
  2: <UsersThree className={"c-icons stroke-width-5px h60w60"} size={26} />,
};

const PeopleSelection = ({ name, people, setPeople }) => {
  return (
    <div className="row my-0 g-4 justify-content-center">
      {Array.from({ length: 3 }).map((element, index) => {
        return (
          <React.Fragment key={index}>
            <div className="space-select-box select-input-cox mx-3 bg-transparent">
              <input
                className="bottom-0 end-0 form-check-input h_230px w-100 position-absolute start-0 top-0 bg-transparent"
                name={name}
                type="radio"
                checked={people == index + 1}
                value={index + 1}
                onChange={(e) => setPeople(e.target.value)}
              />
              <a href="#/" className="check-image">
                <div className="check-image-box">
                  <Check size={14} className="c-icons color-white-03-solid" />
                </div>
              </a>
              <div className="">
                <div className="d-flex bg-white-03 m-auto h_230px radius_3 flex-column">
                  {getPhospherIcon[index]}
                  <div className="text-center p-10">
                    <div className="text-center c-font f-16 p-10 px-0 fw-semibold">
                      {_l(index + 1)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
      <div className="row my-0 g-4 justify-content-center">
        <div className="space-select-box select-input-cox mx-3 bg-transparent" onClick={() => {setPeople(4)}}>
          <input
            className="bottom-0 end-0 form-check-input w-100 position-absolute start-0 top-0 bg-transparent"
            name={name}
            type="radio"
            value={4}
            checked={people == 4}
            onChange={(e) => setPeople(e.target.value)}
          />
          <a href="#/" className="check-image">
            <div className="check-image-box">
              <Check size={14} className="c-icons color-white-03-solid" />
            </div>
          </a>
          <div className="">
            <div className="d-flex bg-white-03 m-auto  radius_3 flex-column">
              <div className="text-center p-10">
                <div className="text-center c-font f-16 p-10 px-0 fw-semibold">
                  {_l(4)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="space-select-box select-input-cox mx-3 bg-transparent" onClick={() => {setPeople(5)}}>
          <input
            className="bottom-0 end-0 form-check-input w-100 position-absolute start-0 top-0 bg-transparent"
            name={name}
            type="radio"
            value={5}
            checked={people == 5}
            onChange={(e) => setPeople(e.target.value)}
          />
          <a href="#/" className="check-image">
            <div className="check-image-box">
              <Check size={14} className="c-icons color-white-03-solid" />
            </div>
          </a>
          <div className="">
            <div className="d-flex bg-white-03 m-auto  radius_3 flex-column">
              <div className="text-center p-10">
                <div className="text-center c-font f-16 p-10 px-0 fw-semibold">
                  {_l(5)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="space-select-box select-input-cox mx-3">
          <div className="d-flex align-items-center">
            <div className="stand-alone-top p-0">
              <Form.Group
                className={`c-input-box position-relative text-center `}
              >
                <div className="inputWrapper d-flex">
                  <Form.Control
                    // placeholder={placeholder}
                    className="regFormInput text-center"
                    type={"text"}
                    value={people}
                    onChange={(e) => setPeople(e.target.value)}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeopleSelection;
