import React, { useEffect, useState, useRef } from "react";
import Calendar from "../../components/Calendar";
import { useLocation, useNavigate } from "react-router-dom";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import 'intro.js/introjs.css';
import { X,MagnifyingGlass,UserCircleGear,CalendarBlank,Link,CaretDown,EnvelopeSimple,ChatCircle,PushPin,DotsThreeVertical,Prohibit ,Hash} from "phosphor-react";
import jquery from "jquery";
import Accordion from "react-bootstrap/Accordion";
import "react-datepicker/dist/react-datepicker.css";
import CaretDoubleDownIcon from "../../assets/icons/CaretDoubleDownIcon";
import CustomerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import CommanLoader from "../Loader/CommanLoader";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import { TaskCommentModalOpen, _l, hideLeftPanelNew, isCustomerHavePaidSpace, showLeftPanelNew } from "../../hooks/utilities";
import {
   
    getDefaultTopics,
    getTopicTasks,
    fetchMails,
    updateMailCalendarCounter,
    getMailAssigees,
    commanoffcanvasisOpen,
    leftPanelFilters,
    updateSchedualEmail
} from "../../actions/customer";
import { showMessage } from "../../actions/messages";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import { toggleFeatureRestrictionModal } from "../../actions/general-actions/general-actions";
import EmailCard from "../EmailCard";
import { MAIL_DATA } from "../../constants/constants";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { getGroupDetails } from "../../actions/chat";
import GeneralCardView from "../Documents/GeneralCard";
import AddEmailTemplatesModalNew from "../Modals/AddEmailTemplatesModalNew";


const MyEmailList = ({ setAllMAilMessages, setAllImportantMessages,synchronizedMail }) => {
let pageName = "MyEmail" 

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
    const spaces = JSON.parse(localStorage.getItem("spaces"));
    const savedProject = localStorage.getItem("selectedOffice");
    const [projectId, setProjectId] = useState(
        savedProject && savedProject != null
            ? savedProject
            : spaces && spaces.length
                ? spaces[0].project_id
                : 0
    );
    const clearStatusFilter = (flag = true) => {
    };
    
    const ModalClose = () => {
    }
    const [inbox_messages, setInbox_messages] = useState([]);
    const [important_messages, setImportant_messages] = useState([]);
    const [mailToView, setMailToView] = useState(false);
    const [mailDate, seteMaildate] = useState(false);
    const [mailTypeFilter, setMailTypeFilter] = useState('all');
    const { isAppliedUnreadComment, commanoffcanvasIsOpen, defaultTopics } = useSelector((state) => state.customer);
    const [showLoader, setShowLoader] = useState(false);
    const { mailFilters } = useSelector((state) => state.customer);
    const [oldInbx, setOldInbx] = useState([]);
    const [oldImportant, setOldImportant] = useState([]);
    const [mailSearchString, setMailSearchString] = useState("");
    const [refetch, setRefetch] = useState(true);
    const allMails = useRef([]);
    const count = useRef(0);
    const { importantMessages, inboxMessages, statusClose,mailNextPageToken, taskFilters, spamMessage, priority_messages, mailResultSizeEstimate, schedule_messages } = useSelector((state) => state.customer);
    const stateTopicData = useSelector((state) => state.customer);
    const [selectedMailId, setSelectedMailId] = useState(0);
    const [mailType,setMailType] = useState('gmail');
  const [showDefaultIcons, setShowDefaultIcons] = useState([]); 
  const [selectedTaskIdforIconToggle, setSelectedTaskIdforIconToggle] = useState(0);
  const [prirotyMails, setPrirotyMails] = useState([])
  const [messagesPage, setMessagesPage] = useState(1);
  const [bulkEmailSendShow, setBulkEmailSendShow] = useState(false);
  const [schedualEmailData, setschedualEmailData] = useState({})

  const email_filter_key = localStorage.getItem("email_filter_key");

    useEffect(() => {
        setStatesOfMails(inboxMessages, importantMessages, spamMessage);
    }, [importantMessages, inboxMessages, spamMessage, statusClose])
    useEffect(() => {
        if(!isCustomerHavePaidSpace())
        {
            navigate(location.state && location.state.from ? location.state.from : "/");
            dispatch(toggleFeatureRestrictionModal(true));
        }
        count.mailReqCount = 0;

        dispatch(leftPanelFilters({ 
            filter_key : "inbox",
            image : "",
            main_key : "my_emails",
            main_sub_key : "",
            sub_category : [],
            title : "l_inbox"
        }));
        dispatch(getGroupDetails({}));
    }, [])

    const extractEmails = (text) => {
        return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    }

    useEffect(() => {
        if (mailFilters) {
            if (refetch == true) {
                dispatch(fetchMails(taskFilters)).then((res)=>{
                    setShowLoader(false);
                });
                setRefetch(true);
                setShowLoader(true);
            } else {
                setStatesOfMails();
                setRefetch(true);
            }
        }
    }, [mailDate, isAppliedUnreadComment, mailFilters, taskFilters]);

    const fetchMailListings = () => {
        setShowLoader(true);
        CustomerServices.getEmails().then((res) => {
            if (res.status == 1) {
                var inm = res.data.inbox_messages;
                var imm = res.data.important_messages;

                setStatesOfMails(inm, imm);
                
            }
            setShowLoader(false);
        });
    }

    useEffect(() => {
        setStatesOfMails();
    }, [mailTypeFilter, mailSearchString])

    const setStatesOfMails = (inm = oldInbx, imm = oldImportant, spamMessage) => {
        setOldInbx(inm);
        setOldImportant(imm);
        setAllMAilMessages([...inm, ...imm]);
        allMails.current = [...inm, ...imm];
        allMails.mailFilters = mailFilters;
        var _imm = imm;
        var _inm = inm
        let inbox_tag = []

        
        if ((mailFilters || []).length > 0 && !localStorage.getItem("email_filter_email")) {
            
            // inm = inm.filter((message) => {
            //     return !mailFilters.includes(extractEmails(message['conversation']['messages'][0]['payload']['headers'].filter((filter) => filter['name'] == 'From')[0]['value'])[0])
            // });
            if (mailTypeFilter == "spam" ) {
                inm = spamMessage;
            }else if (mailTypeFilter != 'important') {
                imm = imm.filter((message) => !mailFilters.includes(extractEmails(message['conversation']['messages'][0]['payload']['headers'].filter((filter) => filter['name'] == 'From')[0]['value'])[0]));
            }
            inm = inm && inm.length &&  inm.filter(
                (conversation) =>
                    conversation["conversation"][
                        "messages"
                    ].filter(
                        (message) =>
                        ((message["labelIds"] && message["labelIds"].includes(
                            localStorage.getItem("email_filter_key").toUpperCase()
                        ) == true || (localStorage.getItem("email_filter_key") == 'all' && message["labelIds"].includes(
                            'INBOX'
                        ) == true)) && ((['INBOX', 'IMPORTANT', 'SENT', 'DRAFT'].filter((type) => type != localStorage.getItem("email_filter_key").toUpperCase())).includes(
                            localStorage.getItem("email_filter_key").toUpperCase()
                        ) == false))
                    ).length > 0
            )

        } else if ((mailFilters || []).length > 0 && localStorage.getItem("email_filter_email")) {

            inm = inm.filter((message) => JSON.parse(localStorage.getItem("email_filter_email")).includes(extractEmails(message['conversation']['messages'][0]['payload']['headers'].filter((filter) => filter['name'] == 'From')[0]['value'])[0]));
            if (mailTypeFilter != 'important') {
                imm = imm.filter((message) => JSON.parse(localStorage.getItem("email_filter_email")).includes(extractEmails(message['conversation']['messages'][0]['payload']['headers'].filter((filter) => filter['name'] == 'From')[0]['value'])[0]));
            }
        }
        
        setAllImportantMessages(_imm);

        if (mailSearchString) {
            inm = inm.filter(item => (item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Subject'))[0]['value'].toLowerCase().includes(mailSearchString.toLowerCase()) == true || (item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'].toLowerCase().includes(mailSearchString.toLowerCase()) == true || (extractEmails((item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'].toLowerCase())[0] == mailSearchString.toLowerCase()));

            imm = imm.filter(item => (item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Subject'))[0]['value'].toLowerCase().includes(mailSearchString.toLowerCase()) == true || (item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'].toLowerCase().includes(mailSearchString.toLowerCase()) == true || (extractEmails((item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'].toLowerCase())[0] == mailSearchString.toLowerCase()));

        }
        const tagTaskFilters = taskFilters.filter((tag) => tag.main_key == 'my_emails');
        if (tagTaskFilters && tagTaskFilters[0] && tagTaskFilters[0].main_key == "my_emails"  && tagTaskFilters[0].filter_key > 0 ) {
            _inm && _inm.forEach((topic) => {
                topic.thread.custom_topic.map((tag) => {
                    if (tag.tag_id == tagTaskFilters[0].filter_key) {
                        inbox_tag.push(topic)
                    }
                })  
            })
            if (inm.length) {
                inm = inm.concat(inbox_tag);
            }
        }
        setInbox_messages(inm);
        // setInbox_messages(MAIL_DATA);
        // localStorage.setItem("mailData",JSON.stringify(inm))
        setImportant_messages(imm);
        let nameTopic = taskFilters.filter((n) => n.main_key != 'date_range_filter')
        if (count.mailReqCount == 0) {
            window.addEventListener('email_filter', () => {
                setMailTypeFilter(localStorage.getItem("email_filter_key"));
            });
            window.addEventListener('filter_key_close', () => {
                localStorage.setItem("email_filter_key", "all");
                localStorage.setItem("email_filter_email", "");
                setMailTypeFilter(localStorage.getItem("email_filter_key"));
            });

            window.addEventListener('bulkAddCustomTopic', () => {
                bulkAddCustomTopic();
            });
            count.mailReqCount = count.mailReqCount + 1;
        }
        if (nameTopic[0] && nameTopic[0].filter_key > 0) {
            setMailTypeFilter(nameTopic[0].title);
        }
        dispatch(updateMailCalendarCounter(inm,imm,mailTypeFilter));
        
    }
    const showMailDetailsMessage = (show_documentCanvas, mailToView ,is_inbox) => {
        mailToView['thread']['is_inbox'] = is_inbox;
        setShowDocumentCanvas(show_documentCanvas);
        setMailToView(mailToView);
        hideLeftPanelNew();
        TaskCommentModalOpen();
        dispatch(commanoffcanvasisOpen(true))
        setSelectedTaskIdforIconToggle(mailToView['conversation']['id'])
        if(show_documentCanvas){
        }
        dispatch(getMailAssigees(mailType,mailToView['thread']['id']));
        CustomerServices.markAsRead(mailToView['thread']['id']).then((res) => {
            if (res.status == 1) {
              var inm = oldInbx.map((mail) => {
                if (mail['thread']['id'] == mailToView['thread']['id']) {
                  mail['conversation']['messages'].map((mes) => {
                    var index = mes['labelIds'].indexOf('UNREAD');
                    if (index != -1) {
                      delete mes['labelIds'][index];
                    }
                    return mes;
                  });
                  mail['thread']['mentions'] = [];
                }
                return mail;
              });
              setOldInbx(inm);
              var imm = oldImportant.map((mail) => {
                if (mail['thread']['id'] == mailToView['thread']['id']) {
                  mail['conversation']['messages'].map((mes) => {
                    var index = mes['labelIds'].indexOf('UNREAD');
                    if (index != -1) {
                      delete mes['labelIds'][index];
                    }
                    return mes;
                  });
                  mail['thread']['mentions'] = [];
                }
                return mail;
              });
              setOldImportant(imm);
              setStatesOfMails(inm, imm);

              var ain = inboxMessages.map((mail) => {
                if (mail['thread']['id'] == mailToView['thread']['id']) {
                  mail['conversation']['messages'].map((mes) => {
                    var index = mes['labelIds'].indexOf('UNREAD');
                    if (index != -1) {
                      delete mes['labelIds'][index];
                    }
                    return mes;
                  });
                  mail['thread']['mentions'] = [];
                }
                return mail;
              });
              var alim = importantMessages.map((mail) => {
                if (mail['thread']['id'] == mailToView['thread']['id']) {
                  mail['conversation']['messages'].map((mes) => {
                    var index = mes['labelIds'].indexOf('UNREAD');
                    if (index != -1) {
                      delete mes['labelIds'][index];
                    }
                    return mes;
                  });
                  mail['thread']['mentions'] = [];
                }
                return mail;
              });

              setAllMAilMessages(ain, alim);
            }
          });
    }

    const closeEmail = (message_id) => {
        CustomerServices.closeEmail(message_id).then((res) => {
            if (res.status == 1) {
                markImportantEmailToClose(message_id);
            }
        });
    }

    const markImportantEmail = (message_id, message_date) => {
        CustomerServices.markImportantEmail(message_id, message_date).then((res) => {
            if (res.status == 1) {
                swapMailToImportant(message_id);
            }
        });
    }
    const htmlDecode = (content) => {
        let e = document.createElement('div');
        e.innerHTML = content;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    const swapMailToImportant = (message_id, saverFromInbox = false) => {
        const ibm = oldInbx.filter(item => item['thread']['id'].trim() != message_id.trim());
        const res = oldInbx.filter(item => item['thread']['id'].trim() == message_id.trim());
        if (res && res.length > 0) {
            
        
        res[0]['thread']['is_saver'] = saverFromInbox ? 1 : 0;
        
        res[0]['thread']['is_inbox'] = 0;
        res[0]['thread']['is_important'] = 1;

        setOldInbx(ibm);
        setOldImportant([...res, ...oldImportant]);
        setStatesOfMails(ibm, [...res, ...oldImportant]);
        }
    }
    const markImportantEmailToClose = (message_id) => {
        const res = important_messages.map(item => {
            if (item['thread']['id'].trim() == message_id.trim()) {
                item['thread']['is_closed'] = 1;
            }
            return item;
        });
        setImportant_messages(res);
    }
    const UpdateMessage = (message_id, status, column) => {
        CustomerServices.UpdateMessage(message_id, status, column).then((res) => {
            if (res.status == 1) {
                if (column == 'un_important') {
                    swapMailToIbox(message_id);
                } else {
                    UpdateMessageIsState(message_id, status, column);
                }
            }
        });
    }
    const UpdateMessageIsState = (message_id, status, column) => {
        const res = important_messages.map(item => {
            if (item['thread']['id'].trim() == message_id.trim()) {
                item['thread'][column] = status;
            }
            return item;
        });

        setImportant_messages(res);
    }

    useEffect(() => {
        if (mailFilters) {
            window.addEventListener('gmail_exchange', () => {
                dispatch(fetchMails(taskFilters));
            });
        }
    }, [mailFilters])

    const swapMailToIbox = (message_id) => {
        const imm = oldImportant.filter(item => item['conversation']['id'] != message_id);
        const res = oldImportant.filter(item => item['conversation']['id'] == message_id);
        if (res && res.length > 0) {        
        res[0]['thread']['is_important'] = 0;
        res[0]['thread']['is_inbox'] = 1;
        setOldInbx([...res, ...oldInbx]);
        setOldImportant(imm);
        setStatesOfMails([...res, ...oldInbx], imm);
        }
    }
    const saverFromInbox = (message_id, message_date) => {
        CustomerServices.saverFromInbox(message_id, message_date).then((res) => {
            if (res.status == 1) {
                swapMailToImportant(message_id, true);
            }
        });
    }

    useEffect(() => {
        window.addEventListener('mailSyncRevoked', () => {
            setStatesOfMails([], []);
        });
        window.addEventListener('addEditCategoryModalClose', () => {
            setRefetch(false);
        });
    }, []);

    const addCustomTopic = (mail, title) => {
        if (mail != '' && title != '') {
            CustomerServices.addCustomTopic(projectId, 'my_customs', mail, title, 0).then((res) => {
                if (res.status == 1) {
                    handleAddCMTopic({ id: res.data.tag_id ? res.data.tag_id : 0, name: title, email: mail });
                }
            });
        } else {
            dispatch(showMessage('unsucess', _l("l_error"), _l("l_please_enter_valid_topic")));
            return false;
        }
    }
    const handleAddCMTopic = (category) => {
        setRefetch(false);
        // dispatch(getDefaultTopics(projectId, "", "", 'MyEmail'));
        // dispatch(getTopicTasks(projectId, "MyEmail"));
    };
    const bulkAddCustomTopic = () => {
        setShowLoader(true);
        var imm = allMails.current.filter((message) => !allMails.mailFilters.includes(extractEmails(message['conversation']['messages'][0]['payload']['headers'].filter((filter) => filter['name'] == 'From')[0]['value'])[0]));
        var topics = [];
        var topicMails = [];
        imm.map((mail) => {
            var from = mail['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From')[0]['value'];
            var title = htmlDecode(from);
            var mail = extractEmails(from)[0];
            if (!topicMails.includes(mail)) {
                topicMails.push(mail);
                topics.push({ 'title': title, 'mail': mail });
            }
        });
        topics = JSON.stringify(topics);
        CustomerServices.bulkAddCustomTopic(topics, projectId).then((res) => {
            if (res.status == 1) {
                setRefetch(false);
                dispatch(getDefaultTopics(projectId, "", "", 'MyEmail'));
                dispatch(getTopicTasks(projectId, "MyEmail"));
                setShowLoader(false);
            }
        });
    }

    const addToSpamTopic = (mail, title) => {
        let pushed = false;
        if (mail != '' && title != '') {
            (stateTopicData.topicData.filter((t)=> t.main_key == 'my_customs')[0]['categories'] || []).map((category)=> {
                if(category.title == 'Spam'){
                    pushed = true;
                    mail = category.email ? category.email +','+ mail : mail;
                    CustomerServices.addCustomTopic(projectId, 'my_customs',mail,category.title,category.filter_key).then((res) => {
                        if (res.status == 1) {
                            handleAddCMTopic({ id: res.data.tag_id ? res.data.tag_id : 0, name: title, email: mail });
                        }
                    });
                }
            });
            if(pushed == false){
                CustomerServices.addCustomTopic(projectId, 'my_customs',mail,'Spam',0).then((res) => {
                    if (res.status == 1) {
                        handleAddCMTopic({ id: res.data.tag_id ? res.data.tag_id : 0, name: title, email: mail });
                    }
                });
            }
            return
        } else {
            dispatch(showMessage('unsucess', _l("l_error"), _l("l_please_enter_valid_topic")));
            return false;
        }
    }

    const handleComponentAction = (type,data) =>{
        if(type == 'UpdateMessage'){
            UpdateMessage(data.id,data.value,data.column);
        }else if(type == 'markImportantEmail'){
            markImportantEmail(data.id,data.value);
        }else if(type == 'saverFromInbox'){
            saverFromInbox(data.id,data.value);
        }else if(type == 'shareEMail'){
            dispatch(getMailAssigees(mailType,mailToView['thread']['id']));
        }
    }
    
    const fetchMoreMails = (token) => {
        setShowLoader(true);
        dispatch(fetchMails(taskFilters, 0,token)).then((res)=>{
            setShowLoader(false);
        });
    }

    const handleAddTag = (data, item) => {
        try {
            let custom_topic = [];
            const index = inbox_messages.findIndex(item => item['conversation']['historyId'] == data['conversation']['historyId']);
            const threadId = data && data.thread && data.thread.id
            custom_topic = data && data.thread && data.thread.custom_topic
            const isTag = custom_topic.findIndex((topic) => topic.tag_id == item.filter_key);
             if (isTag > -1) {
                custom_topic = custom_topic.filter((topic) => topic.tag_id != item.filter_key);
             }else{
                custom_topic = [{tag_id : item.filter_key}].concat(custom_topic);
             }

            CustomerServices.addCustomTopicTags(threadId, custom_topic).then((res) => {
                if (res.status) {
                    let tagsId = []
                    res.data.tag_ids.map((tag) => {
                        tagsId.push({tag_id : tag})
                    })
                    let updatedInboxMessages = inbox_messages[index];
                    updatedInboxMessages['thread'].custom_topic = tagsId.slice();
                    setInbox_messages(
                      Object.values({ ...inbox_messages, [index]: updatedInboxMessages })
                    );
                }
            })
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleToggleIcons = () =>{
        setShowDefaultIcons(["calendar","assigneTo","pin","priority","comment", "disabled", "hash", "mail",])    
      }

      useEffect(() => {
        if (priority_messages && priority_messages.length > 0) {
            let ids = [];
            priority_messages.map((mail)=>{
                ids.push(mail.thread.id)
            })
            setPrirotyMails(ids)
        }else {
            setPrirotyMails([])
        }
        
      }, [JSON.stringify(priority_messages)])

      useEffect(() => {
        if (!mailNextPageToken) {
            setMessagesPage(1);
        }
      }, [JSON.stringify(inbox_messages)])

      const deleteShedualEmail = (id) => {
        CustomerServices.deleteShedualeEmail(id).then((res) => {
            if (res.status) {
                let updatedschedual = schedule_messages.filter((msg) => msg.id != id);
                dispatch(updateSchedualEmail(updatedschedual));
            }
        })
      }

    return (
        <>
            <div className="d-flex flex-column h-100">
                <div className="schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column" id="list_view">
                    <div id="list_view" className={`d-flex flex-column h-100 flex-grow-1 backdroplighttheme ${commanoffcanvasIsOpen ? "" : "daily-schedule-main w-800px"}`}>
                   
                {/* <Calendar
                    projectId={projectId}
                    clearStatusFilter={clearStatusFilter} setTaskModalClose={ModalClose} seteMaildate={seteMaildate} setRefetch={setRefetch} pageName={'MyEmail'} setShowLoader={setShowLoader}
                /> */}
                <div className={`comman-main-box padding-bottom-100px custom-accordian-main res-padding-right0 h-100 task-list-accordian ${showDocumentCanvas ? "list-view-toggle" : ""
                    }`}>
                    <Accordion defaultActiveKey={['1']} alwaysOpen className="task-list-accordian-main mt-0">
                        <div className="pill-wrapper with-top-30px">
                            <div id="" className="">
                                <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                             
                                    {/* <div className="d-flex commn-search radius_3 justify-content-between">
                                        <div className="comment-input-box">
                                            <input type="text" placeholder="Search" className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"

                                                onChange={(e) => {
                                                    setMailSearchString(e.target.value);
                                                }}
                                                value={mailSearchString}

                                            />
                                        </div>
                                        <a href="#/" className="comman_action_icon">
                                            <div className="d-flex align-items-center ">
                                                <div className="action_icon h25w25 d-none">
                                                <X size={14} className="c-icons filter-img" weight="light" />
                                                </div>
                                                <div className="action_icon h25w25">
                                                <MagnifyingGlass size={14} className="c-icons" weight="light" />
                                                </div>
                                            </div>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {(mailTypeFilter == 'all' || mailTypeFilter == 'important' || localStorage.getItem("email_filter_email") || !mailTypeFilter || mailTypeFilter) ?

                            <Accordion.Item eventKey="0" className="with-pb-15 ">
                                <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border ">
                                <div className="checkbox_accordian">
                                    <Accordion.Header className="active in-progress commanTaskCount accordion-header">
                                        <div className="left-task-header color-blue  fw-semibold title-fonts c-font f-13">
                                            <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span>
                                            {_l('l_important')}  ({(important_messages || []).length})
                                        </div>
                                    </Accordion.Header>
                                    </div>
                                </div>
                                <Accordion.Body className="taskCountToggle">
                                    {important_messages && important_messages.length > 0 ?
                                     important_messages.map((email) => {
                                        return (

                                            <div>
                                                <EmailCard
                                                     email={email}
                                                     active={selectedTaskIdforIconToggle}
                                                     pageName={"myEmail"}
                                                     fromReply={false}
                                                     showOptions={false}
                                                     setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                                                     selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                                                     showDefaultIcons={showDefaultIcons}
                                                     setShowDefaultIcons={setShowDefaultIcons}
                                                     handleToggleIcons={handleToggleIcons}
                                                     setAllMAilMessages={setAllMAilMessages}
                                                     setAllImportantMessages={setAllImportantMessages}
                                                     showMailDetailsMessage={showMailDetailsMessage}
                                                     inbox={0}
                                                     showDocumentCanvas={showDocumentCanvas}
                                                     UpdateMessage={UpdateMessage}
                                                     markImportantEmail={markImportantEmail}
                                                     is_important={true}
                                                     prirotyMails={prirotyMails}
                                                     />
                                                {/* <div className="task-list-card list-view" >
                                                    <div className="task-list-wrapper">
                                                        <div className="d-flex w-100 align-items-start">
                                                            <div className="d-flex task-list-detail">
                                                                <div className="task-left flex-grow-1 width100minus160">
                                                                    <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                                                                        <div className="task-top-part flex-grow-1 main-calc-width-task d-flex text-truncate" onClick={() => { showMailDetailsMessage(!showDocumentCanvas, email,0) }}>
                                                                            <div className="d-flex align-items-center w-100">
                                                                                <div className="task-creator-img">
                                                                                    <div className="comman-image-box h20w20 rounded-circle">
                                                                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${email['thread']['profile']}')` }}>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <a href="#/"
                                                                                    className="task-list-name text-truncate"
                                                                                    data-effect="solid"
                                                                                    data-delay-show='1000'
                                                                                    data-class="tooltip-main"
                                                                                    data-place="top"
                                                                                >
                                                                                    <span className="me-2">{htmlDecode((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])}</span>
                                                                                    <span className="opacity-50 fw-normal">{(email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Subject'))[0]['value']}</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="task-mid-part w-460 d-flex res-flex-wrap-nowrap align-items-center  for-content-between comman_action_icon justify-content-end">
                                                                            <div className="d-flex align-items-center justify-content-end res-space-start res-width-100">
                                                                                <div className="pill-min-width pill-bottom-show  text-end">
                                                                                    <div className="d-flex align-items-center">
                                                                                        {email['thread']['status'] == 0 ? <a href="#/" className="badge  badge-white rounded-pill  me-2"><span className="color-white p-0">{_l('l_close')}</span></a> : <></>}
                                                                                        {email['thread']['is_saver'] == 1 ? <a href="#/" className="badge  badge-white rounded-pill  me-2"><span className="color-white p-0">{_l('l_flame')}</span></a> : <></>}
                                                                                        <a href="#/" className="badge  badge-white rounded-pill  me-2"><span className="color-white p-0">{_l('l_important')}</span></a>
                                                                                        <div className="badge  badge-white rounded-pill  d-none me-2"><span className="text-warning p-0">{_l('l_request')}</span></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center task-bottom-part-full">
                                                                        <div className="task-right-static-options">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className={email['thread']['cc'] ? "d-flex align-items-center justify-content-end w-120" : "d-flex align-items-center justify-content-end w-60" }>
                                                                                    {
                                                                                        email['thread']['cc'] ?
                                                                                    
                                                                                        <div className="position-relative pe-2 assignee-list-wrapper card-view-less-padding">
                                                                                            <div className="multi_inner_wrapper with-min-width d-flex with_minus"
                                                                                            data-tip={`${email['thread']['cc']}`}
                                                                                            data-effect="solid"
                                                                                            data-delay-show='1000'
                                                                                            data-place="top"
                                                                                            data-class="tooltip-main"
                                                                                            data-bs-toggle="tooltip"
                                                                                            aria-expanded="false"
                                                                                            >

                                                                                                <a href="#/" className="comman-image-box multi-wrapper-border h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                                                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${email['thread']['cc_profile']}')` }}></div>
                                                                                                </a>
                                                                                                { email['thread']['cc'].split(',').length >1 ?
                                                                                                <a href="#/" className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                                                                    <div className="unread_count m-auto">+{email['thread']['cc'].split(',').length-1}</div>
                                                                                                </a>
                                                                                                : <></>}
                                                                                            </div>
                                                                                        </div>
                                                                                        : <></> 
                                                                                    }
                                                                                    <div className="comman_action_icon with_separator_10 card-view-none  ">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="dropup">
                                                                                                <div className="" >
                                                                                                    <div className="action_icon with_bg on-hover-active-toggle-img">
                                                                                                        <UserCircleGear size={18} className="c-icons" weight="light" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="white-border-box p-0 d-flex ">
                                                                                    <a href="#/" className="custom-datepicker datepicker-input w-90 m-auto ms-2 c-font">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <CalendarBlank size={16} className="c-icons mx-0" weight="light" />
                                                                                             <span className="c-font f-13 min-width-80 text-truncate ps-2">{new Date((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Date'))[0]['value']).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}</span>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                                <div className="comman_action_icon with_separator_10 card-view-less-padding">
                                                                                    <div className="white-border-box color-white-60 action_icon with_bg  dropdown d-flex" >
                                                                                        <div className="d-flex align-items-center m-auto">
                                                                                            <span className={email['thread']['status'] == 0 ? "c-dots c-10 in-progress-white rounded-circle" : "c-dots c-10 in-progress-red rounded-circle"} onClick={() => { UpdateMessage(email['conversation']['id'], (email['thread']['status'] == 0 ? 1 : 0), 'status') }} ></span>
                                                                                            <span className="d-none">Not Started</span>
                                                                                            <CaretDown size={14} className="c-icons d-none" weight="light" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="comman_action_icon with_separator_10 card-view-less-padding" onClick={() => { UpdateMessage(email['conversation']['id'], (email['thread']['is_saver'] == 0 ? 1 : 0), 'is_saver') }}>
                                                                                    <div className="priority-checkbox span-13 white-border-box action_icon with_bg  ">
                                                                                        <input type="checkbox" id="priority" className="absolute-input" checked="" />
                                                                                        {/* <div className="check_box"> * /}
                                                                                        <div>
                                                                                        {email['thread']['is_saver'] == 1 ? (
                                                                                            <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                                                                            ) : (
                                                                                            <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center justify-content-end table-list-attechment">
                                                                                {/* <div className="d-flex align-items-center justify-content-end table-list-attechment w_172"> * /}
                                                                                    <div className=" with_separator_10 d-flex align-items-center card-view-less-padding min-width-60">
                                                                                        <a href="#/" className="comman_action_icon w-100 d-flex" >
                                                                                            <div className="d-flex align-items-center w-100">
                                                                                                <div className="action_icon with_bg on-hover-active-toggle-img position-relative">
                                                                                                    {((email['conversation']['messages'].filter((reply) => reply['labelIds'].includes('UNREAD')) || []).length) ? <span className=" rounded-pill badge-custom d-flex p-0 bg-warning">{((email['conversation']['messages'].filter((reply) => reply['labelIds'].includes('UNREAD')) || []).length)}</span> : <></>}
                                                                                                    <EnvelopeSimple size={18} className="c-icons" weight="light" />
                                                                                                </div>
                                                                                                {email['conversation']['messages'].length > 0 ? <span className="c-font f-12 opacity-50 d-flex justify-content-center flex-grow-1 text-on-hover-active-green p-0 ms-auto">{email['conversation']['messages'].length}</span> : <></>}
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div className=" with_separator_10 d-flex align-items-center d-none  ">
                                                                                        <a href="#/" className="comman_action_icon w-100 d-flex ">
                                                                                            <div className="d-flex align-items-center w-100">
                                                                                                <div className="action_icon with_bg on-hover-active-toggle-img">
                                                                                                    <Link size={18} className="c-icons" weight="light" />
                                                                                                </div>
                                                                                                <span className="c-font f-12 opacity-50 d-flex justify-content-center flex-grow-1 text-on-hover-active-green"></span>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div className=" with_separator_10 d-flex align-items-center card-view-less-padding before-dnone pe-0">
                                                                                        <a href="#/" className="comman_action_icon w-100 d-flex" >
                                                                                            <div className="d-flex align-items-center w-100">
                                                                                                <div className="action_icon with_bg position-relative on-hover-active-toggle-img">
                                                                                                    {((email['thread']['mentions'].filter((comment) => comment['is_read'] == 0).length) ? <span className=" rounded-pill badge-custom d-flex p-0">{((email['thread']['mentions'].filter((comment) => comment['is_read'] == 0) || []).length)}</span> : <></>)}
                                                                                                    <ChatCircle size={18} className="c-icons" weight="light" />
                                                                                                </div>
                                                                                                {email['thread']['comments'].length > 0 ? <span className="c-font f-12 opacity-50 d-flex justify-content-center flex-grow-1 text-on-hover-active-green">{email['thread']['comments'].length}</span> : <></>}
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="task-assign-section">
                                                                    <div className="d-flex align-items-center justify-content-end">
                                                                       
                                                                        <div className="before-dnone comman_action_icon with_separator_10 pe-0">
                                                                            <a href="#/" className="action_icon with_bg light-theme-img "
                                                                                onClick={() => { UpdateMessage(email['conversation']['id'], (email['thread']['is_closed'] == 0 ? 1 : 0), 'un_important') }}

                                                                            >
                                                                               
                                                                                <PushPin
                                                                                size={16} weight="light"
                                                                                    className={email['thread']['is_closed'] == 1 ? 'c-icons' : 'c-icons  d-none'}
                                                                                />
                                                                              
                                                                                <PushPin
                                                                                size={16}
                                                                                color="#00ff6a"
                                                                                weight="fill"
                                                                                    className={email['thread']['is_closed'] == 1 ? 'c-icons  d-none' : 'c-icons'}


                                                                                />
                                                                            </a>
                                                                        </div>
                                                                        <div className="before-dnone comman_action_icon with_separator_10 ">
                                                                            <a href="#/" className="action_icon with_bg light-theme-img" 
                                                                                data-tip={_l("l_add_custom")}
                                                                                data-effect="solid"
                                                                                data-delay-show='1000'
                                                                                data-class="tooltip-main"
                                                                                onClick={()=>{addCustomTopic(extractEmails(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])), htmlDecode(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])))}}
                                                                                >
                                                                                <Hash size={18} className="c-icons" weight="light" />
                                                                            </a>
                                                                        </div>  
                                                                        <div className="before-dnone comman_action_icon with_separator_10 p-0"
                                                                            data-tip={_l("l_add_to_spam")}
                                                                            data-effect="solid"
                                                                            data-delay-show='1000'
                                                                            data-class="tooltip-main">
                                                                            <a href="#/" className="action_icon with_bg light-theme-img"
                                                                            onClick={()=>{addToSpamTopic(extractEmails(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])), htmlDecode(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])))}}
                                                                            >
                                                                                <Prohibit size={18} className="c-icons" weight="light" />
                                                                            </a>
                                                                        </div>    
                                                                        <div className="dropdown h20w20  comman_action_icon dropstart d-none">
                                                                            <a href="#/"
                                                                                className="line-height-0 light-theme-img on-hover-active-toggle-img left-toggle-none"
                                                                                id="ActiveOfficeOptionsDropdown"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                data-tip={_l("l_options")}
                                                                                data-effect="solid"
                                                                                data-delay-show='1000'
                                                                                data-class="tooltip-main"
                                                                            >
                                                                                <DotsThreeVertical size={18} className="c-icons" weight="light" />
                                                                            </a>
                                                                            <ActionDropdown
                                                                                actions={[
                                                                                    {
                                                                                        actionName: _l("l_add_custom"),
                                                                                        actionHandler: () => {
                                                                                            addCustomTopic(extractEmails(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])), htmlDecode(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])))
                                                                                        },
                                                                                        actionIcon: "CheckIcon",
                                                                                    },
                                                                                    {
                                                                                        actionName: _l("l_add_to_spam"),
                                                                                        actionHandler: () => {
                                                                                            addToSpamTopic(extractEmails(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])), htmlDecode(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])))
                                                                                        },
                                                                                        actionIcon: "CheckIcon",
                                                                                    },
                                                                                    {
                                                                                        actionName: _l("l_add_topic"),
                                                                                        actionHandler: () => {},
                                                                                        actionIcon: "CheckIcon",
                                                                                    }
                                                                                ]}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        )
                                    })
                                    : 
                                    <>
                                    <CommanPlaceholder imgType="email" placeholderText = {_l("l_no_data")} />
                                    </>
                                    }
                                    
                                </Accordion.Body>
                            </Accordion.Item> : <></>

                        }
                        {(mailTypeFilter != 'important' && email_filter_key !== "scheduled") ?
                            <Accordion.Item eventKey="1" className="with-pb-15 ">
                                <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                                <div className="checkbox_accordian">
                                    <Accordion.Header className="active  to-do commanTaskCount accordion-header">
                                        <div className="left-task-header  fw-semibold title-fonts c-font f-13">
                                            <span className="c-dots c-7 rounded-circle in-progress-white me-2"></span>
                                            {mailTypeFilter == 'all' ? _l('l_inbox') : mailTypeFilter} ({taskFilters.filter((c) => c.filter_key == "all")[0] && taskFilters.filter((c) => c.filter_key == "all")[0].filter_key == "all" ? mailResultSizeEstimate : (inbox_messages || []).length })
                                        </div>
                                    </Accordion.Header>
                                    </div>
                                </div>
                                <Accordion.Body className="taskCountToggle">
                                    {inbox_messages && inbox_messages.length > 0 ?
                                     inbox_messages.sort((a,b)=>{
                                      return  b["thread"].is_priority - a["thread"].is_priority
                                        
                                     }).slice(0, (messagesPage * 10)).map((email, index) => {
                                        defaultTopics && defaultTopics.length > 0 && defaultTopics.filter((topic) => topic.main_key === "my_emails")[0] && 
                                        defaultTopics.filter((topic) => topic.main_key === "my_emails")[0].categories.forEach((category) => {
                                            email['thread'] && email['thread'].custom_topic && email['thread'].custom_topic.forEach((tag) => {
                                            if (category.filter_key == tag.tag_id) {
                                                tag.name = category.title
                                              }
                                            })
                                          })
                                        return (
                                            <div>
                                                      <EmailCard
                                                     email={email}
                                                     active={selectedTaskIdforIconToggle}
                                                     pageName={"myEmail"}
                                                     fromReply={false}
                                                     showOptions={false}
                                                     setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                                                     selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                                                     showDefaultIcons={showDefaultIcons}
                                                     setShowDefaultIcons={setShowDefaultIcons}
                                                     handleToggleIcons={handleToggleIcons}
                                                     setAllMAilMessages={setAllMAilMessages}
                                                     setAllImportantMessages={setAllImportantMessages}
                                                     showMailDetailsMessage={showMailDetailsMessage}
                                                     inbox={1}
                                                     showDocumentCanvas={showDocumentCanvas}
                                                     UpdateMessage={UpdateMessage}
                                                     markImportantEmail={markImportantEmail}
                                                     is_important={false}
                                                     prirotyMails={prirotyMails}
                                                     index = {index}
                                                     handleAddTag = {handleAddTag}
                                                     />
                                                 {/* <div className="task-list-card list-view" >
                                                    <div className="task-list-wrapper">
                                                        <div className="d-flex w-100 align-items-start">
                                                            <div className="d-flex task-list-detail">
                                                                <div className="task-left flex-grow-1 width100minus160">
                                                                    <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                                                                        <div className="task-top-part text-truncate flex-grow-1 d-flex main-calc-width-task" onClick={() => { showMailDetailsMessage(!showDocumentCanvas, email,1) }}>
                                                                            <div className="d-flex align-items-center flex-grow-1 text-truncate">
                                                                                <div className="task-creator-img">
                                                                                    <div className="comman-image-box h20w20 rounded-circle">
                                                                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${email['thread']['profile']}')` }}>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <a href="#/"
                                                                                    className="task-list-name text-truncate"
                                                                                    data-effect="solid"
                                                                                    data-delay-show='1000'
                                                                                    data-class="tooltip-main"
                                                                                    data-place="top"
                                                                                >
                                                                                    <span className="me-2">{htmlDecode((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])}</span>
                                                                                    <span className="opacity-50 fw-normal">{(email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Subject'))[0]['value']}</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="task-mid-part w-460 d-flex res-flex-wrap-nowrap align-items-center  for-content-between comman_action_icon justify-content-end">
                                                                            <div className="d-flex align-items-center justify-content-end res-space-start res-width-100">
                                                                                <div className="pill-min-width pill-bottom-show  text-end">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <a href="#/" className="badge  badge-white rounded-pill fw-light"><span className="color-white p-0">{email['conversation']['messages'][0]['labelIds'][(email['conversation']['messages'][0]['labelIds'] || []).length - 1]}</span></a>
                                                                                        <div className="badge  badge-white rounded-pill fw-light d-none"><span className="text-warning p-0">Request</span></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center task-bottom-part-full">
                                                                        <div className="task-right-static-options">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className={email['thread']['cc'] ? "d-flex align-items-center justify-content-end w-120" : "d-flex align-items-center justify-content-end w-60" }>
                                                                                        {
                                                                                            email['thread']['cc'] ?
                                                                                        
                                                                                            <div className="position-relative pe-2 assignee-list-wrapper card-view-less-padding">
                                                                                                <div className="multi_inner_wrapper with-min-width d-flex with_minus"
                                                                                                data-tip={`${email['thread']['cc']}`}
                                                                                                data-effect="solid"
                                                                                                data-delay-show='1000'
                                                                                                data-place="top"
                                                                                                data-class="tooltip-main"
                                                                                                data-bs-toggle="tooltip"
                                                                                                aria-expanded="false"
                                                                                                >

                                                                                                    <a href="#/" className="comman-image-box multi-wrapper-border h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding">
                                                                                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${email['thread']['cc_profile']}')` }}></div>
                                                                                                    </a>
                                                                                                    { email['thread']['cc'].split(',').length >1 ?
                                                                                                    <a href="#/" className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                                                                        <div className="unread_count m-auto">+{email['thread']['cc'].split(',').length-1}</div>
                                                                                                    </a>
                                                                                                    : <></>}
                                                                                                </div>
                                                                                            </div>
                                                                                            : <></> 
                                                                                        }
                                                                                    <div className="comman_action_icon with_separator_10 card-view-none  ">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="dropup">
                                                                                                <div className="" >
                                                                                                    <div className="action_icon with_bg on-hover-active-toggle-img">
                                                                                                        <UserCircleGear size={18} className="c-icons" weight="light" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="white-border-box color-white-60 p-0 d-flex  ">
                                                                                    <a href="#/" className="custom-datepicker datepicker-input w-90 m-auto ms-2 c-font">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <CalendarBlank size={16} className="c-icons mx-0" weight="light" />
                                                                                            <span className="c-font f-13 min-width-80 text-truncate ps-2"> {isAppliedUnreadComment || dateFilter ? new Date((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Date'))[0]['value']).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}) : new Date((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Date'))[0]['value']).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}</span> 
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                                <div className="comman_action_icon with_separator_10 card-view-less-padding d-none">
                                                                                    <div className="white-border-box color-white-60 action_icon with_bg  dropdown d-flex" >
                                                                                        <div className="d-flex align-items-center m-auto">
                                                                                            <span className="c-dots c-10 in-progress-white rounded-circle"></span>
                                                                                            <span className="d-none">Not Started</span>
                                                                                            <CaretDown size={14} className="c-icons d-none" weight="light" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="comman_action_icon with_separator_10 card-view-less-padding" onClick={() => { saverFromInbox(email['conversation']['id'], new Date((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Date'))[0]['value']).toTimeString()) }}>
                                                                                    <div className="priority-checkbox span-13 white-border-box action_icon with_bg  ">
                                                                                        <input type="checkbox" id="priority" className="absolute-input" checked="" />
                                                                                        <div className="check_box">
                                                                                            <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                                                                        </div>
                                                                                        <div className="check_box">
                                                                                            <HighPriorityIcon className="HW18 check_span_img inactive" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center justify-content-end table-list-attechment">
                                                                                    <div className=" with_separator_10 d-flex align-items-center card-view-less-padding min-width-60">
                                                                                        <a href="#/" className="comman_action_icon w-100 d-flex" >
                                                                                            <div className="d-flex align-items-center w-100">
                                                                                                <div className="action_icon with_bg on-hover-active-toggle-img position-relative">

                                                                                                    {((email['conversation']['messages'].filter((reply) => reply['labelIds'].includes('UNREAD')) || []).length) ? <span className=" rounded-pill badge-custom d-flex p-0 bg-warning">{((email['conversation']['messages'].filter((reply) => reply['labelIds'].includes('UNREAD')) || []).length)}</span> : <></>}
                                                                                                    <EnvelopeSimple size={18} className="c-icons" weight="light" />
                                                                                                </div>
                                                                                                {email['conversation']['messages'].length > 0 ? <span className="c-font f-12 opacity-50 d-flex justify-content-center flex-grow-1 text-on-hover-active-green p-0 ms-auto">{email['conversation']['messages'].length}</span> : <></>}
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div className=" with_separator_10 d-flex align-items-center d-none  ">
                                                                                        <a href="#/" className="comman_action_icon w-100 d-flex ">
                                                                                            <div className="d-flex align-items-center w-100">
                                                                                                <div className="action_icon with_bg on-hover-active-toggle-img">
                                                                                                    <Link size={18} className="c-icons" weight="light" />
                                                                                                </div>
                                                                                                <span className="c-font f-12 opacity-50 d-flex justify-content-center flex-grow-1 text-on-hover-active-green">0</span>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div className=" with_separator_10 d-flex align-items-center card-view-less-padding pe-0">
                                                                                        <a href="#/" className="comman_action_icon w-100 d-flex" >
                                                                                            <div className="d-flex align-items-center w-100">
                                                                                                <div className="action_icon with_bg position-relative on-hover-active-toggle-img">
                                                                                                    {((email['thread']['mentions'].filter((comment) => comment['is_read'] == 0).length) ? <span className=" rounded-pill badge-custom d-flex p-0">{((email['thread']['mentions'].filter((comment) => comment['is_read'] == 0) || []).length)}</span> : <></>)}
                                                                                                    <ChatCircle size={18} className="c-icons" weight="light" />
                                                                                                </div>
                                                                                                {email['thread']['comments'].length > 0 ? <span className="c-font f-12 opacity-50 d-flex justify-content-center flex-grow-1 text-on-hover-active-green">{email['thread']['comments'].length}</span> : <></>}
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                    
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="task-assign-section">
                                                                    <div className="d-flex align-items-center justify-content-end">
                                                                        
                                                                        <div className="before-dnone comman_action_icon with_separator_10 ">
                                                                            <a href="#/" className="action_icon with_bg light-theme-img "
                                                                                onClick={() => { markImportantEmail(email['conversation']['id'], new Date((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Date'))[0]['value']).toLocaleString()) }}
                                                                            >
                                                                                <PushPin size={16} className="c-icons" weight="light" />
                                                                            </a>
                                                                        </div>
                                                                        <div className="before-dnone comman_action_icon with_separator_10 ps-0">
                                                                            <a href="#/" className="action_icon with_bg light-theme-img"
                                                                                 data-tip={_l("l_add_custom")}
                                                                                data-effect="solid"
                                                                                data-delay-show='1000'
                                                                                data-class="tooltip-main"
                                                                                onClick={()=>{addCustomTopic(extractEmails(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])), htmlDecode(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])))}}
                                                                                >
                                                                                <Hash size={18} className="c-icons" weight="light" />
                                                                            </a>
                                                                        </div>  
                                                                        <div className="before-dnone comman_action_icon with_separator_10 p-0">
                                                                            <a href="#/" className="action_icon with_bg light-theme-img" 
                                                                                data-tip={_l("l_add_to_spam")}
                                                                                data-effect="solid"
                                                                                data-delay-show='1000'
                                                                                data-class="tooltip-main"
                                                                                onClick={()=>{
                                                                                    addToSpamTopic(extractEmails(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])), htmlDecode(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])))
                                                                                }}
                                                                                >
                                                                                <Prohibit size={18} className="c-icons" weight="light" />
                                                                            </a>
                                                                        </div>
                                                                        <div className=" h20w20  comman_action_icon dropstart d-none">
                                                                            <a href="#/"
                                                                                className="line-height-0 light-theme-img on-hover-active-toggle-img left-toggle-none"
                                                                                id="ActiveOfficeOptionsDropdown"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                                data-tip={_l("l_options")}
                                                                                data-effect="solid"
                                                                                data-delay-show='1000'
                                                                                data-class="tooltip-main"
                                                                            >
                                                                                <DotsThreeVertical size={18} className="c-icons" weight="light" />
                                                                            </a>
                                                                            <ActionDropdown
                                                                                actions={[
                                                                                    {
                                                                                        actionName: _l("l_add_custom"),
                                                                                        actionHandler: () => {
                                                                                            addCustomTopic(extractEmails(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])), htmlDecode(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])))
                                                                                        },
                                                                                        actionIcon: "CheckIcon",
                                                                                    },
                                                                                    {
                                                                                        actionName: _l("l_add_to_spam"),
                                                                                        actionHandler: () => {
                                                                                            addToSpamTopic(extractEmails(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])), htmlDecode(((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])))
                                                                                        },
                                                                                        actionIcon: "CheckIcon",
                                                                                    },
                                                                                    {
                                                                                        actionName: _l("l_add_topic"),
                                                                                        actionHandler: () => {
                                                                                            
                                                                                        },
                                                                                        actionIcon: "CheckIcon",
                                                                                    }
                                                                                ]}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        )
                                    })
                                    : 
                                    <>
                                    <CommanPlaceholder imgType="email" placeholderText = {_l("l_no_data")} />
                                    </>
                                    }
                                    { (inbox_messages && inbox_messages.length > (messagesPage * 10)) || mailNextPageToken ? 
                                        <a href="#/" className="btn c-font f-12 py-2 w-100 view-more-btn transparent bg-white-03-solid-imp"
                                        
                                        onClick={()=>{
                                            if (mailNextPageToken) {
                                                fetchMoreMails(mailNextPageToken)
                                            }
                                            setMessagesPage(messagesPage + 1);
                                        }}
                                        
                                        >
                                            <div className=" d-flex align-item-center justify-content-center">
                                                <div className="">
                                                    <span className="title-fonts">{`${_l('l_view_more')} ${!mailNextPageToken ? `(${inbox_messages.length - (messagesPage * 10)})` : ''}`}</span>
                                                    <CaretDoubleDownIcon
                                                        className="HW14 ms-2"
                                                    />
                                                    <span role="status" aria-hidden="true" className="ms-2 opacity-0 spinner-border spinner-border-sm"></span>
                                                </div>
                                            </div>
                                        </a> 
                                    : <></>}
                                </Accordion.Body>
                            </Accordion.Item> : <></>
                        }
                         {(mailTypeFilter != 'important' && email_filter_key === "scheduled") ?
                            <Accordion.Item eventKey="1" className="with-pb-15 ">
                                <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                                <div className="checkbox_accordian">
                                    <Accordion.Header className="active  to-do commanTaskCount accordion-header">
                                        <div className="left-task-header  fw-semibold title-fonts c-font f-13">
                                            <span className="c-dots c-7 rounded-circle in-progress-white me-2"></span>
                                            {mailTypeFilter} ({schedule_messages && schedule_messages.length})
                                        </div>
                                    </Accordion.Header>
                                    </div>
                                </div>
                                <Accordion.Body className="taskCountToggle">
                                    {schedule_messages && schedule_messages.length > 0 ?
                                     schedule_messages.map((email, key) => {
                                        return (
                                            <div>
                                                  <GeneralCardView 
                                                    key={key}
                                                    cardTitle={email.subject}
                                                    // onClick={() => {onClick(email.title, email.id)}}
                                                    details = {{
                                                        schedule_date : email.schedule_date, 
                                                        msg_body : email.msg_body, 
                                                    }}
                                                    hideChatAndCall
                                                    isFromEmailSchedul = {true}
                                                    // onClick={() => {
                                                    //     if (email.is_reply == "0") {
                                                    //         setBulkEmailSendShow(true);
                                                    //         setschedualEmailData(email)
                                                    //     }
                                                    // }}
                                                    actionItems={[
                                                    {
                                                        actionName: _l("l_delete"),
                                                        actionHandler: () => { deleteShedualEmail(email.id)},
                                                        actionIcon: "TrashIcon",
                                                    },
                                                    ]}
                                                />
                                            </div>
                                        )
                                    })
                                    : 
                                    <>
                                    <CommanPlaceholder imgType="email" placeholderText = {_l("l_no_data")} />
                                    </>
                                    }
                                { (schedule_messages && schedule_messages.length > (messagesPage * 10)) || mailNextPageToken ? 
                                    <a href="#/" className="btn c-font f-12 py-2 w-100 view-more-btn transparent bg-white-03-solid-imp"
                                    
                                    onClick={()=>{
                                        if (mailNextPageToken) {
                                            fetchMoreMails(mailNextPageToken)
                                        }
                                        setMessagesPage(messagesPage + 1);
                                    }}
                                    
                                    >
                                        <div className=" d-flex align-item-center justify-content-center">
                                            <div className="">
                                                <span className="title-fonts">{`${_l('l_view_more')} ${!mailNextPageToken ? `(${schedule_messages.length - (messagesPage * 10)})` : ''}`}</span>
                                                <CaretDoubleDownIcon
                                                    className="HW14 ms-2"
                                                />
                                                <span role="status" aria-hidden="true" className="ms-2 opacity-0 spinner-border spinner-border-sm"></span>
                                            </div>
                                        </div>
                                    </a> 
                                : <></>}
                                </Accordion.Body>
                            </Accordion.Item> : <></>
                        }
                    </Accordion>
                    {/* {showDocumentCanvas ? <MyEmailTaskModal showModal={showDocumentCanvas} setShowModal={setShowDocumentCanvas} mailToView={mailToView} setShowLoader={setShowLoader} oldImportant={oldImportant} setOldImportant={setOldImportant} oldInbx={oldInbx} setOldInbx={setOldInbx} setStatesOfMails={setStatesOfMails} /> : <></>} */}
                    { showDocumentCanvas ?
                     <CommanOffCanvas 
                     show={showDocumentCanvas} 
                     handleClose={() => {setShowDocumentCanvas(false)}} 
                     data={mailToView} 
                     setSelectedId={setSelectedMailId} 
                     docType = {mailType} 
                     handleParentComponentAction={handleComponentAction}
                     pageName={"MyEmail"}
                      /> : <></>}
                    {showLoader ? <CommanLoader /> : <></>}

                    {bulkEmailSendShow ? 
                        <AddEmailTemplatesModalNew
                            show={bulkEmailSendShow}
                            handleClose={() => { 
                                setBulkEmailSendShow(false); 
                                setschedualEmailData({});
                            }}
                            type = {"isFromEmailFooter"}
                            from = {"isFromSchedualEmail"}
                            data = {schedualEmailData}
                        />
                        : <></>}
                </div>
                
                </div>
                </div>
            </div>
        </>
    );
};
export default MyEmailList;