import { openDB } from "idb";
import { removeDuplicateObjectsFromArray } from "./utilities";
import { IDB_STORE_LIST } from "../constants/constants";

const IDB_NAME = "myr_ai";
let IDB_VERSION;

const openIDB = async (
  databaseName = IDB_NAME,
  version,
  objectStoreName,
  keyPath
) => {
  let db;
  try {
    db = await openDB(databaseName, version);
    if(IDB_VERSION && Number(db.version) > IDB_VERSION)
    {
      IDB_VERSION = Number(db.version);
    }
    if (
      objectStoreName &&
      keyPath &&
      !db.objectStoreNames.contains(objectStoreName)
    ) {
      db.close();
      db = await openDB(IDB_NAME, Number(db.version) + 1, {
        upgrade(db) {
          db.createObjectStore(objectStoreName, {
            keyPath,
          });
        },
      });
      IDB_VERSION = Number(db.version) + 1;
    }
  } catch (e) {
    console.trace("Error: Can not open IDB", e);
  } finally {
    return db;
  }
};

const readFromDB = async (objectStoreName, key, keyPath) => {
  try {
    const db = await openIDB(IDB_NAME, IDB_VERSION, objectStoreName, keyPath);
    if (!db) {
      console.error("Failed to read record from DB, can not open a connection");
    }
  
    const dbInstance = db;
    if(db.objectStoreNames.contains(objectStoreName))
    {
      const transaction = dbInstance.transaction(objectStoreName, "readonly");
      const objectStore = transaction.objectStore(objectStoreName);
      const data = await objectStore.get(key);
      return data;
    }
    else {
      return [];
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};

const writeToDB = async (objectStoreName, key, value, keyPath) => {
  try {
    let db = await openIDB(IDB_NAME, IDB_VERSION, objectStoreName, keyPath);
    if (!db.objectStoreNames.contains(objectStoreName)) {
      db.createObjectStore(objectStoreName, { keyPath });
      db.close();
      db = await openIDB(IDB_NAME, db.version + 1, objectStoreName, keyPath);
      IDB_VERSION = db.version + 1;
    }
    if (db) {
      await new Promise((resolve, reject) => {
        const transaction = db.transaction(objectStoreName, "readwrite");
        const objectStore = transaction.objectStore(objectStoreName);

        const request = objectStore.put(value);

        request.onsuccess = () => {
          resolve();
        };

        request.onerror = (event) => {
          reject(event.target.error);
        };
      });
      db.close();
    }
  } catch (e) {
    console.error(e);
  }
};

const insertCollectionToDB = async (objectStoreName, collection, keyPath) => {
  try {
    let db = await openIDB(IDB_NAME, IDB_VERSION, objectStoreName, keyPath);

    if (db) {

      if (!db.objectStoreNames.contains(objectStoreName)) {
        db.close();
        db = await openIDB(IDB_NAME, db.version + 1, objectStoreName, keyPath);
        IDB_VERSION = db.version + 1;
      }

      await new Promise((resolve, reject) => {
        const transaction = db.transaction(objectStoreName, "readwrite");
        const objectStore = transaction.objectStore(objectStoreName);

        transaction.oncomplete = () => {
          resolve();
        };

        transaction.onerror = (event) => {
          reject(event.target.error);
        };
        if(collection && typeof collection === 'object' && typeof collection[Symbol.iterator] === 'function')
        {
          for (const item of collection) {
            objectStore.put(item);
          }
        }
      });
      db.close();
    }
  } catch (e) {
    console.error(
      `Error inserting data to ${objectStoreName} ${collection} ${keyPath}`,
      e
    );
  }
};

const readCollectionFromDB = async (objectStoreName, keyPath) => {
  try {
    if(!objectStoreName || !keyPath)
    {
      return [];
    }
    let db = await openIDB(IDB_NAME, IDB_VERSION, objectStoreName, keyPath);
    if(!db.objectStoreNames.contains(objectStoreName))
    {
      db.close();
      return [];
    }
    const tx = db.transaction(objectStoreName, "readonly");
    const store = tx.objectStore(objectStoreName);

    // Get all data from the object store
    const allData = await store.getAll();

    await tx.done;
    db.close();

    return allData;
  } catch (e) {
    console.error(e);
  }
};

const cleanCollection = async (storeName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openIDB(IDB_NAME, IDB_VERSION, storeName);
      const transaction = db.transaction(storeName, "readwrite");
      const objectStore = transaction.objectStore(storeName);

      const clearRequest = objectStore.clear();

      clearRequest.onsuccess = () => {
        db.close();
        resolve(`Cleared all data from ${storeName}`);
      };

      clearRequest.onerror = (event) => {
        reject(`Error clearing data from ${storeName}: ${event.target.error}`);
      };
    } catch (error) {
      reject(`Error opening database: ${error}`);
    }
  });
};

const clearDatabase = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = await openIDB(IDB_NAME);

      const objectStoreNames = Array.from(db.objectStoreNames);

      objectStoreNames.forEach((storeName) => {
        if (!["sessions"].includes(storeName)) {
          cleanCollection(storeName);
        }
      });

      db.close();

      resolve(`Cleared database ${IDB_NAME}`);
    } catch (error) {
      reject(`Error clearing database: ${error}`);
    }
  });
};

const getAllIDBCollectionKeys = async () => {
  try {
    const db = await openIDB(IDB_NAME, IDB_VERSION);
    return Array.from(db.objectStoreNames).map((collectionName) => {
      const transaction = db.transaction(collectionName, "readonly");
      const objectStore = transaction.objectStore(collectionName);
      return { objectStoreName: collectionName, keyPath: objectStore.keyPath };
    });
  } catch (e) {
    console.error(e);
  }
};

//For Login as user transition, cache the data to IDB
const archieveIDBCollections = async (username) => {
  try {
    const idbCollections = await getAllIDBCollectionKeys();
    if (idbCollections && idbCollections.length) {
      let sessionData = {};
      for (const collection of idbCollections) {
        const collectionData = await readCollectionFromDB(
          collection.objectStoreName,
          collection.keyPath
        );
        sessionData = {
          ...sessionData,
          [collection.objectStoreName]: collectionData,
        };
      }
      if (sessionData && Object.keys(sessionData).length) {
        if (sessionData["sessions"]) {
          delete sessionData["sessions"];
        }
        const currentSessionData = await readCollectionFromDB(
          "sessions",
          "user"
        );

        const sessionIndex = currentSessionData.findIndex(
          (w) => w.user === username
        );
        if (sessionIndex === -1) {
          currentSessionData.push({ user: username, data: sessionData });
        } else {
          currentSessionData[sessionIndex] = {
            user: username,
            data: sessionData,
          };
        }
        insertCollectionToDB("sessions", currentSessionData, "user");
      }
    }
  } catch (e) {
    console.error(e);
  }
};

//Restore Previous Session from IDB if exists
const restoreIDBSession = async (username) => {
  try {
    const currentSessionData = await readCollectionFromDB("sessions", "user");
    if (currentSessionData && currentSessionData.length) {
      const sessionIndex = currentSessionData.findIndex(
        (w) => w.user === username
      );
      if (sessionIndex > -1) {
        const userSession = currentSessionData[sessionIndex];
        const collectionList = await getAllIDBCollectionKeys();
        collectionList.forEach((name) => {
          insertCollectionToDB(
            name.objectStoreName,
            userSession[name],
            name.keyPath
          );
        });
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const getCollectionKeyNames = async (objectStoreName) => {
  try {
    let db = await openIDB(IDB_NAME, IDB_VERSION, objectStoreName);
    if (db) {
      if (!db.objectStoreNames.contains(objectStoreName)) {
        return [];
      }

      const transaction = db.transaction([objectStoreName], "readonly");

      // Get the object store
      const objectStore = transaction.objectStore(objectStoreName);

      const getAllKeysRequest = await objectStore.getAllKeys();
      return getAllKeysRequest;
    }
    else {
      return [];
    }
  } catch (e) {
    console.error(e);
    return [];
  }
};


const getRecordsByIdFromIDB = async(objectStoreName, keyPath, paramName, paramValues) => {

  const comparatorFunction = (record, target) => {
    if(Array.isArray(target) && target.find((w) => w == record[paramName]))
    {
      return true;
    }
    else if(record[paramName] == target)
    {
      return true;
    }
    return false;
  }
  try {
    const allRecords = await readCollectionFromDB(objectStoreName, keyPath);
    if(allRecords && allRecords.length)
    {
      let results = [];
      
      const processRecord = (record) => {
        if(typeof record === "object")
        {
          const objectKeys = Object.keys(record);
          
          if(objectKeys.find((param) => param === paramName) && comparatorFunction(record, paramValues))
          {
            results.push(record);
          }
          else {
            objectKeys.forEach((key) => {
              if(Array.isArray(record[key]))
              {
                record[key].forEach((subRecord) => {
                  processRecord(subRecord); // Recursive call for nested arrays
                });
              }
            });
          }
        }
      };

      allRecords.forEach(processRecord);

      return removeDuplicateObjectsFromArray(results, paramName);
    }
    return [];
  }
  catch(e)
  {
    console.error(e);
    return [];
  }
};

const initIDB = async() => {
  try {
    const request = indexedDB.open(IDB_NAME);
    const db = await new Promise((resolve, reject) => {
      request.onerror = (event) => reject(`Failed to open database: ${event.target.error}`);
      request.onsuccess = (event) => resolve(event.target.result);
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        IDB_STORE_LIST.forEach((objectStore) => {
          if (!db.objectStoreNames.contains(objectStore.objectStoreName)) {
            db.createObjectStore(objectStore.objectStoreName, { keyPath: objectStore.keyPath });
          }
        });
      };
    });

    // Close the database connection
    db.close();
    return "Object stores created or already existed.";
  } catch (error) {
    throw new Error(error);
  }
};

export {
  openIDB,
  readFromDB,
  writeToDB,
  insertCollectionToDB,
  readCollectionFromDB,
  cleanCollection,
  clearDatabase,
  getAllIDBCollectionKeys,
  archieveIDBCollections,
  restoreIDBSession,
  getCollectionKeyNames,
  getRecordsByIdFromIDB,
  initIDB
};
