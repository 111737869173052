import React, { useEffect, useState } from 'react'
import { _l } from '../../hooks/utilities'
import { CaretDoubleDown, ChatCircle, ChatDots, DotsNine } from 'phosphor-react'
import CommentMinified from '../Comments/CommentMinified'
import { UncontrolledDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import CommanPlaceholder from '../Placeholder/CommanPlaceholder';
import { Spinner } from 'react-bootstrap';

const EquipmentTable = ({data}) => {
    const {  providerlistTableData } = useSelector((state) => state.customer);
    const spaceState = useSelector((state) => state.customer.spaces);
    const [EquipmentData, setEquipmentData] = useState(
        data.data && data.data.length > 0 ? data.data : []
      );
    const [spacePage, setSpacePage] = useState(1);
    const [loder, setLoder] = useState({
        spaceLoader: false,
    });

    const scroll = (status) => {
        let page;
        let taskStaus = status;
        if (status == 1) {
            page = spacePage + 1;
            setSpacePage(page);
        }
        taskStaus = [status];
        if (status == 1) {
            setLoder({ ...loder, ["spaceLoader"]: false });
        }
    };
        
    const getSpaceName = (id) => {
        const spaceName = spaceState && spaceState.filter((item) => item.project_id == id)[0] && spaceState.filter((item) => item.project_id == id)[0].office_title
        return spaceName; 
    }
    const openEquipmentModal = (item) => {
        let externalURL = "";
        externalURL = `/equipments?space=${item.project_id}&equipment=${item.id}`;
        if (externalURL !== "") {
          window.open(externalURL, "_blank");
          return;
        }
    }

    useEffect(() => {
        setEquipmentData(providerlistTableData.equipment);
      }, [JSON.stringify(providerlistTableData)])
    return (
        <>
            <table className="dataTable text-center tablewithborder">
                <thead className="comman-white-box-shadow">
                    <tr>
                        <th style={{ width: "20%" }} className="text-start">
                            {_l("l_equipment_name")}
                        </th>
                        <th style={{ width: "3%" }} className="text-start">
                            {_l("l_provider_assignee")}
                        </th>
                        <th style={{ width: "3%" }} className="text-start">
                            {_l("l_customer_assignee")}
                        </th>
                        <th style={{ width: "2%" }} className="text-center">
                            {_l("l_is_consumable")}
                        </th>
                        <th style={{ width: "4%" }} className="text-center">
                            {_l("l_number_of_quantity")}
                        </th>
                        <th style={{ width: "2%" }} className="text-center">
                            {_l("l_action")}
                        </th>
                    </tr>
                </thead>
                <tbody style={{ verticalAlign: "middle" }}>
                {EquipmentData && EquipmentData.length ? (
                    <>
                        {EquipmentData .slice(
                            0,
                            spacePage > 1 ? (spacePage - 1) * 10 + 10 : spacePage * 10
                            ).map((equip) => { 
                        return (
                            <>
                            <tr>
                                <td className='pe-0'>
                                    <a href='#/' className='name-with-progress max-width-200 d-block'
                                        onClick={() => {openEquipmentModal(equip);}}
                                    >
                                        <div className='d-flex align-items-center'>
                                            {/* <div className='h35w35 comman-round-box with-bg rounded-circle bg-white-05'>
                                                <div className='comman-bg-img h-100 w-100 bg-style-cover rounded-circle' style={{
                                                    backgroundImage: `url('https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                                                }}></div>
                                            </div> */}
                                            <div className='ps-2 w100minus70 text-start flex-grow-1'>
                                                <div className='d-flex flex-column'>
                                                    <div className='title-fonts text-truncate text-start max-width-200 fw-semibold'>{equip.equipment_name}</div>
                                                    <div className='c-font color-white-60 f-10 lh-1 location me-2 fw-normal'>{getSpaceName(equip.project_id)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </td>
                                <td>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <a
                                            href="#/"
                                            data-bs-toggle="tooltip"
                                            className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                            data-tip={equip.provider_assignee_name}
                                            data-place="right"
                                            data-effect="solid"
                                            data-delay-show="1000"
                                            data-class="tooltip-main"
                                        >
                                            <div
                                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                style={{
                                                    backgroundImage: `url(${equip.provider_assignee_profile_img})`
                                                }}
                                            ></div>
                                            <ReactTooltip />
                                        </a>
                                    </div>
                                
                                </td>
                                <td>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <a
                                            href="#/"
                                            data-bs-toggle="tooltip"
                                            className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                            data-tip={equip.contact_assignee_name}
                                            data-place="right"
                                            data-effect="solid"
                                            data-delay-show="1000"
                                            data-class="tooltip-main"
                                        >
                                            <div
                                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                style={{
                                                    backgroundImage: `url(${equip.contact_assignee_profile_img})`
                                                }}
                                            ></div>
                                            <ReactTooltip />
                                        </a>
                                    </div>
                                
                                </td>
                                <td class="title-fonts text-center">{equip.is_consumable}</td>

                                <td class="title-fonts text-center">{equip.quantity}</td>
                                <td className='p-2'>
                                    <div className='d-flex  align-items-center justify-content-center comman_action_icon'>
                                        <a href="#/" className={`action_icon h32w32 with_bg with-border-active`}>
                                            <DotsNine size={18} weight="light" className="c-icons" />
                                        </a>
                                    </div>


                                </td>
                            </tr>
                            </>
                        );
                        })}
                        <tr>
                            {(EquipmentData.length >= (spacePage - 1) * 10 + 10 &&
                            EquipmentData.length != (spacePage - 1) * 10 + 10) ||
                            loder.spaceLoader ? (
                            <>
                                <td className="border-0" colSpan={12}>
                                <a
                                    href="#/"
                                    className="btn btn-white-03 w-100 view-more-btn"
                                    onClick={() => {
                                    setLoder({ ...loder, ["spaceLoader"]: true });
                                    scroll(1);
                                    }}
                                >
                                    <div className="d-flex align-item-center justify-content-center">
                                    <span className=" title-fonts c-font f-12 ms-2">
                                        {_l("l_view_more_btn")} (
                                        {spacePage > 1
                                        ? EquipmentData.length - ((spacePage - 1) * 10 + 10)
                                        : EquipmentData.length - 10}
                                        )
                                    </span>
                                    <CaretDoubleDown
                                        size={12}
                                        className="c-icons mx-2"
                                        weight="light"
                                    />
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        className={`ms-2 ${
                                        loder.spaceLoader ? "opacity-1" : "opacity-0"
                                        }`}
                                        aria-hidden="true"
                                    />
                                    </div>
                                </a>
                                </td>
                            </>
                            ) : (
                            <></>
                            )}
                        </tr>
                    </>
                    ) : (
                    <>
                        {" "}
                        <tr className="">
                        <td className="border-0" colSpan={7}>
                            <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                            <div className="d-flex flex-column align-items-center">
                                <CommanPlaceholder
                                imgType="request"
                                placeholderText={_l("l_no_data")}
                                />
                            </div>
                            </div>
                        </td>
                        </tr>
                    </>
                    )}
                </tbody>
            </table>
        </>
    )
}

export default EquipmentTable