import moment from "moment";
import jquery from "jquery";

import {
  FETCH_SPACES,
  FETCH_CALENDAR_TASKS,
  FETCH_TASK_LIST,
  UPDATE_TASK_STATUS,
  CHANGE_PRIORITY,
  UPDATE_PIN,
  GET_TOPICS_TASK,
  GET_DEFAULT_TOPICS,
  UPDATE_TASK_PROJECT,
  SET_TASK_DATA,
  GET_TASK_DEFAULT_ASSIGNEE,
  GET_SELECTED_TASK,
  SET_SELECTED_PROJECT,
  UPDATE_TASK_ASSIGNEE,
  TASK_LIST_PAGINATION,
  CLIENT_TEAM_DATA,
  LEFT_PANEL_TASK_FILTERS,
  SET_TASK_COUNTS,
  TASK_SEARCH_STRING,
  SET_SELECTED_TASK,
  GET_TASK_CHECKLISTITEMS,
  UPDATE_CHECKLISTITEMS,
  GET_BENCHMARK_DETAIL,
  GET_BENCHMARK_SCHEDULE,
  APPLY_ALL_TASKS_FILTERS,
  APPLY_TASKS_STATUS_FILTERS,
  GET_MENTION_LIST,
  VIEW_ALL_TASKS,
  ACTIVE_TASK_STATUS_FILTERS,
  GET_CONTACT_PERMISSIONS,
  WALLET_DETAILS,
  MODAL_DETAILS,
  UNREAD_COMMENT_TASKS,
  IS_TOAST_CLOSE,
  UPDATE_PROJECT_TOPICS,
  TASK_UNREAD_CMT_CNT_ALL,
  UNREAD_COMMENT_COUNT_UPDATE,
  IS_PREMUIM_ESTIMATE_DISABLE,
  IS_IBAN_MODAL_OPEN,
  UPDATE_SPACE_NOTIFICATION_COUNT,
  CREATE_SCHDULE_DETAILS,
  GET_BENCHMARK_LIST,
  SET_FILTER_BENCHMARK_CATEGORY,
  UPDATE_PROVIDER_ASSIGNEE,
  UPDATE_REQUEST_TASK_ASSIGNEE,
  UPDATE_ALL_PROJ_NOTI_COUNT,
  TASK_TYPE_FILTER,
  FETCH_MAILS,
  CALENDARS_DETAILS,
  CALENDARS_ASSIGNEE,
  CHECK_LIST_UPDATE,
  SYN_MYR_TASK_ASSIGNEE,
  UPDATE_TASK_ASSIGNEE_NEW,
  UPDATE_BENCHMARK_PROPOSALS_COMMENTS,
  UPDATE_MAIL_CALENDAR_COUNTER,
  FETCH_PROPOSAL_COMMENTS,
  UPDATE_TASK_ASSIGNEE_LIST,
  UPDATE_MAIL_CC_ASSIGNEE,
  PRODUCT_TOUR,
  GET_TASK_CHAT_ASSIGNEE,
  VIRTUAL_SPACE_LIST,
  UPDATE_VIRTUAL_SPACE_NOTI_COUNT,
  TASK_BULK_ACTION,
  GET_FLOOR_DOCUMENT,
  GET_FLOOR_LIST,
  SELECTED_FLOOR_ID,
  GET_MAIL_ASSIGNEE,
  LEAD_MODAL_TOGGLE,
  SET_GLOBAL_SEARCH_STRING,
  SET_GET_LANGUAGE,
  SET_NEW_MEETING,
  INVOICE_COUNTER,
  ESTIMATE_COUNTER,
  ESTIMATE_TOPICS,
  GET_COMPANY_EMPLOYEE,
  FETCH_LOCK_TASK_LIST,
  COUNT_LOCK_TASKS,
  UPDATE_HIDE_LOADER,
  GET_DEFAULT_TOPIC_LIST,
  GET_USER_INFO,
  GET_SPACE_WORK_SCORE,
  GET_CALENDAR_TASK_LIST,
  SET_CATEGORY_NOTIFICATION_COUNTS,
  PROVIDER_REPORTING_DATA,
  BENCHMARKS_COUNTER,
  GET_ATTENDANCE,
  GET_ATTENDANCE_LIST,
  GET_ATTENDANCE_COUNT_WEEK_CALENDAR,
  GET_PROJECT_ATTENDANCE_COUNT,
  STAFF_COUNTER,
  STAFF_TOPICS,
  SET_TEAMS,
  GET_TASK_LISTS,
  SET_EQUIPMENT_CATEGORY_ID,
  GET_TASK_ATTACHMENTS,
  GET_DETAILS_FOR_LOGIN_AS_USER,
  SUBSCRIPTION_COUNTER,
  EQUIPMENTS_CATEGORY_TOPICS,
  SELECTED_SUB_FLOOR_ID,
  SET_POI_LAT_LNG,
  GET_POIS_LIST,
  GET_ACTIVE_POIS_LIST,
  SET_BENCHMARK_SCHEDULE,
  CLIENT_TAB_DATA,
  GET_AR_LOCAL_ANCHORS,
  CLOSE_OUT_SIDE_CLICK,
  MODEL_VIEWER_POINT,
  EDIT_ACCESS_SPACE,
  COMMANOFFCANVAS_IS_OPEN,
  OPEN_TASK_DETAILS_CALENDAR,
  UPDATE_SPACE_CONTENT,
  TASK_ATTACHMENTS_FOR_CREATE_TASK,
  UPDATE_UNREAD_CHAT_COUNT_TASKLIST,
  GET_PROJECT_THEME,
  SET_FAVORITE_DASHBOARD,
  _NEW_CLIENT_DATA,
  SUCCESS_FLAG,
  GET_OPERATOR_BY_CATEGORY,
  COUNTRY_CODE,
  GET_ALL_USER_LIST_FOR_STAFF,
  SELECTED_CHAT_USER,
  IS_BACKDROP,
  IS_ALL_CHAT_USERLIST_SHOW,
  GENERAL_UNREAD_COUNT,
  SEARCH_STRING_CHAT_ALL_USER,
  DAY_GRID_MONTH,
  DAY_GRID_WEEK,
  DAY_GRID_DAY,
  IS_TASKLIST_VIEW,
  IS_AI_CHAT,
  SET_SELECTED_CALENDAR_DATE,
  CREATE_TASK_REQ_INPUT_ICONS,
  DAY_DIFFERENCE,
  APPLIED_DATE_RANGE_FILTER,
  SELECTED_VIEW,
  DATERANGE_FILTER_APPLIED,
  CALENDAR_VIEW,
  BENCHMARK_CREATE_OR_NOT,
  SELECTED_DATERANGE_FILTER,
  PINNED_DELAY_FILTER,
  INV_HEADER_CAL_COUNTER,
  GET_CHECKED_TASK,
  IMAGEVIEWER_IS_OPEN,
  TASKLIST_LOADER,
  OPEN_CHATAI_INPUTBOX,
  SELECTED_DATE_VIEW,
  GET_EQUIPMENTS_LIST,
  SET_EQUIPMENTS_QUANTITY,
  SET_EQUIPMENTS_QUANTITY_LIST,
  SET_NEW_EQUIPMENT,
  LAST_CHAT_SESSION,
  METRICS_DETAILS,
  SELECTED_TYPE_OF_SPACE_ID,
  FILTER_FOR_3D_MODEL,
  UPDATE_TASK_LIST,
  SELECTED_DATE_CHATAI,
  DASHBOARD_DETAILS_WITH_ALL_SPACE,
  DASHBOARD_VIEW,
  DASHBOARD_FILTER,
  TASK_RATING,
  UPDATE_WEEK_CALENDAR,
  TASK_AND_MESSAGES,
  FIRST_TASK_DATE,
  LAST_TASK_DATE,
  FIRST_MESSAGE_DATE,
  LOADING_BENCHMARK_DETAILS,
  SET_SPACE_LIST,
  DASHBOARD_DETAILS_WITH_TOTAL,
  SHOW_THREE_DAYS_WEEK,
  CREATE_TASK_MODAL_IS_OPEN,
  CREDITNOTES_DETAILS,
  MISSED_CALL,
  CHAT_UNREADMESSAGE_COUNT,
  CHANGE_WEEK_CAL_NAVI,
  UNREADTASK_COMMENT,
  UPDATE_SELECTED_TASK_DETAIL,
  GENERATE_AI_THREAD_ID,
  IS_TOPIC_LIST_LOADING,
  CLEAR_DEFAULT_TOPICS,
  SELECTED_DATE_INDEX_FOR_CALENDAR,
  TASK_LIST_FOR_CHAT,
  FIRST_MESSAGE_ID,
  CALL_MESSAGE_PAGINATIONS,
  LAST_FILTER_DETAILS,
  LAST_MESSAGE_SPACE,
  DISPLAY_CALL_FULLSCREEN,
  IS_CALL_RECORDING,
  CREATE_TASK_FOR_AUDIO_CALL,
  GET_CALL_TASK_DETAILS,
  GET_COMMENTS_DURING_AUDIO_CALL,
  CALL_RECORDING_ACTION_DETAILS,
  SHOW_MUTE_MIC,
  CALL_FUNCTION_OF_CALL_DISCONNECT,
  FULL_SCREEN_LOADER_CHANGE_SPACE,
  SELECTED_PLAN_FROM_CHAT,
  SELECTED_PROPOSAL,
  GET_COMPANY_INVITETION_LIST,
  IS_JOB_PROPOSAL_PAGE,
  OPEN_CONTACT_DIRECTORY_CHAT,
  ACCORDION_EVENT_KEY,
  LOADING_BENCHMARK_WEEK_SCHEDULE,
  IBAN_ACCOUNTS_LIST,
  EDIT_SCHEDULE_JSON,
  SHOW_API_LOADING_LOADER,
  SET_LIST_OF_SELECTEDOPERATORE_LIST,
  MAIL_REPLAY_FROM_LIST,
  SET_EQUIPMENT_CONSUMBLE_CHECK_LIST,
  IS_CREATE_TASK_DEFAULT_TOPICS,
  GET_PROVIDER_LIST,
  SET_RISK_DATA_LIST,
  PROVIDER_LIST_TABLE_DATA,
  UPDATE_PROVIDER_LIST_TABLE_DATA,
  SET_PROVIDER_LIST_LOADER,
  UPDATE_DEFAULT_TOPICS_AI_DATA,
  IS_TODO_TABLE,
  UPDATE_SCHEDUALE_EMAILS,
  UPDATE_DEFAULT_TOPICS,
  PROVDER_WALLET_LIST
} from "../actions/action-type";
import { CustomerModel } from "../interfaces/customerModels";
import { REMOVE_BENCHMARK } from "../actions/benchmark-actions/benchmark-action-type";
import { USR_IMG_PLACEHOLDER } from "../actions/chat-action-type";
import { formateDate } from "../hooks/utilities";

const CUSTOM_ASSIGNEE_CHANGE = ['1','2'];
const initialState = new CustomerModel();

export default function (state = initialState, action) {
  const isVirtualSpace = localStorage.getItem("selectedVirtualSpace") ? true : false ;
  const adminAsStaff = localStorage.getItem('adminArchieve') && localStorage.getItem('adminArchieve') ? true : false;

  const { type, payload } = action;
  
  switch (type) {
    case FETCH_SPACES:
      const user_type = localStorage.getItem("user_type");
      const {extra} = payload.data;
      let spaceArray = payload.data.data;
      if(user_type != "staff")
      {
        if(spaceArray && spaceArray.length && spaceArray.some((x) => x.init_space == 1)) //Flag to setup space through AI Chat from connect page
        {
          if(user_type === "contact" || (user_type === "operator" && spaceArray.length == 1))
          {
            localStorage.setItem("space_setup_pending", 1);
          }
        }
        else if(spaceArray && spaceArray.length > 0 && spaceArray.filter((x) => x.project_id > 0).length > 0){

          localStorage.removeItem("space_setup_pending");
        }
        spaceArray = spaceArray.filter((w) => w.init_space != 1);
      }

      localStorage.setItem(
        "spaces",
       spaceArray &&spaceArray.length
          ? JSON.stringify(spaceArray)
          : JSON.stringify([])
      );
      const offices = spaceArray;
      if(extra)
      {
        localStorage.setItem("is_active_space", extra.is_active_space);
      }

      let selectedActiveOffice = [];
      if (offices) {
        selectedActiveOffice = offices.filter(
          (x) => x.status == "1" || x.status == "2"
        );
      }
      if ((!localStorage.getItem("selectedOffice") &&selectedActiveOffice.length) && (localStorage.getItem("is_registered") != 1) ) {
        localStorage.setItem("selectedOffice", selectedActiveOffice[0].project_id );

        if (localStorage.getItem("user_type") == "operator") {
          localStorage.setItem(
            "operatorClientId",
            selectedActiveOffice[0].client_id
              ? selectedActiveOffice[0].client_id
              : 0
          );
          localStorage.setItem(
            "is_added_from_operator",
            selectedActiveOffice[0].is_added_from_operator
              ? selectedActiveOffice[0].is_added_from_operator
              : 0
          );
          if (
            selectedActiveOffice[0].client_id !=
            localStorage.getItem("client_id")
          ) {
            localStorage.setItem(
              "spaceClientDetails",
              JSON.stringify([
                {
                  cp_id: selectedActiveOffice[0].client_id
                    ? selectedActiveOffice[0].client_id
                    : 0,
                  mobile: selectedActiveOffice[0].phonenumber
                    ? selectedActiveOffice[0].phonenumber
                    : 0,
                  email: selectedActiveOffice[0].email
                    ? selectedActiveOffice[0].email
                    : "",
                  profile_url: selectedActiveOffice[0].profile_image
                    ? selectedActiveOffice[0].profile_image
                    : "",
                },
              ])
            );
          }
        }
      }

      // if (spaceArray && spaceArray.length > 0) {
      //   localStorage.removeItem("is_registered")        
      // }

      return {
        ...state,
        spaces:
          payload.data.data && payload.data.data.length
            ? spaceArray
            : [],
        selectedProject: localStorage.getItem("selectedOffice")
          ? localStorage.getItem("selectedOffice")
          : spaceArray &&
            spaceArray[0] &&
            spaceArray[0].project_id
            ? spaceArray[0].project_id
            : 0,
      };
    case SET_SPACE_LIST:
      return {
        ...state,
        spaces: payload.data
      }
    case FETCH_CALENDAR_TASKS:      
      localStorage.setItem("calendarData", JSON.stringify(payload.data.data));
      // localStorage.setItem("scrollDate", (payload.data.data[0].date));
      return {
        ...state,
        calendarData: payload.data.data,
      };
    case FETCH_TASK_LIST:
      let sortedTaskList = payload.data && payload.data.data ? payload.data.data : [];
      sortedTaskList = sortedTaskList.sort(
        (a, b) => parseInt(b.id) - parseInt(a.id)
      );
      return {
        ...state,
        taskList: sortedTaskList,
        extraCountTask: payload && payload.data && payload.data.extra ? payload.data.extra : [],
        UnreadCommentTaskList: payload && payload.data &&  payload.data.data ? payload.data.data : [],
      };
    case FETCH_LOCK_TASK_LIST:
      let _sortedTaskList = payload.data && payload.data.data ? payload.data.data : [];
      _sortedTaskList = _sortedTaskList.sort(
        (a, b) => (new Date(a.time) - new Date(b.time))
      );
      return {
        ...state,
        lockTaskList: _sortedTaskList,
        lockCountTask: payload.data.extra ? payload.data.extra : [],
        UnreadCommentTaskList: payload.data.data ? payload.data.data : [],
      };
    case UPDATE_TASK_STATUS:
      if (state.taskList) {
        const taskList = state.taskList;
        let targetTask = state.selectedTask ? state.selectedTask : {};
        targetTask.checklist_progress = Math.round((targetTask.checklist && targetTask.checklist.filter((c) => c.finished == 1).length / targetTask.checklist.length) * 100)
        taskList.forEach((task) => {
          if (task.id === payload.payload.task_id) {
            if (payload.payload.status == 'task_convert_to_request') {
              task.is_requested = 1
            } else {
              task.id = payload.payload.original_task_id;
              task.status = payload.payload.status.toString();
            }
          }
        });
        if (payload.payload.is_requested_task) {
          targetTask = state.selectedTask;
          if (targetTask && Object.keys(targetTask).length > 0) {
            if (payload.payload.status == 'task_convert_to_request') {
              targetTask['is_requested'] = 1
            } else {
              targetTask['status'] = payload.payload.status.toString();
              targetTask['is_requested'] = 0
              targetTask['id'] = targetTask['task_id']
            }

          }
        } else {
          if (Object.keys(targetTask).length > 0) {
            if (payload.payload.status == 'task_convert_to_request') {
              targetTask['is_requested'] = 1
            } else {
              targetTask['status'] = payload.payload.status.toString();
              targetTask['is_requested'] = 0
            }

          } else {
            targetTask =
              taskList[
              taskList.findIndex(
                (task) => task.id === payload.payload.task_id
              )
              ];
          }
        }
        return {
          ...state,
          taskList: taskList,
          selectedTask: targetTask,
        };
      }
      return state;

    case CHANGE_PRIORITY:
      let taskList = state.taskList;

      if (taskList && taskList.length) {
        let highPriorityTasks = [];
        let lowPriorityTasks = [];
        let newTaskList = [];

        taskList.forEach((task) => {
          if (task.id === payload.payload.task_id) {
            task.priority = payload.payload.taskPriority == 3 ? "2" : "3";
          }

          if (payload.payload.taskPriority && task.priority === "3") {
            highPriorityTasks.push(task);
          } else if (payload.payload.taskPriority && task.priority === "2") {
            lowPriorityTasks.push(task);
          }
        });
        newTaskList = highPriorityTasks.concat(lowPriorityTasks);
        if (payload.payload.taskPriority == 2) {
          const taskIndex = taskList.findIndex(
            (task) => task.id === payload.payload.task_id
          );
          if (taskIndex != -1) {
            highPriorityTasks = [taskList[taskIndex]];
            lowPriorityTasks = taskList;
            lowPriorityTasks.splice(taskIndex, 1);
            newTaskList = highPriorityTasks.concat(lowPriorityTasks);
          } else {
            newTaskList = taskList;
          }
        }
        let currentTask = state.selectedTask;
        if (currentTask) {
          currentTask.priority = payload.payload.taskPriority == 3 ? "2" : "3";
        }

        return {
          ...state,
          taskList: newTaskList,
          selectedTask: currentTask,
        };
      } else {
        return state;
      }

    case UPDATE_PIN:
      let taskLists = state.taskList;

      if (taskLists && taskLists.length) {
        taskLists.forEach((task) => {
          if (task.id === payload.payload.task_id) {
            task.pinned = payload.payload.taskPinShow == 0 ? "1" : "0";
          }
        });
        let currentPinTask = state.selectedTask;
        if (currentPinTask) {
          if (currentPinTask.is_requested == 1) {
            currentPinTask.is_pinned = payload.payload.taskPinShow == 0 ? "1" : "0";
          } else {
            currentPinTask.pinned = payload.payload.taskPinShow == 0 ? "1" : "0";
          }

        }
        return {
          ...state,
          selectedTask: currentPinTask,
          taskList: taskLists,
        };
      } else {
        return state;
      }

    case GET_TOPICS_TASK:
      const sortedTopics =
        payload && payload.topics && payload.topics.length
          ? payload.topics.sort((a, b) => a.filter_key - b.filter_key)
          : [];
      var mailFilters = [];
      payload.topics.forEach((topic) => {
        topic["categories"].forEach((category) => {
          if (category["email"]) {
            mailFilters = [...mailFilters, ...category.email.split(",")];
          }

          if (topic["main_key"] == "provider_task") {
            category["member_list"] &&
              category["member_list"].forEach((mem) => {
                mailFilters.push(mem);
              });
          }
        });
      });

      let notificationCount = 0;
      sortedTopics.forEach((topic) => {
        topic.categories.forEach((category) => {
          notificationCount += category.total_notification_count ?  category.total_notification_count.chat_count + category.total_notification_count.task_count : 0;
        });
      });
      const spaces = JSON.parse(localStorage.getItem("spaces"));
      if (spaces && spaces.length) {
        spaces.forEach((space) => {
          if (space.project_id === action.project_id)
            space.notification_count = notificationCount;
        });
      }
      state.mailFilters = mailFilters;
      // alert('ok');
      return {
        ...state,
        topicData: sortedTopics,
        mailFilters: mailFilters,
      };

    case GET_DEFAULT_TOPICS:
      let tempDefaultTopics = payload;
      var mailFilters = [];
      let aiMyr = {};
      if (payload.type && payload.type != "" && payload.id != "") {
        tempDefaultTopics = state.defaultTopics ? state.defaultTopics : [];
        tempDefaultTopics = tempDefaultTopics.map((topic) => {
          if (topic.main_key == "my_task") {
            topic["categories"] = topic.categories.filter(
              (category) => category.filter_key != payload.id
            );
          }
          return topic;
        });
      }
      

      tempDefaultTopics.map((topic) => {
        topic['categories'].forEach((category) => {
          if(category['email']){
            mailFilters.push(category['email']);
          }
          if (topic["main_key"] == "provider_task") {
            category["member_list"] &&
              category["member_list"].forEach((mem) => {
                mailFilters.push(mem);
              });
          }
        });
      });
      state.mailFilters = mailFilters;
      // For aiMyr find set Globle state
      tempDefaultTopics && tempDefaultTopics.map((top) => {
        if (top.main_key == "my_contact") {
          top.categories.map((category) => {
            if (category.contact_role && category.contact_role == 4) {
                aiMyr = ({categories : category, main_key : top.main_key, title : top.title})
            }
          })
        }
      })
      return {
        ...state,
        defaultTopics: tempDefaultTopics,
        topicData:tempDefaultTopics,
        mailFilters: mailFilters,
        topicLoadedFromIDB: action.topicLoadedFromIDB,
        aiMyr : aiMyr
      };

    case UPDATE_TASK_PROJECT:
      // state.taskList.forEach((task) => {
      //   if (task.id === payload.task_id) {
      //     task.rel_id = payload.project_id;
      //   }
      // });
      let previousTaskList = state.taskList;
      if (localStorage.getItem("selectedOffice") != payload.project_id) {
        previousTaskList = previousTaskList.filter(
          (task) => task.id != payload.task_id
        );
      }
      return {
        ...state,
        taskList: previousTaskList,
      };

    case SET_TASK_DATA:
      let currentTaskList = state.taskList;
      let currentTask = state.selectedTask;
      if (currentTaskList && currentTaskList.length) {
        currentTaskList.forEach((task, index) => {
          if (task.id == payload.task_id) {
            if (payload.task_id > 0 && payload.convertTaskId > 0) {
              task.request_task_id = payload.convertTaskId;
              task.name = payload.task_name;
            } else if (payload.flag == "pinnedFlag") {
              task.pinned = task.pinned == 1 ? 0 : 1;
            } else {
              if (payload.dueDate != '' &&
                moment(task.duedate).format("YYYY-MM-DD HH:mm:ss") !=
                moment(payload.dueDate).format("YYYY-MM-DD HH:mm:ss") &&
                payload.is_requested_task == ""
              ) {
                if (payload.dueDate != undefined && payload.dueDate != null) {
                  currentTaskList.splice(index, 1);
                }
              } else {
                task.name = payload.task_name;
                task.startdate = payload.startDate != '' ? moment(payload.startDate).format("YYYY-MM-DD HH:mm:ss") : '';

              }
            }
            if (payload.is_requested == 1) {
              task['startdate'] = moment(payload.startDate).format("YYYY-MM-DD HH:mm:ss")

            }
          }
        });
        if (currentTask) {
          currentTask.duedate = payload.dueDate != '' ? moment(payload.dueDate).format("YYYY-MM-DD HH:mm:ss") : '';
          currentTask.startdate = payload.startDate != '' ? moment(payload.startDate).format("YYYY-MM-DD HH:mm:ss") : '';
          currentTask['name'] = payload.task_name;
          if (payload.flag == "pinnedFlag") {
            if (currentTask.is_requested == 1) {
              currentTask['is_pinned'] = currentTask.is_pinned == 1 ? 0 : 1;

            }


          }
          currentTask.visible_to_client = payload.visible_to_client
          currentTask.visible_to_provider = payload.visible_to_provider
        }
      }
  
      return {
        ...state,
        taskList: currentTaskList,
        selectedTask: currentTask,
      };

    case GET_TASK_DEFAULT_ASSIGNEE:

      return {
        ...state,
        defaultTaskAssignees: payload.data,
      };

    case GET_SELECTED_TASK:
      return {
        ...state,
        selectedTask: payload.data.data,
      };

    case SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: payload.project_id,
      };

    case UPDATE_TASK_ASSIGNEE:
      let selectedTask = state.selectedTask;
      let tasklist = state.taskList;
      const selfMail = localStorage.getItem("email");
      let assigneeData = payload.assignee;
      if (assigneeData['staffid']) {
        assigneeData['staffid'] = assigneeData.id;
      }

      if (payload.fromtask && payload.fromtask > 0) {
        if (tasklist && tasklist.length) {
          tasklist = tasklist.map((task) => {
            if (task.id == payload.fromtask) {
              if (payload.type == "my_peoples" || payload.type == "my_people") {
                let tempIndexStatus = false;
                if (!tempIndexStatus) {
                  assigneeData.is_assigned = 1;
                  let tempAssignees = task.task_assignee.providers;
                  task.task_assignee.providers = tempAssignees.concat([
                    assigneeData,
                  ]);
                } else {
                  assigneeData.is_assigned = 0;
                  let tempAssignees = task.task_assignee.providers.splice(
                    tempIndexStatus,
                    1
                  );
                  task["task_assignee"]["providers"] = tempAssignees;
                }
              } else if (payload.type == "current_providers" || payload.type == "myr_staff") {
                let tempIndexStatus = false;
                if (!tempIndexStatus) {
                  assigneeData.is_assigned = 1;
                  let tempAssignees = task.task_assignee.providers;
                  task.task_assignee.providers = tempAssignees.concat([
                    assigneeData,
                  ]);
                } else {
                  assigneeData.is_assigned = 0;
                  let tempAssignees = task.task_assignee.providers.splice(
                    tempIndexStatus,
                    1
                  );
                  task["task_assignee"]["providers"] = tempAssignees;
                }
              } else if (payload.type == "equipments") {
                let tempIndexStatus = false;
                if (!tempIndexStatus) {
                  assigneeData.is_assigned = 1;
                  let tempAssignees = task.task_assignee.equipments;
                  task.task_assignee.equipments = tempAssignees.concat([
                    assigneeData,
                  ]);
                } else {
                  assigneeData.is_assigned = 0;
                  let tempAssignees = task.task_assignee.equipments.splice(
                    tempIndexStatus,
                    1
                  );
                  task["task_assignee"]["equipments"] = tempAssignees;
                }
              } else if (payload.type == "myr_network") {
                let tempIndexStatus = false;
                if (!tempIndexStatus) {
                  assigneeData.is_assigned = 1;
                  let tempAssignees = task.task_assignee.myr_network;
                  task.task_assignee.myr_network = tempAssignees.concat([
                    assigneeData,
                  ]);
                } else {
                  assigneeData.is_assigned = 0;
                  let tempAssignees = task.task_assignee.myr_network.splice(
                    tempIndexStatus,
                    1
                  );
                  task["task_assignee"]["myr_network"] = tempAssignees;
                }
              } else if (payload.type == "my_self") {
                let tempIndexStatus = false;
                if (payload.is_tag && payload.is_tag != "") {
                  if (payload.is_tag == "set") {
                    assigneeData.is_assigned = 1;
                    task.tags = [assigneeData];
                  } else if (payload.is_tag == "remove") {
                    task["tags"] = [];
                    assigneeData.is_assigned = 0;
                  }
                } else {
                  if (!tempIndexStatus) {
                    assigneeData.is_assigned = 1;
                    let tempAssignees = task.task_assignee.providers;
                    task.task_assignee.providers = tempAssignees.concat([
                      assigneeData,
                    ]);
                  } else {
                    assigneeData.is_assigned = 0;
                    let tempAssignees = task.task_assignee.providers.splice(
                      tempIndexStatus,
                      1
                    );
                    task["task_assignee"]["providers"] = tempAssignees;
                  }
                }
              }
              return task;
            }
            return task;
          });
        }
        return {
          ...state,
          taskList: tasklist,
        };
      }
      switch (payload.type) {
        case "my_peoples":
          const peopleIndex = selectedTask.task_assignee.providers.findIndex(
            (assignee) => assignee.name === assigneeData.name
          );
          if (peopleIndex === -1) {
            assigneeData.is_assigned = 1;
            selectedTask.task_assignee.providers.push(assigneeData);
          } else {
            assigneeData.is_assigned = 0;
            selectedTask.task_assignee.providers.splice(peopleIndex, 1);
          }
          break;
        case "current_providers":
          const providerIndex = selectedTask.task_assignee.providers.findIndex(
            (assignee) => assignee.name === assigneeData.name
          );
          if (providerIndex === -1) {
            assigneeData.is_assigned = 1;
            selectedTask.task_assignee.providers.push(assigneeData);
          } else {
            assigneeData.is_assigned = 0;
            selectedTask.task_assignee.providers.splice(providerIndex, 1);
          }
          break;
        case "equipments":
          const equipmentIndex =
            selectedTask.task_assignee.equipments.findIndex(
              (assignee) =>
                assignee.id === assigneeData.id ||
                assignee.equipment_id === assigneeData.id
            );

          if (equipmentIndex === -1) {
            assigneeData.is_assigned = 1;
            selectedTask.task_assignee.equipments.push(assigneeData);
          } else {
            assigneeData.is_assigned = 0;
            selectedTask.task_assignee.equipments.splice(equipmentIndex, 1);
          }
          break;
        case "myr_network":
          const networkIndex = selectedTask.task_assignee.myr_network.findIndex(
            (assignee) => assignee.name === assigneeData.name
          );
          if (networkIndex === -1) {
            assigneeData.is_assigned = 1;
            selectedTask.task_assignee.myr_network.push(assigneeData);
          } else {
            assigneeData.is_assigned = 0;
            selectedTask.task_assignee.myr_network.splice(networkIndex, 1);
          }
          break;
        case "my_self":
          const index = selectedTask.task_assignee.providers.findIndex(
            (assignee) => assignee.email == selfMail
          );

          if (payload.is_tag && payload.is_tag != "") {
            if (payload.is_tag == "set") {
              assigneeData.is_assigned = 1;
              selectedTask.tags = [assigneeData];
            } else if (payload.is_tag == "remove") {
              selectedTask.tags = [];
              assigneeData.is_assigned = 0;
            }
          } else {
            if (index === -1) {
              assigneeData.is_assigned = 1;
              selectedTask.task_assignee.providers.push(assigneeData);
            } else {
              assigneeData.is_assigned = 0;
              selectedTask.task_assignee.providers.splice(index, 1);
            }
          }
          break;
        default:
          break;
      }
      return {
        ...state,
        selectedTask: selectedTask,
      };

    case TASK_LIST_PAGINATION:
      // let currentTasks = state.taskList.concat(payload.data.data);
      // return {
      //   ...state,
      //   taskList: currentTasks.filter(
      //     (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      //   ),
      // };
      return {
        taskList: payload.data.data,
        taskFilters: state.taskFilters,
        activeTaskStatusFilters: state.activeTaskStatusFilters,
      };
    case CLIENT_TEAM_DATA:
      let projectTeam = payload.data.data;
      return {
        ...state,
        clientTeamData: {
          ...state.clientTeamData,
          [payload.project_id]: projectTeam,
        },
      };

    case LEFT_PANEL_TASK_FILTERS:
  
      if (payload.topicFilterClear === "all") {
        return {
          ...state,
          taskFilters: [],
        };
      } else if (payload.topicFilterClear === "remove_range") {
        let rangeFilter = state.taskFilters && state.taskFilters.length > 0 ? state.taskFilters : []
        rangeFilter = rangeFilter.filter((data)=> data.main_key != "date_range_filter") 
        return {
          ...state,
          taskFilters: rangeFilter.length > 0 ? rangeFilter : [],
        };
      }else if(payload.topicFilterClear === "hold_range_filter"){
        let rangeFilter = state.taskFilters && state.taskFilters.length > 0 ? state.taskFilters : []
        return {
          ...state,
          taskFilters: rangeFilter.length > 0 ? rangeFilter.filter((taskFilter) => taskFilter.main_key == "date_range_filter") : [],
        };
      }else if(payload.topicFilterClear === "hold_range_filter_task_count"){
        var defaultTopics = state.defaultTopics
        let rangeFilter = state.taskFilters && state.taskFilters.length > 0 ? state.taskFilters.filter((taskFilter) => taskFilter.main_key == "date_range_filter") : []
        let categoriesfilter = [];
        defaultTopics && defaultTopics.forEach((topic) => {
          if (topic.main_key == payload.action) {
            topic.categories.forEach((category) => {
              if((category.filter_key != localStorage.getItem("staff_id")) && (adminAsStaff ? category.is_active == 1 : category.contact_role && category.contact_role ? (category.contact_role != 4 && category.is_active == 1) : category.is_active == 1)){
                categoriesfilter.push({
                  main_key : payload.action,
                  filter_key: category.filter_key,
                  main_sub_key: category.main_sub_key,
                  sub_category: category.sub_category,
                  title : category.title,
                  image : category.image ? category.image : USR_IMG_PLACEHOLDER
                })
              }
            })
          }
        })

        if (rangeFilter.length > 0) {
          categoriesfilter = rangeFilter.concat(categoriesfilter)
        }
        return {
          ...state,
          taskFilters: categoriesfilter
        };
      }else if(payload.topicFilterClear === "remove_range_filter_task"){
        let filterDetails = [payload.filter_details]
        let rangeFilter = state.taskFilters && state.taskFilters.length > 0 ? state.taskFilters.filter((taskFilter) => taskFilter.main_key == "date_range_filter") : []
        if (rangeFilter.length > 0) {
          filterDetails = rangeFilter.concat(filterDetails)
        }
        return{
          ... state,
          taskFilters : filterDetails.length > 0 ? filterDetails : []
        }
      }else if(payload.topicFilterClear === "applyForMe"){
        let rangeFilter = state.taskFilters && state.taskFilters.length > 0 ? state.taskFilters.filter((taskFilter) => taskFilter.main_key == "date_range_filter") : []
        let forMesection = []
        state.defaultTopics && state.defaultTopics.map((topic) =>{
          if (topic.main_key == "my_task") {
            topic.categories && topic.categories.map((category) =>{
              let obj = {
                main_key : topic.main_key,
                filter_key : category.filter_key,
                main_sub_key : category.main_sub_key,
                title : category.title,
                sub_category : [],
                image : localStorage.getItem("contact_image")
              }
              forMesection.push(obj);
              localStorage.setItem("selectedTopic",JSON.stringify(obj))
            })
            
          }
        })
        let returnArr = rangeFilter.concat(forMesection)
        return{
          ...state,
          taskFilters : rangeFilter.concat(forMesection)
        }
      }else if(payload.topicFilterClear === "applyUnread"){
        let unreadfilter = [{
          filter_key : "unread_comments",
          main_key: "unread_comments",
          main_sub_key : "unread_comments",
          sub_category : [],
          title : "Unread comments"
        }];
        let finallFilter = [];
        // let isUnreadApply = state.taskFilters && state.taskFilters.length > 0 ? state.taskFilters : []
        let isUnreadApply = state.taskFilters && state.taskFilters.length == 0 ? [] : state.taskFilters.filter((task)=> task.main_key === "unread_comments")

        if (isUnreadApply.length == 0) {
          finallFilter = state.taskFilters.concat(unreadfilter)
        } else {
          finallFilter = state.taskFilters.filter((task)=> task.main_key !== "unread_comments")          
        }
        return{
          ...state,
          taskFilters : finallFilter
        }
      }else if (payload.topicFilterClear === "applyCleaningFilter"){
        let finallFilter = [];
        let rangeFilter = state.taskFilters && state.taskFilters.length > 0 ? state.taskFilters.filter((taskFilter) => taskFilter.main_key == "date_range_filter") : [];
        let cleaningArr = state.defaultTopics && state.defaultTopics.length > 0 ? state.defaultTopics.filter((key)=> key.main_key == "providers") : []
        // let filter = [{
        //   filter_key : 21,
        //   main_key : "providers",
        //   main_sub_key : "providers",
        //   sub_category : [],
        //   title : "Cleaning"
        // }]
        let filter = [];
        if (cleaningArr.length > 0) {
          cleaningArr = cleaningArr[0].categories && cleaningArr[0].categories.length > 0 ? cleaningArr[0].categories.filter((category) => category.filter_key == 21 && category.is_active == 1) : []; 
          if (cleaningArr.length > 0) {
           cleaningArr.map((key) =>{
            filter.push({
              filter_key : key.filter_key,
              main_key : "providers",
              main_sub_key : "providers",
              sub_category : [],
              title : key.title
            })
            localStorage.setItem("selectedTopic",JSON.stringify({id:key.filter_key, title: key.title, main_key:"providers", is_active: key.is_active, main_key_title: "providers ",main_sub_key: [], contact_role : localStorage.getItem("contact_role"), total_notification_count: key.total_notification_count, thread_id : "",filter_key: key.filter_key,image: key.image}))
          })}
        }else{
          filter =[{
            filter_key : "assigned_to_me",
            image : "" ,
            main_key : "my_task",
            main_sub_key :"my_task",
            sub_category :[],
            title :"l_to_me"
          }]
          localStorage.setItem("selectedTopic",JSON.stringify({id:"assigned_to_me", title: "l_to_me", main_key:"my_task", is_active: 1, main_key_title: "my_task ",main_sub_key: [], contact_role : localStorage.getItem("contact_role"), total_notification_count: {}, thread_id : "",filter_key: "assigned_to_me"}))
        }

        finallFilter = rangeFilter.concat(filter)
        return{
          ...state,
          taskFilters : finallFilter
        }
      }else if (payload.topicFilterClear === "selectAllTopics"){   
        let rangeFilter = state.taskFilters && state.taskFilters.length > 0 ? state.taskFilters.filter((taskFilter) => taskFilter.main_key == "date_range_filter") : []
        let myTask = []
        let providers = []
        let myContact = []
        let equipments = []
        const user_type = localStorage.getItem("user_type");
        
        state.defaultTopics && state.defaultTopics.map((topic) =>{
          if (topic.main_key == "my_task") {
            topic.categories && topic.categories.map((category) =>{
              let obj = {
                main_key : topic.main_key,
                filter_key : category.filter_key,
                main_sub_key : category.main_sub_key,
                title : category.title,
                sub_category : [],
                image : localStorage.getItem("contact_image")
              }
              myTask.push(obj);
            })
            
          }
          if (topic.main_key == "providers") {
            topic.categories && topic.categories.map((category) =>{
              let obj = {
                main_key : topic.main_key,
                filter_key : category.filter_key,
                main_sub_key : category.main_sub_key,
                title : category.title,
                sub_category : [],
              }
              providers.push(obj);
            })
            
          }
          if (topic.main_key == "my_contact" && user_type == "contact") {
            topic.categories && topic.categories.map((category) =>{
              let obj = {
                main_key : topic.main_key,
                filter_key : category.filter_key,
                main_sub_key : category.main_sub_key,
                title : category.title,
                sub_category : [],
              }
              myContact.push(obj);
            })
            
          }
          if (topic.main_key == "my_operator" && user_type == "operator") {
            topic.categories && topic.categories.map((category) =>{
              let obj = {
                main_key : topic.main_key,
                filter_key : category.filter_key,
                main_sub_key : category.main_sub_key,
                title : category.title,
                sub_category : [],
              }
              myContact.push(obj);
            })
            
          }
          if (topic.main_key == "equipments") {
            topic.categories && topic.categories.map((category) =>{
              let obj = {
                main_key : topic.main_key,
                filter_key : category.filter_key,
                main_sub_key : category.main_sub_key,
                title : category.title,
                sub_category : [],
              }
              equipments.push(obj);
            })
          }
        })
        let returnArr = rangeFilter.concat(myTask, providers, myContact, equipments)
        return{
          ...state,
          taskFilters : returnArr,
        }
      }
      let active_filters = state.taskFilters ? state.taskFilters : [];
      let tempfilter = []
      // if (payload.newFilterType == "newFlow") {
      //   active_filters = payload.filter_details
      // } else {
      const pathname = window.location.pathname;
      // if (window.location.pathname === "/MyEmail") {
      //   active_filters = active_filters.filter(() => 0);
      // }

      // if (payload.filter_details.main_key == "my_emails") {
      //   active_filters = active_filters.filter(
      //     (filter) => filter.main_key != "my_emails"
      //   );
      // }
      if (payload.filter_details.main_key == "my_customs") {
        active_filters = active_filters.filter(
          (filter) => filter.main_key != "my_customs"
        );
      }
      

      //To Keep only one main category at a time and remove others except date
      active_filters = active_filters.filter((main) => {
        if (
          payload.filter_details.main_key === "date_range_filter" &&
          (payload.filter_details.filter_key === "date_range_filter" ||
            payload.filter_details.filter_key === "all")
        ) {
          return main;
        } else if (
          main.main_key === payload.filter_details.main_key ||
          main.main_key === "date_range_filter"
        ) {
          return main;
        }
      });
      if (payload.topicFilterClear == "") {
        let isAppliedAlready = 1;
        if (payload.filter_details.main_key == "date_range_filter") {
          isAppliedAlready = active_filters.findIndex(
            (filter) => filter.main_key === payload.filter_details.main_key
          );
        } else {
          isAppliedAlready = active_filters.findIndex(
            (filter) =>
              filter.main_key === payload.filter_details.main_key &&
              filter.filter_key === payload.filter_details.filter_key
          );
        }
        if (isAppliedAlready === -1) {
          if (
            payload.filter_details.main_key == "date_range_filter" &&
            payload.action == "add"
          ) {
            // jquery("#active_week_date").removeClass("c-gradinet-active-border");
          }
          active_filters.push({
            filter_key: payload.filter_details.filter_key,
            main_key: payload.filter_details.main_key,
            title: payload.filter_details.title,
            image: payload.filter_details.image || "",
            main_sub_key: payload.filter_details.main_sub_key || "",
            sub_category: payload.filter_details.sub_category || [],
          });
        } else {
          active_filters.splice(isAppliedAlready, 1);
          
          jquery(
            `#${payload.filter_details.main_key}_${payload.filter_details.filter_key}`
          )
            .parent()
            .addClass("collapsed");
          if (payload.filter_details.main_key === "date_range_filter") {
            if (payload.action === "add") {
              jquery("#active_week_date").removeClass(
                "c-gradinet-active-border"
              );
              active_filters.push({
                filter_key: payload.filter_details.filter_key,
                main_key: payload.filter_details.main_key,
                title: payload.filter_details.title,
                image: payload.filter_details.image || "",
                main_sub_key: payload.filter_details.main_sub_key || "",
                sub_category: payload.filter_details.sub_category || [],
              });
            } else {
              jquery("#active_week_date").addClass("c-gradinet-active-border");
              localStorage.removeItem("fromDate");
              localStorage.removeItem("toDate");
              localStorage.removeItem("taskFilters");
            }
          }
        }
      }
      if(pathname == '/aichat' || state.globalView == "AichatView"){
        if(active_filters[0] == undefined){
          active_filters = []
        }else{
          tempfilter = payload.filter_details.main_key == "my_task" ? active_filters : [];
          if(payload.filter_details.main_key == "my_task"){
            let rangeFilter = tempfilter.filter((key)=>key.main_key == "date_range_filter")
            let topicfilter = tempfilter.filter((key)=>key.main_key != "date_range_filter") 
            let finallFilter = rangeFilter.concat([payload.filter_details])
            tempfilter = rangeFilter.length > 0 && topicfilter.length == 0 ? finallFilter : active_filters;

          }else if(active_filters.some((key)=>key.filter_key == "date_range_filter") && active_filters.filter((key)=>key.main_key == "my_task").length == 0 && active_filters.length > 0){
            let temprange  = active_filters.filter((key)=>key.filter_key == "date_range_filter")
            let arr = active_filters.filter((key)=>key.filter_key != "date_range_filter").pop()
            if(arr)
            {
              tempfilter = temprange.concat(arr)
            }
          }else if((payload.filter_details.main_key == "my_contact" || payload.filter_details.main_key == "my_operator") && (payload.filter_details.filter_key == localStorage.getItem("staff_id"))){
            if(payload.filter_details.main_sub_key != "team"){
              payload.filter_details.main_sub_key = "people" 
            }
            tempfilter = [payload.filter_details]
          }else if(payload.filter_details.main_key == "date_range_filter"){
            let temprange  = [payload.filter_details]
            let arr = active_filters.filter((key)=>key.filter_key != "date_range_filter")
            tempfilter = temprange.concat(arr)
          }else{
            tempfilter = payload.filter_details.main_key ? [payload.filter_details] : []
          }
        active_filters = tempfilter
        }
       } 
      //  else{
      //   active_filters = active_filters
      //  }
       else if (pathname == "/provider-reporting" || pathname == "/employee-reporting" || pathname == "/equipment-reporting") {
        active_filters = active_filters
       }else if(pathname == "/dashboard"){
        // if (payload.filter_details.main_key == "date_range_filter") {
        //   let topicArr = state.taskFilters.filter((filter) => filter.main_key != "date_range_filter")
        //   active_filters = topicArr.concat([payload.filter_details])
        // }else{
        //   let topicArr = state.taskFilters.filter((filter) => filter.main_key == "date_range_filter")
        //   active_filters = active_filters.filter((filter) => filter.filter_key == payload.filter_details.filter_key)
        //   active_filters = topicArr.concat(active_filters)
        // }
        active_filters = active_filters
        // return {
        //   ...state,
        //   taskFilters: active_filters.length > 0 ? active_filters : [payload.filter_details]
        // }

       }else{
        if(active_filters.length == 0){
          active_filters = [payload.filter_details]
        }else if(payload.filter_details.main_key == "my_task"){
          active_filters = active_filters
        }else if(active_filters.some((key)=> key.filter_key == "date_range_filter" || key.filter_key == "all")){ 
          let rangeArr = active_filters.filter((key)=> key.filter_key == "date_range_filter" || key.filter_key == "all")
          let topicArr = active_filters.filter((key)=> key.filter_key != "date_range_filter" && key.filter_key != "all")
          let otherFilter = payload.filter_details.main_key != "date_range_filter"  ? [payload.filter_details] : topicArr ? topicArr :[]
          active_filters = rangeArr.concat(otherFilter)
        } else if (state.taskFilters.length > 1 &&  payload.action == "remove") { // all topic to single category/equipment/people/my_task
          active_filters = [];
        }  else {
          active_filters = [active_filters[active_filters.length - 1]]
        }
       }
      return {        
        ...state,
        taskFilters: payload.filter_details.main_key && active_filters.length > 0 ? active_filters.filter((fil) => filter_key != "unread_comments") : payload.filter_details.main_key ? [payload.filter_details] : [],
      };

    case APPLY_ALL_TASKS_FILTERS:
      return {
        ...state,
        taskFilters: payload.filters,
      };

    case SET_TASK_COUNTS:
      let customValues = []
      if (payload.is_lead) {
        customValues = payload.data.extra && payload.data.extra.custom_fields
      }
      let accessOfficeData = payload.data.extra && payload.data.extra.access_office ? payload.data.extra.access_office : {};
      return {
        ...state,
        taskCounts: payload.data.data ? payload.data.data : [],
        customFieldValues: customValues,
        accessOfficeData: accessOfficeData
      };

    case TASK_SEARCH_STRING:
      return {
        ...state,
        taskSearchString: payload.string,
      };

    case EDIT_ACCESS_SPACE:
      return {
        ...state,
        accessOfficeData: payload,
      };

    case SET_SELECTED_TASK:
      return {
        ...state,
        selectedTask: payload.data,
      };

    case GET_TASK_CHECKLISTITEMS:
      return {
        ...state,
        taskChecklist: payload.data,
      };

    case UPDATE_CHECKLISTITEMS:
      // return {
      //   ...state,
      //   taskChecklist: payload.data
      // };
      return state;

    case GET_BENCHMARK_DETAIL:
      if (payload.benchmarkDetailClear == "all") {
        return {
          ...state,
          benchmarkDetail: {},
        };
      } else if (payload.benchmarkDetailClear == "edit") {
        let benchmarkDetails = state.benchmarkDetail ? state.benchmarkDetail : {};
        benchmarkDetails[payload.keyName] = payload.value
        return {
          ...state,
          benchmarkDetail: benchmarkDetails,
        };
      }
      return {
        ...state,
        benchmarkDetail: payload.data,
      };

    case GET_BENCHMARK_SCHEDULE:
      if (payload.benchmarkDetailClear == "all") {
        return {
          ...state,
          weekScheduleClear: {},
        };
      }

      let payloadData = payload.data;
      let currentChecklistItems = payload.data && payload.data.week_schedule_details ? payload.data.week_schedule_details : [];

      currentChecklistItems = currentChecklistItems.filter(
        (obj, index, self) =>
          index ===
          self.findIndex(
            (o) =>
              (obj.room_id == o.room_id &&
                obj.type_of_space_item_id == o.type_of_space_item_id &&
                obj.item_name == o.item_name)
          )
      );
      if(payloadData && payloadData.week_schedule_details)
      {
        payloadData.week_schedule_details = currentChecklistItems.map((checklist) => {
          checklist = {...checklist, assigned_days: {}};
          Object.keys(checklist.days).forEach((day) => {
            checklist = {...checklist, assigned_days: {...checklist.assigned_days, [day]: -1}}
          });
          return checklist;
        });
      }
      
      if(payload.copy)
      {
        if(currentChecklistItems.length)
        {
          currentChecklistItems = currentChecklistItems.map((x) => 
          {
            x.id = 0;
            return x;
          });
          payloadData.week_schedule_details = currentChecklistItems;
          return {
            ...state,
            benchmarkSchedule: payloadData
          };
        }
      }
      return {
        ...state,
        benchmarkSchedule: payload.data,
      };

      case SET_BENCHMARK_SCHEDULE:
        return {
          ...state,
          benchmarkSchedule: payload,
        };
    case CLOSE_OUT_SIDE_CLICK:
      return {
        ...state,
        outSideClick: payload,
      };
      case MODEL_VIEWER_POINT:
      return {
        ...state,
        modelViewerPoint: payload,
      };
    case APPLY_TASKS_STATUS_FILTERS:
      let statusFilter = state.taskStatusFilter;

      let flag = payload.flag;
      if (flag != "") {
        statusFilter.splice(flag, 1);
      } else {
        statusFilter.push(payload.statusFilters);
      }

      return {
        ...state,
        taskStatusFilter: statusFilter,
      };

    case GET_MENTION_LIST:
      return {
        ...state,
        mentionList: payload.data.filter((x) => x.id && x.name).map((x) => {return {...x, name: `${x.name} `}}),
      };
    case VIEW_ALL_TASKS:
      let currentTasks = state.taskList.concat(payload.data.data);
      return {
        ...state,
        taskList: currentTasks.filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        ),
      };
    case ACTIVE_TASK_STATUS_FILTERS:
      var statusFilters = [];
      if (payload.isClear == 0) {
        statusFilters = payload.statusList.filterStatus;
      }

      return {
        ...state,
        activeTaskStatusFilters: statusFilters,
      };
    case GET_CONTACT_PERMISSIONS:
      return {
        ...state,
        contactPermissions: payload.data,
      };
    case WALLET_DETAILS:
      return {
        ...state,
        walletDetails: payload.data,
      };
    case MODAL_DETAILS:
      return {
        ...state,
        walletDetails: payload.data,
      };
    case UNREAD_COMMENT_TASKS:
      let currentTaskLists = state.taskList ? state.taskList : [];
      let unreadTasks = state.UnreadCommentTaskList
        ? state.UnreadCommentTaskList
        : [];

      let IsAppliedUnreadComment = false;
      if (payload.flag) {
        // unreadTasks = currentTaskLists
        // .filter(
        //   (task) => task.unread_comment_count > 0
        // );
        IsAppliedUnreadComment = true;
      } else {
        // currentTaskLists = unreadTasks;
      }
      return {
        ...state,
        // taskList: unreadTasks,
        // UnreadCommentTaskList: currentTaskLists,
        isAppliedUnreadComment: IsAppliedUnreadComment,
        // extraCountTask: payload.data.extra,
      };
    case IS_TOAST_CLOSE:
      return {
        ...state,
        toastClose: payload.flag,
        // extraCountTask: payload.data.extra,
      };

    case UPDATE_PROJECT_TOPICS:
      let updatedTopics = state.topicData;
      const index = updatedTopics.findIndex(
        (x) => x.main_key === payload.main_key
      );
      if (index > -1) {
      if (payload.action == "delete") {
        updatedTopics[index].categories = updatedTopics[index] && updatedTopics[index].categories && updatedTopics[index].categories.filter((x) => x.filter_key != payload.newTopic.filter_key)
      } else {
      if (payload.is_edit_id > 0 || payload.is_edit_id < 0) {
        updatedTopics[index].categories = updatedTopics[index].categories.map((topic) => {
          if (topic.filter_key == payload.is_edit_id) {
            let image = topic['image']
            let updateDetails = topic 
            updateDetails['image'] = image
            updateDetails['title'] = payload.newTopic.title
            updateDetails['email'] = payload.newTopic.email
            return updateDetails
          }
          return topic
        })
      } else {
        if (updatedTopics[index].main_key == "my_people" || updatedTopics[index].main_key  == "task_topic") {
          if (isVirtualSpace == true) {
            let teamArr = updatedTopics[index].categories.map((data)=>{if (data.filter_key > 0) { return(data) }}) 
            let peopleArr = updatedTopics[index].categories.map((data)=>{if (data.filter_key < 0) { return(data) }}) 
            
            if (payload.newTopic.filter_key > 0 ) {
              teamArr.push({...payload.newTopic})          
            } else {
              peopleArr.push({...payload.newTopic})
            }
    
            let newteamArray = teamArr.filter((data)=>{ if(data && data.title !== undefined) {return (data)}});
            let newstaffArray = peopleArr.filter((data)=>{ if(data && data.title !== undefined) {return (data)}});
            
            updatedTopics[index].categories = newteamArray.concat(newstaffArray);            
          } else {
            let teamArr = updatedTopics[index].categories.map((data)=>{if (data.filter_key < 0) { return(data) }}) 
            let peopleArr = updatedTopics[index].categories.map((data)=>{if (data.filter_key > 0) { return(data) }}) 
            
            if (payload.newTopic.filter_key < 0 ) {
              teamArr.push({...payload.newTopic})          
            } else {
              peopleArr.push({...payload.newTopic})
            }
    
            let newteamArray = teamArr.filter((data)=>data && data.title !== "undefined").sort((a,b)=>a.title.localeCompare(b.title));
            let newstaffArray = peopleArr.filter((data)=>data && data.title !== "undefined").sort((a,b)=>a.title.localeCompare(b.title));
      
            updatedTopics[index].categories = newteamArray.concat(newstaffArray);
          }
              
        } else {
          updatedTopics[index].categories.push({
            ...payload.newTopic,
          });
        }
      }
    } } 

      return {
        ...state,
        topicData: updatedTopics,
        defaultTopics : updatedTopics
      };
    case TASK_UNREAD_CMT_CNT_ALL:
      return {
        ...state,
        taskUnreadCmtCntAll: payload.taskUnreadCmtCntAll,
      };
      case UPDATE_UNREAD_CHAT_COUNT_TASKLIST:
        return {
          ...state,
          taskList: payload,
        };
    case UNREAD_COMMENT_COUNT_UPDATE:
      if (state.taskList && state.taskList.length) {
        state.taskList.forEach((task) => {
          if (task.id === payload.task_id) {
            task.unread_comment_count = payload.count;
            if (state.UnreadCommentTaskList) {
              task.waiting_for_response = true;
            }
          }
        });
      }
      return {
        ...state,
      };
    case IS_PREMUIM_ESTIMATE_DISABLE:
      return {
        ...state,
        isPremiumEstimateAccess:
          localStorage.getItem("membership_package_id") == 1 ? true : false,
      };
    case IS_IBAN_MODAL_OPEN:
      return {
        ...state,
        isIBANModalOpen:
          localStorage.getItem("iban_modal_open") == 1 ? true : false,
      };
    case UPDATE_SPACE_NOTIFICATION_COUNT:
      let spaceList = state.spaces;
      if (spaceList.length) {
        spaceList.forEach((space) => {
          const { project_id, count, direction, notiType } = payload;
          if(notiType == "chat"){
            if (
              space.project_id == project_id &&
              (direction == 1 ||
                (direction == -1 && parseInt(space.chat_notification_count) >= count))
            ) {
              space.chat_notification_count =
                parseInt(space.chat_notification_count) + direction * count;
            }
          }else{
            if (space.project_id == project_id && (direction == 1 || (direction == -1 && (parseInt(space.notification_count) + parseInt(space.unread_notification_count)) >= count))) 
            {
              space.notification_count =
                parseInt(space.notification_count)+ direction * count;
            }
          }
        });
      }
      return { ...state, spaces: spaceList };
    case CREATE_SCHDULE_DETAILS:
      return {
        ...state,
        createSchduleDetails:
          Object.keys(payload.schedule).length > 0 ? payload : {},
      };
    case GET_BENCHMARK_LIST:
      let currentSchedule = payload.createSchduleDetails;
      let benchmarks = [];
      if (currentSchedule.length) {
        benchmarks = currentSchedule;
      }
      return {
        ...state,
        benchmarkList: currentSchedule.length ? benchmarks : payload.data,
        proposalKpiCount : payload.proposalKpiCount
      };
    case SET_FILTER_BENCHMARK_CATEGORY:
      return {
        ...state,
        filterBenchmarkCategory: payload.categoryId,
      };
    case SET_GLOBAL_SEARCH_STRING:
      return {
        ...state,
        globalSearchString: payload.search_string,
      };

    case UPDATE_PROVIDER_ASSIGNEE:
      let taskCardList = state.taskList;
      const { staff, template } = payload;
      const assignee = {
        staffid: staff.filter_key,
        email: "",
        mobile: "",
        profile_image: staff.image,
        full_name: staff.title,
      };
      if (taskCardList && taskCardList.length) {
        taskCardList.forEach((task) => {
          if (
            task.schedule_id &&
            task.schedule_id == payload.schedule_id &&
            task.checklist_title_id == template
          ) {
            if (task.task_assignee && task.task_assignee.providers) {
              const assigneeIndex = task.task_assignee.providers.findIndex(
                (x) => x.staffid == assignee.staffid
              );
              if (assigneeIndex === -1) {
                task.task_assignee.providers.push(assignee);
              } else {
                task.task_assignee.providers.splice(assigneeIndex, 1);
              }
            } else {
              task["task_assignee"]["providers"] = [assignee];
            }
          }
        });
        return {
          ...state,
          taskList: taskCardList,
        };
      } else {
        return state;
      }
    case UPDATE_REQUEST_TASK_ASSIGNEE:
      let targetTask = state.selectedTask ? state.selectedTask : {};
      if (payload.assignees && payload.assignees.length) {
        targetTask["assignees_type"]["assginee_id"] =
          payload.assignees[0]["filter_key"];
        targetTask["assignees_type"]["assginee_type"] =
          payload.assignees[0]["main_key"];
      } else {
        targetTask["assignees_type"] = {};
      }

      return {
        ...state,
        // taskList: taskList,
        selectedTask: targetTask,
      };
    case UPDATE_ALL_PROJ_NOTI_COUNT:
      let allSpaces = state.spaces;
      const { projects } = payload;
      var unreadMsgArr = {};
      var updatedSpaceArr = [];
      if (allSpaces.length > 0 && projects.length > 0) {
        projects.map((item) => {
          unreadMsgArr[item._id] = item.unreadCount;
        });
        allSpaces.map((item) => {
          if (unreadMsgArr[item.project_id] && unreadMsgArr[item.project_id] > 0) {
            item.chat_notification_count = String(unreadMsgArr[item.project_id]);
          }else{
            item.chat_notification_count = "0";
          }
          updatedSpaceArr.push(item);
        });
      }
      return { ...state, spaces: updatedSpaceArr };
    case TASK_TYPE_FILTER:
      return { ...state, taskTypeFilter: payload.type };
    case FETCH_MAILS:
      let messages = payload.data.data;

      if (payload.data.pretoken) {

        console.log('token', payload.data.pretoken);

        messages.inbox_messages = [...(state.inboxMessages || []), ...(messages.inbox_messages || [])];
        // messages.important_messages = [...state.importantMessages,...messages.important_messages];
      }
      
      return {
        ...state,
        importantMessages: messages ? messages.important_messages : [],
        inboxMessages: messages ? messages.inbox_messages : [],
        statusClose: messages ? messages.status_close : "",
        mailNextPageToken: messages ? messages.nextPageToken : "",
        mailResultSizeEstimate: messages ? messages.resultSizeEstimate : "",
        spamMessage: messages ? messages.spam_messages : [],
        priority_messages : messages ? messages.priority_messages ? messages.priority_messages : [] : [],
        schedule_messages : messages ? messages.schedule_messages : []
      };

    case CALENDARS_DETAILS:
      return {
        ...state,
        calendarsDefaultTopics: payload,
      };

    case CALENDARS_ASSIGNEE:

      let tasks = state.selectedTask;
      if (tasks) {
        tasks = payload;
      }

      return {
        ...state,
        selectedTask: tasks,
      };
    case CHECK_LIST_UPDATE:
      let task = state.selectedTask;
      if (task && task.is_requested && task.is_requested == 1) 
      {
        task.checklist = payload.checklist.map((checklist) => checklist)
      }
      return {
        ...state,
        selectedTask: task,
      };
    case SYN_MYR_TASK_ASSIGNEE:

      let sync_data = state.selectedTask;
      if (sync_data) {
        sync_data = payload;
      }
      return {
        ...state,
        selectedTask: sync_data,
      };

    case REMOVE_BENCHMARK:
      return { ...state, benchmarkList: state.benchmarkList.filter(x => x.id != payload.benchmark_id) }
    case UPDATE_TASK_ASSIGNEE_NEW:
      const contact_role =localStorage.getItem("contact_role");
      const userType = localStorage.getItem("user_type");
      let selectedTaskNew = state.selectedTask ? state.selectedTask : {};
      let tasklistNew = state.taskList ? state.taskList : [];
      if ((window.location.pathname == "/calendar" || window.location.pathname == "/chat") && selectedTaskNew && Object.keys(selectedTaskNew).length) {
        if (payload.schedule_assignees.length > 0) {
          selectedTaskNew.schedule_assignees = payload.schedule_assignees            
        }
          if (selectedTaskNew.assignee_list && selectedTaskNew.assignee_list.constructor === Array) {
            var mainKeys = selectedTaskNew.assignee_list.map((e) => e.main_key)
            if (payload.type == "equipments") {
              let Index = mainKeys.findIndex((e) => e == payload.type);
              if (Index > -1) {
                let IndexT = task.assignee_list[Index]['categories'].findIndex((taskassign) => taskassign.filter_key == payload.filter_key)
                if (IndexT > -1) {
                  let IndexU = task.assignee_list[Index]['categories'][IndexT].sub_category.findIndex((taskassign) => taskassign.id == payload.sub_category[0].id)
                  if (IndexU > -1) {
                    task.assignee_list[Index]['categories'][IndexT].sub_category = task.assignee_list[Index]['categories'][IndexT].sub_category.filter((taskassign) => taskassign.id != payload.sub_category[0].id)
                  }else{
                    task.assignee_list[Index]['categories'][IndexT].sub_category.push(payload.sub_category[0].id)
                  }
                }else{
                  task.assignee_list[Index]['categories'] = [{ filter_key: payload.filter_key, sub_category: payload.sub_category.map((s) => s.id) }]
                }
              }else{
                  task.assignee_list.push({
                    main_key: payload.type,
                    categories: [{ filter_key: payload.filter_key, sub_category: payload.sub_category.map((s) => s.id) }]
                  })
              }
            } else if(payload.isRequest && payload.type == "provider_task" && userType == "contact" && CUSTOM_ASSIGNEE_CHANGE.includes(contact_role)){
              let Index = mainKeys.findIndex((e) => e == payload.type);
              if (Index > -1) {
                let IndexT = task.assignee_list[Index]['categories'].findIndex((taskassign) => taskassign.filter_key == payload.filter_key)
                if (IndexT > -1) {
                  let IndexU = task.assignee_list[Index]['categories'][IndexT].sub_category.findIndex((taskassign) => taskassign.id == payload.sub_category[0].id)
                  if (IndexU > -1) {
                    task.assignee_list[Index]['categories'][IndexT].sub_category = task.assignee_list[Index]['categories'][IndexT].sub_category.filter((taskassign) => taskassign.id != payload.sub_category[0].id)
                  }else{
                    task.assignee_list[Index]['categories'][IndexT].sub_category.push(payload.sub_category[0].id)
                  }
                }else{
                  task.assignee_list[Index]['categories'] = [{ filter_key: payload.filter_key, sub_category: payload.sub_category.map((s) => s.id) }]
                }
              }else{
                  task.assignee_list.push({
                    main_key: payload.type,
                    categories: [{ filter_key: payload.filter_key, sub_category: payload.sub_category.map((s) => s.id) }]
                  })
              }
            } else {
            const index = mainKeys.findIndex(a => a == payload.type);
            if (index > -1) {
              let isExist = selectedTaskNew.assignee_list[index] && selectedTaskNew.assignee_list[index].categories.findIndex((taskassign) => taskassign.filter_key == payload.filter_key)
              if (isExist > -1) {
                selectedTaskNew.assignee_list[index]['categories'] = selectedTaskNew.assignee_list[index]['categories'].filter((taskassign) => taskassign.filter_key != payload.filter_key)
              } else {
                if (payload.isRequest && payload.type == "provider_task" ) {
                  selectedTaskNew.assignee_list[index]['categories'] = [{ filter_key: payload.filter_key }]
                } else {
                  selectedTaskNew.assignee_list[index]['categories'].push({ filter_key: payload.filter_key })
                }
              }
            } else {
              selectedTaskNew['assignee_list'].push({
                main_key: payload.type,
                categories: [{ filter_key: payload.filter_key }]
              })
            }
          }
          } else {
            if (payload.type == "equipments") {
              task['assignee_list'] = [{
                main_key: payload.type,
                categories: [{ filter_key: payload.filter_key, sub_category: payload.sub_category }]
              }]
            }else{
            selectedTaskNew['assignee_list'] = [{
              main_key: payload.type,
              categories: [{ filter_key: payload.filter_key }]
            }]
          }
          }
          tasklistNew = tasklistNew.map((task) => {
            if (task.id == payload.taskId) {
              if (payload.schedule_assignees.length > 0) {
                task.schedule_assignees = payload.schedule_assignees            
              }
                if (task.assignee_list && task.assignee_list.constructor === Array) {
                  var mainKeys = task.assignee_list.map((e) => e.main_key)
                  if (payload.type == "equipments") {
                    let Index = mainKeys.findIndex((e) => e == payload.type);
                    if (Index > -1) {
                      let IndexT = task.assignee_list[Index]['categories'].findIndex((taskassign) => taskassign.filter_key == payload.filter_key)
                      if (IndexT > -1) {
                        let IndexU = task.assignee_list[Index]['categories'][IndexT].sub_category.findIndex((taskassign) => taskassign.id == payload.sub_category[0].id)
                        if (IndexU > -1) {
                          task.assignee_list[Index]['categories'][IndexT].sub_category = task.assignee_list[Index]['categories'][IndexT].sub_category.filter((taskassign) => taskassign.id != payload.sub_category[0].id)
                        }else{
                          task.assignee_list[Index]['categories'][IndexT].sub_category.push(payload.sub_category[0].id)
                        }
                      }else{
                        task.assignee_list[Index]['categories'] = [{ filter_key: payload.filter_key, sub_category: payload.sub_category.map((s) => s.id) }]
                      }
                    }else{
                        task.assignee_list.push({
                          main_key: payload.type,
                          categories: [{ filter_key: payload.filter_key, sub_category: payload.sub_category.map((s) => s.id) }]
                        })
                    }
                  } else if(payload.isRequest && payload.type == "provider_task" && userType == "contact" && CUSTOM_ASSIGNEE_CHANGE.includes(contact_role)){
                    let Index = mainKeys.findIndex((e) => e == payload.type);
                    if (Index > -1) {
                      let IndexT = task.assignee_list[Index]['categories'].findIndex((taskassign) => taskassign.filter_key == payload.filter_key)
                      if (IndexT > -1) {
                        let IndexU = task.assignee_list[Index]['categories'][IndexT].sub_category.findIndex((taskassign) => taskassign.id == payload.sub_category[0].id)
                        if (IndexU > -1) {
                          task.assignee_list[Index]['categories'][IndexT].sub_category = task.assignee_list[Index]['categories'][IndexT].sub_category.filter((taskassign) => taskassign.id != payload.sub_category[0].id)
                        }else{
                          task.assignee_list[Index]['categories'][IndexT].sub_category.push(payload.sub_category[0].id)
                        }
                      }else{
                        task.assignee_list[Index]['categories'] = [{ filter_key: payload.filter_key, sub_category: payload.sub_category.map((s) => s.id) }]
                      }
                    }else{
                        task.assignee_list.push({
                          main_key: payload.type,
                          categories: [{ filter_key: payload.filter_key, sub_category: payload.sub_category.map((s) => s.id) }]
                        })
                    }
                  } else {
                  const index = mainKeys.findIndex(a => a == payload.type);
                  if (index > -1) {
                    let isExist = task.assignee_list[index] && task.assignee_list[index].categories.findIndex((taskassign) => taskassign.filter_key == payload.filter_key)
                    if (isExist > -1) {
                      task.assignee_list[index]['categories'] = task.assignee_list[index]['categories'].filter((taskassign) => taskassign.filter_key != payload.filter_key)
                    } else {
                      if (payload.isRequest && payload.type == "provider_task") {
                        task.assignee_list[index]['categories'] = [{ filter_key: payload.filter_key }]
                      }else{
                        task.assignee_list[index]['categories'].push({ filter_key: payload.filter_key })
                      }
                    }
                  } else {
                    task['assignee_list'].push({
                      main_key: payload.type,
                      categories: [{ filter_key: payload.filter_key }]
                    })
                  }
                }
                } else {
                  if (payload.type == "equipments") {
                    task['assignee_list'] = [{
                      main_key: payload.type,
                      categories: [{ filter_key: payload.filter_key, sub_category: payload.sub_category }]
                    }]
                  }else{
                    task['assignee_list'] = [{
                      main_key: payload.type,
                      categories: [{ filter_key: payload.filter_key }]
                    }]
                  }
                }
              // }
              // selectedTaskNew = task
              return task
            }
            return task;
          })
      }else{
        tasklistNew = tasklistNew.map((task) => {
          if (task.id == payload.taskId) {  
           
              if (payload.schedule_assignees.length > 0) {
                  task.schedule_assignees = payload.schedule_assignees            
              }
              const userType =localStorage.getItem("user_type");
              const contact_role =localStorage.getItem("contact_role");
              if (task.assignee_list && task.assignee_list.constructor === Array) {
                var mainKeys = task.assignee_list.map((e) => e.main_key)
                if (payload.type == "equipments") {
                  // if (payload.type == "equipments" || ((payload.type == "provider_task" && CUSTOM_CLIENT_IDS.includes(client_id) && CUSTOM_ADMIN_ROLES[client_id] && !CUSTOM_ADMIN_ROLES[client_id].includes(contact_role) && contact_role != 3))) {
                  let Index = mainKeys.findIndex((e) => e == payload.type);
                  if (Index > -1) {
                    let IndexT = task.assignee_list[Index]['categories'].findIndex((taskassign) => taskassign.filter_key == payload.filter_key)
                    if (IndexT > -1) {
                      let IndexU = task.assignee_list[Index]['categories'][IndexT].sub_category.findIndex((taskassign) => taskassign == payload.sub_category[0].id)
                      if (IndexU > -1) {
                        task.assignee_list[Index]['categories'][IndexT].sub_category = task.assignee_list[Index]['categories'][IndexT].sub_category.filter((taskassign) => taskassign != payload.sub_category[0].id)
                      }else{
                        task.assignee_list[Index]['categories'][IndexT].sub_category.push(payload.sub_category[0].id)
                      }
                    }else{
                      task.assignee_list[Index]['categories'] = [{ filter_key: payload.filter_key, sub_category: payload.sub_category.map((s) => s.id) }]
                    }
                  }else{
                      task.assignee_list.push({
                        main_key: payload.type,
                        categories: [{ filter_key: payload.filter_key, sub_category: payload.sub_category && payload.sub_category.map((s) => s.id) }]
                      })
                  }
                } else if(payload.isRequest && payload.type == "provider_task" && userType == "contact" && CUSTOM_ASSIGNEE_CHANGE.includes(contact_role)){
                  let Index = mainKeys.findIndex((e) => e == payload.type);
                  if (Index > -1) {
                    let IndexT = task.assignee_list[Index]['categories'].findIndex((taskassign) => taskassign.filter_key == payload.filter_key)
                    if (IndexT > -1) {
                      let IndexU = task.assignee_list[Index]['categories'][IndexT].sub_category.findIndex((taskassign) => taskassign == payload.sub_category[0].id)
                      if (IndexU > -1) {
                        task.assignee_list[Index]['categories'][IndexT].sub_category = task.assignee_list[Index]['categories'][IndexT].sub_category.filter((taskassign) => taskassign != payload.sub_category[0])
                      }else{
                        task.assignee_list[Index]['categories'][IndexT].sub_category.push(payload.sub_category[0])
                      }
                    }else{
                      task.assignee_list[Index]['categories'] = [{ filter_key: payload.filter_key, sub_category: payload.sub_category.map((s) => s) }]
                    }
                  }else{
                      task.assignee_list.push({
                        main_key: payload.type,
                        categories: [{ filter_key: payload.filter_key, sub_category: payload.sub_category && payload.sub_category.map((s) => s) }]
                      })
                  }
                } else {
                const index = mainKeys.findIndex(a => a == payload.type);
                if (index > -1) {
                  let isExist = task.assignee_list[index] && task.assignee_list[index].categories.findIndex((taskassign) => taskassign.filter_key == payload.filter_key)
                  if (isExist > -1) {
                    task.assignee_list[index]['categories'] = task.assignee_list[index]['categories'].filter((taskassign) => taskassign.filter_key != payload.filter_key)
                  } 
                } else {
                  task['assignee_list'].push({
                    main_key: payload.type,
                    categories: [{ filter_key: payload.filter_key }]
                  })
                }
              }
              } else {
                if (payload.type == "equipments" || (payload.isRequest && payload.type == "provider_task" && userType == "contact" && CUSTOM_ASSIGNEE_CHANGE.includes(contact_role))) {
                  task['assignee_list'] = [{
                    main_key: payload.type,
                    categories: [{ filter_key: payload.filter_key, sub_category: payload.sub_category }]
                  }]
                }else{
                  task['assignee_list'] = [{
                    main_key: payload.type,
                    categories: [{ filter_key: payload.filter_key }]
                  }]
                }
              }
            // }
            selectedTaskNew = task
            return task
          }
          return task;
        })
      }
      if (payload.myr_total > 0 && selectedTaskNew && Object.keys(selectedTaskNew).length) {
        selectedTaskNew['myr_total'] = payload.myr_total
      }
      if (payload.isOpen) {
        return {
          ...state,
          selectedTask: selectedTaskNew,
          taskList: tasklistNew,
        };
      } else {
        return {
          ...state,
          taskList: tasklistNew,
        };
      }
    case GET_FLOOR_DOCUMENT:
      return {
        ...state,
        getFloorDocument: payload
      }
    case GET_FLOOR_LIST:
      return {
        ...state,
        getSapceFloorList: payload
      }
    case SELECTED_FLOOR_ID:
      return {
        ...state,
        getSelectedFloorId: payload
      }
    case SELECTED_TYPE_OF_SPACE_ID:
      return {
        ...state,
        getSelectedTypeOfSpaceId: payload
      }
    case SELECTED_SUB_FLOOR_ID:
      return {
        ...state,
        getSelectedSubFloorId: payload
      }
    case SET_POI_LAT_LNG:
      return {
        ...state,
        setPOILatLng: payload
      }
    case GET_POIS_LIST:
      return {
        ...state,
        getPOIList: payload
      }
    case GET_ACTIVE_POIS_LIST:
      return {
        ...state,
        getActivePOIList: payload
      }
    case GET_AR_LOCAL_ANCHORS:
      return {
        ...state,
        get_Ar_Local_Anchors: payload
      }
    case UPDATE_BENCHMARK_PROPOSALS_COMMENTS:
      const { proposal_id, comment, operation } = payload;
      let proposal_list = state.benchmarkDetail.proposal_list;
      if (proposal_list.length) {
        proposal_list[0].proposals.forEach((proposal) => {
          if (proposal.proposal_id == proposal_id) {
            switch (operation) {
              case -1:
                proposal.proposal_comments = proposal.proposal_comments.filter((x) => x.comment_id != comment.comment_id)
                break;
              case 0:
                proposal.proposal_comments.forEach((x) => {
                  if (x.comment_id == comment.comment_id) {
                    x.comment = comment.comment;
                  }
                })
                break;
              case 1:
                proposal.proposal_comments.push(comment)
                break;
              default:
                break;
            }
          }
        });
        return {
          ...state,
          benchmarkDetail: { ...state.benchmarkDetail, proposal_list }
        }
      }
      return state;
    case UPDATE_MAIL_CALENDAR_COUNTER:

      // if (state.calendarData) {
      //   const totalMails = [...(payload.inbox_messages || []), ...(payload.important_messages || [])];
      //   let unread_comments = 0;

      //   (totalMails || []).map((email) => {
      //     unread_comments = unread_comments + (email['thread']['mentions'] || []).filter((comment) => comment['is_read'] == 0).length;
      //   });

      //   var data = state.calendarData;
      //   data[3].completed_count = unread_comments;
      //   data[3].todo_count = payload.filter == 'important' ? (payload.important_messages || []).length : (payload.inbox_messages || []).length;
      
      
      //   localStorage.setItem("calendarData", JSON.stringify(data));

      //   return {
      //     ...state,
      //     calendarData: data,
      //   };
      // }

      return {
        ...state,
      };
    case FETCH_PROPOSAL_COMMENTS:
      const { comments, id } = payload;
      let proposals_list = state.benchmarkDetail.proposal_list;
      if(proposals_list.length)
      {
        proposals_list[0].proposals.forEach((proposal) => {
          if (proposal.proposal_id == id) {
            proposal.proposal_comments = comments;
          }
        });
      }

      return {
        ...state,
        benchmarkDetail: { ...state.benchmarkDetail, proposals_list }
      }
    case UPDATE_TASK_ASSIGNEE_LIST:
      let { defaultTopics: allTopics } = state;
      let { assignee: currentAssignee, taskId } = payload;
      if (taskId > 0) {
        allTopics = allTopics.map((topic) => {
          let isAssignedType = currentAssignee.filter((assined) => assined.name == topic.main_key)
          if (isAssignedType.length) {
            let categoryList = topic.categories.map((provider) => {
              let isAssignedId = currentAssignee.filter((assined) => assined.id == provider.filter_key)
              if (isAssignedId.length) {
                provider['is_assigned'] = provider['is_assigned'] && provider['is_assigned'] == 1 ? 0 : 1
                return provider
              }
              return provider
            });
            topic['categories'] = categoryList
            return topic
          } else {
            let categoryList = topic.categories.map((provider) => {
              provider['is_assigned'] = 0
              return provider
            });
            topic['categories'] = categoryList
            return topic
          }
        })
      } else {
        allTopics = allTopics.map((topic) => {
          let isAssignedType = currentAssignee.filter((assined) => assined.name == topic.main_key)
          if (isAssignedType.length) {
            let categoryList = topic.categories.map((provider) => {
              let isAssignedId = currentAssignee.filter((assined) => assined.id == provider.filter_key)
              if (isAssignedId.length) {
                provider['is_assigned'] = provider['is_assigned'] && provider['is_assigned'] == 1 ? 0 : 1
                return provider
              }
              return provider
            });
            topic['categories'] = categoryList
            return topic
          }
          return topic
        })
      }

      return {
        ...state,
        defaultTopics: allTopics
      }
      break;
    case UPDATE_MAIL_CC_ASSIGNEE:
      var defaultTopics_ = state.defaultTopics;
      defaultTopics_ = defaultTopics_.map((top) => {
        if (payload.data.type == top.main_key) {
          top.categories.map((cat) => {
            if (cat.email == payload.data.details.id) {
              cat.is_assigned = payload.type == 'set' ? 1 : 0;
            }
            return cat;
          })

        }

        return top;
      })
      return {
        ...state,
        defaultTopics: defaultTopics_
      }

    case GET_TASK_CHAT_ASSIGNEE:

      let update_chat_assignee = state.defaultTaskAssignees;
      if (update_chat_assignee) {
        update_chat_assignee = payload.data;
      }

      return {
        ...state,
        defaultTaskAssignees: update_chat_assignee,
      };
      break;
    case PRODUCT_TOUR:
      return {
        ...state,
        productTour: payload.data
      }
      break;
    case VIRTUAL_SPACE_LIST:
      return {
        ...state,
        virtualSpaceList: payload.data
      }
      break;
    case UPDATE_VIRTUAL_SPACE_NOTI_COUNT:
      var vSpaceList = state.virtualSpaceList.slice();
      // check the msg comes from virtual space or not
      if (vSpaceList && vSpaceList.length > 0) {
        var vSpaceIndex = vSpaceList.findIndex(item => item.id == payload.project_id);
        if (vSpaceIndex > -1) {
          if(payload.notiType == "chat"){
            vSpaceList[vSpaceIndex].unread_chat_msg_count = parseInt(vSpaceList[vSpaceIndex].unread_chat_msg_count) + payload.direction * payload.count;
          }else{
            vSpaceList[vSpaceIndex].unread_notification_count = parseInt(vSpaceList[vSpaceIndex].unread_notification_count) + payload.direction * payload.count;
          }
        }
      }
      return {
        ...state,
        virtualSpaceList: vSpaceList
      }
      break;
    case TASK_BULK_ACTION:
      let tasklistBulkAction = state.taskList;
      let updateTaskList = []
      tasklistBulkAction.forEach((task) => {
        switch (payload.type) {
          case 'delete_task':
            if (!payload.taskIds.includes(task.id)) {
              updateTaskList.push(task)
            }
            break;
          case 'status_change':
            if (payload.taskIds.includes(task.id)) {
              task['status'] = `${payload.status}`
              task['is_requested'] = '0'
            }
            updateTaskList.push(task)
            break;
          case 'task_convert_to_request':
            if (payload.taskIds.includes(task.id)) {
              task['is_requested'] = '1'
            }
            updateTaskList.push(task)
            break;
          default:
            break;
        }
      })

      return {
        ...state,
        taskList: updateTaskList
      }
      break;
    case GET_CALENDAR_TASK_LIST:
      return {
        ...state,
        taskList: payload
      }
    case GET_MAIL_ASSIGNEE:

      state.mail_assignees = [];

      return {
        ...state,
        mail_assignees: payload
      }
      break;
      case SET_GET_LANGUAGE:
      return {
        ...state,
        get_language: payload
      }
      break;
      
      case LEAD_MODAL_TOGGLE:
      return {
        ...state,
        isLeadOpen: payload,
      };
      break;
    case SET_NEW_MEETING:
        return {
          ...state,
          get_link: payload
        }
        break;
    case INVOICE_COUNTER:
        return {
          ...state,
          get_counts: payload
        }
        break;
    case ESTIMATE_COUNTER:
        return {
          ...state,
          get_counts: payload
        }
        break;
    case ESTIMATE_TOPICS:
        return {
          ...state,
          estimateTopics: payload
        }
        break;
    case GET_COMPANY_EMPLOYEE:
      return {
        ...state,
        getCompanyEmployee: payload
      }
      break;
    case COUNT_LOCK_TASKS:
        return {
          ...state,
          getLockTaskCount: payload
        }
        break;
    case UPDATE_HIDE_LOADER:
        return {
          ...state,
           hideLoader: payload
        }
        break;
    case GET_DEFAULT_TOPIC_LIST:
        return {
          ...state,
          getDefaultTopicList: payload
        }
    case GET_USER_INFO:
        return {
          ...state,
          getUserInfo: payload
        }
    case GET_ATTENDANCE:
        return {
          ...state,
          getAttendance: payload
        }
    case GET_ATTENDANCE_LIST:
        return {
          ...state,
          getAttendanceList: payload
        }
    case GET_SPACE_WORK_SCORE:
        return {
          ...state,
          space_work_score: payload.spaces,space_logo_url:payload.logo_url,image_logo_url:payload.image_logo_url,user_placeholder:payload.user_placeholder
        }
        break;
    
    case SET_CATEGORY_NOTIFICATION_COUNTS:
      let taskTopics = state.topicData;
      let {main_key, filter_key, count, direction, notification_param} = payload;
      let localStorageCount = Number(localStorage.getItem("unread_schedule_comment"));
      const currentPage = window.location.pathname;

      if(taskTopics && taskTopics.length)
      {
        taskTopics.forEach((topic) => {
          if(topic.main_key == main_key)
          {
            topic.categories.forEach((subTopic) => {
              if(subTopic.filter_key == filter_key)
              {
                subTopic.total_noti_count = subTopic.total_noti_count + (count * direction);
                subTopic.total_notification_count[notification_param] = subTopic.total_notification_count[notification_param] + (count * direction);
                localStorageCount = localStorageCount + (count * direction);
              }
            });
          }
        });
      }
      if(currentPage == "/proposals")
      {
        let element = document.getElementById("proposal-badge-count");
        if(element)
        {
          localStorage.setItem("unread_schedule_comment", localStorageCount);
          document.getElementById("proposal-badge-count").textContent = localStorageCount;
        }
      }

      return {...state, topicData: taskTopics}
    case PROVIDER_REPORTING_DATA:
        return {
          ...state,
          getReportingEmployee: payload.data
        }
        break;
    case BENCHMARKS_COUNTER:
        return {
          ...state,
          benchmarkCounter: payload
        }
        break;
    case GET_ATTENDANCE_COUNT_WEEK_CALENDAR:
        return {
          ...state,
          getAttendanceCountWeekCalendarer: payload.data
        }
        break;
    case GET_PROJECT_ATTENDANCE_COUNT:
        return {
          ...state,
          projectAttendancCount: payload.data
        }
        break;
    case STAFF_COUNTER:
        return {
          ...state,
          staffCounter: payload
        }
        break;
     case STAFF_TOPICS:
        return {
          ...state,
          staffTopics: payload
        }
        break;
    case SET_TEAMS:
      return {
        ...state,
        userTeams: payload
      }
      break;
      case GET_TASK_LISTS:
        return {
          ...state,
          taskList: payload.taskList,
          // selectedTask: payload.selectedTask
        }
        
        case GET_TASK_ATTACHMENTS:
        return {
          ...state,
          task_attachments: payload
        }
        case SET_EQUIPMENT_CATEGORY_ID:
        return {
          ...state,
          equipment_category_id: payload
        }
        case GET_DETAILS_FOR_LOGIN_AS_USER:
        return {
          ...state,
          get_detail_for_login_as_user: payload
        }
        break;
      case SUBSCRIPTION_COUNTER:
        return {
          ...state,
          subCounter: payload,
          // selectedTask: payload.selectedTask
        }
        break;
      case EQUIPMENTS_CATEGORY_TOPICS:
        return {
          ...state,
          equipmentTopics: payload,
          // selectedTask: payload.selectedTask
        }
        break;
      case CLIENT_TAB_DATA:
        return {
          ...state,
          spaceClient: payload
        }
        break;
    case COMMANOFFCANVAS_IS_OPEN:
      return {
        ...state,
        commanoffcanvasIsOpen: payload,
      };
    case IMAGEVIEWER_IS_OPEN:
      return {
        ...state,
        imageviewerisOpen: payload,
      };
    case DAY_DIFFERENCE:
      return {
        ...state,
      daterangeDayDifference: payload,
      };
    case APPLIED_DATE_RANGE_FILTER:
      return {
        ...state,
      daterangeAppliedFilters: payload,
      };
    case IS_BACKDROP:
      return {
        ...state,
        isBackdrop: payload,
      };
    case OPEN_CHATAI_INPUTBOX:
      return {
        ...state,
        openChatAi: payload,
      };
      case CREATE_TASK_REQ_INPUT_ICONS:
      return {
        ...state,
        createTaskReqInputIcons: payload,
      };
    case DAY_GRID_MONTH:
      return {
        ...state,
        isDayGridMonth: payload,
      };
    case DAY_GRID_WEEK:
      return {
        ...state,
        isDayGridWeek: payload,
      };
    case DAY_GRID_DAY:
      return {
        ...state,
        isDayGridDay: payload,
      };
    case IS_TASKLIST_VIEW:
      return {
        ...state,
        isTasklistView: payload,
      };
      case IS_AI_CHAT:
      return {
        ...state,
        isAiChat: payload,
      };
    case OPEN_TASK_DETAILS_CALENDAR:
      return {
        ...state,
        calendarCanvasIsOpen: payload,
      };
    case UPDATE_SPACE_CONTENT:
      return {
        ...state,
        content: payload,
      };
    case TASK_ATTACHMENTS_FOR_CREATE_TASK:
      return {
        ...state,
        attachmentsForTask: payload,
      };
    case GET_PROJECT_THEME:
      return {
        ...state,
        themeColor: payload,
      };
    case _NEW_CLIENT_DATA:
      return {
        ...state,
        newCusData: payload,
      };
    case GET_OPERATOR_BY_CATEGORY:
      return {
        ...state,
        operatorListByCategory: payload,
      };
    case COUNTRY_CODE:
      return {
        ...state,
        country_codes: payload,
      };
    case GET_ALL_USER_LIST_FOR_STAFF:
      return {
        ...state,
        getAllUserForStaff: payload,
      };
    case SELECTED_CHAT_USER:
      return {
        ...state,
        selectedUserForChat: payload,
      };
    case IS_ALL_CHAT_USERLIST_SHOW:
      return {
        ...state,
        isAllChatUserlistShow: payload,
      };
    case GENERAL_UNREAD_COUNT:
      return {
        ...state,
        generalUnreadCount: payload,
      };
    case SEARCH_STRING_CHAT_ALL_USER:
      return {
        ...state,
        searchStringForAllChatUser: payload,
      };
    case SET_SELECTED_CALENDAR_DATE:
      return {
        ...state,
        getSelectedCalendarDate: payload,
      };
    case SUCCESS_FLAG:
      return {
        ...state,
        newCusFlag: payload,
      };
      case SET_FAVORITE_DASHBOARD:
        return {
          ...state,
          favDashboard: payload,
        }
      case SELECTED_VIEW:
        return {
          ...state,
          globalView: payload,
        }
    case SELECTED_DATE_VIEW:
      return {
        ...state,
        dateView: payload,
          }
      case DATERANGE_FILTER_APPLIED:
        return {
          ...state,
          daterangeFilterApplied: payload,
        }
      case CALENDAR_VIEW:
        return {
          ...state,
          calendarView: payload,
        }
    case BENCHMARK_CREATE_OR_NOT:
      return {
        ...state,
        benchmark_create: payload
      }
    case SELECTED_DATERANGE_FILTER:
      return {
        ...state,
        appliedDaterangeFilter: payload
      }
    case PINNED_DELAY_FILTER:
      return {
        ...state,
        pinDelayfilter: payload
      }
      case GET_CHECKED_TASK:
      return {
        ...state,
        getCheckedTask: payload
      }
    case INV_HEADER_CAL_COUNTER:
      return {
        ...state,
        inv_count: payload
      }
    case TASKLIST_LOADER:
      return {
        ...state,
        tasklistLoader: payload
      }
      case GET_EQUIPMENTS_LIST:
      return {
        ...state,
        get_equipments_list: payload
      }
      case SET_EQUIPMENTS_QUANTITY:
      return {
        ...state,
        set_equipments_quantity: payload
      }
      case SET_EQUIPMENTS_QUANTITY_LIST:
      return {
        ...state,
        set_equipments_quantity_list: payload
      }
      case SET_NEW_EQUIPMENT:
      return {
        ...state,
        set_new_equipment: payload
      }
      case LAST_CHAT_SESSION:
      return {
        ...state,
        lastChatSession: payload
      }
      case  METRICS_DETAILS:
      return{
        ...state,
        metricsDetails : payload
      }
      case FILTER_FOR_3D_MODEL:
      return {
        ...state,
        filterfor3dPlan: payload
      }
      case UPDATE_TASK_LIST:
      return {
        ...state,
        taskList: payload
      }
      case SELECTED_DATE_CHATAI:
      return {
        ...state,
        ChatAiDate: payload
      }
      case DASHBOARD_DETAILS_WITH_ALL_SPACE:
        return {
          ...state,
          dashBoradDetails: payload
        }
      case CREATE_TASK_MODAL_IS_OPEN:
        return {
          ...state,
          createTaskModalIsopen: payload
        }
      case DASHBOARD_VIEW:
        return {
          ...state,
          dashBoradView: payload
        }
        case DASHBOARD_FILTER:
          return {
            ...state,
            dashBoradFilter: payload
          }

      case TASK_RATING:
          return {
            ...state,
            taskList: payload
          }
      case UPDATE_WEEK_CALENDAR:
          return {
            ...state,
            chatcalendarData: payload
          }
      case TASK_AND_MESSAGES:
          return {
            ...state,
            taskandMessages: payload
          }
      case FIRST_TASK_DATE:
          return {
            ...state,
            firstTaskDate: payload
          }
      case LAST_TASK_DATE:
          return {
            ...state,
            lastTaskDate: payload
          }

      case LOADING_BENCHMARK_DETAILS:
        return {
          ...state,
          isLoadingBenchmarkInfo: payload.flag
        }
      case LOADING_BENCHMARK_WEEK_SCHEDULE:
        return {
          ...state,
          isLoadingBenchmarkWeekSchedule: payload.flag
        }
      case FIRST_MESSAGE_DATE:
          return {
            ...state,
            firstMessageDate: payload
          }
        
      case DASHBOARD_DETAILS_WITH_TOTAL:
          return {
            ...state,
            dashboradSummaryData: payload
          }
    case CREATE_TASK_MODAL_IS_OPEN:
      return {
        ...state,
        createTaskModalIsopen: payload
      }
    case SHOW_THREE_DAYS_WEEK:
      return {
        ...state,
        displayThreeDays: payload
      }
    case CREDITNOTES_DETAILS:
      return {
        ...state,
        creditNoteDetails: payload
      }
    case MISSED_CALL:
      var defaultTopics_ = state.defaultTopics;
      defaultTopics_ = defaultTopics_.map((top) => {
          top.categories.map((category) => {
            if(category.chat_data){
            if (category.chat_data.groupId == payload.group) {
              category.total_notification_count.missed_call_count = category.total_notification_count.missed_call_count + 1 ;
            }}
            return category;
          })
        return top;
      })
      return {
        ...state,
        defaultTopics: defaultTopics_
      }
    case CHAT_UNREADMESSAGE_COUNT:
      var defaultTopics_ = state.defaultTopics ;
      defaultTopics_ = defaultTopics_.map((top) => {
          top.categories.map((category) => {
            if (payload.notiType == "chatMSG") {
              if (category.chat_data && category.chat_data.groupId == payload.data) {
                if (payload.action == "add") {
                  if (category && category.total_notification_count && category.total_notification_count) {
                    if (payload.count == 'Chat') {
                      category.total_notification_count.chat_count = category.total_notification_count.chat_count + 1 ;
                    }else{
                      category.total_notification_count.chat_count = category.total_notification_count.chat_count + payload.count ;
                    }
                  }
                }else if(payload.action == "remove"){
                  if (category && category.total_notification_count && category.total_notification_count) {
                    category.total_notification_count.chat_count = 0;
                  }
                }
              }
            }else if(payload.notiType == "task"){
              if (payload.action == "add") {
                if (top.main_key == "my_task") {  
                  if (payload && payload.data && payload.data.filter_key && category.filter_key == payload.data.filter_key && top.main_key == payload.data.main_key) {
                    if (category && category.total_notification_count && category.total_notification_count) {
                      category.total_notification_count.task_count = category.total_notification_count.task_count + 1;
                    }
                  }
                }else{
                  if (payload && payload.data && payload.data.filter_key && category.filter_key == payload.data.filter_key && category.main_sub_key == payload.data.main_sub_key) {
                    if (category && category.total_notification_count && category.total_notification_count) {
                    category.total_notification_count.task_count = category.total_notification_count.task_count + 1;
                    }
                  }
                }
              }
            }
          return category;
          })
        return top;
      })
      return {
        ...state,
        defaultTopics: defaultTopics_
      }  
    case CHANGE_WEEK_CAL_NAVI:
      return {
        ...state,
        weekCalNaviSide: payload
      }
    case UNREADTASK_COMMENT:
      var defaultTopics_ = state.defaultTopics ? state.defaultTopics: [] ;
      var unreadTaskComment = "my_task" !== payload.data ? state.unreadTaskComment : 0 ;
      var taskFilter = state.taskFilters ? state.taskFilters : []
          defaultTopics_ && defaultTopics_.map((top) => {
              top.categories.map((category) => {
                taskFilter && taskFilter.map((item) => {
                  if (category.filter_key == item.filter_key && category.main_sub_key == item.main_sub_key){ 
                    if ("my_task" == payload.data) {
                      unreadTaskComment += category.total_notification_count && category.total_notification_count.task_count;
                    }else{
                      unreadTaskComment = category.total_notification_count.task_count
                    }
                  }
                })
              })
          })

      return {
        ...state,
        unreadTaskComment: unreadTaskComment
      }

    case UPDATE_SELECTED_TASK_DETAIL:
      const schedule_id = payload
      return {
        ...state,
        selectedTask: {...state.selectedTask, schedule_id }
      }

    case GENERATE_AI_THREAD_ID:
    
      const {thread_id, topic_filter_key, topic_main_key, topic_main_sub_key} = action.payload;

      const currentTopicList = JSON.parse(JSON.stringify(state.defaultTopics.slice()));

      currentTopicList.forEach((w) => {
        if(w.main_key === topic_main_key && w.categories.length)
        {
          w.categories.forEach((category) => {
            if(category.filter_key == topic_filter_key && category.main_sub_key == topic_main_sub_key)
            {
              category.thread_id = thread_id;
            }
          });
        }
      });

      return {
        ...state,
        defaultTopics: currentTopicList
      }

    case IS_TOPIC_LIST_LOADING:
      return {
        ...state,
        isTopicListLoading: action.payload
      }
    case CLEAR_DEFAULT_TOPICS:
      return {
        ...state,
        defaultTopics: payload
      }
    case SELECTED_DATE_INDEX_FOR_CALENDAR:
      return {
        ...state,
        calendarDateIndex: payload
      }
    case TASK_LIST_FOR_CHAT:
      return {
        ...state,
        chatTaskList: payload
      }
    case FIRST_MESSAGE_ID:
      return {
        ...state,
        firstIdofMessage: payload
      }
    case CALL_MESSAGE_PAGINATIONS:
      return {
        ...state,
        preDataAvaliable: payload
      }
    case LAST_FILTER_DETAILS:
      return {
        ...state,
        lastAppliedFilter: payload
      }
    case LAST_MESSAGE_SPACE:
      spaceList = state.spaces ? state.spaces : [];
      var defaultTopics_ = state.defaultTopics ? state.defaultTopics: [] ;
      if (spaceList.length) {
        spaceList.forEach((space) => {
          const { project_id, messages, notiType } = payload;
          if(notiType == "chat"){
            if (space.project_id == project_id) {
              space.chat_group_message_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
              space.chat_group_message = messages.message;
              space.chat_group_name = messages.senderName;
              space.chat_group_picture = '';
            }
          }
        });
      }
        defaultTopics_ = defaultTopics_.map((top) => {
          const { messages } = payload
          top.categories.map((category) => {
            if(category.chat_data){
            if (category.chat_data.groupId == messages.group) {
              category.chat_data.lastMessageDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
              category.chat_data.lastMessage = messages.message;
              category.chat_data.lastMsgUserName = messages.senderName;
              category.chat_data.lastMsgUserId = messages.user;
            }}
            return category;
          })
        return top;
      })
      return {
        ...state,
        spaces : spaceList,
        defaultTopics : defaultTopics_
      }
      case DISPLAY_CALL_FULLSCREEN:
        return {
          ...state,
          isCallFullscreen: payload
        }  
      case IS_CALL_RECORDING:
        return {
          ...state,
          callRecordingActive: payload
        }  
      case CREATE_TASK_FOR_AUDIO_CALL:
        return {
          ...state,
          callTask: payload
        }  
      case GET_CALL_TASK_DETAILS:
        return {
          ...state,
          callTaskDetails: payload
        }  
      case GET_COMMENTS_DURING_AUDIO_CALL:
        return {
          ...state,
          callComments: payload
        }  
      case CALL_RECORDING_ACTION_DETAILS:
        return {
          ...state,
          callRecordingAction: payload
        }  
      case SHOW_MUTE_MIC:
        return {
          ...state,
          displayMic: payload
        }  
      case CALL_FUNCTION_OF_CALL_DISCONNECT:
        return {
          ...state,
          isEndCall: payload
        }
      case FULL_SCREEN_LOADER_CHANGE_SPACE:
        return {
          ...state,
          fullScreenLoadChanSpace : payload
        }  
      case SELECTED_PLAN_FROM_CHAT:
        return {
          ...state,
          chatPlanTittle : payload
        }  
      case SELECTED_PROPOSAL:
        return {
          ...state,
          selectedProposal : payload
        }  
      case GET_COMPANY_INVITETION_LIST:
        return {
          ...state,
          inviteeCompanyList : payload
        }  
      case IS_JOB_PROPOSAL_PAGE:
        return {
          ...state,
          isJobProposalActive : payload
        }  
      case OPEN_CONTACT_DIRECTORY_CHAT:
        return {
          ...state,
          isContactDirChat : payload
        }  
      case ACCORDION_EVENT_KEY:
        return {
          ...state,
          accordionEventKey : payload
        } 
      case IBAN_ACCOUNTS_LIST:
        return {
          ...state,
          IBANAccounts : payload
        } 
      case EDIT_SCHEDULE_JSON:
        return {
          ...state,
          editedScheduleData : payload
        } 
      case SHOW_API_LOADING_LOADER:
        return {
          ...state,
          showLoadingBox : payload
        } 
      case SET_LIST_OF_SELECTEDOPERATORE_LIST:
        return {
          ...state,
          selectedOperators : payload
        } 
      case MAIL_REPLAY_FROM_LIST:
        return {
          ...state,
          isMailReplay : payload
        }
      case SET_EQUIPMENT_CONSUMBLE_CHECK_LIST :
        var defaultTopics_ = state.defaultTopics ? state.defaultTopics: [] ;
        var isCreateDefaultTopics = state.isCreatesTaskDefaultTopics ? state.isCreatesTaskDefaultTopics : []
        const {equipment, datatype} = payload
        var setDefaultTopics = [];
        if (datatype == "isCreateTasks") {
          setDefaultTopics = isCreateDefaultTopics
        }else{
          setDefaultTopics = defaultTopics_
        }
        setDefaultTopics = setDefaultTopics.map((top) => {
          top.categories.map((category) => {
            if(category.sub_category && category.sub_category.length > 0){
              category.sub_category.forEach((sub) => {
                const equipmentUpdate = equipment.filter((item) => item.equipment_quantity_id == sub.id)
                if (equipmentUpdate.length > 0) {
                  if (equipmentUpdate[0].equipment_status) {
                    sub.task_checklist_id = 1;
                  }else{
                    sub.task_checklist_id = 0;
                  }
                }
              return sub;
              })
            }
          return category;
          })
        return top;
        })
        return {
          ...state,
          defaultTopics : datatype == "" ? setDefaultTopics : defaultTopics_,
          isCreatesTaskDefaultTopics : datatype == "isCreateTasks" ? setDefaultTopics : isCreateDefaultTopics
        }
      case IS_CREATE_TASK_DEFAULT_TOPICS :
        var defaultTopicsTask = state.defaultTopics ? state.defaultTopics: [] ;
        const {isdefaultTopics, isFromCreateTask} = payload
        return {
          ...state,
          isCreatesTaskDefaultTopics : isFromCreateTask == "" ?  isdefaultTopics : [],
          defaultTopics : isFromCreateTask == "isFromCreateTask" ?  isdefaultTopics : defaultTopicsTask
        }
  
        case  GET_PROVIDER_LIST :
          return {
            ...state,
          providerList : payload
          }
  
        case  SET_RISK_DATA_LIST :
          return {
            ...state,
          riskList : payload
          }
        case  PROVIDER_LIST_TABLE_DATA :
          return {
            ...state,
          providerlistTableData : payload
          }
        case UPDATE_PROVIDER_LIST_TABLE_DATA :
          let updayeProviderList =  state.providerlistTableData ? state.providerlistTableData : {};
          const {providerData, tabName, keys} = payload;
            if (updayeProviderList && updayeProviderList[`${tabName}`] && updayeProviderList[`${tabName}`].length) {
              switch (tabName) {
                case 'task':
                  let providerTask = updayeProviderList.task
                  if (keys === "delete") {
                    providerTask = providerTask.filter((item) => item.id !== providerData.taskId);
                  } else{
                    providerTask.map((provi) => {
                      if (provi.id == providerData.taskId) {
                        switch (keys) {
                          case "spaces":
                            provi.space_name = providerData.office_title;
                            provi.space_project_id = providerData.project_id;
                            break;
                          case "date":
                            provi.duedate = providerData.dueDate != '' ? moment(providerData.dueDate).format("YYYY-MM-DD HH:mm:ss") : '';
                            break;
                          case "pins":
                            provi.pinned = providerData.taskPin && providerData.taskPin == 1 ? 0 : 1
                            break;
                          case "priority":
                            provi.priority = providerData.priority == "3" ? "2" : "3";
                            break;
                          case "status":
                            if (providerData.statusId != "task_convert_to_request") {
                               provi.status = providerData.statusId;
                               provi.is_requested = 0;
                            } else {
                              provi.is_requested = 1;
                            }
                            break;
                          default:
                            break;
                        }
                      }
                      return provi;
                    });
                  }
                  updayeProviderList = {...updayeProviderList, ['task'] : providerTask}
                  break;
              
                default:
                  break;
                }
            }
            return {
             ...state,
              providerlistTableData : updayeProviderList
            }
        case SET_PROVIDER_LIST_LOADER :
              return {
                ...state,
                providerListDataLoading : payload
              }
        case UPDATE_DEFAULT_TOPICS_AI_DATA :
          const { filterkey, mainKkey ,main_sub_key} = payload
          var defaultTopics_ = state.defaultTopics ? state.defaultTopics: [] ;
          defaultTopics_.forEach((topic) =>{
            if (topic.main_key == mainKkey) {
              topic.categories.forEach((category) =>{
                if (category.filter_key == filterkey) {
                  console.log("Number(category.is_ai_access_data) == 0",Number(category.is_ai_access_data) == 0,category.filter_key, category.is_ai_access_data)
                  category.is_ai_access_data = Number(category.is_ai_access_data) == 0 ? 1 : 0;
                }
              })
            }
          })
          // console.log("defaultTopics_",defaultTopics_)
              return {
                ...state,
                defaultTopics : defaultTopics_
              }
        case IS_TODO_TABLE :
          return {
           ...state,
            isTodoTable : payload
          }
        case UPDATE_SCHEDUALE_EMAILS :
          return {
           ...state,
           schedule_messages : payload
          }
        case  UPDATE_DEFAULT_TOPICS :
          return {
            ...state,
            defaultTopics : payload
          }
        case PROVDER_WALLET_LIST :
          return {
            ...state,
            providerWallet : payload
          }
    default:
      return state;
  }
}
