import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l, getDatePickerLocale } from "../../hooks/utilities";
import Form from "react-bootstrap/Form";
import { CalendarBlank } from "phosphor-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const EditEquipmentsQuantity = ({
  show,
  handleClose,
  data,
  setData,
  quantityData
}) => {
  const [quantity, setQuantity] = useState({});
  useEffect(() => {
    if (data) {
      setQuantity(data)
    }
  }, [data])
  const handleChange = (name, value) => {
    let Arr = quantity
    Arr = {
      ...Arr,
      [name]: value
    }
    setQuantity(Arr);
  }
  const save = () => {
    let arr = quantityData
    arr[quantity.index] = quantity
    setData(arr.slice());
    handleClose()
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="md"
        centered
        scrollable
      >
        <Modal.Header closeButton className="mb-2">
          <Modal.Title>{_l("l_edit_quantity")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="form-wrapper-main pt-0">
            <div className="row">
              <Form.Group className="col-12 c-input-box pb-2 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_name")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_name")}
                  type="text"
                  value={quantity.name}
                  onChange={(e) => {
                    handleChange("name", e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-12 c-input-box pb-2 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_life_time")} {` (${_l("l_years")})`}
                </Form.Label>
                <div
                  className="custom-datepicker w-100 pt-0"
                  data-bs-toggle="tooltip"
                  title="Aug, 8 2022"
                >
                  <div className="d-flex align-items-center form-control  color-white-60">
                    <CalendarBlank size={18} className="c-icons mx-0" weight="light" />
                    <DatePicker
                      selected={quantity.lifetime}
                      onChange={(date) => handleChange("lifetime",date)}
                      disabledKeyboardNavigation
                      placeholderText={_l('l_select_start_date')}
                      locale={getDatePickerLocale()}
                      dateFormat="dd/MM/yyyy"
                      portalId="custom_datepicker"
                      calendarClassName="custom-datepicker pt-0"
                      selectsStart
                      defaultMenuIsOpen
                      calendarStartDay={1}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-12 c-input-box pb-2 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_device_id")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_device_id")}
                  type="text"
                  value={quantity.device_id}
                  onChange={(e) => {
                    handleChange("device_id", e.target.value);
                  }}
                  name=""
                />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={() => save()}>
            {_l('l_save')}
            
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditEquipmentsQuantity;
