import React, { useEffect } from "react";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyCr-kB6s621Lpnk9gLz-MMKc66i2pxcyYs");

export const MapScreen = ({
  solarInfo,
  setSolarInfo,
  regionList,
  setRegionList,
}) => {
  useEffect(() => {
    const fetchRegion = async (address) => {
      try {
        const response = await Geocode.fromAddress(address);
        const { region_id, region_name } = selectSpaceRegion(
          response.results[0].address_components
        );
        return {
          region_id,
          region_name,
          place_id: response.results[0].place_id,
        };
      } catch (e) {
        console.error(e);
        throw e;
      }
    };

    const selectSpaceRegion = (addressComponent) => {
      let regionId = 0;
      let regionName = "";
      let fetchedRegion = "";
      let index = addressComponent.findIndex(
        (add) => add.types && add.types.includes("administrative_area_level_2")
      );
      if (index === -1) {
        index = addressComponent.findIndex(
          (add) =>
            add.types && add.types.includes("administrative_area_level_1")
        );
      }
      if (index > -1) {
        fetchedRegion = addressComponent[index].long_name;
      }
      regionList.forEach((region) => {
        if (
          region.label.toLowerCase().trim() ==
          fetchedRegion.toLowerCase().trim()
        ) {
          regionId = region.value;
          regionName = region.label;
        }
      });
      if (regionId == 0) {
        setRegionList(regionList.concat([{ value: 0, label: fetchedRegion }]));
        return { region_id: 0, region_name: fetchedRegion };
      } else {
        return { region_id: regionId, region_name: regionName };
      }
    };

    const handleSvelteAppData = (event) => {
      if (event.data && typeof event.data !== "string" && event.data.type) {
        switch (event.data.type) {
          case "address":
            fetchRegion(event.data.address).then((response) => {
              setSolarInfo({
                ...solarInfo,
                address: event.data.address,
                ...response,
              });
            });
            break;
          case "panel_count":
            setSolarInfo({
              ...solarInfo,
              no_of_plates: event.data.totalPanels,
            });
            break;
          case "electricity_production":
            setSolarInfo({
              ...solarInfo,
              electricity_production: event.data.electricity_production,
            });
            break;
          case "total_savings":
            setSolarInfo({ ...solarInfo, savings: event.data.total_savings });
            break;
          case "solar_incentives":
            setSolarInfo({
              ...solarInfo,
              aids_financial: event.data.solar_incentives,
            });
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("message", handleSvelteAppData);

    return () => {
      window.removeEventListener("message", handleSvelteAppData);
    };
  }, []);

  return (
    <div className="mapfull position-relative h-100">
      <iframe src="http://localhost:5173/" title="Svelte App"></iframe>
    </div>
  );
};

export default MapScreen;
