import React, { useEffect, useState } from "react";
import OnboardingHeader from "../SpaceOnBoarding/OnboardingHeader";
import OnboardingFooter from "../SpaceOnBoarding/OnboardingFooter";
import {
  DefaultLeftPanel,
  _l,
  countryCodedigitLength,
  generateNativeFingerPrint,
  getDeviceFingerprintUsingLibrary,
  leftPanelMouseUpEvent,
  showError,
} from "../../hooks/utilities";
import RadioButtonWithIcon from "../Modals/OnboardingReusableComponents/RadioButtonWithIcon";
import {
  Buildings,
  HourglassSimpleLow,
  UserCircleGear,
  UserGear,
  UserPlus,
  Wrench,
} from "phosphor-react";
import CustomerServices from "../../services/customer-services";
import Address from "../SpaceOnBoarding/SpaceDetails/Address";
import ListingWithCheckbox from "../Modals/OnboardingReusableComponents/ListingWithCheckbox";
import RegisterClient from "../ExploreFeatures/ExternalBenchmark/RegisterClient";
import AuthServices from "../../services/auth-services";
import CommanLoader from "../Loader/CommanLoader";
import { getCustomerSpaces, setProject } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/auth";
import { showMessage } from "../../actions/messages";
import { useNavigate, useSearchParams } from "react-router-dom";
import ConnectProvider from "../ExploreFeatures/ConnectProvider/ConnectProvider";
import moment from "moment";
import Geocode from "react-geocode";
import AddBenchmarkIcon from "../../assets/icons/AddBenchmarkIcon";
import FileSettingIcon from "../../assets/icons/FileSettingIcon";
import FindJobIcon from "../../assets/icons/FindJobIcon";
import JoinCompanyTrainingIcon from "../../assets/icons/JoinCompanyTrainingIcon";
import UserMagnifyingIcon from "../../assets/icons/UserMagnifyingIcon";
import UserSettingIcon from "../../assets/icons/UserSettingIcon";
import OperatorForm from "./OperatorForm";
import AgentInformation from "./AgentInformation";
import AlertModal from "../Modals/AlertModal";
import { DEFAULT_ADDRESS, DEFAULT_REGION_ID, DEFAULT_REGION_NAME, COUNTRY_CODE_OPTIONS } from "../../constants/constants";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_SECRET);

const CryptoJS = require("crypto-js");

const REGISTER_USER_TYPE = {
  CUSTOMER: 1,
  PROVIDER: 2,
  AGENT: 3,
};

const PLAN_ICONS = [
  AddBenchmarkIcon,
  FileSettingIcon,
  UserGear,
  UserPlus,
  UserSettingIcon,
  UserMagnifyingIcon,
  FindJobIcon,
  JoinCompanyTrainingIcon,
];

const AGENT_PLANS = [
  {
    id: "0",
    package_name: {
      english: "Find Jobs",
      french: "Trouver des emplois",
      spanish: "Encontrar trabajos",
    },
    rel_type: "agent",
    rel_id: 1, //For Agent Training Plan
    package_description: {
      english: "Search for Employment Opportunities",
      french: "Recherche d'opportunités d'emploi",
      spanish: "Búsqueda de Oportunidades de Empleo",
    },
  },
  {
    id: "0",
    package_name: {
      english: "Join Company Training",
      french: "Rejoignez la formation en entreprise",
      spanish: "Únase a la capacitación de la empresa",
    },
    rel_type: "agent",
    rel_id: 2, //For Agent Training Plan
    package_description: {
      english: "Get Trained by Joining a Team",
      french: "Formez-vous en rejoignant une équipe",
      spanish: "Capacítese uniéndose a un equipo",
    },
    disabled: true,
  },
];

const RadioButtonArray = ({ data, name, onChange, onClick, value }) => {
  return (
    <div className="d-flex justify-content-center flex-wrap">
      {data.map((values, key) => {
        return (
          <RadioButtonWithIcon
            key={key}
            {...values}
            onChange={onChange}
            onClick={onClick}
            name={name}
            checked={value === values.value}
          />
        );
      })}
    </div>
  );
};

const RegistrationMain = ({ language }) => {
  const userTypes = [
    {
      title: "l_company_title_one",
      description: "l_company_description_one",
      value: REGISTER_USER_TYPE.CUSTOMER,
      Icon: { name: Buildings, className: "c-icons stroke-width-5px h60w60" },
    },
    {
      title: "l_company_title_two",
      description: "l_company_description_two",
      value: REGISTER_USER_TYPE.PROVIDER,
      Icon: {
        name: UserCircleGear,
        className: "c-icons stroke-width-5px h60w60",
      },
    },
    {
      title: "l_company_title_three",
      description: "l_company_description_three",
      value: REGISTER_USER_TYPE.AGENT,
      Icon: { name: Wrench, className: "c-icons stroke-width-5px h60w60" },
    },
  ];
  const urlParamsList = ["id", "space", "provider-type"];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {isLoggedIn} = useSelector((state) => state.auth);

  const currentFingerPrint = localStorage.getItem("device_fingerprint");
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("accessToken");

  const [formData, setFormData] = useState({
    registerUserType: 0,
    userSubscriptionPlan: 0,
  });
  const [currentStep, setCurrentStep] = useState("registrationType");
  const [backButtonText, setBackButtonText] = useState("");
  const [nextButtonText, setNextButtonText] = useState("");
  const [titleText, setTitleText] = useState("");
  const [description, setDescription] = useState("");
  const [userPackageList, setUserPackageList] = useState([]);
  const [categoryListing, setCategoryListing] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [coOrdinates, setCoOrdinates] = useState({
    lat: 48.83888,
    lng: 2.38062,
  });
  const [spaceDetail, setSpaceDetail] = useState({
    address: DEFAULT_ADDRESS,
    title: "",
    region_id: DEFAULT_REGION_ID,
    office_id: "",
    superfice: 1000,
    number_of_desk: 10,
    country_id: "",
    is_edit: "",
    logo: "",
    is_virtual_space: 0,
    space_type: "",
    space_logo: "",
    floor_count: 1,
    space_id: 0,
    id: 0,
    floors: [],
    region_name: DEFAULT_REGION_NAME,
  });
  const [contactDetail, setContactDetail] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    country_code: "+33",
    company_id: 0,
    password: "",
    company_name: "",
    address: "",
    staff_id: "",
    contact_id: "",
    client_id: "",
    project_id: "",
    is_external: "",
    full_name: "",
    termsAndConditions: true,
    invoice_tax: {value: 0, label: ""}
  });
  const [showLoader, setShowLoader] = useState(false);
  const [isExternalBenchmark, setIsExternalBenchmark] = useState(false);
  const [operatorFormData, setOperatorFormData] = useState({
    mission_address: "",
    mission_region: "",
    service_start_date: new Date(),
    service_end_date: new Date(moment().add(10, "days").format("YYYY-MM-DD")),
    unknown_end_date: 0,
    contract_description: "",
    slots: [
      {
        start_time: "",
        end_time: "",
        rate: "",
        slots: {
          mon: 0,
          tue: 0,
          wed: 0,
          thu: 0,
          fri: 0,
          sat: 0,
          sun: 0,
        },
      },
    ],
  });
  const [operatorContract, setOperatorContract] = useState();
  const [operatorContractPreview, setOperatorContractPreview] = useState([]);
  const [agentSlots, setAgentSlots] = useState([
    {
      start_time: "",
      end_time: "",
      rate: "",
      slots: {
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0,
      },
    },
  ]);
  const [agentDocuments, setAgentDocuments] = useState({
    id_card: {
      title: "l_id_card",
      preview: [],
    },
    // company_id: {
    //   title: "l_company_id",
    //   preview: [],
    // },
    payment: {
      title: "l_iban_label",
      preview: [],
    },
    insurance: {
      title: "l_insurance_documents",
      preview: [],
    },
    profile_image: {
      title: "l_profile_image_upload",
      preview: [],
    },
  });
  const [companyListOptions, setCompanyListOptions] = useState([]);
  const [showAlert, setShowAlert] = useState({});
  const [isReadOnlyMode, setIsReadOnlyMode] = useState(false);
  const [activePlanIndex, setActivePlanIndex] = useState("");
  const [loadingNextStep, setLoadingNextStep] = useState(false);

  //To update header and button text based on step change
  const updateTextualContent = (stepName) => {
    let backButtonTxt = "";
    let nextButtonTxt = "";
    let titleTxt = "";
    let descriptionTxt = "";

    switch (stepName) {
      case "registrationType":
        backButtonTxt = "";
        nextButtonTxt = "";
        titleTxt = "l_user_type";
        descriptionTxt = "l_user_type_description";
        break;
      case "serviceType":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt = "l_select_plan";
        descriptionTxt = "l_select_plan_description";
        break;
      case "addressBox":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt = "l_space_address_title_text";
        descriptionTxt = "l_space_address_subtitle_text";
      case "categoryList":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt = "l_service_categories";
        descriptionTxt = "l_service_categories_description";
        break;
      case "registrationForm":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt = "l_basic_details_form_title";
        descriptionTxt = "l_basic_info_description";
        break;
      case "agentInformation":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt =
          formData.userSubscriptionPlan == 1
            ? "l_setup_availability_and_documents"
            : "l_upload_documents";
        descriptionTxt = "";
        break;
      case "operatorAvailability":
        backButtonTxt = "l_back";
        nextButtonTxt = "l_next";
        titleTxt = "l_setup_avilability";
        descriptionTxt = "";
        break;
    }
    setBackButtonText(backButtonTxt);
    setNextButtonText(nextButtonTxt);
    setTitleText(titleTxt);
    setDescription(descriptionTxt);
  };

  //Select 1st step
  const selectUserType = (value) => {
    if (value <= 0) {
      showError("l_please_select_user_type");
    } else {
      setFormData({ ...formData, registerUserType: value });
      setCurrentStep("serviceType");
    }
  };

  //Select 2nd step
  const selectPlan = async (value) => {
    if (formData.registerUserType === REGISTER_USER_TYPE.AGENT) {
      setFormData({ ...formData, userSubscriptionPlan: Number(value) });
      setContactDetail({
        ...contactDetail,
        user_type: value == 2 ? 3 : 4,
      });
      setCurrentStep("addressBox");
    } else {
      if (value <= 0) {
        showError("l_please_select_plan");
      } else {
        setFormData({ ...formData, userSubscriptionPlan: value });
        if (value == 1) {
          await externalBenchmarkCreation();
        } else {
          setCurrentStep("addressBox");
        }
      }
    }
  };

  //Address step
  const selectAddress = () => {
    if (!spaceDetail.address) {
      showError("l_please_enter_address");
    } else if (spaceDetail.region_id < 0) {
      showError("l_invalid_region");
    } else {
      setContactDetail({ ...contactDetail, address: spaceDetail.address });
      if (
        formData.registerUserType === REGISTER_USER_TYPE.CUSTOMER &&
        formData.userSubscriptionPlan == 2
      ) {
        setCurrentStep("registrationForm");
      } else {
        setCurrentStep("categoryList");
      }
    }
  };

  //Categories step
  const saveCategories = () => {
    const selectedCategories = categoryListing.filter((w) => w.checked);
    if (
      formData.registerUserType === REGISTER_USER_TYPE.AGENT &&
      formData.userSubscriptionPlan == 2
    ) {
      setCurrentStep("registrationForm");
    } else if (!selectedCategories.length) {
      showError("l_please_select_category");
    } else {
      if (
        formData.registerUserType === REGISTER_USER_TYPE.PROVIDER &&
        formData.userSubscriptionPlan == 7
      ) {
        setCurrentStep("operatorAvailability");
      } else if (
        formData.registerUserType === REGISTER_USER_TYPE.AGENT &&
        formData.userSubscriptionPlan == 1
      ) {
        setCurrentStep("agentInformation");
      } else {
        setCurrentStep("registrationForm");
      }
    }
  };

  //Complete Registration flow
  const handleRegistration = async () => {
    if (countryCodedigitLength(contactDetail.country_code).min !== contactDetail.mobile_number.length && countryCodedigitLength(contactDetail.country_code).max !== contactDetail.mobile_number.length) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_phone_number_with_country_code")
        )
      );
      return false
    }
    const selectedCategories = categoryListing
      .filter((w) => w.checked && w.value > 0 && !w.addedRealTime)
      .map((z) => z.value);
    const newCategories = categoryListing
      .filter((w) => w.checked && w.value == 0)
      .map((z) => z.label);

    const nextButtonTxt = nextButtonText;

    try {
      setLoadingNextStep(true);
      setNextButtonText("l_please_wait");
      const customerData = {
        agent_documents: agentDocuments,
        company_name: contactDetail.company_name,
        first_name: `${contactDetail.first_name}`,
        country_code: contactDetail.country_code,
        email: contactDetail.email,
        mobile_number: contactDetail.mobile_number,
        password: contactDetail.password,
        address: spaceDetail.address,
        package_id: formData.userSubscriptionPlan,
        user_type: formData.registerUserType,
        region_name: spaceDetail.region_name,
        region_id: spaceDetail.region_id,
        company_id: contactDetail.company_id,
        user_type: contactDetail.user_type || formData.registerUserType,
        invoice_tax: contactDetail.invoice_tax ? contactDetail.invoice_tax.value : 0
      };

      let operatorAvailabilityData;
      let region_id;
      let region_name;

      if (
        formData.registerUserType === REGISTER_USER_TYPE.PROVIDER &&
        formData.userSubscriptionPlan == 7
      ) {
        operatorAvailabilityData = JSON.parse(JSON.stringify(operatorFormData));
        operatorAvailabilityData.service_start_date = moment(
          operatorFormData.service_start_date
        ).format("YYYY-MM-DD");
        operatorAvailabilityData.service_end_date = moment(
          operatorFormData.service_end_date
        ).format("YYYY-MM-DD");
        operatorAvailabilityData.slots = operatorAvailabilityData.slots.filter(
          (slot) => Object.values(slot.slots).some((w) => w == 1)
        );
        operatorAvailabilityData.slots.forEach((w) => {
          w.start_time = moment(w.start_time).format("HH:mm");
          w.end_time = moment(w.end_time).format("HH:mm");
        });
        let regionData = await fetchRegion(
          operatorAvailabilityData.mission_address
        );
        region_id = regionData.region_id;
        region_name = regionData.region_name;
      }

      if (
        formData.registerUserType === REGISTER_USER_TYPE.AGENT &&
        formData.userSubscriptionPlan == 2
      ) {
        const response = await CustomerServices.editCompanyEmployees(
          contactDetail.first_name,
          contactDetail.last_name,
          contactDetail.email,
          contactDetail.mobile_number,
          3,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          contactDetail.contact_id,
          contactDetail.staff_id,
          contactDetail.password,
          undefined,
          undefined,
          contactDetail.project_ids,
          contactDetail.company_id,
          1
        );
        if (response.status) {
          dispatch(login(customerData.email, customerData.password));
          localStorage.setItem("space_setup_pending", 1);
        }
      } else {
        const response = await AuthServices.externalUserRegistratoin(
          customerData,
          selectedCategories,
          newCategories,
          formData.userSubscriptionPlan,
          operatorAvailabilityData,
          operatorContract,
          region_id,
          region_name,
          agentSlots
            .slice()
            .filter((slot) => Object.values(slot.slots).some((w) => w == 1))
            .map((w) => {
              return {
                ...w,
                start_time: moment(w.start_time).format("HH:mm:ss"),
                end_time: moment(w.end_time).format("HH:mm:ss"),
              };
            })
        );
        if (response.status > 0) {
          dispatch(login(customerData.email, customerData.password));
          localStorage.setItem("space_setup_pending", 1);
        }
        else {
          showError(response.message);
        }
      }
    } catch (e) {
      console.error(e);
    }
    finally {
      setNextButtonText(nextButtonTxt);
      setLoadingNextStep(false);
    }
  };

  //Validate Slot Availability
  const validateSlots = (slots) => {
    let flag = true;
    slots = slots.filter((slot) =>
      Object.values(slot.slots).some((w) => w == 1)
    );
    if (!operatorFormData.service_start_date) {
      flag = false;
      showError("l_please_select_the_start_date");
    } else if (!slots.length) {
      flag = false;
      showError("l_one_or_more_row_has_all_emply_slots");
    } else if (slots.filter((w) => !w.start_time || !w.end_time).length) {
      flag = false;
      showError("l_start_and_end_time_mandatory");
    }
    // else if (slots.filter((w) => !w.rate || w.rate <= 0).length) {
    //   flag = false;
    //   showError("l_rate_is_required");
    // }
    return flag;
  };

  //Setup Availability
  const handleSetupAvailability = () => {
    let { slots } = operatorFormData;

    if (!validateSlots(slots)) {
      return false;
    }

    slots = slots.filter((slot) =>
      Object.values(slot.slots).some((w) => w == 1)
    );

    if (!operatorFormData.service_start_date) {
      showError("l_please_select_the_start_date");
    } else if (!slots.length) {
      showError("l_one_or_more_row_has_all_emply_slots");
    } else if (slots.filter((w) => !w.start_time || !w.end_time).length) {
      showError("l_start_and_end_time_mandatory");
    } else {
      setCurrentStep("registrationForm");
    }
  };

  //Handle Agent availablity
  const handleAgentAvailability = () => {
    let validDocumentsUploaded = true;
    let skippedDocuments = [];

    Object.keys(agentDocuments).forEach((documentType) => {
      if (
        !["profile_image", "company_id"].includes(documentType) &&
        !agentDocuments[documentType].attachments
      ) {
        validDocumentsUploaded = false;
        skippedDocuments.push(documentType);
      }
    });

    if (
      formData.registerUserType === REGISTER_USER_TYPE.AGENT &&
      formData.userSubscriptionPlan == 1 &&
      !validateSlots(agentSlots)
    ) {
      return false;
    }
    if (validDocumentsUploaded) {
      setCurrentStep("registrationForm");
    } else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          `${_l("l_upload_all_required_documents")}: ${skippedDocuments.join(
            ", "
          )}`
        )
      );
    }
  };

  //Direction: 1 for Next, -1 for Back
  const handleStepChange = (direction) => {
    if (direction > 0) {
      switch (currentStep) {
        case "registrationType":
          selectUserType(formData.registerUserType);
          break;
        case "serviceType":
          selectPlan(formData.userSubscriptionPlan);
          break;
        case "addressBox":
          selectAddress();
          break;
        case "categoryList":
          saveCategories();
          break;
        case "registrationForm":
          handleRegistration();
          break;
        case "operatorAvailability":
          handleSetupAvailability();
          break;
        case "agentInformation":
          handleAgentAvailability();
          break;
      }
    } else {
      switch (currentStep) {
        case "serviceType":
          setCurrentStep("registrationType");
          break;
        case "addressBox":
          setCurrentStep("serviceType");
          break;
        case "categoryList":
          setCurrentStep("addressBox");
          break;
        case "registrationForm":
          if (
            formData.registerUserType === REGISTER_USER_TYPE.CUSTOMER &&
            formData.userSubscriptionPlan == 2
          ) {
            setCurrentStep("addressBox");
          } else if (
            formData.registerUserType === REGISTER_USER_TYPE.PROVIDER &&
            formData.userSubscriptionPlan == 7
          ) {
            setCurrentStep("operatorAvailability");
          } else if (
            formData.registerUserType === REGISTER_USER_TYPE.AGENT &&
            formData.userSubscriptionPlan == 1
          ) {
            setCurrentStep("agentInformation");
          } else {
            setCurrentStep("categoryList");
          }
          break;
        case "agentInformation":
        case "operatorAvailability":
          setCurrentStep("categoryList");
          break;
      }
    }
  };

  const addNewCategory = async (label) => {
    try {
      const response = await CustomerServices.addCategory(
        label,
        undefined,
        undefined,
        {},
        undefined,
        undefined,
        [],
        1
      );
      if (response.status == 1) {
        setCategoryListing(
          [{ label, value: 0, checked: true, addedRealTime: true }].concat(
            categoryListing
          )
        );
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
      showError("l_something_went_wrong");
    }
  };

  const handleAddNewCategory = async (label) => {
    if (
      formData.registerUserType === REGISTER_USER_TYPE.AGENT &&
      formData.userSubscriptionPlan == 2
    ) {
      addNewCategory(label);
    } else {
      setCategoryListing(
        [{ label, value: 0, checked: true }].concat(categoryListing)
      );
    }
  };

  //Conditional render of JSX (Stepper)
  const StepJSX = () => {
    switch (currentStep) {
      case "registrationType":
        return (
          <RadioButtonArray
            data={userTypes}
            name={"user-type"}
            onClick={(e) => {
              if (!isReadOnlyMode) {
                selectUserType(Number(e.target.value));
              } else if (e.target.value == formData.registerUserType) {
                handleStepChange(1);
              }
            }}
            value={formData.registerUserType}
          />
        );
      case "serviceType":
        const planData = userPackageList
          .filter(
            (plan) =>
              (formData.registerUserType === REGISTER_USER_TYPE.CUSTOMER &&
                plan.rel_type === "customer") ||
              (formData.registerUserType === REGISTER_USER_TYPE.PROVIDER &&
                plan.rel_type === "operator") ||
              (formData.registerUserType === REGISTER_USER_TYPE.AGENT &&
                plan.rel_type === "agent")
          )
          .map((plan) => {
            return {
              ...plan,
              title: plan.package_name[language],
              description: plan.package_description[language],
              value: plan.rel_id || plan.id,
              acknowledgementStepsKey: plan.acknowledgementStepsKey,
            };
          });

        return (
          <RadioButtonArray
            data={planData}
            name={"user-type"}
            onClick={(e) => {
              if (!isReadOnlyMode) {
                selectPlan(e.target.value);
                setActivePlanIndex(
                  planData.find((w) => w.rel_id == e.target.value)
                    .acknowledgementStepsKey
                );
              } else if (e.target.value == formData.userSubscriptionPlan) {
                handleStepChange(1);
              }
            }}
            value={formData.userSubscriptionPlan}
          />
        );
      case "categoryList":
        return (
          <ListingWithCheckbox
            list={categoryListing}
            setList={setCategoryListing}
            handleAddNewCategory={(label) => handleAddNewCategory(label)}
            // readOnly={isReadOnlyMode}
          />
        );
      case "externalBenchmark":
        return <></>;
      default:
        return <></>;
    }
  };

  //Handle External benchmark creation flow
  const externalBenchmarkCreation = async () => {
    if (token && currentFingerPrint) {
      let spaces = localStorage.getItem("spaces");
      spaces = spaces ? JSON.parse(spaces) : [];
      const client_id = localStorage.getItem("client_id");
      const contact_id = localStorage.getItem("contact_id");

      dispatch(getCustomerSpaces(client_id, contact_id));

      if (spaces &&
        spaces.length &&
        userType === "contact" &&
        (spaces[0].is_external > 0 || spaces[0].schedule_id > 0)
      ) {
        urlParamsList.forEach((w) => {
          searchParams.delete(w);
        });
        searchParams.append("space", spaces[0].project_id);
        searchParams.append("provider-type", "external-benchmark");
        if (spaces[0].schedule_id > 0) {
          searchParams.append("id", spaces[0].schedule_id);
        }
        setSearchParams(searchParams);
      }
    } else {
      const registerAsGuest = async () => {
        try {
          setShowLoader(true);
          localStorage.clear();
          localStorage.setItem("version", process.env.REACT_APP_VERSION);
          localStorage.setItem("language", language);
          let fingerprint;
          const hash = searchParams.get("hash");
          if (hash) {
            fingerprint = hash;
          } else {
            fingerprint = await getDeviceFingerprintUsingLibrary();
            if (!fingerprint) {
              fingerprint = await generateNativeFingerPrint();
            }
          }
          localStorage.setItem("device_fingerprint", fingerprint);
          const response = await AuthServices.registerAsGuestContact(
            fingerprint
          );
          if (response.status) {
            const client_id = response.data.client_id;
            const contact_id = response.data.contact_id;
            const project_id = response.data.project_id;

            if (!searchParams.get("space")) {
              searchParams.set("provider-type", "external-benchmark");
              searchParams.set("space", project_id);
              if(response.data.benchmark_id > 0)
              {
                searchParams.set("id", response.data.benchmark_id);
              }
              searchParams.set("hash", fingerprint);
              if (
                response.data.project_data &&
                response.data.project_data.length &&
                response.data.project_data[0].schedule_id > 0
              ) {
                searchParams.append(
                  "id",
                  response.data.project_data[0].schedule_id
                );
              }
              setSearchParams(searchParams);
            }

            localStorage.setItem("client_id", client_id);
            localStorage.setItem("contact_id", contact_id);
            localStorage.setItem("staff_id", response.data.staff_id);
            localStorage.setItem("user_type", response.data.user_type);
            localStorage.setItem("selectedOffice", project_id);
            localStorage.setItem(
              "spaces",
              JSON.stringify(response.data.project_data)
            );
            if (response.data.token) {
              localStorage.setItem(
                "accessToken",
                CryptoJS.AES.encrypt(
                  CryptoJS.enc.Utf8.parse(response.data.token),
                  process.env.REACT_APP_JWT_ENC_KEY
                )
              );
            }
            dispatch(setProject(project_id));
            setIsExternalBenchmark(true);
          } else {
            showError(response.message);
          }
        } catch (e) {
          showError("l_something_went_wrong");
          console.log(e);
        }
        finally {
          setShowLoader(false);
        }
      };

      registerAsGuest();
    }
    let element = document.getElementById("modified_height");
    let element2 = document.body;
    if (element && element2) {
      element.classList.add("modified-height", "modified-res-default-height");
      element2.classList.add("px-2", "pb-0");
    }

    return () => {
      let element = document.getElementById("modified_height");
      let element2 = document.body;
      if (element && element2) {
        element.classList.remove(
          "modified-height",
          "modified-res-default-height"
        );
        element2.classList.remove("px-2", "pb-0");
      }
    };
  };

  //Fetch List of regions
  const fetchRegion = async (address) => {
    try {
      if(window.location.host !== 'app.myr.ai')
      {
        return { region_id: 158, region_name: "Île-de-France" };
      }
      const response = await Geocode.fromAddress(address);
      const { region_id, region_name } = selectSpaceRegion(
        response.results[0].address_components
      );
      return { region_id, region_name };
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  //Select Region for mission adderess
  const selectSpaceRegion = (addressComponent) => {
    let regionId = 0;
    let regionName = "";
    let fetchedRegion = "";
    let index = addressComponent.findIndex(
      (add) => add.types && add.types.includes("administrative_area_level_2")
    );
    if (index === -1) {
      index = addressComponent.findIndex(
        (add) => add.types && add.types.includes("administrative_area_level_1")
      );
    }
    if (index > -1) {
      fetchedRegion = addressComponent[index].long_name;
    }
    regionList.forEach((region) => {
      if (
        region.label.toLowerCase().trim() == fetchedRegion.toLowerCase().trim()
      ) {
        regionId = region.value;
        regionName = region.label;
      }
    });
    if (regionId == 0) {
      setRegionList(regionList.concat([{ value: 0, label: fetchedRegion }]));
      return { region_id: 0, region_name: fetchedRegion };
    } else {
      return { region_id: regionId, region_name: regionName };
    }
  };

  useEffect(() => {
    const type = searchParams.get("type");
    const hash = searchParams.get("hash");
    const providerType = searchParams.get("provider-type");
    const registerType = searchParams.get("register-type");
    const planType = searchParams.get("plan-type");

    //Get Plan Data
    const getUserPackages = async () => {
      try {
        const response = await CustomerServices.getUserPlans();
        if (response.status) {
          let userPackages = response.data;
          let planIndex = 0;
          userPackages = userPackages.concat(AGENT_PLANS);
          setUserPackageList(
            userPackages.map((w, i) => {
              planIndex++;
              if (planIndex === 4) {
                planIndex = 1;
              }
              return {
                ...w,
                Icon: {
                  name: PLAN_ICONS[i],
                  className: "c-icons stroke-width-5px h60w60",
                },
                acknowledgementStepsKey: `${w.rel_type}_plan_${planIndex}`,
                rel_id: w.rel_id || w.id,
              };
            })
          );
          if(planType && registerType)
          {
            setFormData({
              registerUserType: Number(registerType),
              userSubscriptionPlan: Number(registerType) === REGISTER_USER_TYPE.AGENT ? Number(planType) : planType,
            });
            setCurrentStep("serviceType");
            searchParams.delete("language");
            searchParams.delete("register-type");
            searchParams.delete("plan-type");
            setSearchParams(searchParams);
          }
        }
      } catch (e) {
        showError("l_something_went_wrong");
        console.error(e);
      }
    };

    //Get Regions
    const getRegions = async () => {
      try {
        const response = await CustomerServices.getRegionList();
        if (response.status == 1 && response.data) {
          setRegionList(
            response.data.map((data) => {
              return {
                label: data.name,
                value: data.id,
              };
            })
          );
        }
      } catch (e) {
        showError("l_something_went_wrong");
        console.error(e);
      }
    };

    //Get Categories
    const getCategories = async () => {
      try {
        let isDefault = 1;
        const response = await CustomerServices.getDefaultTopicList(isDefault);
        if (response.status) {
          let allCategories = response.data.map((w) => {
            return {
              label: w.name,
              value: w.id,
              client_id: w.client_id,
              is_default: w.is_default,
            };
          });
          allCategories.sort((a, b) => a.label.localeCompare(b.label));
          const defaultCategories = allCategories.filter(
            (w) => w.is_default == 1
          );
          const otherCategories = allCategories.filter(
            (w, index) =>
              allCategories.findIndex(
                (z, i) =>
                  z.label &&
                  w.label &&
                  z.label.trim().toLowerCase() ===
                    w.label.trim().toLowerCase() &&
                  i !== index
              ) === -1 && w.is_default == 0
          );
          setCategoryListing(defaultCategories.concat(otherCategories));
        }
      } catch (e) {
        console.error(e);
      }
    };

    //Get List of companies for Agent Login
    const getCompanyList = async () => {
      try {
        const response = await CustomerServices.getAllCompany();
        if (response.status == 1 && response.data.length) {
          let companyList = response.data
            .map((w) => {
              return { value: w.userid, label: w.company };
            })
            .filter((w) => w.label && w.label.length);
          companyList.sort((a, b) => a.label.localeCompare(b.label));
          setCompanyListOptions(companyList);
        }
      } catch (e) {
        console.error(e);
      }
    };

    //Get Agent Details
    const getAgentDetails = async (userType, userHash) => {
      setIsReadOnlyMode(true);
      try {
        const response = await AuthServices.getUserInfo(userType, userHash);
        if (response.status) {
          const { data } = response;
          setFormData({
            registerUserType: REGISTER_USER_TYPE.AGENT,
            userSubscriptionPlan: 2,
          });
          setIsReadOnlyMode(true);

          let first_name = data.full_name;
          let last_name = data.full_name.split(" ").slice(1).join(" ");
          let country_code = "";
          let mobile_number = "";

          if (data.phonenumber) {
            mobile_number = data.phonenumber
              .replace(/[- .a-zA-Z]/g, "")
              .substring(3);
            country_code = data.phonenumber
              .replace(/[- .a-zA-Z]/g, "")
              .substring(0, 3);
          }

          setContactDetail({
            address: data.address,
            client_id: data.company_id,
            company_id: data.company_id,
            country_code,
            company_name: data.company_name,
            contact_id: data.contact_id,
            staff_id: data.staff_id,
            email: data.email,
            full_name: data.full_name,
            first_name,
            last_name,
            mobile_number,
            project_ids: data.project_ids,
          });

          localStorage.setItem("client_id", data.company_id);
          localStorage.setItem("contact_id", data.contact_id);
          localStorage.setItem("staff_id", data.staff_id);

          setCategoryListing(
            data.categories.map((w) => {
              return { label: w.name, value: w.id, checked: true };
            })
          );
        }
      } catch (e) {
        console.error(e);
      }
    };
    if(token)
    {
      navigate(`/aichat`);
    }
    else if (type && hash) {
      getAgentDetails(type, hash);
    }
    else if(providerType && providerType.length)
    {
      externalBenchmarkCreation();
    }
    else {
      getCategories();
    }

    getUserPackages();
    getRegions();
    getCompanyList();
    DefaultLeftPanel();
    document.addEventListener("mouseup", leftPanelMouseUpEvent);

    return () => {
      document.removeEventListener("mouseup", leftPanelMouseUpEvent);
    };
  }, []);

  useEffect(() => {
    const direction = searchParams.get("direction");
    if(direction && direction === "back")
    {
      setSearchParams({});
      setIsExternalBenchmark(false);
      setFormData({...formData, registerUserType: REGISTER_USER_TYPE.CUSTOMER});
    }
  }, [searchParams])
  

  useEffect(() => {
    updateTextualContent(currentStep);
  }, [currentStep]);

  useEffect(() => {
    if(isLoggedIn)
    {
      navigate(`/aichat?plan=${activePlanIndex}`);
    }
  }, [isLoggedIn]);
  

  return (
    <React.Fragment>
      {isExternalBenchmark ? (
        <ConnectProvider />
      ) : (
        <div
          className={`onboarding-wrapper-main m-auto h-100 d-flex flex-column with-modified-width onboarding-with-bg ${["agentInformation"].includes(currentStep) ? "onboarding-with-bg" : ""}`}
        >
          {/* with-modified-width class remove for width issue */}
          {showLoader ? (
            <CommanLoader />
          ) : (
            <React.Fragment>
              <OnboardingHeader
                backBtnHandler={() => {
                  handleStepChange(-1);
                }}
                backButtonText={backButtonText}
                description={description}
                hideBackButton={false}
                loader={loadingNextStep}
                loadingNextStep={loadingNextStep}
                nextBtnHandler={() => {
                  handleStepChange(1);
                }}
                nextButtonText={nextButtonText}
                status={""}
                statusClass={""}
                title={titleText}
                hideNextButton={false}
                titlePrefix={""}
              />
              <div
                className={`onboarding-content-scroll-wrapper overflow-hiiden-auto d-flex flex-column`}
              >
                <StepJSX />
                {currentStep === "addressBox" ? (
                  <Address
                    coOrdinates={coOrdinates}
                    regionList={regionList}
                    setCoOrdinates={setCoOrdinates}
                    setRegionList={setRegionList}
                    setSpaceDetail={setSpaceDetail}
                    spaceDetail={spaceDetail}
                  />
                ) : currentStep === "registrationForm" ? (
                  <RegisterClient
                    fromRegister={true}
                    countryCodes={COUNTRY_CODE_OPTIONS}
                    hideCompanyNameField={
                      formData.registerUserType === REGISTER_USER_TYPE.AGENT &&
                      formData.userSubscriptionPlan == 1
                    }
                    contactDetail={contactDetail}
                    setContactDetail={setContactDetail}
                    companyListOptions={
                      formData.registerUserType === REGISTER_USER_TYPE.AGENT
                        ? companyListOptions
                        : []
                    }
                    readOnlyMode={isReadOnlyMode}
                    showTaxField={formData.registerUserType === REGISTER_USER_TYPE.PROVIDER}
                  />
                ) : currentStep === "operatorAvailability" ? (
                  <OperatorForm
                    formData={operatorFormData}
                    setFormData={setOperatorFormData}
                    contract={operatorContract}
                    setContract={setOperatorContract}
                    contractAttachments={operatorContractPreview}
                    setContractAttachments={setOperatorContractPreview}
                  />
                ) : currentStep === "agentInformation" ? (
                  <AgentInformation
                    documents={agentDocuments}
                    handleClose={() => {
                      handleStepChange(-1);
                    }}
                    handleSave={() => {
                      handleStepChange(1);
                    }}
                    onlyTraining={formData.userSubscriptionPlan == 2}
                    setDocuments={setAgentDocuments}
                    setSlots={setAgentSlots}
                    slots={agentSlots}
                  />
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </div>
              {
                ["agentInformation"].includes(currentStep)
                ?
                <></>
                :
                <OnboardingFooter
                  backBtnHandler={() => {}}
                  backButtonText={""}
                  currentStep={""}
                  hideBackButton={""}
                  loader={false}
                  loadingNextStep={false}
                  nextBtnHandler={() => {}}
                  nextButtonText={""}
                  skipButtonHandler={() => {}}
                  skipButtonText={""}
                  totalSteps={5}
                  hideNextButton={false}
                  hideSkipButton
                />
              }
            </React.Fragment>
          )}
        </div>
      )}
      {showAlert.message && showAlert.message.length ? (
        <AlertModal
          buttonText={"l_ok"}
          handleClose={() => {
            navigate("/login");
          }}
          icon={{ name: HourglassSimpleLow }}
          message={showAlert.message}
          secondaryButtonHandler={() => {}}
          secondaryButtonText={""}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

export default RegistrationMain;
