import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Header  from "../components/Header";
import LeftPanel from "../components/LeftPanel";
import Footer from "../components/Footer";
import Calendar from "../components/Calendar";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  CardListFullHeight,
  CardListScroll,
  DefaultLeftPanel,
  _l,
  hideLeftPanelNew,
  setPageMetaDataDescription,
  showLeftPanelNew,
} from "../hooks/utilities";
import { DEFAULT_METADATA_DESCRIPTION } from "../constants/constants";
import {
  getTaskList,
  projectTaskCounts,
  setProviderWalleftList,
  setSelectedDateForChatai,
  setSelectedGloablView,
  setSelectedLanguage,
  setTasklistLoader,
} from "../actions/customer";
import jquery from "jquery";
import { callTaskListForRealtime, setIDofFirstMessage } from "../actions/chat";
import ExternalHeader from "../components/ExternalHeader";
import { DEFAULT_PLACEHOLDER } from "../actions/action-type";
import { getLeadList } from "../actions/leads-actions/leads-actions";
import customerServices from "../services/customer-services";

const PAGENAME_PROP_VALUE_FOR_PATH = {
  "/dashboard": "dashboardnew",
  "/provider-reporting": "operatorListReporting",
  "/employee-reporting": "operatorListReporting",
  "/equipment-reporting": "equipmentListReporting",
  "/liteversion": "LiteVersion",
  "/calendar": "calendar",
  "/aichat": "ChatAi",
  "/space-onboarding": "SpaceOnBoardingNew",
  "/premiumsubscription": "PremiumSubscription",
  "/explore-features": "SpaceOnBoarding",
  "/connect-provider": "SpaceOnBoarding",
  "/tenants": "SpaceOnBoarding",
  "/employees": "SpaceOnBoarding",
  "/providers": "SpaceOnBoarding",
  "/equipments": "SpaceOnBoarding",
  "/leads": "leadPage",
  "/dashboardpre": "dashboard",
  "/clients": "client",
  "/staff": "staff",
  "/credit_notes": "credit_notes",
  "/benchmarks": "SpaceOnBoarding",
  "/equipmentListing": "_equipments",
  "/myprofile": "MyProfile",
  "/create-dpgf-benchmark" : "SpaceOnBoarding",
  "/view-dpgf-proposal" : "SpaceOnBoarding",
  "/proposals" : "Proposals",
  "/hire-new-agent" : "hireNewAgent",
  "/onboarding-proposal" : "OnboardingProposal",
  "/onboarding-employees" :"OnboardingEmployee",
  "/view-onboarding-proposal" :"OnboardingProposalView",
  "/job-proposal-onboarding" :"OnboardingProposal",
  "/MyEmail" : "MyEmail",
  "/providerlist" : "ProviderList",
};

const PAGES_WITH_NO_FOOTER = ["/MyEmail", "/space-onboarding", "/explore-features", "/connect-provider", "/tenants", "/employees", "/providers", "/equipments", "/create-dpgf-benchmark", "/view-dpgf-proposal","/hire-new-agent","/onboarding-proposal","/onboarding-employees","/view-onboarding-proposal","/job-proposal-onboarding"];
const PAGES_WITH_NO_CALENDAR = ["/space-onboarding", "/explore-features", "/connect-provider", "/tenants", "/employees", "/providers", "/equipments", "/create-dpgf-benchmark", "/view-dpgf-proposal","/hire-new-agent","/onboarding-proposal","/onboarding-employees","/view-onboarding-proposal","/job-proposal-onboarding","/providerlist"];
const ONBOARDING_PAGES = ["/hire-new-agent","/onboarding-proposal","/onboarding-employees","/view-onboarding-proposal","/job-proposal-onboarding"];
const PAGES_WITH_NO_HEADER = ["/MyEmail"];
const PAGES_WITH_NO_LEFT_PENAL = ["/MyEmail"];
const languageSelect = [
  { value: "english", label: _l('l_english') },
  { value: "french", label: _l('l_french') },
];

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    globalView,
    commanoffcanvasIsOpen,
    taskFilters,
    activeTaskStatusFilters,
    getSelectedCalendarDate,
    isDayGridDay,
    isAppliedUnreadComment,
    isContactDirChat,
    globalSearchString,
    providerWallet
  } = useSelector((state) => state.customer);
  const {
    leadsFilters,
    leadStatusToggle,
    leadsBulkActionToggle
  } = useSelector((state) => state.leadsReducer);
  const { callTaskAPI } = useSelector((state) => state.chat);
  const {isCallRecived} = useSelector((state) => state.chat);

  const savedProject = localStorage.getItem("selectedOffice");
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const authToken = localStorage.getItem("accessToken");
  let user_type = localStorage.getItem("user_type");

  const location = useLocation();
  
  const [isStatusCleared, setIsStatusCleared] = useState(false);
  const [IsKanbanView, setIsKanbanView] = useState(
    localStorage.getItem("fav_dashboard") == "kanban_view" ? true : false
  );
  const [startDate] = useState(new Date());
  const [endDate] = useState(new Date());
  const [projectId, setProjectId] = useState(savedProject || 0);
  const [pageName, setPageName] = useState(PAGENAME_PROP_VALUE_FOR_PATH[location.pathname]);
  const [callTaskApi, setCallTaskApi] = useState(0);
  const [language, setlanguage] = useState(localStorage.getItem("language"))

  let contact_role = localStorage.getItem("contact_role");
  let callAPI = localStorage.getItem("callTaskAPi")
  const is_agent = localStorage.getItem("is_agent");
  const loginUserRole = localStorage.getItem("contact_role")
  ? localStorage.getItem("contact_role")
  : 0;
const isAdminAsStaff = localStorage.getItem("adminArchieve");



  const clearStatusFilter = (flag = true) => {
    setIsStatusCleared(flag);
  };
  const setLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    localStorage.setItem("language", language)
    
}

  useEffect(() => {
    localStorage.setItem(
      "selectedDate",
      moment(new Date()).format("YYYY-MM-DD")
    );
    setPageMetaDataDescription(DEFAULT_METADATA_DESCRIPTION);

    if (user_type == "operator") {
      // customerServices.getWalletListOfProvider().then((res)=>{
      //   if (res.status) {
      //    dispatch(setProviderWalleftList(res.data.length))
      //   }
      // })
    }
  }, []);

  useEffect(() => {
    if (globalView != "AichatView") {
      dispatch(setIDofFirstMessage(""));
      dispatch(setSelectedDateForChatai(new Date()));
    }

    if (globalView == "listView") {
      setIsKanbanView(false);
    }
    if (globalView === "spaceView") {
      CardListScroll();
    }
    if (globalView == "chat") {
      dispatch(setSelectedGloablView("liteVersionView"));
    }
    if (["listView","kanabanView","liteVersionView","calendarView","spaceView"].includes(globalView)){
      if ((projectId == 0 || projectId == null || projectId == undefined) && (pageName != "dashboardnew" && window.location.pathname != "/dashboard")) {
        DefaultLeftPanel(true);
      } else if ((projectId == 0 || projectId == null || projectId == undefined) && (pageName == "dashboardnew" && window.location.pathname == "/dashboard")) {
        showLeftPanelNew();
      }{
      }
    }else if(localStorage.getItem("is_registered") == 1 && isContactDirChat){
      showLeftPanelNew();
    } else if( pageName != "leadPage"){
      DefaultLeftPanel(true);
    }

    CardListFullHeight();
    if (globalView == "liteVersionView") {
      localStorage.setItem("tasklistView", "liteVersionView");
    }
    return () => {};
  }, [globalView]);

  useEffect(() => {
    if (window.location.pathname == "/") {
      if (
        projectId == 0 ||
        projectId == null ||
        projectId == undefined ||
        projectId == "null"
      ) {
        jquery(document).mouseup(function (e) {
          var container = jquery(".fixed-left-panel");
          if (!container.is(e.target) && container.has(e.target).length === 0) {
            hideLeftPanelNew();
          }
        });
      } else if (commanoffcanvasIsOpen) {
        hideLeftPanelNew();
      } else if (
        (commanoffcanvasIsOpen == false &&
        (projectId == 0 || projectId == null || projectId == undefined) || (contact_role == 3))
      ) {
        hideLeftPanelNew();
      } 
      else if(["page=connect"].find((x) => location.search.toLowerCase().includes(x)))
      {
        hideLeftPanelNew();
      }
      else {
        showLeftPanelNew();
      }
    }else if (ONBOARDING_PAGES.includes(location.pathname)) {
      document.body.classList.add('px-0')
      hideLeftPanelNew()
    }
  });

  useEffect(() => {
    const savedDate = localStorage.getItem("selectedDate");
    const isTaskCreationOngoing = sessionStorage.getItem("isTaskCreationOngoing");
    if (taskFilters && taskFilters.length && !isTaskCreationOngoing && !commanoffcanvasIsOpen) {
      dispatch(setTasklistLoader(true));
      if ( !["leadPage", "MyEmail","ProviderList"].includes(pageName)) {
      dispatch(
        getTaskList(
          client_id,
          contact_id,
          projectId,
          moment(savedDate).format("YYYY-MM-DD"),
          activeTaskStatusFilters.length ? 2 : 1,
          activeTaskStatusFilters,
          taskFilters,
          "",
          0,
          isDayGridDay && !isAppliedUnreadComment ? 1 : 0,
          isDayGridDay
            ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD")
            : moment(startDate).format("YYYY-MM-DD"),
          isDayGridDay
            ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD")
            : moment(endDate).format("YYYY-MM-DD")
        )
      );
      }else if (pageName == "leadPage") {
        dispatch(
          getLeadList(
            activeTaskStatusFilters ? activeTaskStatusFilters : [],
            1,
            taskFilters,
            leadsFilters,
            globalSearchString
          )
        );
      }
      if (pageName == "leadPage") {
        dispatch(
          projectTaskCounts(
            '',
            taskFilters,
            '',
            true,
            pageName,
            '',
            leadsFilters,
            globalSearchString
          )
        );
      }else if (!["ProviderList"].includes(pageName)) {
        dispatch(
              projectTaskCounts(
                projectId,
                taskFilters,
                '',
                false,
                pageName,
              )
            );
      }
    }
  }, [
    JSON.stringify(taskFilters),
    JSON.stringify(activeTaskStatusFilters),
    projectId,
    getSelectedCalendarDate,
    callAPI,
    JSON.stringify(callTaskApi),
    isCallRecived,
    leadsFilters,
  ]);
  useEffect(() => {
    if (pageName == "leadPage") {
      dispatch(
        projectTaskCounts(
          '',
          taskFilters,
          '',
          true,
          pageName,
          '',
          leadsFilters,
          globalSearchString
        )
      );
    }
  },[leadStatusToggle, globalSearchString, leadsBulkActionToggle])
  useEffect(() => {
    const pageNameForPath =
      PAGENAME_PROP_VALUE_FOR_PATH[location.pathname] || "Dashboard"; //As Dashboard component is being configured as default route (*) in App.js, "Dashboard" is used as page name in that component so using the same logic here...
    setPageName(pageNameForPath);

    if(location.pathname == "/" && window.location.pathname == "/dashboard"){
      navigate("/dashboard");
    }

    if (contact_role == 1 && is_agent == 0 && user_type == "operator" && providerWallet == 0 && !isAdminAsStaff) {
      if ((["/premiumsubscription"].includes(location.pathname))) {
        
      }else{
      navigate("/premiumsubscription?step=billing");
      }
      
    }
  }, [location.pathname]);

  useEffect(() => {
    if(callTaskAPI && Object.keys(callTaskAPI).length > 0){
    let selectedTopic = JSON.parse(localStorage.getItem("selectedTopic"))
    let assignedTopics = callTaskAPI && callTaskAPI.assignedTopics ? callTaskAPI.assignedTopics : []
    if (assignedTopics.length > 0 && selectedTopic) {
      let topic  = assignedTopics && assignedTopics.length > 0 && assignedTopics.filter((fil)=> {
        if (fil.mainKey == "my_task") {
          return fil.mainKey == selectedTopic.main_key && callTaskAPI.projectId == projectId          
        }else{
          return   fil.mainKey == selectedTopic.main_key && fil.filterKey == selectedTopic.filter_key && fil.mainSubKey == selectedTopic.main_sub_key && callTaskAPI.projectId == projectId          
        }
      }
    
      )
      
      if (topic.length > 0) {
        setCallTaskApi(callTaskAPI.taskId)
      }
      
      dispatch(callTaskListForRealtime({}))
    }
  }
  }, [callTaskAPI])
  

  return (
    <>
      <div className="d-flex flex-column h-100">
        {
          authToken
          ?
          <React.Fragment>
           {ONBOARDING_PAGES.includes(location.pathname) ? 
           <ExternalHeader 
                  profileImageURL={DEFAULT_PLACEHOLDER}
                  hideLeftPanel={true}
                  language={language}
                  setLanguage={setLanguage}
                  languageSelect={languageSelect}                
                  setlanguage={setlanguage} 
                  pageName={pageName}
                  fieldStep={""}
                  changeFields={()=>{}}
                  stepperProgress={()=>{}}

           /> 
              : PAGES_WITH_NO_HEADER.includes(location.pathname) 
              ? <></> 
                :<Header projectId={projectId} pageName={pageName} />}
            {PAGES_WITH_NO_CALENDAR.includes(location.pathname) ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Calendar
                projectId={projectId}
                clearStatusFilter={clearStatusFilter}
                setTaskModalClose={() => {}}
                pageName={pageName}
              />
            )}
           {ONBOARDING_PAGES.includes(location.pathname) 
            ? <></> 
            : PAGES_WITH_NO_HEADER.includes(location.pathname) 
              ? <></> 
              : <LeftPanel
              projectId={projectId}
              setSelectedProject={setProjectId}
              pageName={pageName}
              clearStatusFilter={clearStatusFilter}
              
            />}
            <Outlet />
            {PAGES_WITH_NO_FOOTER.includes(location.pathname) ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Footer pageName={pageName} />
            )}
          </React.Fragment>
          :
          <Outlet />
        }
      </div>
    </>
  );
};

export default Layout;
