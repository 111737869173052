import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { Check, Plus, X, CalendarBlank } from "phosphor-react";
import DatePicker from "react-datepicker";
import { leadStatuses, _l , getDatePickerLocale } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import CustomerServices from "../../services/customer-services";
import AdminServices from "../../services/admin-services";
import moment from "moment";
import { showMessage } from "../../actions/messages";
import {
  getLeadList,
} from "../../actions/leads-actions/leads-actions";
import PhoneInput from "react-phone-input-2";
const AddLeadModal = ({ show, handleClose }) => {
  const [customField, setCustomFiled] = useState([]);
  const { customFieldValues } = useSelector((state) => state.customer);
  const [customFieldInput, setCustomFieldInput] = useState({});
  const [numberFormate, setNumberFormate] = useState('');
  const [numberlength, setNumberLength]= useState({max : 14, min : 9});
  const SelectDemoOption = [
    { value: "1", label: "role - 1" },
    { value: "2", label: "role - 2" },
    { value: "3", label: "role - 3" },
  ];
  const position = [
    { value: "CEO", label: "CEO" },
    { value: "OM", label: "OM" },
    { value: "FM", label: "FM" },
    { value: "SG", label: "SG" },
    { value: "PM", label: "PM" },
    { value: "EA", label: "EA" },
    { value: "Autre", label: "Autre" },

  ];
  const languageOptions = [
    { value: "EN", label: _l("l_english") },
    { value: "FR", label: _l("l_french") },
  ];
  const initialInput = {
    status: null,
    source: null,
    channel: null,
    assigned: null,
    tags: [],
    name: "",
    address: "",
    position: "",
    city: "",
    email: "",
    state: "",
    website: "",
    country: "",
    country_iso2: "fr",
    phone: "",
    country_code: "",
    zipcode: "",
    company: "",
    default_language: "",
    description: "",
    public: -1,
    contacted_today: 1,
    contacted_today_time: "",
    office_area: 0,
    end_of_contract: "",
    salaries: 0,
    budget: 0,
    period_of_notice: 0,
    crystal_profile_description: "",
    url_records: "",
    first_name: "",
    mobile_number: "",
    cleaning_provider: "",
    how_did_you_hear_about: "",
    probability: null,
    contact_key_1: "",
    contact_key_2: "",
    position_contact_1: [],
    position_contact_2: [],
  };

  const autoCompleteRef1 = useRef();
  const autoCompleteRef2 = useRef();
  const autoCompleteRef3 = useRef();
  const addressRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  let suggestedAddress = useRef("");
  let suggestedCity = useRef("");
  let suggestedState = useRef("");

  const { myrStaffList, myrLeadSources } = useSelector(
    (state) => state.leadsReducer
  );
  const dispatch = useDispatch();

  const [ChangeType, setChangeType] = useState(true);
  const [ChangeTwoType, setChangeTwoType] = useState(true);
  const [ChangeCheckType, setChangeCheckType] = useState(true);
  const [leadInput, setLeadInput] = useState(initialInput);
  const [countryCodes, setCountryCodes] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [tagTitle, setTagTitle] = useState('');
  const [source, setSource] = useState([]);
  const [channel, setChannel] = useState([]);
  const addressOptions = {
    componentRestrictions: { country: leadInput.country_iso2.toLowerCase() },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };

  const stateOptions = {
    componentRestrictions: { country: leadInput.country_iso2.toLowerCase() },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["(regions)"],
  };

  const cityOptions = {
    componentRestrictions: { country: leadInput.country_iso2.toLowerCase() },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["(cities)"],
  };

  useEffect(() => {
    CustomerServices.getCountryCodes(true).then((res) => {
      if (res.status == "1") {
        const phoneNumberCodes = res.data.filter(
          (x) => x.is_calling_country == 1
        );
        setCountryCodes(
          phoneNumberCodes.map((code) => {
            return {
              value: code.code_value,
              label: code.code_value,
            };
          })
        );
        setCountryList(
          res.data.map((code) => {
            return {
              value: code.id,
              label: code.long_name,
              short_code: code.iso2,
            };
          })
        );
      }
    });
  }, []);

  useEffect(() => {
   setChannel(
    myrLeadSources && myrLeadSources.channels.map((item) => {
      return{
        value : item.id,
        label : item.name
      }
    })
   )
   setSource(
    myrLeadSources && myrLeadSources.sources.map((item) => {
      return{
        value : item.id,
        label : item.name
      }
    })
   )
  }, [JSON.stringify(myrLeadSources)])
  

  useEffect(() => {
    if (
      leadInput.address &&
      leadInput.address.trim().length &&
      addressRef.current
    ) {
      try {
        autoCompleteRef1.current = new window.google.maps.places.Autocomplete(
          addressRef.current,
          addressOptions
        ).addListener("place_changed", () => {
          let previousLead = leadInput
          previousLead['address'] = addressRef.current.value
          setLeadInput(previousLead);
          suggestedAddress.current = addressRef.current.value;
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (leadInput.city && leadInput.city.trim().length && cityRef.current) {
      try {
        autoCompleteRef2.current = new window.google.maps.places.Autocomplete(
          cityRef.current,
          cityOptions
        ).addListener("place_changed", () => {
          let previousLead = leadInput
          previousLead['city'] = cityRef.current.value
          setLeadInput(previousLead);
          suggestedCity.current = cityRef.current.value;
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (leadInput.state && leadInput.state.trim().length && stateRef.current) {
      try {
        autoCompleteRef3.current = new window.google.maps.places.Autocomplete(
          stateRef.current,
          stateOptions
        ).addListener("place_changed", () => {
          let previousLead = leadInput
          previousLead['state'] = stateRef.current.value
          setLeadInput(previousLead);
          suggestedState.current = stateRef.current.value;
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [leadInput.address, leadInput.city, leadInput.state]);

  useEffect(() => {
    if (customFieldValues) {
      setCustomFiled(customFieldValues)
    }

  }, [customFieldValues])

  const validateInputFields = () => {
    const mandatory_fields = ["address", "name", "email", "company"];
    let validationSuccess = true;

    if ((leadInput.phone.trim() == '' ||
    (numberlength.max != leadInput.phone.length && numberlength.min != leadInput.phone.length))) {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_valid_mobile_number')));
      validationSuccess = false;
    }

    Object.keys(leadInput)
      .reverse()
      .forEach((key) => {
        if (
          mandatory_fields.includes(key) &&
          (!leadInput[key] && !leadInput[key].value)
        ) {
          validationSuccess = false;
          dispatch(
            showMessage("unsucess", _l("l_error"), _l(`invalid_${key}_value`))
          );
        }
      });
    return validationSuccess;
  };

  const createNewLead = () => {
    if (validateInputFields()) {
      let contryCode = leadInput.country_code + " " + (leadInput.phone).slice((leadInput.country_code).length - 1)
      const lead = {
        status: leadInput.status.value,
        source: leadInput.source && leadInput.source.value,
        name: leadInput.name,
        is_new_lead: 1,
        address: leadInput.address,
        city: leadInput.city,
        email: leadInput.email,
        state: leadInput.state,
        assigned_id: leadInput.assigned.value,
        position: leadInput.position,
        tags: leadInput.tags.length ? JSON.stringify(leadInput.tags) : null,
        website: leadInput.website != '' ? leadInput.website : null,
        zipcode: leadInput.zipcode != '' ? leadInput.zipcode : null,
        country: leadInput.country.length ? leadInput.country[0].value : null,
        phone_number: contryCode,
        company_name: leadInput.company,
        default_language: leadInput.default_language.length ? leadInput.default_language[0].value : null,
        description: leadInput.description != '' ? leadInput.description : null,
        office_area: leadInput.office_area > 0 ? leadInput.office_area : null,
        is_public: leadInput.public,
        date_contacted: leadInput.contacted_today ? moment().format("YYYY-MM-DD HH:MM:SS") : leadInput.contacted_today_time instanceof Date && !isNaN(leadInput.contacted_today_time) ? moment(leadInput.contacted_today_time).format("YYYY-MM-DD HH:MM:SS") : null
        // channel_id: leadInput.channel.value
      };
      AdminServices.createNewLead(lead, customFieldInput).then((res) => {
        if (res.status) {
          dispatch(showMessage("sucess", _l("l_success"), _l("lead_created")));
          dispatch(getLeadList(0, 1));
          handleClose();
        }
        else {
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
        }
      });
    }
  };

  useEffect(() => {
    if (numberFormate.dialCode) {
      setLeadInput({...leadInput, country_code : `+${numberFormate.dialCode}`})
        const dialcodeL = Number(numberFormate.dialCode.length);
        let digitlength = numberFormate.format.match(/\./g).slice(numberFormate.dialCode.length);
        if (["32","352", "49", "44"].includes(numberFormate.dialCode)) {
            if (["49", "44"].includes(numberFormate.dialCode)) {
                setNumberLength({max : 11 + dialcodeL, min : 10 + dialcodeL})
            }else{
                setNumberLength({max : 10 + dialcodeL, min : 9 + dialcodeL})
            }
        }else{
            setNumberLength({max : digitlength.length + dialcodeL, min : digitlength.length + dialcodeL})
        }
    }
  }, [numberFormate])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="xl"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_add_new_leads")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="form-wrapper-main">
            <div className="row m-0 mb-3 border-bottom">
              <Form.Group className="col-xl-3 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_status")}
                </Form.Label>
                {ChangeType ? (
                  <div className="d-flex">
                    <Select
                      className="custom-select-menu flex-grow-1"
                      placeholder={_l("l_please_select")}
                      options={leadStatuses}
                      value={leadInput.status}
                      onChange={(e) => {
                        setLeadInput({ ...leadInput, status: e });
                      }}
                      classNamePrefix="react-select"
                    />
                    <a href="#/"
                      className="form-control  w-auto ms-2"
                      onClick={() => {
                        setChangeType(false);
                      }}
                    >
                      <Plus size={16} className="c-icons" weight="light" />
                    </a>
                  </div>
                ) : (
                  <div className="d-flex">
                    <Form.Control
                      placeholder={_l("l_status")}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className="flex-grow-1"
                    />
                    <a href="#/"
                      className="form-control w-auto ms-2"
                      onClick={() => {
                        setChangeType(true);
                      }}
                    >
                      <Check size={16} className="c-icons" weight="light" />
                    </a>
                  </div>
                )}
              </Form.Group>
              <Form.Group className="col-xl-3 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_source")}
                </Form.Label>
                {ChangeTwoType ? (
                  <div className="d-flex">
                    <Select
                      placeholder={_l("l_please_select")}
                      className="custom-select-menu flex-grow-1"
                      options={source}
                      value={leadInput.source}
                      onChange={(e) => {
                        setLeadInput({ ...leadInput, source: e });
                      }}
                      classNamePrefix="react-select"
                    />
                    <a href="#/"
                      className="form-control w-auto ms-2"
                      onClick={() => {
                        setChangeTwoType(false);
                      }}
                    >
                      <Plus size={16} className="c-icons" weight="light" />
                    </a>
                  </div>
                ) : (
                  <div className="d-flex">
                    <Form.Control
                      placeholder={_l("l_source")}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className="flex-grow-1"
                    />
                    <a href="#/"
                      className="form-control border-left-0 w-auto"
                      onClick={() => {
                        setChangeTwoType(true);
                      }}
                    >
                      <Check size={16} className="c-icons" weight="light" />
                    </a>
                  </div>
                )}
              </Form.Group>
              <Form.Group className="col-xl-3 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_channel")}
                </Form.Label>
                <Select
                placeholder={_l("l_please_select")}
                  className="custom-select-menu flex-grow-1"
                  options={channel}
                  value={leadInput.channel}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, channel: e });
                  }}
                  classNamePrefix="react-select"
                />
              </Form.Group>
              <Form.Group className="col-xl-3 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_assigned")}
                </Form.Label>
                <Select
                placeholder={_l("l_please_select")}
                  className="custom-select-menu"
                  options={myrStaffList.map((staff) => {
                    return {
                      value: staff.staffid,
                      label: staff.fullname,
                      role: 1,
                      profile_image: staff.profile_image_url,
                    };
                  })}
                  value={leadInput.assigned}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, assigned: e });
                  }}
                  classNamePrefix="react-select"
                />
              </Form.Group>
            </div>
            <Form.Group className="col-xl-12 c-input-box position-relative mb-3 border-bottom pb-3">
              <div className="d-flex flex-wrap align-items-center">
              <Form.Label className="input-label no-asterisk ps-2 mb-0 d-flex align-items-center my-1">
                {_l("l_tags")}
              </Form.Label>
                <a href="#/"
                  className="white-border-box ml-10 rounded-pill add-tag mx-0 h-auto my-1"
                  data-bs-toggle="tooltip"
                  title="add task"
                >
                  <div className="d-flex align-items-center flex-wrap">
                    <div className="img-width-14 " onClick={() => {
                      if (tagTitle != '') {
                        setLeadInput({ ...leadInput, tags: [tagTitle, ...leadInput['tags']] })
                        setTagTitle('')
                      } else {
                        dispatch(
                          showMessage("unsucess", _l("l_error"), _l('l_add_tags'))
                        );
                      }

                    }}>
                      <Plus size={14} className="c-icons" weight="light" />
                    </div>
                    <input
                      type="text"
                      placeholder={_l("l_add_tag")}
                      className=" border-0 bg-transparent text_wrap"
                      data-bs-toggle="tooltip"
                      value={tagTitle}
                      onChange={(e) => {
                        setTagTitle(e.target.value)
                      }}
                    />
                  </div>
                </a>
                {
                  leadInput && leadInput.tags && leadInput.tags.length ?
                    leadInput.tags.map((tag, index) => {
                      return (
                        <div
                          className="pill-tag add-tag with_bg rounded-pill mx-2 my-1 px-2" key={index}
                          data-bs-toggle="tooltip"
                          title={`${tag}`}
                        >
                          <span className="color-white-80 c-font f-12 pe-1">{tag}</span>
                          <a href="#/" className="opacity-50 " onClick={() => {
                            setLeadInput({ ...leadInput, tags: leadInput['tags'].filter((title, tagIndex) => tagIndex != index) })
                          }}>
                            <X size={14} className="c-icons" weight="light" />
                          </a>
                        </div>
                      )
                    })
                    : <></>
                }

              </div>
            </Form.Group>
            <div className="row m-0 border-bottom">
              <Form.Group className="col-xl-4 c-input-box position-relative  mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_name")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_name")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={leadInput.name}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, name: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative  mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_address")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={_l("l_address")}
                  rows={1}
                  ref={addressRef}
                  value={leadInput.address}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, address: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_position")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_position")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={leadInput.position}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, position: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_city")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_city")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  ref={cityRef}
                  value={leadInput.city}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, city: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_email_address_label")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_email_address_label")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  type="email"
                  value={leadInput.email}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, email: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_state")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_state")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  ref={stateRef}
                  value={leadInput.state}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, state: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_website")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_website")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={leadInput.website}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, website: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_country")}
                </Form.Label>
                <Select
                
                placeholder={_l("l_please_select")}
                  className="custom-select-menu"
                  options={countryList}
                  value={leadInput.country}
                  onChange={(e) => {
                    let previousLead = leadInput
                    setLeadInput({
                      ...previousLead,
                      country: e,
                      country_iso2: e.short_code,
                      city: "",
                      state: "",
                      zipcode: "",
                    });
                  }}
                  classNamePrefix="react-select"
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 select-half-separator">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_phone")}
                </Form.Label>
                <div className="align-items-center d-flex m-0 react-tel-input-small row select-half-separator-main">
                <PhoneInput
                    inputClass="title-fonts f-12"
                    autoFormat={true}
                    containerClass="f-32"
                    disableCountryGuess={true}
                    countryCodeEditable={false}
                    disableSearchIcon={true}
                    enableSearch={true}
                    enableAreaCodes={true}
                    enableLongNumbers = {true}
                    localization={""}
                    country='fr'
                    preferredCountries={['fr', 'de', 'be', 'es', 'ch','gb','pl','lu']}
                    value={leadInput.phone}
                    placeholder="780 1250 456"
                    isValid={(value, country)=>{
                        if (value.length > numberlength.max) {
                          setLeadInput({ ...leadInput, phone: value.slice(0,numberlength.max) });
                        } else {
                        return true;
                        }
                    }}
                    onChange={(phone, formattedValue) => {
                      setLeadInput({ ...leadInput, phone: phone });
                      setNumberFormate(formattedValue);
                    }}
                    inputProps={{
                        autoFocus: true
                      }}
                    />
                  {/* <Form.Group className="col-xl-3 c-input-box position-relative pe-0">
                    <Select
                    placeholder="+33"
                      className="custom-select-menu"
                      options={countryCodes}
                      classNamePrefix="react-select"
                      value={leadInput.country_code}
                      onChange={(e) => {
                        setLeadInput({ ...leadInput, country_code: e });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-xl-9 c-input-box position-relative">
                    <Form.Control
                      placeholder={_l("l_phone")}
                      type="text"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={leadInput.phone}
                      onChange={(e) => {
                        const reg = new RegExp("^[0-9]+$");
                        const number = e.target.value;
                        if (number.length <= 10 && number.match(reg)) {
                          setLeadInput({ ...leadInput, phone: e.target.value });
                        }
                      }}
                    />
                  </Form.Group> */}
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_zip_code")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_zip_code")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={leadInput.zipcode}
                  onChange={(e) => {
                    const reg = new RegExp("^[0-9]+$");
                    const number = e.target.value;
                    if (number.length <= 6 && number.match(reg)) {
                      setLeadInput({ ...leadInput, zipcode: e.target.value });
                    }
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_copany")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_copany")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={leadInput.company}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, company: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_default_language")}
                </Form.Label>
                <Select
                placeholder={_l("l_please_select")}
                  className="custom-select-menu"
                  options={languageOptions}
                  classNamePrefix="react-select"
                  value={leadInput.default_language}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, default_language: e });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_description")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={_l("l_description")}
                  value={leadInput.description}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, description: e.target.value });
                  }}
                  rows={3}
                />
              </Form.Group>

              <Form.Group className="col-12 c-input-box position-relative mb-3 border-bottom p-0">
                <div className="form-check with-span p-0">
                  <div className="d-flex align-items-center">
                    <Form.Label className="form-check-label text_wrap mx-3 ms-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        checked={leadInput.public > 0}
                        onChange={(e) => {
                          setLeadInput({
                            ...leadInput,
                            public: e.target.checked ? 1 : 0,
                          });
                        }}
                      />
                      <span>{_l("l_public")}</span>
                    </Form.Label>
                    <Form.Label className="form-check-label text_wrap mt-2 mb-3 mx-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={leadInput.contacted_today}
                        checked={leadInput.contacted_today > 0}
                        onChange={(e) => {
                          setLeadInput({
                            ...leadInput,
                            contacted_today: e.target.checked ? 1 : 0,
                          });
                        }}
                        onClick={() => {
                          setChangeCheckType(!ChangeCheckType);
                        }}
                      />
                      <span>{_l("l_contacted_today")}</span>
                    </Form.Label>
                    {!ChangeCheckType ? (
                      <div
                        className="custom-datepicker"
                        data-bs-toggle="tooltip"
                        title={moment().format("DD/MM/YYYY")}
                      >
                        <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                          <CalendarBlank size={18} className="c-icons" weight="light" />
                          <DatePicker
                            selected={leadInput.contacted_today_time}
                            disabledKeyboardNavigation
                            onChange={(date) => {
                              setLeadInput({
                                ...leadInput,
                                contacted_today_time: date,
                              });
                            }}
                            placeholderText={`${_l("l_date_placeholder_text")}`}
                            locale={getDatePickerLocale()}
                            dateFormat="dd MMMM yyyy"
                            selectsStart
                            defaultMenuIsOpen
                            calendarStartDay={1}
                          ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Form.Group>
              {
                customField && customField.length ?
                  customField.filter((type) => type.type == 'input').map((customValue) => {
                    return (
                      <Form.Group className="col-xl-6 c-input-box position-relative mb-3">
                        <Form.Label className="input-label no-asterisk ">
                          {customValue.name}
                        </Form.Label>
                        <Form.Control
                          aria-describedby="basic-addon1"
                          type="text"
                          placeholder={customValue.name}
                          value={customFieldInput[`custom_fields[${customValue.id}]`]}
                          onChange={(e) => {

                            setCustomFieldInput({
                              ...customFieldInput,
                              [`custom_fields[${customValue.id}]`]: e.target.value
                            });
                          }}
                        />
                      </Form.Group>
                    )
                  })
                  : <></>
              }
              {
                customField && customField.length ?
                  customField.filter((type) => type.type == 'select').map((customValue) => {
                    return (
                      <Form.Group className="col-xl-6 c-input-box position-relative mb-3 ">
                        <Form.Label className="input-label no-asterisk ">
                          {customValue.name}
                        </Form.Label>
                        <Select
                        placeholder={_l("l_please_select")}
                          className="custom-select-menu"
                          options={customValue.options && customValue.options.length ? customValue.options.map((name) => {
                            return {
                              value: name,
                              label: name,
                            };
                          }) : []}
                          classNamePrefix="react-select"
                          onChange={(e) => {
                            let previous = {
                              ...customFieldInput,
                              [`custom_fields[${customValue.id}]`]: e
                            }
                            setCustomFieldInput(previous);
                          }}
                          value={customFieldInput[`custom_fields[${customValue.id}]`]}
                        />
                      </Form.Group>
                    )
                  })
                  : <></>
              }
              {
                customField && customField.length ?
                  customField.filter((type) => type.type == 'number').map((customValue) => {
                    return (
                      <Form.Group className="col-xl-6 c-input-box position-relative mb-3">
                        <Form.Label className="input-label no-asterisk ">
                          {customValue.name}
                        </Form.Label>
                        <Form.Control
                          placeholder={customValue.name}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          type="number"
                          value={customFieldInput[`custom_fields[${customValue.id}]`]}
                          onChange={(e) => {
                            setCustomFieldInput({
                              ...customFieldInput,
                              [`custom_fields[${customValue.id}]`]: e.target.value
                            });
                          }}
                        />
                      </Form.Group>
                    )
                  })
                  : <></>
              }
              {
                customField && customField.length ?
                  customField.filter((type) => type.type == 'date_picker').map((customValue) => {
                    return (
                      <Form.Group className="col-xl-6 c-input-box position-relative mb-3">
                        <Form.Label className="input-label no-asterisk ">
                          {customValue.name}
                        </Form.Label>
                        <div
                          className="custom-datepicker"
                          data-bs-toggle="tooltip"
                        // title={moment().format("DD/MM/YYYY")}
                        >
                          <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                            <CalendarBlank size={18} className="c-icons" weight="light" />
                            <DatePicker

                              selected={customFieldInput[`custom_fields[${customValue.id}]`]}
                              disabledKeyboardNavigation
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date)) {
                                  setCustomFieldInput({
                                    ...customFieldInput,
                                    [`custom_fields[${customValue.id}]`]: date
                                  });
                                }
                              }}
                               placeholderText={`${_l("l_date_placeholder_text")}`}
                               locale={getDatePickerLocale()}
                              dateFormat="dd MMMM yyyy"
                              selectsStart
                              defaultMenuIsOpen
                              calendarStartDay={1}
                            ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                          </div>
                        </div>
                      </Form.Group>
                    )
                  })
                  : <></>
              }
              {/* <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_end_of_contract")}
                </Form.Label>
                <div
                  className="custom-datepicker"
                  data-bs-toggle="tooltip"
                  title={moment().format("DD/MM/YYYY")}
                >
                  <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                    <CalendarBlank size={18} className="c-icons" weight="light" />
                    <DatePicker
                      selected={leadInput.end_of_contract}
                      disabledKeyboardNavigation
                      onChange={(date) => {
                        if (date instanceof Date && !isNaN(date)) {
                          setLeadInput({ ...leadInput, end_of_contract: date });
                        }
                      }}
                      placeholderText="dd MMMM yyyy"
                      dateFormat="dd MMMM yyyy"
                      selectsStart
                      defaultMenuIsOpen
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_crystal_profile")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_crystal_profile")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={leadInput.crystal_profile_description}
                  onChange={(e) => {
                    setLeadInput({
                      ...leadInput,
                      crystal_profile_description: e.target.value,
                    });
                  }}
                />
              </Form.Group> */}
              {/* <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_number_of_salaries")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_number_of_salaries")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  type="number"
                  value={leadInput.salaries}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, salaries: e.target.value });
                  }}
                />
              </Form.Group> */}
              {/* <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_budget")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_budget")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  type="number"
                  value={leadInput.budget}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, budget: e.target.value });
                  }}
                />
              </Form.Group> */}
              {/* <Form.Group className="col-xl-4 c-input-box position-relative mb-3 d-none">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_period_of_notice")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_period_of_notice")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  type="number"
                  value={leadInput.period_of_notice}
                  onChange={(e) => {
                    setLeadInput({
                      ...leadInput,
                      period_of_notice: e.target.value,
                    });
                  }}
                />
              </Form.Group> */}

              {/* <Form.Group className="col-xl-12 c-input-box position-relative mb-3 d-none">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_crystal_profile_description")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={_l("l_crystal_profile_description")}
                  rows={3}
                />
              </Form.Group> */}
              {/* <Form.Group className="col-xl-12 c-input-box position-relative mb-3 d-none">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_URL_records")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={_l("l_URL_records")}
                  rows={3}
                  value={leadInput.url_records}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, url_records: e.target.value });
                  }}
                />
              </Form.Group> */}
              {/* <Form.Group className="col-xl-6 c-input-box position-relative mb-3 d-none">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_first_name")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_first_name")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={leadInput.first_name}
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, first_name: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box position-relative mb-3 d-none">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_mobile_number")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_mobile_number")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  type="number"
                  value={leadInput.mobile_number}
                  onChange={(e) => {
                    const reg = new RegExp("^[0-9]+$");
                    const number = e.target.value;
                    if (number.length <= 10 && number.match(reg)) {
                      setLeadInput({
                        ...leadInput,
                        mobile_number: e.target.value,
                      });
                    }
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 d-none">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_cleaning_provider")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={_l("l_cleaning_provider")}
                  value={leadInput.cleaning_provider}
                  onChange={(e) => {
                    setLeadInput({
                      ...leadInput,
                      cleaning_provider: e.target.value,
                    });
                  }}
                  rows={3}
                />
              </Form.Group> */}
              {/* <Form.Group className="col-xl-6 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_contact_key_1")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={_l("l_contact_key_1")}
                  value={leadInput.contact_key_1}
                  onChange={(e) => {
                    setLeadInput({
                      ...leadInput,
                      contact_key_1: e.target.value,
                    });
                  }}
                  rows={3}
                />
              </Form.Group> */}


              {/* <Form.Group className="col-xl-6 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_contact_key_2")}
                </Form.Label>
                <Form.Control
                  as="text"
                  placeholder={_l("l_contact_key_2")}
                  rows={3}
                  value={leadInput.contact_key_2}
                  onChange={(e) => {
                    setLeadInput({
                      ...leadInput,
                      contact_key_2: e.target.value,
                    });
                  }}
                />
              </Form.Group> */}
              {/* <Form.Group className="col-xl-6 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_contact_key_2")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_contact_key_2")}
                  aria-describedby="basic-addon1"
                  type="text"
                  value={leadInput.contact_key_2}
                  onChange={(e) => {
                    setLeadInput({
                      ...leadInput,
                      contact_key_2: e.target.value,
                    });
                  }}
                />
              </Form.Group> */}

              {/* <Form.Group className="col-xl-6 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {`${_l("l_position_contact")} 2 `}
                </Form.Label>
                <Select
                  className="custom-select-menu"
                  options={position}
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    setLeadInput({ ...leadInput, position_contact_2: [e] });
                  }}
                  value={leadInput.position_contact_2}
                />
              </Form.Group> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_cancel")}
          </Button>
          <Button
            variant="primary" size="sm"
            onClick={() => {
              createNewLead();
            }}
          >
            {_l("l_confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddLeadModal;
