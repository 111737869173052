import React, { useEffect, useState } from "react";
import { X, CalendarBlank, Link } from "phosphor-react";
import { _l, formateDate, showError, showSuccess } from "../../hooks/utilities";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import { DropdownMenu } from "reactstrap";
import { Button } from "react-bootstrap";
import subDays from "date-fns/subDays";
import { useDispatch, useSelector} from "react-redux";
import addDays from "date-fns/addDays";
import differenceInDays from "date-fns/differenceInDays";
import getMonth from "date-fns/getMonth";
import { leftPanelFilters, selectedCalendarDate, setDaterangeFilter, setDaterangeFilterApplied, setLastMessageOrTaskDate, setSelectedDateForChatai, setSelectedDateView, setSelectedGloablView, unReadCommentTasks } from "../../actions/customer";
import Select from "react-select";
import moment from "moment";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import { useNavigate } from "react-router-dom";
import { fetchMessages } from "../../actions/chat";
// import { Button } from "rsuite";

const DateRangeDropdown = ({ 
  show, 
  setDatesFrom, 
  pageName = "", 
  setShow, 
  handleclose, 
  initialdates,
  setIsDateRangeFilterApplied =() => {},
  setappliedFilters = () =>{}
 }) => {
  const [fromDate, setFromDate]= useState(initialdates[0]);
  const [toDate, setToDate]= useState(initialdates[1]);
  const [lastYearMonths, setLastYearMonths] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { globalView, taskFilters } = useSelector((state) => state.customer);
  let dateRangeFilters = taskFilters && taskFilters.length ? taskFilters.filter((dateRangeFilter) => dateRangeFilter.main_key == "date_range_filter") : []


  let predefinedBottomRanges = [
    {
      label: _l('l_today'),
      value: [new Date(), new Date(),"today"],
    },
    {
      label: _l('l_this_week'),
      // value: [startOfWeek(new Date()), endOfWeek(new Date()),"this_week"],
      value: [new Date(new Date().setDate(new Date().getDate() - new Date().getDay() +1 )), new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7)),"this_week"],
    },
    // {
    //   label: _l('l_last_7_days'),
    //   value: [subDays(new Date(), 6), new Date(),"last_7_days"],
    // },
    // {
    //   label: _l('l_last_30_days'),
    //   value: [subDays(new Date(), 29), new Date(),"last_30_days"],
    // }
  ];

// useEffect(() => {
//   for (let i = 0; i <= 11; i++) {
//   setLastYearMonths(lastYearMonths => [...lastYearMonths, 
//       {
//         label: moment().subtract(i, 'months').format('MMM YYYY'),
//         value: [startOfMonth(moment().subtract(i, 'months').startOf('month').toDate()),  endOfMonth(moment().subtract(i, 'months').endOf('month').toDate())],
//       }
//     ])
//   }
// }, [])
useEffect(() => {
  const currentMonth = moment().month();
  const currentYear = moment().year();
  const months = [];

  // Calculate months from current month to last 12 months
  for (let i = 0; i <= 11; i++) {
    const month = currentMonth - i;
    const year = currentYear - (month < 0 ? 1 : 0);
    months.push({
      label: moment([year, month >= 0 ? month : 12 + month]).format('MMM YYYY'),
      value: [
        moment([year, month >= 0 ? month : 12 + month]).startOf('month').toDate(),
        moment([year, month >= 0 ? month : 12 + month]).endOf('month').toDate(),
      ],
    });
  }

  // Calculate months from current month to the end of the current year
  const remainingMonths = 12 - currentMonth;
  for (let i = 1; i < remainingMonths; i++) {
    const month = currentMonth + i;
    months.push({
      label: moment([currentYear, month]).format('MMM YYYY'),
      value: [
        moment([currentYear, month]).startOf('month').toDate(),
        moment([currentYear, month]).endOf('month').toDate(),
      ],
    });
  }

  // If current month is 10, 11, or 12, add next three months to the future
  if (currentMonth >= 9) {
    let addMonth = currentMonth == 9 ? 1 : currentMonth == 10 ? 2 : 3
    for (let i = 0; i <= addMonth; i++) {
      const month = i;
      const year = currentYear + 1;
      months.push({
        label: moment([year, month]).format('MMM YYYY'),
        value: [
          moment([year, month]).startOf('month').toDate(),
          moment([year, month]).endOf('month').toDate(),
        ],
      });
    }
  }

  // Sort months from future to past
  const sortedMonths = months.sort((a, b) => moment(b.label) - moment(a.label));
  setLastYearMonths(sortedMonths);
}, []);

  if(pageName != "MyPeople" && globalView != "calendarView"){
    predefinedBottomRanges.unshift({
      label: _l('l_all'),
      value: [addDays(new Date(), -1), addDays(new Date(), -1), "all"],
    });
  }
  if (pageName == 'operatorListReporting') {
    predefinedBottomRanges = predefinedBottomRanges.filter((date) => date.label != _l('l_all'))
  }

  return (
    <DropdownMenu container="body" direction="down" className={`w400px`} aria-labelledby="dateRangePickerDropdown" id="dateRangePickerDropdown">
      <li>
        <div className="dropdown-header">
          <div className="d-flex align-items-center justify-content-between">
            <div className="c-list-detail title-fonts text-truncate color-white c-font f-13 fw-semibold">
              {_l("l_select_date_range")}
            </div>
            <a href="#/" className="right-image d-none" data-bs-dismiss="dropdown">
              <X size={18} className="c-icons" weight="light" />
            </a>
          </div>
        </div>
      </li>
      <li>
        <hr className="dropdown-divider mt-0" />
      </li>
      <li className="">
        <ul className="gx-3 m-0 p-2 py-0 d-flex flex-wrap w-100">
          <li className="pb-15px flex-grow-1">
            <Form.Group className="position-relative">
              <Form.Label className="input-label no-asterisk">
                {`${_l("l_from")}`}
              </Form.Label>
              <div className="custom-datepicker position-relative RangeInputAbsolute">
                <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                  <div className="action_icon h32w32 with_bg">
                    <CalendarBlank
                      size={18}
                      weight="light"
                      className="c-icons opacity-60"
                    />
                  </div>
                  <DatePicker
                      dateFormat="dd/MM/yyyy"
                      block
                      oneTap
                      // appearance="subtle"
                      placeholder={`${_l('l_select_date_range')}`}
                      placement="bottomEnd"
                      placeholderText={`${_l("l_date_placeholder_text")}`}
                      calendarStartDay={1}
                      onChange={(e) => {
                        setFromDate(e)
                        setToDate(e)
                      }}
                      selected={fromDate}
                    isoWeek
                      >
                    </DatePicker>
                </div>
              </div>
            </Form.Group>
          </li>
          <li className="pb-15px flex-grow-1 ms-10px">
            <Form.Group className="position-relative">
              <Form.Label className="input-label no-asterisk">
                {`${_l("l_to")}`}
              </Form.Label>
              <div className="custom-datepicker position-relative RangeInputAbsolute">
                <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                  <div className="action_icon h32w32 with_bg">
                    <CalendarBlank
                      size={18}
                      weight="light"
                      className="c-icons opacity-60"
                    />
                  </div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    block
                    oneTap
                    // appearance="subtle"
                    placeholderText={`${_l("l_date_placeholder_text")}`}
                    calendarStartDay={1}
                    placeholder={`${_l('l_select_date_range')}`}
                    placement="bottomEnd"
                    onChange={(e) => {
                      setToDate(e)
                    }}
                    minDate={fromDate}
                    selected={toDate}
                    isoWeek
                    ></DatePicker>
                </div>
              </div>
            </Form.Group>
          </li>
          <li className="pb-15px ms-10px">
            <Form.Group className="position-relative">
              <Form.Label className="input-label no-asterisk user-select-none opacity-0">
                x
              </Form.Label>
              <div className="custom-datepicker position-relative RangeInputAbsolute">
              <Button className="rs-btn-sm" variant="primary" size="sm"
              style={{height: "53px", minWidth: "53px"}}
              disabled={!fromDate}
               onClick={(e) => {
                  if (pageName == 'operatorListReporting') {
                    dispatch(
                      unReadCommentTasks(false)
                    );
                  }
                  handleclose()
                  if ((formateDate(fromDate) == formateDate(toDate)) || (fromDate && !toDate)) {  
                    dispatch(setDaterangeFilterApplied(false));
                    setIsDateRangeFilterApplied(false)  
                    if((pageName == "ChatAi" || globalView == "AichatView")){
                    dispatch(setSelectedDateForChatai(fromDate))
                    dispatch(selectedCalendarDate(fromDate));
                    dispatch(fetchMessages(         
                      localStorage.getItem("chatUserId"), 
                      localStorage.getItem("activeGroupId"),
                      0,
                      ()=>{},
                      1,
                      new Date(moment(fromDate).add(1 ,"days")),
                      ))
                    }
                    if(formateDate(fromDate) == formateDate(new Date())){
                      setappliedFilters("today")
                      dispatch(setDaterangeFilter("today"))
                    }else{
                      setDaterangeFilter("date")
                      dispatch(setDaterangeFilter("date"))
                      localStorage.setItem("selectedDate",fromDate)
                    }

                  } else {
                    // dispatch(setDaterangeFilterApplied(true));
                    // setIsDateRangeFilterApplied(true)
                    dispatch(setSelectedDateView("customDateView"));
                    setappliedFilters("CustomRange")
                    dispatch(setDaterangeFilter("CustomRange"))
                  }
                  if(globalView == "calendarView" && (differenceInDays(toDate, fromDate) > 31 || (getMonth(toDate) != getMonth(fromDate) && differenceInDays(toDate, fromDate) > 6))){
                        dispatch(
                          showConfirmation(
                            _l("l_are_you_sure"),
                            differenceInDays(toDate, fromDate) > 31 ? 
                            _l("l_sorry_calendar_view_cant_be_shown_for_large_date_range_selections_do_you want_to_switch_to_list_view")
                            : _l("l_sorry_calendar_view_cant_be_shown_for_two_diffrent_months_do_you_want_to_switch_to_list_view"),
                            _l("l_yes"),
                            _l("l_no"),
                            undefined,
                            (e) => {
                              dispatch(setSelectedGloablView("listView"));
                              navigate("/")
                              setDatesFrom([fromDate,toDate])
                              dispatch(toastCloseAction());
                            }
                          )
                        );
                        dispatch(setDaterangeFilter("CustomRange"))
                    }
                    else{
                   setDatesFrom([fromDate,toDate])
                   localStorage.setItem("fromDate", fromDate);
                   localStorage.setItem("toDate", toDate);
                  }
                  }}
                 >{_l("l_apply")}</Button>
              </div>
            </Form.Group>
          </li>
        </ul>
          <li className="dropdown-header">
            <div className="d-flex justify-content-around calender-footer-tabs align-items-center">
              {predefinedBottomRanges.map((range, i) =>{
                return (<>
                {range.value[2] == "today" && globalView != "calendarView"  ? <div className="me-10px px-0 with_separator2_10"></div>  : <></>}
                  <a href="#/" className="c-font f-14 color-white-60 calender-footer-items me-10px"
                    onClick={(e) => { 
                      setDatesFrom(range.value)
                      setShow(false)
                      setappliedFilters(range.value[2])
                      if(range.value[2] == "today"){
                        dispatch(setSelectedDateForChatai(new Date()));
                        dispatch(setLastMessageOrTaskDate(formateDate(new Date())));                   
                        dispatch(
                            leftPanelFilters({
                              main_key: "date_range_filter",
                              filter_key: "date_range_filter",
                              title: "date_range_filter",
                            },"","remove_range")
                          );
                      }
                      if(range.value[2] == "today" || range.value[2] == "last_7_days" || range.value[2] == "this_week"){
                        setIsDateRangeFilterApplied(false);
                        dispatch(setDaterangeFilterApplied(false));
                        dispatch(setDaterangeFilter(range.value[2]))
                      }else{
                        setIsDateRangeFilterApplied(true);
                        dispatch(setDaterangeFilterApplied(true));
                      }
                     }}
                  >{range.label}
                  </a>
                </>)
              })}
              <Select
                  placeholder={_l("l_select_month")}
                  className="custom-select-menu custom-select-sm drop-right min-h-33 w-200 m-w40 with-filter-select me-0"
                  classNamePrefix="react-select"
                  options={lastYearMonths}
                  onChange={(e) => {
                    setDatesFrom(e.value)
                    setShow(false)
                    dispatch(setDaterangeFilterApplied(true));
                    dispatch(setSelectedDateView("monthView"));  
                    dispatch(setDaterangeFilter("month"))
                  }}
                />
            </div>
          </li>
      </li>
      <ReactTooltip />
    </DropdownMenu>
  );
};

export default DateRangeDropdown; 