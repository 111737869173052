export const GET_BENCHMARK_SLOTS = "GET_BENCHMARK_SLOTS";
export const ASSIGN_SLOT_TO_OPERATOR = "ASSIGN_SLOT_TO_OPERATOR";
export const GET_CHECKLIST_TEMPLATES = "GET_CHECKLIST_TEMPLATES";
export const GET_CREATE_SCHEDULE_DETAILS = "GET_CREATE_SCHEDULE_DETAILS";
export const GET_SPACE_ROOMS = "GET_SPACE_ROOMS";
export const ADD_SPACE_ROOM = "ADD_SPACE_ROOM";
export const UPDATE_KPI_COUNTS = "UPDATE_KPI_COUNTS";
export const CREATE_SCHEDULE = "CREATE_SCHEDULE";
export const GET_SPACE_ROOMS_ITEM = "GET_SPACE_ROOMS_ITEM";
export const REMOVE_SPACE_ROOM = "REMOVE_SPACE_ROOM";
export const GET_PROPOSAL_COMMENT = "GET_PROPOSAL_COMMENT";
export const REMOVE_BENCHMARK = "REMOVE_BENCHMARK";
export const STOP_BENCHMARK = "STOP_BENCHMARK";
export const UPDATE_TYPE_OF_SPACE = "UPDATE_TYPE_OF_SPACE";
export const GET_FLOOR_DETAILS = "GET_FLOOR_DETAILS";
export const UPDATE_TYPE_OF_SPACE_LIST = "UPDATE_TYPE_OF_SPACE_LIST";
export const SET_FLOOR_FILTER_ARRAY = "SET_FLOOR_FILTER_ARRAY";
export const SELECT_FLOOR_FOR_SCHEDULE = "SELECT_FLOOR_FOR_SCHEDULE";
export const SET_FLOOR_PLANNING = "SET_FLOOR_PLANNING";
export const UPDATE_FLOOR_SETTING = "UPDATE_FLOOR_SETTING";
export const ADD_CHECKLIST_ITEM_TO_COPY = "ADD_CHECKLIST_ITEM_TO_COPY";
export const REMOVE_CHECKLIST_ITEM_FROM_ARRAY = "REMOVE_CHECKLIST_ITEM_FROM_ARRAY";
export const CLEAR_CHECKLIST_CLIPBOARD = "CLEAR_CHECKLIST_CLIPBOARD";
export const GET_GLOBAL_DOC_TYPE = "GET_GLOBAL_DOC_TYPE";
export const UPDATE_SPACE_COEFFICIENT = "UPDATE_SPACE_COEFFICIENT";
