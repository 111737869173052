import { GET_USER_ID_GLOBAL, LOGIN_SUCCESS } from "../actions/action-type";

const CryptoJS = require("crypto-js");
const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      if (!payload.data.status) {
        localStorage.clear();
        localStorage.setItem("version", process.env.REACT_APP_VERSION);
        return state;
      }
      const client_id = payload.data.data.client_id;
      const contact_id = payload.data.data.contact_id;
      const enc_token = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(payload.data.extra.token),
        process.env.REACT_APP_JWT_ENC_KEY
      );

      var userData = payload.data.data;

      if(userData.is_external)
      {
        sessionStorage.setItem("schedule_id", userData.schedule_id);
        sessionStorage.setItem("project_id", userData.project_id);
      }

      localStorage.setItem("accessToken", enc_token);
      localStorage.setItem("client_id", client_id);
      localStorage.setItem("contact_id", contact_id);
      localStorage.setItem("email", payload.data.data.email);
      localStorage.setItem("full_name", payload.data.data.full_name);
      localStorage.setItem("contact_role", payload.data.data.contact_role);
      localStorage.setItem("contact_image", payload.data.data.contact_image);
      localStorage.setItem("company_name", payload.data.data.company_name);
      localStorage.setItem("company_logo", payload.data.data.company_logo);
      localStorage.setItem("user_type", payload.data.data.user_type);
      if(payload.data.data.is_agent)
      {
        localStorage.setItem("is_agent", payload.data.data.is_agent);
      }
      localStorage.setItem("is_active_space", payload.data.data.is_active_space)
      localStorage.setItem("is_registration_confirmed", payload.data.data.registration_confirmed);
      localStorage.setItem(
        "language",
        payload.data.data.language == "french" ? "french" : "english"
      ); 
      localStorage.setItem("fav_dashboard", payload.data.data.dashboard_name);
      localStorage.setItem("is_external_user", payload.data.data.is_external)
      if ((payload.data.data.admin && payload.data.data.admin === "1") || (payload.data.data.user_type == 'staff')) {
        localStorage.setItem("admin", true);
      }
      if ((payload.data.data.admin && payload.data.data.admin === "0") && (payload.data.data.user_type == 'staff')) {
        localStorage.setItem("admin", 0);
      }
      if ((payload.data.data.boss_operator_id) && (payload.data.data.user_type == 'operator')) {
        localStorage.setItem("boss_operator", payload.data.data.boss_operator_id);
      }
      if (payload.data.data.user_type == 'contact') {
        localStorage.setItem("show_json_api_tab", payload.data.data.show_json_api_tab);
      }
      localStorage.setItem(
        "firstname",
        payload.data.data.firstname ? payload.data.data.firstname : ""
      );
      localStorage.setItem(
        "lastname",
        payload.data.data.lastname ? payload.data.data.lastname : ""
      );
      localStorage.setItem(
        "selectedTheme",
        userData.user_theme == 1 ? "light" : "dark"
      );
      localStorage.setItem("chat_project_id",payload.data.data.chat_project_id)
      if (payload.data.data && payload.data.data.is_agent == 1 && payload.data.data.is_agent_verification == 0) {
        localStorage.setItem("is_registered", 1)
      }
      localStorage.setItem("is_agent", payload.data.data.is_agent)
      localStorage.setItem("wallet_count", Number(payload.data.data.wallet_count))
      

      localStorage.setItem("user_hash_id",payload.data.data.hash)

      const operatorProfileDetails = [
        "address",
        "postal_code",
        "city",
        "country",
      ];
      //Saving operator details to local storage
      Object.keys(payload.data.data).forEach((key) => {
        if (operatorProfileDetails.includes(key)) {
          localStorage.setItem(key, payload.data.data[key]);
        }
      });

      localStorage.setItem(
        "staff_id",
        payload.data.data.staff_id ? payload.data.data.staff_id : 0
      );
      localStorage.setItem(
        "role",
        payload.data.data.role ? payload.data.data.role : 0
      );
      localStorage.setItem(
        "paymentMethod",
        payload.data.data.is_stripe_active &&
          payload.data.data.is_stripe_active > 0
          ? "Stripe"
          : payload.data.data.is_swan_active &&
            payload.data.data.is_swan_active > 0
            ? "Swan"
            : ""
      );
      if (payload.data.data.membership_package_id) {
        localStorage.setItem(
          "membership_package_id",
          payload.data.data.membership_package_id
            ? payload.data.data.membership_package_id
            : 0
        );
      }
      if (payload.data.data.phonenumber) {
        localStorage.setItem(
          "phonenumber",
          payload.data.data.phonenumber &&
            payload.data.data.phonenumber != null &&
            payload.data.data.phonenumber != undefined
            ? payload.data.data.phonenumber
            : ""
        );
      }

      localStorage.setItem(
        "workspace_score",
        payload.data.data.workspace_score
      );

      if (userData.address) {
        localStorage.setItem("company_address", userData.address);
      }

      return {
        ...state,
        isLoggedIn: true,
        user: payload.data.data,
      };
      case GET_USER_ID_GLOBAL:
        return {
          ...state,
          userDataGlobal : payload,
        };
    default:
      return state;
  }
}
