import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { _l, getFileType, getImagePreviewIcon, getPreviewFromURL, showError, showSuccess } from "../hooks/utilities";
import CustomerServices from "../services/customer-services";
import CommanLoader from "./Loader/CommanLoader";
import Select from "react-select";
import { CalendarBlank, CloudArrowUp, Paperclip, X } from "phosphor-react";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import customerServices from "../services/customer-services";
import { updateDefaultTopics } from "../actions/customer";
import { showConfirmation, toastCloseAction } from "../actions/messages";
import DocumentPreview from "./Documents/DocumentPreview";

let fileUplods = [];

const AITopicContext = forwardRef(
  (
    {
      category,
      projectId,
      setCategory,
      setLoadingNextStep,
      is_general_context,
      ai_assistant_id,
      main_key,
    },
    ref
  ) => {
  const dispatch = useDispatch();

  const defaultTopics = useSelector((state) => state.customer.defaultTopics);
  
  const urlParams = new URLSearchParams(window.location.search);
  const assistantId = urlParams.get('user') ? urlParams.get('user') : 0;
    const [providerContextDescription, setProviderContextDescription] =
      useState("");
    const [generalContextDescription, setGeneralContextDescription] =
      useState("");
    const [attachmentContextDescription, setAttachmentContextDescription] =
      useState("");
    const [loadingContent, setLoadingContent] = useState(false);
    const [isGeneralContextActive, setIsGeneralContextActive] = useState(false);
    const [taskMergeFields, setTaskMergeFields] = useState([]);
    const [generalMergeFields, setGeneralMergeFields] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [categoryListing, setCategoriesList] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [loader, setloader] = useState(false);
    const [openGallery, setOpenGallery] = useState(false);
    const [lightboxInputArray, setLightboxInputArray] = useState([]);

    const generalContextRef = useRef();
    const proviederContextRef = useRef();
    const attachmentContextRef = useRef();

    const updateContextValue = async () => {
      const generalContextValue =
        generalContextRef && generalContextRef.current
          ? generalContextRef.current.value
          : "";
      const providerContextValue =
        proviederContextRef && proviederContextRef.current
          ? proviederContextRef.current.value
          : "";
      const attachmentContextValue = attachmentContextRef.current
        ? attachmentContextRef.current.value
        : "";

      setLoadingNextStep(true);

      try {
        const response = await CustomerServices.setSpaceContextData(
          projectId,
          category.id > 0 ? category.id : 0,
          generalContextValue,
          providerContextValue,
          is_general_context,
          ai_assistant_id,
          attachmentContextValue
        );
        if (response.status == 0) {
          showError(response.message);
        } else if (response.status > 0) {
          setCategory({ ...category, ai_context_disabled: false });
          if (generalContextValue.length || providerContextValue.length) {
            setCategory({
              ...category,
              ai_context_active: 1,
              ai_context_disabled: false,
            });
            setIsGeneralContextActive(true);
          }
          if (response.message) {
            showSuccess(response.message);
          }
        }
      } catch (e) {
        console.error(e);
        showError("l_something_went_wrong");
      } finally {
        setLoadingNextStep(false);
      }
    };

    const toggleContextData = async (e) => {
      try {
        const status = e.target.checked ? 1 : 0;
        const response = await CustomerServices.toggleProviderOpenAIStatus(
          0,
          0,
          status,
          1,
          ai_assistant_id
        );
        if (response.status > 0) {
          setIsGeneralContextActive(status > 0);
        } else {
          showError(response.message);
        }
      } catch (e) {
        console.error(e);
        showError("l_something_went_wrong");
      }
    };

    const fetchMergeFields = async (e) => {
      try {
        const response = await CustomerServices.getAIMergeFields();
        if (response.status) {
          if (response.data.general_mergeFields) {
            setGeneralMergeFields(response.data.general_mergeFields);
          }

          if (response.data.task_mergeFields) {
            setTaskMergeFields(response.data.task_mergeFields);
          }
        }
      } catch (e) {
        showError("l_something_went_wrong");
        console.error(e);
      }
    };

    const selectMergeField = (textAreaRef, valueToAppend) => {
      const textArea = textAreaRef.current;

      const selectionStart = textArea.selectionStart;
      const selectionEnd = textArea.selectionEnd;

      const currentText = textArea.value;

      const newText =
        currentText.substring(0, selectionStart) +
        valueToAppend +
        currentText.substring(selectionEnd);

      textArea.value = newText;

      const newCursorPosition = selectionStart + valueToAppend.length;
      textArea.setSelectionRange(newCursorPosition, newCursorPosition);

      textArea.focus();
    };

    const toggleAIContextForProvider = async (e) => {
      try {
        const status = e.target.checked ? 1 : 0;
        const response = await CustomerServices.toggleProviderOpenAIStatus(
          category.id,
          projectId,
          status
        );
        if (response.status) {
          setCategory({ ...category, ai_context_active: status });
        }
      } catch (e) {
        console.error(e);
      }
    };

    const options = [
      { value: "for me", label: "For me" },
      { value: "services", label: "Services" },
      { value: "people", label: "People" },
      { value: "equipments", label: "Equipments" },
    ];
    const options2 = [
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
      { value: "monthly", label: "Monthly" },
      { value: "yearly", label: "Yearly" },
    ];

    const preparePreview = (files) => {
      let previrewArray = [];
      Array.from(files).map((img) => {
        let preview_url = URL.createObjectURL(img);
        previrewArray.push({ name: img.name, url: preview_url });
      });
      fileUplods = files;
      setImagePreviews(previrewArray);
    };
    const removeImageSelection = (name) => {
      imagePreviews.forEach((thumbnail) => {
        URL.revokeObjectURL(thumbnail.url);
      });
      const updatedFileList = Array.from(fileUplods).filter(
        (img) => img.name != name
      );
  
      // setImageBuffer(updatedFileList);
      preparePreview(updatedFileList);
    };

    const handleUploadFile = (event) =>{
      const selectedFiles = Array.from(event.target.files);
      const filesLength = selectedFiles.length && selectedFiles.length + attachments.length;
      if (filesLength > 5) {
        showError("l_you_can_only_upload_5_files_max_per_AI_user");
        return;
      } else {
        try {
          if (selectedFiles && selectedFiles.length) {
            setloader(true)
            customerServices.uploadAiAssistantFiles(assistantId, selectedFiles).then((res)=>{
              if (res && res.status) {
                fecthFile();
                setImagePreviews([]);
                fileUplods = []
                // showSuccess("l_file_upload");
                setloader(false)
              }
            })
          }
        } catch (error) {
          console.log(error)
        }
      }
    }

    useImperativeHandle(ref, () => ({
      updateContextValue: updateContextValue,
    }));

    const fecthFile = () => {
      try {
        customerServices.getuploadAiAssistantFiles(assistantId).then((res)=>{
          if (res && res.status) {
            setAttachments(res.data)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    const handleChangeCategoryStatus = (category) => {
      try {
        customerServices.setCategoryStatusForAi(projectId , category.filter_key, Number(category.is_ai_access_data) == 0 ? 1 : 0).then((res) => {
          if (res && res.status) {
            let index = categoryListing.findIndex((c) => c.filter_key == category.filter_key)

            let categoryListingNew = [...categoryListing];
            if (index > -1){
              categoryListingNew[index].is_ai_access_data = Number(categoryListing[index].is_ai_access_data) == 0 ? 1 : 0;
              // dispatch(updateDefaultTopics(category.filter_key,"providers", category.main_sub_key))
            }
            setCategoriesList(categoryListingNew)
          }
        })
        
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {
      const getContextData = async () => {
        try {
          setLoadingContent(true);
          const response = await CustomerServices.getSpaceContextData(
            projectId,
            category.id,
            is_general_context,
            ai_assistant_id
          );
          if (response.status) {
            const providerContext = response.data.find(
              (w) => w.is_general == 0
            );
            const generalContext = response.data.find((w) => w.is_general > 0);
            if (providerContext) {
              setProviderContextDescription(providerContext.content);
            } else {
              setProviderContextDescription("");
            }
            if (generalContext) {
              setGeneralContextDescription(generalContext.content);
            } else {
              setGeneralContextDescription("");
            }
            if (response.extra) {
              setCategory({
                ...category,
                ai_context_active: Number(response.extra.ai_context_active),
                ai_context_disabled: !providerContext && !generalContext,
              });
              setIsGeneralContextActive(response.extra.ai_context_active > 0);
              if(response.extra.attachment_result)
              {
                setAttachmentContextDescription(response.extra.attachment_result.content);
              }
            }
          }
        } catch (e) {
          showError("l_something_went_wrong");
          console.error(e);
        } finally {
          setLoadingContent(false);
        }
      };
      getContextData();
      fetchMergeFields();
    }, [category.id, projectId]);

    useEffect(() => {
      if (defaultTopics && defaultTopics.length) {
        let category = defaultTopics.filter((t)=> t.main_key === "providers")
        if (category && category.length) {
          setCategoriesList(category[0].categories.filter((t)=> t.filter_key !== "myrai_support"))
        }
      }
      
    }, [defaultTopics])    
    
    useEffect(() => {
      fecthFile();      
    }, [])
    
    useEffect(() => {

    }, [categoryListing])
    
    const handleFileChange = (event) => {
      const selectedFiles = Array.from(event.target.files);
      const filesLength = selectedFiles.length && selectedFiles.length + attachments.length;
      if (filesLength > 5) {
        showError("l_you_can_only_upload_5_files_max_per_AI_user");
        return;
      }
      preparePreview(event.target.files);
    }

    const deleteFile = (file) => {

      const deleteAssitantFile = async () => {
        try {
          customerServices.deleteAssistantFile(assistantId, file.file_id).then((res)=>{
            if (res && res.status) {
              const updatedFileList = attachments.filter((attachment) => attachment.file_id != file.file_id);
              setAttachments(updatedFileList)
              dispatch(toastCloseAction());
            }
          })
        } catch (error) {
          console.log(error)
        }
      }

      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          _l("l_are_you_sure_you_want_to_delete_this_document"),
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => { deleteAssitantFile() }
        )
      );
    }
    
    return (
      <React.Fragment>
      <div className="form-wrapper-main  pb-2">
        {loadingContent ? (
          <CommanLoader />
        ) : (
          <>
            <div className="row pe10per">
              {is_general_context > 0 ? (
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <div className="d-flex align-items-center pb-3">
                    <Form.Label className="input-label no-asterisk mb-0">
                      {_l("l_general_context")}
                    </Form.Label>
                    <div className="form-check form-check-inline m-0 ps-2">
                      <Form.Check
                        className=" with-no-label-space"
                        type="switch"
                        role="switch"
                        checked={isGeneralContextActive}
                        onChange={toggleContextData}
                        disabled={
                          !generalContextRef.current ||
                          !generalContextRef.current.value ||
                          !generalContextRef.current.value.length
                        }
                      />
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    name=""
                    defaultValue={generalContextDescription}
                    ref={generalContextRef}
                  ></Form.Control>
                </Form.Group>
              ) : (
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <Form.Label className="input-label no-asterisk mb-0">
                      {_l("l_provider_context")}
                    </Form.Label>
                    <div className="d-flex align-items-center ">
                      <label class="input-label no-asterisk no-asterisk form-label pe-0 mb-0">
                        {_l("l_off_ai_instruction")}
                      </label>
                      <div className="form-check form-check-inline m-0 pe-2">
                        <Form.Check
                          className=" with-no-label-space"
                          type="switch"
                          role="switch"
                          checked={category.ai_context_active > 0}
                          onChange={toggleAIContextForProvider}
                          disabled={category.ai_context_disabled}
                        />
                      </div>

                      <label class="input-label no-asterisk no-asterisk form-label mb-0">
                        {_l("l_on_ai_instruction")}
                      </label>
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    name=""
                    defaultValue={providerContextDescription}
                    ref={proviederContextRef}
                  ></Form.Control>
                </Form.Group>
              )}
              {category && category.id > -1 ? <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <div className="d-flex align-items-center pb-3">
                  <Form.Label className="input-label no-asterisk mb-0">
                    {_l("l_attachment_context")}
                  </Form.Label>
                </div>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder={`${_l("l_type_description_here")}`}
                  type="text"
                  name=""
                  defaultValue={attachmentContextDescription}
                  ref={attachmentContextRef}
                ></Form.Control>
              </Form.Group> : <></> }

              <div className="border-bottom pt-2"></div>
              {/* upload file start */}

                <div className="d-flex flex-column pb-3">
                  <Form.Group className="flex-grow-1 c-input-box  position-relative pe-0 mb-10px mt-10">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_upload_file")}
                    </Form.Label>
                    <div className="upload-image-preview cursor-pointer col-auto  mb-10px d-flex "  >
                      {
                       attachments && attachments.length > 0 ? 
                       <>
                       {
                        attachments.map((img)=>{
                          let preview = {};
                          preview.url = getPreviewFromURL(img.file_name);
                          return(<>
                                 <div
                        className="comman-image-box h50w50 radius_3 upload-image position-relative  me-3 mt-10px "
                        // data-tip={imagename}
                        data-effect="solid"
                        data-delay-show="1000"
                        data-class="tooltip-main"
                      >
                        {typeof preview.url === "string" ? (
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                          
                          style={{
                            backgroundImage: `url(${""})`,
                          }}
                        ></div>
                      ) : (
                        <div
                          className="h-100 w-100 d-flex cursor-pointer"

                          style={{
                            alignContent: "center",
                            flexWrap: "wrap",
                          }}
                          onClick={() => {
                            setOpenGallery(true);
                            setLightboxInputArray([
                               {
                                  src: img.file_url,
                                  type: getFileType(img.file_name),
                                }]);
                          }}
                        >
                          <preview.url
                            size={40}
                            weight="light"
                            className="c-icons"
                          />
                          <span className="text-truncate c-font f-11"> {img.file_name}</span>
                        </div>
                       )}
                       <a href="#/" className="upload-close"
                            onClick={() => {
                              deleteFile(img)
                            }}
                          >
                            <X size={14} weight="light" className="c-icons" />
                          </a>
                      </div>
                          </>)
                        })
                       }
                       </> : <>
                       {/* <div className="">

                       <CommanPlaceholder mainSpacing="pt-0" imgType="no-images" placeholderText = {_l("l_no_files")} />
                       </div> */}
                       </>
                      }
               
                      <ReactTooltip />
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="comment-input-box flex-grow-1 me-2">
                        <div
                          className="input_box_main with_file_upload"
                          data-tip={`${_l("l_upload_file")}`}
                          data-effect="solid"
                          data-delay-show='1000'
                          data-class="tooltip-main"
                        >
                          <label className="upload_inputbox w-100 upload_relative form-control height-45 border-0 p-0 d-flex align-items-center ">
                            <Form.Control
                              className="hidden-input"
                              type="file"
                              onChange={(event) => { handleUploadFile(event) }}
                              accept=".pdf ,.txt ,.json"
                            />
                            <div className="custom_file">
                              <Paperclip size={18} className="upload_icon c-icons" weight="light" />
                              <div className="btn btn-sm btn-white-10 border-0">
                                <span className="c-font f-10 color-white-60">
                                  {" "}
                                  { loader ? _l('l_please_wait') : _l('l_select_file') }
                                  { loader ?  <Spinner as="span" animation="border" size="sm" role="status" className={`ms-2`} aria-hidden="true"/> : <></> }
                                </span>
                              </div>

                            </div>
                          </label>
                        </div>
                      </div>
                      {/* <div className="text-nowrap h_45">
                        <Button
                          variant=""
                          className="h-100 btn-white-05"
                          size="sm"
                          onClick={()=>[
                            handleUploadFile()
                          ]}
                          disabled={loader}
                        >
                          { loader ? _l('l_please_wait') : _l('l_upload_file') }
                          { loader ?  <Spinner as="span" animation="border" size="sm" role="status" className={`ms-2`} aria-hidden="true"/> : <></> }
                        </Button>
                      </div> */}
                    </div>
                  </Form.Group>
                  <div className="upload-image-preview cursor-pointer col-auto d-flex" >
                    {imagePreviews && imagePreviews.length > 0 && imagePreviews.map((img) => {
                      let preview = {};
                      preview.url = getPreviewFromURL(img.name);
                      return (<>
                        <div
                          className="comman-image-box h50w50 radius_3 upload-image with-border active position-relative me-2"
                          // data-tip={imagename}
                          data-effect="solid"
                          data-delay-show="1000"
                          data-class="tooltip-main"
                        >
                          {typeof img.url === "string" ? (
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"

                              style={{
                                backgroundImage: `url(${img.url})`,
                              }}
                            ></div>
                          ) : (
                            <div
                              className="h-100 w-100 d-flex cursor-pointer"

                              style={{
                                alignContent: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <preview.url
                                size={72}
                                weight="light"
                                className="c-icons"
                              />
                            </div>
                          )}
                          <a href="#/" className="upload-close"
                            onClick={() => {
                              removeImageSelection(img.name)
                            }}
                          >
                            <X size={14} weight="light" className="c-icons" />
                          </a>
                        </div>
                      </>)
                    })}
                    <ReactTooltip />
                  </div>
                </div>
              {/* upload file end */}

                <div className="border-bottom pt-2"></div>

                {/* topic JSON section start */}
                <div className="d-flex flex-column pb-3">
                  <Form.Group className="flex-grow-1 c-input-box  position-relative pe-0 mb-10px mt">
                    <Form.Label className="input-label no-asterisk ">
                      topic jSON
                    </Form.Label>
                    <div className="comman_vertical_btn_h scroll_110px">
                      <div className="comman-content-scroll-wrapper">
                        <ul className="comman-verticle-tab m-0 row">
                          {categoryListing && categoryListing.length > 0 && categoryListing.map((category) => {
                            return (<>
                              <li className="comman-list with-after-50 p-0 col-xl-6">
                                <a href="#/" className="w-100 position-relative tab_link  left-chat ">
                                  <div className="d-flex align-items-center">
                                    <div className="c-list-icon">
                                      <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                                        <div
                                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                          // style={{ backgroundImage: `url('https://images.unsplash.com/photo-1711881027254-e9ff3234d9d8?q=80&w=1947&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}
                                          style={{ backgroundImage: `url('${category.image_url.image_url}')` }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="c-list-detail ps-2 w100minus35">
                                      <div className="top-part pb-1">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="chat-u-name text-truncate">{category.title}</div>
                                          <div className="d-flex align-items-cente">
                                            <div
                                              className="badge badge-white rounded-pill badge  badge-white rounded-pill fw-light me-1"
                                              data-tip={''}
                                              data-effect="solid"
                                              data-delay-show='1000'
                                              data-class="tooltip-main"
                                            >
                                              {/* <span className="color-white">AM</span> */}
                                            </div>
                                            <div className="topic-switch d-flex align-items-center">
                                              <div className="form-check form-check-inline m-0 ps-2">
                                                <Form.Check
                                                  className="with-no-label-space"
                                                  type="switch"
                                                  role="switch"
                                                  checked={Number(category.is_ai_access_data) == 1 ? true : false}
                                                  onChange={(e) => {
                                                    handleChangeCategoryStatus(category)
                                                  }}
                                                />
                                                <label
                                                  className="form-check-label p-0"
                                                ></label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <ReactTooltip />
                              </li>
                            </>)
                          })}

                        </ul>
                      </div>
                    </div>


                  </Form.Group>

                </div>
                {/* topic JSON section end */}
            </div>


            {/* <div className="pe10per d-none">
              <div className="border-top mb-3 mt-2"></div>
              <div className="formCustomContext">
                <div className="c-font f-16 fw-semibold title-fonts mb-3">
                  Custom Context
                </div>
                <div className="d-flex bg-white-03 p-20">
                  <div className="row w-100">
                    <Form.Group
                      className={`col-12 c-input-box position-relative pb-4`}
                    >
                      <Form.Label className="input-label no-asterisk no-asterisk">
                        {_l("Content ")}
                      </Form.Label>
                      <div className="c-font f-14 mailto:title-fonts">
                        <Form.Control
                          placeholder="enter description"
                          as="textarea"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-6 c-input-box position-relative pb-4 z-index-3">
                      <Form.Label className="input-label no-asterisk  form-label">
                        {_l("Select Assignee ")}
                      </Form.Label>
                      <div className="d-flex align-items-center justify-content-between w-100 ">
                        <Select
                          className="custom-select-menu w-100 "
                          classNamePrefix="react-select"
                          placeholder="Select assignee"
                          options={options}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-6 c-input-box position-relative pb-4">
                      <Form.Label className="input-label no-asterisk  form-label">
                        {_l("Status ")}
                      </Form.Label>

                      <div className="d-flex align-items-center form-control ">
                        <Form.Label className="form-check-label text_wrap  mb-0 ps-0">
                          <Form.Check
                            inline
                            name="1"
                            label={_l("Enable")}
                            type="radio"
                          />
                        </Form.Label>
                        <Form.Label className="form-check-label text_wrap mb-0">
                          <Form.Check
                            inline
                            name="1"
                            label={_l("Disable")}
                            type="radio"
                          />
                        </Form.Label>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-lg-6 c-input-box position-relative pb-4">
                      <Form.Label className="input-label no-asterisk  form-label">
                        {_l("Select Period ")}
                      </Form.Label>
                      <div className="d-flex align-items-center justify-content-between w-100 ">
                        <Select
                          className="custom-select-menu w-100 "
                          classNamePrefix="react-select"
                          placeholder="Select period"
                          options={options2}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="col-xl-6  c-input-box mb-32px position-relative">
                      <div className="custom_input_box with_before">
                        <Form.Label className="input-label   form-label">
                          {_l("Select time")}
                        </Form.Label>
                        <div className="custom-datepicker w-100">
                          <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px">
                            <div className="action_icon h32w32 with_bg">
                              <CalendarBlank
                                size={18}
                                weight="light"
                                className="c-icons opacity-60"
                              />
                            </div>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              portalId="custom_datepicker"
                              placeholderText={`${_l(
                                "l_select_date_placeholder"
                              )}`}
                              calendarClassName="custom-datepicker pt-0"
                              calendarStartDay={1}
                            ></DatePicker>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <div class="col-xl-12 c-input-box pb-4 position-relative">
                      <div class="d-flex align-items-center">
                        <div class="pe-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={true}
                          />
                        </div>
                        {_l("l_i_accept_terms_and_conditions")}
                        <a
                          href="https://myr.ai/cgsc.html"
                          class="color-green ms-1"
                          target="_blank"
                        >
                          CGSC
                        </a>
                        <a
                          href="https://myr.ai/cgsp.html"
                          class="color-green ms-1"
                          target="_blank"
                        >
                          CGSP
                        </a>
                        <span class="m-1">&amp;</span>
                        <a
                          href="https://myr.ai/privacy-policy.html"
                          class="color-green ms-1"
                          target="_blank"
                        >
                          RGPD
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Button variant={"primary"} className="" size="sm">
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            
          </>
        )}
      </div>
      {openGallery && lightboxInputArray.length ? (
        <DocumentPreview 
          open={openGallery}
          setOpen={setOpenGallery}
          slides={lightboxInputArray}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
    );
  }
);

export default AITopicContext;
