import React from 'react';
// import CSS from '../css/icon_main.css';

function TagChevron(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>



<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256" className={`comman-icons ${CommanHW}`}><rect width="256" height="256" fill="none"/><path d="M32,200H187.72a8,8,0,0,0,6.65-3.56L240,128,194.37,59.56A8,8,0,0,0,187.72,56H32l48,72Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/></svg>


		</React.Fragment>
	)
}

export default TagChevron