import { CaretLeft } from "phosphor-react";
import React from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { _l } from "../../hooks/utilities";

const StepperHeader = ({
  textContent,
  backButtonHandler,
  nextButtonHandler,
}) => {
  // headerTitle, stepTitle, stepDescription, backButtonText, nextButtonText
  return (
    <>
      <div className=" d-flex justify-content-between comman_action_icon registration-header">
        {
          textContent.backButtonText
          ?
          <div>
            <a className="action_icon d-flex h45w45 with_bg light-theme-white-bg" onClick={backButtonHandler}>
              <CaretLeft size={18} weight="light" className="c-icons" />
            </a>
          </div>
          :
          <React.Fragment></React.Fragment>
        }
        <div className="center-part flex-grow-1">
          <div className="align-items-center d-flex flex-column justify-content-center">
            <div className="c-font f-14 text-center title-fonts">
              <span>{`${_l("l_step")} ${1}`} : </span>
              <span> {textContent.headerTitle} </span>
            </div>
            <div className="d-flex progress-slider pt-20px">
              <div className="progress w_400px">
                <div className="progress-bar" style={{ width: `${textContent.stepperProgress}%` }}></div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="stand-alone-top p-0">
            <Form.Group
              className={` c-input-box position-relative with_separator_10 before-dnone
            }`}
            >
              <Select
                className="custom-select-menu with-filter-select me-0 border-0 min-w-auto bg-transparent"
                closeMenuOnSelect={true}
                // placeholder={`${_l(language ? language : "l_FR")}`}
                classNamePrefix="react-select"
                // options={languageSelects}
                // value={languageSelects.filter(dd=> dd.value == language)}
                // onChange={(value)=>{setLanguage(value.value)
                // setlanguage(value.value)}}
                // menuIsOpen={true}
              />
            </Form.Group>
          </div>
          <Button variant="primary" className="h45w45 w-auto px-4 ms-20px" onClick={nextButtonHandler}>
            {_l("l_next")}
          </Button>
        </div>
      </div>
      {
        textContent.stepTitle
        ?
        <div className="row mt-65px">
          <div className="col-lg-2"></div>
          <div className="col-lg-7 m-auto">
            <div className="onboarding-header p-20px position-relative text-center">
              <div className="d-flex align-items-center justify-content-center">
                <div class=" px-3">
                  <div class="c-font f-20 fw-semibold title-fonts">
                    {textContent.stepTitle}
                  </div>
                  <div class="c-font f-16 pt-15px sub-header text-center">
                    <span>
                      {textContent.stepDescription}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
        :
        <></>
      }
    </>
  );
};

export default StepperHeader;
