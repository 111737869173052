import React, { useEffect, useState } from "react";
import jquery from "jquery";
import { _l, hour, minute, removeDuplicates, second, setCallTimer, updateelapsedTime, updatehours, updateminutes } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import { ATTACH_BASE_URL, USR_IMG_PLACEHOLDER } from "../../actions/chat-action-type";
import { getCommentsForAudiocall, setShowMicDisaply, updateedTaskChecklist } from "../../actions/customer";
import { CornersIn, CornersOut, MicrophoneSlash } from "phosphor-react";
import ReactPlayer from "react-player";

const FeedchatInTaskModal = ({ isMute, isCameraOff, isCameraFound, isScreenShareOn }) => {
    
    const dispatch = useDispatch();
    const { callStatus, myStream, remoteStream } = useSelector((state) => state.chat);
    const { callRecordingActive, displayMic } = useSelector((state) => state.customer);
    const [callName, setCallName] = useState("");
    const [callImage, setCallImage] = useState(USR_IMG_PLACEHOLDER);
    const [isCallMute, setIsCallMute] = useState(false);
    const [isCallCameraOff, setIsCallCameraOff] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [screenStream, setScreenStream] = useState([]);
    const [remoteScreenStream, setRemoteScreenStream] = useState([]);
    const [remoteCameraStream, setRemoteCameraStream] = useState([]);
    const [remoteAudioStream, setRemoteAudioStream] = useState([]);
    const [isCallScreenShareOn, setIsCallScreenShareOn] = useState(false);

    useEffect(() => {
        setCallTimer();
    }, []);

    var startTime = 0;
    var elapsedTime = 0;
    let timer;
    let seconds = second, minutes = minute, hours = hour;
    let isTimerRunning = false;
    
    // Call timer functions
    useEffect(() => {
        if(!jquery.isEmptyObject(callStatus)){
            if(callStatus.status == "notResponding"){
        
            }else{
                if(callStatus.callRole == "caller"){
                    if(callStatus.receiverInfo){
                        if(callStatus.receiverInfo.displayPicture != ""){
                            setCallImage(encodeURI(ATTACH_BASE_URL+callStatus.receiverInfo.displayPicture));
                        }else{
                            setCallImage(USR_IMG_PLACEHOLDER);
                        }
                        setCallName(callStatus.receiverInfo.fname+" "+callStatus.receiverInfo.lname);
                        setIsCallMute(callStatus.receiverInfo.isMute ? true : false);
                        dispatch(setShowMicDisaply(callStatus.receiverInfo.isMute ? true : false));
                        if(callStatus.extraData && callStatus.extraData.isReceiverCamFound == 0){
                            setIsCallCameraOff(true);
                        }else{
                            setIsCallCameraOff(callStatus.receiverInfo.isCameraOff ? true : false);
                        }
                        setIsCallScreenShareOn(callStatus.receiverInfo.isScreenShare ? true : false);
                        // setIsOtherRecording(callStatus.receiverInfo.isRecording ? true : false);
                    }
                }else{
                    if(callStatus.callerInfo){
                        if(callStatus.callerInfo && callStatus.callerInfo.displayPicture != ""){
                            setCallImage(encodeURI(ATTACH_BASE_URL+callStatus.callerInfo.displayPicture));
                        }else{
                            setCallImage(USR_IMG_PLACEHOLDER);
                        }
                        setCallName(callStatus.callerInfo.fname+" "+callStatus.callerInfo.lname);
                        setIsCallMute(callStatus.callerInfo.isMute ? true : false);
                        dispatch(setShowMicDisaply(callStatus.callerInfo.isMute ? true : false));
                        if(callStatus.extraData && callStatus.extraData.isCallerCamFound == 0){
                            setIsCallCameraOff(true);
                        }else{
                            setIsCallCameraOff(callStatus.callerInfo.isCameraOff ? true : false);
                        }
                        setIsCallScreenShareOn(callStatus.callerInfo.isScreenShare ? true : false);
                        // setIsOtherRecording(callStatus.callerInfo.isRecording ? true : false);
                    }
                }
            }
            // setCallName(callStatus.receiverInfo.fname+" "+callStatus.receiverInfo.lname);
            if (callStatus.checklistItems) {
                dispatch(updateedTaskChecklist(removeDuplicates(callStatus.checklistItems)))          
            }
            if (callStatus.commentsList) {
                dispatch(getCommentsForAudiocall(removeDuplicates(callStatus.commentsList)))          
            }
        }
    }, [JSON.stringify(callStatus)]);

    const setFullScreen = (val = false) => {
        setIsFullScreen(val);
        if(val){
            jquery("body").addClass("feed-chat-task-modal-full-screen");
        }else{
            jquery("body").removeClass("feed-chat-task-modal-full-screen");
        }
    };

    function isScreenSharingTrack(track) {
        // Check if track label contains any indication of screen sharing
        const label = track.label.toLowerCase();
        return label.includes('screen') || label.includes('desktop') || label.includes('share');
    }

    function extractStream(mixedStream, type = "") {
        // Loop through the video tracks in the mixed stream
        if(type == "screen" || type == "camera"){
            var count = 0;
            for (const track of mixedStream.getVideoTracks()) {
                count++;
                // Check if the track label indicates screen sharing
                // if (isScreenSharingTrack(track)) {
                if ((type == "screen" && count == 2) || (type == "camera" && count == 1)) { // Get second video stream for screen share
                    // Create a new MediaStream object containing only the screen sharing track
                    const newStream = new MediaStream([track]);
                    return newStream;
                }
            }
        }else if(type == "mic"){
            const audioTracks = mixedStream.getAudioTracks();
            if(audioTracks){
                const audioStream = new MediaStream(audioTracks);
                return audioStream;
            }
        }
        // If no screen sharing track is found, return null or handle accordingly
        return null;
    }

    useEffect(() => {
        if(isScreenShareOn){
            const screenSharingStream = extractStream(myStream, "screen");
            setScreenStream(screenSharingStream);
        }
    }, [myStream, isScreenShareOn]);

    function isStreamValid(stream) {
        return !!stream && stream instanceof MediaStream && stream.getTracks().length > 0;
    }

    useEffect(() => {
        if(isStreamValid(remoteStream)){
            if(isCallScreenShareOn){
                const audioTrack = remoteStream.getAudioTracks()[0];
                setRemoteAudioStream(audioTrack);
                const screenSharingStream = extractStream(remoteStream, "screen");
                setRemoteScreenStream(screenSharingStream);
            }else{
                // const cameraStream = extractStream(remoteStream, "camera");
                // setRemoteCameraStream(cameraStream);
            }
        }
    }, [remoteStream, isCallScreenShareOn]);

    return (
        <div className={`flex-grow-1  p-0 feed-chat-in-task-modal p-0 col-lg-5 ${!isCallCameraOff && !isCallScreenShareOn ? "feed-chat-in-task-modal" : ""}`}>
            <div className="d-flex flex-column w-100 h-100 d-flex flex-column ">

                { isCallCameraOff && !isCallScreenShareOn ?
                <div className="feed-chat-in-task-modal-camara-off">
                    <div className="p-10 bg-white-03 flex-grow-1 d-flex flex-column justify-content-center align-items-center">
                        <div className="p-15"></div>
                        <div className="FeedchatContain">
                            <div className="d-flex flex-column justify-content-center align-items-center m-auto">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="CallerImg position-relative d-none">
                                        <div className="comman-image-box multi-wrapper-border h40w40 rounded-circle">
                                            <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${callImage})` }}></div>
                                        </div>
                                        <span className="Commanwave wave1"></span>
                                        <span className="Commanwave wave2"></span>
                                        <span className="Commanwave wave3"></span>
                                    </div>
                                    <div className="CallerImg position-relative withMargin120px">
                                        {displayMic && <div className="muteoption comman_action_icon position-absolute end-0">
                                            <div className="action_icon h32w32 with_bg radius_3">
                                                <MicrophoneSlash size={18} weight="light" className="c-icons m-auto" />
                                            </div>
                                        </div>}
                                        <div className="comman-image-box multi-wrapper-border h120w120 rounded-circle">
                                            <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${callImage})` }}></div>
                                        </div>
                                        <span className="Commanwave wave1"></span>
                                        <span className="Commanwave wave2"></span>
                                        <span className="Commanwave wave3"></span>
                                    </div>
                                    <div className="CallerImg position-relative d-none">
                                        <div className="comman-image-box multi-wrapper-border h40w40 rounded-circle">
                                            <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${callImage})` }}></div>
                                        </div>
                                        <span className="Commanwave wave1"></span>
                                        <span className="Commanwave wave2"></span>
                                        <span className="Commanwave wave3"></span>
                                    </div>
                                </div>
                                <div className="p-15"></div>
                                <div className="pt-15">
                                    <div className="c-font f-24 title-fonts fw-semibold text-center">{callName}</div>
                                    <div class="align-items-center badge c-font color-green d-flex f-12 h32wauto list-toggle-view-none ms-10px mt-2 rounded-pill text-nowrap justify-content-center">
                                        {callRecordingActive ? 
                                            <>
                                                <span class="bg-green c-10 c-dots me-2 rounded-circle"></span>
                                                <span class="c-font color-green f-13 p-0 text-truncate title-fonts">
                                                    <span class="color-white fw-semibold" id="recording-minute">00:00</span>
                                                    <span class="color-white-60">{" "} /</span> 
                                                </span>
                                            </>
                                        : <></>
                                        }
                                        <span id="call-timer-full" class="color-white fw-semibold ps-1">00:00</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                : <></> }
                
                { !isCallCameraOff || isCallScreenShareOn ?
                <>
                    { isCallMute ?
                    <div className="videocallmic main">
                        <MicrophoneSlash size={20} weight="light" className="c-icons" />
                    </div>
                    : <></> }
                    { remoteStream ? 
                    <>
                        <div className="comman_action_icon end-0 position-absolute top-0 z-index-2">
                            <div className={`before-dnone pt-10px with_separator_10`}>
                                <a href="#/"
                                    className={`action_icon with_bg h32w32 d-flex bg-base-header-color`}
                                    data-tip={isFullScreen ? _l("l_reset_screen") : _l("l_full_screen")}
                                    data-effect="solid"
                                    data-delay-show='1000'
                                    data-class="tooltip-main"
                                    onClick={() => { setFullScreen(isFullScreen ? false : true); }}
                                >
                                    { isFullScreen ? <CornersOut size={18} className="c-icons zoom_out" weight="light"/>
                                    : <CornersIn size={18} className="c-icons zoom_in" weight="light" /> }
                                </a>
                            </div>
                        </div>
                        <ReactPlayer
                            playing
                            muted
                            url={isCallScreenShareOn ? remoteScreenStream : remoteStream}
                            controls={false}
                            width="100%"
                            height={isFullScreen ? "100%" : "50%"}
                            className="remote-video-player"
                        />
                    </>
                    : <></>}
                </>
                : <></> }

                { myStream ? 
                <div>
                    { isMute ?
                    <div className="videocallmic">
                        <MicrophoneSlash size={20} weight="light" className="c-icons" />
                    </div>
                    : <></> }
                    { isCameraOff || !isCameraFound ?
                    <div className="FeedchatContain videocall-placeholder bg-white-03">
                        <div className="d-flex flex-column justify-content-center align-items-center m-auto h-100">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="CallerImg position-relative withMargin120px">
                                    <div className="comman-image-box multi-wrapper-border h120w120 rounded-circle">
                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${localStorage.getItem("contact_image")})` }}></div>
                                    </div>
                                    <span className="Commanwave wave1"></span>
                                    <span className="Commanwave wave2"></span>
                                    <span className="Commanwave wave3"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <ReactPlayer
                        playing
                        muted
                        url={isScreenShareOn ? screenStream : myStream}
                        controls={false}
                        width="100%"
                        height="50%"
                        className="my-video-player"
                    />
                    }
                </div>
                : <></>}
            </div>
        </div>
    );
};

export default FeedchatInTaskModal;
