import React, { useEffect, useState } from "react";
import {
  _l,
} from "../../hooks/utilities";
import { CaretDoubleDown, DotsNine } from "phosphor-react";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import TaskCard from "../TaskCard";


const TEMP_TASK =
 [{
  "id": -1,
  "name": "",
  "startdate": new Date(),
  "duedate": null,
  "dateadded": new Date(),
  "addedfrom": "0",
  "surface": "0",
  "duration": "0",
  "is_added_from_contact": "1",
  "rel_type": "project",
  "rel_id": "",
  "schedule_id": "",
  "checklist_title_id": "",
  "is_requested": "0",
  "project_status_flag": "0",
  "converted_date": null,
  "provider_id": "17",
  "visible_to_client": 1,
  "visible_to_provider": 0,
  "myr_total":"",
  "floor_id": "0",
  "rating": ".",
  "hash": "",
  "email_sender": null,
  "space_project_id": "",
  "average_rating": "",
  "is_admin": "0",
  "is_one_time_benchmark": "0",
  "email": null,
  "addedfrom_staff_id": null,
  "status": "1",
  "priority": 4,
  "request_task_id": "0",
  "pinned": 0,
  "checklist_progress": 0,
  "attachments_count": 0,
  "comments_count": 0,
  "unread_delay_comment_count": "0",
  "unread_comment_count": 0,
  "unread_checklist_comment_count": 0,
  "request_time": {
     "day": 0,
     "hour": 0,
     "minute": 0
  },
  "is_allowed_to_edit": "1",
  "is_allowed_to_delete": "0",
  "creator_name": localStorage.getItem("full_name"),
  "creator_email": localStorage.getItem("email"),
  "creator_profile_image": localStorage.getItem("contact_image"),
  "attachments_audio_count": 0,
  "links_count": 0,
  "company_name": localStorage.getItem("company_name"),
  "company_profile_url": localStorage.getItem("company_logo"),
  "assignee_list": {},
  "assignee_list_DEV": [],
  "schedule_assignees": [ ],
  "assignee_list_v2": {},
  "total_time": "00:00"
}]

const TodoTable = (data) => {
  let pageName = "provider-list";
  let projectId = localStorage.getItem("selectedOffice")
  const { selectedTask, providerlistTableData } = useSelector((state) => state.customer);

  const [todoData, setTodoData] = useState(
    data.data && data.data.length > 0 ? data.data : []
  );
  const [todoPage, setTodoPage] = useState(1);
  const [loder, setLoder] = useState({
    todoLoder: false,
  });
  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [commonModalData, setCommonModalData] = useState();
  const [task_type, setTask_type] = useState("");
  const [checkedTask, setCheckedTask] = useState([]);
  const [taskPinned, setTaskPinned] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [taskId ,setTaskId] = useState();
  const [callUserList, setCallUserList] = useState([]);
  const [selectedTaskIdforIconToggle, setSelectedTaskIdforIconToggle] = useState(0);
  const [showDefaultIcons, setShowDefaultIcons] = useState([]); 

  const scroll = (status) => {
    let page;
    let taskStaus = status;
    if (status == 1) {
      page = todoPage + 1;
      setTodoPage(page);
    }
    taskStaus = [status];
    if (status == 1) {
      setLoder({ ...loder, ["todoLoder"]: false });
    }
  };
  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {
    setShowTaskModal(false);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {
      setShowTaskModal(show);
    }
  };

  const taskCheckUncheck = (task_id) => {
    const taskPosition = checkedTask.findIndex(x => x == task_id)
    if (taskPosition === -1) {
      // checkedTask.push(task_id);
      setCheckedTask([...checkedTask, task_id])
    }
    else {
      setCheckedTask(checkedTask.filter((taskId) => taskId != task_id))
    }
  }
  const handleToggleIcons = (isTaskPinned,taskAssignee,taskAssigneePills,taskPriority,comments_count,rating,setTaskCardIconToggle,taskCardIconToggle) =>{
    if(taskCardIconToggle){
      let tempDefaultIcons = []

      if (isTaskPinned) {
        tempDefaultIcons.push("pin")
      }
      if (taskAssignee.length > 0) {
        tempDefaultIcons.push("peopels")
      }
      if (taskAssigneePills.length > 0) {
        tempDefaultIcons.push("pills")
      }
      if (taskPriority == 3) {
        tempDefaultIcons.push("priority")
      }
      if(comments_count > 0){
        tempDefaultIcons.push("comment")
      }
      if(rating && rating > 0){
        tempDefaultIcons.push("star")
      }   
      if(pageName == "LiteVersion"){
        setShowDefaultIcons(tempDefaultIcons)
      }else{
        setShowDefaultIcons(["calendar","assigneTo","pin","peopels","pills","priority","cube","map","status","comment","star",,"delete","share","call", "template"])
      }
      setTaskCardIconToggle(false)
    }else{
      let tempDefaultIcons = ["calendar","assigneTo","pin","peopels","pills","priority","cube","map","status","comment","star",,"delete","share","call", "template"]
      if(pageName == "LiteVersion"){
        setShowDefaultIcons(tempDefaultIcons)
      }else{
        setShowDefaultIcons(["calendar","assigneTo","pin","peopels","pills","priority","cube","map","status","comment","star",,"delete","share","call", "template"])
      }
      setTaskCardIconToggle(true)
    }

  }

  useEffect(() => {
    if (selectedTask) {
      setTask_type(
        selectedTask.is_requested && selectedTask.is_requested == 1
          ? "request-task"
          : "task"
      );
      setCommonModalData(selectedTask);
      setSelectedTaskId(selectedTask.task_id || selectedTask.id);
      // setSelectedTaskId( selectedTask.id)
      setSelectedTaskId(0);
    }
  }, [JSON.stringify(selectedTask)]);
  
  
  useEffect(() => {
    setTodoData(TEMP_TASK.concat(providerlistTableData.task))  
  }, [JSON.stringify(providerlistTableData)])
  
  
  return (
    <>
      <table className="dataTable text-center tablewithborder">
        <thead className="comman-white-box-shadow">
          <tr>
            <th style={{ width: "3%" }} className="text-start">
              {_l("l_task_creator")}
            </th>
            <th style={{ width: "15%" }} className="text-start">
              {_l("l_title")}
            </th>
            <th style={{ width: "3%" }} className="text-start">
              {_l("l_date")}
            </th>
            <th style={{ width: "1%" }} className="text-start">
              {_l("l_space_assigned")}
            </th>
            <th style={{ width: "5%" }} className="text-start">
              {_l("l_task_assigned")}
            </th>
            <th style={{ width: "2%" }} className="text-start">
              {_l("l_status")}
            </th>
            <th style={{ width: "5%" }} className="text-center">
              {_l("l_action")}
            </th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: "middle" }}>
          {todoData && todoData.length > 0 ? (
            <>
              {todoData
                .sort((a, b) => {
                  if (a.name == "" && b.name == "") {
                    return -1
                  } else {
                    return b.priority - a.priority
                  }
              })
                .slice(
                  0,
                  todoPage > 1 ? (todoPage - 1) * 10 + 10 : todoPage * 10
                )
                .map((task) => {
                  return (
                    <>
                      {/* <TableRow task={task} handler={handleSelectedTask} /> */}
                      <TaskCard
                      key={task.id}
                      task={task}
                      active={selectedTaskId == task.id}
                      project_id={projectId}
                      handler={handleSelectedTask}
                      pageName='provider-list'
                      taskCheckUncheck={taskCheckUncheck}
                      checkedTask={checkedTask}
                      taskPinned={taskPinned}
                      setTaskPinned={setTaskPinned}
                      contacts={contacts}
                      setTaskId={setTaskId}
                      setCallUserList={setCallUserList}
                      setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                      selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                      showDefaultIcons={showDefaultIcons}
                      setShowDefaultIcons={setShowDefaultIcons}
                      handleToggleIcons={handleToggleIcons} 

                      />
                    </>
                  );
                })}
              <tr>
                {(todoData.length >= (todoPage - 1) * 10 + 10 &&
                  todoData.length != (todoPage - 1) * 10 + 10) ||
                loder.todoLoder ? (
                  <>
                    <td className="border-0" colSpan={12}>
                      <a
                        href="#/"
                        className="btn btn-white-03 w-100 view-more-btn"
                        onClick={() => {
                          setLoder({ ...loder, ["todoLoder"]: true });
                          scroll(1);
                        }}
                      >
                        <div className="d-flex align-item-center justify-content-center">
                          <span className=" title-fonts c-font f-12 ms-2">
                            {_l("l_view_more_btn")} (
                            {todoPage > 1
                              ? todoData.length - ((todoPage - 1) * 10 + 10)
                              : todoData.length - 10}
                            )
                          </span>
                          <CaretDoubleDown
                            size={12}
                            className="c-icons mx-2"
                            weight="light"
                          />
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 ${
                              loder.todoLoder ? "opacity-1" : "opacity-0"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                      </a>
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
            </>
          ) : (
            <>
              <tr className="">
                <td className="border-0" colSpan={12}>
                  <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                      <CommanPlaceholder
                        imgType="todo-task"
                        placeholderText={_l("l_no_todo_task")}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      {showTaskModal ? (
        <CommanOffCanvas
          show={showTaskModal}
          handleClose={() => {
            setShowTaskModal(false);
            setCommonModalData();
            setSelectedTaskId(0);
            setTask_type("");
          }}
          pinHandler={(id, type, flag) => {
            // handlePinUnPin(id, type, flag);
          }}
          data={commonModalData}
          docType={task_type}
          setSelectedId={setSelectedTaskId}
          selectedTaskId={selectedTaskId}
          IsKanbanView={false}
          meetingUrl={""}
          handleParentComponentAction={(type, taskData) => {}}
          pageName={"Liteversion"}
          contacts={[]}
          callUserList={[]}
          isFullScreen={true}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TodoTable;
