import React, { useEffect, useState } from "react";
import { showLeftPanelNew } from "../hooks/utilities";
import ProviderList from "../components/Providertables/ProviderList";
import { useSelector } from "react-redux";

const ProviderTable = () => {
  
  const {commanoffcanvasIsOpen} = useSelector((state) => state.customer)
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const [projectId, setProjectId] = useState(
    spaces && spaces.length ? spaces[0].project_id : 0
  );
  useEffect(() => {
    if (commanoffcanvasIsOpen) {
      
    } else {
      showLeftPanelNew(); 
    }
  })
  
  return (
    <>
        <div className="d-flex flex-column h-100">
          <ProviderList  />
        </div>
    </>
  );
};

export default ProviderTable;
