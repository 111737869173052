import React from 'react';
import { _l } from '../../hooks/utilities';
import LoaderVideo from "../../assets/images/myr_new_loader.gif";
import Button from "react-bootstrap/Button";

const ErrorFallback = () => {
    return (
        <div>
            <div className="bg_base_color full-screen main-loader-wrapper text-center">
            <div className="vw-100 vh-100 d-flex">
                <div className="loader-video">
                    <img src={LoaderVideo} alt={LoaderVideo} />
                    {/* <video  autostart="true" autoPlay="true" loop="true" src={LoaderVideo} type={LoaderVideo}/> */}
                <div className="c-font f-20 loader-text mt-4">{_l("l_something_went_wrong")}</div>
                <div className="color-white-60 fst-italic loader-subtitle mt-4">
                    <span className="d-block mb-2">{_l("l_could_not_connect_to_internet")}</span>
                    <span className="d-block mb-2">{_l("l_please_check_your_network_connection")}</span>
                </div>
                <Button variant="primary" className="mt-5" onClick={() => { window.location.reload() }} >{_l("l_reload")} </Button>
            </div>
          </div>
      </div>
            {/* <h5>Internal Server error !</h5>
            <p> Something went wrong
                <br />
                <a href="#/" onClick={() => { window.location.reload() }}>Reload App</a>
            </p> */}
        </div>
    )
}

export default ErrorFallback