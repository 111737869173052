import React from 'react'
import { _l } from '../../hooks/utilities';

const AllscreenToastLoader = () => {
  return (
    <div className='allScreenLoader text-center'>
        <div className="d-flex align-items-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div className=" title-fonts ps-20pximp">{_l("l_updating_schedule")} </div>
        </div>
    </div>
  )
}

export default AllscreenToastLoader