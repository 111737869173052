
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useRef, useState } from "react";
import LeadsProfile from "./Tabscomponent/LeadsProfile";
import LeadReminderModal from "../../Modals/LeadReminderModal";
import LeadEmailTracking from "./Tabscomponent/LeadEmailTracking";
import LeadAppoinment from "./Tabscomponent/LeadAppoinment";
import LeadNotesAndReminders from "./Tabscomponent/LeadNotesAndReminders";
import LeadView from "./Tabscomponent/LeadView";
import LeadAttachments from "./Tabscomponent/LeadAttachments";
import LeadCalls from "./Tabscomponent/LeadCalls";
import Leadcontact from "./Tabscomponent/Leadcontact";
import Form from "react-bootstrap/Form";
import ReactTooltip from "react-tooltip";
import { _l } from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import { EnvelopeSimple, Phone, Eye , Notebook, CalendarBlank, Clock, AddressBook } from "phosphor-react";
import { addAttachmentsToLead } from "../../../actions/leads-actions/leads-actions";
import ChecklistComponent from "../../TaskModalComponents/ChecklistComponent";
import FileUploadComponent from "../../TaskModalComponents/FileUploadComponent";
const LeadsTabs = ({ data, handleCommonButtonToggle , selectedEventDate}) => {
  const [selectedTab, setSelectedTab] = useState("profile");
  const [attachmentsArray, setAttachmentsArray] = useState([]);
  const [previewArray, setPreviewArray] = useState([]);
  const [leadAttachments, setLeadAttchments] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const {globalView} = useSelector((state) => state.customer)
  const uploadHandler = () => {
    dispatch(addAttachmentsToLead(data.lead_id, attachmentsArray));
    setPreviewArray([]);
    setAttachmentsArray([]);
  };

  useEffect(() => {
    if (globalView === "calendarView") {
      setSelectedTab("appoinments");
    }
  },[globalView])

  useEffect(() => {
    setLeadAttchments([]);
    if (data && data.lead_attachments) {
      setLeadAttchments(data.lead_attachments);
    }
  }, [JSON.stringify(data.lead_attachments)]);
  useEffect(() => {
    if (attachmentsArray && Array.from(attachmentsArray).length) {
      uploadHandler();
    }
  }, [attachmentsArray]);
  useEffect(() => {
    if (selectedTab == 'appoinments') {
      handleCommonButtonToggle('commonHeaderBodyData', _l("l_lead_reminder"))
    } else {
      handleCommonButtonToggle('commonHeaderBodyData', '')
    }
  }, [selectedTab])

  return (
    <>
      <div className="tab-wrapper_main res-overflow-auto pb-3">
        <div className="d-flex align-items-center justify-content-between border-bottom">
          <div className="comman-tab-wrapper pb-0 flex-grow-1 p-10 pt-0">
            <div className="d-flex align-items-center">
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "profile" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("profile");
                    data.tabChangeHandler(data.lead_id, "lead_profile");
                  }}
                >
                  {_l("l_profile")}
                </a>
              </div>
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${
                  selectedTab === "checklist" ? "active" : ""
                }`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("checklist");
                  }}
                >
                  {_l("l_checklist")}
                </a>
              </div>
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${
                  selectedTab === "leadcontact" ? "active" : ""
                }`}
              >
                <a href="#/"
                  className="tab-name"
                  data-tip={_l("l_lead_contact")}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                  onClick={() => {
                    setSelectedTab("leadcontact");
                    data.tabChangeHandler(data.lead_id, "lead_contact");
                  }}
                >
                 {_l("l_contact")}
                </a>
              </div>
              {/* REMINDER TAB commented temperory Don't Remove below commented code  */}
              {/* <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${
                  selectedTab === "notesandreminders" ? "active" : ""
                }`}
              >
                <a href="#/"
                  className="tab-name"
                  data-tip={_l("l_reminders")}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                  onClick={() => {
                    setSelectedTab("notesandreminders");
                    data.tabChangeHandler(data.lead_id, "lead_contact");
                    data.tabChangeHandler(data.lead_id, "notesandreminders");
                  }}
                >
                  <Notebook size={16} weight="light" className="c-icons" />
                </a>
              </div> */}
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "calls" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  data-tip={_l("l_calls")}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                  onClick={() => {
                    setSelectedTab("calls");
                    data.tabChangeHandler(data.lead_id, "lead_call_logs");
                  }}
                >
                   {_l("l_calls")}
                </a>
              </div>
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "emailtracking" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  data-tip={_l("l_email")}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                  onClick={() => {
                    setSelectedTab("emailtracking");
                    data.tabChangeHandler(data.lead_id, "lead_tracked_emails");
                  }}
                >
                  {_l("l_email")}
                </a>
              </div>
              <div
                className={`d-none tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "leadview" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  data-tip={_l("l_view")}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                  onClick={() => {
                    setSelectedTab("leadview");
                    data.tabChangeHandler(data.lead_id, "lead_views_tracking");
                  }}
                >
                  <Eye size={16} weight="light" className="c-icons" />
                </a>
              </div>
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "appoinments" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  data-tip={_l("l_appoinments")}
                  data-effect="solid"
                  data-delay-show='1000'
                  data-class="tooltip-main"
                  onClick={() => {
                    setSelectedTab("appoinments");

                  }}
                >
                 {_l("l_appoinments")}
                  {/* {_l("l_appoinments")} */}
                </a>
              </div>

            </div>
          </div>
          <div className="right-tabing-part ">
            {selectedTab === "appoinments" ? (
              // <Button variant="primary" size="sm" onClick={() => handleShow()}>
              //   {_l("l_lead_reminder")}{" "}
              // </Button>
              <></>
            ) : (
              <></>
            )}
          </div>

          <div className="task-header-right comman-tab-wrapper p-0">
            <div className="d-flex align-items-center tab-wrapper justify-content-end ">
              <Form.Check
                type="switch"
                id=""
                label=""
                className="with-no-label-space d-none"
              />
              {attachmentsArray && Array.from(attachmentsArray).length ? (
                <div className={`my-2 task-header-right d-none`}>
                  <div className="d-flex align-items-center justify-content-end">
                    <div
                      className=" position-relative btn-secondary btn btn-sm"
                      onClick={uploadHandler}
                    >
                      <span className="">{_l("l_upload")}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className=" h-100 overflow-hiiden-auto d-flex flex-column">
        {selectedTab === "profile" ? (
          <LeadsProfile data={data.lead_profile} />
        ) : selectedTab === "checklist" ? (
          <>
          <ChecklistComponent 
            data={data.lead_contact} 
            docType="lead" 
            task_id={data.lead_profile.task_id || 0}
            isChecked={true}
            setRequestChecklist={() => { }}
          />
          <FileUploadComponent
          projectId={0}
          task_id={data.lead_profile.task_id  || 0}
          attachmentCountUpdate={() => {
          }}
          toastClose={() => {}}
          closeAllTost={false}
          searchAttchment={''}
          isExternal={false}
          readOnly={false}
          taskDetails={data}
          type={'lead'}
        />
          </>
        ) : selectedTab === "leadcontact" ? (
          <Leadcontact data={data.lead_contact} />
        ) : selectedTab === "files" ? (
          <LeadAttachments
            data={leadAttachments}
            lead_id={data.lead_id}
            attachmentsArray={attachmentsArray}
            setAttachmentsArray={setAttachmentsArray}
            previewArray={previewArray}
            setPreviewArray={setPreviewArray}
            uploadHandler={uploadHandler}
            docType="lead"
          />
        ) : selectedTab === "notesandreminders" ? (
          <LeadNotesAndReminders lead_id={data.lead_id} />
        ) : selectedTab === "calls" ? (
          <LeadCalls data={data.lead_call_logs} />
        ) : selectedTab === "emailtracking" ? (
          <LeadEmailTracking data={data.lead_tracked_emails} />
        ) : selectedTab === "leadview" ? (
          <LeadView data={data.lead_view_tracking} />
        ) : selectedTab === "appoinments" ? (
          <LeadAppoinment data={data} selectedEventDate={selectedEventDate} />
        ) : (
          <></>
        )}
      </div>
      {selectedTab === "appoinments" ? (
        <LeadReminderModal
          show={show}
          reminderData={data}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      ) : (
        <></>
      )}
      <ReactTooltip />
    </>
  );
};
export default LeadsTabs;
