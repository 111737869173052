import React, { useEffect, useState } from "react";
import { HideDropdown, _l } from "../../hooks/utilities";
import { Buildings, Hash, Info, MagnifyingGlass, SignIn, UserCircleGear } from "phosphor-react";
import ReactTooltip from "react-tooltip";
import Spinner from "react-bootstrap/Spinner";
import PremiumEstimateIconCrown from "../../assets/icons/PremiumEstimateIconCrown";
import jquery from "jquery";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { DropdownMenu } from 'reactstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useDispatch } from "react-redux";
import { selectedTypeOfSapceId } from "../../actions/customer";
import moment from "moment";
import CommanLoader from "../Loader/CommanLoader";
import customerServices from "../../services/customer-services";

const DropdownWithAccordion = ({
  list,
  handler,
  loder,
  setLoder = () => {},
  ClassName, isTaskCard = 0, selectedTaskAssigneesId = [], taskAssignMainKey,unControlList=true,
  is_projectStatus = false,
  project_status =[],
  selectedspaceStatus = 0,
  setSelectedspaceStatus =() =>{},
  type = "",
  loginuser='',
  isLoginAsUserDataLoaded = true
}) => {
  const dispatch = useDispatch();
  const [dropdownData, setDropdownData] = useState(list);
  const [customerData, setCustomerData] = useState([]);
  const [providerData, setProviderData] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [searchAssignees, setSearchAssignees] = useState("");
  const [defaulDropdownData, setDefaultDropdownData] = useState(
    list ? list : []
  );
  const [employeeData, setEmployeeData] = useState([])
  const virtualSpace = localStorage.getItem('selectedVirtualSpace');
  const user_type = localStorage.getItem("user_type")
  const [projectStatus, setprojectStatus] = useState(project_status ? project_status : [])
  const [loginAsUserDetails, setLoginAsUserDetails] = useState('');

  const loginDserDetail = (staffId) => {
    try {
      customerServices.getLoginDserDetail(staffId).then((res) => {
        if (res && res.status) {
          setLoginAsUserDetails(res.data);
        }
      })
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (list && list.length) {
      setDropdownData(list);
      if (type != "equipment" && type != "roomSetup") { 
        setCustomerData(list.filter((user) => user.type == 'Customer'));
        setProviderData(list.filter((user) => user.type == 'Provider'));
      }
      setDefaultDropdownData(list);
      // setprojectStatus(list);
      setSearchAssignees("");
      setLoder(false);
    } else {
      setLoder(false);
      setSearchAssignees("");
      setDropdownData([]);
      setCustomerData([]);
      setProviderData([]);
    }

  }, [list]);
  
  useEffect(() => {
    let providerlist = []; 
    let companylist = [];
    if (providerData && providerData.length > 0) {
      providerData.map((p, key)=> {
        if (companylist.includes(p.client_id)) {
          let index = companylist.indexOf(p.client_id);
          providerlist[index].members.push(p);
        } else {
          companylist.push(p.client_id);
          providerlist.push({client_id : p.client_id,company : p.company ,members:[p],type:p.type});
        }
      })
    }
    setProviderList(providerlist)
  },[providerData])

  useEffect(() => {
    taskAssignActive()
  }, [JSON.stringify(selectedTaskAssigneesId)])

  const taskAssignActive = () => {
    setTimeout(() => {
      if (selectedTaskAssigneesId) {
        selectedTaskAssigneesId.forEach(id => {
          jquery(`.custom-class-add[data-id=${taskAssignMainKey}_${id}]`).addClass(
            "active with-tick"
          );
        });
      }
    }, 100);
  }
  // if (isTaskCard) {
  taskAssignActive()
  // }
  const [dropdownOpen,setdropdownOpen] =useState(false);
  const onMouseEnter =(e,data) => {
    setdropdownOpen(true);
    setEmployeeData(dropdownData.filter((item)=>item.boss_operator_id == data.staff_id))
  }

  const onMouseLeave=() => {
    setdropdownOpen(false);
  }

  const DropDownAccordianContent = (
    <>{loginuser !== _l("l_login_as_user") ? <> 
    {loder && !isLoginAsUserDataLoaded ? _l("l_please_wait") : ""}
      {loder ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          className={`ms-2 `}
          aria-hidden="true"
        />
      ) : (
        <></>
      )}</>: <></>}
     {!is_projectStatus ? <div className="comman-search-dropdown" onClick={(e) => e.stopPropagation()}>
        <div className="d-flex align-items-center w-100">
          <div className="comment-input-box flex-grow-1">
            <input
              type="text"
              className="form-control bg-transparent with-base-bg border-0 flex-grow-1 p-2 f-11 c-font"
              placeholder="Search"
              value={searchAssignees}
              onChange={(e) =>{ setSearchAssignees(e.target.value) ; 
                // showLeftPanel(true)
              }}
              // onClick={(e) =>{ showLeftPanel(true)}}
            />
          </div>
          <a href="#/" className="comman_action_icon">
            <div className="d-flex align-items-center ">
              <div className="action_icon h25w25">
                <MagnifyingGlass size={14} className="c-icons" weight="light" />
              </div>
            </div>
          </a>
        </div>
      </div> : <></>}
      {
        (type == "equipment" || type == "roomSetup") && list.length > 0 ?
        <div className="dropdown-inner-scroll-50px withheight60vh">
        { list.map((actionItem , index)=>{
             return(
               <>
                <li className={`border-bottom border-top dfsssss `} key={index} 
                 onClick={()=>{
                  HideDropdown()
                  if (type == "roomSetup") {
                    dispatch(selectedTypeOfSapceId(actionItem));
                  }else{ 
                    handler(actionItem)
                  }
                     }}
                    >
                   <div
                     className={`dropdown-item custom-class-add py-2 cursor-unset no-hover` }
                     data-id={ actionItem.id}
                     data-tip={actionItem.quantity_name || actionItem.title}
                     data-effect="solid"
                     data-delay-show='1000'
                     data-class="tooltip-main"
                   >
                     <a href="#/" className="d-flex align-items-center text-truncate">
                       <div className="ps-2 w100minus30 text_wrap">
                         <div className={`text-truncate`}>
                           {actionItem.quantity_name || actionItem.title}
                         </div>
                       </div>
                     </a>
                   </div>
                   <ReactTooltip/>
                 </li>
               </>
             )
           })
         }
         </div>
         :
         <div className="dropdown-inner-scroll-50px withheight60vh">
        {customerData && customerData.length && !loder ? (
          customerData.sort((a,b) => a.contact_role - b.contact_role).filter((pp) => pp.name && pp.name.toLowerCase().includes(searchAssignees.toLowerCase())).map((actionItem, index) => {
            if(virtualSpace && actionItem.main_key == "myr_staff_task")
            {
              return <></>
            }
            return (
              <>
                {
                  <li
                    key={`customer_${(index + 1)}`}
                    className={`bg-white-03 p-2 custom-accordian-Header-sticky ${ index == 0 && actionItem.type && actionItem.type != "" ? "" : "d-none"
                      }`}
                  >
                    <div className="d-flex align-items-center text-truncate">
                      <div className="c-list-icon">
                        <div className="h25w25 d-flex align-items-center">
                          <Hash size={16} className="c-icons" weight="light" />
                        </div>
                      </div>
                      <div className="ps-2 w100minus30 text_wrap text-start">
                        <div className="c-list-detail text-truncate">
                          {actionItem.type}
                        </div>
                      </div>
                    </div>
                  </li>
                }
                <li className="hr_sepretr" key={`customer_list_${index}`} >
                  <div
                    className={`dropdown-item custom-class-add justify-content-between d-flex align-items-center`}
                    data-id={
                      actionItem.main_key && actionItem.id
                        ? `${actionItem.main_key}_${actionItem.id}`
                        : ""
                    }
                    data-tip={actionItem.name}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                  >
                    <div className="d-flex align-items-center text-truncate"
                    >
                      <div className="c-list-icon">
                        {actionItem.image ? (
                          <div
                            className="h25w25 comman-round-box with-bg rounded-circle bg-white-05"
                            aria-expanded="false"
                          >
                            {actionItem.contact_role == 1 ? (
                              <span className="bg-transparent border-0 rounded-pill badge-custom d-flex p-2 light-pill bg-none">
                                <PremiumEstimateIconCrown className="stroke-transparent path-warning HW14 position-absolute" />
                              </span>
                            ) : actionItem.contact_role == 2 ?
                            <span className="badge-custom bg-none bg-transparent border-0 d-flex light-pill p-1 rounded-pill">
                                <UserCircleGear size={20} color="#fca120" weight="light" />
                              </span>
                            :(
                             <></>
                            )}
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                              style={{
                                backgroundImage: `url('${actionItem.image}')`,
                              }}
                            ></div>
                          </div>
                        ) : (
                          <div className="h25w25 show bg-white-03 rounded-circle d-flex">
                            <Hash size={20} className="c-icons" weight="light" />
                          </div>
                        )}
                      </div>
                      <div className="ps-2 w100minus30 text_wrap">
                        <div className="c-list-detail text-truncate color-white-60">
                          {actionItem.name}
                        </div>
                      </div>
                    </div>
                   
                  {user_type == "staff" ? 
                    <div className="d-flex align-items-center comman_action_icon">
                      {/* <a href="#/" className="action_icon h32w32 with_bg on-hover-active-toggle-img">
                        <NotePencil size={18} className="c-icons" weight="light" />
                      </a> */}
                       {["right"].map((placement) => (
                    <OverlayTrigger
                      trigger={user_type == "staff" ? "click" : null}
                      // trigger={user_type == "staff" ? null : null}
                      rootClose
                      // delay={{ show: 1500 }}
                      key={placement}
                      placement={placement}
                      overlay={
                        <Popover
                          id={`popover-positioned-${placement}`}
                          className="w-400 minheight200px"
                        >
                          <Popover.Body className="p-0">
                            <div className="title-font f13 position-sticky top-0 z-index-2 bg-white-03-solid-imp p-10 border-bottom">
                              <div className='c-font f-16 fw-semibold title-fonts'>{actionItem.name}</div>
                            </div>
                            <div className="popover-detail-wrapper padding_10">
                              <div className='row'>
                                <div className='col-5 pe-10px d-flex justify-content-between c-font f-12 fw-semibold title-fonts'>
                                  <div>{_l("l_last_login")}</div>
                                  <div>:</div>
                                </div>
                                <div className='col-7 ps-10px'>
                                <div className='c-font f-12 color-white-60'>{loginAsUserDetails.last_login && moment(loginAsUserDetails.last_login).format('DD/MM/YYYY hh:mm:s A') || '-'}</div>
                                </div>
                              </div>
                            </div>
                            <div className="popover-detail-wrapper padding_10">
                              <div className='row'>
                                <div className='col-5 pe-10px d-flex justify-content-between c-font f-12 fw-semibold title-fonts'>
                                  <div >{_l("l_login_details")}</div>
                                  <div>:</div>
                                </div>
                                <div className='col-7 ps-10px '>
                                  <div className="">
                                    <span className='c-font f-12 color-white-60'>{_l("l_number_of_session")} :</span>
                                    <span className="c-font f-12 color-white fw-semibold ms-1">{loginAsUserDetails.session_details && loginAsUserDetails.session_details.no_of_session || '-'}</span>
                                  </div>
                                  <div className="">
                                    <span className='c-font f-12 color-white-60'>{_l("l_total_duration")} :</span>
                                    <span className="c-font f-12 color-white fw-semibold ms-1">{loginAsUserDetails.session_details && loginAsUserDetails.session_details.total_duration || '-'}</span>
                                  </div>
                                  <div className="">
                                    <span className='c-font f-12 color-white-60'>{_l("l_average_duration_time_by_session")} :</span>
                                    <span className="c-font f-12 color-white fw-semibold ms-1">{loginAsUserDetails.session_details && loginAsUserDetails.session_details.avg_time || '-'}</span>
                                  </div>
                                  <div className="">
                                    <span className='c-font f-12 color-white-60'>{_l("l_status")} :</span>
                                    <span className="c-font f-12 color-white fw-semibold ms-1">{loginAsUserDetails.session_details && loginAsUserDetails.session_details.status || '-' }</span>
                                  </div>
                                  <div className="">
                                    <span className='c-font f-12 color-white-60'>{_l("l_location")}</span>
                                    <span className="c-font f-12 color-white fw-semibold ms-1">{loginAsUserDetails.session_details && loginAsUserDetails.session_details.city || '-'}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="popover-detail-wrapper padding_10">
                              <div className='row'>
                                <div className='col-5 pe-10px d-flex justify-content-between c-font f-12 fw-semibold title-fonts'>
                                  <div>{_l("l_no_of_comments")}</div>
                                  <div>:</div>
                                </div>
                                <div className='col-7 ps-10px'>
                                <div className='c-font f-12 color-white-60'>{loginAsUserDetails.no_of_comments && loginAsUserDetails.no_of_comments || '-' }</div>
                                </div>
                              </div>
                            </div>
                            <div className="popover-detail-wrapper padding_10">
                              <div className='row'>
                                <div className='col-5 pe-10px d-flex justify-content-between c-font f-12 fw-semibold title-fonts'>
                                  <div>{_l("l_task_score")}</div>
                                  <div>:</div>
                                </div>
                                <div className='col-7 ps-10px'>
                                <div className='c-font f-12 color-white-60'>{loginAsUserDetails.task_score && loginAsUserDetails.task_score || '-'}</div>
                                </div>
                              </div>
                            </div> 
                          </Popover.Body>
                        </Popover>
                      }
                    >
                    <a href="#/" className="action_icon h32w32 with_bg on-hover-active-toggle-img ms-10px" onClick={() => {
                       loginDserDetail(actionItem.staff_id);
                      }}>
                        <Info size={18} className="c-icons" weight="light" />
                      </a>
                    </OverlayTrigger>
                  ))}
                      <a href="#/" className="action_icon h32w32 with_bg on-hover-active-toggle-img ms-10px" onClick={() => {
                        handler(actionItem);
                        HideDropdown();
                      }}>
                        <SignIn size={18} className="c-icons" weight="light" />
                      </a>
                    </div>
                  : <></>}
                  </div>
                  {/* <a href="#/"
                  className={`dropdown-item`}
                >User 1</a> */}
                </li>
              </>
            );
          })
            ) :
              loginuser === _l("l_login_as_user") && !isLoginAsUserDataLoaded ?
                <div className="dropdown-inner-scroll-50px" >
                  <CommanLoader className={`dropdown-loader comman-main-loader-wrapper `} />
                </div>
                : <></>
            }
          {
        is_projectStatus && project_status && project_status.length && project_status.map((actionItem , index)=>{
           
            return(
              <>
               <li className={`border-bottom border-top `} key={index}>
                  <div
                    className={`dropdown-item custom-class-add py-2 cursor-unset no-hover ${selectedspaceStatus == actionItem.id ? "active with-tick" : ""}` }
                    data-id={ actionItem.id}
                    data-tip={actionItem.name}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                  >
                    <a href="#/" className="d-flex align-items-center text-truncate" onClick={()=>{
                      handler(actionItem)
                      setSelectedspaceStatus(actionItem.id)
                    }}>

                      <div className="ps-2 w100minus30 text_wrap">
                        <div className={`text-truncate ${actionItem.class}`}>
                          {actionItem.name}
                        </div>
                      </div>
                    </a>
                  </div>
                </li>
              </>
            )
          })
        }
        {!is_projectStatus && providerList && providerList.length && !loder ? (
          providerList.map((actionItem, index) => {
            if(virtualSpace && actionItem.main_key == "myr_staff_task")
            {
              return <></>
            }
            return (
              <>
                {
                  <li
                    key={`provider_${(index + 1)}`}
                    className={`bg-white-03 p-2 custom-accordian-Header-sticky ${index == 0 && actionItem.type && actionItem.type != "" ? "" : "d-none"
                      }`}
                  >
                    <div className="d-flex align-items-center text-truncate">
                      <div className="c-list-icon">
                        <div className="h25w25 d-flex align-items-center">
                          <Hash size={16} className="c-icons" weight="light" />
                        </div>
                      </div>
                      <div className="ps-2 w100minus30 text_wrap text-start">
                        <div className="c-list-detail text-truncate">
                          {actionItem.type}
                        </div>
                      </div>
                    </div>
                  </li>
                }
                <li className={`bg-white-03 border-bottom border-top`} key={`provider_list_${index}`}>
                  <div
                    className={`dropdown-item custom-class-add py-2 cursor-unset no-hover`}
                    data-id={
                      actionItem.main_key && actionItem.id
                        ? `${actionItem.main_key}_${actionItem.id}`
                        : ""
                    }
                    data-tip={actionItem.name}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                  >
                    <div className="d-flex align-items-center text-truncate">
                    <div className="c-list-icon">
                          <div className="h25w25 show rounded-circle d-flex">
                            <Buildings size={16} className="c-icons opacity-60" weight="light" />
                          </div>
                      </div>
                      <div className="ps-2 w100minus30 text_wrap">
                        <div className="c-list-detail text-truncate color-white-60">
                          {actionItem.company}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {actionItem && actionItem.members && actionItem.members.length > 0 && actionItem.members.sort((a,b) => a.contact_role - b.contact_role).filter((pp) => pp.name && pp.name.toLowerCase().includes(searchAssignees.toLowerCase())).map((member) => {
                return <li className="hr_sepretr" key={index}>
                  <div
                    className={`dropdown-item custom-class-add justify-content-between d-flex align-items-center `}
                    data-id={
                      member.main_key && member.id
                        ? `${member.main_key}_${member.id}`
                        : ""
                    }
                    
                    data-tip={member.name}
                    data-effect="solid"
                    data-delay-show='1000'
                    data-class="tooltip-main"
                  >
                    <a href="#/" className="d-flex align-items-center text-truncate w100minus100per"
                    >
                      <div className="c-list-icon">
                        {member.image ? (
                          <div
                            className="h25w25 comman-round-box with-bg rounded-circle bg-white-05"
                            aria-expanded="false"
                          >
                            {member.contact_role == 1 ? (
                              <span className="bg-transparent border-0 rounded-pill badge-custom d-flex p-2 light-pill bg-none">
                                <PremiumEstimateIconCrown className="stroke-transparent path-warning HW14 position-absolute" />
                              </span>
                            ) : member.contact_role == 2 ?
                            <span className="badge-custom bg-none bg-transparent border-0 d-flex light-pill p-1 rounded-pill">
                                <UserCircleGear size={20} color="#fca120" weight="light" />
                              </span>
                            :(
                             <></>
                            )}
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                              style={{
                                backgroundImage: `url('${member.image}')`,
                              }}
                            ></div>
                          </div>
                        ) : (
                          <div className="h25w25 show bg-white-03 rounded-circle d-flex">
                            <Hash size={20} className="c-icons" weight="light" />
                          </div>
                        )}
                      </div>
                      <div className="ps-2 w100minus30 text_wrap">
                        <div className="c-list-detail text-truncate color-white-60">
                          {member.name}
                        </div>
                      </div>
                    </a>
                  
                  {user_type == "staff" ? 
                    <div className="d-flex align-items-center comman_action_icon">
                      {/* <a href="#/" className="action_icon h32w32 with_bg on-hover-active-toggle-img" onClick={(e) => {e.stopPropagation(); handler({...actionItem, metaData: {key: "edit_user_profile"}})}}>
                        <NotePencil size={18} className="c-icons" weight="light" />
                      </a> */}
                      {["right"].map((placement) => (
                    <OverlayTrigger
                      trigger={user_type == "staff" ? "click" : null}
                      // trigger={user_type == "staff" ? null : null}
                      rootClose
                      // delay={{ show: 1500 }}
                      key={placement}
                      placement={placement}
                      overlay={
                        <Popover
                          id={`popover-positioned-${placement}`}
                          className="w-400 minheight200px"
                        >
                          {/* <Popover.Header as="h3">{`Popover ${placement}`}{`${_l("l_manage_user_label")} ${_l("l_detail")}`}</Popover.Header> */}
                          <Popover.Body className="p-0">
                            <div className="title-font f13 position-sticky top-0 z-index-2 bg-white-03-solid-imp p-10 border-bottom">
                              <div className='c-font f-16 fw-semibold title-fonts'>{member.name}</div>
                            </div>
                            <div className="popover-detail-wrapper padding_10">
                              <div className='row'>
                                <div className='col-5 pe-10px d-flex justify-content-between c-font f-12 fw-semibold title-fonts'>
                                  <div>{_l("l_last_login")}</div>
                                  <div>:</div>
                                </div>
                                <div className='col-7 ps-10px'>
                                <div className='c-font f-12 color-white-60'>{loginAsUserDetails.last_login && moment(loginAsUserDetails.last_login).format('DD/MM/YYYY hh:mm:s A') || '-'}</div>
                                </div>
                              </div>
                            </div>
                            <div className="popover-detail-wrapper padding_10">
                              <div className='row'>
                                <div className='col-5 pe-10px d-flex justify-content-between c-font f-12 fw-semibold title-fonts'>
                                  <div >{_l("l_login_details")}</div>
                                  <div>:</div>
                                </div>
                                <div className='col-7 ps-10px '>
                                  <div className="">
                                    <span className='c-font f-12 color-white-60'>{_l("l_number_of_session")} :</span>
                                    <span className="c-font f-12 color-white fw-semibold ms-1">{loginAsUserDetails.session_details && loginAsUserDetails.session_details.no_of_session || '-'}</span>
                                  </div>
                                  <div className="">
                                    <span className='c-font f-12 color-white-60'>{_l("l_total_duration")} :</span>
                                    <span className="c-font f-12 color-white fw-semibold ms-1">{loginAsUserDetails.session_details && loginAsUserDetails.session_details.total_duration || '-'}</span>
                                  </div>
                                  <div className="">
                                    <span className='c-font f-12 color-white-60'>{_l("l_average_duration_time_by_session")} :</span>
                                    <span className="c-font f-12 color-white fw-semibold ms-1">{loginAsUserDetails.session_details && loginAsUserDetails.session_details.avg_time || '-'}</span>
                                  </div>
                                  <div className="">
                                    <span className='c-font f-12 color-white-60'>{_l("l_status")} :</span>
                                    <span className="c-font f-12 color-white fw-semibold ms-1">{loginAsUserDetails.session_details && loginAsUserDetails.session_details.status || '-' }</span>
                                  </div>
                                  <div className="">
                                    <span className='c-font f-12 color-white-60'>{_l("l_location")}</span>
                                    <span className="c-font f-12 color-white fw-semibold ms-1">{loginAsUserDetails.session_details && loginAsUserDetails.session_details.city || '-'}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="popover-detail-wrapper padding_10">
                              <div className='row'>
                                <div className='col-5 pe-10px d-flex justify-content-between c-font f-12 fw-semibold title-fonts'>
                                  <div>{_l("l_no_of_comments")}</div>
                                  <div>:</div>
                                </div>
                                <div className='col-7 ps-10px'>
                                <div className='c-font f-12 color-white-60'>{loginAsUserDetails.no_of_comments && loginAsUserDetails.no_of_comments || '-' }</div>
                                </div>
                              </div>
                            </div>
                            <div className="popover-detail-wrapper padding_10">
                              <div className='row'>
                                <div className='col-5 pe-10px d-flex justify-content-between c-font f-12 fw-semibold title-fonts'>
                                  <div>{_l("l_task_score")}</div>
                                  <div>:</div>
                                </div>
                                <div className='col-7 ps-10px'>
                                <div className='c-font f-12 color-white-60'>{loginAsUserDetails.task_score && loginAsUserDetails.task_score || '-'}</div>
                                </div>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                    <a href="#/" className="action_icon h32w32 with_bg on-hover-active-toggle-img ms-10px" onClick={() => {
                        loginDserDetail(member.staff_id);
                      }}>
                        <Info size={18} className="c-icons" weight="light" />
                      </a>
                    </OverlayTrigger>
                  ))}
                      <a href="#/" className="action_icon h32w32 with_bg on-hover-active-toggle-img ms-10px" onClick={() => {
                        handler(member);
                        HideDropdown();
                      }}>
                        <SignIn size={18} className="c-icons" weight="light" />
                      </a>
                    </div>
                  : <></>}
                  </div>
                
                </li>
                })}
              </>
            );
          })
        ) : (
          <></>
        )}
       
        {(type == "equipment" || type == "roomSetup") && list && list.length == 0 ? (
          <>
           <CommanPlaceholder imgType="no-images" placeholderText = {_l("l_no_equipments")} />
          </>
        ) :!is_projectStatus && !list.length && isLoginAsUserDataLoaded ? (
          <>
            <CommanLoader className={`dropdown-loader comman-main-loader-wrapper `} />
          </>
        ) : <></>}
      
      </div>
      }
      
      </>
  )

  return (
    <>
    {
      !unControlList ? <DropdownMenu container="body" className={`dropdown-menu overflowscroll max-height-60vh ${loginuser === _l("l_login_as_user") ? "w-240px minheight200px" : 'w-200'} `}>
        {DropDownAccordianContent}</DropdownMenu> :
        <ul
        className={` dropstart  overflowscroll ${ClassName ? ClassName : "w-140"
          }`}
        aria-labelledby="Loginuserdropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
      >{DropDownAccordianContent}</ul>
    }
    </>
  );
};

export default DropdownWithAccordion;
