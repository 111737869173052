import React, { useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { _l } from "../../hooks/utilities";
import { DownloadSimple, Pencil, Trash, User } from "phosphor-react";
import RiskListModal from "../Modals/RiskListModal";
import ProhibitIcon from "../../assets/icons/ProhibitIcon";
import UnControlMainFiltersDropDown from "../TaskModalComponents/LeadsMainFiltersDropDown";
import ReactTooltip from "react-tooltip";

const ProviderListActionModal = ({
    handleDelete =() => {},
    handleDownload =() => {},
    actionList = [],
    data,
    handleCancel = () => {},
  }) => {

  const [addRiskEdit, setAddRiskEdit] = useState(false);

  return (
    <>
      <DropdownMenu 
        className={`w-200 position-center-element ${actionList.includes("Assignee") ? "show" : ''}`}
        container="body"
        direction="left"
      >
      <ul>
      { actionList.includes("delete") && 
        <a href="#/" className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100" onClick={() => { handleDelete() }}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon">
              <Trash size={20} weight="light" className="c-icons" />
            </div>
            <div className="ps-2 w100minus40 text_wrap">{_l("l_delete")}</div>
          </div>
        </a>
        }
        { actionList.includes("download") && 
        <a href="#/" className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100" onClick={() => {handleDownload() }}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon">
              <DownloadSimple size={20} weight="light" className="c-icons" />
            </div>
            <div className="ps-2 w100minus40 text_wrap">{_l("l_download")}</div>
          </div>
        </a>
        }
        { actionList.includes("l_cancel_immediately") && 
        <a href="#/" className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100" onClick={() => {handleCancel("l_cancel_immediately", data) }}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon">
              <ProhibitIcon size={20} weight="light" className="c-icons" />
            </div>
            <div className="ps-2 w100minus40 text_wrap">{_l("l_cancel_immediately")}</div>
          </div>
        </a>
        }
        { actionList.includes("l_cancel__at_period_end") && 
        <a href="#/" className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100" onClick={() => {handleCancel("l_cancel__at_period_end", data) }}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon">
              <DownloadSimple size={20} weight="light" className="c-icons" />
            </div>
            <div className="ps-2 w100minus40 text_wrap">{_l("l_cancel__at_period_end")}</div>
          </div>
        </a>
        }
        { actionList.includes("l_cancel") && 
        <a href="#/" className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100" onClick={() => {handleCancel("l_cancel", data)}}>
          <div className="d-flex align-items-center">
            <div className="c-list-icon">
              <ProhibitIcon size={20} weight="light" className="c-icons" />
            </div>
            <div className="ps-2 w100minus40 text_wrap">{_l("l_cancel")}</div>
          </div>
        </a>
        }
        {
          actionList.includes("edit")
          ? <a href="#/" className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100" onClick={() => setAddRiskEdit(true)}>
            <div className="d-flex align-items-center">
              <div className="c-list-icon">
                <Pencil size={20} weight="light" className="c-icons" />
              </div>
              <div className="ps-2 w100minus40 text_wrap">{_l("l_edit")}</div>
            </div>
            </a>
          : <></>
        }
        {
          actionList.includes("Assignee")
          ? <li href="#/" className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100">
             <UncontrolledDropdown className=""
                data-tip={_l("l_assign_to")}
                data-effect="solid"
                data-delay-show='1000'
                data-class="tooltip-main"
                ><DropdownToggle
                    tag={'a'}
                    className="d-flex on-hover-active-toggle-img"
                    type="button"
                    data-bs-toggle="dropdown" 
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    onClick={(e) => {
                        e.stopPropagation();
                        // showLeadContactComments();
                    }}
                > 
                <div className="d-flex align-items-center">
                  <div className="c-list-icon">
                    <User size={20} weight="light" className="c-icons" />
                  </div>
                  <div className="ps-2 text_wrap">{_l("l_assignee")}</div>
                </div>
                </DropdownToggle>
                <ReactTooltip />
                <DropdownItem toggle={false} className="p-0"> 
                    <UnControlMainFiltersDropDown
                        type = {"provider_list"}
                        data={data}
                        selectedleadfilers = {data && data.provider_assignee && data.provider_assignee.map((item) => item.assignee_id)}
                    />
                </DropdownItem>
            </UncontrolledDropdown>
            </li>
          : <></>
        }
        </ul>
      </DropdownMenu>
      {
        addRiskEdit ?
          <RiskListModal
          show={addRiskEdit}
          handleClose={()=>{
            setAddRiskEdit(false);
          }}
          editData={data && data.risk_detail ? JSON.parse(data.risk_detail)  : data}
          isFromEdit={true}
          hideActionMenu={true}
          actionType="edit"
          TemplateTitle = {data.title}
          id={data.id}
          />
        : <></>
      }
    </>
  );
};

export default ProviderListActionModal;
