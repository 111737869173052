export const FETCH_AUTH_TOKEN = "FETCH_AUTH_TOKEN";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_GROUPS_EXTERNAL = "FETCH_GROUPS_EXTERNAL";
export const LAST_MESSAGE_CONTACTDIR = "LAST_MESSAGE_CONTACTDIR";
export const CONTACT_DIR_COUNT = "CONTACT_DIR_COUNT";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const FETCH_UNREAD_MSG_COUNT = "FETCH_UNREAD_MSG_COUNT";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const MYR_BASE_URL = process.env.REACT_APP_CHAT_WEB_BASE_URL;
export const ATTACH_BASE_URL = MYR_BASE_URL + "/orb/uploads";
export const USR_IMG_PLACEHOLDER =
  MYR_BASE_URL + "/orb/assets/images/default-image-placeholder.svg";
export const CHAT_BASE_URL = process.env.REACT_APP_CHAT_BASE_URL;
export const UPLOAD_CHAT_MEDIA = "UPLOAD_CHAT_MEDIA";
export const UPDATE_UPLOADED_FILENAME = "UPDATE_UPLOADED_FILENAME";
export const USER_ONLINE = "USER_ONLINE";
export const USER_OFFLINE = "USER_OFFLINE";
export const UPDATE_USER_ONLINE = "UPDATE_USER_ONLINE";
export const UPDATE_USER_OFFLINE = "UPDATE_USER_OFFLINE";
export const UPDATE_NEW_MESSAGE = "UPDATE_NEW_MESSAGE";
export const UPDATE_UNREAD_MSG_COUNT = "UPDATE_UNREAD_MSG_COUNT";
export const USER_READ_MSG = "USER_READ_MSG";
export const UPDATE_USER_READ_MSG = "UPDATE_USER_READ_MSG";
export const FETCH_VIEW_GROUPS = "FETCH_VIEW_GROUPS";
export const NEW_TASK_NOTI = "NEW_TASK_NOTI";
export const ALL_PROJ_UNREAD_MSG_CNT = "ALL_PROJ_UNREAD_MSG_CNT";
export const NEW_MSG_FOR_AM = "NEW_MSG_FOR_AM";
export const UPDATE_NEW_MSG_FOR_AM = "UPDATE_NEW_MSG_FOR_AM";
export const MULTI_USR_PLACEHOLDER = MYR_BASE_URL + "/orb/assets/images/default-multiuser-placeholder.svg";
export const NEW_GROUP = "NEW_GROUP";
export const EDIT_GROUP = "EDIT_GROUP";
export const EDIT_MESSAGE = "EDIT_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const UPDATE_GENERAL_MSG_COUNT = "UPDATE_GENERAL_MSG_COUNT";
export const OPEN_GENERAL_CHAT_GROUP = "OPEN_GENERAL_CHAT_GROUP";
export const SET_CALL_STATUS = "SET_CALL_STATUS";
export const MUTE_CALL = "MUTE_CALL";
export const UNMUTE_CALL = "UNMUTE_CALL";
export const ACCEPT_CALL = "ACCEPT_CALL";
export const CALL_NOT_RESPOND = "CALL_NOT_RESPOND";
export const SET_MY_STREAM = "SET_MY_STREAM";
export const SET_REMOTE_STREAM = "SET_REMOTE_STREAM";
export const SET_UNREAD_MSG_COUNT = "SET_UNREAD_MSG_COUNT";
export const GET_GROUP_DETAILS = "GET_GROUP_DETAILS";
export const CALL_ACTION = "CALL_ACTION";
export const CLEAR_MESSAGE_LIST = "CLEAR_MESSAGE_LIST";
export const CALL_TASK_LIST = "CALL_TASK_LIST";
export const STOP_CHAT_SCROLL = "STOP_CHAT_SCROLL";
export const SET_TYPING_MEMBERS = "SET_TYPING_MEMBERS";
export const BURGER_MENU_TOPIC_TYPING = "BURGER_MENU_TOPIC_TYPING";
export const DISABLE_INPUT_CHAT = "DISABLE_INPUT_CHAT";
export const BURGER_MENU_TOPIC_ANALYSIS = "BURGER_MENU_TOPIC_ANALYSIS";
export const CALL_IS_RECIVE = "CALL_IS_RECIVE";
