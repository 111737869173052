import React, { useEffect, useRef, useState } from "react";
import { mapStyle } from "../../../assets/map/mapStyle";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { _l } from "../../../hooks/utilities";
import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_SECRET);
Geocode.setLanguage("fr");
Geocode.setRegion("fr");

export const Address = ({
  setSpaceDetail,
  spaceDetail,
  regionList,
  setRegionList,
  coOrdinates,
  setCoOrdinates,
  isRegister = false,
  formData = {},
  setFormData = ()=>{},
}) => {

  const containerStyle = {
    width: "100%",
    height: "100%",
    position: "static",
  };

  const addressOptions = {
    // componentRestrictions: { country: "fr" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  }

  const addressRef = useRef();
  const autoCompleteRef = useRef();

  const selectSpaceRegion = (addressComponent) => {
    let regionId = 0;
    let regionName = "";
    let fetchedRegion = "";
    let index = addressComponent.findIndex(
      (add) => add.types && add.types.includes("administrative_area_level_2")
    );
    if (index === -1) {
      index = addressComponent.findIndex(
        (add) => add.types && add.types.includes("administrative_area_level_1")
      );
    }
    if (index > -1) {
      fetchedRegion = addressComponent[index].long_name;
    }
    regionList.forEach((region) => {
      if (
        region.label.toLowerCase().trim() == fetchedRegion.toLowerCase().trim()
      ) {
        regionId = region.value;
        regionName = region.label;
      }
    });
    if (regionId == 0) {
      setRegionList(regionList.concat([{ value: 0, label: fetchedRegion }]));
      return { region_id: 0, region_name: fetchedRegion };
    } else {
      return { region_id: regionId, region_name: regionName };
    }
  };

  useEffect(() => {
    if (
      spaceDetail.address &&
      spaceDetail.address.trim().length &&
      addressRef.current
    ) {
      try {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          addressRef.current,
          addressOptions
        ).addListener("place_changed", () => {
          Geocode.fromAddress(addressRef.current.value).then(
            (response) => {
              const placeId = response.results[0].place_id
              const { lat, lng } = response.results[0].geometry.location;
              const { region_id, region_name } = selectSpaceRegion(response.results[0].address_components);
              setSpaceDetail({
                ...spaceDetail,
                address: addressRef.current.value,
                region_id,
                region_name,
                lat,
                lng,
                place_id :placeId
              });
              setCoOrdinates({
                lat: lat,
                lng: lng,
              });
            },
            (error) => {
              console.log(error);
            }
          );
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [spaceDetail.address]);

  useEffect(() => {
    if (formData &&
      formData.companyaddress &&
      formData.companyaddress.trim().length &&
      addressRef.current
    ) {
      try {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          addressRef.current,
          addressOptions
        ).addListener("place_changed", () => {
          Geocode.fromAddress(addressRef.current.value).then(
            (response) => {
              const placeId = response.results[0].place_id
              const { lat, lng } = response.results[0].geometry.location;
              const { region_id, region_name } = selectSpaceRegion(response.results[0].address_components);
              setFormData({
                ...formData,
                companyaddress: addressRef.current.value,
                region_id,
                region_name,
                lat,
                lng,
                place_id :placeId
              });
              setCoOrdinates({
                lat: lat,
                lng: lng,
              });
            },
            (error) => {
              console.log(error);
            }
          );
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [formData.companyaddress]);

  return (
    <div className="h-100 overflow-hidden position-relative rounded-2">
      <div className="onboarding-map hide-magnify">
        <div className="end-0 position-sticky p-4 start-0 top-0 w-100 z-index-2"> 
          <div className="row justify-content-center">
            <div className="col-xl-12 c-input-box pb-3 position-relative">
              <input
                className="form-control onboarding-map-input"
                placeholder={_l("l_address_placeholder")}
                type="text"
                name=""
                onChange={(e) => {
                  if (isRegister) {
                    setFormData({ ...formData, companyaddress: e.target.value });
                  } else{
                    setSpaceDetail({ ...spaceDetail, address: e.target.value });
                  }
                }}
                ref={addressRef}
                value={isRegister ? formData.companyaddress :  spaceDetail.address}
              />
            </div>
            {/* <div className="col-xl-2 c-input-box pb-3 position-relative">
             <Select
               className="custom-select-menu onboarding-map-input"
               classNamePrefix="react-select"
               value={
                 spaceDetail
                   ? regionList.find(
                       (x) =>
                         x.value == spaceDetail.region_id ||
                         x.label == spaceDetail.region_name
                     )
                   : []
               }
               isDisabled={true}
             />
            </div> */}
          </div>
        </div>
        {coOrdinates && Object.keys(coOrdinates).length ? (
          <GoogleMap
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
            options={{
              streetViewControl: false,
              scrollwheel: false,
              styles: mapStyle,
            }}
            mapContainerStyle={containerStyle}
            center={coOrdinates}
            zoom={12}
          >
            <Marker position={coOrdinates} />
          </GoogleMap>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Address