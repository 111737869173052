import React, { useEffect, useState } from "react";
import { _l } from "../hooks/utilities";
import CreateTenant from "../components/ExploreFeatures/Tenants/CreateTenant";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { useSelector } from "react-redux";


const EmployeeRegistration = () => {

  const navigate = useNavigate();

  const [tenantsData, setTenantsData] = useState({
    countryCode: "+33",
    email: "",
    firstname: "",
    lastname: "",
    manager: 0,
    phonenumber: "",
    role: 0,
    assigned_project_ids: [0],
    team_project_id: 0,
    tenantsList: [
            // {
            //   active: "1",
            //   assigned_equipment: [],
            //   assigned_project_ids: ["827"],
            //   contract_emails: "1",
            //   credit_note_emails: "1",
            //   customer_invited: 0,
            //   email: "myr_AfrodilleBeaujolie@jourrapide.com",
            //   estimate_emails: "1",
            //   full_name: "Afrodille Beaujolie",
            //   id: "4266",
            //   invoice_emails: "1",
            //   is_psw_set: "0",
            //   is_skilled: 0,
            //   last_login: null,
            //   no_of_comments: "0",
            //   password_set_link: null,
            //   phone_number: "+332323232323",
            //   position: [],
            //   profile_image:
            //     "https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg",
            //   project_emails: "1",
            //   role_detail: { role_name: "Employee", role_id: "3" },
            //   session_details: [],
            //   staff_id: "3174",
            //   staff_image: [],
            //   task_emails: "1",
            //   task_score: 0,
            //   team_details: [],
            //   teams: [],
            //   ticket_emails: "1",
            // },
    ],
    team_members: [],
    team_name: "",
    teamId: 0,
    position: "",
  });
  const [headerText, setHeaderText] = useState("");
  const [headerDescriptionText, setheaderDescriptionText] = useState("");
  const [titlePrefix, setTitlePrefix] = useState("");
  const [lastMessage, setLastMessage] = useState("");
  const { messageList } = useSelector((state) => state.chat);


  useEffect(() => {
    if (messageList && messageList.length > 0) {
      setLastMessage(messageList[messageList.length - 1]);
    }
  }, [JSON.stringify(messageList)]);


  const getEmployeesList = () => {};
  return (
    <>
      <div className="col-12 col-xl-9 col-xxl-8 registration-content align-items-center mx-auto row registration-step-header">
        <div class="col-lg-2"></div>
        <div class="col-lg-7 m-auto">
          <div class="py-30px position-relative text-center">
            <div class="d-flex align-items-center justify-content-center">
              <div class=" px-0 px-md-3">
                <div class="c-font f-20 fw-semibold title-fonts">
                  {_l("l_add_employee")}
                </div>
                <div class="c-font f-16 pt-15px sub-header text-center">
                  <span>
                    {_l("l_add_employee_description")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
        <div
              className={`d-flex`}
              // style={{ top: "40%" }}
            >
                <div className="d-flex right-side-button ms-auto">
                  <Button
                    variant={"primary btn-sm"}
                    className={`text-nowrap `}
                    onClick={()=>{navigate("/aichat")}}
                    id="footer-primary-button"
                  >
                      <span>
                        {_l("l_next")}
                      </span>
                    
                  </Button>
                </div>
                                

            </div>
        </div>
      </div>
      <div className="flex-grow-1 d-flex flex-column overflow-hiiden-auto row">
        <div className="col-12 col-xl-9 col-xxl-9 registration-footer-content mx-auto">
          <div className="onboarding-content-scroll-wrapper overflow-hiiden-auto flex-column">
            <CreateTenant
              setTenantsData={setTenantsData}
              tenantsData={tenantsData}
              projectId={0}
              showTeams={false}
              getTenantsList={getEmployeesList}
              setHeaderText={setHeaderText}
              setheaderDescriptionText={setheaderDescriptionText}
              setTitlePrefix={setTitlePrefix}
            />
          </div>
          </div>
      </div>
      <a
        href="#/"
        className="bg-white-03 p-10 comman_action_icon w_310 radius_3 feed-chat-main"
        onClick={() => {
          navigate(`/aichat`);
        }}
        // style={{ marginLeft: "50px" }}
      >
        <div className="d-flex align-items-center text-truncate pb-2">
          <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5 ">
            <div
              className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
              style={{
                backgroundImage: `url('https://myrpp.myr.ai/orb/assets/images/myrtasklogo.png')`,
              }}
            ></div>
          </div>
          <div className="c-font f-14 fw-semibold ps-10px">
            Feedchat : Myr.ai support
          </div>
        </div>
        <div className="d-flex align-items-center text-truncate">
          <div className="d-flex align-items-center text-truncate">
            <span className="c-dots  c-7 in-progress-white rounded-circle me-3"></span>
            <div className="c-font f-12 me-10px">
              {lastMessage.createdAt
                ? moment(lastMessage.createdAt).format("YYYY/MM/DD HH:mm ")
                : ""}
            </div>
          </div>
          <div className="c-font f-12 me-10px text-truncate w100minus100per">
            {lastMessage.message ? ReactHtmlParser(lastMessage.message) : ""}
          </div>
        </div>
      </a>
    </>
  );
};

export default EmployeeRegistration;
