import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { _l } from "../../../hooks/utilities";
import $ from "jquery";
import { showMessage } from "../../../actions/messages";
import { useDispatch, useSelector } from "react-redux";
import { getStaffDeatil, updateStaffDate } from "../../../actions/documents-actions/documents-actions";
import { Checkbox, FormControlLabel } from "@mui/material";
import Form from "react-bootstrap/Form";
import documentsServices from "../../../services/documents-services";
import EquipmentListing from "../../../components/EquipmentListing";
const ClientSpaceTab = ({data}) => {
const [allPermissions, setAllPermissions] = useState([]);
const [customerPermissions, setCustomerPermissions] = useState([]);
const [keys, setKeys] = useState([]);
const [staffid, setStaffid] = useState();
const [keyIndex, setKeyIndex] = useState([]);
const [checked, setChecked] = useState(false);
const dispatch = useDispatch();

    return (
        <div>
            <EquipmentListing pageName="customer" clientid={data.id} is_space={'space'}/>
        </div>
    )
}
export default ClientSpaceTab;