import React from "react";
import { Form } from "react-bootstrap";
import { _l } from "../../hooks/utilities";
import Select from "react-select";
import {
  Bell,
  BellSimpleRinging,
  EnvelopeSimple,
  Translate,
} from "phosphor-react";
import {
  LANGUAGE_SELECT_OPTIONS,
  NOTIFICATION_PREFERENCE,
} from "../../constants/constants";

const NotificationPreference = ({
  mobileNotification,
  emailNotification,
  handleNotificationPreference,
}) => {
  const NOTIFICATION_PREFERENCE_OPTIONS = [
    {
      label: _l("l_enable_all"),
      value: NOTIFICATION_PREFERENCE.ENABLE_ALL,
    },
    {
      label: _l("l_only_for_mention"),
      value: NOTIFICATION_PREFERENCE.ONLY_FOR_MENTION,
    },
    {
      label: _l("l_mention_comment_two_times_a_day"),
      value: NOTIFICATION_PREFERENCE.MENTION_COMMENT_TWO_TIMES_A_DAY,
    },
    {
      label: _l("l_disable"),
      value: NOTIFICATION_PREFERENCE.DISABLE,
    },
  ];

  const toggleEmailPreference = () => {
    if(emailNotification == NOTIFICATION_PREFERENCE.DISABLE)
    {
      handleNotificationPreference("email_notification", NOTIFICATION_PREFERENCE.ENABLE_ALL);
    }
    else {
      handleNotificationPreference("email_notification", NOTIFICATION_PREFERENCE.DISABLE);
    }
  }

  return (
    <div className="p-20 px-0 ">
      <div className="pe10per">
        <div class="c-font fw-semibold title-fonts f-14 pb-15px">
          {_l("l_notification")}
        </div>
        <div className="row">
          <div className={` task-list-card list-view document-list-card `}>
            <div className="d-flex w-100 align-items-center">
              <div className="task-creator-img">
                <div className="comman-image-box h32w32 rounded-circle d-flex">
                  <BellSimpleRinging
                    size={18}
                    weight="light"
                    className="c-icons"
                  />
                </div>
              </div>
              <div className="title-fonts text-truncate task-list-name">
                {_l("l_push_notifications")}
              </div>
              <div className="form-check form-check-inline m-0 ps-2">
                <Form.Check
                  className=" with-no-label-space"
                  type="switch"
                  role="switch"
                  checked={mobileNotification > 0}
                  onChange={(e) => handleNotificationPreference("push_notification", e.target.checked ? 1 : 0)}
                />
              </div>
            </div>
          </div>
          <div className={` task-list-card list-view document-list-card `}>
            <div className="d-flex w-100 align-items-center">
              <div className="task-creator-img">
                <div className="comman-image-box h32w32 rounded-circle d-flex">
                  <EnvelopeSimple
                    size={18}
                    weight="light"
                    className="c-icons"
                  />
                </div>
              </div>
              <div className="title-fonts text-truncate task-list-name">
                {_l("l_email_notifications")}
              </div>
              <div className="row z-index-3 w-100 align-items-center justify-content-end pe-20pximp">
                <div className="c_input_border drop_image col-5 position-relative">
                  <Select
                    className="custom-select-menu h_30px scroll-height-30vh"
                    classNamePrefix="react-select"
                    options={NOTIFICATION_PREFERENCE_OPTIONS}
                    value={NOTIFICATION_PREFERENCE_OPTIONS.find((w) => w.value == emailNotification)}
                    isDisabled={emailNotification == NOTIFICATION_PREFERENCE.DISABLE}
                    onChange={(e) => {handleNotificationPreference("email_notification", e.value);}}
                  />
                </div>
                <div className="col-1 text-end ps-0">
                  <div className="form-check form-check-inline m-0 ps-2">
                    <Form.Check
                      className=" with-no-label-space"
                      type="switch"
                      role="switch"
                      checked={emailNotification != NOTIFICATION_PREFERENCE.DISABLE}
                      onChange={toggleEmailPreference}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={` task-list-card list-view document-list-card `}>
            <div className="d-flex w-100 align-items-center">
              <div className="task-creator-img">
                <div className="comman-image-box h32w32 rounded-circle d-flex">
                  <Translate size={18} weight="light" className="c-icons" />
                </div>
              </div>
              <div className="title-fonts text-truncate task-list-name">
                {_l("l_language")}
              </div>
              <div className="row w-100 align-items-center justify-content-end">
                <div className="c_input_border drop_image col-5 position-relative">
                  <Select
                    className="custom-select-menu scroll-height-30vh"
                    classNamePrefix="react-select"
                    options={LANGUAGE_SELECT_OPTIONS}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default NotificationPreference;
