import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { _l } from "../../../hooks/utilities";
import { Form } from "react-bootstrap";

const Estimate = ({ data }) => {
  const [amount, setAmount] = useState(0);
  const [orderNumber, setOrderNumber] = useState("");
  const [estimateDate, setEstimateDate] = useState("");
  const [billingPeriod, setBillingPeriod] = useState("");
  const [nextBillingDate, setNextBillingDate] = useState("");
  const [estimateNumber, setEstimateNumber] = useState("");
  const [billFrom, setBillFrom] = useState("");
  const [billTo, setBillTo] = useState("");
  const [estimateItems, setEstimateItems] = useState([]);
  const [estimateSubTotal, setEstimateSubTotal] = useState(0);
  const [estimateDiscount, setEstimateDiscount] = useState(0);
  const [estimateTotal, setEstimateTotal] = useState(0);
  const [estimateTerms, setEstimateTerms] = useState("");
  const [totalTax, setTotalTax] = useState(0);

  useEffect(() => {
    if (data) {
      setAmount(data.total);
      setOrderNumber("0000-000000");
      setEstimateDate(
        data && data.date ? moment(data.date).format("D MMM, YY") : ""
      );
      setBillingPeriod("unknown");
      setNextBillingDate("unknown");
      setEstimateNumber(data.name);
      setBillFrom(data.bill_from);
      setBillTo(data.bill_to);
      setEstimateItems(data.items);
      setEstimateTerms(data.terms);
      setEstimateSubTotal(data.subtotal);
      setEstimateTotal(data.total);
      setTotalTax(data.total_tax);
    }
  }, [JSON.stringify(data)]);

  return (
    <div>
      <table className="w-100">
        <thead></thead>
        <tbody className="d-none">
          <tr className="w-100 border-bottom">
            <td className="w-50 p-2">
              <div className="d-flex ">
                <span className="">{_l("l_amount")} : {amount}</span>
                <span className="color-white-80  ps-3"></span>
              </div>
            </td>
            <td className="w-50 p-2 border-left">
              <div className="d-flex ">
                <span className="">{_l("l_billing_period")} : </span>
                <span className="color-white-80  ps-3">{billingPeriod}</span>
              </div>
            </td>
          </tr>
          <tr className="w-100 border-bottom">
            <td className="w-50 p-2">
              <div className="d-flex ">
                <span className="">{_l("l_order_number")} : </span>
                <span className="color-white-80  ps-3">{orderNumber}</span>
              </div>
            </td>
            <td className="w-50 p-2 border-left">
              <div className="d-flex ">
                <span className="">{_l("l_next_billing_date")} : </span>
                <span className="color-white-80  ps-3">{nextBillingDate}</span>
              </div>
            </td>
          </tr>
          <tr className="w-100">
            <td className="w-50 p-2">
              <div className="d-flex ">
                <span className="">{_l("l_estimate_date")} : </span>
                <span className="color-white-80  ps-3">{estimateDate}</span>
              </div>
            </td>
            <td className="w-50 p-2 border-left">
              <div className="d-flex ">
                <span className="">{_l("l_estimate_number")} : </span>
                <span className="color-white-80  ps-3">{estimateNumber}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="bill-from w-100 d-flex">
        <div className="w-50">
          <div className="color-white-60  pb-2">{_l("l_bill_from")}</div>
          <span
            dangerouslySetInnerHTML={{
              __html: billFrom,
            }}
          ></span>
        </div>
        <div className="w-50">
          <div className="color-white-60  pb-2">{_l("l_bill_to")}</div>
          <span
            dangerouslySetInnerHTML={{
              __html: billTo,
            }}
          ></span>
        </div>
      </div>
      <div className="">
        <div className=" d-flex flex-column pt-3">
          <div className="comman-content-scroll-wrapper">
            <div className="comman-content-scroll">
              <div className="comman-data-table GeneralItemTable">
                <table className="dataTable comman-table smaller-table">
                  <thead className="bg-transparent z-index-1imp">
                    <tr className="bg-white-03">
                      <th style={{ width: "3%" }}>#</th>
                      <th>{_l("l_item")}</th>
                      <th style={{ width: "7%" }}>{_l("l_quantity")}</th>
                      <th style={{ width: "7%" }}>{_l("l_rate")}</th>
                      <th style={{ width: "18%" }}>{_l("l_tax")} (%)</th>
                      <th style={{ width: "8%" }}>{_l("l_amount")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {estimateItems && estimateItems.length ? (
                      estimateItems.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr className="c-font f-12">
                              <td className="border-bottom align-top">
                                {item.item_order}
                              </td>
                              <td className="max-width-100 text-truncate border-bottom">
                                <div
                                  className="color-white-60 "
                                  dangerouslySetInnerHTML={{
                                    __html: item.description
                                      ? `${item.description}`
                                      : "",
                                  }}
                                >
                                </div>
                                <div
                                  className="color-white-60 "
                                  dangerouslySetInnerHTML={{
                                    __html: item.long_description && item.long_description != ""
                                      ? `${item.long_description}`
                                      : "",
                                  }}
                                >
                                </div>
                              </td>
                              <td className="border-bottom align-top ">
                                {item.qty}
                              </td>
                              <td className="border-bottom align-top ">
                                {item.rate}
                              </td>
                              <td className="border-bottom align-top ">
                                {item.tax_rate}
                              </td>
                              <td className="border-bottom align-top ">
                                {item.amount}
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <></>
                    )}

                    <tr className="c-font f-12">
                      <td colSpan={5} className="text-end border-bottom ">
                      {_l("l_sub_total")}
                      </td>
                      <td className="border-bottom  color-white-60 ">
                        {estimateSubTotal}
                      </td>
                    </tr>
                    <tr className="c-font f-12">
                      <td colSpan={5} className="text-end border-bottom  ">
                      {estimateItems && estimateItems.length ? estimateItems[0].tax_rate : `0% ${_l("l_tva")}`}
                      </td>
                      <td className="border-bottom  color-white-60">
                        {totalTax}
                      </td>
                    </tr>
                    <tr className="c-font f-12">
                      <td colSpan={5} className="text-end color-green ">
                      {_l("l_total")}
                      </td>
                      <td className="fw-semibold  color-green">
                        {estimateTotal}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* Unit Task Estimate Start */}
                  <div className=" d-flex flex-column pt-3">
                    <div className="comman-content-scroll-wrapper">
                      <div className="comman-content-scroll">
                        <div className="comman-data-table">
                        {data.unit_task && data.unit_task.length ? (
                            <React.Fragment>
                              <div className="d-flex align-items-center justify-content-between pt-30px pb-10px bg-white-03-solid position-sticky top-0 z-index-5 light-theme-grey-light-bg">
                                <div className="title-fonts fw-semibold">
                                  {_l("l_bpu_title")}
                                </div>
                              </div>
                              <div className="comman-data-table small-data-table">
                                <table
                                  className="dataTable  title-fonts text-center"
                                  width="100%"
                                >
                                  <thead className="" style={{ top: "54px" }}>
                                    <tr className="">
                                      <th
                                        style={{ width: "40px", padding: "5px" }}
                                      >
                                        {_l("l_id")}
                                      </th>
                                      <th className="text-start">{`${_l(
                                        "l_bpu_title"
                                      )} ${_l("l_name")}`}</th>
                                      <th style={{ width: "70px" }}>
                                        {_l("l_monthly")}
                                      </th>
                                      <th style={{ width: "70px" }}>
                                        {_l("l_quarterly")}
                                      </th>
                                      <th style={{ width: "70px" }}>
                                        {_l("l_half_yearly")}
                                      </th>
                                      <th style={{ width: "70px" }}>
                                        {_l("l_yearly")}
                                      </th>
                                      <th style={{ width: "70px" }}>
                                        {_l("l_proposal_amount")}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data.unit_task.map((unit, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td className="text-start">
                                            {unit.name}
                                          </td>
                                          <td className="text-center ">
                                            <label for="" className="">
                                              <input
                                                className="form-check-input "
                                                type="checkbox"
                                                checked={unit.monthly}
                                                disabled={true}
                                              />
                                            </label>
                                          </td>{" "}
                                          <td className="text-center ">
                                            <label for="" className="">
                                              <input
                                                className="form-check-input "
                                                type="checkbox"
                                                checked={unit.quarterly}
                                                disabled={true}
                                              />
                                            </label>
                                          </td>{" "}
                                          <td className="text-center ">
                                            <label for="" className="">
                                              <input
                                                className="form-check-input "
                                                type="checkbox"
                                                checked={unit.half_yearly}
                                                disabled={true}
                                              />
                                            </label>
                                          </td>{" "}
                                          <td className="text-center ">
                                            <label for="" className="">
                                              <input
                                                className="form-check-input "
                                                type="checkbox"
                                                checked={unit.yearly}
                                                disabled={true}
                                              />
                                            </label>
                                          </td>{" "}
                                          <td>
                                            <Form.Control
                                              className="p-1 title-fonts border"
                                              type="text"
                                              placeholder={_l("l_add_price")}
                                              value={`${unit.amount}`}
                                              disabled
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                {/* Unit Task Estimate End */}
                <div className="terms-condition">
                  <div className="fw-semibold pb-2">{_l("l_terms_conditions")}</div>
                  <div className="color-white-60 py-2">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: estimateTerms,
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};
export default Estimate;
