import React, { useEffect, useState, lazy } from "react";
import { _l, isCamFound } from "../../hooks/utilities";
// import ChecklistComponent from "./ChecklistComponent";
import FileUploadComponent from "./FileUploadComponent";
import LinkComponent from "./LinkComponent";
import NotesComponent from "./NotesComponent";
import { X, MagnifyingGlass, Info, Funnel, UploadSimple } from "phosphor-react";
import ReactTooltip from "react-tooltip";
import DocumentsDetail from "../Documents/DocumentsDetail";
import DocumentsActivity from "../Documents/DocumentsActivity";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import { useSelector } from "react-redux";
import ChecklistComponent from "./ChecklistComponent"
import Documentdata from "../Documents/tabs/Document"
import FeedchatInTaskModalButton from "./FeedchatInTaskModalButton";
import FeedchatInTaskModal from "./FeedchatInTaskModal";
// import FullScreenLoader from "../Loader/FullScreenLoader";

// const Documentdata = lazy(() => {
//   return new Promise((resolve) => {
//     resolve(import("../Documents/tabs/Document"));
//   });
// });
// const FileUploadComponent = lazy(() => import("./FileUploadComponent"));
// const ChecklistComponent = lazy(() => import("./ChecklistComponent"));
// const LinkComponent = lazy(() => import("./LinkComponent"));
// const NotesComponent = lazy(() => import("./NotesComponent"));
// const Documentdata = lazy(() => import("../Documents/tabs/Document"));

function TaskBodyCommonModal({
  data,
  handleBodyAction,
  isExternal = false,
  checklistAssigneesList,
  showFullscreen=false,
  pageName,
  iscallModal,
}) {
  const [activeTab, setActiveTab] = useState(
    (data.provider === "google_drive" || data.provider === "one_drive") && typeof data.is_synced !== "undefined"
      ? "document"
      : "checklist"
  );
  const { isCallFullscreen } = useSelector((state) => state.customer);
  const [selectedTask, setSelectedTask] = useState("checklist");
  const projectId = localStorage.getItem("selectedOffice");
  const [searchLinkAttchment, setSearchLinkAttchment] = useState("");
  const [taskId, setTaskId] = useState(0);
  const [randomID, setRandomID] = useState(String(Math.random()));
  const [showLoader, setShowLoader] = useState(false);
  const [toastClose, setToastClose] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checklistSearchString, setChecklistSearchString] = useState("")
  const [checklistFilter, setChecklistFilter] = useState(["all"]);
    const [actionMenuItems, setActionMenuItems] = useState([]);
  const [ShowCommentModal, setShowCommentModal] = useState(false);
  const {floorDetails} = useSelector((state) => state.benchmarkReducer);
  const { isCallRecived } = useSelector((state) => state.chat);
  const [isMute, setIsMute] = useState(false);
  const [isCameraOff, setIsCameraOff] = useState(false);
  const [isCameraFound, setIsCameraFound] = useState(true);
  
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(data).length) {
      setTaskId(data.id ? data.id : 0);
      setSelectedTask(data);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    setSearchLinkAttchment("")
    setChecklistSearchString("")
  }, [taskId]);

  useEffect(() => {
     let actionMenu = [
      // {
      //   actionName: _l("l_checked"),
      //   actionIcon: "CheckIcon",
      //   actionHandler: () => {checkListFilter("checked")},
      //   active : checklistFilter.includes("checked")
      // },
      // {
      //   actionName: _l("l_not_checked"),
      //   actionIcon: "SquareIcon",
      //   actionHandler: () => {checkListFilter("notchecked")},
      //   active : checklistFilter.includes("notchecked")
      // },
      {
        actionName: _l("l_todo"),
        actionIcon: "CircleFillTodoIcons",
        actionHandler: () => {checkListFilter("todo")},
        active : checklistFilter.includes("todo")
      },
      {
        actionName: _l("l_inprogress"),
        actionIcon: "CircleFillInprogressIcons",
        actionHandler: () => {checkListFilter("inprogress")},
        active : checklistFilter.includes("inprogress")
      },
      {
        actionName: _l("l_done"),
        actionIcon: "CircleFillDoneIcons",
        actionHandler: () => {checkListFilter("done")},
        active : checklistFilter.includes("done")
      },
      {
        actionName: _l("l_priority"),
        actionIcon: "HighPriorityActiveIcon",
        actionHandler: () => {checkListFilter("priority")},
        active : checklistFilter.includes("priority")
      }
    ];

    if(floorDetails.floor.length > 1)
    {
      actionMenu = [{
        actionName: _l("l_floor_filter"),
        actionIcon: "StepIcon",
        actionHandler: () => {checkListFilter("floor")},
        active : checklistFilter.includes("floor")
      }].concat(actionMenu);
    }

    actionMenu = [{
      actionName: _l("l_all"),
      actionIcon: "SelectionAll",
      actionHandler: () => {checkListFilter("all")},
      active : checklistFilter.includes("all")
    },].concat(actionMenu);

    setActionMenuItems(actionMenu);
  }, [checklistFilter]);

  const checkIsCamFound = async () => {
    const camFound = await isCamFound();
    if(!camFound){
        setIsCameraOff(true);
        setIsCameraFound(false);
    }
  };
  
  useEffect(() => {
    console.log("test");
    checkIsCamFound();
  }, []);

  const handleSearchCheckList = (value) =>{
    setChecklistSearchString(value)
  }
  const checkListFilter = (value) => {
    let filterArr = checklistFilter;
    let index = filterArr.findIndex((f) => f == value);
    if (index > -1) {
      filterArr = filterArr.filter((c) => c != value);
    } else {
      filterArr = [value];
    }
    if(!filterArr.length)
    {
      filterArr.push(parseInt(data.floor_id) > 0 ? 'floor' : "all");
    }
    setChecklistFilter(filterArr.slice());
  }
  return (
    <React.Fragment>
      <div className={`tab-wrapper_main res-overflow-auto z-index-12imp d-none ${activeTab === "files" ? "pb-3":""}`}>
        <div className="d-flex align-items-center justify-content-between border-bottom">
          <div className="comman-tab-wrapper pb-0">
            <div className="d-flex align-items-center">
              {(data.provider === "google_drive" || data.provider === "one_drive") &&
              typeof data.is_synced !== "undefined" ? (
                <>
                  <div
                    id="task_detail_head_4"
                    className={`tab-wrapper  for-accessible ${
                      activeTab === "document" ? "active" : ""
                    } `}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("document");
                        // openToastClose();
                      }}
                    >
                      {_l("l_document")}
                    </a>
                    <a href="#/" className="info-icon">
                    {selectedTask.provider === 'one_drive' || selectedTask.provider === 'google_drive'?"":<Info size={18} weight="light" className="c-icons" />}
                    </a>
                  </div>

                  <div
                    id="task_detail_head_5"
                    className={`tab-wrapper  for-accessible ${
                      activeTab === "basic_details" ? "active" : ""
                    } `}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("basic_details");
                        // openToastClose();
                      }}
                    >
                      {_l("l_basic_details")}
                    </a>
                    <a href="#/" className="info-icon">
                      {selectedTask.provider === 'one_drive' || selectedTask.provider === 'google_drive'?"":<Info size={18} weight="light" className="c-icons" />}
                    </a>
                  </div>

                  <div
                    id="task_detail_head_6"
                    className={`tab-wrapper  for-accessible ${
                      activeTab === "activities" ? "active" : ""
                    }`}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("activities");
                        // openToastClose();
                      }}
                    >
                      {_l("l_activities")}
                    </a>
                    <a href="#/" className="info-icon">
                    {selectedTask.provider === 'one_drive' || selectedTask.provider === 'google_drive'?"":<Info size={18} weight="light" className="c-icons" />}
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div
                    id="task_detail_head_1"
                    className={`tab-wrapper  for-accessible d-none ${
                      activeTab === "checklist" ? "active" : ""
                    } `}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("checklist");
                        // openToastClose();
                      }}
                    >
                      {_l("l_checklist")}
                    </a>
                    <a href="#/" className="info-icon d-none">
                      <Info size={18} weight="light" className="c-icons" />
                    </a>
                  </div>
                  <div
                    id="task_detail_head_2"
                    className={`tab-wrapper  for-accessible d-none ${
                      activeTab === "files" ? "active" : ""
                    } `}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("files");
                        // openToastClose();
                      }}
                    >
                      {_l("l_files")}
                    </a>
                    <a href="#/" className="info-icon d-none">
                      <Info size={18} weight="light" className="c-icons" />
                    </a>
                  </div>
                  <div
                    id="task_detail_head_3"
                    className={`tab-wrapper  for-accessible d-none ${
                      activeTab === "links" ? "active" : ""
                    } `}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("links");
                        // openToastClose();
                      }}
                    >
                      {_l("l_links")}
                    </a>
                    <a href="#/" className="info-icon d-none">
                      <Info size={18} weight="light" className="c-icons" />
                    </a>
                  </div>
                </>
              )}

              {/* <div
                id="task_detail_head_4"
                className={`tab-wrapper d-none  ${activeTab === "notes" ? "active" : ""
                  } d-flex align-items-center`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    // setActiveTab("notes");
                    // openToastClose();
                  }}
                >
                  Notes
                </a>
              </div> */}
              {selectedTask && selectedTask.is_one_time_benchmark == 1 ? (
                <div
                  id="task_detail_head_5"
                  className={`tab-wrapper task-detail-tab for-accessible ${
                    activeTab === "benchmark" ? "active" : ""
                  } d-flex align-items-center`}
                >
                  <a href="#/"
                    className="tab-name"
                    onClick={() => {
                      setActiveTab("benchmark");
                    }}
                  >
                    {_l("l_benchmark")}
                  </a>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {activeTab === "checklist" ? (
            <>
            <div
              className={` d-none switch ${isExternal ? "d-none" : " " } `}
              data-tip={`${_l("l_toggle_detail")}`}
              data-effect="solid"
              data-delay-show='1000'
              data-for={randomID}
              data-class="tooltip-main"
            >
              <div className="d-flex align-items-center">
                <div className="c-font f-10 color-white-60 pe-2">
                  {/* <div className="result">
                  Above checkbox is
                  {isChecked ? "checked" : "un-checked"}.
                </div> */}
                </div>
                <div className="label-text">
                  <div className="d-flex align-items-center">
                    <div className="form-check form-switch d-flex justify-content-end align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked={isChecked}
                        onChange={() => {
                          setIsChecked(!isChecked);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex commn-search radius_3 justify-content-between">
              <div className="comment-input-box">
                <input
                  type="text"
                  className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font shadow-none "
                  placeholder={`${_l("l_search")}`}
                  value={checklistSearchString}
                  onChange={(e)=>{handleSearchCheckList(e.target.value)}}
                />
              </div>
              <a href="#/" className="comman_action_icon">
                <div className="d-flex align-items-center ">
                  <div className="action_icon h32w32 with_bg d-none">
                    <X size={18} className="c-icons" weight="light" />
                  </div>
                  <div className="action_icon h32w32 with_bg">
                    <MagnifyingGlass
                      size={18}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
              </a>
              <div className="dropstart ml-10">
              <a href="#/" className={`comman_action_icon  ${checklistFilter.length > 0 ? "iconactive" : ""}`}
              type="button"
              id="commentOptionDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-tip={_l("l_options")}
              data-effect="solid"
              data-delay-show='1000'
              data-class="tooltip-main"
              >
                <div className="d-flex align-items-center  ">
                  <div className="action_icon h32w32 with_bg on-hover-active-toggle-img">
                    <Funnel
                      size={18}
                      className="c-icons"
                      weight="light"
                      // color={`${checklistFilter.length > 0 ? "#00ff6a" : "#1d3545"}`}
                    />
                  </div>
                </div>
              </a>
              <ActionDropdown
                actions={actionMenuItems}
              />
              </div>
            </div>
            </>
          ) : (
            <></>
          )}
          {activeTab === "files" || activeTab === "links" ? (
            <div className="d-flex commn-search radius_3 justify-content-between">
              <div className="comment-input-box">
                <input
                  type="text"
                  className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                  placeholder={`${_l("l_search")}`}
                  value={searchLinkAttchment}
                  onChange={(e) => {
                    setSearchLinkAttchment(e.target.value);
                  }}
                />
              </div>
              <a href="#/" className="comman_action_icon">
                <div className="d-flex align-items-center ">
                  <div className="action_icon h25w25 d-none">
                    <X size={14} className="c-icons" weight="light" />
                  </div>
                  <div className="action_icon h25w25">
                    <MagnifyingGlass
                      size={14}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {(data.provider === "google_drive" || data.provider === "one_drive") &&
      typeof data.is_synced !== "undefined" ? (
        <>
          <div
            id="task_detail_11"
            className={`${activeTab === "document" ? "" : "d-none"} h-100`}
          >
            {/* {isLoading && (
              <div className="comman-main-box res-padding-right0 task-list-accordian">
                <CommanLoader />
              </div>
            )} */}
            <Documentdata data={data} />
          </div>

          <div
            id="task_detail_12"
            className={`${activeTab === "basic_details" ? "" : "d-none"} h-100`}
          >
            <div className="d-flex commn-search radius_3 justify-content-between">
              <div className="comment-input-box">
                <DocumentsDetail data={data} />
              </div>
            </div>
          </div>

          <div
            id="task_detail_13"
            className={`${activeTab === "activities" ? "" : "d-none"} h-100`}
          >
            <DocumentsActivity data={data} />
          </div>
        </>
      ) : (
        <></>
      )}
      <div className={`tast-detail-tab-wrapper res-height-auto ${ShowCommentModal ? "" : "h-135"}`}>
        <div
          id="task_detail_contain_1"
          className={`${
            activeTab === "checklist" ? "" : "d-none"
          } overflow-hiiden-auto d-flex flex-column h-100`}
        >
          {activeTab === "checklist" && (
            <>
            {iscallModal || isCallRecived ? <>
            <div className="row h-100">
                  {/* Chat feed start */}
              {isCallFullscreen ? 
              <FeedchatInTaskModal isMute={isMute} isCameraOff={isCameraOff} isCameraFound={isCameraFound} />
              : <></>
            }
            <div className="col-lg-7 d-flex flex-column h-90per">
                <ChecklistComponent
                projectId={isExternal ? data.project_id  : projectId}
                task_id={taskId}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                isSystemTask={
                  (selectedTask &&
                    selectedTask.addedfrom == 0 &&
                    selectedTask.schedule_id &&
                    parseInt(selectedTask.schedule_id) > 0) ||
                  selectedTask.is_allowed_to_edit != 1
                    ? true
                    : null
                }
                toastClose={() => {}}
                closeAllTost={toastClose}
                setRequestChecklist={() => {}}
                isExternal={isExternal}
                checklistAssigneesList={checklistAssigneesList}
                checklistSearchString={checklistSearchString}
                checklistFilter={checklistFilter}
                showFullscreen={showFullscreen}
                setChecklistFilter={setChecklistFilter}
                actionMenuItems={actionMenuItems}
                data={data}
                isAllowToEdit={data.is_allowed_to_edit == 1}
                iscallModal={iscallModal}
              />
              
                <FileUploadComponent
                projectId={projectId}
                task_id={taskId}
                attachmentCountUpdate={(task_id, flag) => {
                  handleBodyAction("attachmentCountUpdate", {
                    task_id: task_id,
                    flag: flag,
                  });
                }}
                toastClose={() => {}}
                closeAllTost={toastClose}
                searchAttchment={searchLinkAttchment}
                isExternal={isExternal}
                readOnly={false}
                taskDetails={selectedTask}
              />
            </div>
        
        {/* Chat feed over */}
                  {/* feed chat button start */}
          {isCallFullscreen ?
          <FeedchatInTaskModalButton isMute={isMute} setIsMute={setIsMute} isCameraOff={isCameraOff} setIsCameraOff={setIsCameraOff} isCameraFound={isCameraFound} />
          :<></>
        }
      {/* feed chat button over */}
            </div>
              
            
            </>
              : <>

          <ChecklistComponent
            projectId={isExternal ? data.project_id  : projectId}
            task_id={taskId}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            isSystemTask={
              (selectedTask &&
                selectedTask.addedfrom == 0 &&
                selectedTask.schedule_id &&
                parseInt(selectedTask.schedule_id) > 0) ||
              selectedTask.is_allowed_to_edit != 1
                ? true
                : null
            }
            toastClose={() => {}}
            closeAllTost={toastClose}
            setRequestChecklist={() => {}}
            isExternal={isExternal}
            checklistAssigneesList={checklistAssigneesList}
            checklistSearchString={checklistSearchString}
            checklistFilter={checklistFilter}
            showFullscreen={showFullscreen}
            setChecklistFilter={setChecklistFilter}
            actionMenuItems={actionMenuItems}
            data={data}
            isAllowToEdit={data.is_allowed_to_edit == 1}
            iscallModal={iscallModal}
          />
          
          <FileUploadComponent
          projectId={projectId}
          task_id={taskId}
          attachmentCountUpdate={(task_id, flag) => {
            handleBodyAction("attachmentCountUpdate", {
              task_id: task_id,
              flag: flag,
            });
          }}
          toastClose={() => {}}
          closeAllTost={toastClose}
          searchAttchment={searchLinkAttchment}
          isExternal={isExternal}
          readOnly={false}
          taskDetails={selectedTask}
        />
              </> }
        </>
          )}
          <div className="align-items-center bottom-0 d-flex position-sticky d-none">
            <div className="file-image-list p-0 pb-3 pe-3">
              <div className="c-list-icon w-100 position-relative">
                <div className="h130w130 comman-round-box with-bg radius_3 with-react-fancybox bg-white-03 pb-3 pe-3 d-flex bg-white-03">
                  <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3  d-flex">
                    <UploadSimple
                      size={24}
                      className="c-icons"
                      weight="light"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="file-image-list p-0 pb-3 pe-3">
              <div className="c-list-icon w-100 position-relative with-overlay">
                <div className="h130w130 comman-round-box with-bg radius_3 with-react-fancybox">
                  <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3" style={{
                    backgroundImage: `url('https://source.unsplash.com/random')`,
                  }}>

                  </div>
                </div>
              </div>
            </div>
            <div className="file-image-list p-0 pb-3 pe-3">
              <div className="c-list-icon w-100 position-relative">
                <div className="h130w130 comman-round-box with-bg radius_3 with-react-fancybox bg-white-03 pb-3 pe-3 d-flex bg-white-03">
                  <div className="comman-bg-img h-100 w-100 bg-style-cover radius_3  d-flex">
                    <span className="m-auto">+3</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="task_detail_contain_2"
          className={`${activeTab === "files" ? "" : "d-none"} h-100`}
        >
          {activeTab === "files" && (<FileUploadComponent
            projectId={projectId}
            task_id={taskId}
            attachmentCountUpdate={(task_id, flag) => {
              handleBodyAction("attachmentCountUpdate", {
                task_id: task_id,
                flag: flag,
              });
            }}
            toastClose={() => {}}
            closeAllTost={toastClose}
            searchAttchment={searchLinkAttchment}
            isExternal={isExternal}
            readOnly={false}
            taskDetails={selectedTask}
          />)}
        </div>
        <div
          id="task_detail_contain_3"
          className={`${activeTab === "links" ? "" : "d-none"} h-100`}
        >
          {
            activeTab === "links" ? <>
              <LinkComponent
                projectId={projectId}
                task_id={taskId}
                toastClose={() => {}}
                closeAllTost={() => {}}
                searchLink={searchLinkAttchment}
                isExternal={isExternal}
                readOnly={
                  (selectedTask &&
                    selectedTask.addedfrom == 0 &&
                    selectedTask.schedule_id &&
                    parseInt(selectedTask.schedule_id) > 0) ||
                  selectedTask.is_allowed_to_edit != 1
                    ? true
                    : false
                }
              />
            </> : <></>
          }
          
        </div>
        <div
          id="task_detail_contain_4"
          className={`for-disabled ${
            activeTab === "notes" ? "" : "d-none"
          } h-100`}
        >
          {
            activeTab === "notes" ? <>
            <NotesComponent
              projectId={projectId}
              task_id={taskId}
              toastClose={() => {}}
              closeAllTost={() => {}}
              isExternal={isExternal}
            />
          </> : <></>
          }
         
        </div>
        
      </div>

      <ReactTooltip id={randomID}/>
    </React.Fragment>
  );
}

export default React.memo(TaskBodyCommonModal);
