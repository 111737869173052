import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftPanel from "../components/LeftPanel";
import { useDispatch } from "react-redux";

const ExchangeGoogleCode = ({provider = 'google_drive'}) => {
  
  const dispatch = useDispatch();

  useEffect(()=>{
   // window.close();

},[]);

  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const [projectId, setProjectId] = useState(
    spaces && spaces.length ? spaces[0].project_id : 0
  );
  return (
    <>
      <Header projectId={projectId}/>
        <LeftPanel setSelectedProject={setProjectId} projectId={projectId}/>
        
        <Footer />
    </>
  );
};

export default ExchangeGoogleCode;
